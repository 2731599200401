import { ref, object, string } from "yup";

export const InputsLengths = {
  max: {
    name: 255,
    password: 20,
  },
  min: {
    name: 3,
    password: 5,
  },
};

export const getInviteUSer = (passwordOneOfMessage: string) =>
  object({
      password: string()
      .min(InputsLengths.min.password)
      .max(InputsLengths.max.password)
      .required(),
    confirmPassword: string()
      .min(InputsLengths.min.password)
      .max(InputsLengths.max.password)
      .required()
      .oneOf([ref("password")], passwordOneOfMessage),
    // adapter_configurators: 
    //   mixed()
    //   // .test("required", required, (value) => Array.isArray(value) && value.length > 0),
  });

//   export const setModuleSchema = (required: string) =>
//   object({
//     adapter_configurators: 
//       mixed()
//       // .test("required", required, (value) => Array.isArray(value) && value.length > 0),
//   });
