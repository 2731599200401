/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { IconButton, Button } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedParishArea,
  selectFiltersTabOpen,
  setFiltersTabOpen,
  setSelectedParishArea,
  toggleList,
} from "store/slices/mapSlice";
import { useLocation } from "react-router";

type Props = {
  open: boolean;
  type: "list" | "detail" | "wazeMap";
  modulePage?:boolean
};

 
export const ListButtonsContainer = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filterTabOpen = useSelector(selectFiltersTabOpen);
  const [oldState, setOldState] = React.useState(props.open);
  const selectedParish = useSelector(getSelectedParishArea);
  const [oldParish, setOldParish] = React.useState("");

  const buttonTitle = {
    "detail":t("map.detail.titleBtn"),
    "list":t("map.list.open"),
    "wazeMap": t("common.seeDetails") 
  }

  let location = useLocation();

  const searchParams = new URLSearchParams(location.search); 
  const viewType = searchParams.get("view");

  const handleChangeTab = () => {
    dispatch(toggleList(!props.open));
  };

  useEffect(() => {
    if (filterTabOpen && !selectedParish.name && !oldParish) {
      setOldState(props.open);
      dispatch(toggleList(false));
    } else if (filterTabOpen && oldParish === selectedParish.name) {
      setOldState(props.open);
      dispatch(toggleList(false));
      dispatch(setSelectedParishArea({ name: "", id: "" }));
      setOldParish("");
    }

    if (
      filterTabOpen &&
      selectedParish.name &&
      (!oldParish || selectedParish.name !== oldParish)
    ) {
      dispatch(setFiltersTabOpen(false));
      setOldParish(selectedParish.name);
      dispatch(toggleList(true));
    }

    if (oldState && !filterTabOpen && !selectedParish.name && viewType === "visits") {
      dispatch(toggleList(oldState));
    }

    if (selectedParish.name && !filterTabOpen) {
      setOldState(props.open);
      setOldParish(selectedParish.name);
    }

    if (!props.open && selectedParish.name) {
      if (filterTabOpen) dispatch(setFiltersTabOpen(false));
      dispatch(toggleList(true));
    }
  }, [filterTabOpen, selectedParish.name]);

  return (
    <>
      {props.open && !selectedParish.name ? (
        <IconButton
          className="contrast"
          onClick={handleChangeTab}
          sx={{
            position: "absolute",
            top: viewType || props.modulePage ? 95 : 20,
            right: 455,
            zIndex: 1000,
          }}
        >
          <ArrowForward />
        </IconButton>
      ) : !selectedParish.name ? (
        <Button
          className="contrast"
          startIcon={<ArrowBack />}
          onClick={handleChangeTab}
          sx={{ 
            position: "absolute", 
            top: viewType || props.modulePage ? 95 : 20,
            right: 20, 
            zIndex: 1000 
          }}
        >
          {buttonTitle[props.type]}
        </Button>
      ) : null}
    </>
  );
};
