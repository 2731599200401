import { ISelectOption } from 'types/interfaces'
import { TUser } from 'types/types'

export const formDefaultValues = (lang: ISelectOption, account: ISelectOption| null, data?: TUser) => {
  const defaultValues = {
    account: account,
    client: data?.client || '',
    email: data?.email || '',
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    is_admin: data?.is_admin || false,
    is_superadmin: data?.is_superadmin || false,
    language: lang || '',
    permission: data?.permission || [],
    username: data?.username || '',

		status: data?.status || '',

		_is_active: data?.status === 'active' || false
  }
  return defaultValues
}
