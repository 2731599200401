import { IconButton } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

export const CustomRightArrow = ({ onClick, ...rest}: any) => {
  const { disabled } = rest;
  return (
    <IconButton
      className="arrow right"
      color="primary"
      disabled={disabled}
      onClick={() => onClick()}
    >
      <ArrowForwardIosRoundedIcon />
    </IconButton>
  );
};

export const CustomLeftArrow = ({ onClick, ...rest }: any) => {
  const {disabled } = rest;
  return (
    <IconButton
      className="arrow left"
      color="primary"
      disabled={disabled}
      onClick={() => onClick()}
    >
      <ArrowBackIosNewRoundedIcon />
    </IconButton>
  );
};
