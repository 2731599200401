import { InputAutocomplete, InputCheckbox, InputText, RequiredField } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import React, { useEffect, useState } from "react";
import { IModuleForm, ISelectOption } from "types/interfaces";
import { getAccountSchema } from "./validations";
import useGet from "hooks/fetchData/useGet";
import { TAdapterConfigurator, TAccount } from "types/types";
import { formDefaultValues } from "./formDefaultValues";
import { RequiredFields } from "components/Utils/RequiredField";

const AccountsForm = (props: {
  formId: string;
  clientID?: string;
  data?: TAccount;
  editForm?: boolean;
  administrationPage?: boolean;
  onFormSubmit: (payLoad: IModuleForm) => void;
  onChangeStatusForm?: (status: boolean) => void;
}) => {
  const { t } = useTranslation();

  const {
    formId,
    clientID,
    data,
    onFormSubmit,
    administrationPage,
    onChangeStatusForm,
  } = props;

  const [gatewaysOptions, setGatewaysOptions] = useState<ISelectOption[]>([]);

  const defaultValues = formDefaultValues(data);
  const methodsForm = useForm<IModuleForm>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(getAccountSchema()),
  });

  const [adminCheckBox, setAdminCheckBox] = useState(defaultValues.admin)

  const { control, handleSubmit, setValue } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IModuleForm> = (
    payLoad: IModuleForm
  ) => {
    onFormSubmit(payLoad);
  };
  function arrayIsEmpty<T>(array: T | null | undefined): boolean {
    return !array || !Array.isArray(array) || !array.length;
  }

  // get list of gateways
  const { data: allGateways } = useGet(ServiceApiUrl.adapterConfigurators, adminCheckBox ? {
    all: true
  } : {
    client: clientID,
  });

  useEffect(() => {
    if (allGateways) {
      const options = allGateways?.data?.map((p: TAdapterConfigurator) => ({
        label: p.name,
        value: p.id,
      }));

      setGatewaysOptions(options);
    }
  }, [allGateways]);

  const handleCheckBoxChange = (e: any) => {
    setAdminCheckBox(e.target.checked);
  }

  useEffect(() => {
    if (onChangeStatusForm) {
      onChangeStatusForm(methodsForm.formState.isValid);
    }
  }, [onChangeStatusForm, methodsForm.formState.isValid]);

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RequiredField title={t("accounts.name")} />
              <InputText
                control={control}
                name="name"
                placeholder={t("accounts.namePlaceholder")}
              />
            </Grid>
            {administrationPage && (
              <Grid item xs={12}>
                <InputCheckbox
                  control={control}
                  name="admin"
                  setValue={setValue}
                  labelText={t("accounts.adminLabel")}
                  checked={defaultValues.admin}
                  onChange={handleCheckBoxChange}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid sx={{ display: "flex", alignItems: "baseline" }}>
                <RequiredField title={t("accounts.adapters")} />
              </Grid>
              {arrayIsEmpty(gatewaysOptions) && (
                <Typography mt={2} mb={2} variant="body1">
                  {t("adapters.thereAreNotAdapters")}
                </Typography>
              )}
              {!arrayIsEmpty(gatewaysOptions) && (
                <InputAutocomplete
                  name="adapter_configurators"
                  options={gatewaysOptions}
                  multiple
                  control={control}
                  required
                />
              )}
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <RequiredFields allRequired />
    </>
  );
};

export default AccountsForm;
