/* eslint-disable react-hooks/exhaustive-deps */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { Collapse, Grid, IconButton, TableCell, TableRow } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { TStream } from 'types/types'

import { ChipStatus } from 'components/Utils/ChipStatus'
import { TableCellExpanded, TableRowExpanded } from 'styles/css/components'
import { formatDate } from 'utils/date'
import RowItem from 'utils/Table/RowItem'
import { statusColor } from 'utils/utils'

function StreamsList(props: {
  row: TStream
  open: boolean
  onClick: Function
}) {
  const { row, open, onClick } = props
  const { t } = useTranslation()

  const showExpandCell = true

  return (
    <>
      <TableRow
        className={clsx({ 'no-pointer': !showExpandCell, expanded: open })}
        onClick={() => {
          if (!showExpandCell) return void 0
          onClick(open ? '' : row.id)
        }}
      >
        <TableCell sx={{ width: 50 }} size="small">
          {showExpandCell && (
            <IconButton size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <ChipStatus
            label={row.is_active ? t('common.active') : t('common.inactive')}
            color={
              row.is_active ? statusColor('active') : statusColor('non-active')
            }
          />
        </TableCell>
        <TableCell>{row.unit}</TableCell>
        <TableCell>
          {formatDate(row.created_at, t('calendar.dateTimeFormatLocal'))}
        </TableCell>
      </TableRow>
      {showExpandCell && (
        <TableRowExpanded>
          <TableCellExpanded colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container columns={{ xs: 2, sm: 6, md: 8, lg: 12 }}>
                <RowItem label={'Id'} value={row.id} />
                <RowItem
                  label={t('adapters.category')}
                  value={row?.category?.name}
                />
                <RowItem
                  label={t('adapters.description')}
                  value={row.description}
                />
                <RowItem
                  label={t('common.updatedAt')}
                  value={formatDate(
                    row.updated_at,
                    t('calendar.dateTimeFormatLocal')
                  )}
                />
              </Grid>
            </Collapse>
          </TableCellExpanded>
        </TableRowExpanded>
      )}
    </>
  )
}

export default StreamsList
