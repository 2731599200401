import { IOccurrenceStatusForm } from "./StatusForm"

export const formDefaultValues = (data?: IOccurrenceStatusForm) => {
  const defaultValues = {
    name: data?.name || '',
    is_open : data?.is_open || false,
    is_closed  : data?.is_closed || false,
  }
  return defaultValues
}
