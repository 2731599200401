import { GenericTable, IApiResponse, Loading, TGenericTableModel } from "@alb/live-lib";
import CircleIcon from "@mui/icons-material/Circle";
import { Box, Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { IBugaNumBikesChart, IBugaNumBikesValuesChart } from "types/interfaces";

import NoData from "components/Utils/NoData";
import { formatDate } from "utils/date";

import { CircularProgressBikes } from "./CircularProgressBikes";

interface IBugaBikesStationChart {
  dataChart: IBugaNumBikesChart[];
  loading: boolean;
  error?: AxiosError<any, any> | null;
}

export const BugaBikesStationChart = ({
  dataChart,
  loading,
  error,
}: IBugaBikesStationChart) => {
  const { t } = useTranslation();

  const colorMax = "#92CC76";
  const colorMin = "#EF6666";

  const modelTable: TGenericTableModel<any> = {
    columns: [
      {
        html: (row) => (
          <>{formatDate(row.date, t("calendar.dateFullFormat"))}</>
        ),
      },
      {
        html: (row) => (
          <Box display={"inline-flex"} alignItems={"center"}>
            <Typography marginRight={1}>
              {showTime(row.max.start_time, row.max.end_time)}
            </Typography>
            <CircularProgressBikes
              valueProgress={showNumBikes(row.max.chart)}
              valueLabel={row.max.chart.attached_bikes}
              color={colorMax}
            />
          </Box>
        ),
      },
      {
        html: (row) => (
          <Box display={"inline-flex"} alignItems={"center"}>
            <Typography marginRight={1}>
              {showTime(row.min.start_time, row.min.end_time)}
            </Typography>
            <CircularProgressBikes
              valueProgress={showNumBikes(row.min.chart)}
              valueLabel={row.min.chart.attached_bikes}
              color={colorMin}
            />
          </Box>
        ),
      },
    ],
  };

  function showTime(startTime: string | Date, endTime: string | Date) {
    const format = t("calendar.hourFormat");
    return formatDate(startTime, format) + " - " + formatDate(endTime, format);
  }

  function showNumBikes(values: IBugaNumBikesValuesChart) {
    return (values.attached_bikes * 100) / values.capacity;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3" fontSize={"18px"}>
            {t("buga.stationsCard.titleBikesStationChart")}
          </Typography>
        </Grid>
        <Grid container item mt={1} xs={12}>
          <Loading show={loading} />
          {!loading && (error || dataChart.length === 0) && (
            <NoData error={error} />
          )}
        </Grid>
        {!loading && dataChart.length > 0 && (
          <>
            <Grid container item mt={1} xs={12}>
              <Grid item xs={6} display={"flex"}>
                <CircleIcon
                  fontSize="small"
                  sx={{ color: colorMax, marginRight: 1 }}
                />
                <Typography> {t("buga.stationsCard.maxBikes")} </Typography>
              </Grid>
              <Grid item xs={6} display={"flex"}>
                <CircleIcon
                  fontSize="small"
                  sx={{ color: colorMin, marginRight: 1 }}
                />
                <Typography> {t("buga.stationsCard.minBikes")} </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ overflow: "auto", height: "197px" }}>
              <GenericTable
                model={modelTable}
                items={{ data: dataChart } as IApiResponse<IBugaNumBikesChart>}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
