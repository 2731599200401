import { Loading, useFeedback } from "@alb/live-lib";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IOccurrenceForm } from "types/interfaces";
import { TOccurrence } from "types/types";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import useUpdate from "hooks/fetchData/useUpdate";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { setDrawingMap, setDrawnCoords } from "store/slices/mapSlice";
import {
  getListDeletedMedia,
  setListDeletedMedia,
} from "store/slices/occurrencesSlice";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

import OccurrenceForm from "./form/OccurrenceForm";
import { formatDataEditForm } from "./utils/formatDataToSend";

interface IOccurrenceEdit {
  idOccurrence: string;
  open: boolean;
  onClose: () => void;
  onSuccess: (occurrence: TOccurrence) => void;
}

const OccurrenceEdit = ({
  idOccurrence,
  open,
  onClose,
  onSuccess,
}: IOccurrenceEdit) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const dispatch = useDispatch();

  const editFormId = "edit-occurrences-form";

  const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

  const listDeletedMedia = useSelector(getListDeletedMedia);

  const [formIsValid, setFormIsValid] = useState(false);

  const {
    data: occurrenceInitialData,
    loading: loadingData,
    error: errorData,
    refetch: refetchInitialData,
  } = useGet(
    `${ServiceApiUrl.occurrences}/${idOccurrence}`,
    undefined,
    { manual: true },
    headers
  );

  const { loading, refetch: editOccurrence } = useUpdate(
    ServiceApiUrl.occurrences,
    idOccurrence,
    headers
  );

  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  const handleOnClose = () => {
    onClose(); //fecha modal
    dispatch(setDrawingMap(false));
    dispatch(setDrawnCoords(null));
  };

  const handlerSuccess = (occurrence: TOccurrence) => {
    handleOnClose(); //fecha modal
    addFeedback({
      message: t("occurrences.occurrencesEdited"),
      severity: "success",
    });
    onSuccess && onSuccess(occurrence);
    dispatch(setListDeletedMedia([]));
  };

  //ao submeter o formulário
  const onSubmit: SubmitHandler<IOccurrenceForm> = async (payLoad: IOccurrenceForm) => {
    const result = formatDataEditForm(payLoad, listDeletedMedia);

    try {
      await editOccurrence({ data: result }).then((response) => {
        handlerSuccess(response.data);
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (idOccurrence) {
      refetchInitialData();
    }
  }, [idOccurrence]);

  useEffect(() => {
    if (occurrenceInitialData) {
      dispatch(setDrawingMap(true));
      dispatch(setDrawnCoords(occurrenceInitialData.geolocation));
    }
  }, [occurrenceInitialData]);

  return (
    <>
      <Dialog
        maxWidth="xl"
        fullWidth
        sx={{
          ".MuiPaper-root": {
            padding: 0,
            display: "flex",
            flexDirection: "row",
          },
        }}
        open={open}
      >
        <Grid container sx={{ overflow: "auto" }}>
          <Grid item xs={true} sx={{ padding: "0 10px 0 20px" }}>
            <DialogTitle>
              <Typography
                component={"span"}
                gutterBottom={true}
                variant="h5"
                noWrap
              >
                {t("occurrences.titleEditOccurrenceDialog")}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handleOnClose}
                size="small"
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 15,
                  zIndex: 500,
                }}
                title={t("close")}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent
              style={{
                paddingTop: "0",
                paddingRight: "10px",
              }}
            >
              <Loading show={loadingData} />
              {!loadingData && (errorData || !occurrenceInitialData) && (
                <NoData error={errorData} />
              )}
              {!loadingData && !errorData && occurrenceInitialData && (
                <OccurrenceForm
                  data={occurrenceInitialData}
                  formId={editFormId}
                  editMode="edit"
                  onFormSubmit={onSubmit}
                  setFormIsValidStatus={getFormIsValidStatus}
                />
              )}
            </DialogContent>
            {!loadingData && !errorData && occurrenceInitialData && (
              <DialogActions
                style={{
                  paddingRight: "10px",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleOnClose}
                >
                  {t("cancel")}
                </Button>

                <LoadingButton
                  loading={loading}
                  variant="contained"
                  color="primary"
                  type={"submit"}
                  disableElevation
                  disabled={!formIsValid}
                  form={editFormId}
                >
                  {t("common.save")}
                </LoadingButton>
              </DialogActions>
            )}
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default OccurrenceEdit;
