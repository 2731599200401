import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SubmitHandler } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useFeedback } from '@alb/live-lib'
import Footer from 'components/ui/footer'
import backgroundImage from '../assets/images/live-urban/recover_password.svg'
import { ReactComponent as Logo } from '../assets/images/live-urban/logo_horizontal_URBAN.svg'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import useGet from 'hooks/fetchData/useGet'
import { RecoverPasswordForm } from 'components/recover-password/RecoverPasswordForm'
// import useCreate from 'hooks/fetchData/useCreate'
import useUpdate from 'hooks/fetchData/useUpdate'
import { useEffect, useState } from 'react'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { ReactComponent as LogoCircle } from '../assets/images/live-urban/logo.svg'

export const RecoverPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { addFeedback } = useFeedback()
  const [searchParams] = useSearchParams()

  const [success, setSuccess] = useState<boolean>(false)
  const formId = 'recover-password'

  const token = searchParams.get('token') || ''

  const {
    data: user,
    error
  } = useGet(ServiceApiUrl.validateTokenURL, { token })

  const headersAdd = {
    headers: {
      Token: token,
    },
  }

  useEffect(() => {
    if (success) {
      addFeedback({
        message: t('invite.messageSuccessConfirmInvitation'),
        severity: 'success',
      })
      navigateToLogin()
    }
    // eslint-disable-next-line
  }, [success])

  //pedido para criar confirmar
  const { loading: loadingRequest, refetch: confirmUSer } = useUpdate(
    ServiceApiUrl.setPwdURL,
    '',
    headersAdd
  )

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<any> = async (payload: any) => {
    const { password, confirmPassword } = payload

    if (password !== confirmPassword) {
      await setSuccess(false)
    }
    var passwordSubmit = {
      password: payload.password,
    }
    await confirmUSer({ data: passwordSubmit })
    await setSuccess(true)
  }

  const navigateToLogin = () => navigate('/login')

  return (
    <>
      <Container sx={{ pt: 8 }} maxWidth={'lg'}>
        {error && (
          <Box>
            <Box>
              <LogoCircle width={'40%'} />
              <Typography
                sx={{ padding: '3% 0' }}
                color="secondary"
                variant="h4"
              >
                {t('errors.somethingWentWrong')}
              </Typography>

              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                {t('invite.invalidInvitedTokenText')}
              </Typography>
            </Box>
            <Stack marginTop={2} alignItems={'flex-start'}>
              <Button
                color="primary"
                type="button"
                variant="outlined"
                startIcon={<ArrowBackRoundedIcon />}
                onClick={navigateToLogin}
              >
                {t('goBack')}
              </Button>
            </Stack>
          </Box>
        )}
        {user && !error && (
          <>
            <Typography variant="h2">
              {t('recoverPassword.pageTitle')}
            </Typography>

            <Typography mt={2} variant="body1">
              {t('recoverPassword.pageSubtitle')}
              <Logo width={'6rem'} />
            </Typography>

            <RecoverPasswordForm
              data={user}
              formId={formId}
              onFormSubmit={formSubmitHandler}
              loading={loadingRequest}
            />
          </>
        )}
      </Container>
      <Footer backgroundImage={backgroundImage} maxWidth={'lg'} />
    </>
  )
}

export default RecoverPassword
