/* eslint-disable react-hooks/exhaustive-deps */
import { IMapPoint, InputDateTime, useFeedback, useUpdateEffect } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import EventIcon from "@mui/icons-material/Event";
import { Box, Button, Card, CardContent, Grid, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { TUser } from "types/types";
import { date, object } from "yup";

import useMarkers from "hooks/useMarkers";
import i18n from "i18n/config";
import { getUser } from "store/slices/authSlice";
import { getSelectedMarkerType, selectFiltersTabOpen, selectMarkers, setFitBounds } from "store/slices/mapSlice";
import { getWazeFilterParams, setWazeFilterParams } from "store/slices/wazeSlice";
import styles from "styles/modules/map/controls.module.scss";
import { clientPublicPortal, IClientPublicPortal } from "utils/clientsPublicPortal";
import { objectIsEmpty } from "utils/utils";
import { formatDate } from "utils/date";
import { wazeTypes } from "components/map/waze/wazeTypes";

import { MarkersTypeOptions } from "./FilterMapTab/utils/GetOptions";

interface IWazeStartDateControl {
	todayDate: Date;
	publicAPI?: boolean;
	sendNotifications: boolean;
	setSendNotifications: any;
}

export const WazeStartDateControl = ({
	todayDate,
	publicAPI,
	sendNotifications,
	setSendNotifications,
}: IWazeStartDateControl) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation();
	const selectedType = useSelector(getSelectedMarkerType);
	const mapWazeParams = useSelector(getWazeFilterParams);
	
	const { getWazeData } = useMarkers();

	const userRedux = useSelector(getUser);
	const user = !publicAPI
		? userRedux
		: (clientPublicPortal(location.pathname) as TUser | IClientPublicPortal);

	const filterTabOpen = useSelector(selectFiltersTabOpen);
	const { addFeedback } = useFeedback();
	const markers: IMapPoint[] = useSelector(selectMarkers);

	const [open, setOpen] = useState<boolean>(false);
	const [startDateWaze, setStartDateWaze] = useState(todayDate);
	const [resultsCount, setResultsCount] = useState<IMapPoint[]>([]);

	const success = () => {
		if (sendNotifications) {
			addFeedback({
				message: t("feedback.success.appliedFilters"),
				severity: "success",
				duration: 1500,
			});
		} else {
			setSendNotifications(true);
		}
	};

	const [filtersWereOpened, setFiltersWereOpened] = useState<boolean>(false);


	useEffect(() => {
		//se abriu pela primeira vez, troca o estado
		if (filterTabOpen) setFiltersWereOpened(true);
	}, [filterTabOpen]);

	//vai fazer o pedido dos dados waze com a start date por esta função
	//até que os filtros sejam abertos
	useUpdateEffect(() => {
		const getWaData = async () => {
			try {
				await getWazeData(user);
				success();
			} catch { }
		}
		if (!objectIsEmpty(mapWazeParams) && !filtersWereOpened) {
			getWaData();
		}
	}, [mapWazeParams["from"]]);

	//quando a data de inicio for alterada, faz dispatch para os parametros do pedido dos dados waze
	useUpdateEffect(() => {
		dispatch(
			setWazeFilterParams({
				...mapWazeParams,
				from: formatDate(
					startDateWaze,
					t("calendar.dateTimeEndpoints")
				),
				to: new Date()
			})
		);
	}, [startDateWaze]);

	const handleChangeStartDateWaze = (startDate: Date) => {
		setTimeout(() => {
			if (!filterErrors.start_date_waze) {
				//sempre que a data for alterada, envia fitBounds par afazer fitBounds do lado do mapa nos resultados
				dispatch(setFitBounds(true));
				//se não existir erro, faz set da data
				setStartDateWaze(startDate);
			}
		}, 0);
	};

	const defaultValues = {
		start_date_waze: new Date(startDateWaze),
	};

	const getStartDateSchema = () => {
		return object().shape(
			{
				start_date_waze: date()
					.nullable()
					.typeError(t("validations.invalidDate", { dateFormat: "yyyy-MM-dd" }))
					.when("start_date_waze", (value, schema) => {
						if (mapWazeParams["status_date"]) {
							return schema.max(
								mapWazeParams["status_date"],
								t("waze.startDateAfterStatusDateError")
							);
						} else {
							return schema;
						}
					}),
			},
			[["start_date_waze", "start_date_waze"]]
		);
	};

	const methods = useForm<{ start_date_waze: Date }>({
		defaultValues: defaultValues,
		mode: "all",
		reValidateMode: "onChange",
		resolver: yupResolver(getStartDateSchema()),
	});

	const {
		control,
		watch,
		formState: { errors: filterErrors },
	} = methods;

	let showCard =
		selectedType === MarkersTypeOptions.waze;

	//para obter a contagem dos dados waze
	useEffect(() => {
		const result = markers.filter((marker) => (wazeTypes.includes(marker.type)));
		setResultsCount(result);
	}, [markers]);

	const handleClick = () => {
		setOpen(!open);
	};

	var today = new Date();

	const selectedDateLabel = () => {
		let selectedDate = watch("start_date_waze");

		let label =
			today.toDateString() === selectedDate.toDateString()
				? t("common.today")
				: formatDate(selectedDate, t("calendar.dateFormat"));

		return label;
	};

	return (
		<>
			{showCard && (
				<Box pl={1}>
					{open && (
						<Grid item pb={1} xs={true}>
							<Card elevation={0} className={styles["map-control-shadow"]}>
								<CardContent>
									<Typography mb={1} variant="h3" fontSize={"16px"}>
										{t("waze.waze")}
									</Typography>
									<Box mb={1.5} sx={{ display: "flex" }}>
										<Typography
											variant="body1"
											color="text.secondary"
											sx={{
												fontSize: "12px",
												fontWeight: 700,
											}}
										>
											{resultsCount?.length}
										</Typography>
										<Typography
											color="text.secondary"
											sx={{
												fontSize: "12px",
												paddingLeft: 0.5,
											}}
										>
											{t("map.list.subTitle", { count: resultsCount?.length })}
										</Typography>
									</Box>

									<FormProvider {...methods}>
										<Typography mr={6} gutterBottom fontSize={"12px"}>
											{t("waze.startDateEqualOrGreater")}
										</Typography>
										<InputDateTime
											name="start_date_waze"
											activeField={watch("start_date_waze")}
											control={control}
											locale={i18n.language}
											handleOnChange={handleChangeStartDateWaze}
											labelBtnCancel={t("cancel")}
											labelBtnConfirm={t("apply")}
											placeholder={t("calendar.placeholderDate")}
										/>
									</FormProvider>
								</CardContent>
							</Card>
						</Grid>
					)}
					<Tooltip
						title={t("waze.startDatewaze")}
						placement="right"
					>
						<Button
							onClick={handleClick}
							className="contrast"
							endIcon={<EventIcon />}
						>
							{selectedDateLabel()}
						</Button>
					</Tooltip>
				</Box>
			)}
		</>
	);
};

export default WazeStartDateControl;
