import { ConfirmDialog } from "@alb/live-lib";
import { t } from "i18next";

const RemoveUserDialog = (props: {
    onDialogCancel: () => void,
    onDialogConfirm: () => void
}) => {

    const { onDialogCancel, onDialogConfirm } = props;

    return(<ConfirmDialog 
        open={true}
        type="error"
        title={t("group.removeUser")}
        message={t("group.removeUserConfirmation")}
        actionConfirmText={t("common.delete")}
        actionCancelText={t("common.cancel")}
        loading={false}
        onCancel={onDialogCancel}
        onConfirm={onDialogConfirm}
    />)
}

export default RemoveUserDialog;