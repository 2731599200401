/* eslint-disable react-hooks/exhaustive-deps */
import { BackToButton, LayoutContext, Loading, useFeedback } from "@alb/live-lib";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { IOccurrenceForm } from "types/interfaces";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import useUpdate from "hooks/fetchData/useUpdate";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { setDrawingMap, setDrawnCoords } from "store/slices/mapSlice";
import { getListDeletedMedia, setListDeletedMedia } from "store/slices/occurrencesSlice";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { PERMISSIONS } from "utils/permissions/permissions";

import { OccurrenceHistory } from "./detail/OccurrenceHistory";
import OccurrenceForm from "./form/OccurrenceForm";
import { formatDataEditForm } from "./utils/formatDataToSend";

const OccurrenceDetail = () => {
  let params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const { addFeedback } = useFeedback();

  const editFormId = "edit-occurrences-form";

  const listDeletedMedia = useSelector(getListDeletedMedia);

  const [formIsValid, setFormIsValid] = useState(false);

  const { addHeader, addAction, action: editForm } = useContext(LayoutContext);

  const canEdit = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.UPDATE);

  const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);
  const {
    data: occurrence,
    loading,
    error,
    refetch,
  } = useGet(
    `${ServiceApiUrl.occurrences}/${params.occurrenceId}`,
    undefined,
    undefined,
    headers
  );

  const { loading: loadingEdit, refetch: editOccurrence } = useUpdate(
    ServiceApiUrl.occurrences,
    occurrence?.id,
    headers
  );

  const header = {
    title: t("occurrences.detail"),
    backTo: (
      <BackToButton
        title={t("goBack")}
        onClick={() => navigate("/occurrences?view=list")}
      />
    ),
    action: occurrence && canEdit && (
      <>
        <Button
          sx={{ marginRight: "10px" }}
          variant="outlined"
          type="reset"
          form={editFormId}
          disableElevation
          disabled={!editForm}
          onClick={() => onClickCancel()}
        >
          {t("cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          disableElevation
          startIcon={editForm ? <SaveIcon /> : <EditRoundedIcon />}
          loading={loadingEdit}
          {...(editForm && {
            type: "submit",
            form: editFormId,
            disabled: !formIsValid,
          })}
          onClick={() => {
            if (!editForm) {
              onClickEdit();
            }
          }}
        >
          {t(editForm ? "save" : "occurrences.editOccurrence")}
        </LoadingButton>
      </>
    ),
  };

  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  const onClickCancel = () => {
    addAction(false);
    dispatch(setDrawingMap(false));
    dispatch(setDrawnCoords(null));
  };

  const onClickEdit = () => {
    addAction(true);
    dispatch(setDrawingMap(true));
    dispatch(setDrawnCoords(occurrence.geolocation));
  };

  const handlerSuccess = () => {
    onClickCancel();
    addFeedback({
      message: t("occurrences.occurrencesEdited"),
      severity: "success",
    });
    dispatch(setListDeletedMedia([]));
    refetch();
  };
  //ao submeter o formulário
  const onSubmit: SubmitHandler<IOccurrenceForm> = async (
    payLoad: IOccurrenceForm
  ) => {
    const result = formatDataEditForm(payLoad, listDeletedMedia);

    try {
      await editOccurrence({ data: result }).then(() => {
        handlerSuccess();
      });
    } catch (error) {}
  };

  useEffect(() => {
    addHeader(header);
  }, [occurrence, editForm, formIsValid, loadingEdit]);

  useEffect(() => {
    return () => {
      addAction(false);
    };
  }, []);

  return (
    <>
      <Loading show={loading} />
      {(error || !occurrence) && !loading && <NoData error={error} />}
      {!error && occurrence && !loading && (
        <>
          <Card sx={{ marginTop: 2 }}>
            <CardContent>
              <Typography variant="h3" gutterBottom>
                {t("common.details")}
              </Typography>
              <OccurrenceForm
                data={occurrence}
                formId={editFormId}
                editMode={editForm ? "edit" : "detail"}
                onFormSubmit={onSubmit}
                setFormIsValidStatus={getFormIsValidStatus}
              />
              {(occurrence.timeline?.steps.length > 0 ||
                occurrence.updates?.length > 0 ||
                occurrence.comments?.length > 0) && (
                <OccurrenceHistory occurrence={occurrence} />
              )}
            </CardContent>
          </Card>
        </>
      )}
    </>
  );
};
export default OccurrenceDetail;
