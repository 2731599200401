import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { InputText, RequiredField, useFeedback } from "@alb/live-lib";
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { useEffect } from "react";
import { ServiceApiUrl } from "services/ServiceApiUrl";

import useUpdate from "hooks/fetchData/useUpdate";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RequiredFields } from "components/Utils/RequiredField";

interface IPassword {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export default function ChangePassword(props: any) {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();

  const {
    loading,
    refetch: changePassword,
  } = useUpdate(ServiceApiUrl.resetPassword);

  const passwordLengths = {
    min: 5,
    max: 20,
  };
  const changePasswordSchema = yup
    .object({
      currentPassword: yup
        .string()
        .min(passwordLengths.min)
        .max(passwordLengths.max)
        .required(t("requiredField")),
      newPassword: yup
        .string()
        .min(passwordLengths.min)
        .max(passwordLengths.max)
        .required(t("requiredField")),
      confirmNewPassword: yup
        .string()
        .min(passwordLengths.min)
        .max(passwordLengths.max)
        .required(t("requiredField"))
        .oneOf([yup.ref("newPassword")], t("user.passwordMustMatch")),
    })
    .required();

  const methodsEdit = useForm<IPassword>({
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(changePasswordSchema),
  });

  const { handleSubmit, control, reset } = methodsEdit;

  useEffect(() => {
    reset();
    // eslint-disable-next-line
  }, [props.onClose]);

  const handlerSuccess = () => {
    props.onClose(true);
    addFeedback({
      message: t("feedback.success.changesSaved"),
      severity: "success",
    });
  };

  const submitEditForm: SubmitHandler<IPassword> = async (
    payLoad: IPassword
  ) => {
    const values = {
      old_password: payLoad.currentPassword,
      new_password: payLoad.newPassword,
    };
    await changePassword({ data: values }); // send datas
    handlerSuccess();
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={props.open}>
      <DialogTitle id="alert-dialog-title">
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("passwordRecovery.changePassword")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <FormProvider {...methodsEdit}>
          <form id="change-pwd-form" onSubmit={handleSubmit(submitEditForm)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RequiredField title={t("user.currentPassword")} />
                <InputText
                  name="currentPassword"
                  type="password"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <RequiredField title={t("user.newPassword")} />
                <InputText
                  name="newPassword"
                  type="password"
                  control={control}
                />
              </Grid>
              <Grid item xs={12}>
                <RequiredField title={t("user.confirmNewPassword")} />
                <InputText
                  name="confirmNewPassword"
                  type="password"
                  control={control}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        <RequiredFields allRequired />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={props.onClose}>
          {t("cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!methodsEdit.formState.isValid}
          type={"submit"}
          form={"change-pwd-form"}
        >
          {t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
