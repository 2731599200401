/* eslint-disable react-hooks/exhaustive-deps */
import { Loading } from "@alb/live-lib";
import { Grid, Typography, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { IDataLineChart } from "types/interfaces";

import { defaultOptions } from "components/charts/defaultOptions";
import { ReactECharts as BasicLineChart, IReactECharts } from "components/charts/ReactECharts";
import NoData from "components/Utils/NoData";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";

interface IBugaBikeVariationChart {
  data: IDataLineChart[];
  loading: boolean;
  error?: AxiosError<any, any> | null;
}

export const BugaBikeVariationChart = ({
  data,
  loading,
  error,
}: IBugaBikeVariationChart) => {
  const { t } = useTranslation();
  const theme = useTheme();

  function optionsLineChart(values: IDataLineChart[]) {
    let dataXAxis: string[] = [];
    const dataSeries = values.map((val: IDataLineChart) => {
      dataXAxis.push(formatDate(val.date, t("calendar.dateTimeFormatLocal")));
      return val.value;
    });
    return {
      tooltip: {
        confine: true,
        extraCssText: "white-space:inherit;",
        trigger: "axis",
        backgroundColor: theme.palette.background.paper,
        textStyle: {
          color:
            theme.palette.mode === "light" ? "" : theme.palette.common.white,
        },
      },
      textStyle: {
        fontFamily: theme.typography.fontFamily,
        fontSize: 13,
      },
      legend: {
        ...defaultOptions.legend,
        ...{
          textStyle: {
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            fontSize: "12px",
          },
        },
      },
      xAxis: {
        type: "category",
        axisTick: { show: false },
        data: dataXAxis,
        axisLine: {
          lineStyle: {
            color:
              theme.palette.mode === "light" ? "" : theme.palette.common.white,
          },
        },
      },
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: (val: string | number) => formatNumber(val),
        },
        axisLine: {
          lineStyle: {
            color:
              theme.palette.mode === "light" ? "" : theme.palette.common.white,
          },
        },
      },
      series: [
        {
          name: t("buga.stationsCard.legendVariationBikesChart"),
          type: "line",
          data: dataSeries,
        },
      ],
    } as IReactECharts["option"];
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} display={"flex"}>
          <Typography variant="h3" fontSize={"18px"}>
            {t("buga.stationsCard.titleVariationBikesChart")}
          </Typography>
          <Typography variant="h6">&nbsp;{"(" + t("average") + ")"}</Typography>
        </Grid>
        {!loading && data.length > 0 && (
          <Grid
            item
            xs={12}
            sx={{
              height: "500px",
              ">div": { height: "100%" },
            }}
          >
            <BasicLineChart option={optionsLineChart(data)} />
          </Grid>
        )}
        <Grid item xs={12} pr={1}>
          <Loading show={loading} />
          {!loading && (error || data.length === 0) && <NoData error={error} />}
        </Grid>
      </Grid>
    </>
  );
};
