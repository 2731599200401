import { getCookie } from "utils/cookie";
import { THEME } from "utils/keys";

function colorTextTheme(darkMode: boolean, color: string) {
  return darkMode ? "white" : color;
}

export function colorsStatusBuga(label: string) {
  const theme = getCookie(THEME);
  const darkMode = theme === "dark";
  switch (label) {
    case "operational":
    case "true":
      return {
        text: colorTextTheme(darkMode, "#06750C"),
        chip: "#3EE04733",
      };
    case "maintenance":
      return {
        text: colorTextTheme(darkMode, "#B77800"),
        chip: "#FFC04633",
      };
    case "false":
      return {
        text: colorTextTheme(darkMode, "#CF190D"),
        chip: "#FF594E33",
      };
    default:
      return {
        text: undefined,
        chip: undefined,
      };
  }
}
