/* eslint-disable react-hooks/exhaustive-deps */
import { IApiResponse, IRange, Loading } from "@alb/live-lib";
import { Box, useTheme } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IOccurrenceStatus, ISelectOption } from "types/interfaces";

import { defaultOptions } from "components/charts/defaultOptions";
import FilterSelect from "components/Utils/FilterSelect";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { arrayIsEmpty } from "utils/utils";

import { defaultOptionsOccurrences, textStyle } from "./utils/optionsOccurrences";

interface IValuesPieChar {
  name: string;
  value: number;
}

const ChartCategory = (props: {
  selectedRange: IRange | undefined;
  needToRefetch: boolean | undefined;
}) => {
  const { needToRefetch, selectedRange } = props;
  const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);
  const theme = useTheme();

  const { t } = useTranslation();
  const ALL_OPTION_KEY = "all";

  const allOption = { label: t("occurrences.all"), value: ALL_OPTION_KEY };
  const [occurrenceStatus, setOccurrenceStatus] = useState<{
    label: string;
    value: string;
  }>(allOption);

  //request to get total occurrences by category
  const { data, loading, error, refetch: refetchOccurrences } = useGet(
    ServiceApiUrl.occurrencesAllCategories,
    getParams(),
    undefined,
    headers
  );

  function getParams() {
		let params: any =  {
      ...(occurrenceStatus.value !== ALL_OPTION_KEY && {
        status: occurrenceStatus.value,
      }),
    };
		if (selectedRange?.startDate || selectedRange?.endDate) {
      params.record_date_interval = formatDate(selectedRange?.startDate, t("calendar.dateTimeFormat")) + "," + formatDate(selectedRange?.endDate, t("calendar.dateTimeFormat"))
		}
		return params;
	}

  //transform data to get only the ones with values
  // let occurrencesByCategories = data?.filter(
  //   (item: IValuesPieChar) => item.value > 0 && item.name !== "occurrences"
  // );

  function showValuesPie(allOccurrences: IValuesPieChar[]) {
    if (allOccurrences) {
      const SUMvalues = allOccurrences
        .map((element: IValuesPieChar) => element.value)
        .reduce((accumulator: number, currentValue: number) => {
          return accumulator + currentValue;
        }, 0);
      const dataHide = {
        // make an record to fill the bottom 50%
        value: SUMvalues,
        itemStyle: {
          // stop the chart from rendering this piece
          color: "none",
          decal: {
            symbol: "none",
          },
        },
        label: {
          show: false,
        },
      };
      return [
        {
          color: defaultOptions.color,
          name: t("occurrences.category"),
          type: "pie",
          startAngle: 180,
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: false,
            },
          },
          labelLine: {
            show: false,
          },
          data: [...allOccurrences, dataHide],
        },
      ];
    }
  }

  useEffect(() => {
    if(needToRefetch) {
      refetchOccurrences();
    }

  }, [needToRefetch])

  //chart options
  var option = {
    ...defaultOptionsOccurrences(theme),
    legend: {
      show: true,
      orient: "horizontal",
      top: 140,
      textStyle: textStyle(theme),
    },
    series: showValuesPie(data?.categories),
  };

  //on status change
  const handleSelectedStatus = (option: ISelectOption | null) => {
    if (!option) return undefined;
    setOccurrenceStatus(option);
  };

  const customOptions = (data: IApiResponse<IOccurrenceStatus>) => {
    let opt = data.data?.map((occurrence: IOccurrenceStatus) => ({
      label: occurrence?.name,
      value: occurrence?.id,
    }));
    return [{ label: t("occurrences.all"), value: ALL_OPTION_KEY }, ...opt];
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Box sx={{ height: "15%", display: "flex", alignItems: "center" }}>
        {occurrenceStatus && (
          <FilterSelect<IOccurrenceStatus>
            immediateRequest
            apiUrl={ServiceApiUrl.occurrencesStatus}
            onSelected={handleSelectedStatus}
            selectedValue={occurrenceStatus.value}
            customOptions={customOptions}
            othersConfig={headers}
            showLabel
            showBorder
          />
        )}
      </Box>

      <Box sx={{ height: "85%", display: "grid", alignItems: "center" }}>
        <Loading show={loading} />

        {arrayIsEmpty(data?.categories) && !loading && (
          <NoData error={error} />
        )}
        {!arrayIsEmpty(data?.categories) && !loading && (
          <ReactEcharts style={{ height: "100%" }} option={option} />
        )}
      </Box>
    </Box>
  );
};

export default ChartCategory;
