import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDevicesFilterParams } from "store/slices/adapterConfiguratorsSlice";
import { selectEventsFilterParams } from "store/slices/eventsSlice";
import { getSelectedMarkerType } from "store/slices/mapSlice";
import { selectOccurrencesFilterParams } from "store/slices/occurrencesSlice";
import { objectIsEmpty } from "utils/utils";

const useGetFiltersCount = () => {
  let mapDeviceParams = useSelector(selectDevicesFilterParams);
  let mapOccurrencesParams = useSelector(selectOccurrencesFilterParams);
  let mapEventsParams = useSelector(selectEventsFilterParams);
  const selectedType = useSelector(getSelectedMarkerType);

  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    let mapOccurrencesParamsAux = { ...mapOccurrencesParams }; //cria uma cópia dos params das ocorrencias originais
    //não deve considerar a data de inicio das ocorrencias na contagem dos filtros porque está fora da tab dos filtros.
    if (!objectIsEmpty(mapOccurrencesParamsAux)) {
      delete mapOccurrencesParamsAux["record_date"];
    }

    const allFiltersParams = {
      ...mapDeviceParams,
      ...mapOccurrencesParamsAux, //envia um auxiliar sem as datas para contagem
      ...mapEventsParams,
      ...(selectedType !== "all" && selectedType !== "visits" && { selectedType }),
    };

    Object.keys(allFiltersParams).forEach(
      (key) =>
        allFiltersParams[key] === undefined && delete allFiltersParams[key]
    );
    setFilterCount(
      !objectIsEmpty(allFiltersParams)
        ? Object.keys(allFiltersParams).length
        : 0
    );
  }, [mapDeviceParams, mapOccurrencesParams, mapEventsParams, selectedType]);

  return filterCount;
};

export default useGetFiltersCount;
