import { IRange } from "@alb/live-lib";
import WazeTypesChart from "./WazeTypesChart";

interface IWazeBarChart {
  selectedAdapter: string,
  selectedRange: IRange
}

const WazeBarChart = (props: IWazeBarChart) => {

  const {selectedAdapter, selectedRange} = props;

  return <><WazeTypesChart selectedAdapterKey={selectedAdapter} selectedRange={selectedRange} /></>
}

export default WazeBarChart;