import { Chip, Grid } from '@mui/material'
import React from 'react'
import { StatusColorOptions } from 'utils/utils'

interface IChipStatus {
  label: string
  color?: string
  sx?: any
}

export const ChipStatus = ({ label, color, sx }: IChipStatus) => {
  return (
    <Grid display={'flex'} alignItems={'center'} gap={1}>
      <Chip
        size="small"
        label={label}
        variant="filled"
        color={color ? (color as StatusColorOptions) : 'default'}
        sx={{ ...sx }}
      />
    </Grid>
  )
}
