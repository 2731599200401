import { Button, Dialog, DialogActions, DialogTitle, IconButton, Typography } from "@mui/material";
import GroupForm from "./GroupForm";
import { t } from "i18next";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { IGroupForm } from "types/interfaces";
import useCreate from "hooks/fetchData/useCreate";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useFeedback } from "@alb/live-lib";
import useUsersOptions from "components/occurrences/hooks/useUsersOptions";

const GroupAdd = (props: any) => {

    const addFormId = "add-group-form";
    const [ formIsValid, setFormIsValid] = useState(false);
    const { addFeedback } = useFeedback();

    //pedido para criar a grupo
    const { refetch: createGroup } = useCreate(
        ServiceApiUrl.groups,
        undefined,
        undefined
    );

  const handlerSuccess = () => {
    props.closeDialog(false); //fecha modal
    addFeedback({
      message: t("group.groupAdded"),
      severity: "success",
    });
    props.onSuccess && props.onSuccess();
  }

  const { usersOptions } = useUsersOptions()

    const onSubmit: SubmitHandler<IGroupForm> = async (
        payLoad: IGroupForm
    ) => {
        try {
            await createGroup({ data: payLoad });
            await handlerSuccess();
        } catch (error) {}
    }

        const getFormIsValidStatus = (status: boolean) => {
            setFormIsValid(status);
        };


    return(<>
        <Dialog open={true}>
            <DialogTitle>
				<Typography
                    component={"span"}
                    gutterBottom={true}
                    variant="h5"
                    noWrap
				>
					{t("group.createGroup")}
				</Typography>


				<IconButton
					aria-label="close"
                    onClick={() => props.closeDialog(false)}
					size="small"
					sx={{
					    position: "absolute",
						right: 10,
						top: 15,
						zIndex: 500,
					}}
					title={t("close")}
							>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

            <GroupForm
                formId={addFormId}
                onFormSubmit={onSubmit}
                setFormIsValidStatus={getFormIsValidStatus}
                usersOptions={usersOptions}
            />

            <DialogActions
              style={{
                paddingRight: "10px",
              }}
            >
                <Button variant="outlined" color="primary" onClick={() => props.closeDialog(false)}>
                    {t("cancel")}
                </Button>

                <LoadingButton
                    loading={false}
                    variant="contained"
                    color="primary"
                    type={"submit"}
                    disableElevation
                    disabled={!formIsValid}
                    form={addFormId}
                >
                    {t("common.add")}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>)
}

export default GroupAdd;
