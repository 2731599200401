import { useFeedback } from '@alb/live-lib'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import InviteForm from 'components/invite/InviteForm'
import Footer from 'components/ui/footer'
import useGet from 'hooks/fetchData/useGet'
import useUpdate from 'hooks/fetchData/useUpdate'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { FlexGridBaseline } from 'styles/css/components'

import backgroundImage from '../assets/images/live-urban/join_live_urban.svg'
import { ReactComponent as LogoCircle } from '../assets/images/live-urban/logo.svg'

const Invite = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { addFeedback } = useFeedback()
  const [success, setSuccess] = useState<boolean>(false)
  const editFormId = 'reset-pwd-form'

  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || ''

  const {
    data: user,
    error,
  } = useGet(ServiceApiUrl.validateTokenURL, { token })

  const headersAdd = {
    headers: {
      Token: token,
    },
  }

  useEffect(() => {
    if (success) {
      addFeedback({
        message: t('invite.messageSuccessConfirmInvitation'),
        severity: 'success',
      })
      navigateToLogin()
    }
    // eslint-disable-next-line
  }, [success])
  //pedido para criar confirmar
  const { loading: loadingSetPwd, refetch: confirmUSer } = useUpdate(
    ServiceApiUrl.setPwdURL,
    '',
    headersAdd
  )

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<any> = async (payLoad: any) => {
    var passwordSubmit = {
      password: payLoad.password,
    }
    await confirmUSer({ data: passwordSubmit })
    await setSuccess(true)
  }

  const navigateToLogin = () => navigate('/login')

  return (
    <div>
      <Container sx={{ paddingTop: 8, flex: ' 1' }}>
        {/* <LiveLoading show={loading} />*/}
        {error && (
          <Box>
            <Box>
              <LogoCircle width={'40%'} />
              <Typography
                sx={{ padding: '3% 0' }}
                color="secondary"
                variant="h4"
              >
                {t('errors.somethingWentWrong')}
              </Typography>

              <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                {t('invite.invalidInvitedTokenText')}
              </Typography>
            </Box>
            <Stack marginTop={2} alignItems={'flex-start'}>
              <Button
                color="primary"
                type="button"
                variant="outlined"
                startIcon={<ArrowBackRoundedIcon />}
                onClick={navigateToLogin}
              >
                {t('goBack')}
              </Button>
            </Stack>
          </Box>
        )}
        {user && !error && (
          <>
            <InviteForm
              data={user}
              formId={editFormId}
              onFormSubmit={formSubmitHandler}
            />
            <Grid container mt={4} justifyContent={'flex-start'}>
              <Grid item>
                <LoadingButton
                  loading={loadingSetPwd}
                  variant="contained"
                  color="secondary"
                  disabled={false}
                  type={'submit'}
                  form={editFormId}
                >
                  {t('invite.join')}
                </LoadingButton>
              </Grid>
            </Grid>
            <FlexGridBaseline gap={'0.3rem'} mt={2}>
              <Typography variant="body1">
                {t('invite.alreadyHaveAccount')}
              </Typography>
              <Typography
                color="secondary"
                variant="body1"
                className="clickNavigateLogin"
                onClick={navigateToLogin}
              >
                {t('invite.clickToEnter')}
              </Typography>
            </FlexGridBaseline>
          </>
        )}
      </Container>

      <Footer backgroundImage={backgroundImage} maxWidth={'lg'} />
    </div>
  )
}

export default Invite
