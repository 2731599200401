import AccountsView from 'components/management/accounts/AccountsView'

function AccountsViewPage() {
  return (
    <>
      <AccountsView />
    </>
  )
}

export default AccountsViewPage
