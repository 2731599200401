/* eslint-disable react-hooks/exhaustive-deps */
import {
  InputCheckbox,
  InputText,
  RequiredField,
  arrayIsEmpty,
} from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { RequiredFields } from "components/Utils/RequiredField";
import React, { Fragment, useEffect, useMemo, useRef } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formDefaultValues } from "./formDefaultValues";
import { getAnalysisAddFormSchema } from "./validations";
import { useSelector } from "react-redux";
import { getGeojsonFilesUpload } from "store/slices/mapSlice";
import { IGeojsonFeatures, IAnalysisFilesForm, IAnalysisForm } from "types/interfaces";
import { TAnalysis } from "../AnalysisTab";

export interface IAnalysisFormProps {
  formId: string;
  data?: IAnalysisForm | TAnalysis;
  onFormSubmit: (payLoad: IAnalysisForm) => void;
  setFormIsValidStatus?: (status: boolean) => void;
  edit?:boolean
}

export const AnalysisForm = ({
  formId,
  data,
  onFormSubmit,
  setFormIsValidStatus,
  edit,
}: IAnalysisFormProps) => {
  const { t } = useTranslation();

  let fileInputRef = useRef<HTMLInputElement | null>(null);

  const multiFilesUpload = useSelector(getGeojsonFilesUpload);

  let filesCheckbox = { files: {} };
  let filesNames = useMemo(
    () =>
      multiFilesUpload?.map((file: IGeojsonFeatures) => {
        filesCheckbox.files = {
          ...filesCheckbox.files,
          [file.id]: file.visible,
        };
        return {
          id: file.id,
          name: file.name,
          checked: file.visible,
          custom_file: !!file.hasOwnProperty("filters"),
        };
      }),
    [multiFilesUpload]
  );

  //guarda os ficheiros que tem o filter.
  const ourFiles = multiFilesUpload
    ?.filter((file: IGeojsonFeatures) => file.hasOwnProperty("filters"))
    .map((f: IGeojsonFeatures) => f.id);

  const defaultValues = formDefaultValues(filesCheckbox, data);

  const methodsForm = useForm<IAnalysisForm>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(getAnalysisAddFormSchema(edit)),
  });

  const { control, handleSubmit, setValue, reset, watch, formState: { isDirty} } = methodsForm;

  let filesValues = watch("files");

  let checkedFiles =
    filesValues &&
    Object.entries(filesValues).filter(([key, value]) => value === true);

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IAnalysisForm> = (
    payLoad: IAnalysisForm
  ) => {
    onFormSubmit(payLoad);
    clearCachedFiles();
  };

  const clearCachedFiles = () => {
    //clear cached files
    if (fileInputRef?.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.files = null;
    }
  };

  useEffect(() => {
    if (multiFilesUpload && !arrayIsEmpty(multiFilesUpload)) {
      reset(defaultValues);
    }
  }, [multiFilesUpload]);

  useEffect(() => {
    if (setFormIsValidStatus) {
      setFormIsValidStatus(methodsForm.formState.isValid && isDirty);
    }
  }, [setFormIsValidStatus, methodsForm.formState.isValid, isDirty]);

  const onchangeChecks = (
    file: IAnalysisFilesForm,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e?.target?.checked;
    // permitir apenas 1 custom file com o checked a true
    if (newValue && file.custom_file) {
        //percorre todos os ourFiles e coloca-os a false, menos o que foi alterado agora!
        ourFiles?.forEach((element: string) => {
          //se o file for diferente do que foi alterado agora, coloca a false!!!!
          if (element !== file.id) {
            //coloca este input/check a false!
            setValue(`files.${element}`, false);
          }
        });
    }
  };

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RequiredField title={t("analysis.analysisName")} />
              <InputText
                control={control}
                name="name"
                placeholder={t("analysis.analysisNamePlaceholder")}
              />
            </Grid>
            {!edit &&
            <Grid item xs={12}>
              <RequiredField
                title={t("analysis.files", { count: multiFilesUpload?.length || 0 })}
                sx={{
                  verticalAlign: "middle",
                  display: "inline-flex",
                  gap: 0.5,
                }}
              />
              <br />
              {
                filesNames &&
                filesNames?.length > 0 &&
                filesNames?.map((file: IAnalysisFilesForm) => (
                  <Fragment key={file.id}>
                    <InputCheckbox
                      onChange={(e) => onchangeChecks(file, e)}
                      control={control}
                      name={`files.${file.id}`}
                      labelText={file.name}
                      setValue={setValue}
                      checked={file.checked}
                      disabled={
                        checkedFiles?.length === 1 &&
                        checkedFiles[0][0] === file.id
                      }
                    />
                    <br />
                  </Fragment>
                ))}
            </Grid>
           }
          </Grid>
        </form>
      </FormProvider>
      <RequiredFields />
    </>
  );
};
