import { InputText, RequiredField } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { RequiredFields } from "components/Utils/RequiredField";
import React, { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { formDefaultValues } from "./formDefaultValues";
import { getEventStatusFormSchema } from "./validations";

export interface IEventStatusForm {
  id: string;
  name: string;
  color: string;
}

export interface IStatusForm {
  formId: string;
  data?: IEventStatusForm;
  editForm?: boolean;
  onFormSubmit: (payLoad: IEventStatusForm) => void;
  setFormIsValidStatus?: (status: boolean) => void;
}

const StatusForm = ({
  formId,
  data,
  editForm,
  onFormSubmit,
  setFormIsValidStatus,
}: IStatusForm) => {
  const { t } = useTranslation();

  const defaultValues = formDefaultValues(data);

  const methodsForm = useForm<IEventStatusForm>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(getEventStatusFormSchema()),
  });

  const { control, handleSubmit } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IEventStatusForm> = (
    payLoad: IEventStatusForm
  ) => {
    onFormSubmit(payLoad);
  };

  useEffect(() => {
    if (setFormIsValidStatus)
      setFormIsValidStatus(methodsForm.formState.isValid);
  }, [setFormIsValidStatus, methodsForm.formState.isValid]);

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RequiredField title={t("common.name")} />
              <InputText
                control={control}
                name="name"
                placeholder={t("events.statusEvent.statusNamePlaceholder")}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("events.statusEvent.color")}
              </Typography>

              <InputText
                control={control}
                name="color"
                placeholder={t("events.statusEvent.statusColorPlaceholder")}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <RequiredFields />
    </>
  );
};
export default StatusForm;
