import { Card, CardContent, Grid, Typography } from "@mui/material";
import WazeMap from "./WazeMap";
import { IRange } from "@alb/live-lib";
import WazeBarChart from "./WazeBarChart";
import { useTranslation } from "react-i18next";
import WazeLineChart from "./WazeLineChart";
import { WazeAdapters } from "pages/Waze";

interface IWazeDashBoardProps {
  filterRange: IRange,
  selectedWazeAdapter:  string,
}

const WazeDashboard = (props: IWazeDashBoardProps) => {
  
  const { filterRange, selectedWazeAdapter } = props
  const { t } = useTranslation()

  function getTitleChartCard(adapterKey: string) {
    switch (adapterKey) {
      case WazeAdapters.alerts:
        return t("waze.totalTypeChart");
      case WazeAdapters.jams:
        return t("waze.totalJamsChart");
      default:
        return "";
    }
  }

  function getTitleLineChartCard(adapterKey: string) {
    switch (adapterKey) {
      case WazeAdapters.alerts:
        return t("waze.typeChart");
      case WazeAdapters.jams:
        return t("waze.jamsChart");
      default:
        return "";
    }
  }

  return <>
    <Grid container>
      <Grid item md={6} sm={6} sx={{ paddingRight: '20px'}}>
        <Card sx={{ height: '100%' }}>
          <CardContent sx={{height: '100%'}}>
            <Typography variant="h3" fontSize={"22px"}>
              {getTitleLineChartCard(selectedWazeAdapter)}
            </Typography>
            <WazeLineChart selectedAdapter={selectedWazeAdapter} selectedRange={filterRange} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} sm={6} height={'50%'}>
        <Card 
          className="map-component"
          sx={{
            height: "350px",
            ".leaflet-container": {
              borderRadius: "12px",
            },
            "& .map-wraper": {
              margin: "0 !important"
            }
          }}
          >
          {selectedWazeAdapter === WazeAdapters.jams && <WazeMap filterRange={filterRange} selectedWazeAdapter={selectedWazeAdapter}  />}
          {selectedWazeAdapter === WazeAdapters.alerts && <WazeMap filterRange={filterRange} selectedWazeAdapter={selectedWazeAdapter}  />}
        </Card>
        <Card sx={{ marginTop: '20px' }}>
          <CardContent>
            <Typography variant="h3" fontSize={"22px"}>
              {getTitleChartCard(selectedWazeAdapter)}
            </Typography>
            <WazeBarChart selectedAdapter={selectedWazeAdapter} selectedRange={filterRange} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>
}

export default WazeDashboard;