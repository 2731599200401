import React from 'react'
import ReactDOM from 'react-dom/client'

import { CookiesProvider } from 'react-cookie';

import { Provider } from 'react-redux'
import { store } from './store/store'

import App from './App'
import { LayoutProvider } from '@alb/live-lib'

import './styles/main.scss'
import './i18n/config'
import "@alb/live-lib/index.css"

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<CookiesProvider>
			<Provider store={store}>
				<LayoutProvider>
					<App />
				</LayoutProvider>
			</Provider>
		</CookiesProvider>
	</React.StrictMode>
)
