import { ILoginFormInput, Login as LoginUI, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { AxiosError } from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ILoginRes } from "types/interfaces";
import * as yup from "yup";

import { InputsLengths } from "components/recover-password/validations";
import useErrorMessage from "hooks/useErrorMessage";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { setAuth } from "store/slices/authSlice";
import { api as axios } from "utils/axios";
import { emailRegex } from "utils/regex";

import loginBG from "../assets/images/live-urban/login_bg.svg";
import logo from "../assets/images/live-urban/logo.svg";
import { fetchLastNotifications } from "store/slices/notificationsSlice";

function Login() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const { showError } = useErrorMessage();
	const { removeFeedback } = useFeedback();

	const [isLoading, setIsLoading] = useState<boolean>(false);

	// --------- recover password component ------------ //
	const onForgotClick = () => {
		navigate("/forgot")
	}

	// ------------ login component --------------- //
	const schemaLogin = yup
		.object()
		.shape({
			email: yup
				.string()
				.email(t("loginForm.emailRule"))
				.required(t("loginForm.emailRequired"))
				.matches(emailRegex, t("passwordRecovery.emailRule")),
			password: yup
				.string()
				.max(InputsLengths.max.password)
				.min(InputsLengths.min.password)
				.required(t("loginForm.passwordRequired")),
		})
		.required();

	async function onSubmitLogin(data: ILoginFormInput) {
		setIsLoading(true);

		// store auth info and redirect into app
		await axios
			.post<ILoginRes>(ServiceApiUrl.login, data).then((res) => {

				i18n.changeLanguage((res.data.user.language as string).toLowerCase(), () => {
					removeFeedback(); // remove feedback notification

					dispatch(setAuth(res.data));

					// notifications
					dispatch(fetchLastNotifications())

					navigate("/map", { replace: true }); // redirect into the app
				})

			}).catch((e: AxiosError) => {
				showError(e)
			})
		setIsLoading(false);
	}

	return (
		<Grid container component="main" sx={{ height: "100vh" }}>
			<Grid
				item
				xs={false}
				sm={8}
				md={6}
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<LoginUI
					logo={logo}
					alt="live!URBAN"
					title={t("loginForm.welcome")}
					recoverLabel={t("passwordRecovery.recoverPassword")}
					buttonLabel={t("loginForm.login")}
					validator={yupResolver(schemaLogin)}
					onSubmit={onSubmitLogin}
					loadingSubmit={isLoading}
					onClick={onForgotClick}
				/>
			</Grid>
			<Grid
				item
				xs={false}
				sm={4}
				md={6}
				sx={{
					backgroundImage: `url(${loginBG})`,
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "right",
				}}
			/>
		</Grid>
	);
}

export default Login;
