import { LayoutContext } from "@alb/live-lib";
import { Card, CardContent, Grid } from "@mui/material";

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";

import StreetLightingWidget from "components/executive-cards/energy/StreetLightingWidget";
import PublicBuildingsWidget from "components/executive-cards/energy/PublicBuildingsWidget";
import ConsumptionBTWidget from "components/executive-cards/energy/ConsumptionBTWidget";
import Temperature from "components/executive/meteorology/temperature";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";

const Executive = () => {
  const { t } = useTranslation();
  // ------ permissions
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.MODULES.EXECUTIVE.READ);

  // ------ header page
  const header = {
    title: t("executive.title"),
  };
  const { addHeader } = useContext(LayoutContext);

  function idAdapter(domainWidget: any) {
    let id: any;
    if (adapterConfigurators) {
      id = adapterConfigurators.data.filter(
        (item: any) => item.domain === domainWidget
      );
      return id[0].id;
    }
  }

  const { data: adapterConfigurators } = useGet(
    ServiceApiUrl.adapterConfigurators
  );

  const cardsList = [
    { description: "", domain: "Meteo", unit: "", type: "Ambiente" },
    {
      description: "Somatório mensal (últimos 6 meses)",
      domain: "ipEnergy",
      unit: "MWh",
      type: "Energia",
    },
    {
      description: "Somatório mensal (últimos 6 meses)",
      domain: "ptdEnergy",
      unit: "MWh",
      type: "Energia",
    },
    {
      description: "Somatório por freguesia (último mês)",
      domain: "consumptionEnergy",
      unit: "MWh",
      type: "Energia",
    },
  ];

  //assign the right widget to domain
  let domainWidget: { [domain: string]: JSX.Element } = {
    ipEnergy: <StreetLightingWidget />,
    ptdEnergy: <ConsumptionBTWidget />,
    consumptionEnergy: <PublicBuildingsWidget />,
    Meteo: <Temperature idAdapter={idAdapter("Meteo")} />,
    //add the rest here...
  };

  const returnWidget = (domain: string) => {
    return domainWidget[domain];
  };

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {canREAD && (
        <>
          <Grid
            mb={3}
            container
            columnSpacing={5}
            rowSpacing={3}
            mt={4}
          >
            {cardsList.map((card: any, index: number) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={card.domain === "Meteo" ? 8 : 4}
                lg={card.domain === "Meteo" ? 8 : 4}
                key={index}
              >
                <Card>
                  <CardContent>
                    <>{returnWidget(card.domain)}</>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
};

export default Executive;
