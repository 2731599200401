import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import useGet from "hooks/fetchData/useGet";
import { useEffect, useState } from "react";
import { ServiceApiUrl } from "services/ServiceApiUrl";

const useUsersOptions = (moduleID?: string) => {
  const [usersOptions, setUsersOptions] = useState<TOption[]>([]);

  function GetParams() {
    var params: { module?: string } = {
      ...(moduleID && { module: moduleID }),
    };
    return params;
  }

  const { data: allUsers, loading } = useGet(ServiceApiUrl.user, GetParams());

  useEffect(() => {
    if (allUsers && allUsers !== undefined) {
      const usersOptionsSelect = allUsers?.data?.map((p: any) => ({
        label: ` ${p.first_name} ${p.last_name} - (${p.username})`,
        value: p.id,
      }));

      setUsersOptions(usersOptionsSelect);
    }
  }, [allUsers]);

  return { usersOptions, loading };
};

export default useUsersOptions;
