import { IApiResponse } from "@alb/live-lib";
import { INotification } from "@alb/live-lib/templates/sidebar/components/SidebarNotificationItem";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLastNotifications, getLastNotifications, getReloadLastNotifications } from "store/slices/notificationsSlice";
import { INotificationAPI } from "types/interfaces";
import { useNotificationsUtils } from "./useNotificationsUtils";
import { useTranslation } from "react-i18next";

export const useGetLastNotifications = () => {
	const { i18n } = useTranslation();

	const dispatch = useDispatch();
	const lastNotificationsResponse: IApiResponse<INotificationAPI> = useSelector(getLastNotifications);
	const reloadLastNotifications: boolean = useSelector(getReloadLastNotifications);
	const { convertNotification } = useNotificationsUtils();

	const [lastNotifications, setLastNotifications] = useState<INotification[]>([]);
	useEffect(() => {
		if (!lastNotificationsResponse) return;

		const _lastNotifications: INotification[] = [];
		lastNotificationsResponse?.data.forEach((notificationAPI: INotificationAPI) => {
			const notification = convertNotification(notificationAPI);
			_lastNotifications.push(notification);
		});

		setLastNotifications(_lastNotifications)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastNotificationsResponse, i18n.language])

	useEffect(() => {
		if (!reloadLastNotifications) return;
		dispatch(fetchLastNotifications());

	}, [reloadLastNotifications, dispatch])

	return { lastNotifications, count: lastNotificationsResponse?.totalCount || 0 };
};
