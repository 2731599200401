import { TableCell, TableRow } from "@mui/material";
import { useEffect, useState } from "react";

const OpenDataTableRow = (props: any) => {

    //let cells: any = [];
    const [cells, setCells] = useState<any>([]);

    
    
    useEffect(() => {
        if(props.content) {
            setCells(props.content.map((s: string, i: number) => {
                return <TableCell key={i}>{s}</TableCell>
            }))
        }
    }, [props.content])

    return(<>
        <TableRow
            sx={{ cursor: "pointer", height: 44 }}
        >
            {cells}
        </TableRow>
    </>)
}

export default OpenDataTableRow;