import { Stack } from '@mui/material'
import React from 'react'
import QArtMapStreamsFilter from './QArtMapStreamsFilter'
import QArtMapStreamsLegend from './QArtMapStreamsLegend'

export interface IQArtMapStreamsContainer {
	onChange: (filter: string) => void
}

function QArtMapStreamsContainer({onChange}: IQArtMapStreamsContainer) {
	return (
		<Stack direction={'column'} spacing={1} sx={{minWidth:150}}>
			<QArtMapStreamsFilter onChange={onChange} />
			<QArtMapStreamsLegend />
		</Stack>
	)
}

export default QArtMapStreamsContainer
