import { LayoutContext } from '@alb/live-lib';
import AddIcon from "@mui/icons-material/Add";
import { Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUserPermission } from 'types/interfaces';
import { TModule } from 'types/types';

import UsersAdd from 'components/management/users/UsersAdd';
import UsersList from 'components/management/users/UsersList';
import useGet from 'hooks/fetchData/useGet';
import usePermission from 'hooks/usePermission';
import { ServiceApiUrl } from 'services/ServiceApiUrl';
import { PERMISSIONS } from 'utils/permissions/permissions';

const Users = () => {
	// PERMISSIONS
	const { hasPermission } = usePermission();
	const crudPermissions = hasPermission(PERMISSIONS.MANAGEMENT.CREATE);
	const { t } = useTranslation();
	const header = {
		title: t("pages.users.headerText"),
		action:
			crudPermissions && (
				<Button
					variant="contained"
					disableElevation
					startIcon={<AddIcon />}
					onClick={() => addAction(true)}
				>
					{t("user.inviteUser")}
				</Button>
			),
	}

	const { addHeader, addAction, action } = useContext(LayoutContext)
	const [added, setAdded] = useState<boolean>(false);

	useEffect(() => {
		addHeader(header)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { data: allModules } = useGet(ServiceApiUrl.modules, null,
		{
			manual: !crudPermissions,
		});

	const [permissions, setPermissions] = useState<IUserPermission[]>([]);
	useEffect(() => {
		if (allModules) {
			const perm = allModules?.data.map((m: TModule) => ({
				module_name: m.name,
				module_id: m.id,
				value: "0000",
			}));
			setPermissions(perm);
		}
	}, [allModules]);

	// useEffect(() => {
	// 	if (action) {
	// 		refetchModules()
	// 	}
	// }, [action]);

	let userAdd = null;
	if (crudPermissions && action) {
		userAdd = (<UsersAdd
			allModules={allModules}
			permissions={permissions}
			open={action}
			onClose={() => addAction(false)}
			updateList={() => {
				addAction(false);
				setAdded(true);
			}} />
		);
	}

	return (
		<>
			<UsersList refetch={added} />
			{userAdd}
		</>
	)
}

export default Users
