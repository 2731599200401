import { BasicList, IItemList, Loading } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography } from "@mui/material";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ISelectOption } from "types/interfaces";
import { TMarker } from "types/types";

import NoData from "components/Utils/NoData";
import { CustomTablePagination } from 'components/Utils/Pagination/CustomTablePagination'
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getDeviceDefaultMapList, getDevicesSelectedAdapter, getSelectedAdapterFilter, getSelectedDeviceId, getTextSearchBar, setDevice, setDeviceDefaultMapList, setDevicesAdapter, setSelectedDeviceId } from "store/slices/dashboardSlice";
import { arrayIsEmpty } from "utils/utils";

const ListDevices = (props: {
  adapter?: ISelectOption;
  deviceSelectedMap?: string | null;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [idPage, setIdPage] = useState<string>("");

  const devices: TMarker[] = useSelector(getDevicesSelectedAdapter);
  const deviceDefault: IItemList = useSelector(getDeviceDefaultMapList);
  const selectedDeviceID = useSelector(getSelectedDeviceId);
  // para o select --> armazena o que está selecionado
  const selectedAdapter = useSelector(getSelectedAdapterFilter);
  const textSearch = useSelector(getTextSearchBar); //searchbar text
  //Devices
  const { data: filteredDevices, loading, error, refetch } = useGet(
    ServiceApiUrl.devices,
    GetParams(),
    {
      manual: true,
    }
  );

  function GetParams() {
    var params: {
      page?: number;
      items?: number;
      adapters_id: string;
      device_id?: string;
    } =
      selectedDeviceID && page === 1
        ? {
          adapters_id: selectedAdapter.value,
          device_id: selectedDeviceID,
          //só envia a page se não tiver sido filtrado nada por pesquisa
          ...(textSearch?.length > 0
            ? { contains: textSearch }
            : {
              page: page,
              items: 6,
            }),
        }
        : {
          adapters_id: selectedAdapter.value,
          ...(textSearch?.length > 0
            ? { contains: textSearch }
            : {
              page: page,
              items: 6,
            }),
        };
    return params;
  }

  function handleClickItemList(device: IItemList) {
    dispatch(setDeviceDefaultMapList(device));
    const chosenDevice = devices.find((d) => d.id === device.idItem);
    dispatch(setDevice(chosenDevice));
    if (chosenDevice) dispatch(setSelectedDeviceId(chosenDevice.id));
  }

  // quando se altera de adapter o valor de default da lista de devices também deve mudar
  // useEffect(() => {
  //   debugger
  //   dispatch(setDeviceDefaultMapList(devices[0]));
  // }, [devices]);


  const debounceData = debounce((cb) => {
    cb();
  }, 400);
  const sendRequestAdapterDevices = async () => {
    debounceData(async () => {
      const res = await refetch();
      if (res?.data?.data[0] && page === 1 && textSearch?.length === 0) {
        const firstDevice = res?.data?.data[0]
        dispatch(setDeviceDefaultMapList({title:firstDevice?.title, idItem: firstDevice?.id}));
        dispatch(setDevice(res?.data?.data[0]));
        dispatch(setSelectedDeviceId(res?.data?.data[0].id));
      }
    })
  };

  useEffect(() => {
    setPage(1);
    sendRequestAdapterDevices();
  }, [selectedAdapter]);

  useEffect(() => {
    // if (page !== 1) {
    sendRequestAdapterDevices();
    // }
    setIdPage(page.toString())
  }, [page]);


  useEffect(() => {
    sendRequestAdapterDevices();
    if (textSearch.length === 0) {
      setPage(1);
    }
  }, [textSearch]);


  useEffect(() => {

    if (filteredDevices) {
      const devicesSelectAdpt11 = filteredDevices?.data;
      if (devicesSelectAdpt11) {
        //devices resultados do pedido
        let newOptions = devicesSelectAdpt11;
        if (page > 1 && textSearch.length === 0) {
          // newOptions = [...devices, ...devicesSelectAdpt11];
          newOptions = [...devicesSelectAdpt11];
        }
        dispatch(setDevicesAdapter(newOptions as TMarker[]));
      }
      // quando se entra pela primeira vez no dashboard
      if (props.deviceSelectedMap === null && devicesSelectAdpt11) {
        dispatch(setDevice(devicesSelectAdpt11[0]));
      }

      // // buscar o primeiro device quando se altera para um novo adapater
      // if (
      //   selectedDevice &&
      //   devicesSelectAdpt11 &&
      //   !devicesSelectAdpt11?.find((device: TDevice) => device.id === devicesSelectAdpt11.id)
      // ) {
      //   dispatch(setDevice(devicesSelectAdpt11[0]))
      // }
      // buscar o primeiro device quando se altera para um novo adapater
      if (props.deviceSelectedMap) {
        const getDeviceSelelct = filteredDevices?.data.find(
          (device: TMarker) => device.id === props.deviceSelectedMap
        );
        dispatch(setDevice(getDeviceSelelct));
      }
    }
  }, [filteredDevices]);


  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage + 1)
  }


  const convertIntoItemList = (data:TMarker[]) =>{ 
    const itemList: IItemList[] =  data.map(element => (
      {
        title: element?.title,
        idItem: element?.id
      } 

    ));
    return itemList;
  }

  return (
    <>
      <Box sx={{ padding: 3, width: "100%" }}>
        <Grid container direction="row" alignItems="baseline" gap={2}>
          {!loading && !arrayIsEmpty(filteredDevices?.data) && <Typography variant="subtitle1">
            {t("dashboard.deviceList")}
          </Typography>}
        </Grid>
        {textSearch.length > 0 && arrayIsEmpty(filteredDevices?.data) && !loading && (
          <NoData error={error} />
        )}
        <Loading show={loading} />
        {!loading && !arrayIsEmpty(filteredDevices?.data) && (
        <>
        <BasicList
          sx={{ height: 290, overflow: "auto", maxHeight: 290, paddingTop: 0, paddingBottom: 0 }}
          handleClickItem={handleClickItemList}
          items={
            textSearch.length === 0 && devices
              ? convertIntoItemList(devices)
              : !arrayIsEmpty(filteredDevices?.data)
              ? convertIntoItemList(filteredDevices?.data)
              : []
          }
          selectedItem={deviceDefault}
        // onScroll={handleOnScroll}
        />

        {!arrayIsEmpty(filteredDevices?.data) && (
          <CustomTablePagination
            key={idPage}
            count={filteredDevices?.totalCount}
            page={page}
            totalPages={filteredDevices?.totalPages}
            onPageChange={handleChangePage}
            itemsPage={6}
          />
        )}
        </>
         )}
      </Box>
    </>
  );
};

export default ListDevices;
