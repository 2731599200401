import { Theme } from "@mui/material";
import { objectIsEmpty } from "utils/utils";
import { formatNumber } from "utils/number";

interface IValTooltip {
  name: string;
  value: string;
}

export function defaultOptionsOccurrences(
  theme: Theme,
  othersValsTooltip: IValTooltip | undefined = undefined
) {
  return {
    tooltip: {
      confine: true,
      extraCssText: "white-space:inherit;",
      trigger: "item",
      formatter: function (value: any) {
        const information =
          othersValsTooltip && !objectIsEmpty(othersValsTooltip)
            ? {
                name: value[othersValsTooltip.name],
                value: value[othersValsTooltip.value],
              }
            : {
                name: value.name,
                value: value.data.value,
              };
        return `<b>${information.name}</b>: ${formatNumber(
          information.value
        )}<br />`;
      },
      backgroundColor: theme.palette.background.paper,
      textStyle: {
        color: theme.palette.greyColor,
      },
    },
    textStyle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 13,
    },
  };
}

export function textStyle(theme: Theme) {
  return {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
  };
}
