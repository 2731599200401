import { Navigate, useLocation } from "react-router-dom";

import usePermission from "hooks/usePermission";


interface IAllowedPermission {
  permission: number,
  element: JSX.Element
}

const AllowedPermission = ({permission, element}: IAllowedPermission) => {
  const location = useLocation();
  const state = { from: location };

  const { hasPermission } = usePermission();
  const isAllowed = hasPermission(permission)

  return (
    isAllowed
    ? element
    : <Navigate to="/unauthorized" state={state} replace />
  );
};

export default AllowedPermission;
