/* eslint-disable react-hooks/exhaustive-deps */
import { arrayIsEmpty, getColorTrafficWaze, IRange, Loading } from "@alb/live-lib";
import { Box, useTheme } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { colors as defaultChartColors } from "components/charts/defaultOptions";
import { IReactECharts } from "components/charts/ReactECharts";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { WazeAdapters } from "pages/Waze";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";

interface IWazeLineChartProps {
  selectedAdapter: string, 
  selectedRange: IRange
}

const WazeLineChart = (props: IWazeLineChartProps) => {

  const { selectedAdapter, selectedRange } = props;
  const [ chartOptions, setChartOptions ] = useState<IReactECharts["option"]>({})
  const theme = useTheme()
  const { t } = useTranslation();
  const isDarkMode = theme.palette.mode === 'dark'

  const { data, loading, error } = useGet(
    ServiceApiUrl.wazeTimeSeriesChart(selectedAdapter),
    getParams()
  );

  function getParams() {
    let params: { from?: Date; to?: Date } = {
      ...(selectedRange?.startDate && { from: selectedRange.startDate }),
      ...(selectedRange?.endDate && { to: selectedRange.endDate }),
    };
    return params;
  }

  const getLineChartOptions = (values: any) => {

    return {
      tooltip: {
        confine: true,
        extraCssText: "white-space:inherit;",
        backgroundColor: theme.palette.background.paper,
        trigger: "axis",
        textStyle: {
          color: theme.palette.text.primary,
        },
      },
      legend: {
        bottom: 0,
        data: values.values.map((value: {name: string | number}) => selectedAdapter === 'jams' ? t("waze.jamsLevel." + value.name) : t(`waze.types.${value.name}`)),
        textStyle: {
          color: isDarkMode ? '#fff' : "#000"
        },
      },
      xAxis: {
        type: 'category',
        data: values.dates.map((e: Date) => formatDate(e, t("calendar.dateFullFormat"))),
        axisLabel: {
          color: isDarkMode ? "#fff" : undefined
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: isDarkMode ? "#fff" : undefined
        }
      },
      series: values.values.map((e: {name: string, data: number[] }, index: number) => {
        return {
          ...e,
          name: selectedAdapter === WazeAdapters.jams ? t("waze.jamsLevel." + e.name) : t(`waze.types.${e.name}`),
          type: 'line',
          smooth: true,
          color: selectedAdapter === WazeAdapters.jams ? getColorTrafficWaze(e.name.toString(), theme.palette.mode === 'dark') : defaultChartColors[index],
          symbol: 'circle',
          symbolSize: 8,
          //stack: 'Total'
        }
      })
    }
  }

  useEffect(() => {
    if(data) {
      setChartOptions(getLineChartOptions(data) as IReactECharts["option"])
    }
  }, [data])

  useEffect(() => {
    setChartOptions({})
  }, [selectedAdapter, selectedRange])


  return <>
    <Box display={'flex'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
      <Loading show={loading} />
      {data && !error && !arrayIsEmpty(data.dates) && !loading && (
        <>
          <ReactEcharts 
            option={chartOptions} 
            style={{ height: '500px', width: '100%' }}
          />
        </>
      )}
      {!loading && (error || arrayIsEmpty(data.dates)) && <NoData error={error} />}
    </Box>  
  </>
}

export default WazeLineChart;