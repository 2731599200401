import { TableHeadCell } from "@alb/live-lib";
import { TableHeadSort } from "@alb/live-lib";
import { Table, TableBody, TableContainer } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IHydrogenSupply, IResultTable } from "types/interfaces";

import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import { arrayIsEmpty } from "utils/utils";

import TableInformationRow from "./TableInformationRow";

const TableInformation = (props: IResultTable<IHydrogenSupply>) => {
  const { t } = useTranslation();
  const {
    data: supply,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    page,
    setPage,
  } = props;

  //vou criar um array que só vai criar opções consoante os objetos que existem
  let tableHeads: TableHeadCell[] = []; 
  //vai carregar table heads consoante o que for enviado
  Object.keys(supply?.data?.[0]).forEach(function (key) {

    //campos a ignorar e não mostrar na tabela, para já
    if (key === "production_id") return;
    if (key === "supply_id") return; 
    if (key === "local_id") return;
    if (key === "supply_time") return; 
    if (key === "local") return;
    if (key === "tank_capacity_l") return; 
    
    let obj = {
      id: key,
      label: t(`hydrogenSupply.table.header.${key}`),
    };
    return (tableHeads = [...tableHeads, obj]);
  });

  let row;
  if (!arrayIsEmpty(supply.data)) {
    row = supply.data.map((item: IHydrogenSupply, index: number) => (
      <TableInformationRow key={index} row={item} />
    ));
  }
  const handleRequestSort = (
    item: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //Trocar página da listagem
  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHeadSort
            headCells={tableHeads}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>{row}</TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        count={supply?.totalCount}
        page={page}
        totalPages={supply?.totalPages}
        onPageChange={handleChangePage}
      />
    </>
  );
};

export default TableInformation;
