
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceApiUrl } from 'services/ServiceApiUrl';
import { IDialogProps } from 'types/interfaces';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import StatusForm, { IEventStatusForm } from './StatusForm';
import useCreate from 'hooks/fetchData/useCreate';
import { useFeedback } from '@alb/live-lib';
import { useGetModuleHeaders } from 'hooks/useGetModuleHeaders';
import { MODULES_KEYS } from 'utils/modules/modulesKeys';

const StatusAdd = ({
  open,
  onClose,
  onSuccess,
}: IDialogProps<IEventStatusForm>) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const addFormId = "add-event-category-form";
  const [formIsValid, setFormIsValid] = useState(false);

  const { headers } = useGetModuleHeaders(MODULES_KEYS.EVENTS);

  //pedido para adicionar categoria dos eventos
  const {
    loading,
    refetch: addEventState,
  } = useCreate(ServiceApiUrl.eventsStatus, undefined, headers);

  // sucesso
  const handlerSuccess = () => {
    onClose && onClose(); //fecha modal
    addFeedback({
      message: t("events.statusEvent.statusAdded"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  const formSubmitHandler = async (payLoad: IEventStatusForm) => {
    try {
      await addEventState({ data: payLoad });
      await handlerSuccess();
    } catch (error) {}
  };

  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
          {t("events.statusEvent.addStatus")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <StatusForm
          formId={addFormId}
          onFormSubmit={formSubmitHandler}
          editForm={true}
          setFormIsValidStatus={getFormIsValidStatus}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!formIsValid}
          type={"submit"}
          form={addFormId}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default StatusAdd;
