import event from "assets/images/map/markers/enabled/event.svg"
import instalacaodesportiva from "assets/images/map/markers/enabled/occurrences/instalacaodesportiva.svg"
import livrospedidos from "assets/images/map/markers/enabled/occurrences/livrospedidos.svg"
import materiaisviapublica from "assets/images/map/markers/enabled/occurrences/materiaisviapublica.svg"
import parqueinfantil from "assets/images/map/markers/enabled/occurrences/parqueinfantil.svg"



const IconsList = [
    {name: "event", url: event},
    {name: "instalacaodesportiva", url: instalacaodesportiva},
    {name: "livrospedidos", url: livrospedidos},
    {name: "materiaisnaviapublica", url: materiaisviapublica},
    {name: "parqueinfantil", url: parqueinfantil},
]

export default IconsList;