import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { IGroupForm } from "types/interfaces";
import formDefaultValues from "./formDefaultValues";
import { FormGroup, Grid } from "@mui/material";
import { InputAutocomplete, InputText, RequiredField } from "@alb/live-lib";
import { t } from "i18next";
import { useEffect } from "react";
import { getGroupFormSchema } from "./validation";

const GroupForm = (props: {
    formId: string;
    data?: IGroupForm;
    onFormSubmit: (payLoad: IGroupForm) => void;
    setFormIsValidStatus?: (status: boolean) => void;
    usersOptions?: any
  }) => {

    const { formId, data, onFormSubmit, setFormIsValidStatus, usersOptions } = props;



    let defaultValues;
    if(usersOptions)
       defaultValues = formDefaultValues(data, usersOptions);
    else
        defaultValues = formDefaultValues(data);

    let methodsForm = useForm<IGroupForm>({
        defaultValues: defaultValues,
        mode: "onTouched",
        reValidateMode: "onChange",
        resolver: yupResolver(getGroupFormSchema(data)),
      });

      const {
        control,
        handleSubmit,
        formState: { isDirty },
    } = methodsForm;

      useEffect(() => {
        if (setFormIsValidStatus)
          setFormIsValidStatus(methodsForm.formState.isValid && isDirty);

          // eslint-disable-next-line
      }, [methodsForm.formState.isValid, isDirty]);
   

    const formSubmitHandler: SubmitHandler<IGroupForm> = (
        payLoad: IGroupForm
      ) => {
        let userAux: any = [];
        payLoad.user?.forEach((e: any, i: number) => {
            userAux[i] = e.value;
        })
        payLoad.user = userAux;
        onFormSubmit(payLoad);
      };

    return(<>
        { <FormProvider {...methodsForm} >
            <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}> 
                 <FormGroup sx={{width: "500px"}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RequiredField title={t("group.name")} />
                            <InputText name="name" type="text" control={control} />
                        </Grid>

                        <Grid item xs={12}>
                            <RequiredField title={t("group.users")} />
                            <InputAutocomplete
                                name="user"
                                options={usersOptions}
                                multiple
                                control={control}
                                required
                            />
                        </Grid>
                    </Grid>
                 </FormGroup>
            </form>
        </FormProvider>}
    </>)
}

export default GroupForm;