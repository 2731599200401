import { IPaletteColor, getColor, palettePollutantColors } from "./QArtColorsLimits";
import L from "leaflet";
import { IQArtMapMarkers } from "./QArtMap";
import { TFunction } from "i18next";

function QArtMapMarkerIcon(marker: IQArtMapMarkers, pollutant: string, t:TFunction<"translation", undefined>) {

	let value: number | string = '';
	let color:IPaletteColor = getColor(marker.global.name, marker.global.value);

	if (pollutant !== '') {
		color = palettePollutantColors.outOfLimit;
		const pV = marker.agg_values?.find((p) => p.name === pollutant);
		if (pV) {
			color = getColor(pollutant, pV.value);
			value = Math.round(pV.value);
		}
	}

  const cssSelected = marker.selected ? "marker--selected" : "";

  const divIcon = L.divIcon({
    className: `icon-wrapper`,
    html: `<div class="marker ${cssSelected}"
				style="border:2px solid ${color.dark};
					background-color: ${color.main};
					color: #000;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 10px;
					width: 28px;
					height: 28px;
					border-radius: 50% 50% 50% 0;">${value}</div>`,
    popupAnchor: [0, 0],
    iconAnchor: [14, 14],
    iconSize: undefined,
  });

  return divIcon;
}

export default QArtMapMarkerIcon;
