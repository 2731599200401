/* eslint-disable react-hooks/exhaustive-deps */
import { IRange, LayoutContext } from "@alb/live-lib";
import { Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AnalysisView } from "components/cerManagement/AnalysisView";
import CardsTotals from "components/cerManagement/CardsTotals";
import { CERManagementSelect, IDeviceInfo } from "components/cerManagement/CERManagementSelect";
import FiltersSection from "components/cerManagement/FiltersSection";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";

const CERManagement = () => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const { addHeader } = useContext(LayoutContext);
  const canREAD = hasPermission(PERMISSIONS.MODULES.CERMANAGEMENT.READ);

  const header = {
    title: t("cerManagement.title"),
  };

  useEffect(() => {
    addHeader(header);
  }, []);

  const [device, setDevice] = useState<IDeviceInfo>();

  const [filterDateRange, setFilterDateRange] = useState<IRange | undefined>();

  return (
    <>
      {canREAD && (
        <>
          <Grid container>
            <Grid item xs={6}>
              <CERManagementSelect setDevice={setDevice} />
            </Grid>
            <FiltersSection setFilterDateRange={setFilterDateRange} />
          </Grid>

          {device && (
            <>
              <CardsTotals device={device} filterDateRange={filterDateRange} />
              <AnalysisView device={device} filterDateRange={filterDateRange} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default CERManagement;
