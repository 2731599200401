import { LayoutContext } from "@alb/live-lib"
import NotificationsList from "components/notifications/NotificationsList"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"

const Notifications = () => {
	// global component variables
	const { addHeader } = useContext(LayoutContext)
	const { t } = useTranslation()

	// page header configuration
	const header = {
		title: t('notifications.notifications'),
	}

	// component effects
	useEffect(() => {
		addHeader(header)
		// eslint-disable-next-line
	}, [])

	return (
		<NotificationsList  />
	)
}

export default Notifications;
