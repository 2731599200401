/* eslint-disable react-hooks/exhaustive-deps */
import { Loading } from "@alb/live-lib";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupIcon from "@mui/icons-material/Group";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Card, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getNameClient } from "store/slices/authSlice";
import { getFilterDateRange, getGeojsonType, getSelectedAnalysisDetail, getSelectedParishArea } from "store/slices/mapSlice";
import { getOverviewChart, setOverviewChart } from "store/slices/peopleMobilitySlice";
import styles from "styles/modules/map/list.module.scss";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";
import { getIdClientVisits } from "utils/utils";

export const CardsInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const nameClient = useSelector(getNameClient);
  const idParish = useSelector(getSelectedParishArea).id;
  const dateRange = useSelector(getFilterDateRange);
  const geojsonTypeMap = useSelector(getGeojsonType);
  const analysisDetail = useSelector(getSelectedAnalysisDetail);
  const data = useSelector(getOverviewChart);

  const {
    data: dataInfo,
    loading,
    error,
    refetch,
  } = useGet(
    ServiceApiUrl.overViewTourism,
    {
      start_date: formatDate(
        dateRange.startDate,
        t("calendar.dateFormatGeoAnalytics")
      ),
      end_date: formatDate(
        dateRange.endDate,
        t("calendar.dateFormatGeoAnalytics")
      ),
      city_id: idParish ? idParish : getIdClientVisits(nameClient),
    },
    {
      manual: true,
    }
  );

  const cardsList = [
    {
      title: t("map.detail.residents"),
      tooltip: t("map.detail.infoResidents"),
      value: data?.residents ? formatNumber(data?.residents) : "--"
    },
    {
      title: t("map.detail.regulars"),
      tooltip: t("map.detail.infoRegulars"),
      value: data?.regulars ? formatNumber(data?.regulars) : "--",
    },
  ];

  useEffect(() => {
    if (geojsonTypeMap !== "analysis" && !analysisDetail) {
      refetch();
    }
  }, [dateRange, analysisDetail]);

  useEffect(() => {
    if (!loading && dataInfo) {
      dispatch(setOverviewChart(dataInfo));
    }
  }, [dataInfo]);

  useEffect(() => {
    if (loading || error) {
      dispatch(setOverviewChart(null));
    }
  }, [loading, error]);

  return (
    <Grid container spacing={3}>
      {cardsList.map((card, index) => (
        <Grid item xs={6} key={index}>
          <Card>
            <CardContent className={styles["list-detail__card"]}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>{card.title}</Typography>
                  <Tooltip title={card.tooltip}>
                    <InfoOutlinedIcon color="primary" fontSize="small" />
                  </Tooltip>
                </Box>
              <Loading show={loading} />
              {!loading && <>
                <Typography pt={1} align="center" variant="h3">
                  {card.value}
                </Typography>
              </>}
            </CardContent>
          </Card>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Card>
          <CardContent className={styles["list-detail__card"]}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1">
                  {t("map.detail.biggestFlow")}
                </Typography>
                <Tooltip title={t("map.detail.biggestFlowDescription")}>
                  <InfoOutlinedIcon color="primary" fontSize="small" />
                </Tooltip>
              </Box>
              <Loading show={loading} />
              {!loading && <>
                <Grid container spacing={2} pt={1}>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <CalendarMonthIcon color="primary" />
                <Typography pl={1} variant="h3">
                  {data?.busiest_day?.date || "--"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <GroupIcon color="primary" />
                <Typography pl={1} variant="h3">
                  {data?.busiest_day?.visits
                    ? formatNumber(data?.busiest_day.visits)
                    : "--"}
                </Typography>
              </Grid>
            </Grid>
              </>}
            
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
