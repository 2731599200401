/* eslint-disable react-hooks/exhaustive-deps */
import { arrayIsEmpty, GenericTable, IApiResponse, Loading, TableHeadCell, TGenericTableModel } from '@alb/live-lib';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { TWazeList } from 'types/types';

import NoData from 'components/Utils/NoData';
import { CustomTablePagination } from 'components/Utils/Pagination/CustomTablePagination';
import useGet from 'hooks/fetchData/useGet';
import i18n from 'i18n/config';
import { WazeAdapters } from 'pages/Waze';
import { ServiceApiUrl } from 'services/ServiceApiUrl';
import { IFilterDateRangeMap } from 'store/slices/mapSlice';
import { formatDate } from 'utils/date';

interface IWazeAlertsList {
	filterSelectedRange: IFilterDateRangeMap;
	selectedType: string | null,
	filterSearch: string | null
}

function WazeAlertsList({filterSelectedRange, selectedType, filterSearch}:IWazeAlertsList) {
	const { t } = useTranslation();
	const [page, setPage] = useState(1);
	const maxPerPage = 10;

	const { data, loading, error, refetch } = useGet<IApiResponse<TWazeList>>(ServiceApiUrl.wazeList(WazeAdapters.alerts), getParams());

	function getParams() {
		var params: {
			page: number;
			items: number;
		} = {
			page: page,
			items: maxPerPage,
			...(filterSelectedRange && {
				from: filterSelectedRange.startDate,
				to: filterSelectedRange.endDate,
			}),
			...(selectedType && { type: selectedType }),
			...(filterSearch && { contains: filterSearch })
		};
		return params;
	}

	const handleChangePage = (newPage: number) => {
		setPage(newPage + 1);
	};

	const pagination =
		data && !arrayIsEmpty(data?.data) ? (
			<Box ml={1}>
				<CustomTablePagination
					count={data?.totalCount}
					page={page}
					totalPages={data?.totalPages}
					onPageChange={handleChangePage}
					itemsPage={maxPerPage}
				/>
			</Box>
		) : undefined;

	const tableHeads: TableHeadCell[] = [
		{
			id: "type",
			label: t("common.type"),
		},
		{
			id: "local",
			label: t("common.local"),
		},
		{
			id: "date",
			label: t("common.date"),
		},
	];

	const model: TGenericTableModel<TWazeList> = {
		columns: [
			{
				html: (row) => (
					<>
						{i18n.exists(`waze.types.${row.type}`)
							? t(`waze.types.${row.type}`)
							: row.type}
					</>
				),
			},
			{
				html: (row) => (
					<>
						{row.local}
					</>
				),
			},
			{
				html: (row) => (
					<>
						{formatDate(row.date, t("calendar.dateTimeFormatLocal"))}
					</>
				),
			},
		],
		headSort: {
			headCells: tableHeads,
		},
	};

	useEffect(() => {
		setPage(1)
		refetch()
	}, [selectedType, filterSelectedRange, filterSearch])

	return (
		<>
			<Loading show={loading} />
			{!loading && (arrayIsEmpty(data?.data) || error) && (
				<NoData error={error} />
			)}
			{!loading && !error && data && !arrayIsEmpty(data?.data) && (
				<>
					<GenericTable
						model={model}
						items={data}
						pagination={pagination}
						stripedTable
					/>
				</>
			)}
		</>
	);
}

export default WazeAlertsList
