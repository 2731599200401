import {TUser } from 'types/types'

export const formDefaultValues = (data?: TUser) => {
  const defaultValues = {
    first_name: data?.first_name || '' ,
    password: '',
    confirmPassword: ''
  }
  return defaultValues
}
