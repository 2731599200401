/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutContext } from "@alb/live-lib";
import AdaptersList from "components/management/adapters/AdaptersList";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Adapters = () => {
  const { t } = useTranslation();
  const header = {
    title: t("pages.adapters.headerText"),
  };
  const { addHeader } = useContext(LayoutContext);

  useEffect(() => {
    addHeader(header);
  }, []);

  return (
    <>
      <AdaptersList />
    </>
  );
};

export default Adapters;
