import { IRange } from "@alb/live-lib";
import { useTranslation } from "react-i18next";
import { ICard } from "types/interfaces";

/* eslint-disable react-hooks/exhaustive-deps */
import { ModuleCards } from "components/modules/ModuleCards";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";

interface ICardsTotal {
  deviceName: string;
  filterDateRange?: IRange | undefined;
}

const CardsTotals = ({ deviceName, filterDateRange }: ICardsTotal) => {
  const { t } = useTranslation();

  const getParams = () => {
    var params = {
      ...(filterDateRange && {
        start_date: formatDate(
          filterDateRange?.startDate,
          t("calendar.dateFormatGeoAnalytics")
        ),
        end_date: formatDate(
          filterDateRange?.endDate,
          t("calendar.dateFormatGeoAnalytics")
        ),
      }),
    };

    return params;
  };
  const {
    data: cardsTotals,
    loading,
    error,
  } = useGet<ICard[]>(ServiceApiUrl.hydrogenCards(deviceName), getParams());

  return (
    <>
      <ModuleCards
        module="hydrogenSupply"
        items={cardsTotals}
        loading={loading}
        error={error}
        sx={{ mt: 5 }}
        dataTestId="HydrogenSupplyCardsContainer"
      />
    </>
  );
};

export default CardsTotals;
