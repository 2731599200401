import { Grid } from "@mui/material";
// import { CircularProgress } from '@mui/icons-material'
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputText, RequiredField } from "@alb/live-lib";
import { getRecoverPassword } from "./validations";
import { RequiredFields } from "components/Utils/RequiredField";

interface IRecoverForm {
  email: string;
  password: string;
  confirmPassword: string;
  logout: boolean;
}

export const RecoverPasswordForm = (props: {
  formId: string;
  data?: any;
  loading: boolean;
  onFormSubmit: (payLoad: IRecoverForm) => void;
}) => {
  const { formId, data, loading, onFormSubmit } = props;

  const { t } = useTranslation();
  const defaultValues = {
    email: data?.email || "",
    password: "",
    confirmPassword: "",
    // logout: false,
  };

  const methodsForm = useForm<IRecoverForm>({
    defaultValues: defaultValues,
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: yupResolver(
      getRecoverPassword(t("recoverPassword.passwordMustMatch"))
    ),
  });

  const {
    control,
    handleSubmit,
    // setValue
  } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IRecoverForm> = (
    payLoad: IRecoverForm
  ) => {
    onFormSubmit(payLoad);
  };
  return (
    <Grid container mt={5}>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid item xs={12} sm={10}>
            <RequiredField title={t("recoverPassword.emailLabel")} />
            <InputText
              disabled
              control={control}
              name="email"
              placeholder={t("recoverPassword.emailPlaceholder")}
            />
          </Grid>

          <Grid item sm={6}>
            <Grid container>
              <Grid item pt={3} xs={12}>
                <RequiredField title={t("recoverPassword.passwordLabel")} />

                <InputText
                  type={"password"}
                  control={control}
                  name="password"
                  placeholder={t("recoverPassword.passwordPlaceholder")}
                />
              </Grid>
              <Grid item pt={3} xs={12}>
                <RequiredField
                  title={t("recoverPassword.passwordConfirmationLabel")}
                />
                <InputText
                  type={"password"}
                  control={control}
                  name="confirmPassword"
                  placeholder={t(
                    "recoverPassword.passwordConfirmationLabelPlaceholder"
                  )}
                />
                <RequiredFields allRequired />
              </Grid>
              {/* Maybe for future implementation */}
              {/* <Grid item pt={3} xs={12} sm={8} className={styles['logout']}>
                <InputCheckbox
                  control={control}
                  setValue={setValue}
                  name="logout"
                  labelText={t('recoverPassword.logout')}
                />
              </Grid> */}

              <Grid container mt={4} justifyContent={"flex-start"}>
                  <LoadingButton
                  loading={loading}
                  variant="contained"
                  color="secondary"
                  type={"submit"}
                  form={formId}
                  disabled={!methodsForm.formState.isValid}
                >
                  {t("recoverPassword.buttonLabel")}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      
    </Grid>
  );
};
