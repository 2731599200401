import { IEventForm } from "types/interfaces";

export const formDefaultValues = (data?: IEventForm) => {
  const defaultValues = {
    name: data?.name || "",
    type: data?.type.id || "",
    description: data?.description || "",
    start_date: data?.start_date || null,
    end_date: data?.end_date || null,
    geolocation: "", //  JSON.stringify(data?.location) || "",
    category: data?.category.id || "",
    status: data?.status.id || "",
    url: data?.url || "",
    notes: data?.notes || "",
  };
  return defaultValues;
};
