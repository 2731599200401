import { BackToButton, LayoutContext } from "@alb/live-lib";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import FilterSelectForArray from "components/Utils/FilterSelectForArray";
import Loading from 'components/Utils/Loading'
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils/utils";

import IsoTypology from "./IsoTypology";

export interface ITipology {
	code: number,
	denominator_description: string,
	denominator_value: number,
	description: string,
	numerator_description: string,
	numerator_value: number,
	source: string,
	unit: string,
	value: number,
	year: number,
}

export interface IisoCategory {
	name: string,
	data: Array<ITipology>
}

const Isoview = () => {
	const { t } = useTranslation();
	let params = useParams();
	let location = useLocation()
	let navigate = useNavigate()
	const { addHeader } = useContext(LayoutContext)

	let { data: iso, loading, error } = useGet(
		`${ServiceApiUrl.isoCategorys}/${params.isoIndicatorName}`
	)


	const handleSelectedAdapter = (option: any | null) => {

		if (!option) return undefined
		let path = location.pathname;

		path = path.slice(0, path.lastIndexOf('/'))
		path = path + '/' + option.label

		navigate(path)
	};

	const header = {
		title: iso?.name ? iso?.name : t("indicators.detail"),
		backTo: (
			<BackToButton
				title={t("goBack")}
				onClick={() => navigate("/iso-indicators")}
			/>
		),
		select: (iso &&
			<FilterSelectForArray<any>
				apiUrl={ServiceApiUrl.isoCategorys}
				onSelected={handleSelectedAdapter}
				selectedValue={params?.isoIndicatorName}
			/>
		)
	}

	let deviceView = <NoData error={error} />

	if (!arrayIsEmpty(iso?.typology)) {
		deviceView = iso?.typology.map((t: IisoCategory, index: number) => (
			<IsoTypology key={index} name={t.name} data={t.data} />
		))
	}

	useEffect(() => {
		addHeader(header)
		// eslint-disable-next-line
	}, [iso?.name])


	return (<>
		{loading && <Loading />}
		{!loading && deviceView}
	</>)
}

export default Isoview;
