import { arrayIsEmpty, IRange } from "@alb/live-lib";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ModuleCards } from "components/modules/ModuleCards";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { IDataStatus } from "pages/ElectricMobility";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

interface ICardsTotal {
	filterName?: string;
	filterDateRange?: IRange | undefined;
	setCardsDataStatus: Dispatch<SetStateAction<IDataStatus>>;
	setErrorRequest: Dispatch<SetStateAction<AxiosError<any, any> | null>>;
}

const CardsTotals = ({ filterName, filterDateRange, setCardsDataStatus, setErrorRequest }: ICardsTotal) => {
	const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);
	const { t } = useTranslation();

	const getParams = () => {
		var params: {
			charger?: string;
		} = {
			...(filterName !== "" && { charger: filterName }),
			...(filterDateRange && {
				start_date_interval: Object.values(filterDateRange)
					.map((date: Date) => formatDate(date, t("calendar.dateTimeFormat")))
					.join(","),
			}),
		};

		return params;
	};

	const { data: cardsTotals, loading, error } = useGet(
		ServiceApiUrl.chargingCards,
		getParams(),
		undefined,
		headers
	);

	let cards;

	if (!loading && cardsTotals && !arrayIsEmpty(cardsTotals)) {
		cards = (
			<ModuleCards
				module="electricMobility"
				items={cardsTotals}
				loading={false}
				error={error}
				sx={{ mt: 5 }}
				dataTestId="eletricMobilityCardsContainer"
			/>
		);
	}

	useEffect(() => {
		if (!loading) {
			if (arrayIsEmpty(cardsTotals)) {
				setCardsDataStatus({
					isLoading: false,
					hasData: false
				})
			} else {
				setCardsDataStatus({
					isLoading: false,
					hasData: true
				})
			}
		} else {
			setCardsDataStatus({
				isLoading: true,
				hasData: false
			})
		}
		// eslint-disable-next-line
	}, [cardsTotals, loading])

	useEffect(() => {
		setErrorRequest(error);
		// eslint-disable-next-line
	}, [error]);

	return <>{cards}</>;
};

export default CardsTotals;
