import { IItemList, IMapPoint } from "@alb/live-lib";
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store";
import { ISelectOption } from "types/interfaces";
import { TMarker } from "types/types";

interface IDashboard {
  selectedDevice: TMarker | null;
  selectedAdapter: ISelectOption;
  devicesSelectedAdapter: TMarker[];
  devicesMapList: IItemList[];
  deviceDefaultMapList: IItemList;
  textSearchBar: string;
  filteredDevices: TMarker[];
  filteredDevicesList: IItemList[];
  selectedDeviceId: string;
  bounds?: number[][];
  devices: TMarker[];
  deviceInfo: any;
}

const initialState: IDashboard = {
  selectedDevice: null,
  selectedAdapter: {
    label: "",
    value: "",
  },
  devicesSelectedAdapter: [],
  devicesMapList: [],
  deviceDefaultMapList: {
    title: "",
    idItem: "",
  },
  textSearchBar: "",
  filteredDevices: [],
  filteredDevicesList: [],
  selectedDeviceId: "",
  bounds: [],
  devices: [],
  deviceInfo: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDevice(state, action) {
      state.selectedDevice = action.payload;
    },
    setSelectedDeviceId(state, action) {
      state.selectedDeviceId = action.payload;
    },
    setSelectedAdapterFilter(state, action) {
      state.selectedAdapter = action.payload;
    },
    setDevicesAdapter(state, action) {
      state.devicesSelectedAdapter = action.payload;
      state.devicesMapList = action.payload.map((device: IMapPoint) => {
        return {
          title: device.title,
          idItem: device.id,
        };
      });
    },
    setDeviceDefaultMapList(state, action) {
      state.deviceDefaultMapList = action.payload;
    },
    setTextSearchBar(state, action) {
      state.textSearchBar = action.payload;
      // update devices according to what is written in the searchBar component
      // if (action.payload.length > 0) {
      //   state.filteredDevicesList = state.devicesMapList.filter((d) =>
      //     d.title.toLowerCase().includes(action.payload.toLocaleLowerCase())
      //   )
      // } else {
      //   state.filteredDevices = state.devicesSelectedAdapter
      //   state.filteredDevicesList = state.devicesMapList
      // }
    },
    setFilteredDevices(state, action) {
      state.filteredDevices = action.payload;
    },
    setFilteredDevicesList(state, action) {
      state.filteredDevicesList = action.payload;
    },
    setBounds(state, action) {
      state.bounds = action.payload;
    },
    setDevicesMapDashboard(state, action) {
      state.devices = action.payload;
    },
    setSelectedDeviceInfo(state, action) {
      state.deviceInfo = action.payload;
    },
    setOneDeviceMapDashboard(state, action) {
      state.devices = state.devices.map((dev) => {
        if (dev.external_id === action.payload.external_id) {
          dev = action.payload
        }
        return dev
      });
    },
  },
});

export const {
  setDevice,
  setSelectedAdapterFilter,
  setDevicesAdapter,
  setDeviceDefaultMapList,
  setTextSearchBar,
  setFilteredDevices,
  setFilteredDevicesList,
  setSelectedDeviceId,
  setBounds,
  setDevicesMapDashboard,
  setSelectedDeviceInfo,
  setOneDeviceMapDashboard,
} = dashboardSlice.actions;

export const getSelectedDevice = (state: RootState) =>
  state.dashboard.selectedDevice;
export const getSelectedAdapterFilter = (state: RootState) =>
  state.dashboard.selectedAdapter;
export const getDevicesSelectedAdapter = (state: RootState) =>
  state.dashboard.devicesSelectedAdapter;
export const getDevicesMapList = (state: RootState) =>
  state.dashboard.devicesMapList;
export const getDeviceDefaultMapList = (state: RootState) =>
  state.dashboard.deviceDefaultMapList;
export const getTextSearchBar = (state: RootState) =>
  state.dashboard.textSearchBar;
export const getFilteredDevices = (state: RootState) =>
  state.dashboard.filteredDevices;
export const getFilteredDevicesList = (state: RootState) =>
  state.dashboard.filteredDevicesList;
export const getSelectedDeviceId = (state: RootState) =>
  state.dashboard.selectedDeviceId;
export const getMapBoundsDashboard = (state: RootState) =>
  state.dashboard.bounds;
export const getDevicesMapDashboard = (state: RootState) =>
  state.dashboard.devices;
export const getSelectedDeviceInfo = (state: RootState) =>
  state.dashboard.deviceInfo;
export default dashboardSlice.reducer;
