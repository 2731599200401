import { CardInfo, Loading } from "@alb/live-lib";
import { Box, Grid, styled, SvgIcon, SvgIconTypeMap, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { AxiosError } from "axios";
import { addHours } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

import NoData from "components/Utils/NoData";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";
import { arrayIsEmpty } from "utils/utils";

import { moduleCardsIcons } from "./cards/moduleCardsIcons";

const MuiTypographyInnerValueStringStyled = styled(Typography)(({ theme }) => {
	return {
		fontSize: "14",
		fontWeight: "700",
		display: "contents",
	};
});
const MuiTypographyValueStringStyled = styled(Typography)(({ theme }) => {
	return {
		fontSize: "30px",
		display: "flex",
		fontWeight: 700
	};
});
const MuiTypographyValueStyled = styled(Typography)(({ theme }) => {
	return {
		display: "inline-block",
		fontSize: "20px",
		fontWeight: "normal",
	};
});
export interface IModuleCardItem {
	name: string;
	value?: number | string;
	type: string;
	values?: { name: string; value: number }[];
	unit?: string;
}
interface IModuleCards {
	module:
	| "events"
	| "occurrences"
	| "electricMobility"
	| "hydrogenSupply"
	| "urbanWaste"
	| "cerManagement"
	| "mobility"
	| "waze"
	| "buga"
	| "qart";
	items: IModuleCardItem[] | undefined;
	loading: boolean;
	error: AxiosError<any, any> | null;
	sx?: SxProps<Theme>;
	dataTestId?: string;
}
export const ModuleCards = ({
	module,
	items,
	loading,
	error,
	sx,
	dataTestId,
}: IModuleCards) => {
	const { t, i18n } = useTranslation();
	const theme = useTheme();

	function showTranslationName(name: string) {
		return i18n.exists(`${module}.state.${name}`)
			? t(`${module}.state.${name}`)
			: name;
	}
	function showItemValueString(value: string) {
		const splitValue = value.split("/");
		return (
			<>
				{splitValue[0]}
				<MuiTypographyInnerValueStringStyled variant="caption">
					{"/" + splitValue[1]}
				</MuiTypographyInnerValueStringStyled>
			</>
		);
	}

	function showItemValueNumber(item: IModuleCardItem) {
    if (item.unit && item.unit === "hours") {
      return showTimeInterval(String(item.value), t("calendar.hourFormat"));
    }
    return formatNumber(item.value || "");
  }

	// colocar o intervalo de 1h com o formato do idioma
	function showTimeInterval(time: string | Date, format: string) {
		const moreOneHour = addHours(new Date(time), 1);
		return formatDate(time, format) + " - " + formatDate(moreOneHour, format);
	}

	const formatItemValue = (item: IModuleCardItem) => {
		return (
			<Box>
				{item?.value !== undefined && (
					<MuiTypographyValueStringStyled variant="caption" data-testid={item.name}>
						{String(item.value).includes("/")
							? showItemValueString(String(item.value))
							: showItemValueNumber(item)}
					</MuiTypographyValueStringStyled>
				)}
				{item?.values?.map(
					(subItem: { name: string; value: number }, index: number) => (
						<MuiTypographyValueStyled variant="caption" key={index} marginRight={'10px'}>
							{i18n.exists(`${module}.state.${subItem.name}`)
								? t(`${module}.state.${subItem.name}`)
								: subItem.name}
							: <b>{formatNumber(subItem?.value)}</b>
						</MuiTypographyValueStyled>
					)
				)}
			</Box>
		);
	};

	const getIcon = (name: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & { muiName: string; }) => {
		const color = theme.palette.mode === 'light' ? theme.palette.neutral5.main : theme.palette.neutral5.dark;
		return <SvgIcon
			sx={{ width: '44px', height: '44px', color: color }}
			component={name}
			inheritViewBox
		/>
	}

	function templateGrid(module: string) {
    switch (module) {
      case "buga":
        return "auto";
      default:
        return 3;
    }
  }

	return (
		<>
			{loading ? (
				<Loading show={loading} />
			) : (
				<Box
					data-testid={dataTestId}
					sx={{
						...sx,
					}}
				>
					<Grid container spacing={{ xs: 2, md: 3 }}>
						{(!items || arrayIsEmpty(items) || error) && (
							<Grid item xs={12}>
								<NoData error={error} />
							</Grid>
						)}
						{items &&
							!arrayIsEmpty(items) &&
							!error &&
							items.map((item: IModuleCardItem, index: number) => {
								if (item.name === undefined)
									return <React.Fragment key={index}></React.Fragment>;
								return (
									<Grid
                    item
                    xs={templateGrid(module)}
                    sm={templateGrid(module)}
                    md={templateGrid(module)}
                    xl={templateGrid(module)}
                    key={index}
                    sx={{ minWidth: "335px" }} // when md, xl is auto
                  >
                    <CardInfo
                      icon={getIcon(moduleCardsIcons[item?.name])}
                      title={showTranslationName(item.name)}
                      type={t(`common.${item.type}`)}
                      value={formatItemValue(item)}
                    />
                  </Grid>
								);
							})}
					</Grid>
				</Box>
			)}
		</>
	);
};
