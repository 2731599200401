import { LayoutContext } from "@alb/live-lib";
import { Button } from "@mui/material";
import GroupList from "components/management/groups/GroupList";
import usePermission from "hooks/usePermission";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { PERMISSIONS } from "utils/permissions/permissions";
import AddIcon from "@mui/icons-material/Add"; 
import GroupAdd from "components/management/groups/GroupAdd";

const Groups = () => {

    const { hasPermission } = usePermission();
    const canCreate = hasPermission(PERMISSIONS.MANAGEMENT.GROUPS.CREATE);
	const [ addingGroup, setAddingGroup] = useState(false);

    const header = {
		title: t("pages.groups.headerText"),
		action:
			(
				canCreate && <Button
					variant="contained"
					disableElevation
					startIcon={<AddIcon />}
					onClick={() => setAddingGroup(true)}
				>
					{t("group.createGroup")}
				</Button>
			),
	}

	const { addHeader } = useContext(LayoutContext)

    useEffect(() => {
		addHeader(header)
		// eslint-disable-next-line
	}, [])


	const [ hasToRefetch, setHasToRefetch ] = useState(false)
	const handleGroupAdded = () => {
		setHasToRefetch(true);
	}


	const [ adding, setAdding ] = useState<any>()

	useEffect(() => {
		if(addingGroup)
			setAdding(<GroupAdd closeDialog={setAddingGroup} onSuccess={handleGroupAdded}/>)
		else
			setAdding(null)
	}, [addingGroup])

    
    return(<>
        <GroupList hasToRefetch={hasToRefetch} setHasToRefetch={setHasToRefetch} />
		{adding}
    </>)
}

export default Groups;