import { Box, Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { fetchReadAllNotification } from 'store/slices/notificationsSlice';

export interface IMarkAllAsRead {
	count: number
}
function MarkAllAsRead({count}: IMarkAllAsRead) {
	const { t } = useTranslation();

	const dispatch = useDispatch()

	const handleMarkAllAsReadClick = async (event: React.MouseEvent<HTMLButtonElement>) => {

		dispatch(fetchReadAllNotification({read: true, reload: true}));
	}

	return (
		<Box width={"100%"} display={'flex'} justifyContent={'flex-end'}>
			<Button
				variant="outlined"
				onClick={handleMarkAllAsReadClick}
			>
				{t("common.markAllAsRead", {count: count})}
			</Button>
		</Box>
	)
}

export default MarkAllAsRead
