/* eslint-disable react-hooks/exhaustive-deps */
import { InputAutocomplete, InputCheckbox, InputText, RequiredField } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ISelectOption, IUserAddForm, IUserPermission } from "types/interfaces";
import { TAccount, TUser } from "types/types";

import { RequiredFields } from "components/Utils/RequiredField";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils/utils";

import { formDefaultValues } from "./formDefaultValues";
import { getLanguages } from "./languages";
import UserPermissions from "./UserPermissions";
import { getUserSchema } from "./validations";

const CRUD = "CRUD";

const UsersForm = (props: {
	formId: string;
	clientID?: string;
	data?: TUser;
	administration?: boolean;
	permissions?: IUserPermission[];
	onFormSubmit: (payLoad: IUserAddForm) => void;
	onChangeStatusForm?: (status: boolean) => void;
}) => {
	const { t } = useTranslation();

	const {
		formId,
		clientID,
		data,
		administration,
		permissions,
		onFormSubmit,
		onChangeStatusForm,
	} = props;

	const [accountID, setAccountID] = useState<ISelectOption | null>(null);
	const [accountsOptions, setAccountsOptions] = useState<ISelectOption[]>([]);
	const [adminChecked, setAdminChecked] = useState<boolean>(
		data?.is_admin || false
	);
	const [superAdminChecked, setSuperAdminChecked] = useState<boolean>(
		data?.is_superadmin || false
	);

	const showToggleActive =
		data?.status === "active" || data?.status === "non-active" || false;

	let params = {};
	if (data?.is_superadmin) {
		params = { superAdmin: true };
	} else if (!data?.is_superadmin && data?.is_admin) {
		params = { client: clientID, admin: true };
	} else {
		params = { client: clientID };
	}

	let permitionModule: { [permitionId: string]: boolean } = {};
	permissions?.forEach((permission) => {
		CRUD.split("").forEach((c) => {
			const name = `${permission.module_id}_${c}`;
			const obj = { [name]: false };
			permitionModule = { ...permitionModule, ...obj };
		});
	});

	// get list of accounts
	const { data: allAccounts, refetch: listAccounts } = useGet(
		ServiceApiUrl.accounts,
		{ ...params }
	);
	const langOptions = getLanguages(t);

	const selectedLang = langOptions.filter((obj) => {
		return obj.value === data?.language;
	});

	let defaultValues = formDefaultValues(
		selectedLang.length === 0 ? langOptions[0] : selectedLang[0],
		accountID,
		data
	);
	defaultValues = { ...defaultValues, ...permitionModule };

	const methodsForm = useForm<IUserAddForm>({
		defaultValues: defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
		resolver: yupResolver(getUserSchema({matchesEmail: t("passwordRecovery.emailRule")})),
	});

	const { control, handleSubmit, resetField, setValue } = methodsForm;

	useEffect(() => {
		if (allAccounts) {
			const options = allAccounts?.data?.map((p: TAccount) => ({
				label: p.name,
				value: p.id,
			}));

			resetField("account", { defaultValue: null });

			setAccountsOptions(options);
			if (data?.account) {
				setAccountID({
					label: data.account.name,
					value: data.account.id,
				});
			} else {
				setAccountID({
					label: options[0].label,
					value: options[0].value,
				});
			}
		}
	}, [allAccounts]);

	useEffect(() => {
		if (accountID) {
			resetField("account", { defaultValue: accountID });
		}
	}, [accountID]);

	useEffect(() => {
		if (adminChecked) {
			resetField("is_admin", { defaultValue: true });
		} else {
			resetField("is_admin", { defaultValue: false });
		}
	}, [adminChecked]);
	useEffect(() => {
		if (superAdminChecked) {
			resetField("is_superadmin", { defaultValue: true });
		} else {
			resetField("is_superadmin", { defaultValue: false });
		}
	}, [superAdminChecked]);

	const onAdminChange = (
		event: ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setAdminChecked(checked);

		if (checked) listAccounts({ params: { client: clientID, admin: true } });
		else {
			listAccounts({ params: { client: clientID } });
		}
	};

	const onSuperAdminChange = (
		event: ChangeEvent<HTMLInputElement>,
		checked: boolean
	) => {
		setSuperAdminChecked(checked);

		if (checked) {
			setAdminChecked(checked);
			listAccounts({ params: { superAdmin: true } });
		} else {
			listAccounts({ params: { client: clientID, admin: adminChecked } });
		}
	};

	//vai colocar por defeito as permissões que já são do user e colocar nos estados
	useEffect(() => {
		if (data) {
			setAdminChecked(data.is_admin);
			setSuperAdminChecked(data.is_superadmin);
			setAccountID({
				label: data?.account?.name || "",
				value: data?.account?.id || "",
			});
		}
	}, []);

	function preparePayload(payLoad: IUserAddForm) {
		let _payLoad: IUserAddForm = { ...payLoad };
		if (data?.status === "active" || data?.status === "non-active") {
			_payLoad.status = _payLoad._is_active ? "active" : "non-active";
		}
		return _payLoad;
	}
	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<IUserAddForm> = (
		payLoad: IUserAddForm
	) => {
		const _payload = preparePayload(payLoad);
		onFormSubmit(_payload);
	};

	useEffect(() => {
		if (onChangeStatusForm) {
		  onChangeStatusForm(methodsForm.formState.isValid);
		}
	}, [onChangeStatusForm, methodsForm.formState.isValid]);

	return (
		<>
			<FormProvider {...methodsForm}>
				<form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={!administration ? 12 : 6}>
							<InputCheckbox
								control={control}
								setValue={setValue}
								name={"is_admin"}
								labelText={t("user.admin")}
								disabled={superAdminChecked}
								onChange={onAdminChange}
							/>
						</Grid>
						{administration && (
							<Grid item xs={12} sm={6}>
								<InputCheckbox
									control={control}
									setValue={setValue}
									name={"is_superadmin"}
									labelText={t("user.superAdmin")}
									onChange={onSuperAdminChange}
								/>
							</Grid>
						)}
						<Grid item xs={12} sm={6}>
							<Typography gutterBottom color="text.black" variant="h6">
								{t("user.firstName")}
							</Typography>
							<InputText
								control={control}
								name="first_name"
								placeholder={t("modules.namePlaceholder")}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Typography gutterBottom color="text.black" variant="h6">
								{t("user.lastName")}
							</Typography>

							<InputText
								control={control}
								name="last_name"
								placeholder={t("modules.namePlaceholder")}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<RequiredField title={t("user.Username")} />
							<InputText
								control={control}
								name="username"
								placeholder={t("modules.namePlaceholder")}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<RequiredField title={t("user.institutionalEmail")} />
							{
								data ?
									<Typography mt={1} color="text.primary" variant="body1">
										{data.email}
									</Typography>
									:
									<InputText
									control={control}
									name="email"
									placeholder={t("user.email")}
									/>
							}

						</Grid>

						<Grid item xs={12} sm={6}>
							<Typography gutterBottom color="text.black" variant="h6">
								{t("user.language")}
								<abbr />
							</Typography>

							{langOptions && (
								<InputAutocomplete
									name="language"
									options={langOptions}
									control={control}
								/>
							)}
						</Grid>

						<Grid item xs={12} sm={6}>
							{!arrayIsEmpty(accountsOptions) && (
								<>
									<RequiredField title={t("accounts.account")} />
									{!adminChecked && !superAdminChecked && (
										<InputAutocomplete
											name="account"
											options={accountsOptions}
											control={control}
										/>
									)}
								</>
							)}

							{(adminChecked || superAdminChecked) && (
								<Typography
									color="text.primary"
									variant="body1"
									sx={{ mt: 1.5 }}
								>
									{accountID?.label}
								</Typography>
							)}
						</Grid>

						{showToggleActive && (
							<Grid item xs={12} sm={6}>
								<InputCheckbox
									control={control}
									setValue={setValue}
									name={"_is_active"}
									labelText={t("user.status.active")}
									checked={defaultValues._is_active}
								/>
							</Grid>
						)}

						{!adminChecked && !superAdminChecked && permissions && !arrayIsEmpty<IUserPermission[]>(permissions) && (
							<Grid item xs={12}>
								<UserPermissions
									control={control}
									setValue={setValue}
									edit
									permissions={permissions}
									allPermissions={adminChecked || superAdminChecked}
								/>
							</Grid>
						)}

					</Grid>
				</form>
			</FormProvider>
			<RequiredFields />
		</>
	);
};

export default UsersForm;
