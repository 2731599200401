import { PageLayout } from '@alb/live-lib'

import { Navigate, Outlet, useLocation } from 'react-router-dom'
import useLocalStorage from 'hooks/useLocalStorage'
import { AUTH } from 'utils/keys'

import Login from 'pages/Login'
import LiveMain from './liveMain'

interface IRequireAuth {
  required: boolean
  invite?: boolean
}


const RequireAuth = ({ required, invite }: IRequireAuth) => {
  const { getLocalStorage } = useLocalStorage()
  const auth = getLocalStorage(AUTH)

  const location = useLocation()
  const state = { from: location }

  if (required) {
    // requires the user to be logged in
    if (!auth) {
      return <Navigate to="/login" state={state} replace />
    }
    return <PageLayout children={<Outlet />} />
  } else {
    // requires the user to be logged out
    if (auth && !invite) {
      return <Navigate to="/map" state={state} replace />
    } else if (!auth && invite) {
      return <LiveMain backgroundImage children={<Outlet />} />
    } else if (auth && invite) {
      return <Navigate to="/map" state={state} replace />
    } else {
      return <Login />
    }
  }
}

export default RequireAuth
