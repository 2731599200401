import { EntityInfo, EntityInfoItem, Loading } from "@alb/live-lib";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { TGroup, TUser } from "types/types";

import NoData from "components/Utils/NoData";
import { TabPanelContainer } from "components/Utils/Tab/TabPanelContainer";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { ContainedTabs } from "styles/css/components";
import { formatDate } from "utils/date";

import UsersTab from "./usersTab/UsersTab";

interface IGroupView {
    group:TGroup;
    groupLoading:boolean;
    refetchGroups: () => void;
}

const GroupView = ({group, groupLoading, refetchGroups}: IGroupView) => {

    const { data: usersData, loading: usersLoading, error } = useGet(
        ServiceApiUrl.user, 
        undefined, 
        undefined
    )

    const [ groupUsers, setGroupUsers] = useState<any>([]);

    useEffect(() => {
        setGroupUsers([])
        if (!usersLoading) {
          const newUsers: any = [];
            group?.user?.forEach((userId: string) => {
                usersData.data?.forEach((u: TUser) => {
                if (u.id === userId) {
                    newUsers.push(u)
                }
                })
            })
            setGroupUsers((prevUsers: any) => [...prevUsers, ...newUsers]);
        }
        // eslint-disable-next-line
      }, [usersData, group]);
    
      const value = '1';

    return(<>
        {!group && !groupLoading && <NoData error={error} />}
        {groupLoading && <Loading show={groupLoading} />}
        {!groupLoading && group && <>
            <EntityInfo
                sx={{ mb: 5 }}
                title={t("common.details")}
            >
                <EntityInfoItem
                    label={t("group.name")}
                    value={group.name}
                />
                <EntityInfoItem
                    label={t("common.userQuantity")}
                    value={group.user?.length}
                />
                <EntityInfoItem
                    label={t("common.createdAt")}
                    value={formatDate(group.created, t('calendar.dateTimeFormatLocal'))}
                />
            </EntityInfo>
            {/*<Grid container  columnSpacing={6} rowSpacing={2}>
                {!usersLoading && userCards}
            </Grid>*/}

        <Box mt={2}>
            <TabContext value={value}>
                <ContainedTabs indicatorColor="secondary" onChange={() => {}} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                    <Tab label={t('common.users')} value="1" />
                </ContainedTabs>

                <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
                    <TabPanelContainer>
                        <UsersTab users={groupUsers} group={group} refetchGroups={refetchGroups} />
                    </TabPanelContainer>
                </TabPanel>
            </TabContext>
        </Box>
        </>}
    </>)
}

export default GroupView;