import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '@store'
import { TMarker, TUser } from 'types/types'

import { fetchMarkersOneModule } from 'services/MarkersAPI'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { api as axios } from 'utils/axios'
import { IClientPublicPortal } from 'utils/clientsPublicPortal'

interface IEventsSlice {
  events: TMarker[]
  eventsStatus: any
  eventsFilterParams: any
}

export interface IFetchMarkersModule {
  publicAPI: boolean
  user: TUser | IClientPublicPortal
  keyModule: string
  url: string
  bounds: number[][]
  filterParams: any
}

const initialState: IEventsSlice = {
  events: [],
  eventsStatus: [],
  eventsFilterParams: {},
}

export const fetchEvents = createAsyncThunk(
  'events/fetchEvents',
  async (
    {
      publicAPI,
      user,
      keyModule,
      url,
      bounds,
      filterParams: params,
    }: IFetchMarkersModule,
    thunkAPI
  ) => {
    const response = await fetchMarkersOneModule(
      publicAPI,
      user,
      keyModule,
      url,
      bounds,
      params
    )
    return response
  }
)

export const fetchStatus: any = createAsyncThunk(
  'events/fetchEventsStatus',
  async ({ authToken, moduleID }: any, thunkAPI) => {
    try {
      const response = await axios.get(ServiceApiUrl.eventsTotalStatus, {
        headers: {
          Authorization: `token ${authToken}`,
          'MODULE-ID': moduleID,
        },
      })
      return response.data
    } catch (error) {
      console.error(error)
    }
  }
)

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setEvents(state, action) {
      state.events = action.payload
    },
    setEventsFilterParams(state, action) {
      state.eventsFilterParams = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      state.events = [...action.payload]
    })
    builder.addCase(fetchEvents.rejected, (state, action) => {
      state.events = []
    })
    builder.addCase(fetchStatus.fulfilled, (state, { payload }) => {
      // TO DO: Confirmar a posição dos estados no array

			// #NS TODO
			const items = payload.map((item:any)=> {
				return {...item, type:'total'}
			})

      state.eventsStatus = items;
    })
  },
})

export const { setEvents, setEventsFilterParams } =
  eventsSlice.actions

export const getEvents = (state: RootState) => state.events.events
export const selectEventsStatus = (state: RootState) =>
  state.events.eventsStatus
export const selectEventsFilterParams = (state: RootState) =>
  state.events.eventsFilterParams
export default eventsSlice.reducer
