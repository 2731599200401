import { TableCell, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IHydrogenSupply } from "types/interfaces";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";

const TableInformationRow = (props: { row: IHydrogenSupply }) => {
  const { row } = props;
  const { t } = useTranslation();
  return (
    <>
      <TableRow>
        {row.date && (
          <TableCell>
            {formatDate(row.date, t("calendar.dateTimeFormatLocal"))}
          </TableCell>
        )}
        {row.power && (
          <TableCell>{formatNumber(row.power.toFixed(1))}</TableCell>
        )}
        {row.quantity_supplied && (
          <TableCell>{formatNumber(row.quantity_supplied)}</TableCell>
        )}
        {row.supply_time_minutes && (
          <TableCell>{formatNumber(row.supply_time_minutes)}</TableCell>
        )}
        {row.consumed_energy && (
          <TableCell>{formatNumber(row.consumed_energy)}</TableCell>
        )}
         {row.produced_hydrogen && (
          <TableCell>{formatNumber(row.produced_hydrogen.toFixed(3))}</TableCell>
        )}
        {row.tank_capacity_kg && (
          <TableCell>{formatNumber(row.tank_capacity_kg.toFixed(2))}</TableCell>
        )}
        {/* {row.tank_capacity_l && (
          <TableCell>{formatNumber(row.tank_capacity_l.toFixed(2))}</TableCell>
        )} */}
        {row.temperature && (
          <TableCell>{formatNumber(row.temperature)}</TableCell>
        )}
        {row.pressure && <TableCell>{formatNumber(row.pressure)}</TableCell>}
        {row.total_hydrogen && (
          <TableCell>{formatNumber(row.total_hydrogen.toFixed(2))}</TableCell>
        )}
      </TableRow>
    </>
  );
};

export default TableInformationRow;
