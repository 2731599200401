/* eslint-disable react-hooks/exhaustive-deps */
import { useFeedback } from "@alb/live-lib";
import useUpdate from "hooks/fetchData/useUpdate";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IDialogProps } from "types/interfaces";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CategoriesForm, { IOccurrenceCategoryForm } from "./CategoriesForm";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

const CategoriesEdit = ({
  open,
  data,
  onClose,
  onSuccess,
}: IDialogProps<IOccurrenceCategoryForm>) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const editFormId = "edit-occurrence-category-form";

  const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

  //pedido para editar categorias das ocorrências
  const {
    loading,
    refetch: updateOccurrenceCategory,
  } = useUpdate(ServiceApiUrl.occurrencesCategories, data?.id, headers);

  // sucesso
  const handlerSuccess = () => {
    onClose && onClose(); //fecha modal
    addFeedback({
      message: t("feedback.success.changesSaved"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  const formSubmitHandler = async (payLoad: IOccurrenceCategoryForm) => {
    try {
      await updateOccurrenceCategory({ data: payLoad });
      await handlerSuccess();
    } catch (error) {}
  };

  const [ formIsValid, setFormIsValid ] = useState(false);

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
          {t("occurrences.editCategory")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <CategoriesForm
          formId={editFormId}
          data={data}
          onFormSubmit={formSubmitHandler}
          editForm={true}
          setFormIsValidStatus={setFormIsValid}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!formIsValid}
          type={"submit"}
          form={editFormId}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CategoriesEdit;
