import * as MUIcon from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

import { valueEmptyTo } from "utils/utils";

const MapEntiyInfoItem = (props: {
  label: string;
  value: string;
  icon?: string;
}) => {
  const { icon, label, value } = props;

  let mL = 0;
  if (icon && label) {
    mL = 0.5;
  }

  //permite enviar a string do respetivo icon pretendido
  const CustomIcon = icon && MUIcon[icon as keyof typeof MUIcon];

  return (
    <Grid item mb={2} xs={6}>
      <Grid item xs={12} sx={{ display: "flex", alignItems: "baseline" }}>
        {icon && CustomIcon && (
          <CustomIcon sx={{ fontSize: "12px", color: "text.secondary" }} />
        )}
        {label && (
          <Typography
            marginLeft={mL}
            marginRight={1}
            color={"text.secondary"}
            variant="subtitle2" 
          >
            {label}
          </Typography>
        )}
      </Grid>
      <Typography variant="h6"  fontWeight={"700"}>
        {valueEmptyTo(value)}
      </Typography>
    </Grid>
  );
};

export default MapEntiyInfoItem;
