import { IApiResponse } from "@alb/live-lib";
import useWebSocket from "react-use-websocket";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { INotificationAPI } from "types/interfaces";
import { getCookie } from "utils/cookie";
import { AUTH_TOKEN } from "utils/keys";

export const useWSNotifications = () => {
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket<
    IApiResponse<INotificationAPI>
  >(ServiceApiUrl.wsNotifications, {
    onError: (event: Event) => {
      console.log("error ws", event);
    },
    onClose: (event: CloseEvent) => {
      console.log("close ws", event);
    },
    onOpen: () => {
      const token: string | null = getCookie<string | null>(AUTH_TOKEN);
      if (token) {
        sendToken(token);
      }
    },
    //Will attempt to reconnect on all close events, such as server shutting down
    // devido a como se está a fazer o authentication, não é possivel utilizar o reconnect
    // shouldReconnect: () => true,
    // reconnectAttempts: 5,
    // //attemptNumber will be 0 the first time it attempts to reconnect,
    // // so this equation results in a reconnect pattern of 1 second, 2 seconds, 4 seconds, 8 seconds,
    // // and then caps at 10 seconds until the maximum number of attempts is reached
    // reconnectInterval: (attemptNumber) =>
    //   Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
  });

  const sendToken = (token: string) => {
    const authToken = { authentication: token };
    // console.log(authToken);
    sendJsonMessage(authToken);
  };

  return { lastMessage, readyState };
};

export default useWSNotifications;
