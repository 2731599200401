import { InputText, RequiredField } from '@alb/live-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { RequiredFields } from 'components/Utils/RequiredField';
import React, { useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'; 
import { useTranslation } from 'react-i18next';

import { formDefaultValues } from './formDefaultValues';
import { getEventCategoryFormSchema } from './validations';
import ImagePicker from 'components/occurrences/administration/categories/imagePicker/ImagePicker';
import IconsList from './iconList';

export interface IEventCategoryForm {
	id: string;
	name: string;
	icon?: string
}

export interface ICategoriesForm {
	formId: string;
	data?: IEventCategoryForm;
	editForm?: boolean;
	onFormSubmit: (payLoad: IEventCategoryForm) => void;
	setFormIsValidStatus?: (status: boolean) => void;
}

const CategoriesForm = ({ formId, data, editForm, onFormSubmit, setFormIsValidStatus }: ICategoriesForm) => {
	const { t } = useTranslation();

	const defaultValues = formDefaultValues(data);

	const methodsForm = useForm<IEventCategoryForm>({
		defaultValues: defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
		resolver: yupResolver(getEventCategoryFormSchema()),
	});

	const { control, handleSubmit, setValue , trigger} = methodsForm;

	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<IEventCategoryForm> = (
		payLoad: IEventCategoryForm
	) => {
		onFormSubmit(payLoad);
	};

	useEffect(() => {
		if (setFormIsValidStatus)
			setFormIsValidStatus(methodsForm.formState.isValid);
	}, [setFormIsValidStatus, methodsForm.formState.isValid]);

	return (
		<>
			<FormProvider {...methodsForm}>
				<form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<RequiredField title={t("common.name")} />
							<InputText
								control={control}
								name="name"
								placeholder={t("events.categoryNamePlaceholder")}
							/>
						</Grid>
						<Grid item xs={12}>
							<RequiredField title={t("occurrences.categoryIcon")} />
							<ImagePicker list={IconsList} name="icon" control={control} setValue={setValue} trigger={trigger}/>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
			<RequiredFields allRequired />
		</>
	);
};
export default CategoriesForm
