/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { ISelectOption } from "types/interfaces";

import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useTranslation } from "react-i18next";
import i18n from "i18n/config";

export const useTypeOptions = () => {
  const [typeOptions, setTypeOptions] = useState<ISelectOption[]>([]);

  const { data: allTypes, loading } = useGet<string[]>(
    ServiceApiUrl.wazeAlertsTypes,
    null
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (allTypes) {
      const typeOptionsSelect = allTypes?.map((type: string) => ({
        label: i18n.exists(`waze.types.${type}`) ? t(`waze.types.${type}`) : type,
        value: type,
      }));

      setTypeOptions(typeOptionsSelect);
    }
  }, [allTypes]);

  return { typeOptions, loading };
};
