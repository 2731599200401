/* eslint-disable react-hooks/exhaustive-deps */
import { TableHeadOrder } from "@alb/live-lib";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatOrderBy } from "utils/orderByAPI";
import { arrayIsEmpty } from "utils/utils";

import { EventsTable } from "./EventsTable";
import { FiltersSection } from "./FiltersSection";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

const EventsList = (props: { refetch?: boolean }) => {

	const { refetch } = props;
	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");

	const [filterSearch, setFilterSearch] = useState<string>("")
	const [filterStatus, setFilterStatus] = useState<string>('')
	const [filterType, setFilterType] = useState<string>('')

	const { headers } = useGetModuleHeaders(MODULES_KEYS.EVENTS);

	const {
		data: events,
		loading,
		error,
		refetch: refetchEvents,
	} = useGet(ServiceApiUrl.events, GetParams(), undefined, headers);

	function GetParams() {
		var params: {
			page: number;
			items: number;
			order_by: string;
			contains?: string
			status?: string
			type?: string
		} = {
			page: page,
			items: ROWS_PER_PAGE,
			order_by: formatOrderBy(order, orderBy),
			...(filterSearch !== "" && { contains: filterSearch }),
			...(filterStatus !== "" && { status: filterStatus }),
			...(filterType !== "" && { type: filterType }),
		};
		return params;
	}

	useEffect(() => {
		if (refetch) refetchEvents();
	}, [refetch]);

	let eventsList = <NoData error={error} />;

	if (loading) {
		eventsList = <Loading />;
	}

	if (!loading && events && !arrayIsEmpty(events.data)) {
		eventsList = (
			<EventsTable
				data={events}
				orderBy={orderBy}
				order={order}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				page={page}
				setPage={setPage}
				refetch={refetchEvents}
			/>
		);
	}

	return (
		<Grid item mt={4} data-testid={"EventsListContainer"}>
			<FiltersSection setPage={setPage}
				setFilterSearch={setFilterSearch}
				setFilterStatus={setFilterStatus}
				setFilterType={setFilterType}
			/>

			{eventsList}
		</Grid>
	);

};

export default EventsList;
