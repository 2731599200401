import load from "assets/images/map/markers/enabled/smartparking/load.svg"
import eletric from "assets/images/map/markers/enabled/smartparking/eletric.svg"
import meter from "assets/images/map/markers/enabled/smartparking/meter.svg"

import occupiedload from "assets/images/map/markers/disabled/smartparking/load.svg"
import occupiedeletric from "assets/images/map/markers/disabled/smartparking/eletric.svg"
import occupiedmeter from "assets/images/map/markers/disabled/smartparking/meter.svg"


const getUrlByType = (type: string, state: string | boolean | undefined = undefined) => {
    switch (type) {
        case "P":
            if(state)
                return occupiedmeter;
            return meter
        case "C":
            if(state)
                return occupiedload;
            return load
        case "E":
            if(state)
                return occupiedeletric;
            return eletric
    }
}

export default getUrlByType;