import { useFeedback } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IUserAddForm, IUserPermission } from "types/interfaces";
import { TModule } from "types/types";

import useCreate from "hooks/fetchData/useCreate";
import { ServiceApiUrl } from "services/ServiceApiUrl";

import UsersForm from "./UsersForm";

export interface IUsersAdd {
	clientID?: string;
	open: boolean;
	invite?: boolean;
	onClose: () => void;
	updateList: () => void;
	administration?: boolean;
	permissions?: IUserPermission[];
	allModules?: any;
}

const CRUD = "CRUD";

const UsersAdd = ({
	clientID,
	open,
	invite,
	onClose,
	updateList,
	administration,
	permissions,
	allModules,
}: IUsersAdd) => {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const addFormId = 'add-users-form'
	const [formIsValid, setFormIsValid] = useState(false);

	//   const { hasPermission } = useHasPermissions();
	//   const canAdminister = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);

	//create user
	const {
		loading,
		refetch: createUser,
	} = useCreate(ServiceApiUrl.user);

	//create user request
	const formSubmitHandler: SubmitHandler<IUserAddForm> = async (
		payLoad: IUserAddForm
	) => {
		const _payLoad = preparePayload(payLoad);
		try {
			await createUser({ data: _payLoad });
			await handlerSuccess();
		} catch (error) { }
	};

	function preparePayload(payLoad: IUserAddForm) {
		let _payLoad: IUserAddForm = {
			account: payLoad.account,
			email: payLoad.email,
			first_name: payLoad.first_name,
			last_name: payLoad.last_name,
			is_admin: payLoad.is_admin,
			language: payLoad.language,
			permission: [],
			username: payLoad.username,
		};
		//campos reservados a utilizadores superAdmin, e se for pela administração
		if (administration) {
			_payLoad.client = clientID;
			_payLoad.is_superadmin = payLoad.is_superadmin;
		}
		const perm: IUserPermission[] = [];
		allModules?.data.forEach((m: TModule) => {
			let value: string[] = [];
			CRUD.split("").forEach((c) => {
				const name = `${m.id}_${c}`;
				const t = (payLoad as unknown as any)[name];
				value.push(t ? "1" : "0");
			});
			if (value.join("") !== "0000") {
				perm.push({
					module_id: m.id,
					value: value.join(""),
				});
			}
		});
		payLoad.is_admin || payLoad.is_superadmin ? _payLoad.permission = [] : _payLoad.permission = perm;

		_payLoad.account = (typeof payLoad.account === 'string') ? payLoad.account : payLoad.account?.value || '';
		_payLoad.language = (typeof payLoad.language === 'string') ? payLoad.language : payLoad.language?.value || '';

		return _payLoad;
	}


	const handlerSuccess = () => {
		onClose();
		addFeedback({
			message: t("user.userAdded"),
			severity: "success",
		});
		updateList && updateList();
	};

	const getFormIsValidStatus = (status: boolean) => {
		setFormIsValid(status);
	};

	return (
		<Dialog maxWidth="md" fullWidth open={open}>
			<DialogTitle>
				<Typography
					component={"span"}
					color="text.black"
					gutterBottom={true}
					variant="h5"
					noWrap
				>
					{invite ? t("user.inviteUser") : t("user.addUser")}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<UsersForm
					formId={addFormId}
					onFormSubmit={formSubmitHandler}
					clientID={clientID}
					administration={administration}
					permissions={permissions}
					onChangeStatusForm={getFormIsValidStatus}
				/>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="primary" onClick={onClose}>
					{t("common.cancel")}
				</Button>

				<LoadingButton
					loading={loading}
					variant="contained"
					color="primary"
					disabled={!formIsValid}
					type={"submit"}
					form={addFormId}
				>
					{invite ? t("common.invite") : t("common.add")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default UsersAdd;
