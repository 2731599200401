import { TableHeadCell, TableHeadSort } from "@alb/live-lib";
import {
  Table,
  TableBody,
  TableContainer
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { TAdapterConfigurator } from "types/types";
import { IResultTable } from "types/interfaces";
import { arrayIsEmpty } from "utils/utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatRoutePath } from "utils/routePath";
import AdaptersTableRow from "./AdaptersTableRow";
import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";

export const AdaptersTable = (props: IResultTable) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: adapters,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    page,
    setPage
  } = props;

  const tableHeads: TableHeadCell[] = [
    {
      id: "name",
      label: t("common.name"),
    },
    {
      id: "is_active",
      label: t("common.statusLabel"),
    },
    {
      id: "domain",
      label: t("adapters.domain"),
    },
    {
      id: "updated_at",
      label: t("common.updatedAt"),
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  const handleRowClick = (row: TAdapterConfigurator) => {
    const path = formatRoutePath(location, params, {
      id: row.id,
    });
    navigate(path);
  };

  let row;
  if (!arrayIsEmpty(adapters.data)) {
    row = adapters.data.map((adapter: TAdapterConfigurator) => (
      <AdaptersTableRow
        key={adapter.id}
        row={adapter}
        onRowClick={handleRowClick}
      />
    ));
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHeadSort
            headCells={tableHeads}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>{row}</TableBody>
        </Table>
      </TableContainer>

			<CustomTablePagination
				count={adapters?.totalCount}
				page={page}
				totalPages={adapters?.totalPages}
				onPageChange={handleChangePage}
			/>
    </>
  );
};
