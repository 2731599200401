/* eslint-disable react-hooks/exhaustive-deps */
import { arrayIsEmpty, Loading, MiniSelect } from "@alb/live-lib";
import { Grid, Typography, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import ReactEcharts from "echarts-for-react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";
import { TStream } from "types/types";

import { colors, defaultOptions } from "components/charts/defaultOptions";
import { IReactECharts } from "components/charts/ReactECharts";
import NoData from "components/Utils/NoData";
import { formatNumber } from "utils/number";

import { IStreamValue } from "./AnalysisView";
import { IDeviceInfo } from "./CERManagementSelect";
import { formatDate } from "utils/date";

export interface IChartInfo {
	selectedDevice: IDeviceInfo;
	selectOptions?: ISelectOption[]; //caso se queira um select
	title: string;
	description?: string;
	setSelectedType?: React.Dispatch<React.SetStateAction<string>>;
	deviceStreamsValues: { object: IStreamValue[] } | undefined;
	loading: boolean;
	error: AxiosError<any, any> | null;
	selectedStreams: TStream[];
}

const LineChartAnalysisWidget = ({
	selectedDevice,
	selectOptions,
	title,
	description,
	setSelectedType,
	deviceStreamsValues: values,
	loading,
	error,
	selectedStreams,
}: IChartInfo) => {
	const { t } = useTranslation();
	const theme = useTheme();

	const [selectedOption, setSelectedOption] = useState<string>(
		selectOptions?.[0].value || ""
	);
	//guarda os values das streams do device selecionado
	const [dataChart, setDataChart] = useState<IStreamValue[]>([]);
	const [chartOptions, setChartOptions] = useState<IReactECharts["option"]>({});

	function changeOption(e: ISelectOption) {
		setSelectedOption(e.value);
	}
	//quando o device alterar, dá reset aos valores
	useEffect(() => {
		setDataChart([]);
		setSelectedOption(selectOptions?.[0].value || "");
	}, [selectedDevice]);

	useEffect(() => {
		setDataChart(values?.object || []);
	}, [values]);

	useEffect(() => {
		setSelectedType?.(selectedOption);
	}, [selectedOption]);

	const selectValue = () => {
		return (
			selectOptions?.find(
				(option: ISelectOption) => option.value === selectedOption
			) || ""
		);
	};
	//recarrega gráfico caso os valores ou as streams alterem
	useEffect(() => {
		addChartOptions();
	}, [dataChart, dataChart?.length, selectedStreams]);

	const addChartOptions = useCallback(() => {
		let seriesArray: any[] = [];
		let mapXAxisValues: string | string[] = [];

		mapXAxisValues = dataChart?.map((v: any) => formatDate(v.collect_date, t("calendar.dateTimeFormatLocal")));

		selectedStreams?.forEach((element: TStream) => {
			seriesArray = [
				...seriesArray,
				{
					name: element.unit
						? `${t(`streams.${element.name.toLowerCase()}`)} (${element.unit})`
						: "",
					data: dataChart?.map((s: any) => {
						return s[element?.name];
					}),
					type: "line",
					lineStyle: { width: 1 },
					showSymbol: false,
				},
			];
		});

		const options: IReactECharts["option"] = {
			tooltip: {
				...defaultOptions.tooltip,
				backgroundColor: theme.palette.background.paper,
				textStyle: {
					color: theme.palette.mode === "light" ? "" : theme.palette.common.white,
				}
			},
			textStyle: {
				fontFamily: theme.typography.fontFamily,
				fontSize: 13,
			},
			xAxis: {
				offset: 10,
				boundaryGap: false,
				axisTick: { show: false },
				type: "category",
				data: mapXAxisValues,
				axisLine: {
					lineStyle: {
						color: theme.palette.mode !== "light" ? theme.palette.common.white : "",
					},
				},
			},
			grid: {
				left: "2%",
				right: "5%",
				containLabel: true,
			},
			color: colors,
			yAxis: {
				name: selectedStreams?.[0]?.unit,
				type: "value",
				nameLocation: "end",
				nameTextStyle: {
					padding: [0, 30, 0, 0],
				},
				axisLabel: {
					formatter: (val: any) => formatNumber(val) as any,
				},
				splitLine: {
					lineStyle: {
						color: "#F0F0F0",
					},
				},
				axisLine: {
					lineStyle: {
						color: theme.palette.mode === "light" ? "#333333" : theme.palette.common.white,
					},
				},
			},
			legend: {
				show: true,
				orient: "horizontal",
				bottom: 0,
				textStyle: {
					color: theme.palette.text.primary,
					fontFamily: theme.typography.fontFamily,
					fontSize: 13,
				},
				itemHeight: 16,
				itemWidth: 16,
				icon: "circle",
				itemGap: 25,
				left: 0,
			},
			series: seriesArray,
		};
		setChartOptions(options);
	}, [dataChart, dataChart.length, selectedStreams]);

	return (
		<>
			<Typography variant="h3" fontSize={"22px"}>
				{selectedOption === "power" &&
					selectedDevice.domain === "inversor_fotovoltaico"
					? t("cerManagement.powerComparison")
					: title}
			</Typography>
			<Grid
				container
				justifyContent="space-between"
				direction="row"
				alignItems="center"
			>
				{description && <Typography variant="body1">{description}</Typography>}
				<Grid
					item
					sx={{
						display: "inline-flex",
						alignItems: "baseline",
					}}
				>
					{selectOptions && (
						<MiniSelect
							onChangeOption={changeOption}
							showLabel
							options={selectOptions}
							value={selectValue()}
							sx={{ "& .MuiOutlinedInput-input": { padding: 0 } }}
						/>
					)}
				</Grid>
			</Grid>

			<Loading show={loading} />
			{!loading && !error && !arrayIsEmpty(dataChart) && chartOptions && (
				<ReactEcharts
					style={{ height: "308px" }}
					option={chartOptions}
					notMerge={true}
				/>
			)}
			{!loading && (arrayIsEmpty(dataChart) || !chartOptions || error) && (
				<NoData error={error} />
			)}
		</>
	);
};

export default LineChartAnalysisWidget;
