export interface IClientPublicPortal {
  id: string,
  name: string,
  city: string,
  centerPoint: number[],
  url: string,
}

const clientsPublicPortal = [
  {
    id: "aeb3a64c-668a-483e-ba46-87166a07ce23",
    name: "CMFunchal",
    city: "funchal",
    centerPoint: [32.6502497847944, -16.91954216631708],
    url: "/funchal",
  },
  // TODO se existirem mais clientes deve-se declarar aqui
]

// informação hardcode do client através do url
export function clientPublicPortal(urlClient:string) {
  return clientsPublicPortal.find((client: IClientPublicPortal) => client.url === urlClient)
}

// array com os urls dos possiveis portais (pois pode haver + que 1 client)
export function urlsPublicPortal() {
  return clientsPublicPortal.map((client: IClientPublicPortal) => {return client.url})
}