import { DynamicForm, FilterSection, IDynamicInputTemplate, IFormDefinition, TypeDynamicInput } from "@alb/live-lib";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { debounce } from "lodash";
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";

import { stringIsEmpty } from "utils/utils";

interface ISearch {
	name: string;
	status: string | null;
}
interface IColXTemplate extends IDynamicInputTemplate {
	xsCol: 'auto' | number | boolean
}
const ColXTemplate = ({ inputLabel, children, xsCol }: IColXTemplate) => {

	return (<Grid item xs={xsCol}>
		{inputLabel && <Typography gutterBottom variant="h6">
			{inputLabel}
		</Typography>
		}
		{children}
	</Grid>)
}

export const FiltersSection = (props: {
	setPage: React.Dispatch<React.SetStateAction<number>>;
	setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
	setFilterStatus: React.Dispatch<React.SetStateAction<string>>;
}) => {
	const { t } = useTranslation();
	const { setPage, setFilterSearch, setFilterStatus } = props;

	const [filtersCount, setFiltersCount] = useState<number>(0);
	const [search, setSearch] = useState<string>("");
	const [status, setStatus] = useState<string>("");

	const debounceChangeName = debounce((cb) => {
		cb();
	}, 350);
	const handleChangeName = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		debounceChangeName(() => {
			setPage(1);
			//se o valor anterior for vazio
			if (stringIsEmpty(search)) {
				addFilterCount(); //adiciona à contagem de filtros
			} else if (stringIsEmpty(e.target.value)) {
				removeFilterCount(); //se o input estiver vazio, remove da contagem de filtros
			}
			setSearch(e.target.value);
		})
	};
	const handleChangeStatus = (
		e: SyntheticEvent<Element, Event>,
		value: ISelectOption
	) => {
		setPage(1);
		//se existir opção selecionada
		if (value && value.value) {
			//se o valor anterior for vazio
			if (stringIsEmpty(status)) {
				addFilterCount(); //adiciona à contagem de filtros
			}
			setStatus(value.value);
		}
		//se não existir opção selecionada
		else {
			removeFilterCount(); //se for removida a opção do select, remove da contagem do filtro
			setStatus("");
		}
	};

	const statusOptionsList = [
		{
			label: t("common.active"),
			value: "true",
		},
		{
			label: t("common.inactive"),
			value: "false",
		},
	];

	const template = useCallback(
		({ inputLabel, children }: IDynamicInputTemplate) => {
			return <ColXTemplate xsCol={true} inputLabel={inputLabel} children={children} />
		},
		[],
	)

	const dynamicForm = {
		formId: 'form-search',
		inputsDefinition: {
			name: {
				inputType: TypeDynamicInput.text,
				label: t("common.search"),
				placeholder: t("common.typeToSearchPlaceholder"),
				defaultValue: '',
				handleOnChange: handleChangeName,
				template: template,
			},
			status: {
				inputType: TypeDynamicInput.autocomplete,
				label: t("adapters.status"),
				placeholder: t("adapters.statusPlaceholder"),
				options: statusOptionsList,
				defaultValue: null,
				handleOnChange: handleChangeStatus,
				template: template,
			}
		}
	}

	const defaultValues: ISearch = {
		name: dynamicForm.inputsDefinition.name.defaultValue,
		status: dynamicForm.inputsDefinition.status.defaultValue
	};
	const methodsForm = useForm<ISearch>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: defaultValues,
		// resolver: yupResolver(getResolverSchema()),
	});

	const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm }

	const addFilterCount = () => {
		setFiltersCount((prevValue) => prevValue + 1);
	};
	const removeFilterCount = () => {
		setFiltersCount((prevValue) => {return prevValue > 0 ? prevValue-1 : 0});
	};

	useEffect(() => {
		setFilterSearch(search);
	}, [search, setFilterSearch]);

	useEffect(() => {
		setFilterStatus(status);
	}, [status, setFilterStatus]);

	const cleanFilters = () => {
		methodsForm.reset();
		setSearch("");
		setStatus("");
		setFiltersCount(0);
	};

	const showClearFilters = () => {
		return filtersCount > 1
	}

	return (
		<>
			<FilterSection
				activeFiltersCount={filtersCount}
				filterTitle={t("common.filter")}
			>
				<Grid container>
					<Grid item xs={showClearFilters() ? 9 : 12}>
						<DynamicForm formDefinition={formDefinition} />
					</Grid>
					{showClearFilters() && (
						<Grid mb={1} item xs={true} display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
							<Button variant="outlined" onClick={cleanFilters}>
								{t("common.cleanFilters")}
							</Button>
						</Grid>
					)}
				</Grid>
			</FilterSection>
		</>
	);
};
