import { IRange } from "@alb/live-lib";
import { Card } from "@mui/material";

import { BugaHeatMapBikes } from "./maps/BugaHeatMapBikes";

interface IBugaBikesDashboard {
  selectedDateRange: IRange | undefined;
}

export const BugaBikesDashboard = ({
  selectedDateRange,
}: IBugaBikesDashboard) => {
  return (
    <>
      <Card>
        <BugaHeatMapBikes selectedDateRange={selectedDateRange} />
      </Card>
    </>
  );
};
