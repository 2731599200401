import { PERMISSIONS } from "../permissions";
import { userPermissions } from "./userPermissions";

const permissions = [
  ...userPermissions,
  ...Object.values(PERMISSIONS.MAP),
  ...Object.values(PERMISSIONS.DASHBOARD),
  PERMISSIONS.MANAGEMENT.CREATE,
  PERMISSIONS.MANAGEMENT.UPDATE,
  PERMISSIONS.MANAGEMENT.DELETE,
  ...Object.values(PERMISSIONS.MANAGEMENT.USERS),
  ...Object.values(PERMISSIONS.MANAGEMENT.ADAPTERS),
  ...Object.values(PERMISSIONS.MANAGEMENT.ACCOUNTS),
  ...Object.values(PERMISSIONS.MANAGEMENT.ALERTS),
  ...Object.values(PERMISSIONS.MANAGEMENT.GROUPS),
  ...Object.values(PERMISSIONS.ABOUT),
];
export const adminPermissions = permissions.filter(
  (value, index, self) => self.indexOf(value) === index
);
