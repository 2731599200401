/* eslint-disable react-hooks/exhaustive-deps */
import { useFeedback } from "@alb/live-lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IDialogProps } from "types/interfaces";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CategoriesForm, { IEventCategoryForm } from "./CategoriesForm";
import useCreate from "hooks/fetchData/useCreate";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

const CategoriesAdd = ({
  open,
  onClose,
  onSuccess,
}: IDialogProps<IEventCategoryForm>) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const addFormId = "add-event-category-form";

  const { headers } = useGetModuleHeaders(MODULES_KEYS.EVENTS);

  //pedido para adicionar categoria dos eventos
  const {
    loading,
    refetch: addEventCategory,
  } = useCreate(ServiceApiUrl.eventsCategories, undefined, headers);

  // sucesso
  const handlerSuccess = () => {
    onClose && onClose(); //fecha modal
    addFeedback({
      message: t("events.categoryAdded"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  const formSubmitHandler = async (payLoad: IEventCategoryForm) => {
    try {
      await addEventCategory({ data: payLoad });
      await handlerSuccess();
    } catch (error) {}
  };

  const [ formIsValid, setFormIsValid ] = useState(false);

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
          {t("events.addCategory")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <CategoriesForm
          formId={addFormId}
          onFormSubmit={formSubmitHandler}
          editForm={true}
          setFormIsValidStatus={setFormIsValid}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!formIsValid}
          type={"submit"}
          form={addFormId}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CategoriesAdd;
