import { arrayIsEmpty, LayoutContext } from "@alb/live-lib";
import { Add } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { EventAdd } from "components/events/EventAdd";
import EventsList from "components/events/EventList";
import { ModuleCards } from "components/modules/ModuleCards";
import usePermission from "hooks/usePermission";
import { getUser } from "store/slices/authSlice";
import { fetchStatus, selectEventsStatus } from "store/slices/eventsSlice";
import { setDrawingMap, setDrawnCoords } from "store/slices/mapSlice";
import { getCookie } from "utils/cookie";
import { AUTH_TOKEN } from "utils/keys";
import { getSelectedModule } from "utils/modules/modules";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { PERMISSIONS } from "utils/permissions/permissions";

const Events = () => {
  // global component variables
  const { addHeader, addAction, action } = useContext(LayoutContext);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const eventsStatus = useSelector(selectEventsStatus);
  const { t } = useTranslation();

  // fetch status function
  const fetchEventStatus = () => {
    const authToken = getCookie<string>(AUTH_TOKEN);
    const moduleID = getSelectedModule(user, MODULES_KEYS.EVENTS);

    return dispatch(fetchStatus({ authToken, moduleID }));
  };

  // user permissions
  const { hasPermission } = usePermission();
  const canCreate = hasPermission(PERMISSIONS.MODULES.EVENTS.CREATE);

  // local state management
  const [added, setAdded] = useState<boolean>(false);
  const [status, setStatus] = useState<any>(eventsStatus);
  const [loading, setLoading] = useState<boolean>(true);
  const error = null;

  // page header configuration
  const header = {
    title: t("events.events"),
    action: canCreate && (
      <Button
        variant="contained"
        disableElevation
        startIcon={<Add />}
        onClick={() => addAction(true)}
      >
        {t("events.addEvent")}
      </Button>
    ),
  };

  // component handlers
  const onCloseDialog = () => {
    addAction(false);
    dispatch(setDrawingMap(false));
    dispatch(setDrawnCoords(null));
  };

  // extra components && extra handlers
  let eventAdd;
  if (action) {
    eventAdd = canCreate && (
      <EventAdd
        open={action}
        onClose={onCloseDialog}
        onSuccess={() => {
          setAdded(true);
          fetchEventStatus();
          onCloseDialog();
        }}
      />
    );
  }

  // component effects
  useEffect(() => {
    addHeader(header);
    fetchEventStatus();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (eventsStatus.length > 0) {
      setStatus((prev: []) => (prev = eventsStatus));
      setLoading((prev: any) => (prev = false));
    }
  }, [eventsStatus]);

  const clientHasEvents = status[0]?.value >= 1;

  return (
    <>
      {!arrayIsEmpty(status) && clientHasEvents && (
        <Box data-testid={"EventsCardsContainer"}>
          <ModuleCards
            module="events"
            items={status}
            loading={loading}
            error={error}
            sx={{ mt: 5 }}
          />
        </Box>
      )}
      <Box sx={{ mt: 4 }}>
        {/* <ToggleViewButton
          display={display}
          toggle={toggleConfig}
          onChange={handleDisplay}
          sx={{
            height: '35px',
            position: 'absolute',
          }}
        />
        {display === 'list' && <EventsList refetch={added} />}
        {display === 'cards' && null}
        */}
        <EventsList refetch={added} />
      </Box>
      {eventAdd}
    </>
  );
};

export default Events;
