import { Trans, useTranslation } from "react-i18next";

import { AppLogo } from "@alb/live-lib";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import styles from "styles/modules/about.module.scss";

import iconStudies from "assets/images/about/goals/studies-colored.svg";
import iconDigital from "assets/images/about/goals/digital-transformation-colored.svg";
import iconIman from "assets/images/about/goals/iman-colored.svg";
import iconCity from "assets/images/about/goals/city-colored.svg";
import iconBook from "assets/images/about/goals/book-colored.svg";
import imgCMAveiro from "assets/images/about/partners/cmaveiro.svg";
import imgALB from "assets/images/about/partners/alb.png";
import imgCEDES from "assets/images/about/partners/cedes.png";
import imgUA from "assets/images/about/partners/ua.png";
import imgInovaria from "assets/images/about/partners/inovaria.svg";
import imgIT from "assets/images/about/partners/it.png";
import imgCoFinancing from "assets/images/about/financiamento.png";

const AboutCMAveiro = () => {
  const { t } = useTranslation();

  const stylePartnerAvatar = {
    width: 120,
    height: 120,
    ">.MuiAvatar-img": {
      objectFit: "contain",
    },
  };

  return (
    <Grid container>
      <Grid container item mt={4}>
        <Typography variant="h1">Aveiro STEAM City</Typography>
      </Grid>
      <Grid container item mt={10}>
        <Typography variant="body1" align="justify">
          {t("about.paragraph1")}
        </Typography>
      </Grid>
      <Grid container item mt={1}>
        <Typography variant="body1" align="justify">
          {t("about.paragraph2")}
        </Typography>
      </Grid>
      <Grid container item className={styles["goals-grid"]} mt={10}>
        <Grid item py={8} pr={8}>
          <Typography variant="h2" gutterBottom>
            {t("about.goals")}
          </Typography>
          <Typography variant="body1">
            <Trans
              i18nKey="about.paragraph3"
              components={{
                span: <span className={styles["bold-text"]} />,
              }}
            />
          </Typography>
        </Grid>
        <Card sx={{ backgroundColor: "transparent", p: 2 }} variant="outlined">
          <CardContent>
            <AppLogo
              src={iconStudies}
              variant="rounded"
              sx={{ width: 72, height: 72 }}
            />
            <Typography variant="body1">
              <Trans
                i18nKey="about.goal1"
                components={{
                  span: <span className={styles["goals-card-title"]} />,
                  br: <br />,
                }}
              />
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: "transparent", p: 2 }} variant="outlined">
          <CardContent>
            <AppLogo
              src={iconDigital}
              variant="rounded"
              sx={{ width: 72, height: 72 }}
            />
            <Typography variant="body1">
              <Trans
                i18nKey="about.goal2"
                components={{
                  span: <span className={styles["goals-card-title"]} />,
                  br: <br />,
                }}
              />
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid container item mt={2} className={styles["goals-grid"]}>
        <Card sx={{ backgroundColor: "transparent", p: 2 }} variant="outlined">
          <CardContent>
            <AppLogo
              src={iconIman}
              variant="rounded"
              sx={{ width: 72, height: 72 }}
            />
            <Typography variant="body1">
              <Trans
                i18nKey="about.goal3"
                components={{
                  span: <span className={styles["goals-card-title"]} />,
                  br: <br />,
                }}
              />
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: "transparent", p: 2 }} variant="outlined">
          <CardContent>
            <AppLogo
              src={iconCity}
              variant="rounded"
              sx={{ width: 72, height: 72 }}
            />
            <Typography variant="body1">
              <Trans
                i18nKey="about.goal4"
                components={{
                  span: <span className={styles["goals-card-title"]} />,
                  br: <br />,
                }}
              />
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ backgroundColor: "transparent", p: 2 }} variant="outlined">
          <CardContent>
            <AppLogo
              src={iconBook}
              variant="rounded"
              sx={{ width: 72, height: 72 }}
            />
            <Typography variant="body1">
              <Trans
                i18nKey="about.goal5"
                components={{
                  span: <span className={styles["goals-card-title"]} />,
                  br: <br />,
                }}
              />
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid container item mt={8}>
        <Typography variant="body1" align="justify">
          {t("about.paragraph4")}
        </Typography>
        <Typography variant="body1" align="justify">
          {t("about.paragraph5")}
        </Typography>
      </Grid>
      <Grid container item mt={1}>
        <Typography variant="body1" align="justify">
          {t("about.paragraph6")}
        </Typography>
      </Grid>
      <Grid container item mt={10}>
        <Typography variant="h2">{t("about.partners")}</Typography>
      </Grid>
      <Grid container item mt={1} justifyContent="space-between">
        <AppLogo src={imgCMAveiro} variant="square" sx={stylePartnerAvatar} />
        <AppLogo src={imgALB} variant="square" sx={stylePartnerAvatar} />
        <AppLogo src={imgCEDES} variant="square" sx={stylePartnerAvatar} />
        <AppLogo src={imgUA} variant="square" sx={stylePartnerAvatar} />
        <AppLogo src={imgInovaria} variant="square" sx={stylePartnerAvatar} />
        <AppLogo src={imgIT} variant="square" sx={stylePartnerAvatar} />
      </Grid>
      <Grid container item mt={10}>
        <Grid item xs={12}>
          <Typography variant="h2">{t("about.co-financing")}</Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <AppLogo
            src={imgCoFinancing}
            variant="square"
            sx={{
              width: 520,
              height: 120,
              ">.MuiAvatar-img": {
                objectFit: "contain",
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutCMAveiro;
