import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ISelectedDeviceAndStreams,
  ISelectedDevicesAndOptions,
} from "types/interfaces";
import { TAdapterConfigurator, TDevice, TStream } from "types/types";
import DoubleSelectDeviceStreams from "../double-select-device-streams/DoubleSelectDeviceStreams";
import LiveToogleButton from "../toogle-button/LiveToggleButton";
import CloseIcon from "@mui/icons-material/Close";

interface IEditChart {
  open: boolean;
  chartToEditAux?: ISelectedDevicesAndOptions;
  handlerClose(): void;
  setChartToEdit: Dispatch<
    SetStateAction<ISelectedDevicesAndOptions | undefined>
  >;
  adapters: TAdapterConfigurator[];
  getSubmittedData(data: ISelectedDevicesAndOptions): void;
}

const EditChart = ({
  open,
  handlerClose,
  chartToEditAux,
  setChartToEdit,
  adapters,
  getSubmittedData,
}: IEditChart) => {
  const { t } = useTranslation();

  const [chartType, setChartType] = useState("");
  function getChartType(type: string) {
    setChartType(type);
  }
  //guarda os devices e as streams selecionadas
  const [firstItemSelected, setFirstItemSelected] =
    useState<ISelectedDeviceAndStreams>({
      device: undefined,
      streams: [],
    });
  const [secondItemSelected, setSecondItemSelected] =
    useState<ISelectedDeviceAndStreams>({
      device: undefined,
      streams: [],
    });

  //função que vai devolver os devices e as streams do filho (selects)
  function getFirstDevicesAndStreams(device: TDevice, streams: TStream[]) {
    setFirstItemSelected({
      ...firstItemSelected,
      device: device,
      streams: streams,
    });
  }
  function getSecondDevicesAndStreams(device: TDevice, streams: TStream[]) {
    setSecondItemSelected({
      ...secondItemSelected,
      device: device,
      streams: streams,
    });
  }

  const formSubmitHandler = () => {
    if (chartToEditAux) {
      //devolve o gráfico editado ao ComparativeAnalysis
      getSubmittedData({
        ...chartToEditAux,
        firstItem: firstItemSelected,
        secondItem: secondItemSelected,
        chartType,
        // range:chartToEditAux?.range
      });
    }
    handlerClose();
  };

  const closeAndCancel = () =>{
    handlerClose();
    cancelEdit();
  }

  function cancelEdit() {
    setChartToEdit(undefined);
  }

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
      >
        <DialogTitle id="alert-dialog-title-edit-chart">
          <Typography
            component={"span"}
            gutterBottom={true}
            variant="h5"
            noWrap
          >
            {t("comparativeAnalysis.editChart")}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeAndCancel}
            size="small"
            sx={{
              position: "absolute",
              right: 10,
              top: 15,
            }}
            title={t("close")}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Grid container spacing={2}>
            {adapters.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Typography gutterBottom color="text.black" variant="body1">
                    {t("comparativeAnalysis.chartType")}
                  </Typography>

                  <LiveToogleButton
                    defaultValue={chartToEditAux?.chartType}
                    firstOption={{
                      label: t("comparativeAnalysis.barChart"),
                      value: "bar",
                    }}
                    secondOption={{
                      label: t("comparativeAnalysis.lineChart"),
                      value: "line",
                    }}
                    getValue={getChartType}
                  />
                </Grid>

                {adapters.length > 0 && chartToEditAux && (
                  <>
                    <DoubleSelectDeviceStreams
                      defaultItem={chartToEditAux.firstItem}
                      deviceNumber="1"
                      adapters={adapters}
                      setDevicesAndStreams={getFirstDevicesAndStreams}
                    />

                    <DoubleSelectDeviceStreams
                      defaultItem={chartToEditAux.secondItem}
                      deviceNumber="2"
                      adapters={adapters}
                      setDevicesAndStreams={getSecondDevicesAndStreams}
                    />
                  </>
                )}
              </>
            )}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={closeAndCancel}
          >
            {t("cancel")}
          </Button>

          <Button
            variant="contained"
            disabled={
              !(
                firstItemSelected.streams.length > 0 &&
                secondItemSelected.streams.length > 0
              )
            }
            onClick={formSubmitHandler}
          >
            {t("common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default EditChart;
