
import { useEffect, } from 'react'

const ReloadPage = (props: { interval: number }) => {
	const { interval } = props;

	useEffect(() => {
		const intervalId = setInterval(() => {
			window.location.reload();
		}, interval);

		return () => {
			clearInterval(intervalId);
		};

	}, [interval]);

	return null
}

export default ReloadPage
