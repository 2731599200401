import { ChipCustom } from "@alb/live-lib";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton, SvgIcon, TableCell, TableRow, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TOccurrence } from "types/types";

import { moduleCardsIcons } from "components/modules/cards/moduleCardsIcons";
import usePermission from "hooks/usePermission";
import { formatDate } from "utils/date";
import { PERMISSIONS } from "utils/permissions/permissions";

import { getColorUrgency } from "./utils/colors";

const OccurrencesTableRow = (props: {
  row: TOccurrence;
  onRowClick: (row: TOccurrence) => void;
  onEdit: (row: TOccurrence) => void;
  onDeleteClick: (row: TOccurrence) => void;
}) => {
  const { row, onRowClick, onEdit, onDeleteClick } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const { hasPermission } = usePermission();
  const canEdit = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.UPDATE);
  const canDelete = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.DELETE);

  function showIconWithLabel(label: string) {
    const nameColumn = label.toLowerCase();
    return (
      <Box sx={{ display: "flex" }}>
        {moduleCardsIcons[nameColumn] && (
          <SvgIcon
            sx={{
              fontSize: "19.95px",
              marginRight: "5px",
              "> path": {
                fill: theme.palette.mode === "light" ? theme.palette.neutral5.main :  theme.palette.neutral5.dark,
              },
            }}
            component={moduleCardsIcons[nameColumn]}
            inheritViewBox
          />
        )}
        {label}
      </Box>
    );
  }
  return (
    <>
      <TableRow
        sx={{ cursor: "pointer", height: 44 }}
        onClick={(e): void => {
          e.stopPropagation();
          onRowClick(row);
        }}
      >
        {/* <TableCell>{row.type ? t(`occurrences.${row.type}`) : ''}</TableCell> */}
        <TableCell>
          {row?.status ? showIconWithLabel(row.status.name) : "--"}
        </TableCell>
        <TableCell data-testid={"occurrenceCategoryCell"}>{row?.category?.name}</TableCell>
        <TableCell className="truncate-3" title={row.address}>{row.address}</TableCell>
        {/* <TableCell>
          {row.geolocation.iconPosition
            ? JSON.stringify(row.geolocation.iconPosition)
            : "--"}
        </TableCell> */}
        <TableCell>
          {formatDate(row.record_date, t("calendar.dateTimeFormatLocal"))}
        </TableCell>
        <TableCell>{row.assignee ? row.assignee.assignee_name : "--"}</TableCell>
        <TableCell>
          {row.urgency ? (
            <ChipCustom
              label={row.urgency.name}
              colorChip={getColorUrgency(row.urgency.name).chip}
              colorLabel={getColorUrgency(row.urgency.name).label}
            />
          ) : (
            "--"
          )}
        </TableCell>

        <TableCell>
          {row.resolution_days !==null
            ? row.resolution_days + " " + t("calendar.days")
            : "--"}
        </TableCell>

        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
          {canEdit && (
            <IconButton
              title={t("common.edit")}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
                onEdit(row);
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          {canDelete && (
            <IconButton
              title={t("common.delete")}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
                onDeleteClick(row);
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default OccurrencesTableRow;
