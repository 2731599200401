import { Card, CardContent, Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

import { IParkingChartData } from "pages/Parking";

import ParkingBarChart from "./charts/ParkingBarChart";
import ParkingPieChart from "./charts/ParkingPieChart";

const ParkingCharts = (props: { pieChartData: IParkingChartData[] | undefined, loading:boolean, error:AxiosError<any, any> | null;}) => {

    const { pieChartData, loading, error } = props;
    const { t } = useTranslation();

    return(<>
        
        <Grid 
            mb={3}
			container
			columnSpacing={5}
			rowSpacing={3}
			mt={4}
			justifyContent="space-between"
			sx={{ pt: '35px' }}>



            <Grid item xs={12} sm={12}>
                <Typography variant="h3" noWrap>
                {t("parking.analisys")}
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Card data-testid={"parkingTimeChartContainer"}>
					<CardContent sx={{height: "100%"}}>
                        <ParkingBarChart />
                    </CardContent>
                </Card>
                
            </Grid>
            <Grid item xs={12} sm={6}>
                <Card data-testid={"parkingTypeChartContainer"}>
					<CardContent sx={{height: "100%"}}>
                        <ParkingPieChart loading={loading} data={pieChartData} error={error}/>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    </>)
}

export default ParkingCharts;