import { styled } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import { getDefaultBoxShadow } from '@alb/live-lib'

export const TabPanelContainer = styled(Grid)<any>(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'light'
      ? theme.palette.common.white
      : theme.palette.greyColor.dark,
  boxShadow: getDefaultBoxShadow(),
  borderRadius: 12,
  padding: theme.spacing(3),
}))
