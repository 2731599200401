/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { CustomLeftArrow, CustomRightArrow } from "./CarouselArrows";
import "react-multi-carousel/lib/styles.css";
import { Loading } from "@alb/live-lib";
import { formatStream } from "utils/utils";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const WeatherCarousel = ({
  deviceType,
  deviceSelected,
  handleOnChange,
  setLoadingStreams,
}: any) => {
  const { t } = useTranslation();
  // valores das streams para o DetailWidget
  const [streamsDetailWidget, setStreamsDetailWidget] = useState<object[]>([]);

  // get list of gateways
  const {
    refetch,
    loading,
  } = useGet(`${ServiceApiUrl.devices}/${deviceSelected}`, null, {
    manual: true,
  });

  const sendRequestDeviceDetails = async () => {
    await refetch()
      .then((res) => {
        const response = res.data;
        const streams = Object.keys(response.last_read_value).map(
          (stream: any) => {
            return {
              name: t("streams." + stream.toLowerCase()),
              value: formatStream(response.last_read_value[stream]) ?? "--",
              selected: false,
              streamName: stream,
            };
          }
        );
        const value = streams[0];
        setStreamsDetailWidget(
          streams.map((el: any) =>
            el?.name === value.name
              ? { ...value, selected: true }
              : { ...el, selected: false }
          )
        );
        handleOnChange?.(value);
      })
      .catch((error) => {
        // dispatch(setSelectedDeviceInfo(null));
      });
  };

	useEffect(() => {
		if (deviceSelected) {			
      sendRequestDeviceDetails();
    }
	}, [deviceSelected])

  function onClickDialog(value: any) {
    setStreamsDetailWidget(
      streamsDetailWidget.map((el: any) =>
        el?.name === value.name
          ? { ...value, selected: true }
          : { ...el, selected: false }
      )
    );
    handleOnChange?.(value);
  }

  useEffect(() => {
    setLoadingStreams(loading);
  }, [loading]);

  return (
    <>
      <Loading show={loading} />
      {!loading && (
        <div className="carousel-wrapper">
          <Carousel
            ssr
            className="carousel"
            renderArrowsWhenDisabled
            // partialVisbile
            deviceType={deviceType}
            // itemClass="image-item"
            responsive={responsive}
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
          >
            {/* {images.slice(0, 5).map(image => {
        return (
          <img
          draggable={false}
          alt="text"
          style={{ width: "100%", height: "100%" }}
          src={image}
        />
        );
      })} */}
            {streamsDetailWidget.map((image: any, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => onClickDialog(image)}
                  // className='horizontal-scroll-card'
                  className={
                    image?.selected
                      ? "horizontal-scroll-card horizontal-scroll-card-active"
                      : "horizontal-scroll-card"
                  }
                >
                  <div className="horizontal-scroll-card-title">
                    {image?.name}
                  </div>
                  <div>
                    <span>{image?.value}</span>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default WeatherCarousel;
