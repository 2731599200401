import { AccordionDetails, Typography } from "@mui/material";
import { formatNumber } from "utils/number";

interface IIsoDetail {
    numeratorDescription: string,
    numeratorValue: number,
    denominatorDescription: string,
    denominatorValue: number,
    source: string,
    year: number
}


const IsoDetails = (props: IIsoDetail ) => {

    const numeratorStyle = {
        fontSize: "12px",
        display: "inline-block"
    }

    const sourceStyle = {
        fontWeight: "bold",
        fontSize: "12px",
        display: "inline-block"
    }

    const valueStyle = {
        color: "#51bfbc",
        fontSize: "12px",
        display: "inline-block",
        alignItems: "center"
    }

    const numeratorDescription = props.numeratorDescription;
    const numeratorValue = formatNumber(Math.ceil(props.numeratorValue))

    const denominatorDescription = props.denominatorDescription;
    const denominatorValue = formatNumber(Math.ceil(props.denominatorValue))

    const source = props.source;
    const year = props.year;

    return(
        <>
            <AccordionDetails>
                        <Typography>
                            <Typography component={'span'}  sx={numeratorStyle}>
                                {numeratorDescription}
                            </Typography >
                            {props.numeratorValue &&  <Typography component={'span'} sx={valueStyle} ml={1}>
                                {numeratorValue}
                            </Typography>}

                        </Typography>
                        <Typography>
                            <Typography component={'span'}  sx={numeratorStyle}>
                                {denominatorDescription}
                            </Typography>
                            {props.denominatorValue && <Typography component={'span'} sx={valueStyle} ml={1}>
                                {denominatorValue}
                            </Typography>}
                        </Typography>
                        {
                        props.source && props.year &&
                            <Typography sx={sourceStyle}>
                                {source + " (" + year + ")"}
                            </Typography>
                        }
                    </AccordionDetails>
        </>
    )
}

export default IsoDetails;
