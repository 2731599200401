import { PERMISSIONS } from "../permissions";
import { getModuleKey } from "./clientsModulesArray";

export function getRolesByModules(client: any) {
  let permissionsArray: number[] = [];
  client?.modules.forEach((p: any) => {
    const module = getModuleKey(p.name);
    let moduleKey: any;
    if (module) {
      moduleKey = Object.values((PERMISSIONS.MODULES as any)[module]);
			permissionsArray.push(...moduleKey);
    }
    // permissionsArray.push(...moduleKey);
    // return moduleKey;
  });
  return permissionsArray;
}
