import { IMediaFile } from "@alb/live-lib";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store";
import { TMarker } from "types/types";

import { fetchMarkersOneModule } from "services/MarkersAPI";

import { IFetchMarkersModule } from "./eventsSlice";

interface IOccurrencesSlice {
  occurrences: TMarker[];
  occurrencesStatus: any;
  occurrencesFilterParams: any;
  listDeletedMedia: IMediaFile[];
}

const initialState: IOccurrencesSlice = {
  occurrences: [],
  occurrencesStatus: [],
  occurrencesFilterParams: {},
  listDeletedMedia: [],
};

export const fetchOccurrences = createAsyncThunk(
  "occurrences/fetchOccurrences",
  async (
    {
      publicAPI,
      user,
      keyModule,
      url,
      bounds,
      filterParams: params,
    }: IFetchMarkersModule,
    thunkAPI
  ) => {
    const response = await fetchMarkersOneModule(
      publicAPI,
      user,
      keyModule,
      url,
      bounds,
      params
    );
    return response;
  }
);

const occurrencesSlice = createSlice({
  name: "occurrences",
  initialState,
  reducers: {
    setOccurrences(state, action) {
      state.occurrences = action.payload;
    },
    setOccurrencesFilterParams(state, action) {
      state.occurrencesFilterParams = action.payload;
    },
    setListDeletedMedia(state, action) {
      state.listDeletedMedia = action.payload;
    },
    addItemListDeletedMedia(state, action) {
      const data = state.listDeletedMedia;
      data.push(action.payload);
      state.listDeletedMedia = data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOccurrences.fulfilled, (state, action) => {
      state.occurrences = [...action.payload];
    });
    builder.addCase(fetchOccurrences.rejected, (state, action) => {
      state.occurrences = [];
    });
  },
});

export const {
  setOccurrences,
  setOccurrencesFilterParams,
  setListDeletedMedia,
  addItemListDeletedMedia,
} = occurrencesSlice.actions;

export const getOccurrences = (state: RootState) =>
  state.occurrences.occurrences;
export const selectOccurrencesFilterParams = (state: RootState) =>
  state.occurrences.occurrencesFilterParams;
export const getListDeletedMedia = (state: RootState) =>
  state.occurrences.listDeletedMedia;
export default occurrencesSlice.reducer;
