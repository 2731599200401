import { format } from "date-fns";
import { Point } from "geojson";
import { IGeojsonFeaturesCollection, ISelectedParishArea } from "types/interfaces";

import { IFilterDateRangeMap } from "store/slices/mapSlice";

export interface IParamsDownload {
  search: string | null;
  typeVisitor: string | null;
  geographicArea: string | null;
  time: string | null;
  dateRange: [string, string];
  selectedParish: ISelectedParishArea | null;
}

export const dateRangeDownload = (dateRange: IFilterDateRangeMap) => {
  const dateFormat = "yyyy-MM-dd";
  const iDate = format(dateRange.startDate, dateFormat);
  const fDate = format(dateRange.endDate, dateFormat);
  const finalRange: [string, string] = [iDate, fDate];
  return finalRange;
};

export const downloadFile = (data: any, fileName: string) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = fileName;

  link.click();
};

export const convertCoordsToGeojson = (
  coords: [number, number, number][] | undefined,
  params: IParamsDownload | null
) => {
  if (!coords) {
    return {
      type: "FeatureCollection",
      features: [],
    };
  }
  // get coordinates of points on the map
  const points = coords?.map((element: number[]) => {
    return {
      type: "Feature",
      properties: {
        rating: element[2],
      },
      geometry: {
        type: "Point",
        coordinates: [element[1], element[0]],
      },
    };
  });
  // create the geojson
  const geojson = {
    type: "FeatureCollection",
    features: points,
  };
  // add filters if it exist
  if (params) {
    const dataFilters = {
      search: params.search || null,
      typeVisitor: params.typeVisitor || null,
      geographicArea: params.geographicArea || null,
      time: params.time || null,
      dateRange: params.dateRange,
      selectedParish: params.selectedParish?.id ? params.selectedParish : null,
    };
    Object.assign(geojson, { filters: dataFilters });
  }
  return geojson;
};

export const convertCoordsArray = (geojson: IGeojsonFeaturesCollection) => {
  const coordinates = geojson.features.map((val: GeoJSON.Feature) => {
    const geometry = val.geometry as Point;
    return [
      geometry.coordinates[1],
      geometry.coordinates[0],
      val.properties?.rating,
    ];
  });
  return coordinates;
};
