import Isoview from "components/indicators/iso/isoView/IsoView";

const IsoViewPage = () => {
    return(
        <>
            <Isoview />
        </>
    )
}

export default IsoViewPage;