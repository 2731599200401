/* eslint-disable react-hooks/exhaustive-deps */
import { InputAutocomplete, InputText, RequiredField } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import React, { useEffect, useState } from "react";
import { IClientForm, ISelectOption } from "types/interfaces";
import { formDefaultValues } from "./formDefaultValues";
import { getClientFormSchema, getEditClientFormSchema } from "./validations";
import useGet from "hooks/fetchData/useGet";
import { TAdapterConfigurator } from "types/types";
import { RequiredFields } from "components/Utils/RequiredField";

const ClientsForm = (props: {
  formId: string;
  data?: IClientForm;
  editForm?: boolean;
  onFormSubmit: (payLoad: IClientForm) => void;
  setFormIsValidStatus?: (status: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { formId, data, editForm, onFormSubmit, setFormIsValidStatus } = props;

  const [gatewaysOptions, setGatewaysOptions] = useState<ISelectOption[]>([]);

  // get list of gateways
  const { data: allGateways, refetch: refetchGateways, } = useGet(ServiceApiUrl.adapterConfigurators, {
    all: true,
  },
  {
    manual: true,
});

  useEffect(() => {
    if (!editForm) {
      refetchGateways()
    }
  }, [editForm]);


  useEffect(() => {
    if (allGateways) {
      const options = allGateways?.data?.map((p: TAdapterConfigurator) => ({
        label: p.name,
        value: p.id,
      }));

      setGatewaysOptions(options);
    }
  }, [allGateways]);

  const defaultValues = formDefaultValues(data);

  const methodsForm = useForm<IClientForm>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(!editForm ? getClientFormSchema() : getEditClientFormSchema()),
  });

  const { control, handleSubmit } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IClientForm> = (
    payLoad: IClientForm
  ) => {
    onFormSubmit(payLoad);
  };
  function arrayIsEmpty<T>(array: T | null | undefined): boolean {
    return !array || !Array.isArray(array) || !array.length;
  }

  useEffect(() => {
    if (setFormIsValidStatus)
      setFormIsValidStatus(methodsForm.formState.isValid);
  }, [methodsForm.formState.isValid]);

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RequiredField title={t("clients.name")} />
              <InputText
                control={control}
                name="client.name"
                placeholder={t("clients.namePlaceholder")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("clients.centerPoint")}
              </Typography>
              <InputText
                control={control}
                name="client.center_point"
                placeholder={t("clients.centerPointPlaceholder")}
              />
            </Grid>

            {!editForm && (
              <Grid item xs={12}>
                <fieldset>
                  <legend>{t("accounts.account")}</legend>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <RequiredField title={t("accounts.name")} />
                      <InputText
                        control={control}
                        name="account.name"
                        placeholder={t("accounts.namePlaceholder")}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <RequiredField title={t("accounts.adapters")} />
                      {!arrayIsEmpty(gatewaysOptions) && (
                        <InputAutocomplete
                          name="account.adapter_configurators"
                          options={gatewaysOptions}
                          multiple
                          control={control}
                          required
                        />
                      )}
                    </Grid>
                  </Grid>
                </fieldset>
              </Grid>
            )}
          </Grid>
        </form>
      </FormProvider>
      <RequiredFields />
    </>
  );
};

export default ClientsForm;
