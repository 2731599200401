/* eslint-disable react-hooks/exhaustive-deps */
import { FilterSection, MiniSelect } from "@alb/live-lib";
import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import { Grid, Typography, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { IBugaSelectedAdapter, ISelectOption } from "types/interfaces";
import { TAdapterConfigurator } from "types/types";

import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { DoubleSelect } from "styles/css/components";
import { BugaTypeKey } from "types/enum";
import { getSelectedModule } from "utils/modules/modules";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

interface IBugaSelect {
  loadingSelect: Dispatch<SetStateAction<boolean>>;
  errorSelect: Dispatch<SetStateAction<AxiosError<any, any> | null>>;
  adapterSelect: Dispatch<SetStateAction<IBugaSelectedAdapter | null>>;
}

export const BugaSelect = ({
  loadingSelect,
  errorSelect,
  adapterSelect,
}: IBugaSelect) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const user = useSelector(getUser);

  const [selectOptionsList, setSelectOptionsList] = useState<TOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const moduleID = getSelectedModule(user, MODULES_KEYS.BUGA);

  //get all adapters
  const {
    data: adapters,
    loading,
    error,
  } = useGet(ServiceApiUrl.adapterConfigurators, { module_id: moduleID });

  function makeAdapterSettings(domain: string, name: string) {
    switch (domain) {
      case "mobilitybikes":
        return {
          label: t("buga.bikes"),
          key: BugaTypeKey.bikes,
        };
      case "mobilitystations":
        return {
          label: t("buga.stations"),
          key: BugaTypeKey.station,
        };
      default:
        return {
          label: name,
          key: domain,
        };
    }
  }

  function changeOption(e: ISelectOption) {
    setSelectedOption(e.value);
  }
  const selectValue = () => {
    return (
      selectOptionsList?.find(
        (option: ISelectOption) => option.value === selectedOption
      ) || ""
    );
  };

  useEffect(() => {
    if (adapters?.data) {
      const adaptersSolTrafego = adapters.data.map(
        (element: TAdapterConfigurator) => {
          return {
            label: makeAdapterSettings(element.domain, element.name).label,
            value: element.domain,
          };
        }
      );
      setSelectOptionsList(adaptersSolTrafego);

      if (!selectedOption) {
        setSelectedOption(adaptersSolTrafego[0].value);
      }
    }
  }, [adapters]);

  useEffect(() => {
    loadingSelect(loading);
  }, [loading]);

  useEffect(() => {
    errorSelect(error);
  }, [error]);

  useEffect(() => {
    if (selectedOption) {
      const selectedAdapter = adapters?.data.find(
        (elem: TAdapterConfigurator) => elem.domain === selectedOption
      );
      const option = {
        domain: selectedOption,
        id: selectedAdapter.id,
        keyURL: makeAdapterSettings(
          selectedAdapter.domain,
          selectedAdapter.name
        ).key,
      } as IBugaSelectedAdapter;
      adapterSelect(option);
    }
  }, [selectedOption]);

  return (
    <>
      {selectedOption && (
        <Grid container item xs={false} sm={12} md={12} mt={4}>
          <DoubleSelect>
            <Grid item xs={true}>
              <Typography mr={1} variant="h6">
                {t("dashboard.dataFrom")}
              </Typography>
            </Grid>
            <Grid item xs={true}>
              <FilterSection
                select={
                  <MiniSelect
                    onChangeOption={changeOption}
                    showLabel
                    showBorder
                    sx={{
                      ...(theme.palette.mode === "light" && {
                        backgroundColor: "#ECF8F8",
                      }),
                    }}
                    borderColor="rgba(81,191,188,0.5)"
                    options={selectOptionsList}
                    value={selectValue()}
                  />
                }
              />
            </Grid>
          </DoubleSelect>
        </Grid>
      )}
    </>
  );
};
