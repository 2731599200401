import { TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, TableBody, TableContainer, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TDevice } from "types/types";

import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableExpanded } from "styles/css/components";
import { arrayIsEmpty } from "utils/utils";
import { formatOrderBy } from "utils/orderByAPI";

import DevicesList from "./DevicesList";
import NoData from "components/Utils/NoData";

interface IDevicesListTab {
  filters: { id: string };
}

function DevicesListTab({ filters }: IDevicesListTab) {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("name");

  const {
    data: devices,
    loading,
    error
  } = useGet(ServiceApiUrl.devices, {
    adapters_id: filters.id,
    order_by: formatOrderBy(order, orderBy),
    page: page,
    items: ROWS_PER_PAGE,
  });

  const [openRow, setOpenRow] = useState("");

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  const tableHeads: TableHeadCell[] = [
    {
      id: "name",
      label: t("common.name"),
    },
    {
      id: "is_active",
      label: t("common.statusLabel"),
    },
    {
      id: "geoLocation",
      label: t("adapters.geoLocation"),
      notSortable: true,
    },
    {
      id: "last_read_at",
      label: t("adapters.lastReadAt"),
    },
  ];

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <div>
      <Grid
        container
        mt={1}
        alignItems="center"
        direction="row"
        sx={{ justifyContent: "end" }}
      >
        <Grid item xs={true}>
          <Typography variant="h3">{t("adapters.devices")}</Typography>
        </Grid>
      </Grid>

      {arrayIsEmpty(devices?.data) && !loading && (
        <NoData error={error} />
      )}

      {devices && !arrayIsEmpty(devices?.data) && !loading && (
        <>
          <TableContainer>
            <TableExpanded>
              <TableHeadSort
                headCells={tableHeads}
                onRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <TableBody>
                {devices?.data?.map((device: TDevice, index: number) => (
                  <DevicesList
                    key={device.id}
                    row={device}
                    open={device.id === openRow}
                    onClick={(id: string) => setOpenRow(id)}
                  />
                ))}
              </TableBody>
            </TableExpanded>
          </TableContainer>

					<CustomTablePagination
						count={devices?.totalCount}
						page={page}
						totalPages={devices?.totalPages}
						onPageChange={handleChangePage}
					/>
        </>
      )}
    </div>
  );
}

export default DevicesListTab;
