const useLocalStorage = () => {

  function getLocalStorage (key: string): any | null {
    const json = localStorage.getItem(key);

    if (!json) {
      return null;
    }

    try {
      const data:any = JSON.parse(json);
      return data ?? null;
    } catch {
      return json
    }
  }

  function setLocalStorage (key: string, value: any | null) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  function clearLocalStorage(key: string) {
    localStorage.removeItem(key);
  }


  return {
    getLocalStorage,
    setLocalStorage,
    clearLocalStorage,
  };
};

export default useLocalStorage;
