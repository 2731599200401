import { OverridableComponent } from "@mui/types";
import { SvgIconTypeMap } from "@mui/material";
import WaterDropRoundedIcon from '@mui/icons-material/WaterDropRounded';
import WaterOutlinedIcon from '@mui/icons-material/WaterOutlined';
import LocalDiningOutlinedIcon from '@mui/icons-material/LocalDiningOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import GolfCourseRoundedIcon from '@mui/icons-material/GolfCourseRounded';
import DataUsageRoundedIcon from '@mui/icons-material/DataUsageRounded';
import SchoolRoundedIcon from '@mui/icons-material/SchoolRounded';
import EmojiObjectsRoundedIcon from '@mui/icons-material/EmojiObjectsRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import HouseRoundedIcon from '@mui/icons-material/HouseRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded';
import WifiRoundedIcon from '@mui/icons-material/WifiRounded';
import DirectionsTransitFilledRoundedIcon from '@mui/icons-material/DirectionsTransitFilledRounded';

export const isoCategoriesIcons: {
  [icon: string]: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
} = {
  agua: WaterDropRoundedIcon,
  aguasresiduais: WaterOutlinedIcon,
  alimentacao: LocalDiningOutlinedIcon,
  ambiente: PublicOutlinedIcon,
  culturaedesporto: GolfCourseRoundedIcon,
  economia: DataUsageRoundedIcon,
  educacao: SchoolRoundedIcon,
  energia: EmojiObjectsRoundedIcon,
  financas: AttachMoneyRoundedIcon,
  governo: AccountBalanceRoundedIcon,
  habitacao: HouseRoundedIcon,
  lazer: ExtensionRoundedIcon,
  planeamentourbano: LocationCityRoundedIcon,
  populacao: PeopleRoundedIcon,
  residuossolidos: DeleteRoundedIcon,
  saude: LocalHospitalRoundedIcon,
  seguranca: SecurityRoundedIcon,
  telecomunicacoes: WifiRoundedIcon,
  transportes: DirectionsTransitFilledRoundedIcon,
};
