import { mixed, object, string } from "yup";

export const InputsLengths = {
  max: {
    name: 50,
  },
  min: {
    name: 3,
  },
};

export const getAnalysisAddFormSchema = (edit?:boolean) =>
  object().shape(
    {
      name: string()
        .min(InputsLengths.min.name)
        .max(InputsLengths.max.name)
        .required(),
      //validação das checkboxs dos files
      ...(!edit && 
      {files: string().when(["files"], {
        is: (files: any) => !!files,
        then: () =>
          mixed()
            .required()
            .test("at-least-one-true", "At least one selected", (obj: any) => {
              return Object.values(obj).some((value) => value);
            }),
        otherwise: () => string().notRequired(),
      }),}
      )
    },
    [
      ["files", "files"],
    ]
  );
