import { LayoutContext } from "@alb/live-lib";
import { useContext, useEffect } from "react";

import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import AboutCMAveiro from "components/about/cmaveiro/AboutCMAveiro";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import AboutCreditAssigment from "components/about/credit-assignment/AboutCreditAssignment";
import { Stack } from "@mui/system";
import appInfo from "../../package.json";
import preval from "preval.macro";
import { formatDate } from 'utils/date'

const About = () => {
  // ---- permissions of user
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.ABOUT.READ);
  const user = useSelector(getUser);

  var date = preval`module.exports = new Date();`;

  // ---- page header
  const { t } = useTranslation();
  const header = {
    title: t("sidebar.about"),
  };
  const { addHeader } = useContext(LayoutContext);

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {canREAD && (
        <>
          {user.client.name === "CMAveiro" && <AboutCMAveiro />}
          {user.client.name === "CMFunchal" && <></>}
          <AboutCreditAssigment client={user.client.name} />
          <Stack
            mt={2}
            justifyContent="flex-end"
            direction="row"
            alignItems="flex-end"
          >
            <Typography variant="body1" mr={1}>
              {`v${appInfo.version}`}
            </Typography>
            <Typography variant="caption">
              {` (${formatDate(date, t("calendar.dateFullFormatOf"))})`}
            </Typography>
          </Stack>
        </>
      )}
    </>
  );
};

export default About;
