import { IApiResponse } from "@alb/live-lib";
import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { useEffect, useState } from "react";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IOccurrenceUrgency } from "types/interfaces";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

const useGroupOptions = () => {
    const [groupOptions, setGroupOptions] = useState<TOption[]>([]);

    const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

    const {
		data: allGroups,
		loading
	} = useGet<IApiResponse<IOccurrenceUrgency>>(ServiceApiUrl.groups, null, undefined, headers)

    useEffect(() => {
		if(allGroups) {
			const groupsOptionsSelect = allGroups?.data?.map((u) => ({
				label: u.name,
				value: u.id,
			  }))

			  setGroupOptions(groupsOptionsSelect)
		}

	}, [allGroups])


    return {groupOptions, loading}
}

export default useGroupOptions;
