import React from 'react'
import { IGraphsInfo, IQArtCardItem, IQArtGraphItem } from './QArtCardChart'
import ChartWidget, { IChartInfo } from 'components/charts/ChartWidget'
import { useTranslation } from 'react-i18next';
import { getColor } from './QArtColorsLimits';

export interface IQArtChart {
	data: IQArtGraphItem[] | undefined,
	item: IQArtCardItem,
	legendName: string,
	interval: string
}

function generateChartData(graphs: IQArtGraphItem[] | undefined, pollutant: string,
	translationStreamName: (name:string)=>string) {
	let graphsInfo: IGraphsInfo = { data: [], limits: [], yMaxValue: 0 };

	graphs?.forEach((graph) => {

		const value = graph.value;
		const color = getColor(pollutant, value);


		const name = translationStreamName(graph.name);
		graphsInfo.data.push({
			name: name,
			label: name,
			value: value,
			itemStyle: { color: color?.main },
			emphasis: {
				itemStyle: {
					color: color?.dark,
				},
			}
		})

	});

	return graphsInfo;
}

function QArtGlobalChart({ data, item, legendName, interval }:IQArtChart) {
	const { t, i18n } = useTranslation();

	function translationStreamNameAbbr(name: string) {
		return i18n.exists(`qart.pollutantStreamsAbbr.${name}`)
			? t(`qart.pollutantStreamsAbbr.${name}`)
			: name;
	}

	const getChartProps = (pollutant: IQArtCardItem): IChartInfo => {
		const graphInfo = generateChartData(data, pollutant.name, translationStreamNameAbbr);

		const props = {
			title: "",
			loading: false,
			error: null,
			data: graphInfo.data,
			unit: pollutant.unit,
			legendName: legendName,
		}

		return props;
	}

	return (
		<ChartWidget {...getChartProps(item)} color={item.color} />
	)
}

export default QArtGlobalChart
