import { IDialogProps, Loading, getColorTrafficWaze } from "@alb/live-lib";
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectDetailsError, selectDetailsLoading, selectMarkerDetails } from "store/slices/mapSlice";
import NoData from "components/Utils/NoData";
import CloseIcon from '@mui/icons-material/Close';
import { getIcons } from "components/map/list/listIcons";
import MarkerDialogTitle from "./MarkerDialogTitle";
import MapEntiyInfoItem from "./MapEntiyInfoItem";
import { formatDate } from "utils/date";

interface IJamDetail {
  delay: number,
  length: number,
  level: number,
  speed_k_m_h: number,
  street: string,
  typeMarker: string,
  time: string
}

const JamsInfo = ({ open, onClose }: IDialogProps) => {

  const { t } = useTranslation();

  const data: IJamDetail = useSelector(selectMarkerDetails);
  const loading = useSelector(selectDetailsLoading);
  const error = useSelector(selectDetailsError);
  const theme = useTheme()
  const isDarkMode = theme.palette.mode === 'dark'

  const icon = getIcons("jams")

  return <>
    <Dialog maxWidth="sm" fullWidth open={open}>
      <Box marginTop={'10px'} marginBottom={'20px'}>
        

        { data && <>
          <MarkerDialogTitle
            icon={icon}
            altIcon={data.street}
            title={data.street}
            status={<Typography display="inline" fontWeight={600} fontSize={16} sx={{ color:  getColorTrafficWaze(data.level.toString(), isDarkMode) }}>{t("waze.jamsLevel." + data.level)}</Typography>}
            domain={t(`waze.` + data.typeMarker)}
            onClose={onClose}
          />

          <DialogContent
            sx={{
              ".MuiPaper-root &.MuiDialogContent-root": {
                padding: "0 0",
              },
            }}
          >
            <Grid container direction={"row"}>
              <MapEntiyInfoItem
                label={t("waze.street")}
                value={data.street || '--'}
              />
              <MapEntiyInfoItem
                label={t("waze.avgspeed")}
                value={data.speed_k_m_h.toString() + " km/h" || '--'}
              />
              <MapEntiyInfoItem
                label={t("waze.lenght")}
                value={data.length?.toString() + "m" || '--'}
              />
              <MapEntiyInfoItem
                label={t("waze.delay")}
                value={data.delay > 0 ? data.delay?.toString() + "s" : '--'}
              />
              <MapEntiyInfoItem
                label={t("waze.date.valuesDate")}
                value={data.time ? formatDate(data.time, t("calendar.dateTimeFullFormat")) :  '--'}
              />
            </Grid>
          </DialogContent>  
          <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                {t("close")}
              </Button>
            </DialogActions>
        </> }

        
        <Loading show={loading} />
        {error.isError && !loading && <> 
          {!loading && <Box width={'100%'} display={'flex'} justifyContent={'end'}>
            <IconButton onClick={onClose}><CloseIcon /></IconButton>
          </Box>}
          <NoData error={error.description} />
          </>
        }
      </Box>
    </Dialog>
  </>
}

export default JamsInfo;