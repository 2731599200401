import { BackToButton, IApiResponse, LayoutContext } from "@alb/live-lib";
import useGet from "hooks/fetchData/useGet";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import FilterSelect from "components/Utils/FilterSelect";
import { TGroup } from "types/types";
import { formatRoutePath } from "utils/routePath";
import GroupView from "components/management/groups/GroupView";


const GroupDetail = () => {

    let params = useParams();
    const groupId = params.groupId;
    const navigate = useNavigate();
    const { addHeader } = useContext(LayoutContext);
	const location = useLocation();
    
    
      const customOptions = (data: IApiResponse<TGroup>) => {
        return data.data?.map((group: TGroup) => ({
          label: group.name,
          value: group.id,
        }));
      }

      const { data: group, loading: groupLoading, refetch: refetchGroups} = useGet(
        `${ServiceApiUrl.groups}/${groupId}`, 
        undefined, 
        undefined)


      const handleSelectedGroup = (e: any) => {
        const path = formatRoutePath(location, params, {
			groupId: e.value,
		  });
		navigate(path);
      }

      const header = {
        title: group?.name ? group?.name : t("group.detail"),
        backTo: (
            <BackToButton
              title={t("goBack")}
              onClick={() => navigate("/management/groups")}
            />
          ),
          select: group && (
            <FilterSelect<any>
              apiUrl={ServiceApiUrl.groups}
              onSelected={handleSelectedGroup}
              selectedValue={params?.groupId}
              customOptions={customOptions}
            />
          ),
      }

      useEffect(() => {
        addHeader(header)
        // eslint-disable-next-line
      }, [group])

      

    return(<>
        <GroupView 
         group={group} 
         groupLoading={groupLoading}
         refetchGroups={refetchGroups}
         />
    </>)
}

export default GroupDetail;