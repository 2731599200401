import DeleteIcon from '@mui/icons-material/DeleteRounded'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ChipStatus } from 'components/Utils/ChipStatus'
import { TClient } from 'types/types'
import { statusColor } from 'utils/utils'
import { formatDate } from 'utils/date'
import { PERMISSIONS } from "utils/permissions/permissions";
import usePermission from "hooks/usePermission";

const ClientsTableRow = (props: {
	row: TClient
	onDelete: (row: TClient) => void
	onEdit: (row: TClient) => void
	onRowClick: (row: TClient) => void;
}) => {
	const { row, onDelete, onEdit, onRowClick } = props;
	const { t } = useTranslation();

	// ---- permissions
	const { hasPermission } = usePermission();
	const canUpdate = hasPermission(PERMISSIONS.ADMINISTRATION.CLIENTS.UPDATE);
	const canDelete = hasPermission(PERMISSIONS.ADMINISTRATION.CLIENTS.DELETE);

	return (
		<>
			<TableRow sx={{ cursor: 'pointer' }} onClick={(e): void => {
				e.stopPropagation();
				onRowClick(row);
			}}>
				<TableCell>{row.name}</TableCell>
				<TableCell>
					<ChipStatus
						label={row.is_active ? t('common.active') : t('common.inactive')}
						color={
							row.is_active ? statusColor('active') : statusColor('non-active')
						}
					/>
				</TableCell>

				<TableCell>
					{formatDate(row.updated_at, t('calendar.dateTimeFormatLocal'))}
				</TableCell>
				<TableCell
					align={"right"}
					sx={{ whiteSpace: "nowrap" }}
				>
					{canUpdate && (
						<IconButton
							title={t("common.edit")}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								e.stopPropagation();
								onEdit(row);
							}}
						>
							<EditIcon />
						</IconButton>
					)}
					{canDelete && (
						<IconButton
							title={t("common.delete")}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								e.stopPropagation();
								onDelete(row);
							}}
						>
							<DeleteIcon />
						</IconButton>
					)}
				</TableCell>
			</TableRow>
		</>
	);
};

export default ClientsTableRow;
