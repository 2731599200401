import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { useEffect, useState } from "react";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IApiResponse, IOccurrenceUrgency } from "types/interfaces";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

const useUrgencyOptions = () => {
    const [urgencyOptions, setUrgencyOptions] = useState<TOption[]>([]);

	const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

    const {
		data: allUrgencyTypes,
		loading
	} = useGet<IApiResponse<IOccurrenceUrgency>>(ServiceApiUrl.occurrencesUrgency, null, undefined, headers)

	useEffect(() => {
		if(allUrgencyTypes) {
			const urgencyOptionsSelect = allUrgencyTypes?.data?.map((u) => ({
				label: u.name,
				value: u.id,
			  }))

			  setUrgencyOptions(urgencyOptionsSelect)
		}

	}, [allUrgencyTypes])


	return {urgencyOptions, loading}
}

export default useUrgencyOptions;
