import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

import ChartWidget, { IChartInfo } from 'components/charts/ChartWidget'
import useGet from 'hooks/fetchData/useGet'
import { useGetModuleHeaders } from 'hooks/useGetModuleHeaders'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { formatDate } from 'utils/date'
import { MODULES_KEYS } from 'utils/modules/modulesKeys'

import DevicesList, { IUrbanWasteSubItemList } from './DevicesList'

interface IDataVeolia {
	aggregated_collect_date: Date;
	descargas: number;
	co2: number;
	kms: number;
	total_recolha_kg: number;
	nox: number;
}

export const CardsView = () => {
	const { t } = useTranslation();
	const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);
	const {
		data,
		loading,
		error,
	} = useGet(ServiceApiUrl.aveiroVeolia, null, undefined, headers);

	const [dataKey, setDataKey] = React.useState<string>("kms");
	const [dataText, setDataText] = React.useState<string>("Quilómetros percorridos");
	const [dataUnit, setDataUnit] = React.useState<string>('km')

	//transform discharges data
	let chartData = data?.data.map((e: IDataVeolia) => {
		return { name: formatDate(e.aggregated_collect_date, t("calendar.dateFullFormat")), value: e[dataKey as keyof IDataVeolia] };
	});

	const chartProps: IChartInfo = {
		title: dataText,
		loading: loading,
		error: error,
		data: chartData,
		unit: dataUnit,
		legendName: dataText
	}

	const reloadChart = (item: IUrbanWasteSubItemList) => {
		if (item && item.idItem) {
			setDataKey(item.idItem);
			setDataText(item.title);
			setDataUnit(item.unit || '')
		}
	}

	return (
		<Grid
			mb={3}
			container
			columnSpacing={5}
			rowSpacing={3}
			mt={4}
			justifyContent="space-between"
			sx={{ pt: '35px' }}
		>
			<Grid item xs={12}>
				<Typography variant="h3">
					{t('urbanWaste.analyticsLabel')}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={12} md={8}>
				<Card data-testid={"urbanWasteDeviceAnalysisChart"}>
					<CardContent>
						<ChartWidget {...chartProps} />
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} sm={12} md={4}>
				<Card>
					<CardContent>
						<DevicesList itemClicked={reloadChart} />
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	)
}
