import { Loading } from "@alb/live-lib";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatRoutePath } from "utils/routePath";
import { arrayIsEmpty } from "utils/utils";

import { FiltersSection } from "./FiltersSection";
import IsoCategoriesTableRow from "./IsoCategoriesTableRow";

export interface ICategories {
  name: string;
  goals: { [type: string]: number };
}

export const IsoCategoriesTable = () => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

    const [filterSearch, setFilterSearch] = useState<string>("");

  const {
    data: indicatorData,
     loading,
    error,
  } = useGet(ServiceApiUrl.indicatorCategories, GetParams());

  function GetParams() {
    var params= {
      ...(filterSearch !== '' && { name: filterSearch }),
    }
    return params
  }

  //transform data
  let categories = indicatorData?.map((val: any) => {
    return {
      name: Object.keys(val)[0],
      goals: Object.values(val)[0],
    };
  });

  const handleRowClick = (row: ICategories) => {
    const path = formatRoutePath(location, params, {
      id: row.name
    });
    navigate(path);
  };

  let row;
  if (!arrayIsEmpty(categories)) {
    row = categories?.map((category: ICategories, index: number) => (
      <IsoCategoriesTableRow
        key={index}
        row={category}
        onRowClick={handleRowClick}
      />
    ));
  }

  return (
    <Box data-testid={"ISOcategoriesContainer"}>
    <FiltersSection setFilterSearch={setFilterSearch} />
    {!loading && arrayIsEmpty(indicatorData) && <NoData error={error}/>}

      {!arrayIsEmpty(categories) && !loading && (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>
                    {t("indicators.iso.categoriesISO.category")}
                  </TableCell>
                  <TableCell>
                    {t("indicators.iso.categoriesISO.indicators")}
                  </TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{row}</TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Loading show={loading} />
    </Box>
  );
};


