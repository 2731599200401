import { IDialogProps, Loading } from "@alb/live-lib";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { getIcons } from "components/map/list/listIcons";
import NoData from "components/Utils/NoData";
import { selectDetailsError, selectDetailsLoading, selectMarkerDetails } from "store/slices/mapSlice";

import MapEntiyInfoItem from "./MapEntiyInfoItem";
import MarkerDialogTitle from "./MarkerDialogTitle";
import { formatDate } from "utils/date";

interface IAlertDetail {
  street: string,
  sub_type: string,
  type: string,
  time: string
}

const WazeAlertsInfo = ({ open, onClose }: IDialogProps) => {
  const { t } = useTranslation();

  const data: IAlertDetail = useSelector(selectMarkerDetails);
  const loading = useSelector(selectDetailsLoading);
  const error = useSelector(selectDetailsError);

  let icon;
  if(data)
    icon = getIcons(data.type, data.sub_type)


  return <>
    <Dialog maxWidth="sm" fullWidth open={open}>
      <Box marginTop={'10px'} marginBottom={'20px'}>
        

      { data && <>
          <MarkerDialogTitle
            icon={icon}
            altIcon={data.street}
            title={data.street}
            domain={t(`waze.types.` + data.type)}
            onClose={onClose}
          />

          <DialogContent
            sx={{
              ".MuiPaper-root &.MuiDialogContent-root": {
                padding: "0 0",
              },
            }}
          >
            <Grid container direction={"row"}>
              <MapEntiyInfoItem
                label={t("waze.subType")}
                value={data.sub_type ? t("waze.subTypes." + data.sub_type) :  '--'}
              />
              <MapEntiyInfoItem
                label={t("waze.date.valuesDate")}
                value={data.time ? formatDate(data.time, t("calendar.dateTimeFullFormat")) :  '--'}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                {t("close")}
              </Button>
            </DialogActions>
        </>
      }

          

        <Loading show={loading} />
        {error.isError && !loading && <> 
          {!loading && <Box width={'100%'} display={'flex'} justifyContent={'end'}>
            <IconButton onClick={onClose}><CloseIcon /></IconButton>
          </Box>}
          <NoData error={error.description} />
          </>
        }
      </Box>  
    </Dialog>
  </>
}

export default WazeAlertsInfo;