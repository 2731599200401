import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { useEffect, useState } from "react";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import {
  IApiResponse,
  IEventCategories,
  ISelectOption,
} from "types/interfaces";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

export const useCategoriesOptions = () => {

  const { headers } = useGetModuleHeaders(MODULES_KEYS.EVENTS);

  const [categoriesOptions, setCategoriesOptions] = useState<ISelectOption[]>();

  const {
    data: allCategories,
    loading,
  } = useGet<IApiResponse<IEventCategories>>(
    ServiceApiUrl.eventsCategories,
    null,
    undefined,
    headers
  );

  useEffect(() => {
    if (allCategories) {
      let typeOptionsSelect = allCategories?.data?.map((p) => ({
        label: p.name,
        value: p.id,
      }));
      typeOptionsSelect = typeOptionsSelect.sort(
        (
          a: ISelectOption,
          b: ISelectOption //sort by label
        ) => (a.label > b.label ? 1 : -1)
      );
      setCategoriesOptions(typeOptionsSelect);
    }
  }, [allCategories]);

  return { categoriesOptions, loading };
};
