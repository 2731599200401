import { Delete } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import Tooltip from '@mui/material/Tooltip';
import { t } from "i18next";


const DeleteButton = (props: { deleteArray: () => void }) => {

    return(<>
        <Tooltip
            title={t("map.buttons.remove")}
        >
            <IconButton
              sx={{
                float: "right",
                marginRight: "10px",
                marginTop: "10px",
                zIndex: 999
              }}
              onClick={props.deleteArray}
            >
              <Delete/>
            </IconButton>
        </Tooltip>
        
    </>)
}

export default DeleteButton