import { TMarker } from "types/types";

export interface DeviceRealTime {
  external_id: string,
  last_read_at: string,
  last_read_value: {
    park_occupied: boolean | string,
    park_type: string,
  }
}

export function updateDeviceInfo(device: TMarker, valuesRealTime: DeviceRealTime) {
  // change received data
  const typeValue = valuesRealTime.last_read_value.park_type.padEnd(2, " ");
  const occupiedString = String(valuesRealTime.last_read_value.park_occupied);
  const occupiedValue = occupiedString.charAt(0).toUpperCase() + occupiedString.slice(1) + " ";
  const newValues = {
    ...valuesRealTime,
    last_read_value: {
      park_type: typeValue,
      park_occupied: occupiedValue,
    }
  }
  // save data received on the device
  const info = {
    ...device,
    last_read_at: newValues.last_read_at,
    last_read_value: newValues.last_read_value,
  };
  return info;
}

export function updateDeviceLastRead(device: TMarker, newValues: DeviceRealTime) {
  return {
    ...device,
    date: [newValues.last_read_at],
  };
}
