/* eslint-disable react-hooks/exhaustive-deps */
import { InputSelect, Loading } from "@alb/live-lib";
import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, FormGroup, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { BugaTypeKey } from "types/enum";
import { arrayIsEmpty } from "utils/utils";

interface IBugaFiltersForm {
  keyAdapter: BugaTypeKey;
  formID: string;
  data: IBugaFiltersFieldsForm | null;
  numActiveFilters: number;
  onFormSubmit: (payLoad: IBugaFiltersFieldsForm) => void;
}

export interface IBugaFiltersFieldsForm {
  status?: string | null;
  docked?: string | null;
}

export const BugaFiltersForm = ({
  keyAdapter,
  formID,
  data,
  numActiveFilters,
  onFormSubmit,
}: IBugaFiltersForm) => {
  const { t } = useTranslation();
  const [statusOptions, setStatusOptions] = useState<TOption[]>([]);
  const dockedOptions: TOption[] = [
    {
      label: t("buga.list.docked.true"),
      value: "true",
    },
    {
      label: t("buga.list.docked.false"),
      value: "false",
    },
  ];
  const statusOptionsStations: TOption[] = [
    {
      label: t("buga.list.status.true"),
      value: "true",
    },
    {
      label: t("buga.list.status.false"),
      value: "false",
    },
  ];

  const optionsRequest = {
    manual: true,
  };

  const {
    data: allStatus,
    loading: loadingStatus,
    error: errorStatus,
    refetch: refetchAllStatus,
  } = useGet(ServiceApiUrl.bugaBikesStatus, null, optionsRequest);

  const defaultValues: IBugaFiltersFieldsForm = {
    status: data?.status || "",
    docked: data?.docked || "",
  };

  const methodsForm = useForm<IBugaFiltersFieldsForm>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const { control, handleSubmit } = methodsForm;

  function refetchFilters(key: BugaTypeKey) {
    switch (key) {
      case BugaTypeKey.bikes:
        // status filter
        refetchAllStatus();
        break;
      case BugaTypeKey.station:
        // status filter
        setStatusOptions(statusOptionsStations);
        break;
    }
  }

  function clearOptionsFilters() {
    setStatusOptions([]);
  }

  const formSubmitHandler: SubmitHandler<IBugaFiltersFieldsForm> = (
    payLoad: any
  ) => {
    if (payLoad && Object.keys(payLoad).length > 0) {
      for (const key in payLoad) {
        if (payLoad[key] === "") {
          delete payLoad[key];
        }
      }
    }
    onFormSubmit(payLoad);
  };

  useEffect(() => {
    clearOptionsFilters();
    refetchFilters(keyAdapter);
  }, [keyAdapter]);

  useEffect(() => {
    if (allStatus?.data) {
      const statusList = allStatus.data.map((elem: { status: string }) => {
        return {
          label: t("buga.list.status." + elem.status),
          value: elem.status,
        };
      });
      setStatusOptions(statusList);
    }
  }, [allStatus]);

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formID} onSubmit={handleSubmit(formSubmitHandler)}>
          <FormGroup>
            <Grid container>
              <Grid container item xs={12} justifyContent="flex-end">
                {numActiveFilters > 0 && (
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    type={"submit"}
                    onClick={() => {
                      methodsForm.reset({
                        status: "",
                        docked: "",
                      });
                    }}
                  >
                    {t("clear")}
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              spacing={2}
            >
              <Grid item xs={12}>
                <Typography gutterBottom variant="h6">
                  {t("common.statusLabel")}
                </Typography>
                {statusOptions && !arrayIsEmpty(statusOptions) && (
                  <InputSelect
                    placeholder={t("common.statusPlaceholder")}
                    name="status"
                    control={control}
                    options={statusOptions}
                    noOptionsText={t("common.noOptions")}
                  />
                )}
                {loadingStatus && <Loading show={loadingStatus} />}
                {!loadingStatus &&
                  (errorStatus ||
                    (statusOptions && arrayIsEmpty(statusOptions))) && (
                    <NoData error={errorStatus} />
                  )}
              </Grid>
              {keyAdapter === BugaTypeKey.bikes && (
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h6">
                    {t("streams.docked")}
                  </Typography>
                  <InputSelect
                    placeholder={t("map.filter.visitsMadePlaceholder")}
                    name="docked"
                    control={control}
                    options={dockedOptions}
                    noOptionsText={t("common.noOptions")}
                  />
                </Grid>
              )}
            </Grid>
          </FormGroup>
        </form>
      </FormProvider>
    </>
  );
};
