import { TMarker, TUser } from "types/types";

import { getSelectedModule } from "utils/modules/modules";
import { api as axios } from "utils/axios";
import { IClientPublicPortal } from "utils/clientsPublicPortal";

// pedido dos markers de 1 modulo
async function fetchMarkersModule(
  header: any,
  url: string,
  bounds: number[][],
  params?: any
) {
  let markers: TMarker[] = [];
  const config = {
    headers: { ...header },
    params: {
      points: JSON.stringify(bounds),
      ...params,
    },
    manual: true,
  };
  await axios.get(url, config).then((response) => {
    markers = response.data;
  });
  return markers;
}

// pedido dos markers de 1 client tendo em conta se API é publica ou privada
export async function fetchMarkersOneModule(
  publicAPI: boolean,
  user: TUser | IClientPublicPortal,
  module: string,
  url: string,
  bounds: number[][],
  params?: any
) {
  let markers: TMarker[] = [];
  if (!publicAPI) {
    const markersIDModule = getSelectedModule(user, module);
    if (markersIDModule) {
      const header = {
        "MODULE-ID": markersIDModule,
      };
      markers = await fetchMarkersModule(header, url, bounds, params);
    }
  } else {
    const client = user as IClientPublicPortal;
    const header = {
      "Client-ID": client.id,
    };
    markers = await fetchMarkersModule(header, url, bounds, params);
  }
  return markers;
}
