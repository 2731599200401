import { HeatmapOptions, IHeatmapColor } from "@alb/live-lib";

import { arrayIsEmpty } from "./utils";

//colors to heatmap
export const legendHeatmapColors: IHeatmapColor[] = [
  { color: "purple", value: 0 },
  { color: "blue", value: 0.25 },
  { color: "cyan", value: 0.5 },
  { color: "green", value: 0.6 },
  { color: "yellow", value: 0.75 },
  { color: "red", value: 1 },
];

// converte os values em percentagem para enviar no gradiente como é colocado no heatmap
export const rangeColorsHeatMap = (colors: IHeatmapColor[]) => {
  return colors.map((e: any) => e.color + " " + e.value * 100 + "%");
};

const getAvailableBounds = (
  heatmapData: HeatmapOptions<number> | null,
  mapBounds: number[][]
) => {
  let availablePoints: any = [];

  heatmapData?.points.forEach((p: any) => {
    if (p[0] > mapBounds[2][0] && p[0] < mapBounds[0][0]) {
      if (p[1] < mapBounds[0][1] && p[1] > mapBounds[1][1]) {
        availablePoints.push(p);
      }
    }
  });
  return {
    points: availablePoints,
  };
};

export const maxValueLegend = (
  coordinates: HeatmapOptions<number> | null,
  mapBounds: number[][]
) => {
  const max = !arrayIsEmpty(coordinates?.points)
    ? Number(
        Math.max(
          ...getAvailableBounds(coordinates, mapBounds).points.map(
            (v: any) => v[2]
          ),
          0
        )
      ).toFixed(2)
    : 0;
  return Number(max);
};

export const minValueLegend = (
  coordinates: HeatmapOptions<number> | null,
  mapBounds: number[][]
) => {
  const min = !arrayIsEmpty(coordinates?.points)
    ? Number(
        Math.min(
          ...getAvailableBounds(coordinates, mapBounds).points.map(
            (v: any) => v[2]
          ),
          0
        )
      ).toFixed(2)
    : 0;
  return Number(min);
};
