import React from 'react'
import { IQArtMapMarkers } from './QArtMap'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'utils/date';
import { formatNumber } from 'utils/number';
import { getClassification } from './QArtColorsLimits';

export interface IQArtMapMarkerPopupInfo {
	marker: IQArtMapMarkers,
	pollutant: string
}
function QArtMapMarkerPopupInfo({ marker, pollutant }: IQArtMapMarkerPopupInfo) {
	const { t } = useTranslation();

	const classif = getClassification(marker.global.name, marker.global.value);

	let polt = t(`qart.pollutantStreams.${marker.global.name}`);
	let airQ = t(`qart.pollutantClassification.${classif}`) + ' (' + formatNumber(marker.global.value) + ` ${marker.global.unit})`;

	if (pollutant !== '') {
		polt = t(`qart.pollutantStreams.${pollutant}`);
		airQ = '--';
		const pV = marker.agg_values?.find((p) => p.name === pollutant);
		if (pV) {
			const classif = getClassification(pollutant, pV?.value);
			airQ = t(`qart.pollutantClassification.${classif}`) + ' ' + formatNumber(pV?.value) + ` (${pV?.unit})`;
		}
	}

	return (
		<TableContainer component={Paper}>
			<Table sx={{ maxWidth: 300 }} size="small">
				<TableBody>
					<TableRow>
						<TableCell><strong>{t('common.local')}</strong></TableCell>
						<TableCell>{marker.title}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><strong>{t('qart.airQuality')}</strong></TableCell>
						<TableCell>{airQ}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><strong>{t('qart.pollutant')}</strong></TableCell>
						<TableCell>{polt}</TableCell>
					</TableRow>
					<TableRow>
						<TableCell><strong>{t('common.day')}</strong></TableCell>
						<TableCell>{formatDate(marker.date, t("calendar.dateFullFormatOf"))}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default QArtMapMarkerPopupInfo
