import { SvgIcon, SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { IModuleCardItem } from 'components/modules/ModuleCards'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { getWholeAndDecimal } from 'utils/number';
import { QArtBoxContainerStyled, QArtBoxStyled, QArtTypographyStyled } from './QArtCardStyle';
import { moduleCardsIcons } from 'components/modules/cards/moduleCardsIcons';
import { IQArtCardItem } from './QArtCardChart';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { InfoTooltip } from './QArtStyled';

export interface IQArtCard {
	item: IQArtCardItem
}

function QArtCard({ item }: IQArtCard) {
	const { t } = useTranslation();
	const theme = useTheme();

	const getIcon = (name: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & { muiName: string; }, color: string) => {
		return <SvgIcon
			sx={{ width: '80px', height: '80px', color: color }}
			component={name}
			inheritViewBox
		/>
	}

	function showItemValueNumber(item: IModuleCardItem) {
		if (!item.value) return "";

		const [whole, dec] = getWholeAndDecimal(Number(item.value));

		return (
			<span>
				{whole},
				<span className='valueDec'>{dec}</span>
			</span>
		)
	}

	return (
		<QArtBoxContainerStyled>
			{getIcon(moduleCardsIcons[item.name], item.color?.main || theme.palette.greyColor.main)}
			<QArtBoxStyled>
				<div>
					<Typography variant="caption" fontSize={14}>
						{t(`common.${item.type}`)}
					</Typography>
					<InfoTooltip title={<Typography>{t('qart.cardAvgValueInfo')}</Typography>}>
						<InfoOutlinedIcon sx={{ cursor: 'help', marginLeft: 1 }} fontSize="small" color="primary" />
					</InfoTooltip>
				</div>
				<QArtTypographyStyled>
					{showItemValueNumber(item)}
				</QArtTypographyStyled>
			</QArtBoxStyled>
		</QArtBoxContainerStyled>
	)
}

export default QArtCard
