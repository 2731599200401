/* eslint-disable react-hooks/exhaustive-deps */
import { TableCell, TableRow } from "@mui/material";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { ChipStatus } from "components/Utils/ChipStatus";
import { statusColor } from "utils/utils";
import { formatDate } from "utils/date";
import { TDevice } from "types/types";

function DevicesList(props: {
  row: TDevice | any;
  open: boolean;
  onClick: Function;
}) {
  const { row, open } = props;
  const { t } = useTranslation();

  return (
    <>
      <TableRow
        className={clsx({"no-pointer":true,
          expanded: open,
        })}
      >
        <TableCell>{row.title}</TableCell>
        <TableCell>
          <ChipStatus
            label={row.is_active ? t("common.active") : t("common.inactive")}
            color={
              row.is_active ? statusColor("active") : statusColor("non-active")
            }
          />
        </TableCell>
        <TableCell>{row.geolocation?.iconPosition?.join()}</TableCell>
        <TableCell>
          {row.date[0]
            ? formatDate(row.date[0], t("calendar.dateTimeFormatLocal"))
            : "--"}
        </TableCell>
        {/* <TableCell>
          {formatDate(row.created_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>
        <TableCell>
          {formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell> */}
      </TableRow>
      {/* {showExpandCell && (
        <TableRowExpanded>
          <TableCellExpanded colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Grid container columns={{ xs: 2, sm: 6, md: 8, lg: 12 }}> */}
      {/* <RowItem
                  label={t("adapters.externalId")}
                  value={row.external_id}
                /> */}
      {/* <RowItem
                  label={t("adapters.geoLocation")}
                  value={row.geolocation?.iconPosition?.join()}
                /> */}
      {/* <RowItem
                  label={t("adapters.extraParams")}
                  value={JSON.stringify(row.extra_params)}
                /> */}
      {/* <RowItem label={t("adapters.lastReadAt")} value={row.date[0]} /> */}
      {/* </Grid>
            </Collapse>
          </TableCellExpanded>
        </TableRowExpanded>
      )}  */}
    </>
  );
}

export default DevicesList;
