import { InputText, RequiredField } from '@alb/live-lib';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { RequiredFields } from 'components/Utils/RequiredField';
import React, { useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formDefaultValues } from './formDefaultValues';
import { getOccurrenceCategoryFormSchema } from './validations';
import ImagePicker from './imagePicker/ImagePicker';
import IconsList from './IconsList';

export interface IOccurrenceCategoryForm {
	id: string;
	name: string;
	icon: string;
}

export interface ICategoriesForm {
	formId: string;
	data?: IOccurrenceCategoryForm;
	editForm?: boolean;
	onFormSubmit: (payLoad: IOccurrenceCategoryForm) => void;
	setFormIsValidStatus?: (status: boolean) => void;
}

const CategoriesForm = ({ formId, data, editForm, onFormSubmit, setFormIsValidStatus }: ICategoriesForm) => {
	const { t } = useTranslation();

	const defaultValues = formDefaultValues(data);

	const methodsForm = useForm<IOccurrenceCategoryForm>({
		defaultValues: defaultValues,
		mode: "onTouched",
		reValidateMode: "onChange",
		resolver: yupResolver(getOccurrenceCategoryFormSchema()),
	});


	const { control, handleSubmit, setValue, trigger } = methodsForm;

	//ao submeter o formulário
	const formSubmitHandler: SubmitHandler<IOccurrenceCategoryForm> = (
		payLoad: IOccurrenceCategoryForm
	) => {
		onFormSubmit(payLoad);
	};

	useEffect(() => {
		
		if (setFormIsValidStatus)
			setFormIsValidStatus(methodsForm.formState.isValid);

			// eslint-disable-next-line
	}, [methodsForm.formState.isValid]);


	return (
		<>
			<FormProvider {...methodsForm}>
				<form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<RequiredField title={t("common.name")} />
							<InputText
								control={control}
								name="name"
								placeholder={t("occurrences.categoryNamePlaceholder")}
							/>
						</Grid>
						<Grid item xs={12}>
							<RequiredField title={t("occurrences.categoryIcon")} />
							<ImagePicker list={IconsList} name="icon" control={control} setValue={setValue} trigger={trigger}/>
						</Grid>
					</Grid>
				</form>
			</FormProvider>
			<RequiredFields allRequired />
		</>
	);
};
export default CategoriesForm
