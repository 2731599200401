import { DynamicForm, IDynamicInputTemplate, IFormDefinition, IRange, TypeDynamicInput } from '@alb/live-lib'
import { Stack } from '@mui/material'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { ColXTemplate } from 'utils/templates';
import { useForm } from "react-hook-form";
import { IQArtFilters } from './QArtContainer';
import { endOfDay } from "date-fns";
interface IDefaultValuesFilters {
	dateRangeCalendar: IRange | undefined;
}
export type TDateRange = Omit<IRange, 'label' | 'key'>;

interface IQArtFiltersPage extends IQArtFilters {
	onChange: (dateRange: TDateRange) => void
}

function QArtFilters({ onChange, dateRange }: IQArtFiltersPage) {
	const { t, i18n } = useTranslation();

	const defaultRangeDate = {
		inputRangeField: dateRange
	}
	const handleOnSelectedRange = (dateRange: TDateRange) => {
		// console.log(dateRange);
		onChange(dateRange)
	};

	const template = useCallback(
		({ inputLabel, children }: IDynamicInputTemplate) => {
			return (
				<ColXTemplate
					xsCol={true}
					inputLabel={inputLabel}
					children={children}
				/>
			);
		},
		[]
	);

	const predefinedRanges = {
		labelLast30Days: t("calendar.last30Days"),
		labelLast7Days: t("calendar.last7Days"),
		position: "left",
	};

	const dynamicForm = {
		formId: "form-search-qart",
		inputsDefinition: {
			dateRangeCalendar: {
				name: 'inputRangeField',
				inputType: TypeDynamicInput.daterange,
				placeholder: t("calendar.placeholderDate"),
				defaultValue: undefined,
				defaultValueField: defaultRangeDate,
				onSelectedRange: handleOnSelectedRange,
				noOptionsText: t("common.noOptions"),
				template: template,
				locale: i18n.language,
				predefinedRanges: predefinedRanges,
				labelBtnCancel: t("cancel"),
				labelBtnConfirm: t("apply"),
				clearButton: true,
				maxDate: endOfDay(new Date())
			}
		},
	};
	const defaultValues: IDefaultValuesFilters = {
		dateRangeCalendar: dynamicForm.inputsDefinition.dateRangeCalendar.defaultValue,
	};
	const methodsForm = useForm<IDefaultValuesFilters>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: defaultValues,
	});
	const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm };

	return (
		<Stack direction={'column'} spacing={2} paddingX={2}>
			<DynamicForm formDefinition={formDefinition} />
		</Stack>
	)
}

export default QArtFilters
