import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store";
import useLocalStorage from "hooks/useLocalStorage";
import { COMPARATIVE_ANALYSIS_CHARTS } from "utils/keys";
import { ISelectedDevicesAndOptions } from "types/interfaces";

// eslint-disable-next-line react-hooks/rules-of-hooks
const { getLocalStorage, setLocalStorage } = useLocalStorage();
interface IComparativeAnalysisState {
  comparativeAnalysisCharts: ISelectedDevicesAndOptions[];
  adapterIsSmartParkingFunchal: {deviceNumber: string | undefined, smartParking: boolean | undefined},
}

const initialState: IComparativeAnalysisState = {
  comparativeAnalysisCharts: getLocalStorage(COMPARATIVE_ANALYSIS_CHARTS),
  adapterIsSmartParkingFunchal: {deviceNumber: undefined, smartParking:undefined},
};

export const comparativeAnalysisSlice = createSlice({
  name: COMPARATIVE_ANALYSIS_CHARTS,
  initialState,
  reducers: {
    //guarda a info dos gráficos em LS
    setComparativeAnalysisCharts(state, action) {
      state.comparativeAnalysisCharts = action.payload;
      setLocalStorage(
        COMPARATIVE_ANALYSIS_CHARTS,
        state.comparativeAnalysisCharts
      );
    },
    setAdapterSmartParkingFunchal(state, action) {
      state.adapterIsSmartParkingFunchal = action.payload;
    },
  },
});

export const { setComparativeAnalysisCharts, setAdapterSmartParkingFunchal } =
  comparativeAnalysisSlice.actions;

//devolve a info do gráficos guardados em LS
export const getComparativeAnalysisCharts = (state: RootState) =>
  state.comparativeAnalysisCharts.comparativeAnalysisCharts;
  export const getAdapterIsSmartParkingFunchal = (state: RootState) =>
  state.comparativeAnalysisCharts.adapterIsSmartParkingFunchal;

export default comparativeAnalysisSlice.reducer;
