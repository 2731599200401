/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";

const useLevelOptions = () => {

    const [ levelOptions, setLevelOptions ] = useState<ISelectOption[] | null>(null)
    const levels = [1, 2, 3, 4, 5]
    const { t } = useTranslation();
    
    useEffect(() => {
      const options: ISelectOption[] = levels.map((item: number) => {
        return {
          label: t("waze.jamsLevel." + item),
          value: item.toString()
        }
      }) 
      setLevelOptions(options)
    }, [])
    
    return { levelOptions }
}

export default useLevelOptions;