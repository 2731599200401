import { TableHeadOrder } from '@alb/live-lib'
import Grid from '@mui/material/Grid'
import { useEffect, useState } from 'react'

import Loading from 'components/Utils/Loading'
import NoData from 'components/Utils/NoData'
import { ROWS_PER_PAGE } from 'components/Utils/Pagination/pagination'
import useGet from 'hooks/fetchData/useGet'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { formatOrderBy } from 'utils/orderByAPI'
import { arrayIsEmpty } from 'utils/utils'

import { AccountsTable } from './AccountsTable'
import { FiltersSection } from './FiltersSection'

interface IAccountsList {
  refetch: boolean
}

const AccountsList = ({ refetch }: IAccountsList) => {
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState<TableHeadOrder>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')
  const [filterSearch, setFilterSearch] = useState<string>('')
  const [filterStatus, setFilterStatus] = useState<string>('')

  const {
    data: accounts,
    loading,
    error,
    refetch: refetchAccounts,
  } = useGet(ServiceApiUrl.accounts, GetParams(), undefined)

  function GetParams() {
    var params: {
      page: number
      items: number
      order_by: string
      active?: string
      contains?: string
      [key: string]: string | unknown
    } = {
      page: page,
      items: ROWS_PER_PAGE,
      order_by: formatOrderBy(order, orderBy),
      ...(filterStatus !== '' && { active: filterStatus }),
      ...(filterSearch !== '' && { contains: filterSearch }),
    }
    return params
  }

  useEffect(() => {
    if (refetch) refetchAccounts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch])

  let accountsList = <NoData error={error} />

  if (loading) {
    accountsList = <Loading />
  }

  if (!loading && accounts && !arrayIsEmpty(accounts.data)) {
    accountsList = (
      <AccountsTable
        data={accounts}
        orderBy={orderBy}
        order={order}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        refetch={refetchAccounts}
      />
    )
  }
  return (
    <Grid item mt={4}>
      <FiltersSection
        setPage={setPage}
        setFilterSearch={setFilterSearch}
        setFilterStatus={setFilterStatus}
      />
      {accountsList}
    </Grid>
  )
}

export default AccountsList
