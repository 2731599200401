import { Typography } from '@mui/material'

import { ModuleCards } from "components/modules/ModuleCards";

const CardsTotals = () => {


	// const {
	// 	data, //: cardsTotals,
	// 	loading,
	// } = useGet('/aveiro/veolia', null, undefined, headers);

	const loading = false;
	const error = null;
	const cardsTotals = [
		{ name: 'Total quilómetros', value: 19673, type: 'quilómetros' },
		{ name: 'Total de combustível gasto', value: 11838, type: 'litros' },
		{ name: 'Total recolhido', value: 2991, type: 'toneladas' },
		{ name: 'Total de descargas', value: 356, type: 'nr' },
	]

	return (<>
		<Typography variant="body1" component={'p'} marginTop={5}>
			Informação relativa ao mês de junho de 2020
		</Typography>
		<ModuleCards
			module="urbanWaste"
			items={cardsTotals}
			loading={loading}
			error={error}
			sx={{ mt: 2 }}
		/>
	</>)
}

export default CardsTotals;
