/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TUser } from "types/types";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";
import useDelete from "hooks/fetchData/useDelete";

export interface IUsersDelete {
  user: TUser;
  open: boolean;
  handlerClose:any;
  updateList: () => void;
}

const UsersDelete = ({
  user,
  open,
  handlerClose,
  updateList,
}: IUsersDelete) => {


  const { t } = useTranslation();
  const { addFeedback } = useFeedback();

  const {
    loading,
    refetch: deleteUser,
  } = useDelete(ServiceApiUrl.user, user.id);

  //sucesso
  const handlerSuccess = () => {
    handlerClose();
    addFeedback({
      message: t("user.userDeleted"),
      severity: "success",
    });
    updateList();
  };

  // eliminar user
  const handleDeleteUser = async () => {
    try {
      await deleteUser();
      await handlerSuccess();
    } catch { }
  };

  return (
    <ConfirmDialog
              open={open}
              type="error"
              title={`${t("user.deleteUser")} ${user.username}`}
              message={t("confirmations.userDeleteConfirmation")}
              actionConfirmText={t("common.delete")}
              actionCancelText={t("common.cancel")}
              loading={loading}
              onCancel={handlerClose}
              onConfirm={handleDeleteUser}
    />
  );
};

export default UsersDelete;
