import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@store'
import { getCookie, setCookie } from 'utils/cookie'
import { THEME } from 'utils/keys'


interface IAppSlice {
  theme: string
}

const initialState: IAppSlice = {
  theme: getCookie<string>(THEME)
}

const appManagementSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload
      setCookie<string>(THEME, state.theme);
    },
  },
})

export const { setTheme } = appManagementSlice.actions

export const getTheme = (state: RootState) => state.app.theme

export default appManagementSlice.reducer
