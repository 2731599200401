import { arrayIsEmpty, Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from '@alb/live-lib'
import { Grid, TableBody, TableContainer } from '@mui/material'
import { t } from "i18next";
import { useEffect, useState } from "react";
import { TGroup } from 'types/types';

import NoData from 'components/Utils/NoData';
import { CustomTablePagination } from 'components/Utils/Pagination/CustomTablePagination';
import { ROWS_PER_PAGE } from 'components/Utils/Pagination/pagination';
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableLink } from 'styles/css/components';
import { formatOrderBy } from 'utils/orderByAPI';

import { FiltersSection } from './FiltersSection';
import GroupDeleteDialog from './GroupDeleteDialog';
import GroupEditDialog from './GroupEditDialog';
import GroupRow from './GroupRow';

const GroupList = (props: any) => {

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");
	const [filterSearch, setFilterSearch] = useState<string>('')

    const {
		data: groups,
		loading,
		error,
		refetch: refetchGroups,
	} = useGet(ServiceApiUrl.groups, GetParams(), undefined)

    const tableHeads: TableHeadCell[] = [
		{
			id: 'name',
			label: t('common.name'),
		},
        {
			id: 'users',
			label: t('common.userQuantity'),
		},
		{
			id: 'created',
			label: t('common.createdAt'),
		},
		{
			id: 'action_cell',
			label: '',
			notSortable: true,
		},
	]

	useEffect(() => {
		if(props.hasToRefetch) {
			refetchGroups()
			props.setHasToRefetch(false);
		}
		// eslint-disable-next-line
	}, [props.hasToRefetch])

	const [ selectedRow, setSelectedRow ] = useState<any>(null)
	const [ showDeleteDialog, setShowDeleteDialog ] = useState(false)
	const [ showEditDialog, setShowEditDialog ] = useState(false)

	const handleDeleteClick = (row: TGroup) => {
		setSelectedRow(row);
		setShowDeleteDialog(true);
	}

	const hanldeEditClick = (row: TGroup) => { 
		setSelectedRow(row);
		setShowEditDialog(true);
	}

	const onClose = () => {
		setSelectedRow(null);
		setShowDeleteDialog(false);
		setShowEditDialog(false);
	}

	

	function GetParams() {
		var params: {
			page: number;
			items: number;
			order_by: string;
			active?: string;
			contains?: string;
		} = {
			page: page,
			items: ROWS_PER_PAGE,
			order_by: formatOrderBy(order, orderBy),
			...(filterSearch !== '' && { contains: filterSearch }),
		};
		return params;
	}

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	}

	const handleChangePage = (newPage: number) => {
		setPage(newPage + 1);
	}

    return(
 	<Grid item mt={4}>
      	<FiltersSection
        setPage={setPage}
        setFilterSearch={setFilterSearch}
      	/>
        {loading && <Loading show={loading} />}

		{arrayIsEmpty(groups?.data) && !loading && <NoData error={error} />}

        {groups && !arrayIsEmpty(groups?.data) && !loading && (
				<>
					<TableContainer>
                        <TableLink>
							<TableHeadSort
								headCells={tableHeads}
								onRequestSort={handleRequestSort}
								orderBy={orderBy}
								order={order}
							/>
							<TableBody>
                            {groups?.data?.map((group: TGroup, index: number) => (
                                <GroupRow data={group} key={index} onDeleteClick={handleDeleteClick} onEditClick={hanldeEditClick} />
                            ))}
							</TableBody>
                        </TableLink>
					</TableContainer>

					<CustomTablePagination
						count={groups?.totalCount}
						page={page}
						totalPages={groups?.totalPages}
						onPageChange={handleChangePage}
					/>
				</>
			)}
		<GroupDeleteDialog selectedRow={selectedRow} showDeleteDialog={showDeleteDialog} onClose={onClose} refetchGroups={refetchGroups}/>
		{ selectedRow && <GroupEditDialog selectedRow={selectedRow} showEditDialog={showEditDialog} onClose={onClose} refetchGroups={refetchGroups} />}
	</Grid>
	)
}

export default GroupList;