/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";
import { TAccount } from "types/types";
import useDelete from "hooks/fetchData/useDelete";
export interface IAccountsDelete {
  account: TAccount;
  clientID?: string;
  open: boolean;
  handlerClose: () => void;
  updateList: () => void;
}

const AccountsDelete = ({
  account,
  clientID,
  open,
  handlerClose,
  updateList,
}: IAccountsDelete) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();

  const {
    loading,
    refetch: deleteModule,
  } = useDelete(`${ServiceApiUrl.accounts}/${account.id}`);
  //sucesso
  const handlerSuccess = () => {
    handlerClose();
    addFeedback({
      message: t("accounts.accountDeleted"),
      severity: "success",
    });
    updateList();
  };

  // eliminar módulo
  const handleDeleteModule = async () => {
    try {
      await deleteModule();
      await handlerSuccess();
    } catch {}
  };

  return (
    <ConfirmDialog
              open={open}
              type="error"
              title={`${t("common.delete")} ${account.name}`}
              message={t("accounts.accountDeleteConfirmation")}
              actionConfirmText={t("common.delete")}
              actionCancelText={t("common.cancel")}
              loading={loading}
              onCancel={handlerClose}
              onConfirm={handleDeleteModule}
    />
  );
};

export default AccountsDelete;
