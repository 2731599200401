
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

import NoData from 'components/Utils/NoData'
import { ROWS_PER_PAGE } from 'components/Utils/Pagination/pagination'
import useGet from 'hooks/fetchData/useGet'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { formatOrderBy } from 'utils/orderByAPI'
import { arrayIsEmpty } from 'utils/utils'

import { ClientsTable } from './ClientsTable'
import { FiltersSection } from './FiltersSection'
import { TableHeadOrder, Loading } from '@alb/live-lib'

const Clients = (props: { refetch: boolean }) => {
	const { refetch } = props
	const [page, setPage] = useState(1)
	const [order, setOrder] = useState<TableHeadOrder>('asc')
	const [orderBy, setOrderBy] = useState<string>('name')

	const [filterSearch, setFilterSearch] = useState<string>("");
	const [filterStatus, setFilterStatus] = useState<string>("");

	const {
		data: clients,
		loading,
		error,
		refetch: refetchClients,
	} = useGet(ServiceApiUrl.clients, GetParams())

	function GetParams() {
		var params: {
			page: number;
			items: number;
			order_by: string;
			contains?: string;
			active?: string;
		} = {
			page: page,
			items: ROWS_PER_PAGE,
			order_by: formatOrderBy(order, orderBy),
			...(filterSearch !== "" && { contains: filterSearch }),
			...(filterStatus !== "" && { active: filterStatus }),
		};
		return params;
	}

	useEffect(() => {
		if (refetch) refetchClients()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refetch])

	return <>
		<Box marginTop={2}>
			<FiltersSection setPage={setPage}
				setFilterSearch={setFilterSearch}
				setFilterStatus={setFilterStatus} />
		</Box>
		<Loading show={loading} />
		{!loading && (arrayIsEmpty(clients?.data) || error) && <NoData error={error} />}
		{!loading && clients && !arrayIsEmpty(clients.data) && !error && (
			<ClientsTable
				data={clients}
				orderBy={orderBy}
				order={order}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				page={page}
				setPage={setPage}
				refetch={refetchClients}
			/>
		)}
		</>
}

export default Clients
