import { Table, TableHead, TableRow, TableCell, Checkbox, TableBody, TableContainer, Typography, Stack } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { INotificationAPI, IResultTable } from 'types/interfaces'
import { arrayIsEmpty } from 'utils/utils'
import { CustomTablePagination } from 'components/Utils/Pagination/CustomTablePagination'
import NotificationsTableRow, { INotificationRow } from './NotificationsTableRow'

import { useNotificationsUtils } from 'hooks/useNotificationsUtils'
import useUpdate from 'hooks/fetchData/useUpdate'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { LoadingButton } from '@mui/lab'
import { useDispatch } from 'react-redux'
import { fetchLastNotifications } from 'store/slices/notificationsSlice'
interface EnhancedTableProps {
	numSelected: number;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	rowCount: number;
	onMarkAllAsReadClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	loading: boolean;
	disableSelectAll: boolean
}
function EnhancedTableHead(props: EnhancedTableProps) {
	const {
		onSelectAllClick,
		onMarkAllAsReadClick,
		numSelected,
		rowCount,
		loading,
		disableSelectAll
	} = props;

	const { t } = useTranslation();

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all notifications on the page"
						}}
						sx={{ paddingLeft: 0 }}
						disabled={disableSelectAll}
					/>
				</TableCell>
				<TableCell colSpan={3}>
					{numSelected > 0 ? (
						<Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
							<Typography color="inherit" variant="body1" component="div" >
								{t("notifications.numSelected", { count: numSelected })}
							</Typography>
							<LoadingButton
								loading={loading}
								variant="outlined"
								color="greyColor"
								onClick={onMarkAllAsReadClick}
							>
								{t("common.markAsRead")}
							</LoadingButton>
						</Stack>
					) : (
						<Typography color={disableSelectAll ? "GrayText" : "inherit"} variant="body1" component="div" >
							{t("notifications.selectAll")}
						</Typography>
					)}
				</TableCell>
			</TableRow>
		</TableHead>
	);
}

export const NotificationsTable = (props: IResultTable) => {
	const {
		data: notifications,
		page,
		setPage,
		refetch
	} = props

	const dispatch = useDispatch()

	const { loading, refetch: readAllNotifications } = useUpdate(
		ServiceApiUrl.notificationsRead,
	);

	const [selected, setSelected] = useState<readonly string[]>([]);

	const { getNotificationInfo } = useNotificationsUtils();

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1) //porque o index da paginação do mui inicia no 0
	}

	const handleReadClick = (row: INotificationAPI) => {
		refetch();
		dispatch(fetchLastNotifications());
	}

	const onRowClick = (event: React.MouseEvent<unknown>, row: INotificationAPI) => {
		const id = row.id;

		const selectedIndex = selected.indexOf(id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	}

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = notifications.data.reduce((values, value) => {
				if (!value.read) values.push(value.id);
				return values;
			}, [])

			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleMarkAllAsReadClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
		// console.log("selected: ", selected);
		try {
			await readAllNotifications({ data: { id: selected, read: true } }).then((response) => {
				refetch();
				dispatch(fetchLastNotifications());
			});
		} catch (error) { console.log(error) }
	}

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	let rows
	if (!arrayIsEmpty(notifications.data)) {
		rows = notifications.data.map((notification: INotificationAPI) => {
			const isItemSelected = isSelected(notification.id);
			const {title, link, icon} = getNotificationInfo(notification);
			const notificationRow: INotificationRow = {
				...notification,
				title: title,
				icon: icon,
				link: link
			}
			return (
				<NotificationsTableRow
					key={notification.id}
					row={notificationRow}
					onRowClick={onRowClick}
					onReadClick={handleReadClick}
					selected={isItemSelected}
				/>
			)
		})
	}

	const getNotificationUnreadCount = () => {
		return notifications.data.filter((not: INotificationAPI) => !not.read).length;
	}

	const disableSelectAll: boolean = !notifications.data.some((not) => not.read === false);

	return (
		<>
			<TableContainer>
				<Table>
					<EnhancedTableHead
						numSelected={selected.length}
						onSelectAllClick={handleSelectAllClick}
						onMarkAllAsReadClick={handleMarkAllAsReadClick}
						rowCount={getNotificationUnreadCount()}
						loading={loading}
						disableSelectAll={disableSelectAll}
					/>
					<TableBody>{rows}</TableBody>
				</Table>
			</TableContainer>

			<CustomTablePagination
				count={notifications.totalCount}
				page={page}
				totalPages={notifications.totalPages}
				onPageChange={handleChangePage}
			/>
		</>
	)
}
