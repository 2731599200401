import { BackToButton, LayoutContext, TableHeadOrder, useFeedback } from '@alb/live-lib';
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Loading from 'components/Utils/Loading';
import NoData from 'components/Utils/NoData';
import useGet from 'hooks/fetchData/useGet';
import { ServiceApiUrl } from 'services/ServiceApiUrl';
import { arrayIsEmpty } from 'utils/utils';
import { getListParams } from 'utils/listParams';

import StatusAdd from './StatusAdd';
import StatusTable from './StatusTable';
import { FiltersSection } from './FiltersSection';
import { useGetModuleHeaders } from 'hooks/useGetModuleHeaders';
import { MODULES_KEYS } from 'utils/modules/modulesKeys';
import ReorderIcon from '@mui/icons-material/Reorder';
import { SortList } from 'components/Utils/SortList/SortList';
import useUpdate from "hooks/fetchData/useUpdate";

const StatusList = () => {
	const { t } = useTranslation();
	const { addHeader, addAction, action } = useContext(LayoutContext);
	const navigate = useNavigate();

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("frontend_order");
	const [filterSearch, setFilterSearch] = useState<string>("");
	const [openDialogOrderStates, setOpenDialogOrderStates] = useState(false);

	const { addFeedback } = useFeedback();

	const header = {
		title: t("occurrences.status"),
		action: (
			<Button
				variant="contained"
				disableElevation
				startIcon={<AddIcon />}
				onClick={() => addAction(true)}
			>
				{t("occurrences.addStatus")}
			</Button>
		),
		backTo: <BackToButton title={t("goBack")} onClick={() => navigate(-1)} />,
	};

	useEffect(() => {
		addHeader(header);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

	const {
		data: status,
		loading,
		error,
		refetch,
	} = useGet(ServiceApiUrl.occurrencesStatus, getListParams(page, order, orderBy, filterSearch), undefined, headers);

	let statusList = <NoData error={error} />;

	if (loading) {
		statusList = <Loading />;
	}

	if (status && !arrayIsEmpty(status.data)) {
		statusList = (
			<StatusTable
				data={status}
				orderBy={orderBy}
				order={order}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				page={page}
				setPage={setPage}
				refetch={refetch}
			/>
		);
	}

	let statusAdd;

	if (action) {

		statusAdd = (
			<StatusAdd
				open={action}
				onClose={() => addAction(false)}
				onSuccess={() => {
					addAction(false);
					refetch();
				}}
			/>
		);
	}


	const onClickOrderStates = () => {
		setOpenDialogOrderStates(true);
	};

	const handlerCloseChangeClient = () => {
		setOpenDialogOrderStates(false);
	};

	const { refetch: reorderList } = useUpdate(
		ServiceApiUrl.occurrencesStatus
	);

	const handlerSuccess = () => {
		addFeedback({
			message: t("events.statusEvent.alteredStates"),
			severity: "success",
		});

		setOpenDialogOrderStates(false);
		refetch();
	}

	const handlerOrderedList = async (
		value: any
	) => {
		let array = value.map((a: any) => a.id)
		try {
			await reorderList({ data: array }).then(
				async (response) => {
					handlerSuccess()
				}
			);
		} catch (error) { }
	};

	return (
		<>
			<Grid item mt={4}>
				<Grid container>
					<Grid item xs={6}>
						<Button
							color="primary"
							variant="outlined"
							size="small"
							startIcon={<ReorderIcon />}
							onClick={onClickOrderStates}
						>
							{t("events.statusEvent.sortStatus")}
						</Button>
					</Grid>
					<Grid item xs={6}>
						<FiltersSection
							setPage={setPage}
							setFilterSearch={setFilterSearch}
						/></Grid>
				</Grid>
				{statusList}
			</Grid>

			{statusAdd}
			{openDialogOrderStates && status && !arrayIsEmpty(status.data) && (
				<SortList
					title={t("events.statusEvent.sortStatus")}
					list={status.data}
					open={openDialogOrderStates}
					onClose={handlerCloseChangeClient}
					orderedList={handlerOrderedList}
				/>
			)}

		</>
	)
}

export default StatusList
