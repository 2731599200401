import { SvgIcon, TableCell, TableRow } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { formatNumber } from "utils/number";

import { isoCategoriesIcons } from "../isoCategoriesIcons";
import { ICategories } from "./IsoCategoriesTable";

const IsoCategoriesTableRow = (props: {
  row: ICategories;
  onRowClick: (row: ICategories) => void;
}) => {
  const { row, onRowClick } = props;
  const { t } = useTranslation();

  const getName = (indicator: string) => {
    //transform to lowercase, remove spaces and special characters
    return _.deburr(indicator?.toLowerCase().replaceAll(" ", ""));
  };
  return (
    <>
      <TableRow
        sx={{ cursor: "pointer" }}
        onClick={(e): void => {
          e.stopPropagation();
          onRowClick(row);
        }}
      >
        <TableCell align="center" sx={{ width: "8%" }}>
          <SvgIcon
            sx={{ fontSize: "30px" }}
            titleAccess={row.name}
            component={isoCategoriesIcons[getName(row.name)]}
            inheritViewBox
          />
        </TableCell>
        <TableCell data-testid={"categoryNameISO"}>{row.name}</TableCell>
        <TableCell>
          {t("indicators.iso.categoriesISO.goals.main")}
          {": "}
          {row.goals.Principal ? formatNumber(row.goals.Principal) : 0}
        </TableCell>
        <TableCell>
          {t("indicators.iso.categoriesISO.goals.support")}
          {": "}
          {row.goals.Suporte ? formatNumber(row.goals.Suporte) : 0}
        </TableCell>
        <TableCell>
          {t("indicators.iso.categoriesISO.goals.profile")}
          {": "}
          {row.goals.Perfil ? formatNumber(row.goals.Perfil) : 0}
        </TableCell>
      </TableRow>
    </>
  );
};

export default IsoCategoriesTableRow;
