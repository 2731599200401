/* eslint-disable react-hooks/exhaustive-deps */
import { DrawOption, getBoundsVisibleArea, IActionControl, IMap, IMapPoint, LayoutContext, Map, TileType } from "@alb/live-lib";
import { t } from "i18next";
import { LatLngExpression } from "leaflet";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { TMarker } from "types/types";

import MarkerInfoComponent from "components/map-entity-info/MarkerInfoComponent";
import { FilterTab } from "components/map/controls/FilterMapTab/FilterTab";
import useGetFiltersCount from "components/map/controls/FilterMapTab/hooks/useGetFiltersCount";
import { MarkersTypeOptions } from "components/map/controls/FilterMapTab/utils/GetOptions";
import OccurrencesStartDateControl from "components/map/controls/OccurrencesStartDateControl";
import { MapListContainer } from "components/map/list/MapListContainer";
import useFetchMarkers from "hooks/useFetchMarkers";
import useMarkers from "hooks/useMarkers";
import { getUser } from "store/slices/authSlice";
import { getEvents, setEventsFilterParams } from "store/slices/eventsSlice";
import { getMapBounds, getMarkerDetails, getShapeBounds, selectFiltersTabOpen, selectMarkers, selectOpenList, setBounds, setFiltersTabOpen, setHighlightedListMarker, setSelectedMarkerType, setShapeBounds, TypeMarkerInfo } from "store/slices/mapSlice";
import { getOccurrences, setOccurrencesFilterParams } from "store/slices/occurrencesSlice";
import { clientPublicPortal } from "utils/clientsPublicPortal";
import { formatDate } from "utils/date";
import { arcgisKey } from "utils/keys";
import { arrayIsEmpty, getPreviousDay } from "utils/utils";

import { IconFilter } from "./Map";

const PublicPortal = () => {
  // ---- Header
  const { addHeader } = useContext(LayoutContext);

  let lastDay = formatDate(
    getPreviousDay(new Date()),
    t("calendar.dateTimeEndpoints")
  );

  useEffect(() => {
    addHeader();
    //reset redux states
    dispatch(setSelectedMarkerType(MarkersTypeOptions.all));
    // dispatch(setOccurrencesFilterParams({}));
    dispatch(setOccurrencesFilterParams({ record_date: lastDay }));
    dispatch(setEventsFilterParams({}));
    dispatch(setFiltersTabOpen(false));
  }, []);

  // ---- Info of client
  const location = useLocation();
  const infoClient = clientPublicPortal(location.pathname);

  // ---- Map
  const dispatch = useDispatch();
  const filterTabOpen = useSelector(selectFiltersTabOpen);
  const filtersCount = useGetFiltersCount();
  let mapCenter: LatLngExpression | undefined = {
    lat: infoClient?.centerPoint[0] || 0,
    lng: infoClient?.centerPoint[1] || 0,
  };
  const openList = useSelector(selectOpenList);

  const mapBounds = useSelector(getMapBounds);
  const { getAllMarkersMap } = useMarkers();
  const { fetchAllMarkers } = useFetchMarkers(true);

  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [markerTypeDetails, setMarkerTypeDetails] = useState<string>("");
  const [filterCoordinates, setFilterCoordinates] = useState<
    [LatLngExpression] | []
  >([]);
  const [highlightedPoint, setHighlightedPoint] = useState<IMapPoint | null>(
    null
  );
  const [openFilters, setOpenFilters] = useState(false);

  const events: TMarker[] = useSelector(getEvents);
  const occurrences: TMarker[] = useSelector(getOccurrences);
  const markers: IMapPoint[] = useSelector(selectMarkers);
  const shapeBounds = useSelector(getShapeBounds);

  async function getMarkersMap() {
    await getAllMarkersMap();
  }

  useEffect(() => {
    if (
      !arrayIsEmpty(mapBounds) &&
      // filter is array or object
      ((Array.isArray(filterCoordinates) && arrayIsEmpty(filterCoordinates)) ||
        Object.keys(filterCoordinates).length === 0)
    ) {
      fetchAllMarkers();
    }
  }, [mapBounds]);

  useEffect(() => {
    getMarkersMap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, occurrences]);

  const handlePointClick = (point: IMapPoint) => {
    const publicAPI = {
      public: true,
      client: infoClient,
    };
    dispatch(
      getMarkerDetails(point.id, point.type as TypeMarkerInfo, point.subType, undefined, publicAPI)
    );
    setMarkerTypeDetails(point.type);
    setOpenDetails(true);
  };

  //faz o pedido apenas quando o shapeBounds  alterar
  useEffect(() => {
    fetchAllMarkers();
  }, [shapeBounds]);

  const handleDrawFilter = (filters: [LatLngExpression] | []) => {
    setFilterCoordinates(filters);
    if (
      !arrayIsEmpty(mapBounds) &&
      ((Array.isArray(filters) && arrayIsEmpty(filters)) ||
        Object.keys(filters).length === 0)
    ) {
      dispatch(setShapeBounds([]));
      // fetchAllMarkers(); //faz quando receber o novo valor do shapeBounds

      // nao existe poligono - pedidos com o mapBounds
    } else {

      const bounds = getBoundsVisibleArea(filters as any);

      // poligono desenhado - filtrar markers em redux
      if (infoClient?.city === "funchal") {
        dispatch(setShapeBounds(bounds));
      }
    }
  };
  const handleBoundsChange = (bounds: number[][]) => {
    dispatch(setBounds(bounds));
  };

  const getTodayFirstDate = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    let stringDate = formatDate(date, t("calendar.dateTimeEndpoints"));
    return stringDate;
  };

  //ref auxiliar para guardar o valor mais atualizado, para colocar no actionsCtrl
  //se utilizar o estado diretamente, não atualiza o valor
  const filterTabOpenRef = useRef<boolean>();
  filterTabOpenRef.current = filterTabOpen;
  const filtersCountRef = useRef<number>(0);
  filtersCountRef.current = filtersCount;

  function returnIcon() {
    return <IconFilter count={filtersCountRef.current} />;
  }

  let actionsCtrl: IActionControl[] = [
    {
      action: () => {
        dispatch(setFiltersTabOpen(!filterTabOpenRef.current));
      },
      icon: returnIcon, //é enviado em formato função porque não estava a atualizar o valor do count!
      title: t("map.buttons.filters"),
    },
  ];

  var userLanguage = useSelector(getUser)?.language; //se existir
  var browserLanguage = navigator.language.slice(0, 2); //get browser language

  const args: IMap = {
    arcgisKey: arcgisKey,
    language: userLanguage ? userLanguage : browserLanguage,
    mapTranslations: {
      buttons: {
        layers: t("map.buttons.layers"),
        selectAreas: t("map.buttons.selectAreas"),
        edit: t("map.buttons.edit"),
        remove: t("map.buttons.remove"),
        marker: t("map.buttons.marker"),
        circle: t("map.buttons.circle"),
        rectangle: t("map.buttons.rectangle"),
        polygon: t("map.buttons.polygon"),
        polyline: t("map.buttons.polyline"),
        recenter: t("map.buttons.recenter"),
        delete: t("map.buttons.remove"),
        cancel: t("map.buttons.cancel"),
        close: t("close"),
      },
      popups: {
        lastRead: t("map.marker.lastReading"),
        showDetail: t("common.showDetail"),
      },
      date: {
        dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
      },
      leafletDraw: {
        removeShapeConfirmation: t("leafletDraw.removeShapeConfirmation"),
        removeShape: t("leafletDraw.removeShape"),
        dragToEdit: t("leafletDraw.dragToEdit"),
        clickCancelToUndo: t("leafletDraw.clickCancelToUndo"),
        clickToRemove: t("leafletDraw.clickToRemove"),
        howToDrawCircle: t("leafletDraw.howToDrawCircle"),
        howToDrawCircleMarker: t("leafletDraw.howToDrawCircleMarker"),
        howToDrawMarker: t("leafletDraw.howToDrawMarker"),
        howToDrawPolygon: t("leafletDraw.howToDrawPolygon"),
        howToDrawRectangle: t("leafletDraw.howToDrawRectangle"),
      },
      markers: {
        active: t("common.active"),
        inactive: t("common.inactive"),
      },
      common: {
        noData: t("common.noData"),
      },
    },
    mapVariant: "page",
    actionsControls: actionsCtrl,
    mapCenterPoint: mapCenter,
    initialMapCenter: mapCenter
      ? (Object.values(mapCenter) as LatLngExpression)
      : undefined, //centro do mapa inicial, estático
    mapZoom: 15,
    initialZoom: 15,
    mapTileType: TileType.default,
    points: markers,
    mapModule: false,
    showZoom: true,
    onPointClick: handlePointClick,
    highlightPoint: highlightedPoint,

    // controls clients
    leftBottomContentMap: showLeftBottomContentMap(),

    /// drawOptions -->
    onDrawFilter: handleDrawFilter,
    drawOptions: [DrawOption.rectangle, DrawOption.circle, DrawOption.polygon],

    onBounds: handleBoundsChange,

    // actions controls

    width: openList ? "450px" : filterTabOpen ? "334px" : "0px",
    dynamicAttribution: true,
  };

  const onCloseFilterTab = () => {
    dispatch(setFiltersTabOpen(false));
  };

  const handleOnHoverItemList = (item: IMapPoint | null) => {
    setHighlightedPoint((prev) => (prev = item?.selected ? item : null));
    dispatch(setHighlightedListMarker(item));
  };

  //da primeira vez que a tab dos filtros for aberta, mantem o componente disponível
  useEffect(() => {
    if (!openFilters && filterTabOpen) setOpenFilters(true);
  }, [filterTabOpen]);

  function showLeftBottomContentMap() {
    return (
      <OccurrencesStartDateControl
        publicAPI={true}
        todayDate={new Date(getTodayFirstDate())}
        sendNotifications={false}
        setSendNotifications={undefined}
      />
    );
  }

  return (
    <>
      <MapListContainer
        items={markers}
        itemDetails={handlePointClick}
        onHover={handleOnHoverItemList}
      />

      {openFilters && (
        <FilterTab
          open={filterTabOpen}
          publicAPI={true}
          onClose={onCloseFilterTab}
        />
      )}

      <Map {...args} />

      {openDetails && markerTypeDetails && (
        <MarkerInfoComponent
          type={markerTypeDetails}
          dialogProps={{
            open: openDetails,
            onClose: () => setOpenDetails(false),
          }}
          publicPage={true}
        />
      )}
    </>
  );
};

export default PublicPortal;
