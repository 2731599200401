/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button
} from "@mui/material";
import EditRoundedIcon from '@mui/icons-material/EditRounded'

import { BackToButton, EntityInfo, EntityInfoItem, IApiResponse, LayoutContext, Loading } from '@alb/live-lib'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'

import NoData from 'components/Utils/NoData'
import useGet from 'hooks/fetchData/useGet'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { setDrawingMap, setDrawnCoords } from 'store/slices/mapSlice'
import { formatDate } from 'utils/date'

import { EventEdit } from './EventEdit'
import { useGetModuleHeaders } from 'hooks/useGetModuleHeaders'
import { formattedLocation } from 'utils/utils'
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";
import FilterSelect from "components/Utils/FilterSelect";
import { formatRoutePath } from "utils/routePath";
import { ISelectOption } from "types/interfaces";
import { TEvent } from "types/types";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import EventDetailMap from "./EventDetailMap";

const getFormattedLocation = (geolocation: { iconPosition: [number, number], type: string; coordinates: [[number, number]] }) => {

	if (geolocation.type === "marker") { return formattedLocation(geolocation.iconPosition) }
	else { return geolocation.coordinates.map((c: [number, number]) => formattedLocation(c)).join(", ") }

}

const EventDetail = () => {
	let params = useParams()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const location = useLocation()
	const dispatch = useDispatch()
	const { hasPermission } = usePermission()

	const { addHeader, addAction, action } = useContext(LayoutContext)

	const { headers } = useGetModuleHeaders(MODULES_KEYS.EVENTS);
	const canUpdate = hasPermission(PERMISSIONS.MODULES.EVENTS.UPDATE)

  const {
    data: event,
    loading,
    error,
    refetch,
  } = useGet(
    `${ServiceApiUrl.events}/${params.eventId}`,
    undefined,
    undefined,
    headers
  )

	const handleCloseEdit = () => {
		addAction(false)
		dispatch(setDrawingMap(false))
		dispatch(setDrawnCoords(null))
	}
	const handleEditSuccess = () => {
		handleCloseEdit()

		refetch()
	}

	const handleSelectedEvent = (option: ISelectOption | null) => {
		if (!option) return undefined;

		const path = formatRoutePath(location, params, {
			eventId: option.value,
		});
		navigate(path);
	};

	const customOptions = (data: IApiResponse<TEvent>) => {
		return data.data?.map((item: TEvent) => ({
			label: item.name,
			value: item.id,
		}));
	};

	const header = {
		title: event?.name ? event?.name : t("events.detail"),
		backTo: <BackToButton title={t("goBack")} onClick={() => navigate('/events')} />,
		select: event && (
			<FilterSelect<TEvent>
				apiUrl={ServiceApiUrl.events}
				onSelected={handleSelectedEvent}
				selectedValue={params?.eventId}
				othersConfig={headers}
				customOptions={customOptions}
			/>
		),
		action: event && canUpdate && (
			<Button
				variant="contained"
				disableElevation
				startIcon={<EditRoundedIcon />}
				onClick={() => addAction(true)}
			>
				{t("events.editEvent")}
			</Button>
		),
	}
  let eventView = <NoData error={error} />

	useEffect(() => {
		addHeader(header)
	}, [event?.name])

	if (loading) {
		eventView = <Loading show={loading} />
	}

	if (event) {
		eventView = (<>
			<EntityInfo title={t('common.details')}>
				<EntityInfoItem label={t('events.name')} value={event.name} />

				<EntityInfoItem label={t('events.status')} value={event.status.name} />

				<EntityInfoItem
					label={t('events.description')}
					value={event.description}
				/>

				<EntityInfoItem label={t('events.type')} value={event.type.name} />

				<EntityInfoItem
					label={t('events.category')}
					value={event.category.name}
				/>

				<EntityInfoItem
					label={
						event.type === 'expected'
							? t('events.startDate')
							: t('events.unexpectedStartDate')
					}
					value={formatDate(event.startDate, t('calendar.dateTimeFullFormat'))}
				/>

				<EntityInfoItem
					label={
						event.type === 'expected'
							? t('events.endDate')
							: t('events.unexpectedEndDate')
					}
					value={formatDate(event.endDate, t('calendar.dateTimeFullFormat'))}
				/>

				<EntityInfoItem label={t('events.eventURL')} value={event.url} />

				<EntityInfoItem
					label={t('events.eventReporter')}
					value={event.reporter.username}
				/>

				<EntityInfoItem
					label={t('common.createdAt')}
					value={formatDate(event.created_at, t('calendar.dateTimeFullFormat'))}
				/>
				<EntityInfoItem
					label={t('common.updatedAt')}
					value={formatDate(event.updated_at, t('calendar.dateTimeFullFormat'))}
				/>

				<EntityInfoItem
					label={t('events.location')}
					value={getFormattedLocation(event.geolocation)}
				/>

				<EntityInfoItem label={t('events.notes')} value={event.notes} />
			</EntityInfo>

			<EventDetailMap event={event} />
		</>
		)
	}

	let eventEdit
	if (action) {
		eventEdit = (
			<EventEdit
				open={action}
				data={event}
				onClose={handleCloseEdit}
				onSuccess={handleEditSuccess}
			/>
		)
	}

	return (
		<>
			{eventView}
			{eventEdit}
		</>
	)
}
export default EventDetail
