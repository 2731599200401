/* eslint-disable react-hooks/exhaustive-deps */
import { useFeedback } from "@alb/live-lib";
import useUpdate from "hooks/fetchData/useUpdate";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IDialogProps } from "types/interfaces";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import TypesForm, { IEventTypeForm } from "./TypesForm";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

const TypesEdit = ({
  open,
  data,
  onClose,
  onSuccess,
}: IDialogProps<IEventTypeForm>) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const editFormId = "edit-event-category-form";
  const [formIsValid, setFormIsValid] = useState(false);

  const { headers } = useGetModuleHeaders(MODULES_KEYS.EVENTS);

  //pedido para editar categorias dos eventos
  const {
    loading,
    refetch: updateEventType,
  } = useUpdate(ServiceApiUrl.eventsTypes, data?.id, headers);

  // sucesso
  const handlerSuccess = () => {
    onClose && onClose(); //fecha modal
    addFeedback({
      message: t("feedback.success.changesSaved"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  const formSubmitHandler = async (payLoad: IEventTypeForm) => {
    try {
      await updateEventType({ data: payLoad });
      await handlerSuccess();
    } catch (error) {}
  };

  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
          {t("events.types.editTypes")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <TypesForm
          formId={editFormId}
          data={data}
          onFormSubmit={formSubmitHandler}
          editForm={true}
          setFormIsValidStatus={getFormIsValidStatus}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!formIsValid}
          type={"submit"}
          form={editFormId}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TypesEdit;
