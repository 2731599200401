import { LayoutContext } from '@alb/live-lib'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Button } from '@mui/material'
import AddIcon from "@mui/icons-material/Add";
import ClientsAdd from "components/clients/ClientsAdd";
import Clients from "components/clients/Clients";
type Props = {}

const ClientsManagement = (props: Props) => {
  const { t } = useTranslation();
  const header = {
    title: t("pages.clients.headerText"),
    // backTo: <BackToButton title={t("goBack")} onClick={() => alert()} />,
    action: (
      <Button
        variant="contained"
        disableElevation
        startIcon={<AddIcon />}
        onClick={() => addAction(true)}
      >
        {t("clients.addClients")}
      </Button>
    ),
  }
  const { addHeader, addAction, action } = useContext(LayoutContext)
  const [ added, setAdded ] = useState<boolean>(false);
  // const handleAction = (action: boolean) => {
  //   addAction(action)
  // }

  useEffect(() => {
    addHeader(header)
    // eslint-disable-next-line
  }, [])

  let clientAdd;
  if (action) {
    clientAdd = (
      <ClientsAdd
        open={action}
        onClose={() => addAction(false)}
        onSuccess={() => {
          addAction(false);
          setAdded(true);
        }}
      />
    );
  }
  return (
    <>
      <Clients refetch={added} />
      {clientAdd}
    </>
  );
}

export default ClientsManagement
