import {
    Grid,
    MenuItem,
    Typography,
    SelectChangeEvent,
    ListSubheader,
  } from "@mui/material";
  import { useState, Dispatch, SetStateAction } from "react";
  import { useTranslation } from "react-i18next";
  import { TStream } from "types/types";
  import SelectMultipleStreams from "../../components/dashboard/select-multiple-streams/SelectMultipleStreams";
  
  interface IStreamsAxis {
    streamsChartLeftAxis: any[];
    streamsChartRightAxis: any[];
  }
  interface ISelectedItemsAxis {
    leftAxisSelectedItems: string[];
    rightAxisSelectedItems: string[];
  }
  interface IStreamsSelectsChart {
    allStreams: any[];
    streamsAxis: IStreamsAxis;
    setStreamsAxis: Dispatch<SetStateAction<IStreamsAxis>>;
    selectedItemsAxis: ISelectedItemsAxis;
    setSelectedItemsAxis: Dispatch<SetStateAction<ISelectedItemsAxis>>;
    handleStateSelectChange(
      event: SelectChangeEvent<string[]>,
      setOpenSelect: Dispatch<SetStateAction<boolean>>,
      axis: string
    ): void;
  }
  
  const SelectsStreamsChart = ({
    allStreams,
    streamsAxis,
    setStreamsAxis,
    selectedItemsAxis,
    setSelectedItemsAxis,
    handleStateSelectChange,
  }: IStreamsSelectsChart) => {
    const { t } = useTranslation();
  
    //controla o estado dos selects
    const [openLeftSelect, setOpenLeftSelect] = useState(false);
    const [openRightSelect, setOpenRightSelect] = useState(false);
  
    function disableStream(stream: TStream, axisChartStreams: TStream[]) {
      //se as streams no gráfico forem mais de 2, vai verificar quais são as unidades diferentes do primeiro elemento no gráfico e dá disabled a essas
      if (axisChartStreams?.length >= 2) {
        if (stream.unit !== axisChartStreams[0].unit) {
          return true;
        }
      }
    }
  
    const cleanRightSelection = () => {
      setSelectedItemsAxis({ ...selectedItemsAxis, rightAxisSelectedItems: [] });
      setStreamsAxis({ ...streamsAxis, streamsChartRightAxis: [] });
      setOpenRightSelect(false);
    };
    const cleanLeftSelection = () => {
      setSelectedItemsAxis({ ...selectedItemsAxis, leftAxisSelectedItems: [] });
      setStreamsAxis({ ...streamsAxis, streamsChartLeftAxis: [] });
      setOpenLeftSelect(false);
    };
  
    return (
      <Grid container>
        <Grid item xs>
          <SelectMultipleStreams
            open={openLeftSelect}
            setOpen={setOpenLeftSelect}
            value={selectedItemsAxis.leftAxisSelectedItems ?? " "}
            onChange={(
              e: SelectChangeEvent<typeof selectedItemsAxis.leftAxisSelectedItems>
            ) => {
              handleStateSelectChange(e, setOpenLeftSelect, "left");
            }}
            renderValue={
              selectedItemsAxis.leftAxisSelectedItems.length > 0
                ? undefined
                : () => (
                    <Typography noWrap variant={"inherit"}>
                      {t("dashboard.selectStreams")}
                    </Typography>
                  )
            }
          >
            {selectedItemsAxis.leftAxisSelectedItems?.length > 0 && (
              <ListSubheader disableGutters color="inherit">
                {/* utilizar o onClickCapture no MenuItem, em vez de colocar uma ListSubheader também é uma solução */}
                <MenuItem component="div" onClick={cleanLeftSelection}>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {t("dashboard.clearSelection")}
                  </Typography>
                </MenuItem>
              </ListSubheader>
            )}
            {allStreams
              ?.filter(
                (s: TStream) =>
                  !selectedItemsAxis.rightAxisSelectedItems.includes(s.id)
              )
              .map((stream: TStream) => (
                <MenuItem
                  disabled={disableStream(
                    stream,
                    streamsAxis.streamsChartLeftAxis
                  )}
                  key={stream.id}
                  value={stream.id}
                >
                  {t(`hydrogenSupply.table.header.${stream.id}`)}
                </MenuItem>
              ))}
          </SelectMultipleStreams>
        </Grid>
        {allStreams.length > 1 && (
          <Grid item xs sx={{ textAlign: "end" }}>
            <SelectMultipleStreams
              open={openRightSelect}
              setOpen={setOpenRightSelect}
              value={selectedItemsAxis.rightAxisSelectedItems ?? " "}
              onChange={(
                e: SelectChangeEvent<
                  typeof selectedItemsAxis.rightAxisSelectedItems
                >
              ) => {
                handleStateSelectChange(e, setOpenRightSelect, "right");
              }}
              renderValue={
                selectedItemsAxis.rightAxisSelectedItems.length > 0
                  ? undefined
                  : () => (
                      <Typography noWrap variant={"inherit"}>
                        {t("dashboard.selectStreams")}
                      </Typography>
                    )
              }
            >
              {selectedItemsAxis.rightAxisSelectedItems?.length > 0 && (
                <ListSubheader disableGutters color="inherit">
                  {/* utilizar o onClickCapture no MenuItem, em vez de colocar uma ListSubheader também é uma solução */}
                  <MenuItem component="div" onClick={cleanRightSelection}>
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                      {t("dashboard.clearSelection")}
                    </Typography>
                  </MenuItem>
                </ListSubheader>
              )}
              {allStreams
                ?.filter(
                  (s: TStream) =>
                    !selectedItemsAxis.leftAxisSelectedItems.includes(s.id)
                )
                .map((stream: TStream) => (
                  <MenuItem
                    disabled={disableStream(
                      stream,
                      streamsAxis.streamsChartRightAxis
                    )}
                    key={stream.id}
                    value={stream.id}
                  >
                    {t(`hydrogenSupply.table.header.${stream.id}`)}
                  </MenuItem>
                ))}
            </SelectMultipleStreams>
          </Grid>
        )}
      </Grid>
    );
  };
  
  export default SelectsStreamsChart;
  