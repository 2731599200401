export const MODULES_KEYS: {[key: string]: string} = {
  EVENTS: "EVENTS",
  OCCURRENCES: "OCCURRENCES",
  ENERGYPTD: "ENERGYPTD",
  EXECUTIVE: "EXECUTIVE",
	ELECTRICMOBILITY: "ELECTRICMOBILITY",
	HYDROGENSUPPLY: "HYDROGENSUPPLY",
	URBANWASTE: "URBANWASTE",
  PEOPLEMOBILITY: "PEOPLEMOBILITY",
  OPENDATA: "OPENDATA",
  CERMANAGEMENT: "CERMANAGEMENT",
  PARKING: "PARKING",
  WAZE: "WAZE",
  BUGA: "BUGA",
	AIRQUALITY: "AIRQUALITY"
};
