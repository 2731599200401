/* eslint-disable react-hooks/exhaustive-deps */
import { MapType, useFeedback } from "@alb/live-lib";

import { useEffect, useState, ChangeEvent, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getGeojsonFilesUpload,
  getSelectedMapType,
} from "store/slices/mapSlice";
import { stringIsJSON } from "utils/utils";

interface IFileUpload {
  onChangeFile: (text: JSON, name: string) => void;
}
export const FileUpload = ({ onChangeFile }: IFileUpload) => {
  const [textFile, setTextFile] = useState(null);
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const viewMapType = useSelector(getSelectedMapType);
  const geojsonUpload = useSelector(getGeojsonFilesUpload);

  let fileReader: any;
  let fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChosen = (e: ChangeEvent<HTMLInputElement>) => {
    let file: FileList | null = e.target.files;
    if (file && file.length > 0) {
      fileReader = new FileReader();
      fileReader.onloadend = handleFileRead;
      fileReader.readAsText(file[0]);
    }
  };

  const handleFileRead = () => {
    let content = fileReader.result;
    setTextFile(content);
  };

  useEffect(() => {
    //se o nome ja existir no geojsonUpload, não deixa submeter novamente
    const fileName = fileInputRef.current?.files
      ? fileInputRef.current.files[0]?.name
      : "";

    //clear cached files
    if (fileInputRef?.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.files = null;
    }
    setTextFile(null);

    if (
      geojsonUpload &&
      geojsonUpload.find((file: any) => file?.name === fileName)
    ) {
      addFeedback({
        message: t("feedback.warning.fileAlreadySubmitted", {
          file: fileName,
        }),
        severity: "warning",
      });
      return;
    }

    if (textFile && stringIsJSON(textFile)) {
      const gjv = require("geojson-validation");
      const info = JSON.parse(textFile);
      if (gjv.valid(info)) {
        onChangeFile(JSON.parse(textFile), fileName);
        const messageFeedback =
          viewMapType === MapType.choropleth &&
          geojsonUpload &&
          geojsonUpload?.length > 0
            ? t("feedback.success.uploadReplaceFile")
            : t("feedback.success.uploadFile");
        addFeedback({
          message: messageFeedback,
          severity: "success",
        });
      } else {
        addFeedback({
          message: t("feedback.error.uploadFileWrongFormat", {
            file: fileName,
          }),
          severity: "error",
        });
      }
    } else if (textFile && !stringIsJSON(textFile)) {
      addFeedback({
        message: t("feedback.error.uploadWrongFile"),
        severity: "error",
      });
    }
  }, [textFile]);

  return (
    <input
      hidden
      accept="application/json, .geojson"
      type="file"
      onChange={handleFileChosen}
      ref={fileInputRef}
    />
  );
};
