import { TabList } from "@mui/lab";
import { Grid, IconButton, styled, Table, TableCell, TableRow } from "@mui/material";
import { getDefaultBoxShadow } from '@alb/live-lib'
//SELECT DIV FOR 2 SELECTS
//caso se queira utilizar 100% de uma grid para colocar vária info lado a lado, sem usar várias grids
export const DoubleSelect = styled(Grid)<any>(({ theme }) => ({
  display: "flex",
  borderRadius: 20,
  // backgroundColor: variables.white,
  alignItems: "baseline",
  // border: "1px solid",
  borderColor: theme.palette.text.secondary,

  "&:hover": {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
  },
  "&.focused": {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
  },
}));

// card for chart (Dashboard)
export const GridWhiteContainer = styled(Grid)<any>(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  backgroundColor:
    theme.palette.mode === "light" ? theme.palette.common.white : theme.palette.greyColor.dark,
  boxShadow: getDefaultBoxShadow(),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
}));

export const TableLink = styled(Table)(() => ({
  minWidth: "650px",
  "& .MuiTableRow-root": {
    cursor: "pointer",
  },
  "& .MuiTableRow-root.no-pointer": {
    cursor: "default",
  },
}));

export const TableRowExpanded = styled(TableRow)(({ theme }) => ({
  cursor: "default !important",
  "& .MuiTableRow-root": {
    cursor: "default !important",
  },
  "& .MuiTableBody-root .MuiTableRow-root:hover": {
    backgroundColor:
      theme.palette.mode === "light"
        ? theme.palette.common.white
        : theme.palette.secondary.dark,
  },
}));

export const TableCellExpanded = styled(TableCell)(({ theme }) => ({
  paddingLeft: "50px !important",
  paddingTop: "0 !important",
  paddingBottom: "0 !important",
  backgroundColor:
    theme.palette.mode === "light"
      ? theme.palette.secondary.light
      : theme.palette.greyColor.main,
}));

export const TableExpanded = styled(Table)(({ theme }) => ({
  minWidth: "650px",
  marginTop: theme.spacing(5),

  "& .MuiTableHead-root .MuiTableRow-root": {
    backgroundColor: "unset",
  },

  "& .MuiTableBody-root .MuiTableRow-root": {
    cursor: "pointer",
    "&.expanded": {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.secondary.light
          : theme.palette.greyColor.main,
    },
  },

  "& .MuiTableRow-root.no-pointer": {
    cursor: "default",
  },
  ".MuiCollapse-root": {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const indicatorSize = "3px";
export const ContainedTabs = styled(TabList)(({ theme }) => ({
  '& .MuiTabs-scrollButtons.Mui-disabled': {
    opacity: '0.3'
  },
  borderBottom: `${indicatorSize} solid rgba(0, 0, 0, 0.08);`,
  "& .MuiButtonBase-root.MuiTab-root": {
    width: "200px",
    textTransform: "none",
    // color: theme.palette.text.primary,
    marginRight: "4px",
    fontSize: "17px",
    "&.Mui-selected": {
      fontWeight: "700",
      color: theme.palette.text.primary,
    },
    "&:last-of-type": {
      marginRight: "0px",
    },
  },
  "& .MuiTabs-indicator": {
    height: indicatorSize,
  },
}));

//caso se queira utilizar 100% de uma grid para colocar vária info lado a lado, sem usar várias grids
export const FlexGridBaseline = styled(Grid)<any>(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
}));

export const ExpandButtonStyle = styled(IconButton)<any>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  fontSize: "23px",
  padding: "2px",
  color: "#9da8b6",
  borderRadius: "50px",
  boxShadow: getDefaultBoxShadow(),
  "&:hover": {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.common.white,
  },
  ...(theme.palette.mode === "dark" && {
    backgroundColor: theme.palette.greyColor.dark,
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: "#424242",
    },
  }),
}));