
import { TFunction } from 'i18next';
import { ISelectOption } from 'types/interfaces'

const languages = [
  { labelKey: "user.portuguese", value: "pt" },
  { labelKey: "user.english", value: "en" },
];

export const getLanguages = (
  t: TFunction<"translation", undefined>
): ISelectOption[] => {
  const langOptions = languages.map((l) => {
    return { label: t(l.labelKey), value: l.value };
  });

  return langOptions;
};
