import { getColorTrafficWaze } from "@alb/live-lib";
import L from "leaflet";
import { TWazeJamMarker } from "types/types";

import './styles/WazeJamsStyles.scss'
import { darkenColor } from "utils/colors";

const wazeJamIcon = (marker: TWazeJamMarker, isDarkMode: boolean) => {
  const bgColor = getColorTrafficWaze(marker.geolocation.level, isDarkMode)
  let borderColor;
  if(bgColor)
    borderColor = darkenColor(bgColor, isDarkMode ? 50 : 25)
  const divIcon = L.divIcon({
    className: "jamIcon",
    html: `
      <div 
        class="jamIconDiv" 
        style="
          background-color: ${bgColor};
          border: 1px solid ${borderColor}
          ">${marker.avg_speed} km/h</div>`
  })
  return divIcon
}

export default wazeJamIcon;