import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, darken, useTheme } from "@mui/material"
import { useEffect, useState } from "react"
import DeleteButton from "./buttons/DeleteButton";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import { arrayIsEmpty } from "@alb/live-lib";

const OpenDataXLSTable = (props: any) => {
    const theme = useTheme();
    const darkMode = useSelector(getUser)?.extra_params?.dark_mode
    
    const [rows, setRows] = useState([]);

    useEffect(() => {
        let data = props.content;

        for(let i = 0; i < data.length; i++) {
            if(!data[i])
                data[i] = []
        }

        setRows(data.map((r: any, ri: number)  => {
            return(<TableRow key={ri} sx={{ borderRadiu: 0 }}>
                {
                    r.map((c: any, ci: number) => {
                        return(<TableCell key={`${ri}${ci}`} sx={{borderRadius: "0 !important", backgroundColor: c.style ?  (darkMode ? darken(`#${c.style.fgColor.rgb}`, 0.7)  : `#${c.style.fgColor.rgb}`): theme.palette.background.paper}} >{c.value}</TableCell>)
                    })
                }
            </TableRow>)
        }))
        // eslint-disable-next-line
    }, [props.content])

    return(<>
    {!arrayIsEmpty(props.content) && (
        <TableContainer sx={{mt: 4, maxHeight: "85vh", }} component={Paper}>
            <Box sx={{
                width: "100%",
            }}>
                <DeleteButton deleteArray={props.clearArrays} />
            </Box>
        
            <Table sx={{ minWidth: 650, borderSpacing: 0  }}>
                <TableBody>
                    {rows}
                </TableBody>
            </Table>
        </TableContainer>)}
    </>)
}

export default OpenDataXLSTable