/* eslint-disable react-hooks/exhaustive-deps */
import { InputAutocomplete, InputCheckbox, InputDateTime, InputSelect, InputText, useFeedback } from "@alb/live-lib";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Alert, FormGroup, Grid, SelectChangeEvent, Typography } from "@mui/material";
import Divider from "@mui/material/Divider/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";
import { endOfDay, startOfDay } from "date-fns";
import { debounce } from "lodash";
import React from "react";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TAdapterConfigurator } from "types/types";
import { date, object } from "yup";

import useGet from "hooks/fetchData/useGet";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { setDevicesFilterParams } from "store/slices/adapterConfiguratorsSlice";
import { setEventsFilterParams } from "store/slices/eventsSlice";
import { getSelectedMarkerType, setFitBounds, setSelectedMarkerType } from "store/slices/mapSlice";
import { selectOccurrencesFilterParams, setOccurrencesFilterParams } from "store/slices/occurrencesSlice";
import { formatDate } from "utils/date";
import { PERMISSIONS } from "utils/permissions/permissions";
import { arrayIsEmpty } from "utils/utils";

import useMapFilters from "./hooks/useMapFilters";
import { GetOptions } from "./utils/GetOptions";
import { getWazeFilterParams, setWazeFilterParams } from "store/slices/wazeSlice";

interface IFiltersForm {
  formId: string;
  publicAPI: boolean;
  trigger?: number;
  applyButtonStatus?: (status: boolean) => void;
}
type IAdaptersKeys = {
  [key: string]: boolean;
};

export interface IFilters {
  type?: string;
  eventStatus?: any;
  eventsCategory?: any;
  start_date_events?: any;
  end_date_events?: any;
  occurrencesStatus?: any;
  occurrencesCategory?: any;
  wazeAdapters?: string | null;
  to?: any,
  from?: any,
  //   end_date_occurrences?: any;
  last_date_update_occurrences?: any;
  deviceStatus?: any;
  visitsMade?: any;
  geographicalArea?: any;
  period?: any;
  adapters?: IAdaptersKeys;
}
const DividerStyled = styled(Divider)(() => ({
  margin: "30px 0px",
  width: "100%",
}));

let resetEvent: boolean = false;
let firstFormChange: boolean;

export const FilterTabForm = ({ formId, publicAPI }: IFiltersForm) => {
  const { t, i18n } = useTranslation();
  const { hasPermission } = usePermission();
  const canReadEvents = hasPermission(PERMISSIONS.MODULES.EVENTS.READ);
  const canReadOccurrences = hasPermission(
    PERMISSIONS.MODULES.OCCURRENCES.READ
  );
  const canReadWaze = hasPermission(PERMISSIONS.MODULES.WAZE.READ)
  const selectedType = useSelector(getSelectedMarkerType);
  let mapOccurrencesParams = useSelector(selectOccurrencesFilterParams);
  const dispatch = useDispatch();
  const {
    eventsStatusOptions,
    occurrencesStatusOptions,
    eventsCategoriesOptions,
    occurrencesCategoriesOptions,
    wazeAdaptersOptions,
    typeOptions,
    devicesStatusOptions,
    MarkersTypeOptions,
    visitsMadeOptions,
    geographicalAreaOptions,
    periodOptions,
  } = GetOptions(useSelector, useGet, t, hasPermission, publicAPI);
  const { addFeedback } = useFeedback();

  // get list of gateways
  const { data: adapterConfigurators, refetch } = useGet(
    ServiceApiUrl.adapterConfigurators,
    {
      contains_devices: true
    },
    { manual: true }
  );

  useEffect(() => {
    resetEvent = false;
    firstFormChange = false;
    if (!publicAPI && selectedType !== "visits") {
      refetch();
    }
  }, []);

  const [formValues, setFormValues] = useState<IFilters>();
  const [checked, setChecked] = useState<{ adapters?: IAdaptersKeys }>();

  const adapterConfigurators_aux = adapterConfigurators?.data.filter((adapter: any )=> adapter.name !== 'Mobilidade Pessoas - Beja')
  const dispatchParams = useMapFilters(adapterConfigurators_aux, publicAPI);
  // extract adapter configurator names
  let adaptersNames: string[] = useMemo(
    () =>
    adapterConfigurators_aux
        ?.map((adapter: TAdapterConfigurator) => adapter.name.replaceAll('.', ''))
        .sort(),
    [adapterConfigurators]
  );
  let adapterschecks = { adapters: {} };
  // default dynamic values for checkboxes
  if (adapterConfigurators_aux) {
    adaptersNames?.map(
      (adapter: string) =>
        (adapterschecks.adapters = {
          ...adapterschecks.adapters,
          [adapter]: true,
        })

    )
    adaptersNames=  adaptersNames?.filter((adapter: any )=> adapter !== 'Mobilidade Pessoas - Beja')
  }
  // checkboxes state when filter dialog opens
  useEffect(() => {
    if (adapterConfigurators && !arrayIsEmpty(adapterConfigurators_aux)) {
      setChecked(adapterschecks);
      reset(defaultValues);
    }
  }, [adapterConfigurators]);

  const {
    control: controlSearchAndType,
    reset: resetSearchAndType,
    setValue,
    watch: searchWatchAndType,
  } = useForm<{ type: string; search: string }>({
    defaultValues: { type: MarkersTypeOptions?.all, search: "" },
  });

  const defaultValues = {
    eventsCategory: null,
    occurrencesCategory: null,
    // end_date_occurrences: null,
    last_date_update_occurrences: null,
    start_date_events: null,
    end_date_events: null,
    deviceStatus: null,
    eventStatus: null,
    occurrencesStatus: null,
    wazeAdapters: null,
    visitsMade: null,
    geographicalArea: null,
    period: null,
    ...adapterschecks,
  };

  const getFiltersSchema = () => {
    return object().shape({
      start_date_events: date()
        .nullable()
        .typeError(t("validations.invalidDate", { dateFormat: "yyyy-MM-dd" })),
      // .max(new Date(), t("validations.invalidMaxDate", { maxDate: nowDateTime("yyyy-MM-dd") })),
      end_date_events: date()
        .nullable()
        .typeError(t("validations.invalidDate", { dateFormat: "yyyy-MM-dd" }))
        // .max(new Date(), t("validations.invalidMaxDate", { maxDate: nowDateTime("yyyy-MM-dd") }))
        .when("start_date_events", ([start_date_events], schema) => {
          if (start_date_events) {
            const dateFromFormatted = formatDate(
              start_date_events,
              "yyyy-MM-dd"
            );
            return schema.min(
              start_date_events,
              t("validations.invalidMinDate", { minDate: dateFromFormatted })
            );
          } else {
            return schema;
          }
        }),
    });
  };
  const filtersForm = useForm<IFilters>({
    defaultValues: defaultValues,
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(getFiltersSchema()),
  });
  const {
    control,
    reset,
    resetField,
    watch,
    trigger,
    formState: { isDirty, errors: filterErrors },
  } = filtersForm;

  const debounceData = debounce((cb) => {
    cb();
  }, 700);  //valor aumentado por causa do centrar nos resultados ao escrever
  const handleSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    debounceData(() => {
    //o debounce foi retirado aqui mas foi colocado nos pedidos (no dispatchParams)
    dispatchParams(undefined, e.target.value);
    });
  };
  const markersTypeRedux = useSelector(getSelectedMarkerType);
  const [typeState, setTypeState] = useState<string | string[] | undefined>(
    filtersForm.getValues().type || markersTypeRedux
  );

  // quando se altera no menu dos markers (leftTopComponent) é preciso atualizar os types da tab do filter
  useEffect(() => {
    if (typeState !== markersTypeRedux) {
      setTypeState(markersTypeRedux);
      resetForm();
    }
  }, [markersTypeRedux]);


  const success = () => {
    addFeedback({
      message: t("feedback.success.appliedFilters"),
      severity: "success",
      duration: 1500,
    });
  };
  let mapWazeParams = useSelector(getWazeFilterParams)
  const resetForm = () => {
    resetEvent = true; //auxiliar para utilizar no watch
    reset();
    resetSearchAndType();
    dispatch(setDevicesFilterParams({}));
    dispatch(setFitBounds(true));
    success()
    dispatch(
      setOccurrencesFilterParams({
        "record_date": mapOccurrencesParams["record_date"],
      })
    );
    dispatch(setEventsFilterParams({}));
    dispatch(setWazeFilterParams({
      ...mapWazeParams,
      ...( {selectedAdapter: ''})
    }))
  };
  const typeOnChange = (e: SelectChangeEvent<string[] | string>) => {
    resetForm();
    setTypeState(e.target.value);
    setValue("type", e.target.value as string, { shouldDirty: true });
    dispatch(setSelectedMarkerType(e.target.value));
    if (!firstFormChange) firstFormChange = true; //da primeira vez em que for aplicado algum filtro, troca a variavel para true
  };
  //sempre que o tipo for alterado, limpa os parametros e os pedidos
  useEffect(() => {
    if (firstFormChange && !onlyOneOptionType)
      dispatchParams(undefined, undefined, true);
  }, [selectedType]);
  //dispatch dos values
  useEffect(() => {
    if (!firstFormChange && isDirty) firstFormChange = true;
    if (firstFormChange) {
      trigger("start_date_events"); // re validate field
      trigger("end_date_events"); // re validate field
      setTimeout(() => {
        if (!filterErrors.start_date_events && !filterErrors.end_date_events) {
          dispatchParams(formValues);
        }
      }, 0);
      // dispatchParams(formValues);
    }
  }, [formValues]);

  //O watch era ativado sempre que havia uma alteração no form, então estava a entrar sempre que os valores alteravam (o pretendido)
  //e sempre que o tipo alterava, porque dá reset, (não queremos isto)
  //então a solução foi usar uma variavel auxiliar (resetEvent) para controlar quando entra no watch pelo reset ao alterar o tipo, ou por alterações no form.
  useEffect(() => {
    const subscription = watch(() => {
      if (!resetEvent) {
        const filtersFormValues = filtersForm.getValues();
        filtersFormValues.start_date_events =
          filtersFormValues.start_date_events
            ? startOfDay(filtersFormValues.start_date_events)
            : null;
        filtersFormValues.end_date_events = filtersFormValues.end_date_events
          ? endOfDay(filtersFormValues.end_date_events)
          : null;
        setFormValues(filtersFormValues);
      }
      resetEvent = false;
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  //se o tipo apenas tiver 2 opções, não mostra. é o all e o próprio.
  let onlyOneOptionType = typeOptions.length <= 2;

  //obter os valores dos adapters e devolve os que tem check
  let adaptersValues = watch("adapters");
  let checkedAdapters = Object.entries(adaptersValues as any).filter(
    ([key, value]) => value === true
  );
  const searchValue = searchWatchAndType("search");
  const typeValue = searchWatchAndType("type");
  const activeFields = {
    search: searchValue.replace(/\s/g, "").length > 0 ? searchValue : undefined,
    type: typeValue !== "all" ? typeValue : undefined,
    events: {
      status: watch("eventStatus"),
      category: watch("eventsCategory"),
      start: watch("start_date_events"),
      end: watch("end_date_events"),
    },
    occurrences: {
      status: watch("occurrencesStatus"),
      category: watch("occurrencesCategory"),
      //   end: watch("end_date_occurrences"),
      last_update: watch("last_date_update_occurrences"),
    },
    waze: {
      adapter: watch("wazeAdapters")
    },
    devices: {
      status: watch("deviceStatus"),
    },
    visits: {
      made: watch("visitsMade"),
      geographicalArea: watch("geographicalArea"),
      period: watch("period"),
    },
  };

  //quando o estado deixar de ter valores, ao esconder a data de fim, também limpa o valor do campo
  useEffect(() => {
    if (!activeFields.occurrences.status) {
      resetField("last_date_update_occurrences");
    }
  }, [activeFields.occurrences.status]);

  return (
    <>
      <FormProvider {...filtersForm}>
        <form id={formId}>
          <FormGroup>
            <Grid container mt={3} spacing={1}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="subtitle2">
                  {t("common.search")}
                </Typography>
                <InputText
                  placeholder={t("common.searchByName")}
                  name="search"
                  control={controlSearchAndType}
                  handleOnChange={handleSearch}
                  activeField={activeFields.search}
                  endAdornment={
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </Grid>
              {!onlyOneOptionType && typeState !== MarkersTypeOptions.visits && (
                <Grid item xs={12}>
                  <Typography gutterBottom variant="subtitle2">
                    {t("common.type")}
                  </Typography>
                  <InputSelect
                    placeholder={t("common.typePlaceholder")}
                    name="type"
                    control={controlSearchAndType}
                    options={typeOptions}
                    handleOnChange={typeOnChange}
                    activeField={activeFields.type}
                  />
                </Grid>
              )}
            </Grid>
            {(typeState !== MarkersTypeOptions.all || onlyOneOptionType) && (
              <>
                <Grid container rowSpacing={1}>
                  <DividerStyled />

                  {typeState === MarkersTypeOptions.events &&
                    (canReadEvents || publicAPI) && (
                      <>
                        <Typography
                          gutterBottom
                          variant="caption"
                          sx={{ color: "#7C7C7C" }}
                        >
                          {t("events.events")}
                        </Typography>

                        {eventsStatusOptions && (
                          <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2">
                              {t("common.statusLabel")}
                            </Typography>
                            <InputAutocomplete
                              placeholder={t("common.statusPlaceholder")}
                              name="eventStatus"
                              control={control}
                              options={eventsStatusOptions}
                              activeField={activeFields.events.status}
                            />
                          </Grid>
                        )}

                        {eventsCategoriesOptions && (
                          <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2">
                              {t("events.category")}
                            </Typography>
                            <InputAutocomplete
                              placeholder={t("events.categoryPlaceholder")}
                              name="eventsCategory"
                              control={control}
                              options={eventsCategoriesOptions}
                              activeField={activeFields.events.category}
                            />
                          </Grid>
                        )}

                        <Grid item xs={12}>
                          <Typography
                            gutterBottom
                            variant="subtitle2"
                            sx={{
                              verticalAlign: "middle",
                              display: "inline-flex",
                              gap: 0.5,
                            }}
                          >
                            {t("events.startDate")}
                            <Tooltip
                              placement="top"
                              title={t("events.startDateFilterTooltip")}
                            >
                              <InfoOutlinedIcon
                                sx={{ fontSize: 20 }}
                                color="primary"
                              />
                            </Tooltip>
                          </Typography>
                          <InputDateTime
                            clearButton
                            name="start_date_events"
                            control={control}
                            activeField={activeFields.events.start}
                            locale={i18n.language}
                            labelBtnCancel={t("cancel")}
                            labelBtnConfirm={t("apply")}
                            placeholder={t("calendar.placeholderDate")}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Typography
                            gutterBottom
                            variant="subtitle2"
                            sx={{
                              verticalAlign: "middle",
                              display: "inline-flex",
                              gap: 0.5,
                            }}
                          >
                            {t("events.endDate")}
                            <Tooltip
                              placement="top"
                              title={t("events.endDateFilterTooltip")}
                            >
                              <InfoOutlinedIcon
                                sx={{ fontSize: 20 }}
                                color="primary"
                              />
                            </Tooltip>
                          </Typography>
                          <InputDateTime
                            clearButton
                            name="end_date_events"
                            control={control}
                            activeField={activeFields.events.end}
                            locale={i18n.language}
                            labelBtnCancel={t("cancel")}
                            labelBtnConfirm={t("apply")}
                            placeholder={t("calendar.placeholderDate")}
                          />
                        </Grid>
                      </>
                    )}
                  
                  { typeState === MarkersTypeOptions.waze &&  canReadWaze &&
                    <>
                      <Typography
                          gutterBottom
                          variant="caption"
                          sx={{ color: "#7C7C7C" }}
                        >
                          {t("sidebar.waze")}
                        </Typography>

                        { wazeAdaptersOptions && (
                          <Grid item xs={12}>
                          <Typography gutterBottom variant="subtitle2">
                            {t("sidebar.adapters")}
                          </Typography>
                          <InputAutocomplete
                            placeholder={t("waze.selectAdapterPlaceholder")}
                            name="wazeAdapters"
                            control={control}
                            options={wazeAdaptersOptions}
                          />
                        </Grid>
                        ) }
                    </>
                  }

                  {typeState === MarkersTypeOptions.occurrences &&
                    (canReadOccurrences || publicAPI) && (
                      <>
                        <Typography
                          gutterBottom
                          variant="caption"
                          sx={{ color: "#7C7C7C" }}
                        >
                          {t("occurrences.occurrences")}
                        </Typography>

                        {occurrencesStatusOptions && (
                          <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2">
                              {t("common.statusLabel")}
                            </Typography>
                            <InputAutocomplete
                              placeholder={t("common.statusPlaceholder")}
                              name="occurrencesStatus"
                              control={control}
                              options={occurrencesStatusOptions}
                              activeField={activeFields.occurrences.status}
                            />
                          </Grid>
                        )}
                        {/* Data de última atualização */}
                        {activeFields.occurrences.status && (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                gutterBottom
                                variant="subtitle2"
                                // sx={{
                                //   verticalAlign: "middle",
                                //   display: "inline-flex",
                                //   gap: 0.5,
                                // }}
                              >
                                {t("occurrences.lastUpdateDate")}
                                {/* <Tooltip
                                  placement="top"
                                  title={t("occurrences.lastUpdateDateTooltip")}
                                >
                                  <InfoOutlinedIcon
                                    sx={{ fontSize: 20 }}
                                    color="primary"
                                  />
                                </Tooltip> */}
                              </Typography>
                              <InputDateTime
                                showTimeInput
                                minDate={
                                  new Date(mapOccurrencesParams["record_date"])
                                }
                                clearButton
                                name="last_date_update_occurrences"
                                control={control}
                                activeField={
                                  activeFields.occurrences.last_update
                                }
                                locale={i18n.language}
                                labelBtnCancel={t("cancel")}
                                labelBtnConfirm={t("apply")}
                                placeholder={t("calendar.placeholderDate")}
                              />
                            </Grid>
                            <Alert sx={{ mt: 1 }} severity="warning">
                              <strong>
                                {t("occurrences.lastUpdateDate")}{" "}
                              </strong>
                              - {t("occurrences.lastUpdateDateTooltip")}
                            </Alert>
                          </>
                        )}

                        {occurrencesCategoriesOptions && (
                          <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2">
                              {t("occurrences.category")}
                            </Typography>
                            <InputAutocomplete
                              placeholder={t("events.categoryPlaceholder")}
                              name="occurrencesCategory"
                              control={control}
                              options={occurrencesCategoriesOptions}
                              activeField={activeFields.occurrences.category}
                            />
                          </Grid>
                        )}
                        {/* {activeFields.occurrences.status &&
												<Grid item xs={12}>
													<Typography
														gutterBottom
														variant="subtitle2"
														sx={{
															verticalAlign: 'middle',
															display: 'inline-flex',
															gap: 0.5,
														}}
													>
														{t('events.endDate')}
														<Tooltip
															placement="top"
															title={t('occurrences.endDateFilterTooltip')}
														>
															<InfoOutlinedIcon
																sx={{ fontSize: 20 }}
																color="primary"
															/>
														</Tooltip>
													</Typography>
													<InputDateTime
														clearButton
														name="end_date_occurrences"
														control={control}
														activeField={activeFields.occurrences.end}
														locale={i18n.language}
														labelBtnCancel={t('cancel')}
														labelBtnConfirm={t('apply')}
														placeholder={t('calendar.placeholderDate')}
													/>
												</Grid>
												} */}
                      </>
                    )}

                  {(typeState === MarkersTypeOptions.devices ||
                    (typeState === MarkersTypeOptions.all &&
                      onlyOneOptionType)) && (
                    <>
                      <Typography
                        gutterBottom
                        variant="caption"
                        sx={{ color: "#7C7C7C" }}
                      >
                        {t("adapters.devices")}
                      </Typography>

                      {devicesStatusOptions &&
                        devicesStatusOptions.length > 1 && (
                          <Grid item xs={12}>
                            <Typography gutterBottom variant="subtitle2">
                              {t("common.statusLabel")}
                            </Typography>
                            <InputAutocomplete
                              placeholder={t("common.statusPlaceholder")}
                              name="deviceStatus"
                              control={control}
                              options={devicesStatusOptions}
                              activeField={activeFields.devices.status}
                            />
                          </Grid>
                        )}
                      <Grid item xs={12}>
                        <Typography gutterBottom variant="subtitle2">
                          {t("modules.adapters")}
                        </Typography>
                        {adaptersNames?.length > 0 && checked ? (
                          <>
                            {adaptersNames?.map((adapter: string) => (
                              <React.Fragment key={adapter}>
                                <InputCheckbox
                                  key={adapter}
                                  control={control}
                                  name={`adapters.${adapter}`}
                                  labelText={adapter}
                                  setValue={setValue}
                                  checked={
                                    checked.adapters &&
                                    checked.adapters[adapter]
                                  }
                                  disabled={
                                    checkedAdapters.length === 1 &&
                                    checkedAdapters[0][0] === adapter
                                  }
                                />
                                <br />
                                </React.Fragment>
                            ))}
                          </>
                        ) : (
                          <Typography>{t("map.filter.noAdapters")}</Typography>
                        )}
                      </Grid>
                    </>
                  )}
                  {typeState === MarkersTypeOptions.visits && (
                    <>
                      <Typography
                        gutterBottom
                        variant="caption"
                        sx={{ color: "#7C7C7C" }}
                      >
                        {t("map.filter.types.visits")}
                      </Typography>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant="subtitle2">
                          {t("map.filter.visitsMade")}
                        </Typography>
                        <InputAutocomplete
                          placeholder={t("map.filter.visitsMadePlaceholder")}
                          name="visitsMade"
                          control={control}
                          options={visitsMadeOptions}
                          activeField={activeFields.visits.made}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant="subtitle2">
                          {t("map.filter.geographicalArea")}
                        </Typography>
                        <InputAutocomplete
                          placeholder={t("map.filter.visitsMadePlaceholder")}
                          name="geographicalArea"
                          control={control}
                          options={geographicalAreaOptions}
                          activeField={activeFields.visits.geographicalArea}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography gutterBottom variant="subtitle2">
                          {t("map.detail.period")}
                        </Typography>
                        <InputAutocomplete
                          placeholder={t("map.filter.periodPlaceholder")}
                          name="period"
                          control={control}
                          options={periodOptions}
                          activeField={activeFields.visits.period}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </>
            )}
          </FormGroup>
        </form>
      </FormProvider>
    </>
  );
};
