import { useTheme } from "@mui/material";
import { addDays, endOfDay, format, startOfDay } from "date-fns";
import { XAXisComponentOption, YAXisComponentOption } from "echarts";
import _, { debounce } from "lodash";
import { useEffect, useRef } from "react";
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { ISelectedDeviceAndStreams } from "types/interfaces";
import { TStream } from "types/types";

import { defaultOptions } from "components/charts/defaultOptions";
import { IReactECharts } from "components/charts/ReactECharts";
import useGet from "hooks/fetchData/useGet";
import useErrorMessage from "hooks/useErrorMessage";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";
import { arrayIsEmpty, objectHasEmptyArrays, objectIsEmpty } from "utils/utils";

interface IDeviceValuesFilterParams {
	external_id: string;
	from: string;
	to: string;
}
interface IAllData {
	value: string;
	streamUnit: string;
	streamName: string;
}

interface IChartControl {
	newRange?: { startDate: Date; endDate: Date };
	chartType?: string;
	firstItemSelected?: ISelectedDeviceAndStreams;
	secondItemSelected?: ISelectedDeviceAndStreams;
	getLoadingData?(loading: boolean): void;
	setChartsOptions?(
		id: string,
		chartType: string,
		options: IReactECharts["option"] | undefined,
		firstItem: ISelectedDeviceAndStreams,
		secondItem: ISelectedDeviceAndStreams,
		range: { startDate: Date; endDate: Date }
	): void;
	chartID: string;
	addOrEdit?: boolean;
	colors: string[];
}

const ChartControl = ({
	setChartsOptions,
	newRange,
	chartType,
	firstItemSelected,
	secondItemSelected,
	getLoadingData,
	chartID,
	addOrEdit,
	colors
}: IChartControl) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const { showError } = useErrorMessage();

	var rangeDate = {
		startDate: addDays(new Date(), -7),
		// startDate: addDays(new Date(), -200),
		endDate: new Date(),
	};

	const selectedRange = newRange ? newRange : rangeDate //se for editar o gráfico, envio-lhe o range que estava antes tbm!

	//Devolve as streams e os valores dos devices
	const {
		data: firstDeviceData,
		loading: firstDeviceLoading,
		error: firstDeviceDataError,
		refetch: firstDeviceDataRefetch,
	} = useGet(
		`${ServiceApiUrl.adapterConfigurators}/${firstItemSelected?.device?.adapter_configurators_id}/values`,
		null,
		{ manual: true }
	);
	const {
		data: secondDeviceData,
		loading: secondDeviceLoading,
		error: secondDeviceDataError,
		refetch: secondDeviceDataRefetch,
	} = useGet(
		`${ServiceApiUrl.adapterConfigurators}/${secondItemSelected?.device?.adapter_configurators_id}/values`,
		null,
		{ manual: true }
	);

	useEffect(() => {
		const errorsFirstDevice =
			firstDeviceDataError ||
			(firstDeviceData && firstDeviceData.status && firstDeviceData.status !== 200 && firstDeviceData.status !== 0); //a estrutura desta resposta é variavel
		const errorsSecondDevice =
			secondDeviceDataError ||
			(secondDeviceData && secondDeviceData.status && secondDeviceData.status !== 200 && secondDeviceData.status !== 0); //a estrutura desta resposta é variavel
		// mostrar feedback quando se cria ou edita um grafico
		if (addOrEdit && errorsFirstDevice && errorsSecondDevice) {
			showError(firstDeviceDataError || errorsSecondDevice);
		}
	}, [
		addOrEdit,
		firstDeviceDataError,
		secondDeviceDataError,
		firstDeviceData?.status,
		secondDeviceData?.status,
	]);

	useEffect(() => {
		if (getLoadingData) {
			getLoadingData(firstDeviceLoading || secondDeviceLoading);
		}
	}, [firstDeviceLoading || secondDeviceLoading]);

	//para que ao entrar no componente, não carregue 2x o que está nos useEffect (por causa do StrictMode), e não gerar gráficos duplicados.
	const effectRan = useRef(false);

	useEffect(() => {
		if (firstItemSelected?.device && secondItemSelected?.device) {
			//se for a primeira vez, entra
			// if (effectRan.current === false) {
			callData(); //faz os pedidos dos values dos devices
			return () => {
				effectRan.current = true; //coloca o effectRan a true e da segunda vez já não entra no if
			};
			// }
		}
	}, [firstItemSelected || secondItemSelected]);

	//quando tiver os valores, pode criar o gráfico
	useEffect(() => {
		//se o componente já tiver sido carregado 2x (por causa do StrictMode) já pode entrar neste if
		if (
			firstDeviceData &&
			secondDeviceData
			// && effectRan.current === true
		) {
			createChartOption(); //entra na função que gera as opções e devolve para o comparativeAnalysis
		}
	}, [firstDeviceData && secondDeviceData]);

	const debounceRequests = debounce((cb) => {
		cb();
	}, 0);
	//fazer o pedido dos values
	const callData = () => {
		if (firstItemSelected?.device && secondItemSelected?.device) {
			const from = format(
				startOfDay(new Date(selectedRange?.startDate)),
				t("calendar.dateTimeFormat")
			);
			const to = format(
				endOfDay(new Date(selectedRange?.endDate)),
				t("calendar.dateTimeFormat")
			);

			const _params: IDeviceValuesFilterParams = {
				external_id: firstItemSelected?.device.external_id,
				from,
				to,
			};
			const _params2: IDeviceValuesFilterParams = {
				external_id: secondItemSelected?.device.external_id,
				from,
				to,
			};

			debounceRequests(() => {
				firstDeviceDataRefetch({ params: { ..._params } });
				secondDeviceDataRefetch({ params: { ..._params2 } });
			});
		}
	};

	const isSmartParking = (device: any) => {
		return (
			device?.adapter_domain === "mobility" &&
			device?.adapter_partner?.name === "SmartParkingFunchal"
		);
	};

	const createChartOption = () => {
		// var mapXAxisValues: XAXisComponentOption | XAXisComponentOption[] = [] ;
		var mapXAxisValues: any = [];
		var mapYAxisValues: YAXisComponentOption | YAXisComponentOption[] = [];
		var seriesArray: any = [];
		var allStreamsDatesAndValues: Array<{
			date: string;
			[streamValue: string]: string;
		}> = []; //vai armazenar todos as datas e os respetivos valores comuns
		const valuesObjectsFirstDevice = firstDeviceData?.object.some(
			(value: any) => typeof value == "object"
		);
		const valuesObjectsSecondDevice = secondDeviceData?.object.some(
			(value: any) => typeof value == "object"
		);

		//se existirem valores do primeiro device
		if (
			firstDeviceData.object &&
			!arrayIsEmpty(firstDeviceData.object) &&
			valuesObjectsFirstDevice &&
			firstItemSelected?.streams &&
			firstItemSelected.streams.length > 0
		) {
			firstItemSelected?.streams?.forEach(
				(stream: TStream, streamsIndex: number) => {
					//percorre as streams selecionadas desse device

					if (streamsIndex === 0) {
						//se for a primeira stream, coloca a data
						allStreamsDatesAndValues = firstDeviceData.object.map(
							(element: any) => {
								return {
									date: formatDate(element.collect_date, t("calendar.dateTimeFormatLocal")),
									[stream.name + "0"]: element[stream.name as keyof any],
								};
							}
						);
					} else {
						//se já não for o primeiro, já tem a data, acrescenta apenas o valor
						allStreamsDatesAndValues = allStreamsDatesAndValues.map(
							(
								element: { date: string;[streamValue: string]: string },
								index: number
							) => {
								return {
									...element,
									[stream.name + "0"]:
										firstDeviceData.object[index][stream.name as keyof any],
								};
							}
						);
					}
				}
			);
		}
		//se existirem valores do segundo device
		if (
			secondDeviceData.object &&
			!arrayIsEmpty(secondDeviceData.object) &&
			valuesObjectsSecondDevice &&
			secondItemSelected?.streams &&
			secondItemSelected.streams.length > 0
		) {
			secondItemSelected?.streams?.forEach(
				(stream: TStream, streamsIndex: number) => {
					//percorre as streams selecionadas desse device

					if (!firstDeviceData || arrayIsEmpty(firstDeviceData.object)) {
						//se nao existirem valores para o primeiro device, cria o allStreamsDatesAndValues do 0
						if (streamsIndex === 0) {
							//se for a primeira stream, coloca a data
							allStreamsDatesAndValues = secondDeviceData.object.map(
								(element: any) => {
									return {
										date: formatDate(element.collect_date, t("calendar.dateTimeFormatLocal")),
										[stream.name + "-1"]: element[stream.name as keyof any],
									};
								}
							);
						} else {
							allStreamsDatesAndValues = allStreamsDatesAndValues.map(
								(
									element: { date: string;[streamValue: string]: string },
									index: number
								) => {
									return {
										...element,
										[stream.name + "-1"]:
											secondDeviceData.object[index][stream.name as keyof any],
									};
								}
							);
						}
					} else {
						secondDeviceData.object.forEach((valueAndDate: any) => {
							//se o array allStreamsDatesAndValues ja tiver a data do elemento, adiciona só o valor, se não, adiciona tudo
							let date = formatDate(valueAndDate.collect_date, t("calendar.dateTimeFormatLocal"))
							if (
								!allStreamsDatesAndValues.some(
									(e: { date: string;[streamValue: string]: string }) => {
										return e.date === date;
									}
								)
							) {
								//nao existe data igual, acrescenta um novo objeto!!
								allStreamsDatesAndValues = [
									...allStreamsDatesAndValues,
									{
										date: date,
										[stream.name + "-1"]:
											valueAndDate[stream.name as keyof any],
									},
								];
							} else {
								//existe a data, acrescenta só o valor
								const indexWithDate = allStreamsDatesAndValues.findIndex(
									(o: { date: string;[streamValue: string]: string }) => {
										return o.date === date;
									}
								);
								if (allStreamsDatesAndValues[indexWithDate]) {
									allStreamsDatesAndValues[indexWithDate][stream.name + "-1"] =
										valueAndDate[stream.name as keyof any];
								}
							}
						});
					}
				}
			);
		}

		if (
			allStreamsDatesAndValues &&
			allStreamsDatesAndValues.length > 0 &&
			(!objectHasEmptyArrays(secondDeviceData.object) ||
				!objectHasEmptyArrays(firstDeviceData.object)) &&
			firstItemSelected &&
			secondItemSelected
		) {
			//ordena por data
			allStreamsDatesAndValues.sort(function (
				x: { date: string;[streamValue: string]: string },
				y: { date: string;[streamValue: string]: string }
			) {
				return new Date(x.date).valueOf() - new Date(y.date).valueOf();
			});
			//vai buscar as datas para colocar no eixo x
			mapXAxisValues = allStreamsDatesAndValues.map(
				(e: { [streamValue: string]: string; date: string }) => {
					return e.date as XAXisComponentOption;
				}
			);
			//separar a data de cada stream em arrays
			var streamSeries: Array<{
				unit: string;
				name: string;
				data: Array<string | null>;
				device: string;
				deviceKey: number;
			}> = [];
			var itemsSelected: any;

			if (objectHasEmptyArrays(firstDeviceData.object)) {
				itemsSelected = secondItemSelected?.streams.map((e: TStream) => {
					return {
						...e,
						device: secondItemSelected?.device?.name,
						deviceKey: "-1",
					};
				});
			} else if (objectHasEmptyArrays(secondDeviceData.object)) {
				itemsSelected = firstItemSelected.streams.map((e: TStream) => {
					return {
						...e,
						device: firstItemSelected?.device?.name,
						deviceKey: "0",
					};
				});
			} else {
				if (firstItemSelected.streams)
					itemsSelected = firstItemSelected?.streams
						.map((e: TStream) => {
							return {
								...e,
								device: firstItemSelected?.device?.name,
								deviceKey: "0",
							};
						})
						.concat(
							secondItemSelected.streams.map((e: TStream) => {
								return {
									...e,
									device: secondItemSelected?.device?.name,
									deviceKey: "-1",
								};
							})
						);
			}

			itemsSelected?.forEach((element: any) => {

				//vai buscar os valores de cada stream
				var streamValues = allStreamsDatesAndValues.map(
					(e: { date: string;[streamValue: string]: string }) =>
						e[element.name + element.deviceKey] !== undefined
							? e[element.name + element.deviceKey]
							: null
				);
				//organiza os valores para colocar nas séries
				streamSeries = [
					...streamSeries,
					{
						unit: element.unit,
						name: element.name,
						data: streamValues,
						device: element.device,
						deviceKey: Math.abs(element.deviceKey)
					},
				];
			});

			streamSeries.forEach(
				(element: {
					unit: string;
					name: string;
					data: Array<string | null>;
					device: string;
					deviceKey: number;
				}, index) => {
					seriesArray = [
						...seriesArray,
						{
							name: element.device ? element.device : "",
							yAxisIndex:
								element.unit ===
									(!objectHasEmptyArrays(firstDeviceData.object)
										? firstItemSelected?.streams[0].unit
										: secondItemSelected?.streams[0].unit)
									? 0
									: 1,
							data: element.data.map((e) => ({
								value: e,
								streamUnit: element.unit,
								streamName: t(`streams.${element.name.toLowerCase()}`),
							})),
							type: chartType,
							color: colors[element.deviceKey],
							// symbolSize: 3,
							lineStyle: { width: 1 },
							showAllSymbol: true,
							symbol: "circle",
							showSymbol: isSmartParking(
								firstItemSelected?.device || secondItemSelected?.device
							)
								? true
								: false,
							step: isSmartParking(
								firstItemSelected?.device || secondItemSelected?.device
							)
								? "end"
								: "",
							areaStyle: isSmartParking(
								firstItemSelected?.device || secondItemSelected?.device
							)
								? {}
								: null,
						},
					];
				}
			);

			//eixos
			var allData = [].concat.apply(
				[],
				seriesArray.map((e: { data: IAllData[] }) => {
					return e.data;
				})
			);
			var allValues = allData.map((e: IAllData) => {
				return e.value;
			});

			//se as series só tiver 1 objeto ou a posição do eixo for igual(significa que tem a mesma unidade) coloca os valores comuns aos dois eixos
			var justOneAxis =
				seriesArray.length < 2 ||
				seriesArray.every(
					(el: { yAxisIndex: number }) =>
						el.yAxisIndex === seriesArray[0].yAxisIndex
				); //verifica se todos os elementos no seriesArray tem o mesmo yAxisIndex || se apenas existe um elemento, nesse caso usa apenas um eixo
			var firstAxisValues = justOneAxis
				? allValues
				: [].concat
					.apply(
						[],
						seriesArray
							.filter((e: { yAxisIndex: number }) => e.yAxisIndex === 0)
							.map((e: { data: IAllData[] }) => {
								return e.data;
							})
					)
					.map((e: any) => {
						return e.value;
					});
			var secondAxisValues = justOneAxis
				? allValues
				: [].concat
					.apply(
						[],
						seriesArray
							.filter((e: { yAxisIndex: number }) => e.yAxisIndex === 1)
							.map((e: { data: IAllData[] }) => {
								return e.data;
							})
					)
					.map((e: any) => {
						return e.value;
					});
			//se o primeiro device tiver valores
			if (!objectHasEmptyArrays(firstDeviceData.object)) {
				mapYAxisValues = [
					{
						name: firstItemSelected?.streams[0].unit
							? `${firstItemSelected?.streams[0].unit}`
							: "",
						position: "left",

						type: "value",
						nameLocation: "end",
						nameTextStyle: {
							padding: [0, 30, 0, 0],
						},
						axisLabel: {
							formatter: (val: any) => formatNumber(val) as string
						},
						splitLine: {
							lineStyle: {
								color: "#F0F0F0",
							},
						},
						axisLine: {
							show: false,
							lineStyle: {
								color: justOneAxis ?
									theme.palette.mode === "light" ? "#333" : theme.palette.common.white
									: theme.palette.primary.main,
							},
						},
						min:
							isSmartParking(
								firstItemSelected?.device || secondItemSelected?.device
							) ||
								(chartType === "bar" && _.min(firstAxisValues) > 0)
								? 0
								: _.floor(_.min(firstAxisValues)),
						max: isSmartParking(
							firstItemSelected?.device || secondItemSelected?.device
						)
							? 1.2
							: _.ceil(_.max(firstAxisValues) * 1.02),
					},
				];
			}
			var YAxisAux: YAXisComponentOption | YAXisComponentOption[];

			//se o primeiro device não tiver valores
			if (
				objectHasEmptyArrays(firstDeviceData.object) ||
				(!objectHasEmptyArrays(firstDeviceData.object) &&
					!objectHasEmptyArrays(secondDeviceData.object) &&
					firstItemSelected?.streams[0].unit !==
					secondItemSelected?.streams[0].unit)
			) {
				YAxisAux = {
					name: secondItemSelected?.streams[0].unit
						? `${secondItemSelected?.streams[0].unit}`
						: "",
					position: objectHasEmptyArrays(firstDeviceData.object)
						? "left"
						: "right",
					nameLocation: "end",
					nameTextStyle: {
						padding: [0, 0, 0, 30],
					},
					type: "value",
					// axisLabel: {
					//   formatter: `{value}`,
					// },
					axisLabel: {
						formatter: (val: any) => formatNumber(val) as string
					},
					axisLine: {
						show: false,
						lineStyle: {
							color: justOneAxis
								? theme.palette.mode === "light" ? "#F0F0F0" : theme.palette.common.white
								: secondItemSelected?.device?.name ===
									firstItemSelected?.device?.name
									? theme.palette.primary.main
									: theme.palette.secondary.main,
						},
					},
					min:
						isSmartParking(
							firstItemSelected?.device || secondItemSelected?.device
						) ||
							(chartType === "bar" && _.min(secondAxisValues) > 0)
							? 0
							: _.floor(_.min(secondAxisValues)),
					max: isSmartParking(
						firstItemSelected?.device || secondItemSelected?.device
					)
						? 1.2
						: _.ceil(_.max(secondAxisValues) * 1.02),
				};
				mapYAxisValues = [...mapYAxisValues, YAxisAux];
			}
		}
		if (
			(!firstDeviceData ||
				objectHasEmptyArrays(firstDeviceData.object) ||
				!valuesObjectsFirstDevice) &&
			(!secondDeviceData ||
				objectHasEmptyArrays(secondDeviceData.object) ||
				!valuesObjectsSecondDevice)
		) {
			if (
				firstItemSelected &&
				secondItemSelected &&
				firstItemSelected.device &&
				secondItemSelected.device &&
				setChartsOptions
			) {
				setChartsOptions(
					chartID,
					chartType ?? "",
					undefined,
					firstItemSelected,
					secondItemSelected,
					selectedRange
				);
			}
		} else {
			// #1186
			// var chartLegend: string[] = [];
			// if (firstItemSelected?.device && secondItemSelected?.device) {
			// 	if (!firstDeviceData || objectHasEmptyArrays(firstDeviceData.object)) {
			// 		chartLegend = [secondItemSelected?.device?.name];
			// 	} else if (
			// 		!secondDeviceData ||
			// 		objectHasEmptyArrays(secondDeviceData.object)
			// 	) {
			// 		chartLegend = [firstItemSelected?.device?.name];
			// 	} else {
			// 		chartLegend = [
			// 			firstItemSelected?.device?.name,
			// 			secondItemSelected?.device?.name,
			// 		];
			// 	}
			// }

			if (
				defaultOptions &&
				defaultOptions?.xAxis &&
				!objectIsEmpty(mapXAxisValues) &&
				firstItemSelected?.device &&
				secondItemSelected?.device
			) {
				const options: IReactECharts["option"] = {
					...defaultOptions,
					xAxis: {
						...defaultOptions?.xAxis,
						...{ data: mapXAxisValues },
						...{ boundaryGap: chartType === "bar" ? true : false },
						...{
							axisLine: {
								show: false,
								lineStyle: {
									color: theme.palette.mode === "light" ? "#333" : theme.palette.common.white
								}
							}
						},
					},
					yAxis: mapYAxisValues,
					series: seriesArray,
					dataZoom: [
						{
							type: "inside",
							start: 0,
							end: 100,
						},
						{
							start: 0,
							end: 10,
						},
					],
					tooltip: {
						...defaultOptions?.tooltip,
						backgroundColor: theme.palette.background.paper,
						textStyle: {
							color: theme.palette.mode === "light" ? "" : theme.palette.common.white,
						},
						...{
							formatter: isSmartParking(
								firstItemSelected?.device || secondItemSelected?.device
							)
								? function (params: any) {
									let showOnTooltip = "";
									showOnTooltip += `${params[0].axisValueLabel} <br>`;

									params.forEach((param: any) => {
										showOnTooltip += `<br> ${param.marker} ${param.seriesName}
                      <b>${param.data.value !== null
												? t(
													"typesParking.park_occupied" +
													"." +
													param.data.value
												)
												: "--"
											}</b>
                      `;
									});
									return showOnTooltip;
								}
								: function (params: any) {
									let showOnTooltip = "";
									showOnTooltip += `${params[0].axisValueLabel} <br>`;
									params.forEach((param: any) => {
										showOnTooltip += `<br> ${param.marker}  ${param.data.streamName
											}
                  <b>: ${param.value !== null
												? `${formatNumber(param.value)} ${param.data.streamUnit}`
												: "null"
											}</b> `;
									});
									return showOnTooltip;
								},
						},
					},
					legend: { show: false }
					// #1186
					// legend: {
					//   ...defaultOptions.legend,
					//   ...{ data: chartLegend, bottom: 55 },
					//   ...{
					//     textStyle: {
					//       color: theme.palette.text.primary,
					//       fontFamily: theme.typography.fontFamily,
					//       fontSize: "12px",
					//     },
					//   },
					// },
				};

				if (
					firstItemSelected?.device &&
					secondItemSelected?.device &&
					setChartsOptions
				) {
					setChartsOptions(
						chartID,
						chartType ?? "",
						options,
						firstItemSelected,
						secondItemSelected,
						selectedRange
					);
				}
			}
		}
	};

	return <></>;
};

export default ChartControl;
