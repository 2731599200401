import { SvgIcon, SvgIconTypeMap, Typography, useTheme } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { IModuleCardItem } from 'components/modules/ModuleCards'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { getWholeAndDecimal } from 'utils/number';
import { QArtBoxContainerStyled, QArtBoxStyled, QArtTypographyStyled } from './QArtCardStyle';
import { moduleCardsIcons } from 'components/modules/cards/moduleCardsIcons';
import { IQArtCardItem } from './QArtCardChart';

export interface IQArtCard {
	item: IQArtCardItem
}

function QArtGlobalCard({ item }: IQArtCard) {
	const { t, i18n } = useTranslation();
	const theme = useTheme();

	const getIcon = (name: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & { muiName: string; }, color: string) => {
		return <SvgIcon
			sx={{ width: '80px', height: '80px', color: color }}
			component={name}
			inheritViewBox
		/>
	}

	function showItemValueNumber(item: IModuleCardItem) {
		if (!item.value) return "";

		const [whole, dec] = getWholeAndDecimal(Number(item.value));

		return (
			<span>
				{whole},
				<span className='valueDec'>{dec}</span>
			</span>
		)
	}

	function showTranslationName(name: string) {
		return i18n.exists(`qart.pollutantStreams.${name}`)
			? t(`qart.pollutantStreams.${name}`)
			: name;
	}

	return (
		<QArtBoxContainerStyled>
			{getIcon(moduleCardsIcons[item.name], item.color?.main || theme.palette.greyColor.main)}
			<QArtBoxStyled>
				<div>
					<Typography variant="caption" fontSize={14}>
						{showTranslationName(item.name)}
					</Typography>
				</div>
				<QArtTypographyStyled>
					{showItemValueNumber(item)}
				</QArtTypographyStyled>
			</QArtBoxStyled>
		</QArtBoxContainerStyled>
	)
}

export default QArtGlobalCard
