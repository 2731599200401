import { IMediaFile } from "@alb/live-lib";

export function formatDataAddForm(payLoad: any) {
  const result = { ...payLoad };

  result.geolocation = JSON.parse(result.geolocation);

  if (result.assignee?.assignee_id && result.assignee?.assignee_type) {
    result.assignee_id = result.assignee?.assignee_id;
    result.assignee_type = result.assignee?.assignee_type;
  }

  if (result.media.length > 0) {
    const mediaList: IMediaFile[] = result.media;
    const urls = mediaList.map((item) => item.url);
    result.media = {
      operation: "add",
      objects: urls,
    };
  } else {
    delete result.media;
  }

  const props = [
    "address",
    "resolution_days",
    "reporter",
    "assignee",
    "status",
    "status_date",
  ];
  props.forEach((element: string) => {
    if (result.hasOwnProperty(element)) {
      delete result[element];
    }
  });

  return result;
}

export function formatDataEditForm(
  payLoad: any,
  listDeletedMedia: IMediaFile[]
) {
  const result = { ...payLoad };

  result.geolocation = {
    iconPosition: JSON.parse(result.geolocation),
  };

  if (result.assignee?.assignee_id && result.assignee?.assignee_type) {
    result.assignee_id = result.assignee?.assignee_id;
    result.assignee_type = result.assignee?.assignee_type;
  }

  if (result.media.length > 0) {
    const mediaList: IMediaFile[] = result.media;
    const urlsBase64 = mediaList
      .filter((item) => item.url.startsWith("data:"))
      .map((item) => item.url);
    const idsDeleted = listDeletedMedia.map((item) => item.id);
    result.media = [
      {
        operation: "add",
        objects: urlsBase64,
      },
      {
        operation: "remove",
        objects: idsDeleted,
      },
    ];
  } else {
    delete result.media;
  }

  const props = [
    "address",
    "resolution_days",
    "reporter",
    "assignee",
    "status_date",
  ];
  props.forEach((element: string) => {
    if (result.hasOwnProperty(element)) {
      delete result[element];
    }
  });

  return result;
}
