/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "@alb/live-lib";
interface IRemoveChart {
  open: boolean;
  handlerClose(): void;
  handleRemoveChart(): void;
}

const RemoveChart = ({
  open,
  handlerClose,
  handleRemoveChart,
}: IRemoveChart) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog
              open={open}
              type="error"
              title={t("comparativeAnalysis.removeChart")}
              message= {t("comparativeAnalysis.chartRemoveConfirmation")}
              actionConfirmText={t("common.delete")}
              actionCancelText={t("common.cancel")}
              onCancel={handlerClose}
              onConfirm={handleRemoveChart}
              loading={false}
    />
  );
};
export default RemoveChart;
