import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { ChipStatus } from "components/Utils/ChipStatus";
import { TAdapterConfigurator } from "types/types";
import { statusColor } from "utils/utils";
import { formatDate } from "utils/date";

const AdaptersTableRow = (props: {
  row: TAdapterConfigurator;
  onRowClick: (row: TAdapterConfigurator) => void;
}) => {
  const { row, onRowClick } = props;
  const { t } = useTranslation();

  return (
    <>
      <TableRow
        sx={{ cursor: "pointer" }}
        onClick={(e): void => {
          e.stopPropagation();
          onRowClick(row);
        }}
      >
        <TableCell>{row.name}</TableCell>
        <TableCell>
          <ChipStatus
            label={row.is_active ? t("common.active") : t("common.inactive")}
            color={
              row.is_active ? statusColor("active") : statusColor("non-active")
            }
          />
        </TableCell>
        <TableCell>{row.domain}</TableCell>

        <TableCell>
          {formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
        </TableCell>
      </TableRow>
    </>
  );
};

export default AdaptersTableRow;
