import { Box, CircularProgress, Typography } from "@mui/material";

interface ICircularProgressBikes {
  valueProgress: number;
  valueLabel: number | string;
  color: string;
}

export const CircularProgressBikes = ({
  valueProgress,
  valueLabel,
  color,
}: ICircularProgressBikes) => {
  return (
    <>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={valueProgress}
          sx={{ color: color }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" component="div">
            {valueLabel}
          </Typography>
        </Box>
      </Box>
    </>
  );
};
