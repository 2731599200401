import { Grid, Typography } from "@mui/material";
import { ITipology, IisoCategory } from "./IsoView";
import { arrayIsEmpty } from "utils/utils";
import IsoTypologyAccordion from "./IsoTypologyAccordion";


const IsoTypology = (props: IisoCategory) => {


    const name = props.name;
    const data = props.data;


    

    let details;
    if (!arrayIsEmpty(data)) {
        details = data.map((i: ITipology, index: number) => (
            <IsoTypologyAccordion
                key={index}
                description={i.description} 
                value={i.value}
                code={i.code}
                denominator_description={i.denominator_description}
                denominator_value={i.denominator_value}
                numerator_description={i.numerator_description}
                numerator_value={i.numerator_value}
                source={i.source}
                unit={i.unit}
                year={i.year}
            />
        ))
    }

    return(
        <>
        <Grid mt={4}>
            <Typography variant="h6" mb={1}>{name}</Typography>
            {details}
        </Grid>
        </>
    )
}

export default IsoTypology;