/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ChartWidget, { IChartInfo } from "components/charts/ChartWidget";
import useGet from "hooks/fetchData/useGet";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { PERMISSIONS } from "utils/permissions/permissions";

const StreetLightingWidget = () => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.MODULES.EXECUTIVE.READ);
  const user = useSelector(getUser);

  const {
    data: streetLighting,
    loading,
    error
  } = useGet(ServiceApiUrl.energyIP);

  //transform streetLighting data
  let streetLightingData = streetLighting?.map((e: any) => {
    const year = new Date(e.aggregated_collect_date).toLocaleString(
      user?.language,
      { year: "numeric" }
    );
    const month = new Date(e.aggregated_collect_date).toLocaleString(
      user?.language,
      { month: "long" }
    );

    const label = month + " " + year
    
    return {
      name: label.charAt(0).toUpperCase() + label.slice(1),
      value: Number(e.sum_active_energy / 1000).toFixed(0),
    };
  });

  //info to send to chartWidget to build chart
  const streetLightingInfo: IChartInfo = {
    title: t("executive.streetLighting"),
    description: t("executive.sum"),
    unit: "MWh",
    data: streetLightingData,
    legendName: t("executive.monthlyConsumption"),
    loading: loading,
    testId: "streetLightingChart",
    error: error,
    labelYearBreak: true,
  };

  return (
    <>
      {canREAD && (
        <ChartWidget {...streetLightingInfo} />
      )}
    </>
  );
};

export default StreetLightingWidget;
