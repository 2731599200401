// export default ClientsView;
import { BackToButton, EntityInfo, EntityInfoItem, LayoutContext } from "@alb/live-lib";
import EditIcon from "@mui/icons-material/Edit";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Button } from "@mui/material";
import { Box, Tab } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ISelectOption } from "types/interfaces";
import { TClient } from "types/types";

import AccountsListTab from "components/management/accounts/AccountsListTab";
import GatewaysListTab from "components/management/adapters/GatewaysListTab";
import ModulesListTab from "components/management/modules/ModulesListTab";
import UsersListTab from "components/management/users/UsersListTab";
import FilterSelect from "components/Utils/FilterSelect";
import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { TabPanelContainer } from "components/Utils/Tab/TabPanelContainer";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { ContainedTabs } from "styles/css/components";
import { formatDate } from "utils/date";
import { formatRoutePath } from "utils/routePath";

import ClientsEdit from "./ClientsEdit";

const ClientsView = () => {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { addHeader, addAction, action } = useContext(LayoutContext);
  const {
    data: client,
    loading,
    error,
    refetch,
  } = useGet(`${ServiceApiUrl.clients}/${params.clientsId}`);

  const [value, setValue] = React.useState("1");

  const handleCloseEdit = () => {
    addAction(false);
  };
  const handleEditSuccess = () => {
    handleCloseEdit();

    // update list
    refetch();
  };

  const handleSelectedClient = (option: ISelectOption | null) => {
    if (!option) return undefined;

    const path = formatRoutePath(location, params, {
      clientsId: option.value,
    });
    navigate(path);
  };

  const header = {
    title: client?.name ? client?.name : t("clients.detail"),
    backTo: (
      <BackToButton
        title={t("goBack")}
        onClick={() => navigate("/administration/clients")}
      />
    ),
    select: client && (
      <FilterSelect<TClient>
        apiUrl={ServiceApiUrl.clients}
        onSelected={handleSelectedClient}
        selectedValue={params?.clientsId}
      />
    ),
    action: client && (
      <Button
        variant="contained"
        disableElevation
        startIcon={<EditIcon />}
        onClick={() => addAction(true)}
      >
        {t("clients.editClient")}
      </Button>
    ),
  };

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client?.name]);

  let deviceView = <NoData error={error} />;
  let clientsModules = <NoData error={error} />;
  let clientsAccounts = <NoData error={error} />;
  let clientsGateways = <NoData error={error} />;
  let clientsUsers = <NoData error={error} />;

  if (loading) {
    deviceView = <Loading />;
    clientsModules = <Loading />;
    clientsAccounts = <Loading />;
    clientsGateways = <Loading />;
    clientsUsers = <Loading />;
  }

  if (client) {
    deviceView = (
      <EntityInfo title={t("common.details")}>
        <EntityInfoItem label={t("clients.name")} value={client.name} />

        <EntityInfoItem
          label={t("clients.status")}
          value={client.is_active ? t("common.active") : t("common.inactive")}
        />

        <EntityInfoItem
          label={t("common.createdAt")}
          value={formatDate(
            client.created_at,
            t("calendar.dateTimeFullFormat")
          )}
        />
        <EntityInfoItem
          label={t("common.updatedAt")}
          value={formatDate(
            client.updated_at,
            t("calendar.dateTimeFullFormat")
          )}
        />
      </EntityInfo>
    );

    clientsModules = <ModulesListTab filters={{ clientID: client.id }} />;
    clientsAccounts = <AccountsListTab filters={{ clientID: client.id }} />;
    clientsGateways = <GatewaysListTab filters={{ clientID: client.id }} />;
    clientsUsers = <UsersListTab filters={{ clientID: client.id }} />;
  }

  let deviceEdit;
  if (action) {
    deviceEdit = (
      <ClientsEdit
        open={action}
        data={client}
        onClose={handleCloseEdit}
        onSuccess={handleEditSuccess}
      />
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      {deviceView}
      {deviceEdit}

      {client && !loading &&
      <Box mt={2}>
        <TabContext value={value}>
          <ContainedTabs indicatorColor="secondary" onChange={handleChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            <Tab label={t("clients.listDetails.modulesList")} value="1" />
            <Tab label={t("clients.listDetails.usersList")} value="2" />
            <Tab label={t("clients.listDetails.adaptersList")} value="3" />
            <Tab label={t("clients.listDetails.accountsList")} value="4" />
          </ContainedTabs>

          <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
            <TabPanelContainer>{clientsModules}</TabPanelContainer>
          </TabPanel>
          <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="2">
            <TabPanelContainer>{clientsUsers}</TabPanelContainer>
          </TabPanel>
          <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="3">
            <TabPanelContainer>{clientsGateways}</TabPanelContainer>
          </TabPanel>
          <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="4">
            <TabPanelContainer>{clientsAccounts}</TabPanelContainer>
          </TabPanel>
        </TabContext>
      </Box>
      }
    </>
  );
};

export default ClientsView;
