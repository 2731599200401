/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";
import { TModule } from "types/types";
import useDelete from "hooks/fetchData/useDelete";
export interface IModulesDelete {
  module: TModule;
  clientID: string;
  open: boolean;
  handlerClose: () => void;
  updateList: () => void;
}

const ModulesDelete = ({
  module,
  clientID,
  open,
  handlerClose,
  updateList,
}: IModulesDelete) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();

  const {
    loading,
    refetch: deleteModule,
  } = useDelete( ServiceApiUrl.modules,
    module?.id)

  //sucesso
  const handlerSuccess = () => {
    handlerClose();
    addFeedback({
      message: t("modules.moduleDeleted"),
      severity: "success",
    });
    updateList();
  };

  // eliminar módulo
  const handleDeleteModule = async () => {
    const payLoad = {"modules": [module.id]}
    try {
      await deleteModule({ data: payLoad });
      await handlerSuccess();
    } catch {}
  };

  return (<>
  <ConfirmDialog
              open={open}
              type="error"
              title={`${t("modules.deleteModule")} ${module.name}`}
              message={t("modules.moduleDeleteConfirmation")}
              actionConfirmText={t("common.delete")}
              actionCancelText={t("common.cancel")}
              loading={loading}
              onCancel={handlerClose}
              onConfirm={handleDeleteModule}
    />
  </>

  );
};

export default ModulesDelete;
