/* eslint-disable react-hooks/exhaustive-deps */
import { arrayIsEmpty, GenericTable, getColorTrafficWaze, IApiResponse, Loading, TableHeadCell, TGenericTableModel } from '@alb/live-lib';
import { Box, Chip, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { TWazeList } from 'types/types';

import NoData from 'components/Utils/NoData';
import { CustomTablePagination } from 'components/Utils/Pagination/CustomTablePagination';
import useGet from 'hooks/fetchData/useGet';
import { WazeAdapters } from 'pages/Waze';
import { ServiceApiUrl } from 'services/ServiceApiUrl';
import { IFilterDateRangeMap } from 'store/slices/mapSlice';
import { formatDate } from 'utils/date';

interface IWazeJamsList {
	filterSelectedRange: IFilterDateRangeMap;
	filterSearch: string | null
	selectedLevel: string | null
}

function WazeJamsList({ filterSelectedRange, filterSearch, selectedLevel }: IWazeJamsList) {
	const { t } = useTranslation();
	const [page, setPage] = useState(1);
	const maxPerPage = 10;
	const theme = useTheme()

	const { data, loading, error, refetch } = useGet<IApiResponse<TWazeList>>(ServiceApiUrl.wazeList(WazeAdapters.jams), getParams());

	function getParams() {
		var params: {
			page: number;
			items: number;
		} = {
			page: page,
			items: maxPerPage,
			...(filterSelectedRange && {
				from: filterSelectedRange.startDate,
				to: filterSelectedRange.endDate,
			}),
			...(filterSearch && { contains: filterSearch }),
			...(selectedLevel && { level: selectedLevel })
			//...(filterType && { type: filterType }),
		};
		return params;
	}

	const handleChangePage = (newPage: number) => {
		setPage(newPage + 1);
	};

	const pagination =
		data && !arrayIsEmpty(data?.data) ? (
			<Box ml={1}>
				<CustomTablePagination
					count={data?.totalCount}
					page={page}
					totalPages={data?.totalPages}
					onPageChange={handleChangePage}
					itemsPage={maxPerPage}
				/>
			</Box>
		) : undefined;

	const tableHeads: TableHeadCell[] = [
		{
			id: "level",
			label: t("waze.intensity"),
			notSortable: true
		},
		{
			id: "local",
			label: t("common.local"),
			notSortable: true

		},
		{
			id: "date",
			label: t("common.date"),
			notSortable: true
		},
	];

	const model: TGenericTableModel<TWazeList> = {
		columns: [
			{
				html: (row) => {
				return	(
					<>
						<Chip label={t("waze.jamsLevel." + row.level)} size='small' sx={{ background: getColorTrafficWaze(row.level, theme.palette.mode === 'dark') }} />
					</>
				)},
			},
			{
				html: (row) => (
					<>
						{row.local}
					</>
				),
			},
			{
				html: (row) => (
					<>
						{formatDate(row.date, t("calendar.dateTimeFormatLocal"))}
					</>
				),
			},
		],
		headSort: {
			headCells: tableHeads,
		},
	};

	useEffect(() => {
		setPage(1)
		refetch()
	}, [selectedLevel, filterSelectedRange, filterSearch])

	return (
		<>
			<Loading show={loading} />
			{!loading && (arrayIsEmpty(data?.data) || error) && (
				<NoData error={error} />
			)}
			{!loading && !error && data && !arrayIsEmpty(data?.data) && (
				<>
					<GenericTable
						model={model}
						items={data}
						pagination={pagination}
						stripedTable
					/>
				</>
			)}
		</>
	);
}

export default WazeJamsList
