import CircleIcon from "@mui/icons-material/Circle";
import { List, ListItem, ListItemText } from '@mui/material'
import React from 'react'
import { getPollutantClassification, palettePollutantColors } from "./QArtColorsLimits";
import { useTranslation } from "react-i18next";

function QArtMapStreamsLegend() {
	const { t, i18n } = useTranslation();

	function translationIfExist(key: string) {
		return i18n.exists(key)
			? t(key)
			: key;
	}
	const pollutantClassification = getPollutantClassification();
	const legend: { label: string; color: string; }[] = [];
	pollutantClassification.forEach(({ color, classification }) => {
		legend.push({ label: translationIfExist(`qart.pollutantClassification.${classification}`), color: color.main })
	})
	legend.push({ label: t('qart.pollutantClassification.withoutLimit'), color: palettePollutantColors.outOfLimit.main });

	return (
		<List dense={true} sx={{ width: '100%', bgcolor: 'background.paper' }}>
			{legend.map((legend, idx) => {
				const labelId = `legend-list-label-${idx}`;

				return (
					<ListItem
						key={idx}
						disablePadding
					>
						<CircleIcon
							fontSize="small"
							sx={{ color: legend.color }}
						/>
						<ListItemText id={labelId} secondary={legend.label} sx={{ paddingLeft: 1 }} />
					</ListItem>
				);
			})}
		</List>
	)
}

export default QArtMapStreamsLegend
