import { InputText, IRecoverPasswordFormInput, RequiredField, RequiredFieldsText, useFeedback } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import axios from "axios";
import { t } from "i18next";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import useErrorMessage from "hooks/useErrorMessage";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { emailRegex } from "utils/regex";

import recoverBG from "../assets/images/live-urban/recover_password.svg";

interface IRecoverPassword{
    email: string
}



const ForgotPassword = () => {

    const emailRules = {
        email: yup
          .string()
          .email(t("passwordRecovery.emailRule"))
          .matches(emailRegex, t("passwordRecovery.emailRule"))
          .required(t("passwordRecovery.emailRequired")),
      };

    const schemaRecoverPassword = yup.object().shape(emailRules).required();

    const navigate = useNavigate()
    const [showMsg, setShowMsg] = useState<boolean>(false);
    const { addFeedback } = useFeedback()
    const { showError } = useErrorMessage()

    const defaultValues = {
        email: ''
    }

    const methods = useForm<IRecoverPassword>({
        defaultValues: defaultValues,
        mode: "onTouched",
        resolver: yupResolver(schemaRecoverPassword),
        reValidateMode: "onChange",
      });

    const { control } = methods;

    function applyDataRecoverPwd() {
        setShowMsg(true)
        addFeedback({
          message: t("feedback.success.successForgotPassword"),
          severity: "success",
        });
      }

    async function onSubmitDialog(data: IRecoverPasswordFormInput) {
        //setIsLoading(true);
        await axios
          .post("api" + ServiceApiUrl.recoverPassword, data)
          .then((res) => {
            applyDataRecoverPwd();
            //setIsLoading(false);
          }).catch((error) => {
            showError(error);
          })
      }

    const navigateToLogin = () => {
        navigate("/login")
    }

    return(
        <>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100vh"
            }}>
            <Grid container component="main" sx={{
                    height: "100%",
                    backgroundImage: `url(${recoverBG})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "right",
                    }}>
                <Grid item sm={2}></Grid>
                <Grid item sm={8}>
                    <Container sx={{ paddingTop: 8, flex: " 1" }} maxWidth={"md"}>
                        <Box maxWidth={"sm"}>
                            <FormProvider {...methods}>
                                <form onSubmit={methods.handleSubmit(onSubmitDialog)}>
                                    <Typography variant="h4">
                                        {t("passwordRecovery.title")}
                                    </Typography>

                                    {!showMsg && (
                                        <Typography mt={1.5} variant="subtitle1">
                                        {t("loginForm.messageForgotPassword")}
                                        </Typography>
                                    )}

                                    {!showMsg && (
                                        <>
                                        <Grid
                                            container
                                            mt={9}>
                                            <Grid item xs={12}>
                                            <RequiredField title={t("recoverPassword.emailLabel")} />
                                            <InputText
                                                name="email"
                                                placeholder={t("recoverPassword.emailPlaceholder")}
                                                control={control}
                                                type="email"
                                                />
                                            </Grid>
                                            <RequiredFieldsText text={t("requiredField")} />
                                        </Grid>

                                        <Stack
                                        mt={4}
                                        direction="row"
                                        justifyContent="flex-start"
                                        spacing={1}>
                                        <Button
                                            color="primary"
                                            type="button"
                                            variant="outlined"
                                            startIcon={<ArrowBackRoundedIcon />}
                                            onClick={navigateToLogin}
                                            disabled={methods.formState.isSubmitting}
                                            >
                                            {t("goBack")}
                                            </Button>


                                            <Button
                                            color="primary"
                                            variant="contained"
                                            type="submit"
                                            disabled={!methods.formState.isValid}
                                            >
                                            {t("common.send")}
                                            </Button>
                                        </Stack>
                                        </>
                                    )}
                                    {showMsg && (
                                        <>
                                        <Typography variant="subtitle1" mt={2}>
                                            {t("loginForm.forgotPasswordMsg")}
                                        </Typography>

                                        <Stack
                                            mt={4}
                                            direction="row"
                                            justifyContent="flex-start"
                                        >
                                            <Button
                                            color="secondary"
                                            type="button"
                                            variant="outlined"
                                            startIcon={<ArrowBackRoundedIcon />}
                                            onClick={navigateToLogin}
                                            disabled={methods.formState.isSubmitting}
                                            >
                                            {t("goBack")}
                                            </Button>
                                        </Stack>
                                        </>
                                    )}
                                </form>
                            </FormProvider>
                        </Box>
                    </Container>
                </Grid>

                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={6}

                />
            </Grid>
            <Grid container>
                <Grid item sm={1} />
                <Grid item sm={10} sx={{padding: "30px"}}>
                    <Typography variant='subtitle2' mt={1}>
                        {t("footer.copy", { year: new Date().getFullYear() })}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
        </>
    )
}

export default ForgotPassword;
