/* eslint-disable react-hooks/exhaustive-deps */
import { Loading, MiniSelect } from "@alb/live-lib";
import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IBugaDashboard, IBugaNumBikesChart, IDataLineChart, IDeviceAdapterDetail, ISelectOption } from "types/interfaces";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { objectIsEmpty } from "utils/utils";

import { BugaBikesStationChart } from "./charts/BugaBikesStationChart";
import { BugaBikeVariationChart } from "./charts/BugaBikeVariationChart";
import { BugaMapStation } from "./maps/BugaMapStation";

export const BugaStationsDashboard = ({
  adapterSelect,
  selectedDateRange,
}: IBugaDashboard) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<TOption[]>([]);
  const [selectedStation, setSelectedStation] = useState<string>(
    options[0]?.value || ""
  );
  const [variationChart, setVariationChart] = useState<IDataLineChart[]>([]);
  const [totalBikesChart, setTotalBikesChart] = useState<IBugaNumBikesChart[]>([]);

  // get all stations
  const {
    data: allStations,
    loading: loadingStations,
    error: errorStations,
  } = useGet(ServiceApiUrl.adapterConfiguratorDevices(adapterSelect.id), null);

  const selectedValueStation = () => {
    return (
      options?.find(
        (option: ISelectOption) => option.value === selectedStation
      ) || ""
    );
  };

  const onSelectedStation = (option: ISelectOption) => {
    setSelectedStation(option.value);
  };

  // get information for charts of a station
  const {
    data: dataCharts,
    loading: loadingCharts,
    error: errorCharts,
    refetch: refetchCharts,
  } = useGet(
    ServiceApiUrl.bugaStationCharts(selectedStation),
    paramsRequest(),
    { manual: true }
  );

  function paramsRequest() {
    const params = {
      from: selectedDateRange?.startDate,
      to: selectedDateRange?.endDate,
    };
    return params;
  }

  function cleanDataCharts() {
    setVariationChart([]);
    setTotalBikesChart([]);
  }

  useEffect(() => {
    if (allStations?.devices.length > 0) {
      const stations = allStations.devices.map((elem: IDeviceAdapterDetail) => {
        return {
          label: elem.name,
          value: elem.external_id,
        };
      });
      setOptions(stations);
      if (selectedStation === "") {
        setSelectedStation(stations[0].value);
      }
    }
  }, [allStations]);

  useEffect(() => {
    if (selectedStation !== "") {
      refetchCharts();
    }
  }, [selectedStation, selectedDateRange]);

  useEffect(() => {
    if(!objectIsEmpty(dataCharts)) {
      setVariationChart(dataCharts.bike_variation || []);
      setTotalBikesChart(dataCharts.total_max_min_bikes || []);
    }
  }, [dataCharts])

  useEffect(() => {
    if(errorCharts) {
      cleanDataCharts();
    }
  }, [errorCharts])

  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h3" fontSize={"22px"} mb={1}>
            {t("buga.stationsCard.title")}
          </Typography>
          <Loading show={loadingStations} />
          {!loadingStations && errorStations && (
            <NoData error={errorStations} />
          )}
          {selectedStation !== "" &&
            !loadingStations &&
            !errorStations && (
              <>
                <MiniSelect
                  showLoading={loadingStations}
                  showLabel
                  showBorder
                  options={options}
                  showSearchBar={allStations?.devices.length > 5}
                  noDataText={t("common.noData")}
                  value={selectedValueStation()}
                  onChangeOption={onSelectedStation}
                />
                <Grid container marginY={3}>
                  <Grid item xs={6}>
                    <BugaBikeVariationChart
                      data={variationChart}
                      loading={loadingCharts}
                      error={errorCharts}
                    />
                  </Grid>
                  <Grid item container xs={6}>
                    <Grid item xs={12}>
                      <BugaMapStation idStation={selectedStation} />
                    </Grid>
                    <Grid item xs={12}>
                      <BugaBikesStationChart
                        dataChart={totalBikesChart}
                        loading={loadingCharts}
                        error={errorCharts}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
        </CardContent>
      </Card>
    </>
  );
};
