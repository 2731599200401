import { useEffect, useState } from 'react'
import { DrawerContainer } from 'components/ui/DrawerContainer'
import { Box, Grid, Typography, DrawerProps } from '@mui/material'
import { ListButtonsContainer } from './ListButtonsContainer'
import { MarkersList } from './MarkersList'
import { useTranslation } from 'react-i18next'
import { FilterButton, IMapPoint } from '@alb/live-lib'
import { Stack } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFiltersTabOpen,
  setFiltersTabOpen,
  getSelectedMarkerType,
  selectOpenList,
  toggleList,
  getActiveModule,
  getSelectedMapType,
  DataModuleMap,
} from 'store/slices/mapSlice'
import usePermission from 'hooks/usePermission'
import { PERMISSIONS } from 'utils/permissions/permissions'
import useGetFiltersCount from '../controls/FilterMapTab/hooks/useGetFiltersCount'

import styles from 'styles/modules/map/list.module.scss'
import { MapDetailContainer } from "../detail/MapDetailContainer";
import { getUser } from "store/slices/authSlice";

interface ImapListContainer {
  items: IMapPoint[];
  onHover: (item: IMapPoint | null) => void;
  itemDetails: (item: IMapPoint) => void;
  hideInfoBtn?: boolean;
  typeUpload?: string;
  children?: React.ReactNode;
}

export const MapListContainer = ({
  items,
  onHover,
  itemDetails,
  hideInfoBtn,
  typeUpload,
  children,
}: ImapListContainer) => {
  const dispatch = useDispatch()
  const filterTabOpen = useSelector(selectFiltersTabOpen)
  const selectedType = useSelector(getSelectedMarkerType)
  const user = useSelector(getUser);
  const selectedMapType = useSelector(getSelectedMapType);

  const { t } = useTranslation()

  const onChangeType = (typeFilter: string) => {
    if (typeFilter === DataModuleMap.visits) {
      return "detail";
    } else {
      return "list";
    }
  };
  const filterMenuMarkers = useSelector(getActiveModule);
  const [type, setType] = useState<"detail" | "list">(
    onChangeType(filterMenuMarkers)
  );

  const filtersCount = useGetFiltersCount()

  const { hasPermission } = usePermission()
  const canReadEvents = hasPermission(PERMISSIONS.MODULES.EVENTS.READ)
  const canReadOccurrences = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.READ)
  const idAdminOrSuperAdmin = user?.is_admin || user?.is_superadmin;
  const isDrawerOpen = useSelector(selectOpenList);
  const title_aux =
    selectedType === 'all'
      ? t('map.list.title')
      : t(`map.filter.types.${selectedType}`)
  let title: string = ''
  if (!idAdminOrSuperAdmin) {
    if ((canReadEvents && canReadOccurrences)) {
      title = title_aux
    } else {
      title = t("map.filter.types.devices")
    }
  } else {
    title = title_aux
  }
  const args: DrawerProps = {
    anchor: 'right',
    open: isDrawerOpen,
    variant: 'persistent',
  };

  useEffect(() => {
    setType(onChangeType(filterMenuMarkers));
    if (onChangeType(filterMenuMarkers) === "detail" && !filterTabOpen) {
      dispatch(toggleList(true));
    }
    // eslint-disable-next-line
  }, [filterMenuMarkers]);

  return (
    <>
      <ListButtonsContainer
        open={isDrawerOpen}
        type={type}
      />
      <DrawerContainer {...args}>
        <Box
          sx={{
            width: 450,
            px: 1,
            overflow: 'hidden',
            height:'100vh',
          }}
        >
          {type === "detail" && (
            <Box className={styles["list-ajustableContent"]}> 
              <Box>
               {hideInfoBtn && children}
              </Box>
                {(selectedMapType === "heat" ||
                (typeUpload !== "simple_upload" &&
                  selectedMapType === "choropleth")) && (
                 <MapDetailContainer hideInfoBtn={hideInfoBtn} />
                 )}
                 
            </Box>
          )}
          {type === "list" && (
            <>
              <Stack
                className={[
                  styles["filter-button"],
                  items.length > 0
                    ? styles["filter-button-results"]
                    : styles["filter-button-noResults"],
                ].join(" ")}
              >
                <FilterButton
                  activeFiltersCount={filtersCount}
                  onClick={() => {
                    dispatch(setFiltersTabOpen(!filterTabOpen));
                  }}
                  filterTitle={t("common.filters")}
                />
              </Stack>

              <Grid
                container
                className={[
                  styles["list-title"],
                  items.length > 0
                    ? styles["list-title-block"]
                    : styles["list-title-flex"],
                ].join(" ")}
              >
                <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
                  {title}
                </Typography>
                {items.length > 0 && (
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      color="text.secondary"
                      sx={{
                        fontSize: "18px",
                        fontWeight: 700,
                        lineHeight: "0.8",
                      }}
                    >
                      {items.length}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      sx={{
                        fontSize: "18px",
                        lineHeight: "0.8",
                        paddingLeft: 1,
                      }}
                    >
                      {t("map.list.subTitle", { count: items.length })}
                    </Typography>
                  </Box>
                )}
              </Grid>
              {items.length > 0 ? (
                <MarkersList
                  items={items}
                  onClick={itemDetails}
                  onHover={onHover}
                />
              ) : (
                <Typography
                  color="text.secondary"
                  mt={2}
                  ml={2}
                  sx={{ fontSize: "16px", fontWeight: 400 }}
                >
                  {t("map.list.noElements")}
                </Typography>
              )}
            </>
          )}
        </Box>
      </DrawerContainer>
    </>
  );
};
