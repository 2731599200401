import * as Yup from "yup";

import enShort from "../utils/yup-locales/src/locales/en/localeShort";
import ptShort from "../utils/yup-locales/src/locales/pt/localeShort";

export default function yupSetLocale(locale: string = 'en') {
  switch (locale.substring(0,2)) {
    case "en":
      Yup.setLocale(enShort);
      break;
    case "pt":
      Yup.setLocale(ptShort);
      break;
    default:
      Yup.setLocale(enShort);
      break;
  }
}
