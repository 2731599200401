import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import {
  AppLogo,
  ChipStatus,
  Loading,
  ToggleViewButton,
  TToggle,
} from '@alb/live-lib'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'

import { formatDate } from 'utils/date'

import { useTranslation } from 'react-i18next'
import { useState } from 'react'

import { ServiceApiUrl } from 'services/ServiceApiUrl'
import useGet from 'hooks/fetchData/useGet'

import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'store/slices/authSlice'

import EditProfile from '../edit-profile/editProfile'
import { setTheme } from 'store/slices/appManagementSlice'
import useUpdate from 'hooks/fetchData/useUpdate'
import { setUser } from 'store/slices/authSlice'

const PersonalData = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const themeMode = useSelector(getUser)?.extra_params?.dark_mode
  const extraParamsUSer = useSelector(getUser)?.extra_params
  const [mode, setMode] = useState<string>(themeMode ? 'dark' :'light')
  const toggleConfig: TToggle[] = [
    {
      value: 'light',
      icon: (
        <LightModeIcon titleAccess={t('theme.lightMode')} fontSize="small" />
      ),
    },
    {
      value: 'dark',
      icon: <DarkModeIcon titleAccess={t('theme.darkMode')} fontSize="small" />,
    },
  ]
  const handleTheme = (
    event: React.MouseEvent<HTMLElement>,
    nemMode: string | null
  ) => {
    if (nemMode !== null) {
      const defaultValues = {
        extra_params: {
          ...extraParamsUSer,
           dark_mode: nemMode === 'dark'
        }
      }
      setMode(nemMode)
      dispatch(setTheme(nemMode))
      updateUser({ data: defaultValues }).then((res) => {
        const response = res.data;
        dispatch(setUser(response))
      })
    }
  }

  // fetch personal data values
  const idUser = useSelector(getUser).id
  const {
    data: dataUser,
    loading,
    error,
    refetch,
  } = useGet(`${ServiceApiUrl.user}/${idUser}`)

    // update personal data
    const {
      // loading,
      // error,
      refetch: updateUser,
    } = useUpdate(ServiceApiUrl.user, idUser)

  // update personal data values
  const updateUsers = async () => {
    await refetch()
  }

  // open or close edit profile dialog
  const [openDialogEditProfile, setOpenDialogEditProfile] =
    useState<boolean>(false)
  const handlerDialogEditProfileState = () => {
    setOpenDialogEditProfile(!openDialogEditProfile)
  }

  return (
    <>
      <Card sx={{ minHeight: '315px' }}>
        {loading && !error && <Loading show={true} />}
        {dataUser && !loading && (
          <>
            <CardHeader
              title={t('user.personalData')}
              action={
                <Button
                  sx={{ mb: 2 }}
                  onClick={() => setOpenDialogEditProfile(true)}
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={<EditIcon />}
                >
                  {t('edit')}
                </Button>
              }
            />
            <CardContent>
              <Grid container>
                <Grid item xs={3}>
                  <AppLogo
                    src={dataUser.avatar}
                    alt={dataUser.first_name}
                    variant="rounded"
                    sx={{ width: '100%', height: '100%' }}
                  />
                </Grid>
                <Grid container item xs={9}>
                  <Grid item xs={12} sx={{ display: 'flex' }}>
                    <Typography color="text.black" variant="h6" pr={1}>
                      {dataUser.first_name + ' ' + dataUser.last_name}
                    </Typography>
                    <ChipStatus
                      label={t(`status.${dataUser.status}`)}
                      status={dataUser.status}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography color="text.primary" variant="body1">
                      {dataUser.username}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    mt={1}
                    xs={12}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <MailOutlineIcon color="disabled" />
                    <Typography color="text.primary" variant="body1" pl={1}>
                      {dataUser.email}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mt={1}
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    <span
                      className={`fi fi-rounded fi-sise-md fis fi-${
                        dataUser.language.toLowerCase() === 'pt' ? 'pt' : 'gb'
                      }`}
                    />
                    <Typography color="text.primary" variant="body1" pl={1}>
                      {t(dataUser.language.toLowerCase())}
                    </Typography>
                  </Grid>
                  <Grid item mt={{ sm: 3, xs: 1 }} xs={12}>
                    <Typography variant="body1">
                      <strong>{t('user.lastLogin')}: </strong>
                      {formatDate(
                        dataUser.last_login,
                        t('calendar.dateTimeFullFormat')
                      )}
                    </Typography>
                  </Grid>
                  <Grid item mt={{ sm: 3, xs: 1 }} xs={12}>
                    <Typography variant="body1">
                      <strong>{t('theme.chooseTheme')} </strong>
                    </Typography>
                    <ToggleViewButton
                      display={mode}
                      toggle={toggleConfig}
                      onChange={handleTheme}
                      sx={{
                        height: '35px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </>
        )}
      </Card>
      {dataUser && (
        <EditProfile
          user={dataUser}
          open={openDialogEditProfile}
          handlerClose={handlerDialogEditProfileState}
          updateGetUsers={updateUsers}
        />
      )}
    </>
  )
}

export default PersonalData
