import { arrayIsEmpty, LayoutContext, useFeedback } from "@alb/live-lib";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

import OpenDataCSVTable from "components/open-data/OpenDataCSVTable";
import OpenDataXLSTable from "components/open-data/OpenDataXLSTable";
import NoData from "components/Utils/NoData";
import { countCharAppearences } from "utils/utils";

const OpenData = () => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const { addHeader } = useContext(LayoutContext);
  const [doc, setDoc] = useState();
  const [fileType, setFileType] = useState<string>();
  const [uploadedFile, setUploadedFile] = useState<string>();
  const error = null; // não existem pedidos nesta pagina
  let fileReader: any;

  const clearArrays = () => {
    setXlsTableContent([]);
    setCSVTableContent([]);
  };

  const handleFileChosen = (e: ChangeEvent<HTMLInputElement>) => {
    let file: FileList | null = e.target.files;
    if (file && file.length > 0) {
      fileReader = new FileReader();

      if (fileInputRef?.current.files[0]?.name === uploadedFile) {
        addFeedback({
          message: t("feedback.warning.fileAlreadySubmitted", {
            file: fileInputRef?.current.files[0]?.name,
          }),
          severity: "warning",
        });
        fileInputRef.current.value = "";
        fileInputRef.current.files = null;
        return;
      }
      if (file[0].type === "text/csv") {
        setFileType("csv");
        fileReader.onloadend = handleCSVFileRead;
        fileReader.readAsText(file[0]);
        addFeedback({
          message: t("feedback.success.uploadFile"),
          severity: "success",
        });
      } else if (file[0].type === "application/vnd.ms-excel") {
        setFileType("xls");
        fileReader.onloadend = handleXLSFileRead;
        fileReader.readAsArrayBuffer(file[0]);
        addFeedback({
          message: t("feedback.success.uploadFile"),
          severity: "success",
        });
      } else {
        addFeedback({
          message: t("feedback.error.uploadWrongFileOpenData"),
          severity: "error",
        });
        return;
      }
    }
    setDoc(fileReader);
    setUploadedFile(fileInputRef?.current.files[0]?.name);
    fileInputRef.current.value = "";
    fileInputRef.current.files = null;
  };

  const [tableHeaders, setTableHeaders] = useState<any[]>([]);
  const [csvTableContent, setCSVTableContent] = useState<any[]>([]);
  const [xlsTableContent, setXlsTableContent] = useState<any[]>([]);

  const handleXLSFileRead = (e: any) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, {
      type: "array",
      cellStyles: true,
      cellHTML: true,
    });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];

    const cellsArray: any = [];

    for (const cellAddress in worksheet) {
      if (!cellAddress.startsWith("!")) {
        const cell = worksheet[cellAddress];
        const cellValue = cell.v;

        const cellStyle = cell.s;

        const { r } = XLSX.utils.decode_cell(cellAddress);

        if (!cellsArray[r]) {
          cellsArray[r] = [];
        }

        const cellObject = {
          value: cellValue,
          style: cellStyle,
        };

        cellsArray[r].push(cellObject);
      }
    }

    setXlsTableContent(cellsArray);
  };

  const handleCSVFileRead = () => {
    let data = fileReader.result;
    let rowData = data.split("\n");
    let delimiter;
    if (
      countCharAppearences(",", rowData[0]) >
      countCharAppearences(";", rowData[0])
    ) {
      delimiter = ",";
    } else {
      delimiter = ";";
    }

    let header = rowData[0].split(delimiter);

    let content: any[] = [];
    for (let index = 1; index < rowData.length; index++) {
      let r = rowData[index];
      content.push(r.split(delimiter));
    }

    setTableHeaders(header);
    setCSVTableContent(content);
  };

  const fileInputRef = useRef<any>(null);

  const header = {
    title: t("openData"),
    action: (
      <>
        <Button
          variant="contained"
          disableElevation
          startIcon={<Add />}
          onClick={() => {
            if (fileInputRef.current) fileInputRef.current.click();
          }}
        >
          {t("map.buttons.upload")}
          <input
            ref={fileInputRef}
            hidden
            accept=".csv, .xls"
            type="file"
            onChange={handleFileChosen}
          />
        </Button>
      </>
    ),
  };

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line
  }, [csvTableContent, xlsTableContent]);
  return (
    <>
      {arrayIsEmpty(csvTableContent) && arrayIsEmpty(xlsTableContent) && (
        <NoData error={error} />
      )}
      {fileType === "xls" && (
        <OpenDataXLSTable clearArrays={clearArrays} content={xlsTableContent} />
      )}
      {fileType === "csv" && (
        <OpenDataCSVTable
          header={tableHeaders}
          document={doc}
          content={csvTableContent}
          clearArrays={clearArrays}
          uploadedFile={uploadedFile}
        />
      )}
    </>
  );
};

export default OpenData;
