import { BackToButton, LayoutContext } from "@alb/live-lib";
import { Grid } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRoutePath } from "utils/routePath";
import { PERMISSIONS } from "utils/permissions/permissions";
import usePermission from "hooks/usePermission";
import { ModuleGridItem } from "components/Utils/ModuleGridItem";

const OccurrencesAdministration = () => {
  const { addHeader } = useContext(LayoutContext);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const { hasPermission } = usePermission();
  const canReadOccurrences = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.READ);

  const header = {
    title: t("occurrences.occurrences"),
    backTo: <BackToButton title={t("goBack")} onClick={() => navigate(-1)} />,
  };

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line
  }, []);

  function navigateTo(page: string) {
    const path = getRoutePath(location, params);
    navigate(`${path}/${page}`);
  }

  return (
    <>
      {canReadOccurrences && (
        <Grid container mt={5} spacing={2}>
					<ModuleGridItem title={t("occurrences.urgencies")} onClick={()=>navigateTo("urgencies")} />
					<ModuleGridItem title={t("occurrences.categories")} onClick={()=>navigateTo("categories")} />
					<ModuleGridItem title={t("occurrences.status")} onClick={()=>navigateTo("status")} />
        </Grid>
      )}
    </>
  );
};

export default OccurrencesAdministration;
