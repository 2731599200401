import { useFeedback } from "@alb/live-lib";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

const useErrorMessage = () => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();

  const showError = (error: AxiosError<any, any> | null) => {
    if (error?.code === "ERR_CANCELED") return;

    const codeError =
      (Array.isArray(error?.response?.data) &&
        error?.response?.data[0].error_code) ||
      (error?.response?.data && error?.response?.data.error_code) ||
      "somethingWentWrong";
    addFeedback({ message: t("errors." + codeError), severity: "error" });
  };

  const getCodeDescritionError = (
    error: AxiosError<any, any> | Event | null
  ) => {
    const errorAxios = error as AxiosError<any, any>;
    if (error?.hasOwnProperty("name") && errorAxios?.name === "AxiosError") {
      const codeError =
        (Array.isArray(errorAxios.response?.data) &&
          errorAxios.response?.data[0].error_code) ||
        (errorAxios.response?.data && errorAxios.response?.data.error_code) ||
        "somethingWentWrong";
      return {
        code: codeError,
        description: t("errors." + codeError),
      };
    } else if(error) {
      return {
        code: "somethingWentWrong",
        description: t("errors.somethingWentWrong"),
      };
    }
  };

  return {
    showError,
    getCodeDescritionError,
  };
};

export default useErrorMessage;
