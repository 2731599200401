import { TableRow, TableCell, IconButton } from "@mui/material";
import { TGroup, TUser } from "types/types";
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import { t } from "i18next";
import { useState } from "react";
import RemoveUserDialog from "./RemoveuserDialog";
import useDelete from "hooks/fetchData/useDelete";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useFeedback } from "@alb/live-lib";


const UsersTabRow = (props: { user: TUser, group: TGroup, refetchGroups: () => void}) => {

    const { user, group, refetchGroups } = props;

    const [ showRemoveUser, setShowRemoveUser ] = useState(false)
    const { addFeedback } = useFeedback();

    const onDeleteClick = () => {
        setShowRemoveUser(true)
    }

    const { refetch: removeUser } = useDelete(
        ServiceApiUrl.groups,
        group.id,
        undefined
    );

    const payload = {
        user: [user.id]
    }

    const handleRemoveUserSuccess = () => {
        addFeedback({
			message: t("group.userRemoved"),
			severity: "success",
		});
        setShowRemoveUser(false);
        refetchGroups();
    }

    const onDialogConfirm = () => {
        removeUser({data: payload}).then(() => {
            handleRemoveUserSuccess()
        })
    }

    const onDialogCancel = () => {
        setShowRemoveUser(false)
    }

    return(<>
        <TableRow>
            <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
            <TableCell>{`${user.email}`}</TableCell>
            <TableCell align={'right'} sx={{ whiteSpace: 'nowrap' }}>
                <IconButton
					title={t('common.delete')}
					aria-label="delete"
					onClick={(e) => {
                                e.stopPropagation()
								onDeleteClick();
							}}
				>
					<DeleteIcon />
				</IconButton>
            </TableCell>
        </TableRow>

        {showRemoveUser && <RemoveUserDialog onDialogCancel={onDialogCancel} onDialogConfirm={onDialogConfirm} />}
    </>)
}

export default UsersTabRow;
