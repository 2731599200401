import {
  ConfirmDialog,
  TableHeadCell,
  TableHeadSort,
  useFeedback,
} from '@alb/live-lib'
import { Table, TableBody, TableContainer } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

// import { useDelete } from "hooks/fetchData/useDelete";
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { TClient } from 'types/types'
import { IResultTable } from 'types/interfaces'
import { arrayIsEmpty } from 'utils/utils'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ClientTableRow from './ClientsTableRow'
import useDelete from 'hooks/fetchData/useDelete'
import ClientsEdit from './ClientsEdit'
import { formatRoutePath } from "utils/routePath";
import { CustomTablePagination } from 'components/Utils/Pagination/CustomTablePagination'

export const ClientsTable = (props: IResultTable) => {
  const { t } = useTranslation()
  const { addFeedback } = useFeedback()
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: clients,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    page,
    setPage,
    refetch,
  } = props

  const [selectedClient, setSelectedClient] = useState<any>()
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  const tableHeads: TableHeadCell[] = [
    {
      id: 'name',
      label: t('common.name'),
    },
    {
      id: 'is_active',
      label: t('common.statusLabel'),
    },
    {
      id: 'updated_at',
      label: t('common.updatedAt'),
    },
    {
      id: 'action_cell',
      label: '',
      notSortable: true,
    },
  ]

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  //Trocar página da listagem
  const handleChangePage = (
    newPage: number
  ) => {
    setPage(newPage + 1) //porque o index da paginação do mui inicia no 0
  }

  const handleEdit = (row: TClient) => {
    setSelectedClient(row)
    setOpenEdit(true)
  }
  const handleDelete = (row: TClient) => {
    setSelectedClient(row)
    setOpenDeleteConfirm(true)
  }

  const handleCloseDelete = () => {
    setOpenDeleteConfirm(false)
    setSelectedClient(undefined)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
    setSelectedClient(undefined)
  }
  const handleEditSuccess = () => {
    handleCloseEdit()

    // update list
    refetch && refetch()
  }

  const { loading, refetch: deleteClient } = useDelete(
    ServiceApiUrl.clients,
    selectedClient?.id
  )

  // delete sucesso
  const handlerDeleteSuccess = () => {
    handleCloseDelete()
    addFeedback({
      message: t('clients.clientDeleted'),
      severity: 'success',
    })

    // update list
    refetch && refetch()
  }

  const handleConfirmDelete = async () => {
    try {
      await deleteClient()
      await handlerDeleteSuccess()
    } catch {}
  }

  const handleRowClick = (row: TClient) => {
    const path = formatRoutePath(location, params, {
      id: row.id
    });
    navigate(path);
  }

  let row
  if (!arrayIsEmpty(clients.data)) {
    row = clients.data.map((client: TClient) => (
      <ClientTableRow
        key={client.id}
        row={client}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onRowClick={handleRowClick}
      />
    ))
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHeadSort
            headCells={tableHeads}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>{row}</TableBody>
        </Table>
      </TableContainer>

			<CustomTablePagination
				count={clients?.totalCount}
				page={page}
				totalPages={clients?.totalPages}
				onPageChange={handleChangePage}
			/>

      {selectedClient && (
        <>
          {openDeleteConfirm && (
            <ConfirmDialog
              type="error"
              open={openDeleteConfirm}
              title={t('clients.deleteClient')}
              message={t('clients.clientDeleteConfirmation')}
              actionConfirmText={t('common.delete')}
              actionCancelText={t('common.cancel')}
              loading={loading}
              onCancel={handleCloseDelete}
              onConfirm={handleConfirmDelete}
            />
          )}

          {openEdit && (
            <ClientsEdit
              open={openEdit}
              data={selectedClient}
              onClose={handleCloseEdit}
              onSuccess={handleEditSuccess}
            />
          )}
        </>
      )}
    </>
  )
}
