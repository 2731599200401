import useWSNotifications from "hooks/useWSNotifications";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchLastNotifications } from "store/slices/notificationsSlice";
import { INotificationAPI } from "types/interfaces";

export interface IWSNotificationsProps {
	notificationsCbk: ()=> void;
}
const WSNotifications = (props: IWSNotificationsProps) => {
	const { notificationsCbk } = props;

	const dispatch = useDispatch();

	const { lastMessage, readyState } = useWSNotifications();

	useEffect(() => {
		let data: INotificationAPI | null = null;
		try {
			data = JSON.parse(lastMessage?.data) as INotificationAPI;
			if (!data) return;
			if (data.type_message !== 'notifications') return
		} catch {
			return
		}
		notificationsCbk();
		dispatch(fetchLastNotifications());

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [readyState, lastMessage]);

	return null
}

export default WSNotifications;
