import { IRange, LayoutContext, ToggleViewButton, TToggle } from "@alb/live-lib";

import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";
import CardsTotals from "components/hydrogenSupply/CardsTotals";
import List from "components/hydrogenSupply/List";
import { Grid, Box } from "@mui/material";
import { FiltersSection } from "components/hydrogenSupply/FiltersSection";
import {
  FormatListBulleted
} from "@mui/icons-material";
import ShowChartIcon from '@mui/icons-material/ShowChart';
import Charts from "components/hydrogenSupply/Charts";

const HydrogenSupply = () => {
  const { t } = useTranslation();
  // ------ permissions
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.MODULES.HYDROGENSUPPLY.READ);

  // ------ header page
  const header = {
    title: t("hydrogenSupply.title"),
  };
  const { addHeader } = useContext(LayoutContext);

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line
  }, []);

  const [deviceFilter, setFilterDevice] = useState<string>("");
  const [filterDateRange, setFilterDateRange] = useState<IRange | undefined>();
  const [display, setDisplay] = useState("chart");

  const args = {
    deviceName: deviceFilter,
    filterDateRange: filterDateRange,
  };

  // extra components
  const toggleConfig: TToggle[] = [
    {
      value: "chart",
      icon: <ShowChartIcon fontSize="small" />,
    },
    {
      value: "list",
      icon: <FormatListBulleted fontSize="small" />,
    },
  ];

  // component handlers
  const handleDisplay = (
    event: React.MouseEvent<HTMLElement>,
    newDisplay: string | null
  ) => {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  };

  return (
    <>
      {canREAD && (
        <>
          <Grid item mt={4}>
            <FiltersSection
              setFilterDevice={setFilterDevice}
              setFilterDateRange={setFilterDateRange}
            />
          </Grid>
          {deviceFilter && (
            <>
              <CardsTotals {...args} />
              <Box sx={{ mt: 4 }}>
                <ToggleViewButton
                  display={display}
                  toggle={toggleConfig}
                  onChange={handleDisplay}
                  sx={{
                    height: "35px",
                    position: "absolute"
                  }}
                />

                {display === "list" && <List {...args} />}
                {display === "chart" && <Charts {...args} />}
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default HydrogenSupply;
