/* eslint-disable react-hooks/exhaustive-deps */
import { FilterSection, MiniSelect } from "@alb/live-lib";
import { Grid, Typography, useTheme } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { DoubleSelect } from "styles/css/components";
import { ISelectOption } from "types/interfaces";

export interface IDeviceInfo {
  name: string;
  domain: string;
  api: string;
  apiCards: string;
}

interface ICerManagementSelect {
  setDevice: Dispatch<SetStateAction<IDeviceInfo | undefined>>;
}

export const CERManagementSelect = ({ setDevice }: ICerManagementSelect) => {
  const { t } = useTranslation();
  const theme = useTheme();

  //Opção de devices estáticos, para já.
  const selectOptions = [
    {
      label: "Produção fotovoltaica",
      value: "Inversor Fotovoltaico Coimbra",
    },
    {
      label: "Armazenamento fotovoltaico",
      value: "Storage",
    },
  ];

  const [selected, setSelected] = useState<string>(
    selectOptions?.[0].value || ""
  );

  const deviceInfo: { [deviceName: string]: IDeviceInfo } = {
    Storage: {
      name: "Armazenamento",
      domain: "storage", //para ser possível ir buscar o adapter.
      api: ServiceApiUrl.storage,
      apiCards: ServiceApiUrl.storageCards,
    },
    "Inversor Fotovoltaico Coimbra": {
      name: "Inversor Fotovoltaico",
      domain: "inversor_fotovoltaico", //para ser possível ir buscar o adapter do device.
      api: ServiceApiUrl.inversor,
      apiCards: ServiceApiUrl.inversorCards,
    },
  };

  useEffect(() => {
    setDevice(deviceInfo[selected]);
  }, [selected]);

  function changeOption(e: ISelectOption) {
    setSelected(e.value);
  }
  const selectValue = () => {
    return (
      selectOptions?.find(
        (option: ISelectOption) => option.value === selected
      ) || ""
    );
  };

  return (
    <Grid container item xs={false} sm={12} md={12} mt={4}>
      <DoubleSelect>
        <Grid item xs={true}>
          <Typography mr={1} variant="h6">
            {t("dashboard.dataFrom")}
          </Typography>
        </Grid>
        <Grid item xs={true}>
          <FilterSection
            select={
              <MiniSelect
                onChangeOption={changeOption}
                showLabel
                showBorder
                sx={{
                  ...(theme.palette.mode === "light" && {
                    backgroundColor: "#ECF8F8",
                  }),
                }}
                borderColor="rgba(81,191,188,0.5)"
                options={selectOptions}
                value={selectValue()}
              />
            }
          />
        </Grid>
      </DoubleSelect>
    </Grid>
  );
};
