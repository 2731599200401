import { arrayIsEmpty, ConfirmDialog, GenericTable, GenericTableActions, InputText, Loading, TActions, TGenericTableModel, useFeedback } from "@alb/live-lib";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Collapse, Grid, InputAdornment, Typography } from "@mui/material";
import { debounce } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAnalysisDetail, IGeojsonFeaturesCollection } from "types/interfaces";

import NoData from "components/Utils/NoData";
import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import useDelete from "hooks/fetchData/useDelete";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getFilterDateRange, getGeojsonFilesUpload, getGeojsonType, getSelectedAnalysis, getSelectedMapType, setGeojsonType, setSelectedAnalysis, setSelectedAnalysisDetail } from "store/slices/mapSlice";
import { setAllChartsDetail } from "store/slices/peopleMobilitySlice";
import { ExpandButtonStyle } from "styles/css/components";
import styles from "styles/modules/map/list.module.scss";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

import AnalysisEdit from "./analysisAdd/AnalysisEdit";

interface IAnalysisTab {
	showSelectedAnalysis: (geojson: IGeojsonFeaturesCollection) => void;
	handleCleanFilters: (startDate: Date, endDate: Date, page: string) => void;
	setGeojsonCounty: React.Dispatch<
		React.SetStateAction<GeoJSON.FeatureCollection | null>
	>;
	setLoadingAnalysis?: React.Dispatch<React.SetStateAction<boolean>>,
	setLoadingAnalysisDetail?: React.Dispatch<React.SetStateAction<boolean>>
}

export type TAnalysis = {
	id: string;
	name: string;
};

export const AnalysisTab = ({
	showSelectedAnalysis,
	handleCleanFilters,
	setGeojsonCounty,
	setLoadingAnalysis,
	setLoadingAnalysisDetail
}: IAnalysisTab) => {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const dispatch = useDispatch();
	const maxAnalysisPerPage = 4;

	//TODO adicionar permissoes das analises do modulo movimento de pessoas
	const canUpdate = true;
	const canDelete = true;

	const filterDateRange = useSelector(getFilterDateRange);
	const multiFilesUpload = useSelector(getGeojsonFilesUpload);
	const selectedAnalysis = useSelector(getSelectedAnalysis);
	const selectedMapType = useSelector(getSelectedMapType);
	const geojsonType = useSelector(getGeojsonType);

	const [textSearch, setTextSearch] = useState<string>("");
	const [page, setPage] = useState(1);
	const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
	const [openEditDialog, setOpenEditDialog] = useState(false);
	const [selectedRow, setSelectedRow] = useState<TAnalysis | undefined>(
		undefined
	);
	const [selectedRowActions, setSelectedRowActions] = useState<
		TAnalysis | undefined
	>(undefined);

	const changeSelectedAnalysis = (item: TAnalysis) => {
		//se o id for o selecionado, limpa a seleção e dá reset ao mapa.
		const value = selectedAnalysis === item.id ? "" : item.id;
		setSelectedRow(!!value ? item : undefined);
		dispatch(setSelectedAnalysis(value));
		//dá "reset" ao mapa.
		if (!value) {
			setGeojsonCounty(null);
			handleCleanFilters(
				filterDateRange.startDate,
				filterDateRange.endDate,
				"module"
			);
			return;
		}
	};

	const { headers } = useGetModuleHeaders(MODULES_KEYS.PEOPLEMOBILITY);

	//pedido para listar analises
	const {
		data: analysis,
		loading,
		error: errorAnalysis,
		refetch: refetchAnalysis,
	} = useGet(ServiceApiUrl.analysis, GetParams(), undefined, headers);

	//pedido para listar analises
	const {
		refetch: refetchDetail,
		loading: loadingDetails,
	} = useGet<IAnalysisDetail[]>(
		`${ServiceApiUrl.analysis}/${selectedAnalysis}`,
		null,
		{ manual: true },
		headers
	);

	// delete analysis
	const { refetch: refetchDelete } = useDelete(
		`${ServiceApiUrl.analysis}/${selectedRowActions?.id}`,
		undefined,
		headers
	);

	useEffect(() => {
		setLoadingAnalysis && setLoadingAnalysis(loading)
	}, [loading])

	useEffect(() => {
		setLoadingAnalysisDetail && setLoadingAnalysisDetail(loadingDetails)
	}, [loadingDetails])

	//quando for feito novo upload deve esconder as analisses
	useEffect(() => {
		if (multiFilesUpload) {
			dispatch(setSelectedAnalysis("")); //clean selected analysis
		}
	}, [multiFilesUpload]);

	//quando a análise alterar, faz pedido dos detalhes para mostrar no mapa
	useEffect(() => {
		if (selectedAnalysis?.length > 0) {
			getAnalysisDetail();
			if (geojsonType !== "analysis") {
				dispatch(setGeojsonType("analysis"))
			}
		} else {
			setSelectedRow(undefined);
			dispatch(setSelectedAnalysisDetail(undefined));
			if (geojsonType === "analysis") {
				dispatch(setGeojsonType("parishes"))
			}
		}
	}, [selectedAnalysis]);

	async function getAnalysisDetail() {
		await refetchDetail().then((response) => {
			if (response.status === 200) {
				dispatch(setSelectedAnalysisDetail(response.data[0]));
				dispatch(setAllChartsDetail(response.data[0].charts));
				showSelectedAnalysis(response.data[0]?.geojson); // send to map
				if (!selectedRow && selectedAnalysis) {
					const analysisRow = {
						id: response.data[0].id,
						name: response.data[0].name,
					};
					setSelectedRow(analysisRow);
				}
			}
		});
	}

	function GetParams() {
		var params: {
			map_type: string;
			page?: number;
			items?: number;
			contains?: string;
		} = {
			map_type: selectedMapType as string,
			...(textSearch?.length > 0
				? { contains: textSearch }
				: { page: page, items: maxAnalysisPerPage }),
		};

		return params;
	}

	const debounceData = debounce((cb) => {
		cb();
	}, 500);

	useEffect(() => {
		if (selectedMapType) {
			if (page > 1) {
				setPage(1);
			}
			if (textSearch.length > 0) {
				setTextSearch("");
				resetField("search", {
					defaultValue: defaultValues.search,
				});
			}
		}
	}, [selectedMapType]);

	useEffect(() => {
		if (analysis?.totalCount > maxAnalysisPerPage && textSearch.length === 0) {
			setPage(1);
		}
	}, [textSearch]);

	//Trocar página da listagem
	const handleChangePage = (newPage: number) => {
		setPage(newPage + 1);
	};

	const handleSearch = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		debounceData(() => {
			setTextSearch(e.target.value);
		});
	};

	const defaultValues = {
		search: "",
	};
	const methods = useForm<{ search: string }>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: defaultValues,
	});

	const { control, watch, resetField } = methods;

	const [collapsed, setCollapsed] = useState<boolean>(true);
	function onHideDetail() {
		setCollapsed(!collapsed);
	}

	function handleEdit(row: TAnalysis) {
		setSelectedRowActions(row);
		setOpenEditDialog(true);
	}

	function clearAnalysis() {
		setGeojsonCounty(null);
		setSelectedRow(undefined);
		dispatch(setSelectedAnalysis(""));
		handleCleanFilters(
			filterDateRange.startDate,
			filterDateRange.endDate,
			"module"
		);
	}

	function handleDelete(row: TAnalysis) {
		setSelectedRowActions(row);
		setOpenDeleteConfirm(true);
	}
	const handleCloseDelete = () => {
		setOpenDeleteConfirm(false);
		setSelectedRowActions(undefined);
	};
	const handleConfirmDelete = async () => {
		try {
			await refetchDelete();
			handleCloseDelete();
			addFeedback({
				message: t("feedback.success.deleteAnalysis"),
				severity: "success",
			});
			clearAnalysis();
			if (page === 1) {
				refetchAnalysis();
			} else {
				setPage(1);
			}
		} catch { }
	};

	function clickRowTable(row: TAnalysis) {
		row && row?.id && changeSelectedAnalysis(row);
	}

	const actions: TActions<TAnalysis> = {
		editAction: canUpdate
			? { title: t("common.edit"), click: handleEdit }
			: undefined,
		deleteAction: canDelete
			? { title: t("common.delete"), click: handleDelete }
			: undefined,
	};

	const model: TGenericTableModel<TAnalysis> = {
		columns: [
			{ html: (row: TAnalysis) => <>{row.name}</> },
			{
				isActions: true,
				html: (row: TAnalysis) => (
					<GenericTableActions row={row} actions={actions} />
				),
			},
		],
		onRowClick: clickRowTable,
		selectedRow: selectedRow,
	};

	const pagination = !arrayIsEmpty(analysis?.data) ? (
		<Box ml={1}>
			<CustomTablePagination
				count={analysis?.totalCount}
				page={page}
				totalPages={analysis?.totalPages}
				onPageChange={handleChangePage}
				itemsPage={maxAnalysisPerPage}
			/>
		</Box>
	) : undefined;

	const handleEditClose = () => {
		setOpenEditDialog(false);
	};

	const handleEditSuccess = () => {
		handleEditClose();
		refetchAnalysis && refetchAnalysis();
	};

	return (
		<>
			<Grid container item className={styles["list-detail__title"]}>
				<Grid item xs={11}>
					<Typography variant="h3">{t("analysis.analysis")}</Typography>
				</Grid>
				<Grid item xs={1}>
					<ExpandButtonStyle onClick={onHideDetail}>
						{collapsed ? (
							<ExpandLess fontSize="inherit" />
						) : (
							<ExpandMore fontSize="inherit" />
						)}
					</ExpandButtonStyle>
				</Grid>
			</Grid>

			<Collapse
				in={collapsed}
				unmountOnExit
				sx={{ paddingLeft: "15px", marginRight: 3 }}
			>
				{(analysis?.totalCount > maxAnalysisPerPage ||
					textSearch.length > 0) && (
						<Grid item xs={12} mt={2}>
							<InputText
								placeholder={t("common.searchByName")}
								name="search"
								control={control}
								handleOnChange={handleSearch}
								activeField={watch("search")}
								endAdornment={
									<InputAdornment position="end">
										<SearchIcon />
									</InputAdornment>
								}
							/>
						</Grid>
					)}

				{arrayIsEmpty(analysis?.data) && !loading && (
					<Grid item xs={12} ml={"0.29rem"}>
						<NoData error={errorAnalysis} />
					</Grid>
				)}

				<Loading show={loading || loadingDetails} />
				{!loading && !loadingDetails && !arrayIsEmpty(analysis?.data) && (
					<>
						<GenericTable
							model={model}
							items={analysis}
							pagination={pagination}
							stripedTable
						/>
					</>
				)}
				{openDeleteConfirm && (
					<ConfirmDialog
						open={openDeleteConfirm}
						type="error"
						title={t("analysis.deleteAnalysis")}
						message={t("analysis.deleteAnalysisConfirmation")}
						actionConfirmText={t("common.delete")}
						actionCancelText={t("common.cancel")}
						loading={loading}
						onCancel={handleCloseDelete}
						onConfirm={handleConfirmDelete}
					/>
				)}
				{openEditDialog && selectedRowActions && (
					<AnalysisEdit
						data={selectedRowActions}
						open={openEditDialog}
						onClose={handleEditClose}
						onSuccess={handleEditSuccess}
					/>
				)}
			</Collapse>
		</>
	);
};
