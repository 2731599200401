/* eslint-disable react-hooks/exhaustive-deps */
import { IRange } from "@alb/live-lib";
import { useEffect } from "react";
import { IBugaSelectedAdapter } from "types/interfaces";

import { ModuleCards } from "components/modules/ModuleCards";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";

interface IBugaCards {
  adapterSelect: IBugaSelectedAdapter;
  filterDateRange: IRange | undefined;
}
export const BugaCards = ({ adapterSelect, filterDateRange }: IBugaCards) => {
  const {
    data,
    loading,
    error,
    refetch: refetchCards,
  } = useGet(ServiceApiUrl.bugaCrads(adapterSelect.keyURL), getParams(), {
    manual: true,
  });

  function getParams() {
    const params = {
      from: filterDateRange?.startDate,
      to: filterDateRange?.endDate,
    };
    return params;
  }

  useEffect(() => {
    if (adapterSelect.id) {
      refetchCards();
    }
  }, [adapterSelect.id, filterDateRange]);

  return (
    <>
      <ModuleCards
        module="buga"
        items={data}
        loading={loading}
        error={error}
        sx={{ mt: 3 }}
        dataTestId="buga"
      />
    </>
  );
};
