import { List, ListItem, ListItemText, Radio} from '@mui/material';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

export interface IQArtMapStreamsFilter {
	onChange: (pollutant:string) => void
}

function QArtMapStreamsFilter({onChange}: IQArtMapStreamsFilter) {
	const { t, i18n } = useTranslation();
	const [checked, setChecked] = useState('');

	const handleToggle = (value: string) => () => {
		setChecked(value);
		onChange(value);
	};

	function showTranslationName(name: string) {
		return i18n.exists(`qart.pollutantStreams.${name}`)
			? t(`qart.pollutantStreams.${name}`)
			: name;
	}

	const streams = [
		{ label: t('common.global'), value: '' },
		{ label: showTranslationName('o3'), value: 'o3' },
		{ label: showTranslationName('no2'), value: 'no2' },
		{ label: showTranslationName('particules_pm10'), value: 'particules_pm10' },
		{ label: showTranslationName('particules_pm25'), value: 'particules_pm25' }
	]

	return (
		<List dense={true} sx={{ width: '100%', bgcolor: 'background.paper' }}>
			{streams.map((stream, idx) => {
				const labelId = `radio-list-label-${stream.value}`;

				return (
					<ListItem
						key={stream.value}
						disablePadding
						onClick={handleToggle(stream.value)}
					>
						<Radio
							aria-label={labelId}
							checked={checked === stream.value}
							tabIndex={-1}
							disableRipple
							sx={{ padding: '0 9px' }}
							value={stream.value}
							name={`radio_${stream.value}`} />
						<ListItemText id={labelId} secondary={stream.label} />
					</ListItem>
				);
			})}
		</List >
	)
}

export default QArtMapStreamsFilter
