import { arrayIsEmpty, Loading } from "@alb/live-lib";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Grid, Popper, useTheme } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Controller } from 'react-hook-form';

import NoData from "components/Utils/NoData";

import ImageOption from "./ImageOption";

export interface categoryImage {

    name: string;
    url: string;

}

const ImagePicker = (props: {list: categoryImage[], control: any, name: string, defaultValue?: string, setValue: any, trigger: any }) => {

    const [ selectedImage, setSelectedImage ] = useState<any>("");
    const [ loading, setLoading ] = useState(true);
    const error = null; // é null porque neste caso os icones são 1 lista criada pelo FE, nao se vai buscar ao BE
    const theme= useTheme()

    const handleImageSelect = (image: {name: string, url: string}) =>{
        setSelectedImage(image);
        setIsOpen(false);
        props.setValue(props.name, image.name)
        props.trigger(props.name)
    }
    
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
        null
      );
    const [ isOpen, setIsOpen ] = useState<boolean>(false);

    const id = isOpen ? 'image-picker-popper' : undefined;

    const onButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(selectedImage) {
            setSelectedImage("");
            props.setValue(props.name, undefined)
            props.trigger()
        } else {
            setIsOpen(!isOpen)
            setAnchorEl(event.currentTarget)
        }
        
    }

    useEffect(() => {
        if(props.control._formValues.icon) {
            props.list.forEach(i => {
                if(i.name === props.control._formValues.icon) {
                    setSelectedImage(i)
                    return;
                }
                    
            })
        }        
        // eslint-disable-next-line
    }, [])

    let loaded = 0
    const handleImagedLoaded = () => {
        loaded++;
        if(loading && loaded >= props.list.length) {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(props.list.length === 0)
            setLoading(false);

            // eslint-disable-next-line
    }, [])

    const elementsList = props.list.map((item: {name: string, url: string}, index: number) => {
        return(<Grid item sm={4} key={index} sx={{
            textAlign: "center",
        }}>
            <ImageOption isLoading={loading}  name={item.name} url={item.url} onClick={handleImageSelect} imageLoaded={handleImagedLoaded} />
        </Grid>)
    })

    

   

    return(
        
            <>
                {selectedImage && 
                    <Box sx={{width: "50px"}}>
                        <img alt={selectedImage.name} src={selectedImage.url} />
                    </Box>
                }
                <Button 
                    onClick={onButtonClick}
                    variant="contained"
                    disableElevation
                    startIcon={
                        selectedImage ? <ClearIcon />  : <AddIcon />}>
                        {selectedImage ? t("occurrences.removeIcon") : t("occurrences.addIcon")
                    }
                </Button>
                    <Popper
                        id={id}
                        open={isOpen}
                        anchorEl={anchorEl}
                        placement="bottom-start"
                        sx={{ zIndex: 2000 }}
                    >
                        <Box sx={{
                            background: theme.palette.common.white,
                            padding: "10px",
                            textAlign: "center",
                            border: "1px solid lightgrey",
                            borderRadius: "10px",
                            marginTop: "5px",
                            width: "300px",
                            height: "200px",
                            overflow: "auto"
                            
                        }}>
                            <Grid container>
                            {loading && <Loading show={true} />}
                            {!arrayIsEmpty(elementsList) ? elementsList : <NoData error={error} /> }
                            </Grid>
                        </Box>
                        
                    </Popper>
                
                <Controller
                    name={props.name}
                    control={props.control}
                    defaultValue={props.defaultValue}
                    render={({ field, formState: { errors } }) => {

                        return (
                            <input {...field} type="hidden" ref={field.ref} value={field.value} onChange={field.onChange}/>)
                    }} />
            </>
    )
}

export default ImagePicker;