import ClientsView from "components/clients/ClientsView";

function ClientsViewPage() {
  return (
    <>
      <ClientsView />
    </>
  );
}

export default ClientsViewPage;