import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import { getSelectedModule } from "utils/modules/modules";

export const useGetModuleHeaders = (moduleKey: string) => {
  const user = useSelector(getUser);
  const moduleID = getSelectedModule(user, moduleKey);
  let headers = {};
  if (moduleID) {
    headers = {
      headers: {
        "MODULE-ID": moduleID,
      },
    };
  }

  return { headers };
};
