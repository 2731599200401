import { ref, object, string } from 'yup'

export const InputsLengths = {
  max: {
    name: 100,
    password: 20,
  },
  min: {
    name: 3,
    password: 5,
  },
}

export const getRecoverPassword = (passwordOneOfMessage: string) =>
  object({
    password: string()
      .min(InputsLengths.min.password)
      .max(InputsLengths.max.password)
      .required(),
    confirmPassword: string()
      .min(InputsLengths.min.password)
      .max(InputsLengths.max.password)
      .required()
      .oneOf([ref('password')], passwordOneOfMessage),
  })
