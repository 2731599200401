import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store";
import { IAllChartsDetail } from "types/interfaces";

interface IPeopleMobilityState {
  charts: IAllChartsDetail;
}

const initialState: IPeopleMobilityState = {
  charts: {
    overview: null,
    presence: [],
    visits_by_date: [],
    visits_by_date_range: {
      data: [],
      period: "total",
    },
  },
};

export const peopleMobilitySlice = createSlice({
  name: "peopleMobility",
  initialState,
  reducers: {
    setAllChartsDetail(state, action) {
      state.charts = action.payload;
    },
    setOverviewChart(state, action) {
      state.charts.overview = action.payload;
    },
    setPresenceChart(state, action) {
      state.charts.presence = action.payload;
    },
    setVisitsByDateChart(state, action) {
      state.charts.visits_by_date = action.payload;
    },
    setDataVisitsByDateRangeChart(state, action) {
      state.charts.visits_by_date_range.data = action.payload;
    },
    setPeriodVisitsDateRangeChart(state, action) {
      state.charts.visits_by_date_range.period = action.payload;
    }
  },
});

export const {
  setAllChartsDetail,
  setOverviewChart,
  setPresenceChart,
  setVisitsByDateChart,
  setDataVisitsByDateRangeChart,
  setPeriodVisitsDateRangeChart,
} = peopleMobilitySlice.actions;

export const getOverviewChart = (state: RootState) =>
  state.peopleMobility.charts.overview;
export const getPresenceChart = (state: RootState) =>
  state.peopleMobility.charts.presence;
export const getVisitsByDateChart = (state: RootState) =>
  state.peopleMobility.charts.visits_by_date;
export const getVisitsByDateRangeChart = (state: RootState) =>
  state.peopleMobility.charts.visits_by_date_range;

export default peopleMobilitySlice.reducer;
