import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { useEffect, useState } from "react";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IApiResponse, IOccurrenceStatus, ISelectOption } from "types/interfaces";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

export const useStatusOptions = () => {
  const { headers } = useGetModuleHeaders(MODULES_KEYS.EVENTS);

  const [statusOptions, setStatusOptions] = useState<ISelectOption[]>();

  const {
    data: allStatus,
    loading,
  } = useGet<IApiResponse<IOccurrenceStatus>>(ServiceApiUrl.eventsStatus, null, undefined, headers);

  useEffect(() => {
    if (allStatus) {
      const statusOptionsSelect = allStatus?.data?.map((p) => ({
        label: p.name,
        value: p.id,
      }));

      setStatusOptions(statusOptionsSelect);
    }
  }, [allStatus]);

  return { statusOptions, loading };
};
