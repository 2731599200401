import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { I18NEXT } from "../utils/keys";
import yupSetLocale from "./yupLocale";

i18n
  // Enables the i18next backend
  .use(Backend)
  // Enable automatic language detection
  .use(LanguageDetector)
  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
    returnNull: false,

    // Standard language used
    supportedLngs: ["en", "pt"],
    fallbackLng: "en",
    debug: true,

    // //Detects and caches a cookie from the language provided
    detection: {
      order: ['queryString', 'cookie', 'navigator'],
      caches:['cookie'],
			lookupCookie: I18NEXT,
    },
    interpolation: {
      escapeValue: false,
    },
  });

// change language for yup
i18n.on("languageChanged", yupSetLocale);

export default i18n;
