import { IRange } from "@alb/live-lib";
import { Grid } from "@mui/material";
import { useState } from "react";
import { IBugaSelectedAdapter } from "types/interfaces";

import { BugaBikesDashboard } from "./BugaBikesDashboard";
import { BugaFilters } from "./BugaFilters";
import { IBugaFiltersFieldsForm } from "./BugaFiltersForm";
import { BugaList } from "./BugaList";
import { BugaStationsDashboard } from "./BugaStationsDashboard";

interface IBugaAnalysisView {
  adapterSelect: IBugaSelectedAdapter;
  filterDateRange: IRange | undefined;
  setFilterDateRange: React.Dispatch<React.SetStateAction<IRange | undefined>>;
}

export const BugaAnalysisView = ({
  adapterSelect,
  filterDateRange,
  setFilterDateRange,
}: IBugaAnalysisView) => {
  const [display, setDisplay] = useState("dashboard");
  const [filterSearch, setFilterSearch] = useState<string>("");
  const [filtersPopup, setFiltersPopup] =
    useState<IBugaFiltersFieldsForm | null>(null);

  return (
    <>
      <Grid container marginTop={3}>
        <BugaFilters
          keyAdapter={adapterSelect.keyURL}
          display={display}
          setDisplay={setDisplay}
          filterDateRange={filterDateRange}
          setFilterDateRange={setFilterDateRange}
          filterSearch={filterSearch}
          setFilterSearch={setFilterSearch}
          dataFilterForm={filtersPopup}
          setDataFilterForm={setFiltersPopup}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12} marginY={3}>
          {display === "dashboard" && (
            <>
              {adapterSelect.domain === "mobilitybikes" && (
                <BugaBikesDashboard selectedDateRange={filterDateRange} />
              )}
              {adapterSelect.domain === "mobilitystations" && (
                <BugaStationsDashboard
                  adapterSelect={adapterSelect}
                  selectedDateRange={filterDateRange}
                />
              )}
            </>
          )}
          {display === "list" && (
            <BugaList
              selectedDateRange={filterDateRange}
              filterSearch={filterSearch}
              filtersPopup={filtersPopup}
              keyURL={adapterSelect.keyURL}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
