import React from 'react'
import { IGraphsInfo, IQArtCardItem, IQArtGraphItem } from './QArtCardChart'
import ChartWidget, { IChartInfo } from 'components/charts/ChartWidget'
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { IPollutantLimits, getColor, getLimit } from './QArtColorsLimits';
import { formatDate } from 'utils/date';

export interface IQArtChart {
	data: [IQArtGraphItem[]] | undefined,
	item: IQArtCardItem,
	legendName: string,
	interval: string
}

function addOrReplace(arr: IPollutantLimits[], newObj: IPollutantLimits) {
	return [...arr.filter((obj) => obj.classification !== newObj.classification), { ...newObj }];
}
function generateChartData(graphs: [IQArtGraphItem[]] | undefined, pollutant: string,
	t: TFunction<"translation", undefined>, interval: string) {
	let graphsInfo: IGraphsInfo = { data: [], limits: [], yMaxValue: 0 };

	let limits: IPollutantLimits[] = [];
	let yMaxValue = 0;

	graphs?.forEach((graph) => {
		const values = graph.filter(item => item.name === pollutant)[0];

		const value = values.value;
		const color = getColor(pollutant, value);
		const limit = getLimit(pollutant, value);
		if (value > yMaxValue) yMaxValue = value;

		if (limit) {
			limits = addOrReplace(limits, limit);
			if (limit.max > yMaxValue) yMaxValue = limit.max;
		}

		const name = interval === 'day' ? formatDate(values.collect_date, t("calendar.dateFormatSimple")) : new Date(values.collect_date).getHours().toString();
		const label = interval === 'day' ? `${formatDate(values.collect_date, t("calendar.dateFullFormatOf"))}` : `${name.padStart(2, '0')}h`

		graphsInfo.data.push({
			name: name,
			label: label,
			value: value,
			itemStyle: { color: color?.main },
			emphasis: {
				itemStyle: {
					color: color?.dark,
				},
			}
		})

	});

	graphsInfo.limits = limits;
	graphsInfo.yMaxValue = yMaxValue;

	return graphsInfo;
}

function QArtChart({ data, item, legendName, interval }:IQArtChart) {
	const { t } = useTranslation();

	const getChartProps = (pollutant: IQArtCardItem): IChartInfo => {
		const graphInfo = generateChartData(data, pollutant.name, t, interval);

		const markLineLimits = graphInfo.limits.map((l) => (
			{ yAxis: l.max, lineStyle: { color: l.color.dark }, name: t(`qart.pollutantClassificationLimit.${l.classification}`) }
		)
		);

		const yAxisMax = graphInfo.yMaxValue;

		const props = {
			title: "",
			loading: false,
			error: null,
			data: graphInfo.data,
			unit: pollutant.unit,
			legendName: legendName,
			xAxisNameOptions: {
				name: t(`common.${interval}`)
			},
			markLine: {
				silent: false,
				symbol: ['none', 'none'],
				label: {
					show: false,
				},
				tooltip: {
					formatter: '{b}: {c}'
				},
				data: markLineLimits
			},
			yAxisMax: yAxisMax
		}

		return props;
	}

	return (
		<ChartWidget {...getChartProps(item)} color={item.color} />
	)
}

export default QArtChart
