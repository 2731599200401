import Contadores from "assets/images/map/markers/enabled/occurrences/contadoresagua.svg" 
import Ambiente from "assets/images/map/markers/enabled/occurrences/ambiente.svg"
import Fiscal from "assets/images/map/markers/enabled/occurrences/fiscalizacao.svg"
import espacosverdes from "assets/images/map/markers/enabled/occurrences/espacosverdes.svg"
import instalacaodesportiva from "assets/images/map/markers/enabled/occurrences/instalacaodesportiva.svg"
import limpezaurbana from "assets/images/map/markers/enabled/occurrences/limpezaurbana.svg"
import livrospedidos from "assets/images/map/markers/enabled/occurrences/livrospedidos.svg"
import materiaisviapublica from "assets/images/map/markers/enabled/occurrences/materiaisviapublica.svg"
import muros from "assets/images/map/markers/enabled/occurrences/muros.svg"
import outros from "assets/images/map/markers/enabled/occurrences/outros.svg"
import parqueinfantil from "assets/images/map/markers/enabled/occurrences/parqueinfantil.svg"
import pavimentos from "assets/images/map/markers/enabled/occurrences/pavimentos.svg"
import predionaoconforme from "assets/images/map/markers/enabled/occurrences/predionaoconforme.svg"
import recolhalixo from "assets/images/map/markers/enabled/occurrences/recolhalixo.svg"
import redeagua from "assets/images/map/markers/enabled/occurrences/redeagua.svg"
import redeesgotos from "assets/images/map/markers/enabled/occurrences/redeesgotos.svg"
import transito from "assets/images/map/markers/enabled/occurrences/transito.svg"
import h2 from "assets/images/map/markers/enabled/occurrences/h2.svg"
import evc from "assets/images/map/markers/enabled/occurrences/evc.svg"
import cer from "assets/images/map/markers/enabled/occurrences/cer.svg"


// os nomes devem ser iguais aos imports do ficherio marker.module.scss
const IconsList = [
    {name: "ambiente", url: Ambiente},
    {name: "contadoresdeagua", url: Contadores},
    {name: "fiscalizacao", url: Fiscal},
    {name: "jardinseespacosverdesurbanos", url: espacosverdes},
    {name: "instalacaodesportiva", url: instalacaodesportiva},
    {name: "limpezaurbana", url: limpezaurbana},
    {name: "livrospedidos", url: livrospedidos},
    {name: "materiaisnaviapublica", url: materiaisviapublica},
    {name: "muros", url: muros},
    {name: "outros", url: outros},
    {name: "parqueinfantil", url: parqueinfantil},
    {name: "pavimentos", url: pavimentos},
    {name: "predionaoconforme", url: predionaoconforme},
    {name: "recolhadelixo", url: recolhalixo},
    {name: "rededeagua", url: redeagua},
    {name: "rededeesgotos", url: redeesgotos},
    {name: "transito", url: transito},
    {name: "cer", url: cer},
    {name: "evc", url: evc},
    {name: "h2", url: h2},


]

export default IconsList