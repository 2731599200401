import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GroupIcon from "@mui/icons-material/Group";
  
import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import { useState } from "react";
import ChangeClient from "./ChangeClient";
  
  
const ProfessionalData = () => {
  const {t} = useTranslation();
  const user = useSelector(getUser);

  const [openDialogChangeClient, setOpenDialogChangeClient] = useState(false);

  const onClickChangeClient = () => {
    setOpenDialogChangeClient(true);
  };
  const handlerCloseChangeClient = () => {
    setOpenDialogChangeClient(false);
  };
  return (
    <>
      {user && ( <> 
        <Card>
          <CardHeader
            title={t("user.professionalData")}
            action={
              user.is_superadmin && (
                <Button
                  sx={{ mb: 2 }}
                  onClick={onClickChangeClient}
                  color="primary"
                  variant="outlined"
                  size="small"
                  startIcon={<EditIcon />}
                >
                  {t("edit")}
                </Button>
              )
            }
          />
          <CardContent>
            <Grid container>
              <Grid item xs={12} sx={{ display:"flex", alignItems: "center" }}>
                <AccountTreeIcon color="disabled" /> 
                <Typography variant="body1" pl={1}>
                  {user.client.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ display:"flex", alignItems: "center" }}>
                <GroupIcon color="disabled" /> 
                <Typography variant="body1" pl={1}>
                  {user.is_superadmin
                    ? t("user.superAdmin")
                    : user.is_admin
                    ? t("user.admin")
                    : t("user.user")}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </> )}
      {user.is_superadmin && (
        <ChangeClient
          user={user}
          open={openDialogChangeClient}
          onClose={handlerCloseChangeClient}
        />
      )}
    </>
  )
}
  
export default ProfessionalData;