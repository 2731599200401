import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionSummary, Typography } from "@mui/material"
import IsoDetails from "./IsoDetails"
import { useEffect, useState } from "react"
import { ITipology } from "./IsoView"
import { useLocation } from "react-router-dom"
import { formatNumber } from "utils/number"

const IsoTypologyAccordion = (props: ITipology) => {

    let location = useLocation();

    const descriptionValueStyle = {
        color: "#51bfbc",
        fontSize: "12px",
        display: "flex",
        alignItems: "center"
    }

    const [expanded, setExpanded] = useState(false)

    const handleAccordionClick = () => {
        setExpanded(!expanded)
    }

    useEffect(() => {
        setExpanded(false)
    }, [location])

    return(
    <Accordion disableGutters={true} onClick={handleAccordionClick} expanded={expanded}>
        <AccordionSummary expandIcon={<ExpandMore/>}>
            <Typography>
                {props.description}
            </Typography>
            {props.value && props.unit &&
            <Typography ml={1}
                sx={descriptionValueStyle}>
                    {formatNumber(props.value)} {props.unit}
            </Typography>
            }
        </AccordionSummary>
        <IsoDetails
            numeratorDescription={props.numerator_description}
            numeratorValue={props.numerator_value}
            denominatorDescription={props.denominator_description}
            denominatorValue={props.denominator_value}
            source={props.source}
            year={props.year}
        />
    </Accordion>
    )
}

export default IsoTypologyAccordion;
