import { AppLogo } from "@alb/live-lib";
import { Grid, Typography, useTheme } from "@mui/material";

import LiveDialogTitle from "./DialogTitle";

export interface ILiveDialogTitle {
  icon: string;
  altIcon: string;
  title: string;
  status?: string | React.ReactNode;
  domain?: string;
  onClose?: () => void;
  children?: React.ReactNode;
}

const MarkerDialogTitle = ({
  icon,
  altIcon,
  title,
  status,
  domain,
  onClose,
}: ILiveDialogTitle) => {
  const theme = useTheme();

  return (
    <LiveDialogTitle onClose={onClose}>
      <Grid container alignItems="center">
        <Grid item mr={1.5}>
          <AppLogo
            sx={{ width: 60, height: "auto" }}
            variant="circular"
            src={icon}
            alt={altIcon}
          />
        </Grid>

        <Grid item alignItems="center">
          <Grid item>
            <Typography fontWeight={700} fontSize={20}>
              {title}
            </Typography>

            <Grid sx={{ color: theme.palette.text.secondary }}>
              {status && (
                typeof status === 'string' ? 
                <>
                  <Typography display="inline" fontWeight={600} fontSize={12}>
                    {status}
                  </Typography>
                </> :
                <>
                  {status}
                </>
              )}
              {domain && (
                <Typography display="inline" fontWeight={600} fontSize={12}>
                  {!!status && " ∙ "}
                  {domain}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </LiveDialogTitle>
  );
};

export default MarkerDialogTitle;
