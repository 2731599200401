import { BasicList, IBasicList, IItemList, ISubItemList } from "@alb/live-lib";
import { Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

interface IUrbanWasteItemList extends IItemList {
	subItems?: IUrbanWasteSubItemList[]
}

export interface IUrbanWasteSubItemList extends ISubItemList {
	unit?: string
}

const DevicesList = (props: { itemClicked: (item: ISubItemList ) => void }) => {
	const { t } = useTranslation();

	const { itemClicked } = props;

	/**export interface IBasicList {
		expand?: boolean;
		sx?: object;
		items: IItemList[];
		selectedItem?: IItemList;
		handleClickItem?: (item: IItemList) => void;
		selectedSubItem?: ISubItemList;
		handleClickSubItem?: (subItem: ISubItemList) => void;
		onScroll?: (event: React.SyntheticEvent<Element, Event>) => void;
	} */

	const items: IUrbanWasteItemList[] = [{
		title: "Resíduos urbanos",
		idItem: "urbanWaste",
		subItems: [
			{
				title: "Quilómetros percorridos",
				idItem: "kms",
				unit: 'km',
			},
			{
				title: "Emissões de poluentes",
				idItem: "co2",
				unit: "co2"
			},
			{
				title: "Tonelagem",
				idItem: "total_recolha_kg",
				unit: "kg"
			},
			{
				title: "Número de descargas",
				idItem: "descargas"
			}
		]
	},
	{
		title: "Resíduos verdes",
		idItem: "greenWaste",
		subItems: []
	},
	{
		title: "Resíduos orgânicos",
		idItem: "organicWaste",
		subItems: []
	}
	]

	const [ selectedItem, setSelectedItem ] = useState(items[0])
	const selectedSubItem = (item: ISubItemList) => {
		itemClicked(item);
	}

	const handleSelectedItem = (item: IItemList) => {
		setSelectedItem(item)
	}


	const listArgs: IBasicList = {
		expand: true,
		selectedItem: selectedItem,
		items: items,
		selectedSubItem: selectedItem.subItems ? selectedItem.subItems[0] : undefined,
		handleClickSubItem: selectedSubItem,
		handleClickItem: handleSelectedItem
	}

	return (<>
		<Typography variant="h3" fontSize={'22px'}>
			{t('urbanWaste.devicesList')}
		</Typography>
		<BasicList {...listArgs} />
	</>
	)
}

export default DevicesList;
