import { FilterSection, IApiResponse, MiniSelect } from '@alb/live-lib'
import { Grid, Typography, useTheme } from '@mui/material'
import useGet from 'hooks/fetchData/useGet';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ServiceApiUrl } from 'services/ServiceApiUrl';
import { DoubleSelect } from 'styles/css/components'
import { ISelectOption } from 'types/interfaces';
import { IQARTDevice } from './qartDeviceInterface';

export interface IQArtDviceSelect {
	onChange: (device: IQARTDevice | undefined) => void
}
function QArtDeviceSelect({ onChange }: IQArtDviceSelect) {
	const { t } = useTranslation();
	const theme = useTheme();

	// const defaultOption:ISelectOption = { label: t('qart.allOption'), value: '' }

	const [options, setOptions] = useState<ISelectOption[]>([]);
	const { data } = useGet<IApiResponse<IQARTDevice>>(ServiceApiUrl.qartDevices);
	const [selectedOption, setSelectedOption] = useState<string>("");

	useEffect(() => {
		if (data) {
			let _options = data?.data?.map((p: IQARTDevice) => ({
				label: p.name,
				value: p.id,
			}));

			// _options = [defaultOption, ..._options];
			setOptions(_options);
			if (selectedOption === '') setSelectedOption(_options[0].value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	function changeOption(option: ISelectOption) {
		setSelectedOption(option.value);
	}
	useEffect(() => {
		if (selectedOption) {
			const device = data?.data.find((value: IQARTDevice) => value.id === selectedOption);
			onChange(device);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedOption])


	const selectValue = () => {
		return (
			options?.find(
				(option: ISelectOption) => option.value === selectedOption
			) || ""
		);
	};

	return (
		<Grid container item xs={false} sm={12}>
			<DoubleSelect>
				<Grid item >
					<Typography mr={1} variant="h6">
						{t("dashboard.dataFrom")}
					</Typography>
				</Grid>
				<Grid item>
					<FilterSection
						select={
							<MiniSelect
								onChangeOption={changeOption}
								showLabel
								showBorder
								sx={{
									minWidth: 250,
									...(theme.palette.mode === "light" && {
										backgroundColor: "#ECF8F8",
									}),
								}}
								borderColor="rgba(81,191,188,0.5)"
								options={options}
								value={selectValue()}
							/>
						}
					/>
				</Grid>
			</DoubleSelect>
		</Grid>
	)
}

export default QArtDeviceSelect
