/* eslint-disable react-hooks/exhaustive-deps */
import { IRange, LayoutContext } from "@alb/live-lib";
import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import { subDays } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import { ModuleCards } from "components/modules/ModuleCards";
import { IFilterFormOccurrences } from "components/occurrences/FilterForm";
import { OccurrenceAdd } from "components/occurrences/OccurrenceAdd";
import { OccurrenceFilters } from "components/occurrences/OccurrenceFilters";
import { OccurrencesCardsView } from "components/occurrences/OccurrencesCardsView";
import OccurrencesList from "components/occurrences/OccurrencesList";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { PERMISSIONS } from "utils/permissions/permissions";
import { objectIsEmpty } from "utils/utils";

interface IOccStatusValue {
	name: string,
	value: string,
	type: string
}

const Occurrences = () => {
	// global component variables
	const { addHeader, addAction, action } = useContext(LayoutContext);
	const { hasPermission } = usePermission();
	const { t } = useTranslation();
	const location = useLocation();
	const { headers: headersOCurrencesCards } = useGetModuleHeaders(
		MODULES_KEYS.OCCURRENCES
	);

	const searchParams = new URLSearchParams(location.search);
	const view = searchParams.get("view");

	// local state management
	const [display, setDisplay] = useState("cards");
	const [status, setStatus] = useState<IOccStatusValue[]>([]);
	const [added, setAdded] = useState<boolean>(false);

	const canCreate = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.CREATE);
	const [filterSearch, setFilterSearch] = useState<string>("");
	const [dataFilterForm, setDataFilterForm] = useState<IFilterFormOccurrences | null>(null);

	const [selectedRange, setSelectedRange] = useState<IRange | undefined>({
		startDate: new Date(subDays(new Date(), 6).setHours(0, 0, 0)),
		endDate: new Date(),
	});

	// page header configuration
	const header = {
		title: t("occurrences.occurrencesManagement"),
		action: canCreate && (
			<Button
				variant="contained"
				disableElevation
				startIcon={<Add />}
				onClick={() => addAction(true)}
			>
				{t("occurrences.addOccurrence")}
			</Button>
		),
	};

	// component handlers
	const onCloseDialog = () => {
		addAction(false);
	};

	let occurrenceAdd;
	if (action) {
		occurrenceAdd = canCreate && (
			<OccurrenceAdd
				open={action}
				onClose={onCloseDialog}
				onSuccess={() => {
					setAdded(true);
					onCloseDialog();
				}}
			/>
		);
	}

	const {
		data: occurrencesStatus,
		error: errorCards,
		refetch: refetchOccurrencesCards,
		loading
	} = useGet(
		ServiceApiUrl.occurrencesTotalStatus,
		getParams(),
		{
			manual: false,
		},
		headersOCurrencesCards
	);

	function getParams() {
		let params = undefined;
		if (selectedRange?.startDate || selectedRange?.endDate) {
			params = {
				record_date_interval:
					formatDate(selectedRange?.startDate, t("calendar.dateTimeFormat")) +
					"," +
					formatDate(selectedRange?.endDate, t("calendar.dateTimeFormat")),
			};
		}

		params = { ...params, order_by: 'frontend_order' }

		return params;
	}

	// component effects
	useEffect(() => {
		addHeader(header);

		if (view === "list") setDisplay("list");
	}, []);

	useEffect(() => {
		if (occurrencesStatus) {
			const total = occurrencesStatus.total;
			const defaultType = { type: "total" };

			let itemsStatus: IOccStatusValue[] = [];
			occurrencesStatus.status?.forEach((item: { name: string, value: number }) => {
				itemsStatus.push({
					name: item.name,
					value: `${item.value}/${total}`,
					...defaultType,
				});
			}
			);

			const resolutionTime = occurrencesStatus.resolutionTime;
			if (!objectIsEmpty(resolutionTime)) {
				itemsStatus.push({
					name: 'avg_resolution_days',
					value: resolutionTime['avg_resolution_days'],
					type: 'avg'
				})
			}

			setStatus((prev: IOccStatusValue[]) => (prev = itemsStatus));
		}
	}, [occurrencesStatus]);

	useEffect(() => {
		if (added) {
			refetchOccurrencesCards();
		}

		setAdded(false);
	}, [added]);

	return (
		<>
			<ModuleCards
				module="occurrences"
				items={status}
				loading={loading}
				error={errorCards}
				sx={{ mt: 5 }}
				dataTestId={"OccurrencesCardsContainer"}
			/>
			<OccurrenceFilters
				display={display}
				setDisplay={setDisplay}
				filterDateRange={selectedRange}
				setFilterDateRange={setSelectedRange}
				filterSearch={filterSearch}
				setFilterSearch={setFilterSearch}
				dataFilterForm={dataFilterForm}
				setDataFilterForm={setDataFilterForm}
			/>

			{display === "list" && (
				<OccurrencesList
					selectedDateRange={selectedRange}
					filterSearch={filterSearch}
					filtersPopup={dataFilterForm}
					refetch={added}
					setRefetch={setAdded}
				/>
			)}
			{display === "cards" && (
				<OccurrencesCardsView
					needToRefetch={added}
					selectedRange={selectedRange}
				/>
			)}
			{occurrenceAdd}
		</>
	);
};

export default Occurrences;
