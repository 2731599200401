import { IRange } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";
import { TAdapterConfigurator, TStream } from "types/types";

import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";

import { IDeviceInfo } from "./CERManagementSelect";
import LineChartAnalysisWidget, {
  IChartInfo,
} from "./LineChartStreamsAnalysisWidget";

export interface IStreamValue {
  [stream: string]: number;
}

interface IAnalysisView {
  device: IDeviceInfo;
  filterDateRange: IRange | undefined;
}
export const AnalysisView = ({ device, filterDateRange }: IAnalysisView) => {
  const { t } = useTranslation();

  //guarda streams que são para mostrar
  const [selectedStreams, setSelectedStreams] = useState<TStream[]>([]);
  const [selectedType, setSelectedType] = useState<string>("power");

  //se o device for o de armaz.
  const isStorage = device.name === "Armazenamento";

  const { data: adapterConfigurators } = useGet(
    ServiceApiUrl.adapterConfigurators
  );

  const getParams = () => {
    let params = undefined;
    if (filterDateRange?.startDate || filterDateRange?.endDate) {
      params = {
        from: formatDate(
          filterDateRange?.startDate,
          t("calendar.dateTimeFormatLocal")
        ),
        to: formatDate(filterDateRange?.endDate, t("calendar.dateTimeFormat")),
      };
    }
    return params;
  };

  //pedido do valor das streams
  const {
    data: values,
    loading,
    error,
  } = useGet<{ object: IStreamValue[] }>(device?.api, getParams());

  const selectedAdpt = adapterConfigurators?.data?.find(
    (adapter: TAdapterConfigurator) => adapter.domain === device?.domain
  );

  useEffect(() => {
    setSelectedStreams(
      selectedAdpt?.streams?.filter((item: TStream) =>
        item.name.toLowerCase().includes(selectedType)
      )
    );
  }, [selectedAdpt?.streams, selectedType]);

  const getSelectOptions = () => {
    const options = [
      {
        label: t("cerManagement.power"),
        value: "power",
      },
      {
        label: t("cerManagement.voltage"),
        value: "voltage_phase",
      },
    ];
    if (isStorage) {
      options.push({
        label: t("cerManagement.current"),
        value: "current_phase",
      });
      return options;
    } else {
      options.push({
        label: t("cerManagement.current"),
        value: "total_current",
      });
      return options;
    }
  };

  const [selectOptions, setSelectOptions] = useState<ISelectOption[]>(
    getSelectOptions()
  );

  useEffect(() => {
    setSelectOptions(getSelectOptions());
  }, [isStorage]);

  //dados "comuns" aos 2 devices
  const chartProps: IChartInfo = {
    deviceStreamsValues: values,
    loading: loading,
    error: error,
    selectedDevice: device,
    selectedStreams: selectedStreams,
    selectOptions: selectOptions,
    setSelectedType: setSelectedType,
    title: isStorage
      ? t("cerManagement.phasesComparison")
      : t("cerManagement.voltageComparison"),
  };

  return (
    <Grid
      mb={3}
      container
      columnSpacing={5}
      rowSpacing={3}
      mt={4}
      justifyContent="space-between"
      sx={{ pt: "35px" }}
    >
      <Grid item xs={12}>
        <Typography variant="h3">{t("urbanWaste.analyticsLabel")}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card data-testid="CERManagementChart">
          <CardContent>
            <LineChartAnalysisWidget {...chartProps} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
