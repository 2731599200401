import { TableHeadOrder } from "@alb/live-lib";

import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";

import { formatOrderBy } from "./orderByAPI";

export function getListParams(page: number, order: TableHeadOrder, orderBy: string, filterSearch: string) {
	var params: {
		page: number;
		items: number;
		order_by: string;
		contains: string;
	} = {
		page: page,
		items: ROWS_PER_PAGE,
		order_by: formatOrderBy(order, orderBy),
		contains: filterSearch,
	};

	return params;
}
