/* eslint-disable react-hooks/exhaustive-deps */
import { AxiosRequestConfig } from "axios";
import useAxios from "axios-hooks";

interface IFetchDataOptions {
  manual: boolean
}

const useGet = <T=any>(
  url: string,
  params?: any,
  options?: IFetchDataOptions,
  othersConfig?: AxiosRequestConfig<any>
) => {
  const requestConfig: AxiosRequestConfig = {
    url: url,
    method: 'GET',
    params: { ...params },
    ...othersConfig,
  }

  const [{ data, loading, error }, refetch, cancelRequest] = useAxios<T>(
    requestConfig,
    { manual: options?.manual }
  )

  return {
    data,
    loading,
    error,
    refetch,
    cancelRequest,
  }
}

export default useGet
