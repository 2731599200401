import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store";
import { TMarker, TWazeJamMarker } from "types/types";

import wazeJamIcon from "components/map/waze/wazeJamIcon";
import { fetchMarkersOneModule } from "services/MarkersAPI";
import { getCookie } from "utils/cookie";

import { IFetchMarkersModule } from "./eventsSlice";

interface IWazeSliceState {
  wazeMarkers: TMarker[],
  wazeFilterParams: any,
}

const initialState: IWazeSliceState = {
  wazeMarkers: [],
  wazeFilterParams: {},
}

export const fetchWazeData = createAsyncThunk(
  "waze/fetchWazeData",
  async (
    {
    publicAPI,
    user,
    keyModule,
    url,
    bounds,
    filterParams: params,
  }: IFetchMarkersModule,
  ) => {
    const response = await fetchMarkersOneModule(
      publicAPI,
      user,
      keyModule,
      url,
      bounds,
      params
    );
    return response;
  }
)

const wazeSlice = createSlice({
  name: "waze",
  initialState,
  reducers: {
    setWazeMarkers(state, action) {
      state.wazeMarkers = action.payload
    },
    setWazeFilterParams(state, action) {
      state.wazeFilterParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWazeData.fulfilled, (state, action) => {
      const markers = action.payload.map((item) => {
        const isDarkMode = getCookie('theme') === 'dark'
        if(item.type === 'jams') {
          return {
            ...item,
            markerDivIcon: wazeJamIcon(item as TWazeJamMarker, isDarkMode),
            ignoreClusters: true,
          }
        }
        return item
      })
      state.wazeMarkers = [...markers];
    });
    builder.addCase(fetchWazeData.rejected, (state, action) => {
      state.wazeMarkers = [];
    });
  },
});

export const { setWazeFilterParams, setWazeMarkers } = wazeSlice.actions

export const getWazeMarkers = (state: RootState) =>
  state.waze.wazeMarkers

export const getWazeFilterParams = (state: RootState) =>
  state.waze.wazeFilterParams

  export default wazeSlice.reducer;