import { TableHeadCell } from "@alb/live-lib";
import { useTranslation } from "react-i18next";
import { IElectricMobility, IResultTable } from "types/interfaces";
import { Table, TableBody, TableContainer, } from '@mui/material';
import { TableHeadSort} from '@alb/live-lib'
import { CustomTablePagination } from 'components/Utils/Pagination/CustomTablePagination';
import TableInformationRow from "./TableInformationRow";
import { arrayIsEmpty } from "utils/utils";

const TableInformation = (props: IResultTable<IElectricMobility>) => {
	const { t } = useTranslation()
	const {
		data: chargingTransactions,
		orderBy,
		order,
		setOrder,
		setOrderBy,
		page,
		setPage
	} = props


	const tableHeads: TableHeadCell[] = [
		{
			id: 'name',
			label: t('electricMobility.table.header.name'),
		},
		{
			id: 'duration',
			label: t('electricMobility.table.header.duration'),
			numeric: true,
		},
		{
			id: 'consumption_kWh',
			label: t('electricMobility.table.header.consume'),
			numeric: true
		},
		{
			id: 'cost',
			label: t('electricMobility.table.header.cost'),
			numeric: true
		},
		{
			id: 'cost_kWh',
			label: t('electricMobility.table.header.costKwh'),
			numeric: true
		},
		{
			id: 'CO2_reduction',
			label: t('electricMobility.table.header.CO2Reduction'),
			numeric: true
		},
		{
			id: 'start_transaction',
			label: t('electricMobility.table.header.startDate'),
		},
		{
			id: 'end_transaction',
			label: t('electricMobility.table.header.endDate'),
		},
	]
	let row
	if (!arrayIsEmpty(chargingTransactions?.data)) {
		row = chargingTransactions.data.map((item: IElectricMobility, index: number) => (
			<TableInformationRow
				key={index}
				row={item}
			/>
		))
	}
	const handleRequestSort = (
		item: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1) //porque o index da paginação do mui inicia no 0
	}

	return <>
		<TableContainer sx={{ mt: 4 }}>
			<Table>
				<TableHeadSort
					headCells={tableHeads}
					onRequestSort={handleRequestSort}
					order={order}
					orderBy={orderBy}
				/>
				<TableBody>{row}</TableBody>
			</Table>
		</TableContainer>

		<CustomTablePagination
			count={chargingTransactions?.totalCount}
			page={page}
			totalPages={chargingTransactions?.totalPages}
			onPageChange={handleChangePage}
		/>
	</>

}

export default TableInformation;
