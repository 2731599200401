import { useFeedback } from '@alb/live-lib';
import DownloadIcon from "@mui/icons-material/Download";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { IconButton } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useGet from 'hooks/fetchData/useGet'
import useErrorMessage from 'hooks/useErrorMessage';
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { dateTimeFormat } from 'utils/date'

const Download = ({
    selectedDeviceInfo,
    startDate,
    endDate
}: any) => {

    const { t } = useTranslation()
    const { addFeedback } = useFeedback()
    const { showError } = useErrorMessage();

    const {
        data: dataCSV,
        error: errorCSV,
        loading: loadingCSV,
        refetch: refetchCSV,
    } = useGet(ServiceApiUrl.adapterDownload(selectedDeviceInfo?.adapter_configurators[0].id), null, {
        manual: true,
    });

    const handleRefetchCSV = async (params: any) => {
        try {
            await refetchCSV({ params: { ...params } });
            addFeedback({
                message: t("feedback.success.downloadFile"),
                severity: "success",
              });
        } catch (err) {
            console.log(err);
        }
    };

    const handleDownload = (e: any) => {
        // e.preventDefault();

        const from = dateTimeFormat(startDate)
        const to = dateTimeFormat(endDate)

        const _params: any = {
            external_id: selectedDeviceInfo.external_id,
            from,
            to,
            // page: 0,
            // items: 0,
        };
        handleRefetchCSV(_params);
    };
    const downloadFile = ({
        data,
        fileName,
        fileType,
        addStartEncodeString = false,
    }: any) => {
        // Create a blob with the data we want to download as a file
        // const blob = new Blob([data]);
        // const blob = new Blob([data], { type: `${fileType};charset=utf-8;` });

        let blobPart = [data];
        if (addStartEncodeString) blobPart = ["\ufeff", data];

        const blob = new Blob(blobPart, {
            type: `${fileType};charset=utf-8;`,
        });

        // Create an anchor element and dispatch a click event on it
        // to trigger a download
        const a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent("click", {
            view: window,
            bubbles: true,
            cancelable: true,
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };
    const downloadCsv = ({ data, fileName, fileType }: any) => {
        downloadFile({
            data,
            fileName,
            fileType: fileType || "text/csv",
            addStartEncodeString: true,
        });
    };

    useEffect(() => {
        if (!loadingCSV && !errorCSV && dataCSV && dataCSV.length > 0) {
            // const dt = format(new Date(), "yyyyMMdd_HHmmss");
            const from = dateTimeFormat(startDate);
            const to = dateTimeFormat(endDate);
            const fileName = `${selectedDeviceInfo.name}_${from}_${to}.csv`;
            downloadCsv({
                data: [dataCSV],
                fileName: fileName,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadingCSV]);

    useEffect(() =>{
        if(errorCSV) {
            showError(errorCSV);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorCSV])

    return (
        <IconButton
            aria-label="download"
            sx={{ float: "right" }}
            className="outlined"
            size="small"
            color="primary"
            onClick={handleDownload}
            disabled={loadingCSV}
            title={t('dashboard.downloadData')}
        >
            {loadingCSV ? <MoreHorizOutlinedIcon /> : <DownloadIcon />}
        </IconButton>
    )
}
export default Download
