import { IRange } from "@alb/live-lib";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ChartClientCityMap from "components/occurrences/charts/ChartClientCityMap";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getNameClient } from "store/slices/authSlice";
import { formatDate } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { getCityClient } from "utils/utils";

import ChartCategory from "./charts/OccurrencesCategoriesChart";
import OccurrencesWeekChart from "./charts/OccurrencesWeekChart";

export const OccurrencesCardsView = (props: {
  needToRefetch?: boolean;
  selectedRange: IRange | undefined;
}) => {
  const { needToRefetch, selectedRange } = props
	const { t } = useTranslation()
	const { headers: headersOCurrences } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);
  const nameClient = useSelector(getNameClient);

	const { data, loading, error, refetch: refetchOccurrences } = useGet(
		ServiceApiUrl.occurrencesAllParishes,
		getParams(),
		undefined,
		headersOCurrences
	)

	function getParams() {
        let params = undefined;
        if (selectedRange?.startDate || selectedRange?.endDate) {
          params = {
            record_date_interval: formatDate(selectedRange?.startDate, t("calendar.dateTimeFormat")) + "," + formatDate(selectedRange?.endDate, t("calendar.dateTimeFormat"))
          };
        }
        return params;
      }

	useEffect(() => {
		if(needToRefetch) {
			refetchOccurrences()
		}
			// eslint-disable-next-line
	}, [needToRefetch])



  return (
    <>
      <Grid
        marginY={3}
        container
        columnSpacing={3}
        rowSpacing={2}
        justifyContent="space-between"
      >
        <Grid item xs={6} sm={6}>
          <Card data-testid={"OccurrencesNumberChart"} sx={{ height: "742px" }}>
            <CardContent sx={{ height: "100%" }}>
              <Typography variant="h3" fontSize={"22px"}>
                <Trans
                  i18nKey="occurrences.charts.titleChartOccurrences"
                  values={{ client: getCityClient(nameClient) }}
                />
              </Typography>
              <ChartClientCityMap data={data} loading={loading} error={error} />
            </CardContent>
          </Card>
        </Grid>
        <Grid container item xs={6} sm={6} rowSpacing={2}>
          <Grid item xs={12} sm={12}>
            <Card
              data-testid={"OccurrencesPerWeekChart"}
              sx={{ height: "370px" }}
            >
              <CardContent sx={{ height: "100%" }}>
                <Typography variant="h3" fontSize={"22px"}>
                  {t("occurrences.charts.titleOccurrencesPerWeek")}
                </Typography>
                <OccurrencesWeekChart
                  selectedRange={selectedRange}
                  needToRefetch={needToRefetch}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Card
              data-testid={"OccurrencesByCategoryChart"}
              sx={{ height: "357px" }}
            >
              <CardContent sx={{ height: "100%" }}>
                <Typography variant="h3" fontSize={"22px"}>
                  {t("occurrences.charts.titleOccurrencesByCategory")}
                </Typography>
                <ChartCategory
                  selectedRange={selectedRange}
                  needToRefetch={needToRefetch}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        {/*<Grid item xs={12} sm={6}>
						<Card>
							<CardContent>
								<Typography variant="h3" fontSize={'22px'}>
									{t('occurrences.occurrencesByParishes')}
								</Typography>
								<ChartOccurrencesParishes data={data} loading={loading} error={error} />
							</CardContent>
						</Card>
					</Grid>*/}
        {/* <Grid item xs={12} sm={6}>
					<Card data-testid={"OccurrencesByStatusChart"}>
						<CardContent>
							<Typography variant="h3" fontSize={'22px'}>
								{t('occurrences.occurrencesByStatus')}
							</Typography>
							<OccurrencesStatusChart  selectedRange={selectedRange} needTorefetch={needToRefetch}/>
						</CardContent>
					</Card>
				</Grid> */}
      </Grid>
    </>
  );
};
