import { IGroupForm } from "types/interfaces";

const formDefaultValues = (data?: IGroupForm, users?: any ) => {


    let usersData:any  = [];
    if(users) {
        data?.user?.forEach(e => {
            users.forEach((u: { value: string; }) => {
                if(u.value === e) {
                    usersData.push(u)
                }
            })
        })
    }
    

    const defaultValues = {
        name: data?.name || "",
        user: usersData || [] 
    }

    return defaultValues;
}

export default formDefaultValues;