import { useFeedback } from "@alb/live-lib";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IClientForm } from "types/interfaces";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import useCreate from "hooks/fetchData/useCreate";
import ClientsForm from "./ClientsForm";
import { useState } from "react";

export interface IClientsAdd {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const ClientsAdd = ({ open, onClose, onSuccess }: IClientsAdd) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const addFormId = "add-clients-form";

  //pedido para criar Cliente
  const { loading, refetch: createClient } = useCreate(ServiceApiUrl.clients);

  // sucesso
  const handlerSuccess = () => {
    onClose(); //fecha modal
    addFeedback({
      message: t("clients.clientAdded"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  const formSubmitHandler = async (payLoad: IClientForm) => {
    const payLoadAPI = payLoad as unknown as IClientForm;
    payLoadAPI.account.adapter_configurators =
      payLoadAPI.account.adapter_configurators.map((a: any) => a.value);
    payLoadAPI.account.admin = true;
    var centerPoint = payLoadAPI.client.center_point
    centerPoint = centerPoint && centerPoint.length > 0 ? centerPoint : []
    payLoadAPI.client.center_point = centerPoint
    try {
      await createClient({ data: payLoadAPI });
      await handlerSuccess();
    } catch (error) { }
  };

  const [formIsValid, setFormIsValid] = useState(false);

  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open}>
      <DialogTitle>
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("clients.addClients")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <ClientsForm
          setFormIsValidStatus={getFormIsValidStatus}
          formId={addFormId}
          onFormSubmit={formSubmitHandler}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!formIsValid}
          type={"submit"}
          form={addFormId}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClientsAdd;
