import { object, string, date, array } from "yup";
import { t } from "i18next";
import { formatDate } from "utils/date";
export const InputsLengths = {
  max: {
    description: 500,
    notes: 500,
  },
  min: {
    name: 3,
    scope: 3,
  },
};

const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;


export const getEventFormSchema = () =>
  object().shape({
    name: string().required(),
    type: string().required(),
    //Se for para retirar a prop "noAllowedWrite", estas validações das datas tem de ser otimizadas no max e min
    start_date: date()
      .typeError(
        t("validations.invalidDate", { dateFormat: "HH:mm, YYYY-MM-DD" })
      )
      .nullable()
      .required(),
		end_date: date()
			.nullable()
			.typeError(
        t("validations.invalidDate", { dateFormat: "HH:mm, YYYY-MM-DD" })
      )
      .when('start_date', ([start_date], schema) => {
				if (start_date) {
					const dateFromFormatted = formatDate(start_date, "HH:mm, yyyy-MM-dd");
					return schema.min(start_date, t("validations.invalidMinDate", { minDate: dateFromFormatted }))
				}
				else {
					return schema;
				}
			}),
    geolocation: array()
    .json()
    .transform((value: any, originalValue: any, context: any) => {
      // o valor é do tipo array
      if (context.isType(value)) return value;

      // para o caso de estar vazio
      if (value?.length === 0) return []

      // tem um valor e não é um array
      return value;
    })
      .min(
        2,
        t("validations.enterValidFormat", {
          formatType: "[lat,lng]",
        })
      )
      .required(
        t("validations.enterValidFormat", {
          formatType: "[lat,lng]",
        })
      )
      .nullable(),
    // geolocation: string().required(),
    category: string().required(),
    status: string().required(),
    url: string().nullable().notRequired().when('url', {
      is: (value: any) => value?.length,
      then: rule => rule.matches(URL, t("events.URLRule")) ,
    })
  },
  [
    // Add Cyclic deps here because when require itself
    ['url', 'url'],
]);
