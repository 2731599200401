import React, { useEffect, useContext, useState } from "react";
import { BackToButton, EntityInfo, EntityInfoItem, IApiResponse, LayoutContext } from "@alb/live-lib";
import { useTranslation } from "react-i18next";

import { ServiceApiUrl } from "services/ServiceApiUrl";
import useGet from "hooks/fetchData/useGet";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import {Loading} from "@alb/live-lib";
import NoData from "components/Utils/NoData";
import UserPermissions from "components/management/users/UserPermissions";

import { formatRoutePath } from "utils/routePath";
import { formatDate } from "utils/date";
import { TUser } from "types/types";
import { ISelectOption } from "types/interfaces";
import UsersEdit from "components/management/users/UsersEdit";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";
import FilterSelect from "components/Utils/FilterSelect";

export const UserDetail = () => {
  let params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { addHeader, addAction, action } = useContext(LayoutContext);
  const { hasPermission } = usePermission();
  const canUpdate = hasPermission(PERMISSIONS.MANAGEMENT.UPDATE);
  const [refetchList, setRefetchList] = useState<boolean>(false);

  // fetch user by id
  const {
    data: user,
    loading,
    error,
    refetch,
  } = useGet(`${ServiceApiUrl.user}/${params.userId}`);

  const handleSelectedUser = (option: ISelectOption | null) => {
    if (!option) return undefined;

    const path = formatRoutePath(location, params, {
      userId: option.value,
    });
    navigate(path);
  };

  const customOptions = (data: IApiResponse<TUser>) => {
    setRefetchList(false)
    return data.data?.map((user: TUser) => ({
      label:
        user?.first_name && user?.last_name
          ? `${user?.first_name} ${user?.last_name}`
          : user.username,
      value: user.id,
    }));
  };

  // user name to set on the page title
  const name =
    user?.first_name && user?.last_name
      ? `${user?.first_name} ${user?.last_name}`
      : undefined;
  const header = {
    title: user ? (name ? name : user?.username) : t("user.detail"),
    backTo: (
      <BackToButton
        title={t("goBack")}
        onClick={() => navigate("/management/users")}
      />
    ),
    select: user && (
      <FilterSelect<any>
        apiUrl={ServiceApiUrl.user}
        onSelected={handleSelectedUser}
        selectedValue={params?.userId}
        customOptions={customOptions}
        refetchList={refetchList}
      />
    ),
    action: canUpdate &&
      user &&
      user?.status !== "deleted" &&
      !user?.is_superadmin && (
        <Button
          variant="contained"
          disableElevation
          startIcon={<EditIcon />}
          onClick={() => addAction(true)}
        >
          {t("user.editUser")}
        </Button>
      ),
  };

  const updateUser = () => {
    refetch();
    setRefetchList(true)
  };

  let userEdit;

  if (canUpdate && action && user) {
    userEdit = (
      <UsersEdit
        user={user}
        open={action}
        onClose={() => addAction(false)}
        updateList={updateUser}
      />
    );
  }


  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, refetchList]);

  return (
    <>
      <Loading show={loading} />
      {!loading && user && (
        <EntityInfo
          sx={{ mb: 5 }}
          title={t("common.details")}
          // extraInfo={
          //   !user.is_superadmin &&
          //   !user.is_admin &&
          //   user.permission && (
          //     <UserPermissions permissions={user.permission} sx={{ mt: 3 }} />
          //   )
          // }
        >
          <EntityInfoItem
            label={t("user.name")}
            value={`${user?.first_name} ${user?.last_name}`}
          />
          <EntityInfoItem
            label={t("common.statusLabel")}
            value={t(`user.status.${user.status}`)}
          />

          <EntityInfoItem label={t("user.Username")} value={user.username} />
          <EntityInfoItem
            label={t("user.institutionalEmail")}
            value={user.email}
          />

          <EntityInfoItem
            label={t("user.language")}
            value={t(`${user.language.toLowerCase()}`)}
          />
          <EntityInfoItem
            label={t("user.administrator")}
            value={user.is_admin ? t("common.yes") : t("common.no")}
          />
          <EntityInfoItem
            label={t("user.superAdministrator")}
            value={user.is_superadmin ? t("common.yes") : t("common.no")}
          />
          <EntityInfoItem
            label={t("common.createdAt")}
            value={formatDate(
              user.created_at,
              t("calendar.dateTimeFullFormat")
            )}
          />
          <EntityInfoItem
            label={t("common.updatedAt")}
            value={formatDate(
              user.updated_at,
              t("calendar.dateTimeFullFormat")
            )}
          />
          <EntityInfoItem
            label={t("user.lastLogin")}
            value={formatDate(
              user.last_login,
              t("calendar.dateTimeFullFormat")
            )}
          />
          {user.account && (
            <EntityInfoItem
              label={t("accounts.account")}
              value={user.account?.name}
            />
          )}
          {!user.is_superadmin && !user.is_admin && user.permission && (
            <UserPermissions permissions={user.permission} sx={{ mt: 3 }} />
          )}
        </EntityInfo>
      )}
      {!loading && !user && <NoData error= {error} />}
      {userEdit}
    </>
  );
};
