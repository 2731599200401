import { InputAutocomplete, InputCheckbox, InputText, RequiredField } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IModuleForm, ISelectOption } from "types/interfaces";
import { TAdapterConfigurator, TModule } from "types/types";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { RequiredFields } from "components/Utils/RequiredField";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { arrayIsEmpty } from "utils/utils";

import { formDefaultValues } from "./formDefaultValues";
import { getModuleSchema } from "./validations";

const ModulesForm = (props: {
  formId: string;
  clientID?: string;
  data?: TModule;
  editForm?: boolean;
  onFormSubmit: (payLoad: IModuleForm) => void;
  onChangeStatusForm?: (status: boolean) => void;
}) => {
  const { t } = useTranslation();

  const {
    formId,
    clientID,
    data,
    editForm,
    onFormSubmit,
    onChangeStatusForm,
  } = props;

  const [moduleChecked, setModuleChecked] = useState<boolean>(false);
  const [modulesOptions, setModulesOptions] = useState<ISelectOption[]>([]);
  const [gatewaysOptions, setGatewaysOptions] = useState<ISelectOption[]>([]);

  // get list of gateways
  const { data: allGateways } = useGet(ServiceApiUrl.adapterConfigurators, {
    client: clientID,
  });

  useEffect(() => {
    if (allGateways) {
      const options = allGateways?.data?.map((p: TAdapterConfigurator) => ({
        label: p.name,
        value: p.id,
      }));

      setGatewaysOptions(options);
    }
  }, [allGateways]);

  // get list of modules
  const {
    data: allModules,
    loading: loadingAllModules,
    error: errorAllModules,
    refetch: refetchAllModules,
  } = useGet(ServiceApiUrl.modules, { all: true, client: clientID}, { manual: true });

  useEffect(() => {
    if (allModules) {
      const options = allModules?.data?.map((p: TModule) => ({
        label: p.name,
        value: p.id,
      }));

      setModulesOptions(options);
    }
  }, [allModules]);

  // const [gateway, setGateway] = useState<any[]>(module.adapter_configurators.map((p: IAdapterConfigurator) => {
  //   return { label: p.name, value: p.id }
  // }) || []);
  const defaultValues = formDefaultValues(data);
  const methodsForm = useForm<IModuleForm>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(getModuleSchema()),
  });

  const { control, handleSubmit, resetField, setValue } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IModuleForm> = (
    payLoad: IModuleForm
  ) => {
    if (payLoad.old_module && payLoad.name) {
      payLoad = {
        ...payLoad,
        name: (payLoad.name as ISelectOption).label,
        id: (payLoad.name as ISelectOption).value,
      };
    }
    delete payLoad["old_module"];
    onFormSubmit(payLoad);
  };

  const onChangeOldModule = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setModuleChecked(checked);
    if (checked) {
      refetchAllModules();
      resetField("adapter_configurators", {
        defaultValue: defaultValues.adapter_configurators,
      });
      resetField("extra_params", {
        defaultValue: defaultValues.extra_params,
      });
      resetField("name", {
        defaultValue: null,
      });
    } else {
      resetField("name", {
        defaultValue: defaultValues.name,
      });
    }
  };

  useEffect(() => {
    if (onChangeStatusForm) {
      onChangeStatusForm(methodsForm.formState.isValid);
    }
  }, [onChangeStatusForm, methodsForm.formState.isValid]);

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            {!editForm && (
              <Grid item xs={12}>
                <InputCheckbox
                  control={control}
                  setValue={setValue}
                  name={"old_module"}
                  labelText={t("modules.addExistingModule")}
                  onChange={onChangeOldModule}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <RequiredField title={t("modules.name")} />
              {moduleChecked ? (
                loadingAllModules ? (
                  <Loading />
                ) : arrayIsEmpty(allModules?.data) ? (
                  <NoData error={errorAllModules} />
                ) : (
                  <InputAutocomplete
                    name="name"
                    options={modulesOptions}
                    control={control}
                    required
                  />
                )
              ) : (
                <InputText
                  // disabled={editForm}
                  control={control}
                  name="name"
                  placeholder={t("modules.namePlaceholder")}
                />
              )}
            </Grid>
            {!moduleChecked && (
              <>
                <Grid item xs={12}>
                  <Typography gutterBottom color="text.black" variant="h6">
                    {t("modules.adapters")}
                  </Typography>

                  {arrayIsEmpty(gatewaysOptions) && (
                    <Typography mt={2} mb={2} variant="body1">
                      {t("adapters.thereAreNotAdapters")}
                    </Typography>
                  )}
                  {!arrayIsEmpty(gatewaysOptions) && (
                    <InputAutocomplete
                      name="adapter_configurators"
                      options={gatewaysOptions}
                      multiple
                      control={control}
                      required
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom color="text.black" variant="h6">
                    {t("adapters.extraParams")}
                  </Typography>
                  <InputText
                    control={control}
                    name="extra_params"
                    placeholder={t("modules.extraParamsPlaceholder", {
                      object: JSON.stringify({ key: "value" }),
                    })}
                  />
                </Grid>
                {!editForm && (
                  <Grid item xs={12}>
                    <Typography gutterBottom color="text.black" variant="h6">
                      {t("modules.moduleType")}
                    </Typography>
                    <InputText
                      control={control}
                      name="endpoint_type"
                      placeholder={t("modules.moduleTypePlaceholder")}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </form>
      </FormProvider>
      <RequiredFields />
    </>
  );
};

export default ModulesForm;
