import { DynamicForm, FilterSection, IDynamicInputTemplate, IFormDefinition, IRange, TypeDynamicInput } from "@alb/live-lib";
import { Grid } from "@mui/material";
import { subDays } from "date-fns";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import i18n from "i18n/config";
import { ColXTemplate } from "utils/templates";

interface IDefaultValues {
    startDateRange: IRange | undefined;
  }

const FiltersSection = (props: {
    setFilterDateRange: React.Dispatch<React.SetStateAction<IRange | undefined>>;
  }) => {

    const { setFilterDateRange } = props

    const defaultValueFieldDateRange = { startDateRange: undefined };

    const [selectedRange, setSelectedRange] = useState<IRange | undefined>();

    // TODO temporario para a demo de AveiroDemo
    const customRange = {
      startDate: new Date(subDays(new Date("2023/09/14"), 6).setHours(0, 0, 0)),
      endDate: new Date("2023/09/14"),
    };

    // const predefinedRanges = {
    //     labelLast30Days: t("calendar.last30Days"),
    //     labelLast7Days: t("calendar.last7Days"),
    //     position: "left",
    //   };

    const template = useCallback(
        ({ inputLabel, children }: IDynamicInputTemplate) => {
          return (
            <ColXTemplate xsCol={12} inputLabel={inputLabel} children={children} />
          );
        },
        []
      );

    const calculateFiltersCount = useCallback(() => {
        let count = 0;
        if (selectedRange) count++;

        return count;
      }, [selectedRange]);

    const selectedRangeDates = (date: IRange | undefined) => {
        setSelectedRange(date);
      };

    const dynamicForm = {
        formId: "form-search",
        inputsDefinition: {
          startDateRange: {
            typefilter:true,
            inputType: TypeDynamicInput.daterange,
            label: t("cerManagement.valuesDate"),
            placeholder: t("calendar.placeholderDate"),
            defaultValue: defaultValueFieldDateRange.startDateRange,
            defaultValueField: defaultValueFieldDateRange,
            onSelectedRange: selectedRangeDates,
            noOptionsText: t("common.noOptions"),
            template: template,
            locale: i18n.language,
            // TODO temporario para a demo de AveiroDemo
            // predefinedRanges: predefinedRanges,
            labelBtnCancel: t("cancel"),
            labelBtnConfirm: t("apply"),
            // TODO temporario para a demo de AveiroDemo
            clearButton: false,
            minDate: customRange.startDate,
            maxDate: customRange.endDate,
          },
        },
      };

    const defaultValues = {
        startDateRange: dynamicForm.inputsDefinition.startDateRange.defaultValue,
      };

    const methodsForm = useForm<IDefaultValues>({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultValues,
      });

    const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm };

    const [ isOpen, setIsOpen ] = useState(true);

    const toogleFilterSection = () => {
        setIsOpen(!isOpen);
    }

   useEffect(() => {
        setFilterDateRange(selectedRange)
        // eslint-disable-next-line
   }, [selectedRange])

    //define o valor que é para preencher no input ao abrir a página
    const setInitialValue = () => {
        methodsForm.setValue("startDateRange", customRange, {
        shouldDirty: true,
        });
    };

    useEffect(() => {
        setInitialValue();
        // eslint-disable-next-line
    }, []);

    return(<>
        <Grid item xs={6} sx={{marginTop: "32px"}}>
            <FilterSection
                activeFiltersCount={calculateFiltersCount()}
                filterTitle={t("common.filter")}
                onToggle={toogleFilterSection}
                opened
            />
        </Grid>
        <Grid item xs={6} sx={{marginTop: "-30px"}}>
            {isOpen && <DynamicForm formDefinition={formDefinition} />}
        </Grid>
    </>)
}

export default FiltersSection;
