import { LayoutContext } from "@alb/live-lib";

import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";
import CardsTotals from "components/urbanWaste/CardsTotals";
import { CardsView } from "components/urbanWaste/CardsView";

const UrbanWaste = () => {

	const { t } = useTranslation();
	// ------ permissions
	const { hasPermission } = usePermission();
	const canREAD = hasPermission(PERMISSIONS.MODULES.URBANWASTE.READ);

	// ------ header page
	const header = {
		title: t("urbanWaste.title"),
	};
	const { addHeader } = useContext(LayoutContext);

	useEffect(() => {
		addHeader(header);
		// eslint-disable-next-line
	}, []);

	return (
		<>{canREAD && (
			<>
				<CardsTotals />
				<CardsView />
			</>
		)}
		</>
	);
};

export default UrbanWaste;
