/* eslint-disable react-hooks/exhaustive-deps */
import { DynamicForm, FilterSectionPopup, IDynamicInputTemplate, IFormDefinition, IRange, ToggleViewButton, TToggle, TypeDynamicInput } from "@alb/live-lib";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Grid, useTheme } from "@mui/material";
import { debounce } from "lodash";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { BugaTypeKey } from "types/enum";
import { compareDifferentDatesRange } from "utils/date";
import { ColXTemplate } from "utils/templates";

import { BugaFiltersForm, IBugaFiltersFieldsForm } from "./BugaFiltersForm";

interface IDefaultValuesBugaFilters {
  dateRangeCalendar: IRange | undefined;
  searchInput?: string;
}

interface IBugaFilters {
  keyAdapter: BugaTypeKey;
  display: string;
  setDisplay: React.Dispatch<React.SetStateAction<string>>;
  filterDateRange: IRange | undefined;
  setFilterDateRange: React.Dispatch<React.SetStateAction<IRange | undefined>>;
  filterSearch: string;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
  dataFilterForm: IBugaFiltersFieldsForm | null;
  setDataFilterForm: React.Dispatch<
    React.SetStateAction<IBugaFiltersFieldsForm | null>
  >;
}

export const BugaFilters = ({
  keyAdapter,
  display,
  setDisplay,
  filterDateRange,
  setFilterDateRange,
  filterSearch,
  setFilterSearch,
  dataFilterForm,
  setDataFilterForm,
}: IBugaFilters) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  const filterFormID = "filter-buga-form";

  const template = useCallback(
    ({ inputLabel, children }: IDynamicInputTemplate) => {
      return (
        <ColXTemplate
          xsCol={true}
          inputLabel={inputLabel}
          children={children}
        />
      );
    },
    []
  );

  const calculateFiltersCount = useCallback(() => {
    let count = dataFilterForm
      ? Object.values(dataFilterForm).filter((value) => !!value).length
      : 0;
    return count;
  }, [dataFilterForm]);

  const debounceSearch = debounce((cb) => {
    cb();
  }, 350);

  const toggleConfig: TToggle[] = [
    {
      value: "dashboard",
      text: t("sidebar.dashboard"),
    },
    {
      value: "list",
      text: t("map.list.title"),
    },
  ];
  function handleDisplay(
    event: React.MouseEvent<HTMLElement>,
    newDisplay: string | null
  ) {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  }

  const setInitialValue = () => {
    methodsForm.setValue("dateRangeCalendar", filterDateRange, {
      shouldDirty: true,
    });
  };

  const handleOnSelectedRange = (dateRange: {
    startDate: Date;
    endDate: Date;
  }) => {
    if (
      compareDifferentDatesRange(
        filterDateRange,
        dateRange,
        t("calendar.dateFormatGeoAnalytics")
      )
    ) {
      setFilterDateRange(dateRange);
    }
  };

  const handleOnSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    debounceSearch(() => {
      setFilterSearch(e.target.value);
    });
  };

  const predefinedRanges = {
    labelLast30Days: t("calendar.last30Days"),
    labelLast7Days: t("calendar.last7Days"),
    position: "left",
  };
  const dynamicForm = {
    formId: "form-search-buga",
    inputsDefinition: {
      dateRangeCalendar: {
        inputType: TypeDynamicInput.daterange,
        placeholder: t("calendar.placeholderDate"),
        defaultValue: undefined,
        defaultValueField: undefined,
        onSelectedRange: handleOnSelectedRange,
        noOptionsText: t("common.noOptions"),
        template: template,
        locale: i18n.language,
        predefinedRanges: predefinedRanges,
        labelBtnCancel: t("cancel"),
        labelBtnConfirm: t("apply"),
        clearButton: true,
      },
      ...(display === "list" && {
        searchInput: {
          inputType: TypeDynamicInput.text,
          placeholder: t("common.searchBy"),
          endAdornment: <SearchIcon />,
          defaultValue: "",
          handleOnChange: handleOnSearch,
          template: template,
          InputProps: {
            sx: { backgoundColor: "red" },
          },
        },
      }),
    },
  };
  const defaultValues: IDefaultValuesBugaFilters = {
    dateRangeCalendar:
      dynamicForm.inputsDefinition.dateRangeCalendar.defaultValue,
    ...(display === "list" && {
      searchInput: dynamicForm.inputsDefinition.searchInput?.defaultValue,
    }),
  };
  const methodsForm = useForm<IDefaultValuesBugaFilters>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });
  const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm };

  const onToggle = (open: boolean) => {
    setOpen(open);
  };

  const cancelFilterForm = () => {
    setOpen((prev) => !prev);
  };

  const applyFilterForm = () => {
    cancelFilterForm();
  };

  const onSubmit: SubmitHandler<IBugaFiltersFieldsForm> = async (
    payLoad: IBugaFiltersFieldsForm
  ) => {
    setDataFilterForm(payLoad);
  };

  const cleanFieldsFilterForm = () => {
    setDataFilterForm(null);
  };

  useEffect(() => {
    // change values of calendar filter
    setInitialValue();
  }, []);

  useEffect(() => {
    // clear filter search when change display
    if (display === "dashboard" && filterSearch !== "") {
      methodsForm.resetField("searchInput");
      setFilterSearch("");
    }
    if (calculateFiltersCount() !== 0) {
      cleanFieldsFilterForm();
    }
  }, [display]);

  useEffect(() => {
    if (calculateFiltersCount() !== 0) {
      cleanFieldsFilterForm();
    }
  }, [keyAdapter]);

  return (
    <>
      <Grid container>
        <Grid item xs={display === "list" ? 5 : 9}>
          <ToggleViewButton
            size={"medium"}
            display={display}
            toggle={toggleConfig}
            onChange={handleDisplay}
          />
        </Grid>
        <Grid
          item
          xs={display === "list" ? 7 : 3}
          paddingX={1}
          sx={{
            ">form >div >div >div.MuiTextField-root": {
              background: theme.palette.mode === "light" ? "white" : "none",
              borderRadius: "12px",
            },
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <DynamicForm formDefinition={formDefinition} />
          {display === "list" && (
            <div style={{ marginLeft: "16px" }}>
              <FilterSectionPopup
              filterTitle={t("common.filter")}
              isOpen={open}
              onToggle={onToggle}
              children={
                <Box>
                  <BugaFiltersForm
                    keyAdapter={keyAdapter}
                    formID={filterFormID}
                    data={dataFilterForm}
                    numActiveFilters={calculateFiltersCount()}
                    onFormSubmit={onSubmit}
                  />
                  <Grid container item mt={4} justifyContent={"flex-end"}>
                    <Button
                      sx={{ marginRight: "10px" }}
                      variant="outlined"
                      onClick={cancelFilterForm}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type={"submit"}
                      form={filterFormID}
                      onClick={applyFilterForm}
                    >
                      {t("apply")}
                    </Button>
                  </Grid>
                </Box>
              }
              activeFiltersCount={calculateFiltersCount()}
            />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};
