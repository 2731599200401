/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonsGroup, ConfirmDialog, DrawOption, IButtonsGroupList, IDrawCoordinates, IMap, IMapPoint, IMapTranslation, IMediaFile, InputMedia, Loading, Map, MapMode, TileType } from "@alb/live-lib";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MapIcon from "@mui/icons-material/Map";
import { Backdrop, Box, Grid, IconButton, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import classNames from "classnames";
import isEqual from "fast-deep-equal";
import { LatLngExpression } from "leaflet";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Control, useFieldArray, useFormContext } from "react-hook-form";
import Carousel from "react-multi-carousel";
import { IOccurrenceForm } from "types/interfaces";
import { TUser } from "types/types";
import { v4 as uuid } from "uuid";

import NoData from "components/Utils/NoData";
import { arcgisKey } from "utils/keys";

import "react-multi-carousel/lib/styles.css";

interface IUpdateDataMedia {
  id: string;
  url: string;
}
interface IUpdateURLMedia {
  loading: boolean;
  error?: AxiosError<any, any> | null;
  data?: IUpdateDataMedia;
}

interface IOccurrenceMapAndMedia {
  // map
  user: TUser;
  markers?: IMapPoint[];
  mapTranslations: IMapTranslation;
  drawCoordinates?: IDrawCoordinates | null;
  onDrawCoordinatesChange?: (coordinates: IDrawCoordinates | null) => void;
  // media and form
  limitMedia: number;
  deleteConfirmTranslations: IDeleteMediaTranslations;
  onDeleteMedia?: (media: IMediaFile) => void;
  updateURLMedia?: IUpdateURLMedia;
  onUpdateURLMedia?: (id: string | undefined) => void;
  control: Control<IOccurrenceForm>;
  formReset?: boolean;
  onFormReset?: Dispatch<SetStateAction<boolean>>;
  editMode: boolean;
}
interface IMediaList {
  id?: string;
  url?: string;
  icon: JSX.Element;
  type: "video" | "image" | "map" | "input";
}
interface IDeleteMediaTranslations {
  title: string;
  message: string;
  actionConfirmText: string;
  actionCancelText: string;
}
export const OccurrenceMapAndMedia = ({
  markers,
  mapTranslations,
  drawCoordinates,
  onDrawCoordinatesChange,
  user,
  limitMedia,
  deleteConfirmTranslations,
  onDeleteMedia,
  updateURLMedia,
  onUpdateURLMedia,
  control,
  formReset,
  onFormReset,
  editMode,
}: IOccurrenceMapAndMedia) => {
  const theme = useTheme();

  const nameInput = "media";
  const { getValues } = useFormContext();
  const { update, remove } = useFieldArray({ name: nameInput });

  const carouselRef = useRef<any>();

  const modeTheme = theme.palette.mode;
  const colorNeutral5 = theme.palette.neutral5.main;
  const initialZoom = 13;
  const mapTile = user.extra_params?.map_tile_layer || TileType.default;
  const darkMode = user.extra_params?.dark_mode;
  const defaultArgsMap = {
    arcgisKey: arcgisKey,
    mapZoom: initialZoom,
    initialZoom: initialZoom,
    mapTileType: mapTile,
    appDarkThemeMode: darkMode,
    mapVariant: "component" as "component",
    mapModule: false,
    showZoom: true,
    language: user.language.toLowerCase() || "pt",
    mapTranslations: mapTranslations,
    dynamicAttribution: true
  };
  const defaultPhotosVideoList: IMediaList[] =
    getValues(nameInput) && getValues(nameInput).length > 0
      ? getValues(nameInput).map((elem: IMediaFile) => {
          return {
            id: elem.id,
            icon: showInputMedia(handleMediaAdded, elem.id),
            url: elem.url,
            type: elem.media_type,
          };
        })
      : [];
  const defaultMediaList: IMediaList[] = [
    {
      icon: <MapIcon sx={{ color: colorNeutral5, fontSize: "50px" }} />,
      type: "map",
    },
    ...defaultPhotosVideoList,
  ];
  const argsInput: IMediaList = {
    icon: showInputMedia(handleMediaAdded),
    type: "input",
  };
  const videoExpiration = 5000 * 60;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [argsMap, setArgsMap] = useState<IMap | null>(null);
  const [media, setMedia] = useState<IMediaList[]>(defaultMediaList);
  const [selectedMedia, setSelectedMedia] = useState<IMediaList>(media[0]);
  const [openBackdrop, setOpenBackdrop] = useState<boolean>(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<boolean>(false);

  // it's 3 because there are a map or a input
  const disabledMoveMedia = media.some((item) => item.type === "input")
    ? media.length <= 3
    : media.length < 3;
  const buttonsDetail: IButtonsGroupList[] = [
    {
      icon: <KeyboardArrowLeftIcon sx={{ color: "white" }} />,
      disabled: disabledMoveMedia,
      onClick: () => {
        moveMedia("previous");
      },
    },
    {
      icon: <KeyboardArrowRightIcon sx={{ color: "white" }} />,
      disabled: disabledMoveMedia,
      onClick: () => {
        moveMedia("next");
      },
    },
    ...(editMode
      ? [
          {
            icon: <DeleteIcon sx={{ color: "white" }} />,
            onClick: () => {
              onClickDeleteMedia();
            },
          },
        ]
      : []),
  ];

  function handleMediaAdded(mediaAdded: IMediaFile) {
    const valueNewMedia = getValues(nameInput)[getValues(nameInput).length - 1];
    const noValue = mediaAdded.url.includes("data:image/") ? "image" : "video";
    const newMedia: IMediaList = {
      id: mediaAdded.id,
      icon: showInputMedia(handleMediaAdded, mediaAdded.id),
      url: mediaAdded.url,
      type: valueNewMedia?.media_type || noValue,
    };

    let data = media;
    if (media.length <= limitMedia) {
      data.splice(media.length - 1, 0, newMedia);
    } else {
      data.pop(); // remove input for list of media
      data.push(newMedia);
    }
    setMedia(data);
  }

  function addInputMedia() {
    if (
      media.length <= limitMedia &&
      !media.some((elem) => elem.type === "input")
    ) {
      let data = media;
      data.push(argsInput);
      setMedia(data);
    }
  }

  function showInputMedia(
    handleMediaAdded: (media: IMediaFile) => void,
    id?: string
  ) {
    return (
      <InputMedia
        name={nameInput}
        fileTypes={["photo", "video"]}
        idMedia={id}
        control={control}
        multiple
        onMediaAdded={handleMediaAdded}
      />
    );
  }

  const CustomDot = ({ index, onClick, active, media }: any) => {
    return (
      <button
        type="button"
        onClick={(e) => {
          if (media[index].type !== "input") {
            onClick();
            e.preventDefault();
          }
        }}
        className={classNames("custom-dot", {
          "custom-dot--active": active,
          "add-input": media.some((elem: IMediaList) => elem.type === "input"),
          ["custom-dot--" + modeTheme]: media[index].type === "map",
        })}
      >
        {media[index].icon}
      </button>
    );
  };

  function getMapCenter(user: TUser, markers: IMapPoint[]) {
    if (markers.length > 0) {
      return markers[0].geolocation.iconPosition as LatLngExpression;
    } else if (user.client && user.client.center_point) {
      return user.client.center_point as LatLngExpression;
    } else {
      return undefined;
    }
  }

  function showMap(argsMap: IMap) {
    return (
      <Box
        className="map-component"
        sx={{
          height: "473px",
          ".leaflet-container": {
            borderRadius: "12px",
          },
        }}
      >
        <Map {...argsMap} />
      </Box>
    );
  }

  function showMedia(
    item: IMediaList,
    onClickMedia?: boolean,
    fullScreen?: boolean
  ) {
    switch (item.type) {
      case "image":
        return (
          <img
            src={item.url}
            alt={item.type}
            width={"100%"}
            height={"100%"}
            onClick={() => {
              if (onClickMedia) {
                onClickMediaCarousel(item);
              }
            }}
          />
        );
      case "video":
        let urlVideo = item.url;
        if (updateURLMedia?.data?.url && updateURLMedia?.data?.id === item.id) {
          urlVideo = updateURLMedia.data.url;
        }
        return updateURLMedia?.loading || updateURLMedia?.error ? (
          <>
            <Loading
              show={updateURLMedia.loading}
              containerSx={fullScreen ? { width: "550px" } : undefined}
            />
            {!updateURLMedia?.loading && (
              <NoData error={updateURLMedia.error} />
            )}
          </>
        ) : (
          <video
            width="100%"
            height="100%"
            {...(fullScreen
              ? {
                  controls: true,
                }
              : {
                  autoPlay: true,
                  muted: true,
                })}
            onClick={() => {
              if (onClickMedia && !fullScreen) {
                onClickMediaCarousel(item);
              }
            }}
          >
            <source src={urlVideo} />
          </video>
        );
    }
  }

  function ignoreMapAndInput(
    index: number,
    list: IMediaList[],
    typeMove: "previous" | "next"
  ) {
    const inputAddMedia = list.some((elem) => elem.type === "input");
    if (typeMove === "previous") {
      if (index === 0) {
        return inputAddMedia ? list.length - 2 : list.length - 1;
      }
      if (inputAddMedia && index === list.length - 1) {
        return list.length - 2;
      }
    }
    if (typeMove === "next") {
      if (index === 0) {
        return index + 1;
      }
      if (inputAddMedia && index === list.length - 1) {
        return 1;
      }
    }
    return index;
  }

  function moveMedia(type: "previous" | "next") {
    const currentIndex = media.indexOf(selectedMedia);
    let newIndex: number = 0;

    if (type === "previous") {
      const previousIndex = (currentIndex + media.length - 1) % media.length;
      newIndex = ignoreMapAndInput(previousIndex, media, type);
    }
    if (type === "next") {
      const nextIndex = (currentIndex + 1) % media.length;
      newIndex = ignoreMapAndInput(nextIndex, media, type);
    }

    setSelectedMedia(media[newIndex]);
    carouselRef?.current?.goToSlide(newIndex);
  }

  function onClickMediaCarousel(item: IMediaList) {
    setOpenBackdrop(true);
    setSelectedMedia(item);
  }

  function onClickDeleteMedia() {
    setOpenDeleteConfirm(true);
  }

  function onCancelDeleteMedia() {
    setOpenDeleteConfirm(false);
  }

  function onConfirmDeleteMedia() {
    onCancelDeleteMedia();
    deleteMedia();
  }

  function deleteMedia() {
    // delete media of carousel
    const currentIndex = media.indexOf(selectedMedia);
    const data = media;
    data.splice(currentIndex, 1);
    if (
      data.length <= limitMedia &&
      !data.some((elem) => elem.type === "input")
    ) {
      data.push(argsInput);
    }
    setMedia(data);
    // change value of control
    const deleteMediaControl = getValues(nameInput).find(
      (elem: IMediaFile) => elem.id === selectedMedia.id
    );
    const indexControl = getValues(nameInput).indexOf(deleteMediaControl);
    remove(indexControl);
    // send removed media for BE
    !deleteMediaControl.url.startsWith("data:") &&
      onDeleteMedia &&
      onDeleteMedia(deleteMediaControl);
    // show carousel on map
    carouselRef?.current?.goToSlide(0);
    setSelectedMedia(data[0]);
    setOpenBackdrop(false);
  }

  useEffect(() => {
    // add input for add new photo or video
    if (editMode) {
      addInputMedia();
    }
    // show data for map
    const points = !markers ? [] : markers;
    const mapCenter = getMapCenter(user, points);
    setArgsMap({
      idMap: uuid(),
      ...defaultArgsMap,
      mapCenterPoint: mapCenter,
      initialMapCenter: mapCenter,
      mapMode: editMode ? MapMode.edit : MapMode.view,
      points: editMode ? [] : points,
      ...(editMode && {
        drawCoordinates: drawCoordinates,
        onDrawCoordinatesChange: onDrawCoordinatesChange,
        drawOptions: [DrawOption.marker],
      }),
    });
  }, [editMode]);

  useEffect(() => {
    // só existe 1 video em cada ocorrencia
    const idVideo = media.find(
      (item) => item.type === "video" && item.url?.startsWith("http")
    )?.id;
    if (idVideo) {
      const intervalId = setInterval(() => {
        onUpdateURLMedia && onUpdateURLMedia(idVideo);
      }, videoExpiration);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [videoExpiration]);

  useEffect(() => {
    if (updateURLMedia?.data && !updateURLMedia?.loading) {
      const updateMedia = updateURLMedia.data;
      const video = getValues(nameInput).find(
        (item: IMediaFile) => item.id === updateMedia.id
      );
      const indexItem = getValues(nameInput).indexOf(video);
      const replaceItem = {
        ...video,
        url: updateMedia.url,
      };
      // update control for inputs of Carousel
      update(indexItem, replaceItem);
    }
  }, [updateURLMedia?.data?.url]);

  useEffect(() => {
    // when the cancel button is clicked and the values have been changed
    if (formReset) {
      const currentValues = media
        .filter((item) => item.type !== "map" && item.type !== "input")
        .map((elem) => {
          return {
            id: elem.id,
            media_type: elem.type,
            url: elem.url,
          };
        });
      if (!isEqual(currentValues, getValues(nameInput))) {
        setMedia(defaultMediaList);
      } else if (media.some((elem) => elem.type === "input")) {
        const data = media;
        data.pop();
        setMedia(data);
      }
      onFormReset && onFormReset(false);
    }
  }, [formReset]);

  return (
    <>
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        swipeable={false}
        draggable={false}
        showDots
        renderDotsOutside
        arrows={false}
        className="carousel-media"
        customDot={<CustomDot media={media} />}
        dotListClass="carousel-dots-list-vertical"
      >
        {media.map((item: IMediaList, index: number) => {
          return (
            <div className="carousel-slider-media" key={index}>
              {item.type === "map" && argsMap && showMap(argsMap)}
              {(item.type === "image" || item.type === "video") &&
                showMedia(item, true)}
            </div>
          );
        })}
      </Carousel>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <Grid container direction="column" alignItems="center" rowSpacing={2}>
          <Grid item xs="auto">
            <IconButton
              className="noShadow"
              size="large"
              sx={{
                position: "fixed",
                right: 0,
                top: 0,
              }}
              onClick={() => {
                setOpenBackdrop(false);
              }}
            >
              <CloseIcon sx={{ color: "white" }} />
            </IconButton>
          </Grid>
          <Grid item height="659px" xs="auto">
            {selectedMedia && showMedia(selectedMedia, false, true)}
          </Grid>
          <Grid item xs="auto">
            <ButtonsGroup
              buttons={buttonsDetail}
              colorBtn={colorNeutral5}
              sizeBtn="small"
            />
          </Grid>
        </Grid>
      </Backdrop>
      {openDeleteConfirm && (
        <ConfirmDialog
          open={openDeleteConfirm}
          type="error"
          {...deleteConfirmTranslations}
          loading={false}
          onCancel={onCancelDeleteMedia}
          onConfirm={onConfirmDeleteMedia}
        />
      )}
    </>
  );
};
