import { IRange, TableHeadOrder } from "@alb/live-lib";
import { Grid } from "@mui/material";
import { AxiosError } from "axios";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { IDataStatus } from "pages/ElectricMobility";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { formatOrderBy } from "utils/orderByAPI";
import { arrayIsEmpty } from "utils/utils";

import TableInformation from "./TableInformation";

interface IList {
	filterName?: string;
	filterDateRange?: IRange | undefined;
	setListDataStatus: Dispatch<SetStateAction<IDataStatus>>
	setErrorRequest: Dispatch<SetStateAction<AxiosError<any, any> | null>>;
}

const List = ({ filterName, filterDateRange, setListDataStatus, setErrorRequest }: IList) => {
	const { t } = useTranslation();
	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");
	const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

	const getParams = () => {
		var params: {
			page: number;
			items: number;
			order_by: string;
			contains?: string;
		} = {
			page: page,
			items: ROWS_PER_PAGE,
			order_by: formatOrderBy(order, orderBy),
			...(filterName !== "" && { name: filterName }),
			...(filterDateRange && {
				start_date_interval: Object.values(filterDateRange)
					.map((date: Date) => formatDate(date, t("calendar.dateTimeFormat")))
					.join(","),
			}),
		};

		return params;
	};

	function reset() {
		setPage(1);
		setOrder("asc");
		setOrderBy("name");
	}

	useEffect(() => {
		reset();
	}, [filterName]);

	const { data: chargingTransactions, loading, error } = useGet(
		ServiceApiUrl.chargingTransactions,
		getParams(),
		undefined,
		headers
	);

	let list;

	if (
		!loading &&
		chargingTransactions &&
		!arrayIsEmpty(chargingTransactions?.data)
	) {
		list = (
			<TableInformation
				data={chargingTransactions}
				orderBy={orderBy}
				order={order}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				page={page}
				setPage={setPage}
			/>
		);
	}

	useEffect(() => {
		if (!loading) {
			if (arrayIsEmpty(chargingTransactions?.data)) {
				setListDataStatus({
					isLoading: false,
					hasData: false
				})
			} else {
				setListDataStatus({
					isLoading: false,
					hasData: true
				})
			}
		} else {
			setListDataStatus({
				isLoading: true,
				hasData: false
			})
		}
		// eslint-disable-next-line
	}, [chargingTransactions, loading])

	useEffect(() => {
		setErrorRequest(error);
		// eslint-disable-next-line
	}, [error]);

	return (
		<Grid item mt={4}>
			{list}
		</Grid>
	);
};

export default List;
