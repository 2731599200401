import { Loading } from "@alb/live-lib";
import { Typography, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import ReactEcharts from "echarts-for-react";
import { useTranslation } from "react-i18next";

import { defaultOptions } from "components/charts/defaultOptions";
import NoData from "components/Utils/NoData";
import { IParkingChartData } from "pages/Parking";
import { formatNumber } from "utils/number";

const ParkingPieChart = (props: {
	data: IParkingChartData[] | undefined;
	loading: boolean;
	error: AxiosError<any, any> | null;
}) => {
	const theme = useTheme();
	const { data, loading, error } = props;
	const { t } = useTranslation();

	let option = {
		tooltip: {
			confine: true,
			extraCssText: "white-space:inherit;",
			trigger: "item",
			backgroundColor: theme.palette.background.paper,
			textStyle: {
				color: theme.palette.greyColor,
			},
			formatter: function (value: any) {
				return `<b>${value.name}</b>: ${formatNumber(value.data.value)}%<br/>`;
			},
		},
		legend: {
			show: true,
			orient: "horizontal",
			bottom: 0,
			itemGap: 40,
			textStyle: {
				color: theme.palette.text.primary,
				fontFamily: theme.typography.fontFamily,
				fontSize: 13,
			},
		},
		series: [
			{
				bottom: "20%",
				color: defaultOptions.color,
				type: "pie",
				radius: ["30%", "80%"],
				avoidLabelOverlap: false,
				label: {
					show: false,
					position: "center",
				},
				emphasis: {
					label: {
						show: false,
					},
				},
				labelLine: {
					show: false,
				},
				data: data,
			},
		],
	};
	return (
		<>
			<Typography variant="h3" fontSize={"22px"}>
				{t("parking.occupationPercentage")}
			</Typography>

			{!loading && !data && <NoData error={error} />}
			<Loading show={loading} />

			{!loading && data && (
				<>
					<Typography color="text.secondary" variant="caption">
						{t("parking.lastReadValues")}
					</Typography>
					<ReactEcharts style={{ height: "480px" }} option={option} />
				</>
			)}
		</>
	);
};

export default ParkingPieChart;
