import { HeatmapOptions, IHeatmapColor, Loading } from "@alb/live-lib";
import { Card, CardContent, Typography } from "@mui/material";
import { AxiosError } from "axios";

import { ColorRangeLegend } from "components/map/controls/ColorGradientLegend";
import NoData from "components/Utils/NoData";
import { maxValueLegend, minValueLegend, rangeColorsHeatMap } from "utils/heatMap";
import { arrayIsEmpty } from "utils/utils";

interface IHeatMapLegend {
  title: string;
  loading: boolean;
  error?: AxiosError<any, any> | null;
  rangeColors: IHeatmapColor[];
  coordinates: HeatmapOptions<number> | null;
  mapBounds: number[][];
}
export const HeatMapLegend = ({
  title,
  loading,
  error,
  rangeColors,
  coordinates,
  mapBounds,
}: IHeatMapLegend) => {
  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h3" fontSize={"18px"}>
            {title}
          </Typography>
          <Loading show={loading} />
          {arrayIsEmpty(coordinates?.points) && !loading && (
            <NoData error={error} />
          )}
          {!arrayIsEmpty(coordinates?.points) &&
            !arrayIsEmpty(mapBounds) &&
            !loading && (
              <ColorRangeLegend
                colors={rangeColorsHeatMap(rangeColors)}
                maxValue={maxValueLegend(coordinates, mapBounds)}
                minValue={minValueLegend(coordinates, mapBounds)}
              />
            )}
        </CardContent>
      </Card>
    </>
  );
};
