import {
	Loading,
	TableHeadCell,
	TableHeadOrder,
	TableHeadSort,
} from '@alb/live-lib'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import EditIcon from '@mui/icons-material/Edit'
import EmailRoundedIcon from '@mui/icons-material/EmailRounded'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import {
	Box,
	IconButton,
	Stack,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TUser } from 'types/types'

import { ChipStatus } from 'components/Utils/ChipStatus'
import { ROWS_PER_PAGE } from 'components/Utils/Pagination/pagination'
import { CustomTablePagination } from 'components/Utils/Pagination/CustomTablePagination'
import useGet from 'hooks/fetchData/useGet'
import usePermission from 'hooks/usePermission'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { TableLink } from 'styles/css/components'
import { formatDate } from 'utils/date'
import { formatOrderBy } from 'utils/orderByAPI'
import { PERMISSIONS } from 'utils/permissions/permissions'
import { statusColor, arrayIsEmpty } from 'utils/utils'

import { FiltersSection } from './FiltersSection'
// import UserDetail from './UserDetail'
import UserInvite from './UserInvite'
import UsersDelete from './UsersDelete'
import UsersEdit from './UsersEdit'
import { useSelector } from 'react-redux'
import { getUser } from 'store/slices/authSlice'

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { formatRoutePath } from 'utils/routePath'
import PersonOffIcon from '@mui/icons-material/PersonOff';

function Row(props: {
	row: TUser
	actionsClick: {
		detailAction: Function
		editAction: Function
		deleteAction: Function
		inviteAction: Function
	}
}) {
	const { row, actionsClick } = props
	const { detailAction, editAction, deleteAction, inviteAction } = actionsClick
	const { t } = useTranslation()
	const user = useSelector(getUser)
	const idAdminOrSuperAdmin = user?.is_admin || user?.is_superadmin;

	// PERMISSIONS
	const { hasPermission } = usePermission()
	const canUpdate = hasPermission(PERMISSIONS.MANAGEMENT.USERS.UPDATE)
	const canDelete = hasPermission(PERMISSIONS.MANAGEMENT.USERS.DELETE)
	const canInvite = user.is_superadmin || user.is_admin

	return (
		<>
			<TableRow
				onClick={() => detailAction(true, row)}
				sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			>
				<TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>
				<TableCell>
					<Stack direction="row" alignItems="flex-end" gap={1}>
						{row.username}
						{(row.is_superadmin || row.is_admin) && (
							<SupervisorAccountIcon
								fontSize="small"
								color={row.is_superadmin ? 'primary' : 'inherit'}
								titleAccess={
									row.is_superadmin
										? t('user.superAdmin')
										: row.is_admin
											? t('user.admin')
											: ''
								}
							/>
						)}
					</Stack>
				</TableCell>
				<TableCell>
					<ChipStatus
						label={t(`user.status.${row.status}`)}
						color={statusColor(row.status)}
					/>
				</TableCell>
				{idAdminOrSuperAdmin && (
          			<TableCell>
           				 <Stack direction="row" alignItems="flex-end" gap={1}>
             				 {row?.account ? row?.account?.name : <PersonOffIcon
               				 fontSize="small"
                			 titleAccess={ t("user.noAccountUser")} />}
            			</Stack>
        			  </TableCell>
        		)}
				<TableCell>
					{formatDate(row.created_at, t('calendar.dateTimeFormatLocal'))}
				</TableCell>
				<TableCell>
					{formatDate(row.updated_at, t('calendar.dateTimeFormatLocal'))}
				</TableCell>

				<TableCell align={'right'} sx={{ whiteSpace: 'nowrap' }}>
					{canUpdate && (
						<IconButton
							title={t('common.edit')}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								editAction(true, e, row)
							}}
							disabled={
								row.status !== 'deleted' && !row.is_superadmin ? false : true
							}
						>
							<EditIcon />
						</IconButton>
					)}
					{canDelete && (
						<IconButton
							title={t('common.delete')}
							aria-label="delete"
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								deleteAction(true, e, row)
							}}
							disabled={
								row.status !== 'deleted' && !row.is_superadmin ? false : true
							}
						>
							<DeleteIcon />
						</IconButton>
					)}
					{canInvite && (
						<IconButton
							title={t('user.reinviteUser')}
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
								inviteAction(true, e, row)
							}
							disabled={
								!row.last_pwd_update || row.status === 'deleted' ? false : true
							}
						>
							<EmailRoundedIcon />
						</IconButton>
					)}
				</TableCell>
			</TableRow>
		</>
	)
}

function UsersList(props: { refetch?: boolean }) {
	const { refetch } = props;

	const { t } = useTranslation()
	const params = useParams()
	const location = useLocation()
	const navigate = useNavigate()
	const user = useSelector(getUser)
	const idAdminOrSuperAdmin = user?.is_admin || user?.is_superadmin;


	const [page, setPage] = useState(1)
	const [order, setOrder] = useState<TableHeadOrder>('asc')
	const [orderBy, setOrderBy] = useState<string>('full_name')

	const [filterSearch, setFilterSearch] = useState<string>('')
	const [filterStatus, setFilterStatus] = useState<string>('')

	const {
		data: users,
		loading,
		refetch: refetchUsers,
	} = useGet(ServiceApiUrl.user, getParams())

	useEffect(() => {
		if (refetch) refetchUsers();
		// eslint-disable-next-line
	}, [refetch]);

	function orderByAPI() {
		let _orderBy = formatOrderBy(order, orderBy)
		if (orderBy === 'full_name') {
			_orderBy = ['first_name', 'last_name']
				.map((o) => (order === 'desc' ? '-' + o : o))
				.join(',')
		}
		return _orderBy
	}

	function getParams() {
		const params: {
			page: number
			items: number
			order_by: string
			contains?: string
			status?: string
		} = {
			page: page,
			items: ROWS_PER_PAGE,
			order_by: orderByAPI(),
			...(filterSearch !== '' && { contains: filterSearch }),
			...(filterStatus !== '' && { status: filterStatus }),
		}

		return params
	}

	//para fazer nova chamada dos Módulos
	const updateUsers = () => {
		refetchUsers()
	}

	// PERMISSIONS
	const { hasPermission } = usePermission()
	const crudPermissions = hasPermission(PERMISSIONS.MANAGEMENT.CREATE)
	const canList = hasPermission(PERMISSIONS.MANAGEMENT.READ)

	const [selectedUser, setSelectedUser] = useState<TUser>()
	const [openDialogEdit, setOpenDialogEdit] = useState(false)
	const [openDialogDelete, setOpenDialogDelete] = useState(false)
	const [openDialogInvite, setOpenDialogInvite] = useState(false)

	//redireciona para a pagina de detalhe do user
	const navigateToDetailPage = (user: TUser) => {
		navigate(
			formatRoutePath(location, params, {
				id: user.id,
			})
		)
	}

	//atualiza o estado do modal de remover
	const handlerDialogDeleteUser = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: TUser
	) => {
		if (open) {
			e.stopPropagation() //evitar que abra o event do parent (a row)
			setSelectedUser(user)
		}
		setOpenDialogDelete(!openDialogDelete)
	}

	//atualiza o estado do modal de editar
	const handlerDialogEditUser = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: TUser
	) => {
		if (open) {
			e.stopPropagation() //evitar que abra o event do parent (a row)
			setSelectedUser(user)
		}
		setOpenDialogEdit(!openDialogEdit)
	}

	const handlerDialogInvite = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		user: TUser
	) => {
		if (open === true) {
			//nesta situação, chega um evento
			e.stopPropagation() //evitar que abra o event do parent (a row)
			setSelectedUser(user) //troca o user pelo escolhido
		}
		setOpenDialogInvite(!openDialogInvite)
	}

	//Trocar página da listagem
	const handleChangePage = (newPage: number) => {
		setPage(newPage + 1) //porque o index da paginação do mui inicia no 0
	}

	const tableHeads: TableHeadCell[] = [
		{
			id: 'full_name',
			label: t('common.name'),
		},
		{
			id: 'username',
			label: t('user.Username'),
		},
		{
			id: 'status',
			label: t('common.statusLabel'),
		},
		...(idAdminOrSuperAdmin
			? [{ id: "account", label: t("user.account") }]
			: []),
		{
			id: 'created_at',
			label: t('common.createdAt'),
		},
		{
			id: 'last_login',
			label: t('common.updatedAt'),
		},
		{
			id: 'action_cell',
			label: '',
			notSortable: true,
		},
	]

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	return (
		<>
			<Box marginTop={2}>
				<FiltersSection
					setPage={setPage}
					setFilterSearch={setFilterSearch}
					setFilterStatus={setFilterStatus}
				/>
			</Box>

			{loading && <Loading show={loading} />}

			{arrayIsEmpty(users?.data) && !loading && canList && (
				<Typography mt={4} mb={2} variant="body1">
					{t('user.noUsers')}
				</Typography>
			)}

			{users && !arrayIsEmpty(users?.data) && !loading && (
				<>
					<TableContainer>
						<TableLink>
							<TableHeadSort
								headCells={tableHeads}
								onRequestSort={handleRequestSort}
								order={order}
								orderBy={orderBy}
							/>
							<TableBody>
								{users?.data?.map((user: TUser, index: number) => (
									<Row
										key={user.id}
										row={user}
										actionsClick={{
											detailAction: () => navigateToDetailPage(user),
											editAction: handlerDialogEditUser,
											deleteAction: handlerDialogDeleteUser,
											inviteAction: handlerDialogInvite,
										}}
									/>
								))}
							</TableBody>
						</TableLink>
					</TableContainer>

					<CustomTablePagination
						count={users?.totalCount}
						page={page}
						totalPages={users?.totalPages}
						onPageChange={handleChangePage}
					/>
				</>
			)}
			{selectedUser && (
				<>
					{crudPermissions && (
						<>
							{openDialogDelete && (
								<UsersDelete
									user={selectedUser}
									open={openDialogDelete}
									handlerClose={() => setOpenDialogDelete(false)}
									updateList={updateUsers}
								/>
							)}
							{openDialogEdit && (
								<UsersEdit
									user={selectedUser}
									open={openDialogEdit}
									onClose={() => setOpenDialogEdit(false)}
									updateList={updateUsers}
								/>
							)}
							{openDialogInvite && (
								<UserInvite
									user={selectedUser}
									open={openDialogInvite}
									handlerClose={handlerDialogInvite}
									updateGetUsers={updateUsers}
								/>
							)}
						</>
					)}
				</>
			)}
		</>
	)
}

export default UsersList
