import { BackToButton, LayoutContext } from "@alb/live-lib";
import { Grid, IconButton, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRoutePath } from "utils/routePath";
import { GridWhiteContainer } from "styles/css/components";
import { PERMISSIONS } from "utils/permissions/permissions";
import usePermission from "hooks/usePermission";

const EventsAdministration = () => {
  const { addHeader } = useContext(LayoutContext);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const { hasPermission } = usePermission();
  const canReadEvents = hasPermission(PERMISSIONS.MODULES.EVENTS.READ);

  const header = {
    title: t("events.events"),
    backTo: <BackToButton title={t("goBack")} onClick={() => navigate(-1)} />,
  };

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line
  }, []);

  function navigateTo(page: string) {
    const path = getRoutePath(location, params);
    navigate(`${path}/${page}`);
  }

  return (
    <>
      {canReadEvents && (
        <Grid container mt={5} spacing={2}>
          <Grid item xs={4}>
            <GridWhiteContainer
              onClick={() => navigateTo("types")}
              sx={{ cursor: "pointer", display: "flex" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">{t("events.eventTypes")}</Typography>
              <IconButton color="secondary" size="large">
                <ArrowForwardRoundedIcon />
              </IconButton>
            </GridWhiteContainer>
          </Grid>

          <Grid item xs={4}>
            <GridWhiteContainer
              onClick={() => navigateTo("categories")}
              sx={{ cursor: "pointer", display: "flex" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">{t("events.categories")}</Typography>
              <IconButton color="secondary" size="large">
                <ArrowForwardRoundedIcon />
              </IconButton>
            </GridWhiteContainer>
          </Grid>

          <Grid item xs={4}>
            <GridWhiteContainer
              onClick={() => navigateTo("status")}
              sx={{ cursor: "pointer", display: "flex" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">{t("events.eventStates")}</Typography>
              <IconButton color="secondary" size="large">
                <ArrowForwardRoundedIcon />
              </IconButton>
            </GridWhiteContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default EventsAdministration;
