import { TableCell, TableRow, IconButton, Stack } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChipStatus } from "components/Utils/ChipStatus";
import { TAccount } from "types/types";
import { statusColor } from "utils/utils";
import { formatDate } from "utils/date";
import { PERMISSIONS } from "utils/permissions/permissions";
import usePermission from "hooks/usePermission";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import AccountsDelete from "components/management/accounts/AccountsDelete";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";

const AccountsTableRow = (props: {
	row: TAccount;
	onRowClick: (row: TAccount) => void;
	refetch: () => void;
}) => {
	const { row, onRowClick, refetch } = props;
	const { t } = useTranslation();
	const { hasPermission } = usePermission();
  const user = useSelector(getUser)

	const canDelete = hasPermission(PERMISSIONS.MANAGEMENT.ACCOUNTS.DELETE);
	const [selectedAccount, setSelectedAccount] = useState<TAccount>();
	const [openDialogDelete, setOpenDialogDelete] = useState(false);

	//atualiza o estado do modal de remover
	const handlerDialogDeleteAccount = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		account: TAccount
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedAccount(account);
		}
		setOpenDialogDelete(!openDialogDelete);
	};

	const updateAccounts = () => {
		refetch();
	};

    //Só os superAdmins podem eliminar contas admin
    const hasPermitionToDelete = row.admin
    ? user.is_superadmin && canDelete
    : canDelete;

	return (
		<>
			<TableRow
				sx={{ cursor: "pointer" }}
				onClick={(e): void => {
					e.stopPropagation();
					onRowClick(row);
				}}
			>
				<TableCell>
					<Stack direction="row" alignItems="flex-end" gap={1}>
						{row.name}
						{row.admin && (
							<AdminPanelSettingsIcon
								fontSize="small"
								color={"primary"}
								titleAccess={t("accounts.adminLabel")}
							/>
						)}
					</Stack>
				</TableCell>
				<TableCell>
					<ChipStatus
						label={row.is_active ? t("common.active") : t("common.inactive")}
						color={
							row.is_active ? statusColor("active") : statusColor("non-active")
						}
					/>
				</TableCell>
				<TableCell>
					{formatDate(row.created_at, t("calendar.dateTimeFormatLocal"))}
				</TableCell>

				<TableCell>
					{formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
				</TableCell>

				<TableCell align="right">
					{hasPermitionToDelete && (
						<IconButton
							title={t("common.delete")}
							aria-label="delete"
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
								handlerDialogDeleteAccount(true, e, row)
							}>
							<DeleteIcon />
						</IconButton>
					)}
				</TableCell>
			</TableRow>

			{hasPermitionToDelete && selectedAccount && openDialogDelete && (
				<AccountsDelete
					account={selectedAccount}
					open={openDialogDelete}
					handlerClose={() => setOpenDialogDelete(false)}
					updateList={updateAccounts}
				/>
			)}
		</>
	);
};

export default AccountsTableRow;
