import { DynamicForm, FilterSection, IDynamicInputTemplate, IFormDefinition, TypeDynamicInput } from "@alb/live-lib";
import { Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";

import { stringIsEmpty } from "utils/utils";

interface ISearch {
	type: string | null;
	read: string | null;
}
interface IColXTemplate extends IDynamicInputTemplate {
	xsCol: 'auto' | number | boolean
}
const ColXTemplate = ({ inputLabel, children, xsCol }: IColXTemplate) => {

	return (<Grid item xs={xsCol}>
		{inputLabel && <Typography gutterBottom variant="h6">
			{inputLabel}
		</Typography>
		}
		{children}
	</Grid>)
}

export const FiltersSection = (props: {
	setPage: React.Dispatch<React.SetStateAction<number>>;
	setFilterType: React.Dispatch<React.SetStateAction<string>>;
	setFilterRead: React.Dispatch<React.SetStateAction<string>>;
}) => {
	const { t } = useTranslation();
	const { setPage, setFilterType, setFilterRead } = props;

	const [filtersCount, setFiltersCount] = useState<number>(0);
	const [type, setType] = useState<string>("");
	const [read, setRead] = useState<string>("");

	const handleChangeType = (
		e: SyntheticEvent<Element, Event>,
		value: ISelectOption
	) => {
		setPage(1);
		//se existir opção selecionada
		if (value && value.value) {
			//se o valor anterior for vazio
			if (stringIsEmpty(type)) {
				addFilterCount(); //adiciona à contagem de filtros
			}
			setType(value.value);
		}
		//se não existir opção selecionada
		else {
			removeFilterCount(); //se for removida a opção do select, remove da contagem do filtro
			setType("");
		}
	};
	const handleChangeRead = (
		e: SyntheticEvent<Element, Event>,
		value: ISelectOption
	) => {
		setPage(1);
		//se existir opção selecionada
		if (value && value.value) {
			//se o valor anterior for vazio
			if (stringIsEmpty(read)) {
				addFilterCount(); //adiciona à contagem de filtros
			}
			setRead(value.value);
		}
		//se não existir opção selecionada
		else {
			removeFilterCount(); //se for removida a opção do select, remove da contagem do filtro
			setRead("");
		}
	};

	const readOptionsList = [
		{
			label: t("common.optionRead"),
			value: "true",
		},
		{
			label: t("common.optionUnread"),
			value: "false",
		}
	];
	const typeOptionsList = [
		{
			label: t("common.optionOccurrence"),
			value: "occurrence",
		},
		{
			label: t("common.optionAlarm"),
			value: "alarm",
		}
	];

	const template = useCallback(
		({ inputLabel, children }: IDynamicInputTemplate) => {
			return <ColXTemplate xsCol={true} inputLabel={inputLabel} children={children} />
		},
		[],
	)

	const dynamicForm = {
		formId: 'form-search',
		inputsDefinition: {
			type: {
				inputType: TypeDynamicInput.autocomplete,
				label: t("common.type"),
				placeholder: t("common.typePlaceholder"),
				options: typeOptionsList,
				defaultValue: null,
				handleOnChange: handleChangeType,
				template: template,
			},
			read: {
				inputType: TypeDynamicInput.autocomplete,
				label: t("common.read"),
				placeholder: t("common.readPlaceholder"),
				options: readOptionsList,
				defaultValue: null,
				handleOnChange: handleChangeRead,
				template: template,
			}
		}
	}

	const defaultValues: ISearch = {
		type: dynamicForm.inputsDefinition.type.defaultValue,
		read: dynamicForm.inputsDefinition.read.defaultValue
	};
	const methodsForm = useForm<ISearch>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: defaultValues,
		// resolver: yupResolver(getResolverSchema()),
	});

	const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm }

	const addFilterCount = () => {
		setFiltersCount((prevValue) => prevValue + 1);
	};
	const removeFilterCount = () => {
		setFiltersCount((prevValue) => {return prevValue > 0 ? prevValue-1 : 0});
	};

	useEffect(() => {
		setFilterType(type);
	}, [type, setFilterType]);

	useEffect(() => {
		setFilterRead(read);
	}, [read, setFilterRead]);

	const cleanFilters = () => {
		methodsForm.reset();
		setType("");
		setRead("");
		setFiltersCount(0);
	};

	const showClearFilters = () => {
		return filtersCount > 1
	}

	return (
		<>
			<FilterSection
				activeFiltersCount={filtersCount}
				filterTitle={t("common.filter")}
			>
				<Grid container>
					<Grid item xs={showClearFilters() ? 9 : 12}>
						<DynamicForm formDefinition={formDefinition} />
					</Grid>
					{showClearFilters() && (
						<Grid mb={1} item xs={true} display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'}>
							<Button variant="outlined" onClick={cleanFilters}>
								{t("common.cleanFilters")}
							</Button>
						</Grid>
					)}
				</Grid>
			</FilterSection>
		</>
	);
};
