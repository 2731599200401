import { DynamicForm, FilterSection, IDynamicInputTemplate, IFormDefinition, IRange, MiniSelect, TypeDynamicInput } from "@alb/live-lib";
import { Button, Grid, Typography, useTheme } from "@mui/material";
import { subDays } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";

import i18n from "i18n/config";
import { FlexGridBaseline } from "styles/css/components";

interface IDefaultValues {
  startDateRange: IRange | undefined;
}
interface IColXTemplate extends IDynamicInputTemplate {
  xsCol: "auto" | number | boolean;
}
const ColXTemplate = ({ inputLabel, children, xsCol }: IColXTemplate) => {
  return (
    <Grid item xs={xsCol}>
      {inputLabel && (
        <Typography gutterBottom variant="h6">
          {inputLabel}
        </Typography>
      )}
      {children}
    </Grid>
  );
};

export const FiltersSection = (props: {
  setFilterDevice: React.Dispatch<React.SetStateAction<string>>;
  setFilterDateRange: React.Dispatch<React.SetStateAction<IRange | undefined>>;
}) => {
  const { t } = useTranslation();
  const { setFilterDevice, setFilterDateRange } = props;
  const theme = useTheme();

  const defaultValueFieldDateRange = { startDateRange: undefined };
  // TODO temporario para a demo de AveiroDemo
  const customRange = {
    startDate: new Date(subDays(new Date("2023/10/10"), 6).setHours(0, 0, 0)),
    endDate: new Date("2023/10/10"),
  };

  // const predefinedRanges = {
  //   labelLast30Days: t("calendar.last30Days"),
  //   labelLast7Days: t("calendar.last7Days"),
  //   position: "left",
  // };

  //static data
  const selectOptions = [
    {
      label: t("hydrogenSupply.filter.production"),
      value: "production",
    },
    {
      label: t("hydrogenSupply.filter.storage"),
      value: "tankage",
    },
    {
      label: t("hydrogenSupply.filter.supply"),
      value: "supply",
    }
  ];

  const [selectedDevice, setSelectedDevice] = useState<string>(
    selectOptions?.[0].value || ""
  );
  const [selectedRange, setSelectedRange] = useState<IRange | undefined>();

  const calculateFiltersCount = useCallback(() => {
    let count = 0;
    if (selectedRange) count++;

    return count;
  }, [selectedRange]);

  const template = useCallback(
    ({ inputLabel, children }: IDynamicInputTemplate) => {
      return (
        <ColXTemplate xsCol={6} inputLabel={inputLabel} children={children} />
      );
    },
    []
  );

  const selectedRangeDates = (date: IRange | undefined) => {
    setSelectedRange(date);
  };

  const dynamicForm = {
    formId: "form-search",
    inputsDefinition: {
      startDateRange: {
        typefilter: true,
        inputType: TypeDynamicInput.daterange,
        label: t("hydrogenSupply.valuesDate"),
        placeholder: t("calendar.placeholderDate"),
        defaultValue: defaultValueFieldDateRange.startDateRange,
        defaultValueField: defaultValueFieldDateRange,
        onSelectedRange: selectedRangeDates,
        noOptionsText: t("common.noOptions"),
        template: template,
        locale: i18n.language,
        // TODO temporario para a demo de AveiroDemo
        // predefinedRanges: predefinedRanges,
        labelBtnCancel: t("cancel"),
        labelBtnConfirm: t("apply"),
        // TODO temporario para a demo de AveiroDemo
        clearButton: false,
        minDate: customRange.startDate,
        maxDate: customRange.endDate,
      },
    },
  };

  const defaultValues: IDefaultValues = {
    startDateRange: dynamicForm.inputsDefinition.startDateRange.defaultValue,
  };
  const methodsForm = useForm<IDefaultValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm };

  //define o valor que é para preencher no input ao abrir a página
  const setInitialValue = () => {
    methodsForm.setValue("startDateRange", customRange, {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    setInitialValue();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFilterDevice(selectedDevice);
  }, [selectedDevice, setFilterDevice]);

  useEffect(() => {
    setFilterDateRange(selectedRange);
  }, [selectedRange, setFilterDateRange]);

  const cleanFilters = () => {
    methodsForm.reset();
    setSelectedRange(undefined);
  };

  const showClearFilters = () => {
    return calculateFiltersCount() > 1;
  };

  function changeOption(e: ISelectOption) {
    setSelectedDevice(e.value);
  }
  const selectValue = () => {
    return (
      selectOptions?.find(
        (option: ISelectOption) => option.value === selectedDevice
      ) || ""
    );
  };

  const select = (
    <FlexGridBaseline>
      <Grid item xs={5}>
        <Typography mr={1} variant="h6">
          {t("dashboard.dataFrom")}
        </Typography>
      </Grid>
      <MiniSelect
        onChangeOption={changeOption}
        showLabel
        showBorder
        sx={{
          ...(theme.palette.mode === "light" && {
            backgroundColor: "#ECF8F8",
          }),
        }}
        borderColor="rgba(81,191,188,0.5)"
        options={selectOptions}
        value={selectValue()}
      />
    </FlexGridBaseline>
  );

  return (
    <>
      <FilterSection
        activeFiltersCount={calculateFiltersCount()}
        filterTitle={t("common.filter")}
        opened
        select={select}
      >
        <Grid container>
          <Grid item xs={showClearFilters() ? 9 : 12}>
            <DynamicForm formDefinition={formDefinition} />
          </Grid>
          {showClearFilters() && (
            <Grid
              mb={1}
              item
              xs={true}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"flex-end"}
            >
              <Button variant="outlined" onClick={cleanFilters}>
                {t("common.cleanFilters")}
              </Button>
            </Grid>
          )}
        </Grid>
      </FilterSection>
    </>
  );
};
