/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ServiceApiUrl } from "services/ServiceApiUrl";
import { useFeedback } from "@alb/live-lib";
import useCreate from "hooks/fetchData/useCreate";
import { IModuleForm } from 'types/interfaces'
import ModulesForm from "./ModulesForm";

export interface IModulesAdd {
  clientID: string;
  open: boolean;
  onClose: () => void;
  updateList: () => void;
}

const ModulesAdd = ({ clientID, open, onClose, updateList }: IModulesAdd) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const addFormId = 'add-modules-form'
  const [formIsValid, setFormIsValid] = useState(false);

  //pedido para criar module
  const {
    loading,
    refetch: createModule,
  } = useCreate(ServiceApiUrl.modules);

  // sucesso
  const handlerSuccess = () => {
    onClose(); //fecha modal
    addFeedback({
      message: t("modules.moduleAdded"),
      severity: "success",
    });
    updateList && updateList();
  };

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IModuleForm> = async (
    payLoad: IModuleForm
  ) => {
    try {
      const payLoadAPI = payLoad as unknown as IModuleForm<string>;
      if (clientID) payLoadAPI.client = clientID;
      payLoadAPI.adapter_configurators = payLoad.adapter_configurators.map(
        (a: any) => a.value
      );
      await createModule({ data: payLoadAPI });
      await handlerSuccess();
    } catch (error) { }
  };

  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography
          component={'span'}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t('modules.addModule')}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <ModulesForm
          formId={addFormId}
          onFormSubmit={formSubmitHandler}
          clientID={clientID}
          onChangeStatusForm={getFormIsValidStatus}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t('common.cancel')}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!formIsValid}
          type={'submit'}
          form={addFormId}
        >
          {t('common.add')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ModulesAdd;
