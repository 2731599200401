import { IconButton, TableCell, TableRow } from "@mui/material";
import { t } from "i18next";
import { TGroup } from "types/types";
import { formatDate } from "utils/date";
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import EditIcon from '@mui/icons-material/Edit'
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";
import { formatRoutePath } from "utils/routePath";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const GroupRow = (props: { 
	data: TGroup, 
	onDeleteClick: (row: TGroup) => void; 
	onEditClick: (row: TGroup) => void
}) => {

	const { data: group, onDeleteClick, onEditClick} = props;

    const { hasPermission } = usePermission();

	const params = useParams();
	const location = useLocation();
	const navigate = useNavigate();

    const canUpdate = hasPermission(PERMISSIONS.MANAGEMENT.GROUPS.UPDATE);
    const canDelete = hasPermission(PERMISSIONS.MANAGEMENT.GROUPS.DELETE);
	
	const onRowClick = (e: any): void => {
		e.stopPropagation()
		const path = formatRoutePath(location, params, {
			groupId: group.id,
		  });
		navigate(path);
	}

    return(<>
        <TableRow	
			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
			onClick={onRowClick}
		>
            <TableCell>{`${group.name}`}</TableCell>
            <TableCell>{`${group.user.length}`}</TableCell>
			<TableCell>{formatDate(group.created, t('calendar.dateTimeFormatLocal'))}</TableCell>
            <TableCell align={'right'} sx={{ whiteSpace: 'nowrap' }}>
                {canUpdate && (
						<IconButton
							title={t('common.edit')}
							onClick={(e) => {
								e.stopPropagation()
								onEditClick(group)}
							}
						>
							<EditIcon />
						</IconButton>
					)}
					{canDelete && (
						<IconButton
							title={t('common.delete')}
							aria-label="delete"
							onClick={(e) => {
								e.stopPropagation()
								onDeleteClick(group)}
							}
						>
							<DeleteIcon />
						</IconButton>
					)}</TableCell>
        </TableRow>
    </>)
}

export default GroupRow;