import { IOccurrenceForm } from "types/interfaces";

export const formDefaultValues = (data?: IOccurrenceForm) => {
  const defaultValues = {
    address: data?.address || "--",
    assignee: {
      assignee_type: data?.assignee?.assignee_type || "user",
      assignee_id: data?.assignee?.assignee_id || "",
    },
    category: data?.category?.id || "",
    description: data?.description || "",
    end_date: data?.end_date || null,
    geolocation: JSON.stringify(data?.geolocation.iconPosition) || "",
    record_date: data?.record_date || null,
    reporter: data?.reporter || "",
    resolution_days: data?.resolution_days || "--",
    status: data?.status?.id || "",
    status_date: data?.status_date || null,
    urgency: data?.urgency?.id || "",
    url: data?.url || "",
    media: data?.media || [],
  };
  return defaultValues;
};
