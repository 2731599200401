import { PERMISSIONS } from "../permissions";

//permissões para todos os utilizadores dos clientes Aveiro (independentemente do tipo e dos módulos)
export const cmAveiroRoles = [
  PERMISSIONS.INDICATORS.CREATE,
  PERMISSIONS.INDICATORS.READ,
  PERMISSIONS.INDICATORS.UPDATE,
  PERMISSIONS.INDICATORS.DELETE,
  ...Object.values(PERMISSIONS.INDICATORS.ISO),
];
