import { useEffect } from 'react'

import { LoadingButton } from '@mui/lab'
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
} from '@mui/material'
import { InputSelect, InputText, RequiredField, useFeedback } from '@alb/live-lib'

import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import useUpdate from 'hooks/fetchData/useUpdate'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useDispatch } from 'react-redux'
import { setUser } from 'store/slices/authSlice'

import { TUser } from 'types/types'
import { RequiredFields } from 'components/Utils/RequiredField'
import { setCookie } from 'utils/cookie'
import { I18NEXT } from 'utils/keys'

export default function EditProfile(props: any) {
	const { t, i18n } = useTranslation()
	const { addFeedback } = useFeedback()
	const dispatch = useDispatch()

	// update personal data
	const {
		loading,
		refetch: updateUser,
	} = useUpdate(ServiceApiUrl.user, props.user.id)

	// options for language
	const langOptions = [
		{
			label: t('pt'),
			value: 'pt',
		},
		{
			label: t('en'),
			value: 'en',
		},
	]

	// default values for the form
	const defaultValues = {
		first_name: props.user.first_name,
		last_name: props.user.last_name,
		username: props.user.username,
		language: props.user.language.toLowerCase(),
	}
	const userProfileSchema = yup
		.object()
		.shape({
			username: yup.string().min(3).max(30).required(t('requiredField')),
		})
		.required()

	const methodsEdit = useForm<TUser>({
		mode: 'onChange',
		reValidateMode: 'onChange',
		defaultValues: defaultValues,
		resolver: yupResolver(userProfileSchema),
	})

	const { handleSubmit, control, reset } = methodsEdit

	const changeUserLanguage = (lng: string) => {
		if (props.user.language.toLowerCase() !== lng.toLowerCase()) {
			i18n.changeLanguage(lng.toLowerCase()) // change language
			setCookie<string>(I18NEXT, lng.toLowerCase(), {maxAge: Number.MAX_SAFE_INTEGER});
		}
	}

	const handlerSuccess = (data: TUser) => {
		props.handlerClose()
		addFeedback({
			message: t('feedback.success.changesSaved'),
			severity: 'success',
		})
		props.updateGetUsers()
		// save new personal data in local storage and redux
		dispatch(setUser(data))
	}

	// update user personal data
	async function submitEditForm(payLoad: any) {
		await updateUser({ data: payLoad }) //send data
		changeUserLanguage(payLoad.language)
		handlerSuccess(payLoad)
	}

	function cancelEditForm() {
		props.handlerClose()
	}

	useEffect(() => {
		reset({
			...defaultValues,
		})
		// eslint-disable-next-line
	}, [props.handlerClose])

	return (
		<Dialog maxWidth="sm" fullWidth open={props.open}>
			<DialogTitle id="alert-dialog-title">
				<Typography
					component={'span'}
					color="text.black"
					gutterBottom={true}
					variant="h5"
					noWrap
				>
					{t('user.editPersonalData')}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<FormProvider {...methodsEdit}>
					<form id="edit-user-form" onSubmit={handleSubmit(submitEditForm)}>
						{props.user && (
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography gutterBottom color="text.black" variant="h6">
										{t('user.firstName')}
									</Typography>
									<InputText name="first_name" control={control} />
								</Grid>
								<Grid item xs={12}>
									<Typography gutterBottom color="text.black" variant="h6">
										{t('user.lastName')}
									</Typography>
									<InputText name="last_name" control={control} />
								</Grid>
								<Grid item xs={12}>
									<RequiredField title={t('user.Username')} />
									<InputText name="username" control={control} />
								</Grid>
								<Grid item xs={12}>
									<Typography gutterBottom color="text.black" variant="h6">
										{t('user.institutionalEmail')}
									</Typography>
									<Typography gutterBottom variant="body1">
										{props.user.email}
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography gutterBottom color="text.black" variant="h6">
										{t('user.language')}
									</Typography>
									<InputSelect
										name="language"
										options={langOptions}
										control={control}
									/>
								</Grid>
							</Grid>
						)}
					</form>
				</FormProvider>
				<RequiredFields />
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="primary" onClick={cancelEditForm}>
					{t('cancel')}
				</Button>

				<LoadingButton
					type={'submit'}
					loading={loading}
					variant="contained"
					color="primary"
					disabled={!methodsEdit.formState.isValid}
					form={'edit-user-form'}
				>
					{t('save')}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	)
}
