import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { IDialogProps } from "types/interfaces";

import { getIcons } from "components/map/list/listIcons";
import NoData from "components/Utils/NoData";
import i18n from "i18n/config";
import { selectDeviceRealTime } from "store/slices/adapterConfiguratorsSlice";
import {
  selectDetailsError,
  selectDetailsLoading,
  selectMarkerDetails,
  setMarkerDetails,
} from "store/slices/mapSlice";
import { updateDeviceInfo } from "utils/data-real-time";
import { formatDate } from "utils/date";
import { formatStream, formattedLocation } from "utils/utils";

import MapEntiyInfoEmpty from "./MapEntiyInfoEmpty";
import MapEntiyInfoItem from "./MapEntiyInfoItem";
import MarkerDialogTitle from "./MarkerDialogTitle";
import { Loading } from "@alb/live-lib";

import CloseIcon from '@mui/icons-material/Close';

const DeviceInfo = ({ open, onClose }: IDialogProps) => {
  const { t } = useTranslation();

  const deviceEventSource = useSelector(selectDeviceRealTime);
  const data = useSelector(selectMarkerDetails);
  const loading = useSelector(selectDetailsLoading);
  const error = useSelector(selectDetailsError);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onShowDetail = () => {
    navigate(`/dashboard/${data.id}`);
  };
  let streamList = <NoData error={error.description} />;
  if (data) {
    if (
      data.adapter_configurators[0].domain === "mobility" &&
      data.adapter_configurators[0].partner__name === "SmartParkingFunchal"
    ) {
      streamList = (
        <>
          {Object.keys(data.last_read_value).map(
            (stream: any, index: number) => (
              <MapEntiyInfoItem
                key={index}
                label={`${t(`streams.${stream}`)}`}
                value={
                  data.last_read_value[stream]
                    ? t(
                        "typesParking." +
                          stream.toLowerCase() +
                          "." +
                          (
                            data.last_read_value[stream].value + ""
                          ).toLowerCase()
                      )
                    : "--"
                }
              />
            )
          )}
        </>
      );
    } else {
      streamList = (
        <>
          {Object.keys(data.last_read_value).map(
            (stream: any, index: number) => (
              <React.Fragment key={index}>
                {stream.toLowerCase() !== "geolocation" && (
                  <MapEntiyInfoItem
                    key={index}
                    label={`${t(`streams.${stream.toLowerCase()}`)}`}
                    value={
                      data.last_read_value[stream]
                        ? formatStream(data.last_read_value[stream])
                        : "--"
                    }
                  />
                )}
              </React.Fragment>
            )
          )}
        </>
      );
    }
  }

  useEffect(() => {
    // --- Smart Parking Funchal Devices - SSE
    if (data && deviceEventSource.external_id === data?.external_id) {
      showValuesRealTime(deviceEventSource);
    }
    // eslint-disable-next-line
  }, [deviceEventSource]);

  function showValuesRealTime(values: any) {
    const infoDevice = updateDeviceInfo(data, values);
    dispatch(
      setMarkerDetails({
        data: infoDevice,
        loading: false,
        error: {
          isError: false,
          description: null,
        },
      })
    );
  }

  const icon = getIcons(
    "device",
    data?.subType,
    data?.is_active,
    data?.last_read_value?.park_type,
    data?.last_read_value?.park_occupied,
    data?.icon
  );

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      {data && !loading && !error.isError ? (
        <>
          <MarkerDialogTitle
            icon={icon}
            altIcon={
              data?.subType
                ? i18n.exists(`domains.${data?.subType}`)
                  ? t(`domains.${data?.subType}`)
                  : data?.subType
                : data?.type
            }
            title={data?.name}
            status={data?.is_active ? t("common.active") : t("common.inactive")}
            domain={t(`domains.${data.adapter_configurators[0].domain}`)}
            onClose={onClose}
          />

          <DialogContent
            sx={{
              ".MuiPaper-root &.MuiDialogContent-root": {
                padding: "0 0",
              },
            }}
          >
            <Grid container direction={"row"}>
              <MapEntiyInfoItem
                icon={"LocationOnRounded"}
                label={"Localização"}
                value={formattedLocation(data.geolocation.iconPosition)}
              />
              <MapEntiyInfoItem
                icon={"AccessTimeRounded"}
                label={t("map.marker.lastReading")}
                value={`${
                  data.last_read_at
                    ? formatDate(
                        data.last_read_at,
                        t("calendar.dateTimeFullFormat")
                      )
                    : "n/a"
                }`}
              />
              <MapEntiyInfoEmpty />

              {streamList}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              {t("close")}
            </Button>
            {data.is_active && (
              <Button variant="contained" color="primary" onClick={onShowDetail}>
                {t("common.showDetail")}
              </Button>
            )}
          </DialogActions>
        </>
      ) : (
          <>
            <Loading show={loading} />
            {error.isError && !loading &&
              <Box marginTop={'10px'} marginBottom={'20px'}>
                <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                  <IconButton onClick={onClose}><CloseIcon /></IconButton>
                </Box>
                <NoData error={error.description} />
              </Box>
            }
          </>
      )}
    </Dialog>
  );
};

export default DeviceInfo;
