import { IRange } from "@alb/live-lib";
import { t } from "i18next";

import { ModuleCards } from "components/modules/ModuleCards";
import useGet from "hooks/fetchData/useGet";
import { formatDate } from "utils/date";

import { IDeviceInfo } from "./CERManagementSelect";

const CardsTotals = (props: { device: IDeviceInfo, filterDateRange: IRange | undefined } ) => {

  const { device, filterDateRange } = props;

  const getParams = () => {
    let params = undefined;
      if (filterDateRange?.startDate || filterDateRange?.endDate) {
        params = { 
          from: formatDate(filterDateRange?.startDate, t("calendar.dateTimeFormat")),
          to: formatDate(filterDateRange?.endDate, t("calendar.dateTimeFormat"))
        };
      }
      return params;
  }

  const {
    data: cardsTotals,
    loading,
    error,
  } = useGet(device?.apiCards, getParams());

  return (
    <>
      <ModuleCards
        module="cerManagement"
        items={cardsTotals}
        loading={loading}
        error={error}
        sx={{ mt: 5 }}
        dataTestId="CERManagementCardsContainer"
      />
    </>
  );
};

export default CardsTotals;
