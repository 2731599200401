import { Button, Dialog, DialogActions, DialogTitle, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "i18next";
import GroupForm from "./GroupForm";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import useUpdate from "hooks/fetchData/useUpdate";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IGroupForm } from "types/interfaces";
import { Loading, useFeedback } from "@alb/live-lib";
import useUsersOptions from "components/occurrences/hooks/useUsersOptions";
import { arrayIsEmpty } from "utils/utils";

const GroupEditDialog = (props: {
    selectedRow: any,
    showEditDialog: boolean,
    onClose: () => void,
    refetchGroups: () => void
}) => {

    const { selectedRow, showEditDialog, onClose, refetchGroups } = props;
    const editFormId = "group-edit-form"

    const [ formIsValid, setFormIsValid ] = useState(false);

    const { addFeedback } = useFeedback();

    const getFormIsValidStatus = (status: boolean) => {
		setFormIsValid(status);
	};


    const { refetch: editGroup } = useUpdate(
		ServiceApiUrl.groups,
		selectedRow.id,
		undefined
	);

    const { usersOptions } = useUsersOptions()

    const handlerSuccess = () => {
        addFeedback({
			message: t("group.groupEdited"),
			severity: "success",
		});
        onClose();
        refetchGroups();
    }

    const onSubmit = async (payLoad: IGroupForm) => {
        try {
			await editGroup({ data: payLoad }).then((response) => {
				handlerSuccess();
			});
		} catch (error) { }
    }

    return(
        <Dialog open={showEditDialog}>
        <DialogTitle sx={{marginRight: "50px"}}>
            <Typography
                component={"span"}
                gutterBottom={true}
                variant="h5"
                noWrap
            >
                {t("group.editGroup")}
            </Typography>


            <IconButton
                aria-label="close"
                onClick={() => onClose()}
                size="small"
                sx={{
                    position: "absolute",
                    right: 10,
                    top: 15,
                    zIndex: 500,
                }}
                title={t("close")}
                        >
                <CloseIcon />
            </IconButton>
        </DialogTitle>

        {!arrayIsEmpty(usersOptions) && <><GroupForm
            data={selectedRow}
            formId={editFormId}
            onFormSubmit={onSubmit}
            setFormIsValidStatus={getFormIsValidStatus}
            usersOptions= {usersOptions}
        />

        <DialogActions
          style={{
            paddingRight: "10px",
          }}
        >
            <Button variant="outlined" color="primary" onClick={() => onClose()}>
                {t("cancel")}
            </Button>

            <LoadingButton
                loading={false}
                variant="contained"
                color="primary"
                type={"submit"}
                disableElevation
                disabled={!formIsValid}
                form={editFormId}
            >
                {t("common.edit")}
            </LoadingButton>
        </DialogActions></>
        }

        {arrayIsEmpty(usersOptions) && <Loading show={true} />}


    </Dialog>)
}

export default GroupEditDialog;
