import { DynamicForm, FilterSection, IFormDefinition, TypeDynamicInput } from "@alb/live-lib";
import { debounce } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { stringIsEmpty } from "utils/utils";

interface ISearch{
  name: string;
}

export const FiltersSection = (props: {
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setFilterSearch: (value:string) => void;
}) => {
  const { t } = useTranslation();
  const { setPage, setFilterSearch } = props;

  const [filtersCount, setFiltersCount] = useState<number>(0);
  const [search, setSearch] = useState<string>("");

	const debounceChangeName = debounce((cb) => {
    cb();
  }, 350);
  const handleChangeName = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
		debounceChangeName(()=> {
			setPage(1);
			//se o valor anterior for vazio
			if (stringIsEmpty(search)) {
				addFilterCount(); //adiciona à contagem de filtros
			} else if (stringIsEmpty(e.target.value)) {
				removeFilterCount(); //se o input estiver vazio, remove da contagem de filtros
			}
			setSearch(e.target.value);
		})
  };

	const dynamicForm = {
		formId: 'form-search',
		inputsDefinition: {
			name: {
				inputType: TypeDynamicInput.text,
				label: t("common.search"),
				placeholder: t("common.typeToSearchPlaceholder"),
				defaultValue: '',
				handleOnChange: handleChangeName
			}
		}
	}

	const defaultValues:ISearch = {
		name: dynamicForm.inputsDefinition.name.defaultValue,
	};
	const methodsForm = useForm<ISearch>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: defaultValues,
		// resolver: yupResolver(getResolverSchema()),
	});

	const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm }

  const addFilterCount = () => {
    setFiltersCount((prevValue) => prevValue + 1);
  };
  const removeFilterCount = () => {
    setFiltersCount((prevValue) => {return prevValue > 0 ? prevValue-1 : 0});
  };

  useEffect(() => {
    setFilterSearch(search);
  }, [search, setFilterSearch]);

  return (
    <>
      <FilterSection
        activeFiltersCount={filtersCount}
        filterTitle={t("common.filter")}
      >
				<DynamicForm formDefinition={formDefinition} />

      </FilterSection>
    </>
  );
};
