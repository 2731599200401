import { TAccount } from 'types/types'

export const formDefaultValues = (data?: TAccount) => {
  const defaultValues = {
    name: data?.name || '' ,
    adapter_configurators: [],
    admin: false,
  }
  return defaultValues
}
