/* eslint-disable react-hooks/exhaustive-deps */
import { DynamicForm, FilterSectionPopup, IFormDefinition, InputAutocomplete, IRange, ToggleViewButton, TToggle, TypeDynamicInput } from "@alb/live-lib";
import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { debounce } from "lodash";
import { ChangeEvent, Dispatch, SetStateAction, SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useForm }  from "react-hook-form"
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";
import RefreshIcon from "@mui/icons-material/Refresh";

import i18n from "i18n/config";
import { WazeAdapters } from "pages/Waze";
import { IFilterDateRangeMap } from "store/slices/mapSlice";
import { ColXTemplate } from "utils/templates";

import useLevelOptions from "./hooks/useLevelOptions";
import { useTypeOptions } from "./hooks/useTypeOptions";

interface IWazeFilterSection {
  selectedDisplay: 'dashboard' | 'list'
  setSelectedDisplay: Dispatch<SetStateAction<"dashboard" | "list">>,
  selectedRange: IRange | undefined,
  setSelectedRange: Dispatch<React.SetStateAction<IFilterDateRangeMap>>,
  selectedAdapter: string,
  setSelectedType: Dispatch<React.SetStateAction<string | null>>
  setFilterSearch: Dispatch<React.SetStateAction<string | null>>
  setSelectedLevel: Dispatch<React.SetStateAction<string | null>>
}

interface IWazeFilterForm {
  dateRangeCalendar: IRange | undefined;
  type?: string | null,
  level?: string | null,
  contains?: string | null
}

const FilterSection = (props: IWazeFilterSection) => {

  const { t } = useTranslation()
  const { selectedDisplay, setSelectedDisplay, selectedRange, setSelectedRange, selectedAdapter, setSelectedType, setFilterSearch, setSelectedLevel } = props;
  const { typeOptions } = useTypeOptions();
  const { levelOptions } = useLevelOptions();
  const [ filterPopupOpen, setFilterPopupOpen ] = useState(false) 
  const typeRef = useRef<null | string>(null)
  const levelRef = useRef<null | string>(null)
  const theme = useTheme();


  const toggleConfig: TToggle[] = [
    {
      value: "dashboard",
      text: t("sidebar.dashboard"),
    },
    {
      value: "list",
      text: t("map.list.title"),
    },
  ];

  function handleDisplay(
    event: React.MouseEvent<HTMLElement>,
    newDisplay: string | null
  ) {
    if(newDisplay === 'dashboard' || newDisplay === 'list') {
      setSelectedDisplay(newDisplay)
      methodsForm.setValue('type', null)
      methodsForm.setValue('level', null)
      methodsForm.setValue('contains', null)
      typeRef.current = null
      levelRef.current = null
      setSelectedLevel(null)
      setSelectedType(null)
      setFilterSearch(null)
    }
      
  }

  const handleSelectedRange = (dateRange: {
    startDate: Date;
    endDate: Date;
  }) => {
    setSelectedRange(dateRange)
  }

  const handleChangeTypeOption = (e: SyntheticEvent<Element, Event>, value: ISelectOption) => {
    if(value)
      typeRef.current = value.value
      //setSelectedType(value.value)
    else
      typeRef.current = null
      //setSelectedType(null)
  }

  const handleChangeLevelOption = (e: SyntheticEvent<Element, Event>, value: ISelectOption) => {
    if(value)
      levelRef.current=value.value
    else
      levelRef.current = null
  }

  const template = useCallback(
    ({ inputLabel, children }: any) => {
      return (
        <ColXTemplate

          xsCol
          inputLabel={inputLabel}
          children={children}
        />
      );
    },
    []
  );
  
  const debounceSearch = debounce((cb) => {
    cb();
  }, 350);

  const handleOnSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    debounceSearch(() => {
      setFilterSearch(e.target.value);
    });
  };

  const predefinedRanges = {
    labelLast30Days: t("calendar.last30Days"),
    labelLast7Days: t("calendar.last7Days"),
    position: "left",
  };
  const dynamicForm = {
    formId: "form-search-waze",
    inputsDefinition: {
      dateRangeCalendar: {
        inputType: TypeDynamicInput.daterange,
        placeholder: t("calendar.placeholderDate"),
        defaultValue: undefined,
        defaultValueField: undefined,
        onSelectedRange: handleSelectedRange,
        noOptionsText: t("common.noOptions"),
        template: template,
        locale: i18n.language,
        predefinedRanges: predefinedRanges,
        labelBtnCancel: t("cancel"),
        labelBtnConfirm: t("apply"),
        clearButton: true,
      },
      ...(selectedDisplay === "list" && {
        contains: {
          inputType: TypeDynamicInput.text,
          placeholder: t("common.searchBy"),
          endAdornment: <SearchIcon />,
          defaultValue: "",
          handleOnChange: handleOnSearch,
          template: template,
        }
      }),
    },
  };

  const defaultValues: IWazeFilterForm = {
    dateRangeCalendar:
      dynamicForm.inputsDefinition.dateRangeCalendar.defaultValue,
    ...(selectedDisplay === 'list' &&
        selectedAdapter === WazeAdapters.alerts &&
        {
          type: null,
        },
        selectedAdapter === WazeAdapters.jams && {
          level: null
        }
        ),     
  };

  const methodsForm = useForm<IWazeFilterForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });


  const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm };

  const setInitialValue = () => {
    methodsForm.setValue("dateRangeCalendar", selectedRange, {
      shouldDirty: true,
    });
    methodsForm.setValue("type", null, {
      shouldDirty: true,
    });
  };

  useEffect(() => {
    setInitialValue();
  }, []);

  const handleFilterPopupToggle = (open: boolean) => {
    setFilterPopupOpen(open);
  }

  const cancelFilterForm = () => {
    setFilterPopupOpen(false);
  }

  const applyFilterForm = () => {
    setSelectedType(typeRef.current)
    setSelectedLevel(levelRef.current)
    cancelFilterForm();
  };

  const countFilters = () => {
    let count = 0;
    const values = methodsForm.getValues()
    if(values.level && selectedAdapter === WazeAdapters.jams)
      count++;
    if(values.type && selectedAdapter === WazeAdapters.alerts)
      count++;

    return count;
  }

  return <>
    <Grid container mt={'20px'}>
      <Grid item xs={selectedDisplay === "list" ? 5 : 9}>
        <ToggleViewButton
          size={"medium"}
          display={selectedDisplay}
          toggle={toggleConfig}
          onChange={handleDisplay}
        />
      </Grid>
      <Grid
          item
          xs={selectedDisplay === "list" ? 6 : 3}
          paddingX={1}
          sx={{
            ">form >div >div >div.MuiTextField-root": {
              background: theme.palette.mode === "light" ? "white" : "none",
              borderRadius: "12px",
            },
          }}
        >
          <DynamicForm formDefinition={formDefinition} />
      </Grid>
      {selectedDisplay === "list" && (
        <Grid item xs={1}>
            {selectedDisplay === "list" && <FilterSectionPopup 
          filterTitle={t("common.filter")}
          isOpen={filterPopupOpen}
          onToggle={handleFilterPopupToggle}
          activeFiltersCount={countFilters()}
        >
          <Box>
          <Grid container>
              <Grid container item xs={12} justifyContent="flex-end">
                {!!countFilters() && (
                  <Button
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    type={"submit"}
                    onClick={() => {
                      setSelectedLevel(null)
                      setSelectedType(null)
                      methodsForm.setValue('type', null)
                      methodsForm.setValue('level', null)
                      typeRef.current = null
                      levelRef.current = null
                    }}
                  >
                    {t("clear")}
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid container item>
              {selectedAdapter === WazeAdapters.alerts && <>
                <Typography variant="h6" gutterBottom>{t("common.type")}</Typography>
                <InputAutocomplete 
                  name="type"
                  control={methodsForm.control}
                  placeholder={t("common.typePlaceholder")}
                  options={typeOptions}
                  defaultValue={null}
                  handleOnChange={handleChangeTypeOption}
                  disableClearable
                /></>
              }
              {selectedAdapter === WazeAdapters.jams && <>
                <Typography variant="h6" gutterBottom>{t("waze.intensity")}</Typography>
                <InputAutocomplete 
                  name="level"
                  control={methodsForm.control}
                  placeholder={t("waze.levelPlaceHolder")}
                  options={levelOptions as TOption[]}
                  defaultValue={null}
                  handleOnChange={handleChangeLevelOption}
                  disableClearable
                /></>
              }
              <Box width={'100%'} display={'flex'} mt={2} justifyContent={'space-between'}>
                <Button
                  sx={{ marginRight: "10px" }}
                  variant="outlined"
                  onClick={cancelFilterForm}
                  fullWidth
                >
                  {t("cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type={"submit"}
                  onClick={applyFilterForm}
                  fullWidth
                >
                  {t("apply")}
                </Button>
              </Box>
            </Grid>
          </Box>
        </FilterSectionPopup>}
        </Grid>
      )}
    </Grid>
  </>
}

export default FilterSection;