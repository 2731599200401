import { clientsModulesArray } from "../permissions/clients/clientsModulesArray";

//devolve o id do módulo enviado
function getSelectedModule(userLogin: any | null, moduleKey: string) {
  let value1: any;
  const moduleName = getModuleNameByKey(moduleKey, userLogin?.client);
  value1 = userLogin?.client.modules?.filter((obj: any) => {
    return obj.name === moduleName;
  });
  if (value1 && value1[0]) return value1[0].id;
  else return undefined;
}

//devolve o nome exato do módulo, dando-lhe a key
function getModuleNameByKey(name: string, clientName: string) {
  let all = clientsModulesArray(clientName).reduce(
    (prev: any, next: any) => prev.concat(next.modules),
    []
  );
  let result = all.find((obj: any) => obj?.key === name);
  return result?.name;
}

export { getSelectedModule, getModuleNameByKey };
