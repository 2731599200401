import { Grid } from '@mui/material';

const MapEntiyInfoEmpty = () => {
  
  return (
    <Grid item xs={12} mb={2} >
      {/* <!-- EMPTY -->       */}
    </Grid>
  )
}

export default MapEntiyInfoEmpty