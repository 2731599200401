import {
  ConfirmDialog,
  TableHeadCell,
  TableHeadSort,
  useFeedback,
} from '@alb/live-lib'
import { Table, TableBody, TableContainer } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { arrayIsEmpty } from 'utils/utils'
import useDelete from 'hooks/fetchData/useDelete'
import EventsTableRow from './EventsTableRow'
import { getUser } from 'store/slices/authSlice'
import { useDispatch, useSelector } from 'react-redux'
import { IResultTable } from 'types/interfaces'
import { TEvent } from 'types/types'
import { formatRoutePath } from 'utils/routePath'
import { useLocation, useNavigate, useParams } from 'react-router'
import { MODULES_KEYS } from 'utils/modules/modulesKeys'
import { getSelectedModule } from 'utils/modules/modules'
import { setDrawingMap, setDrawnCoords } from 'store/slices/mapSlice'
import { fetchStatus } from 'store/slices/eventsSlice'
import { EventEdit } from './EventEdit'
import { CustomTablePagination } from 'components/Utils/Pagination/CustomTablePagination'
import { AUTH_TOKEN } from 'utils/keys'
import { getCookie } from 'utils/cookie'
import { useGetModuleHeaders } from 'hooks/useGetModuleHeaders'

export const EventsTable = (props: IResultTable) => {
  const { t } = useTranslation()
  const { addFeedback } = useFeedback()

  const {
    data: events,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    page,
    setPage,
    refetch,
  } = props

  const [selectedEvent, setSelectedEvent] = useState<any>()
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)

  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const moduleID = getSelectedModule(user, MODULES_KEYS.EVENTS)

  // fetch status function
  const fetchEventStatus = () => {
		const authToken = getCookie<string>(AUTH_TOKEN);
    return dispatch(fetchStatus({ authToken, moduleID }))
  }

  const { headers } = useGetModuleHeaders(MODULES_KEYS.EVENTS);

  const tableHeads: TableHeadCell[] = [
    {
      id: 'name',
      label: t('events.name'),
    },
    {
      id: 'type',
      label: t('events.type'),
    },
    {
      id: 'status',
      label: t('events.status'),
    },
    {
      id: 'start_date',
      label: t('events.startDate'),
    },
    {
      id: 'end_date',
      label: t('events.endDate'),
    },
    {
      id: 'action_cell',
      label: '',
      notSortable: true,
    },
  ]

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  //Trocar página da listagem
  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1) //porque o index da paginação do mui inicia no 0
  }

  const handleEdit = (row: TEvent) => {
    setSelectedEvent(row)
    setOpenEdit(true)
  }
  const handleDelete = (row: TEvent) => {
    setSelectedEvent(row)
    setOpenDeleteConfirm(true)
  }

  const handleRowClick = (row: TEvent) => {
    const path = formatRoutePath(location, params, {
      eventId: row.id,
    })
    navigate(path)
  }

  const handleCloseDelete = () => {
    setOpenDeleteConfirm(false)
    setSelectedEvent(undefined)
  }

  const handleCloseEdit = () => {
    setOpenEdit(false)
    setSelectedEvent(undefined)
    dispatch(setDrawingMap(false))
    dispatch(setDrawnCoords(null))
  }
  const handleEditSuccess = () => {
    handleCloseEdit()

    // update cards
    fetchEventStatus()
    // update list
    refetch && refetch()
  }

  const { loading, refetch: deleteEvent } = useDelete(
    ServiceApiUrl.events,
    selectedEvent?.id,
    headers
  )

  // delete sucesso
  const handlerDeleteSuccess = () => {
    handleCloseDelete()
    addFeedback({
      message: t('events.eventDeleted'),
      severity: 'success',
    })

    // update cards
    fetchEventStatus()
    // update list
    refetch && refetch()
  }

  const handleConfirmDelete = async () => {
    try {
      await deleteEvent()
      await handlerDeleteSuccess()
    } catch {}
  }

  let row
  if (!arrayIsEmpty(events.data)) {
    row = events.data.map((event: TEvent) => (
      <EventsTableRow
        key={event.id}
        row={event}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onRowClick={handleRowClick}
      />
    ))
  }

  return (
    <>
      <TableContainer sx={{ mt: 4 }}>
        <Table>
          <TableHeadSort
            headCells={tableHeads}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>{row}</TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        count={events?.totalCount}
        page={page}
        totalPages={events?.totalPages}
        onPageChange={handleChangePage}
      />

      {selectedEvent && (
        <>
          {openDeleteConfirm && (
            <ConfirmDialog
              type="error"
              open={openDeleteConfirm}
              title={t('events.deleteEvent')}
              message={t('events.eventDeleteConfirmation')}
              actionConfirmText={t('common.delete')}
              actionCancelText={t('common.cancel')}
              loading={loading}
              onCancel={handleCloseDelete}
              onConfirm={handleConfirmDelete}
            />
          )}

          {openEdit && (
            <EventEdit
              open={openEdit}
              data={selectedEvent}
              onClose={handleCloseEdit}
              onSuccess={handleEditSuccess}
            />
          )}
        </>
      )}
    </>
  )
}
