/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutContext } from "@alb/live-lib";
import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { IsoCategoriesTable } from "components/indicators/iso/categoriesList/IsoCategoriesTable";
import CertificationLevel from "components/indicators/iso/certificationLevelCard/CertificationLevel";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";

const IsoPage = () => {
  const { t } = useTranslation();
  // ------ permissions
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.INDICATORS.ISO.READ);

  // ------ header page
  const header = {
    title: t("indicators.iso.title"),
  };
  const { addHeader } = useContext(LayoutContext);

  useEffect(() => {
    addHeader(header);
  }, []);

  return (
    <>
      {canREAD && (
        <>
          <CertificationLevel />
          <IsoCategoriesTable />
        </>
      )}
    </>
  );
};

export default IsoPage;
