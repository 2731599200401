
import { Box } from '@mui/material'
import { useEffect, useState } from 'react'

import NoData from 'components/Utils/NoData'
import { ROWS_PER_PAGE } from 'components/Utils/Pagination/pagination'
import useGet from 'hooks/fetchData/useGet'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { formatOrderBy } from 'utils/orderByAPI'
import { arrayIsEmpty } from 'utils/utils'

import { NotificationsTable } from './NotificationsTable'
import { FiltersSection } from './FiltersSection'
import { TableHeadOrder, Loading } from '@alb/live-lib'
import { useSelector } from 'react-redux'
import { getReloadLastNotifications } from 'store/slices/notificationsSlice'

function GetParams(page: number, order: TableHeadOrder, orderBy: string, filterType: string, filterRead: string) {
	var params: {
		page: number;
		items: number;
		order_by: string;
		type?: string;
		read?: string;
	} = {
		page: page,
		items: ROWS_PER_PAGE,
		order_by: formatOrderBy(order, orderBy),
		...(filterType !== "" && { type: filterType }),
		...(filterRead !== "" && { read: filterRead }),
	};
	return params;
}

const NotificationsList = () => {
	const [page, setPage] = useState(1)
	const [order, setOrder] = useState<TableHeadOrder>('desc')
	const [orderBy, setOrderBy] = useState<string>('created_at')

	const [filterType, setFilterType] = useState<string>("");
	const [filterRead, setFilterRead] = useState<string>("");

	const reloadLastNotifications: boolean = useSelector(getReloadLastNotifications);

	const {
		data: notifications,
		loading,
		error,
		refetch: refetchNotifications,
	} = useGet(ServiceApiUrl.notifications, GetParams(page, order, orderBy, filterType, filterRead), { manual: false })

	useEffect(() => {
		if (!reloadLastNotifications) return;
		refetchNotifications();
	}, [refetchNotifications, reloadLastNotifications])

	return <>
		<Box marginTop={2}>
			<FiltersSection setPage={setPage}
				setFilterType={setFilterType}
				setFilterRead={setFilterRead} />
		</Box>
		<Loading show={loading} />
		{!loading && (arrayIsEmpty(notifications?.data) || error) && <NoData error={error} />}
		{!loading && notifications && !arrayIsEmpty(notifications.data) && !error && (
			<NotificationsTable
				data={notifications}
				orderBy={orderBy}
				order={order}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				page={page}
				setPage={setPage}
				refetch={refetchNotifications}
			/>
		)}
	</>
}

export default NotificationsList
