import { Loading } from "@alb/live-lib";
import InfoIcon from "@mui/icons-material/Info";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, IconButton, LinearProgress, LinearProgressProps, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { GridWhiteContainer } from "styles/css/components";
import { formatNumber } from "utils/number";

import CertificationLevelRanks from "./CertificationLevelRanks";

const CertificationLevel = () => {
	const { t } = useTranslation();
	const [showInfo, setShowInfo] = useState<boolean>(false);


	const {
		data: certificationLevel,
		loading,
		error,
	} = useGet(ServiceApiUrl.indicatorRank);

	return (
		<>
			<GridWhiteContainer mt={4} mb={3} data-testid={"certificationLevelContainer"}>
				<Typography
								sx={{
									fontWeight: 700,
									display: "flex",
									alignItems: "center",
								}}
							>
								{t("indicators.iso.categoriesISO.rank.title")}
								{certificationLevel?.Rank &&
								<>
								<IconButton size="small" onClick={() => setShowInfo(!showInfo)}>
									{showInfo ? (
										<InfoIcon fontSize="small" color="primary" />
									) : (
										<InfoOutlinedIcon fontSize="small" color="primary" />
									)}
								</IconButton>
								:{" "}
								{t(
									`indicators.iso.categoriesISO.rank.types.${certificationLevel?.Rank?.rank?.toLowerCase()}`
								)}
								</>
								}
							</Typography>
							<Loading show={loading} />
				
				{!loading && (!certificationLevel?.Goal || !certificationLevel?.Rank) && <NoData error={error}/>}

				{!loading &&
					certificationLevel?.Goal &&
					certificationLevel?.Rank && (
						<>

							{showInfo && <CertificationLevelRanks />}
							<LinearProgressWithLabel
								value={certificationLevel.Goal.MAX_ind}
								min={certificationLevel.Rank.MAX_ind[0]}
								max={certificationLevel.Rank.MAX_ind[1]}
							/>
							<Box sx={{ textAlign: "right", mr: 6 }}>
								{certificationLevel.Goal && (
									<>
										<Typography
											display="inline"
											sx={{ fontWeight: 700 }}
											color="primary"
										>
											{formatNumber(certificationLevel.Goal.MAX_ind)}
										</Typography>
										<Typography display="inline" sx={{ fontWeight: 700 }}>
											{" "}
											{t("indicators.iso.categoriesISO.rank.indicators")}
										</Typography>
									</>
								)}
								{certificationLevel.Rank.Principal && (
									<LevelLegend
										value={certificationLevel.Rank.Principal}
										text={t("indicators.iso.categoriesISO.rank.core")}
									/>
								)}

								{certificationLevel.Rank.Suporte && (
									<LevelLegend
										value={certificationLevel.Rank.Suporte}
										text={t("indicators.iso.categoriesISO.goals.support")}
									/>
								)}
							</Box>
						</>
					)}
			</GridWhiteContainer>
		</>
	);
};

export default CertificationLevel;

function LinearProgressWithLabel(
	props: LinearProgressProps & { value: number; min: number; max: number }
) {
	let percentage = Math.round((props.value * 100) / props.max);

	const min = formatNumber(Math.round(props.min));
	const max = formatNumber(Math.round(props.max));
	return (
		<>
			{props.value && props.min && props.max && (
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Box sx={{ minWidth: 35 }}>
						<Typography variant="body2" sx={{ fontWeight: 700 }}>
							{min}
						</Typography>
					</Box>
					<Box sx={{ width: "100%", mr: 1 }}>
						<LinearProgress variant="determinate" value={percentage} />
					</Box>
					<Box sx={{ minWidth: 35 }}>
						<Typography variant="body2" sx={{ fontWeight: 700 }}>
							{max}</Typography>
					</Box>
				</Box>
			)}
		</>
	);
}

const LevelLegend = ({
	value,
	text,
}: {
	value: number | number[] | undefined;
	text: string;
}) => {
	return (
		<>
			{value && (
				<>
					<br />
					<Typography display="inline" variant="caption">
						{typeof value === "number" ? formatNumber(value) : formatNumber(value[0]) + " - " + formatNumber(value[1])}
					</Typography>
					<Typography variant="caption" color="text.secondary" display="inline">
						{" "}
						{text && text}
					</Typography>
				</>
			)}
		</>
	);
};

