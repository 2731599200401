import { useState } from 'react'
import { useFeedback } from '@alb/live-lib'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

import { IModuleForm } from 'types/interfaces'
import { ServiceApiUrl } from 'services/ServiceApiUrl'

import useUpdate from 'hooks/fetchData/useUpdate'
import ModulesForm from './ModulesForm'
import { TModule } from 'types/types'

export interface IModulesEdit {
    module: TModule;
    clientID: string;
    open: boolean;
    onClose: () => void;
    onSuccess: () => void;
  }
const ModulesEdit = ({ module, clientID, open, onClose, onSuccess }: IModulesEdit) => {
  const { t } = useTranslation()
  const { addFeedback } = useFeedback()
  const editFormId = 'edit-clients-form'
  const [formIsValid, setFormIsValid] = useState(false);

  //pedido para editar clients
  const { loading, refetch: updateModules } = useUpdate(
    ServiceApiUrl.modules,
    module?.id
  )

  // sucesso
  const handlerSuccess = () => {
    onClose && onClose() //fecha modal
    addFeedback({
      message: t('feedback.success.changesSaved'),
      severity: 'success',
    })
    onSuccess && onSuccess()
  }

  const formSubmitHandler = async (payLoad: IModuleForm) => {
    let payLoadAPI = payLoad as unknown as IModuleForm<string>;
      if (clientID) payLoadAPI.client = clientID;
      payLoadAPI.adapter_configurators = payLoad.adapter_configurators.map(
        (a: any) => a.value
      );
    try {
      await updateModules({ data: payLoadAPI })
      await handlerSuccess()
    } catch (error) {}
  }

  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography component={'span'} gutterBottom={true} variant="h5" noWrap>
          {t('clients.editClient')}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <ModulesForm
          data={module}
          formId={editFormId}
          clientID={clientID}
          onFormSubmit={formSubmitHandler}
          editForm={true}
          onChangeStatusForm={getFormIsValidStatus}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t('common.cancel')}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!formIsValid}
          type={'submit'}
          form={editFormId}
        >
          {t('common.edit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ModulesEdit
