export function rangeOpenOccurrences() {
  return ["#EFC6C6", "#EF7F7F", "#EF6666", "#BD5151", "#803636"];
}

export function colorsStatusOccurrences(status: string) {
  switch (status.toLowerCase()) {
    case "aberto":
      return "#EF6666";
    case "fechado":
      return "#92CC76";
    case "em resolução":
      return "#5470C6";
    case "concluída":
      return "#3BA271";
    case "submetida":
      return "#EF6666";
    case "em análise":
      return "#BD5151";
    case "unida":
      return "#5179f5";
    case "não autorizada":
      return "#FAC858";
    default:
      return "#FC8453";
  }
}
