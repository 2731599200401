/* eslint-disable react-hooks/exhaustive-deps */
import { InputCheckbox, InputText, RequiredField } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid, Typography } from "@mui/material";
import { RequiredFields } from "components/Utils/RequiredField";
import React, { ChangeEvent, useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { formDefaultValues } from "./formDefaultValues";
import { getEventStatusFormSchema } from "./validations";

export interface IOccurrenceStatusForm {
  id: string;
  name: string;
  is_open?: boolean;
  is_closed?: boolean;
}

export interface IStatusForm {
  formId: string;
  data?: IOccurrenceStatusForm;
  editForm?: boolean;
  onFormSubmit: (payLoad: IOccurrenceStatusForm) => void;
  setFormIsValidStatus?: (status: boolean) => void;
}

const StatusForm = ({
  formId,
  data,
  editForm,
  onFormSubmit,
  setFormIsValidStatus,
}: IStatusForm) => {
  const { t } = useTranslation();

  const defaultValues = formDefaultValues(data);

  const methodsForm = useForm<IOccurrenceStatusForm>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(getEventStatusFormSchema()),
  });

  const { control, handleSubmit, setValue } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IOccurrenceStatusForm> = (
    payLoad: IOccurrenceStatusForm
  ) => {
    onFormSubmit(payLoad);
  };

  useEffect(() => {
    if (setFormIsValidStatus)
      setFormIsValidStatus(methodsForm.formState.isValid);
  }, [setFormIsValidStatus, methodsForm.formState.isValid]);

  const [checkedStatusType, setCheckedStatusType] = useState<string>(""); //guarda tipo de estado selecionado

  //ao abrir o editar, coloca o status a true (se existir) por defeito na checkedStatusType
  useEffect(() => {
    if (data) {
      const data_: any = data;
      var dataKeys = Object.keys(data_);
      var filtered = dataKeys.find(function (key: string) {
          return data_[key] === true;
      });
      filtered && setCheckedStatusType(filtered);
    }
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckedStatusType(e.target.name);
  };

  const isChecked = (type: string) => {
    return checkedStatusType === type;
  };

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RequiredField title={t("common.name")} />
              <InputText
                control={control}
                name="name"
                placeholder={t("occurrences.statusNamePlaceholder")}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("common.type")}
              </Typography>
              <InputCheckbox
                control={control}
                name="is_open"
                setValue={setValue}
                labelText={t("occurrences.opening")}
                checked={isChecked("is_open")}
                onChange={handleChange}
                defaultValue={true}
              />
              <br />
              <InputCheckbox
                control={control}
                name="is_closed"
                setValue={setValue}
                labelText={t("occurrences.close")}
                checked={isChecked("is_closed")}
                onChange={handleChange}
                defaultValue={defaultValues.is_closed}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <RequiredFields />
    </>
  );
};
export default StatusForm;
