/* eslint-disable react-hooks/exhaustive-deps */
import {
  ConfirmDialog,
  GenericTable,
  GenericTableActions,
  TableHeadCell,
  TActions,
  TGenericTableModel,
  useFeedback,
} from "@alb/live-lib";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IResultTable } from "types/interfaces";
import { TOccurrenceUrgency } from "types/types";

import useDelete from "hooks/fetchData/useDelete";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { PERMISSIONS } from "utils/permissions/permissions";

import UrgenciesEdit from "./UrgenciesEdit";
import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

const UrgenciesTable = ({
  data,
  page,
  orderBy,
  order,
  setPage,
  setOrder,
  setOrderBy,
  refetch,
}: IResultTable) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();

  const [selectedRow, setSelectedRow] = useState<TOccurrenceUrgency>();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const { hasPermission } = usePermission();
  const canUpdate = hasPermission(PERMISSIONS.MANAGEMENT.MODULES.UPDATE);
  const canDelete = hasPermission(PERMISSIONS.MANAGEMENT.MODULES.DELETE);

  //Trocar página da listagem
  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleEdit = (row: TOccurrenceUrgency) => {
    setSelectedRow(row);
    setOpenEdit(true);
  };
  const handleEditClose = () => {
    setOpenEdit(false);
    setSelectedRow(undefined);
  };
  const handleEditSuccess = () => {
    handleEditClose();

    // update list
    refetch && refetch();
  };

  const handleDelete = (row: TOccurrenceUrgency) => {
    setSelectedRow(row);
    setOpenDeleteConfirm(true);
  };
  const handleCloseDelete = () => {
    setOpenDeleteConfirm(false);
    setSelectedRow(undefined);
  };

  const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

  const {
    loading,
    refetch: deleteUrgency,
  } = useDelete(ServiceApiUrl.occurrencesUrgency, selectedRow?.id, headers);
  // delete sucesso
  const handlerDeleteSuccess = () => {
    handleCloseDelete();
    addFeedback({
      message: t("occurrences.urgencyDeleted"),
      severity: "success",
    });

    // update list
    refetch && refetch();
  };
  const handleConfirmDelete = async () => {
    try {
      await deleteUrgency();
      await handlerDeleteSuccess();
    } catch {}
  };

  const tableHeads: TableHeadCell[] = [
    {
      id: "name",
      label: t("occurrences.name"),
    },
    {
      id: "action_cell",
      label: "",
      notSortable: true,
    },
  ];

  const actions: TActions<TOccurrenceUrgency> = {
    editAction: canUpdate
      ? { title: t("common.edit"), click: handleEdit }
      : undefined,
    deleteAction: canDelete
      ? { title: t("common.delete"), click: handleDelete }
      : undefined,
  };

  const model: TGenericTableModel<TOccurrenceUrgency> = {
    columns: [
      { html: (row: TOccurrenceUrgency) => <>{row.name}</> },
      {
        isActions: true,
        html: (row: TOccurrenceUrgency) => (
          <GenericTableActions row={row} actions={actions} />
        ),
      },
    ],
    headSort: {
      onRequestSort: handleRequestSort,
      headCells: tableHeads,
      order: order,
      orderBy: orderBy,
    },
  };
  const pagination = (
    <CustomTablePagination
      count={data.totalCount}
      totalPages={data.totalPages}
      page={data.currentPage}
      onPageChange={handleChangePage}
    />
  );

  return (
    <>
      <GenericTable model={model} items={data} pagination={pagination} />

      {selectedRow && (
        <>
          {openEdit && (
            <UrgenciesEdit
              data={selectedRow}
              open={openEdit}
              onClose={handleEditClose}
              onSuccess={handleEditSuccess}
            />
          )}
          {openDeleteConfirm && (
            <ConfirmDialog
              open={openDeleteConfirm}
              type="error"
              title={t("occurrences.deleteUrgency")}
              message={t("occurrences.deleteUrgencyConfirmation")}
              actionConfirmText={t("common.delete")}
              actionCancelText={t("common.cancel")}
              loading={loading}
              onCancel={handleCloseDelete}
              onConfirm={handleConfirmDelete}
            />
          )}
        </>
      )}
    </>
  );
};

export default UrgenciesTable;
