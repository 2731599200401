// EAQI = European Air Quality Index
export const palettePollutantColors = {
  veryGood: {
    main: "#2fe175",
    dark: "#209D51",
  },
  good: {
    main: "#26bc74",
    dark: "#1A8351",
  },
  medium: {
    main: "#fdc259",
    dark: "#B1873E",
  },
  high: {
    main: "#fc973d",
    dark: "#B0692A",
  },
  veryHigh: {
    main: "#fb565a",
    dark: "#AF3C3E",
  },
  outOfLimit: {
    main: "#d3d3d3",
    dark: "#6c757d",
  },
};

export interface IPaletteColor {
  main: string;
  dark: string;
}

export interface IPollutantLimits {
  min: number;
  max: number;
  classification: string;
  color: IPaletteColor;
}

interface IPollutant {
  pollutant: {
    name: string;
    limits: IPollutantLimits[];
  }[];
}

const poluttantColorClassification = [
  { color: palettePollutantColors.veryGood, classification: "veryGood" },
  { color: palettePollutantColors.good, classification: "good" },
  { color: palettePollutantColors.medium, classification: "medium" },
  { color: palettePollutantColors.high, classification: "high" },
  { color: palettePollutantColors.veryHigh, classification: "veryHigh" },
];

const pollutantLimits: IPollutant = {
  pollutant: [
    {
      name: "particules_pm10",
      limits: [
        { min: 0, max: 20, ...poluttantColorClassification[0] },
        { min: 21, max: 35, ...poluttantColorClassification[1] },
        { min: 36, max: 50, ...poluttantColorClassification[2] },
        { min: 51, max: 100, ...poluttantColorClassification[3] },
        { min: 101, max: 1200, ...poluttantColorClassification[4] },
      ],
    },
    {
      name: "particules_pm25",
      limits: [
        { min: 0, max: 10, ...poluttantColorClassification[0] },
        { min: 11, max: 20, ...poluttantColorClassification[1] },
        { min: 21, max: 50, ...poluttantColorClassification[2] },
        { min: 26, max: 50, ...poluttantColorClassification[3] },
        { min: 51, max: 800, ...poluttantColorClassification[4] },
      ],
    },
    {
      name: "no2",
      limits: [
        { min: 0, max: 40, ...poluttantColorClassification[0] },
        { min: 41, max: 100, ...poluttantColorClassification[1] },
        { min: 101, max: 200, ...poluttantColorClassification[2] },
        { min: 201, max: 400, ...poluttantColorClassification[3] },
        { min: 401, max: 1000, ...poluttantColorClassification[4] },
      ],
    },
    {
      name: "o3",
      limits: [
        { min: 0, max: 80, ...poluttantColorClassification[0] },
        { min: 81, max: 100, ...poluttantColorClassification[1] },
        { min: 101, max: 180, ...poluttantColorClassification[2] },
        { min: 181, max: 240, ...poluttantColorClassification[3] },
        { min: 241, max: 600, ...poluttantColorClassification[4] },
      ],
    },
    // {
    //   name: "so2",
    //   limits: [
    //     { min: 0, max: 100, ...poluttantColorClassification[0] },
    //     { min: 101, max: 200, ...poluttantColorClassification[1] },
    //     { min: 201, max: 350, ...poluttantColorClassification[2] },
    //     { min: 351, max: 500, ...poluttantColorClassification[3] },
    //     { min: 501, max: 1250, ...poluttantColorClassification[4] },
    //   ],
    // },
  ],
};

export const getPollutantLimits = () => {
  return pollutantLimits.pollutant;
};
export const getPollutantClassification = () => {
  return poluttantColorClassification;
};

export const getLimit = (pollutant: string, value: number) => {
  // convert to integer
  const _value = parseInt(value.toString());
  const limits =
    pollutantLimits.pollutant.find((x) => x.name === pollutant)?.limits || [];
  const limit = limits.find(
    (limit: { min: number; max: number }) =>
      limit.min <= _value && limit.max >= _value
  );

  return limit;
};

export const getColor = (pollutant: string, value: number): IPaletteColor => {
  const limit = getLimit(pollutant, value);

  return limit ? limit.color : palettePollutantColors.outOfLimit;
};

export const getClassification = (pollutant: string, value: number) => {
  const limit = getLimit(pollutant, value);

  return limit ? limit.classification : "";
};
