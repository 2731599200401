import React, { useCallback, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import update from 'immutability-helper'
import { Card } from './Card'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'


interface Item {
  id: number
  text: string
}

interface ISortList {
  title: string;
  list: any[]
  open: boolean;
  onClose: () => void;
  orderedList: (list: any[]) => void;
}

export const SortList = ({ title, list, open, onClose, orderedList }: ISortList) => {
  const { t } = useTranslation();

  const changeClient = async () => {
    orderedList(cards)

  };

  const [cards, setCards] = useState(list)
  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      }),
    )
  }, [])

  const renderCard = useCallback(
    (card: { frontend_order: number; name: string }, index: number) => {
      return (
        <Card
          key={card.frontend_order}
          index={index}
          id={card.frontend_order}
          text={card.name}
          moveCard={moveCard}
        />
      )
    },
    [moveCard],
  )
  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle id="alert-dialog-title">
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DndProvider backend={HTML5Backend}>
          <>
            <div >{cards.map((card, i) => renderCard(card, i))}</div>
          </>
        </DndProvider>

      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("cancel")}
        </Button>

        <LoadingButton
          // loading={loading}
          variant="contained"
          color="primary"
          form={"change-pwd-form"}
          onClick={changeClient}
        >
          {t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
