import AdaptersView from "components/management/adapters/AdaptersView";



function AdaptersViewPage() {
  return (
    <>
      <AdaptersView />
    </>
  );
}

export default AdaptersViewPage;