import { arrayIsEmpty, IRange, Loading } from "@alb/live-lib";
import { Box, useTheme } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { formatNumber } from "utils/number";

import { colorsStatusOccurrences } from "./utils/colorsOccurrences";
import { defaultOptionsOccurrences, textStyle } from "./utils/optionsOccurrences";

interface IValuesBarChart {
  name: string;
  value: {
    [key: string]: string;
  };
}

const OccurrencesWeekChart = (props: {
	selectedRange: IRange | undefined,
	needToRefetch : boolean | undefined}) => {

	const { selectedRange, needToRefetch } = props;
	const { t } = useTranslation();
	const { headers: headersOCurrences } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

	const theme = useTheme()


	// const getWeekDate = (date: dateRange) => {
	//   setWeekDate(date);
	// };

	function getParams() {
		var params = undefined;
		if (selectedRange?.startDate || selectedRange?.endDate) {
			params = {
				record_date_interval: formatDate(selectedRange?.startDate, t("calendar.dateTimeFormat")) + "," + formatDate(selectedRange?.endDate, t("calendar.dateTimeFormat"))
			};
		}
		return params;
	}

	//request to get total occurrences by category
	const { data, loading, error, refetch } = useGet(
		ServiceApiUrl.occurrencesWeekday,
		getParams(),
		{
			manual: false
		},
		headersOCurrences
	);

	useEffect(() => {
		if(needToRefetch) {
			refetch();
		}
		// eslint-disable-next-line
	}, [needToRefetch])

	var week = [
		t("occurrences.sunday"),
		t("occurrences.monday"),
		t("occurrences.tuesday"),
		t("occurrences.wednesday"),
		t("occurrences.thursday"),
		t("occurrences.friday"),
		t("occurrences.saturday"),
	];

  const weekDaysOccurrences = !arrayIsEmpty(data)
    ? Object.entries(data[0]).map((values: any) => {
        const day = Number(values[0]);
        return {
          name: week[day - 1],
          value: values[1],
        };
      })
    : [];

  function showBarsChart(allOccurrences: IValuesBarChart[]) {
    if (!arrayIsEmpty(allOccurrences)) {
      const status = Object.keys(allOccurrences[0].value);
      const series = status.map((elem: string) => {
        return {
          data: allOccurrences.map((element: IValuesBarChart) => element.value[elem]),
          type: "bar",
          stack: "occurrences",
          name: t("occurrences.state." + elem.toLowerCase()),
          color: colorsStatusOccurrences(elem),
        };
      });
      return series;
    }
  }

	//chart options
	var option = {
		...defaultOptionsOccurrences(theme, {
			name: "seriesName",
			value: "data",
		}),
    legend: {
      selectedMode: false,
      bottom: 25,
			textStyle: textStyle(theme),
    },
		xAxis: {
			type: "category",
			data: weekDaysOccurrences?.map(
				(d: IValuesBarChart) => d.name
			),
			axisTick: {
				show: false,
			},
			axisLine: {
				show: false,
			},
			axisLabel: {
				interval: 0,
				...textStyle(theme),
			},
		},
		grid: {
			containLabel: true,
		},
		yAxis: {
			name: t("occurrences.occurrencesNumber"),
			nameLocation: "center",
			nameTextStyle: {
				padding: [0, 0, 30, 0],
				fontSize: 12,
			},
			type: "value",
			axisLabel: {
				formatter: (val: any) => formatNumber(val)
			},
			axisLine: {
				lineStyle: {
				  color: theme.palette.mode === "light" ? "" : theme.palette.common.white
				}
			  }
		},
		series: showBarsChart(weekDaysOccurrences),
	};


	return (
		<Box sx={{ height: "100%", display: "grid", alignItems: "center" }}>
			<Loading show={loading} />
			{!arrayIsEmpty(data) && !loading && (
				<ReactEcharts style={{ height: "100%" }} option={option} />
			)}
			{arrayIsEmpty(data) && !loading && <NoData error={error} />}
		</Box>
	);
};

export default OccurrencesWeekChart;
