/* eslint-disable react-hooks/exhaustive-deps */
import { getColorTrafficWaze, Loading } from "@alb/live-lib";
import { Grid, useTheme } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { colors as defaultChartColors } from "components/charts/defaultOptions";
import { IReactECharts } from "components/charts/ReactECharts";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import i18n from "i18n/config";
import { WazeAdapters } from "pages/Waze";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatNumber } from "utils/number";
import { arrayIsEmpty } from "utils/utils";

const WazeTypesChart = (props: {
  selectedRange: {
    startDate: Date | null;
    endDate: Date | null;
  };
  selectedAdapterKey: string;
}) => {
  const { selectedRange, selectedAdapterKey } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const isDarkMode = theme.palette.mode === 'dark'

  const [chartOptions, setChartOptions] = useState<IReactECharts["option"]>({});

  const { data, loading, error } = useGet(
    ServiceApiUrl.wazeAlertsCategoriesChart(selectedAdapterKey),
    getParams()
  );

  function getParams() {
    let params: { from?: Date; to?: Date } = {
      ...(selectedRange?.startDate && { from: selectedRange.startDate }),
      ...(selectedRange?.endDate && { to: selectedRange.endDate }),
    };
    return params;
  }

  //transform data to get only the ones with values
  //chart options
  function optionsDoughnutChart(values: any, selectedAdapter: string) {
    const dataChart = values
      ?.filter((item: { name: string; value: number }) => item.value > 0)
      .map((item: { name: string; value: number }) => {
        return {
          name: i18n.exists(`waze.types.${item.name}`)
            ? t(`waze.types.${item.name}`)
            : item.name,
          value: item.value,
        };
      });


    return {
      tooltip: {
        confine: true,
        extraCssText: "white-space:inherit;",
        backgroundColor: theme.palette.background.paper,
        trigger: "item",
        formatter: function (value: any) {
          return `<b>${
            i18n.exists(`waze.types.${value.name}`)
              ? t(`waze.types.${value.name}`)
              : selectedAdapter === 'jams' ? t("waze.jamsLevel." + value.name) : value.name
          }</b>: ${formatNumber(value.data.value)}<br/>`;
        },
        textStyle: {
          color: theme.palette.text.primary,
        },
      },
      xAxis: {
        type: 'category',
        data: dataChart.map((item: {name: string, value: number}) => {
          return selectedAdapter === 'jams' ? t("waze.jamsLevel." + item.name) : item.name
        }),
        axisLabel: {
          color: isDarkMode ? "#fff" : undefined
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          color: isDarkMode ? "#fff" : undefined
        }
      },
      series: [
        {
          bottom: "20%",
          color: defaultChartColors,
          itemStyle: {
            color: (params: {data: any}) => {
              const paramsData = (params.data as {name: string, value: number})
              if(selectedAdapter === WazeAdapters.jams) {
                 return getColorTrafficWaze(paramsData.name.toString(), theme.palette.mode === 'dark')
              } 
              const index = dataChart.findIndex((obj: { name: string, value: number }) => {
                return obj.name === paramsData.name
              })
              return defaultChartColors[index]
            },
            
          },
          name: t("waze.type"),
          type: "bar",
          radius: ["30%", "80%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: false,
            },
          },
          labelLine: {
            show: false,
          },
          data: dataChart,
        },
      ],
    } as IReactECharts["option"];
  }

  useEffect(() => {
    if (data) {
      setChartOptions(optionsDoughnutChart(data, selectedAdapterKey));
    }
  }, [data]);

  useEffect(() => {
    setChartOptions({})
  }, [selectedAdapterKey, selectedRange])

  return (
    <>
      <>
        <Grid
          item
          sx={{
            display: "inline-flex",
            alignItems: "baseline",
          }}
        ></Grid>
      </>

      <Loading show={loading} />
      {!arrayIsEmpty(data) && !error &&  !loading && (
        <ReactEcharts option={chartOptions} />
      )}
      {(arrayIsEmpty(data) || error) && !loading && <NoData error={error} />}
    </>
  );
};

export default WazeTypesChart;
