import { TableHeadOrder } from "@alb/live-lib";
import Grid from "@mui/material/Grid";
import { useState } from "react";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatOrderBy } from "utils/orderByAPI";
import { arrayIsEmpty } from "utils/utils";

import { AdaptersTable } from "./AdaptersTable";
import { FiltersSection } from "./FiltersSection";

const AdaptersList = () => {
	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");
	const [filterSearch, setFilterSearch] = useState<string>("");
	const [filterStatus, setFilterStatus] = useState<string>("");

	const {
		data: adapters,
		loading,
		error,
		refetch: refetchAdapters,
	} = useGet(ServiceApiUrl.adapterConfigurators, GetParams(), undefined);

	function GetParams() {
		var params: {
			page: number;
			items: number;
			order_by: string;
			active?: string;
			contains?: string;
		} = {
			page: page,
			items: ROWS_PER_PAGE,
			order_by: formatOrderBy(order, orderBy),
			...(filterStatus !== "" && { active: filterStatus }),
			...(filterSearch !== "" && { contains: filterSearch }),
		};
		return params;
	}

	let adaptersList = <NoData error={error} />;

	if (loading) {
		adaptersList = <Loading />;
	}

	if (!loading && adapters && !arrayIsEmpty(adapters.data)) {
		adaptersList = (
			<AdaptersTable
				data={adapters}
				orderBy={orderBy}
				order={order}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				page={page}
				setPage={setPage}
				refetch={refetchAdapters}
			/>
		);
	}
	return (
		<Grid item mt={4}>
			<FiltersSection setPage={setPage}
				setFilterSearch={setFilterSearch}
				setFilterStatus={setFilterStatus} />

			{adaptersList}
		</Grid>
	);
};

export default AdaptersList;
