import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IDialogProps } from "types/interfaces";
import { TEvent } from "types/types";

import { EventEdit } from "components/events/EventEdit";
import { getIcons } from "components/map/list/listIcons";
import { getUser } from "store/slices/authSlice";
import { getMarkerDetails, selectDetailsError, selectDetailsLoading, selectMarkerDetails, TypeMarkerInfo } from "store/slices/mapSlice";
import { formatDate } from "utils/date";
import { formattedLocation } from "utils/utils";

import MapEntiyInfoItem from "./MapEntiyInfoItem";
import MarkerDialogTitle from "./MarkerDialogTitle";
import { Loading } from "@alb/live-lib";
import NoData from "components/Utils/NoData";

import CloseIcon from '@mui/icons-material/Close';

interface INewComponentProps extends IDialogProps {
  onSuccess?: () => void;
  publicPage?: boolean | false;
}

const EventInfo = ({
  open,
  onClose,
  onSuccess,
  publicPage,
}: INewComponentProps) => {
  const { t } = useTranslation();

  const data = useSelector(selectMarkerDetails);
  const loading = useSelector(selectDetailsLoading);
  const error = useSelector(selectDetailsError);
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const [openEdit, setOpenEdit] = useState(false);

  const onShowEdit = () => {
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleEditSuccess = (event: TEvent) => {
    handleCloseEdit();
    onSuccess && onSuccess();
    // update
    dispatch(getMarkerDetails(event.id, "event" as TypeMarkerInfo, null, user));
  };

  const icon = getIcons(
    "event",
    data?.subType,
    data?.is_active,
    data?.last_read_value?.park_type,
    data?.last_read_value?.park_occupied,
    data?.icon
  );

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open}>
        {data && !loading && !error.isError ? (
          <>
            <MarkerDialogTitle
              icon={icon}
              altIcon={data.name}
              title={data.name}
              status={data.status.name}
              onClose={onClose}
            />

            <DialogContent
              sx={{
                ".MuiPaper-root &.MuiDialogContent-root": {
                  padding: "0 0",
                },
              }}
            >
              <Grid container direction="row">
                {/* <MapEntiyInfoItem label={'Cliente'} value={data.client.name} /> */}
                <MapEntiyInfoItem
                icon={"CalendarToday"}
                  label={
                    data.type === "expected"
                      ? t("events.startDate")
                      : t("events.unexpectedStartDate")
                  }
                  value={formatDate(
                    data.start_date,
                    t("calendar.dateTimeFullFormat")
                  )}
                />
                <MapEntiyInfoItem
                icon={"CalendarToday"}
                  label={
                    data.type === "expected"
                      ? t("events.endDate")
                      : t("events.unexpectedEndDate")
                  }
                  value={formatDate(
                    data.end_date,
                    t("calendar.dateTimeFullFormat")
                  )}
                />
                <MapEntiyInfoItem
                    icon={"LocationOnRounded"}
                  label={t("events.location")}
                  value={formattedLocation(data.geolocation.iconPosition)}
                />
                <MapEntiyInfoItem
                icon={"Link"}
                  label={t("events.eventURL")}
                  value={data.url}
                />
                <MapEntiyInfoItem
                  label={t("events.type")}
                  value={data.type.name}
                />
                <MapEntiyInfoItem
                  label={t("events.description")}
                  value={data.description}
                />
                <MapEntiyInfoItem
                  label={t("events.category")}
                  value={data.category.name}
                />

                {data.notes && (
                  <MapEntiyInfoItem
                    label={t("events.notes")}
                    value={data.notes}
                  />
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                {t("close")}
              </Button>
              {!publicPage && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onShowEdit}
                >
                  {t("common.edit")}
                </Button>
              )}
            </DialogActions>
          </>
        ) : (
          <>
          <Loading show={loading} />
          {error.isError && !loading && 
            <Box marginTop={'10px'} marginBottom={'20px'}>
              <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                <IconButton onClick={onClose}><CloseIcon /></IconButton>
              </Box>
              <NoData error={error.description} />
            </Box>
          }
        </>
        )}
      </Dialog>

      {openEdit && data && !publicPage && (
        <EventEdit
          open={openEdit}
          data={data}
          onClose={handleCloseEdit}
          onSuccess={handleEditSuccess}
        />
      )}
    </>
  );
};

export default EventInfo;
