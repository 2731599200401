/* eslint-disable react-hooks/exhaustive-deps */
import { IRange, LayoutContext, Loading } from "@alb/live-lib";
import { Grid } from "@mui/material";
import { AxiosError } from "axios";
import { subDays } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IBugaSelectedAdapter } from "types/interfaces";

import { BugaAnalysisView } from "components/buga/BugaAnalysisView";
import { BugaCards } from "components/buga/BugaCards";
import { BugaSelect } from "components/buga/BugaSelect";
import NoData from "components/Utils/NoData";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";

const Buga = () => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.MODULES.BUGA.READ);
  const { addHeader } = useContext(LayoutContext);
  const header = {
    title: t("sidebar.buga"),
  };

  const [adapterSelect, setAdapterSelect] =
    useState<IBugaSelectedAdapter | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<AxiosError<any, any> | null>(null);
  const [filterDateRange, setFilterDateRange] = useState<IRange | undefined>({
    startDate: new Date(subDays(new Date(), 6)),
    endDate: new Date(),
  });

  useEffect(() => {
    addHeader(header);
  }, []);

  return (
    <>
      {canREAD && (
        <>
          <Grid container>
            <Grid item xs={12}>
              <BugaSelect
                loadingSelect={setLoading}
                errorSelect={setError}
                adapterSelect={setAdapterSelect}
              />
            </Grid>
            {adapterSelect && (
              <>
                <Grid item xs={12}>
                  <BugaCards
                    adapterSelect={adapterSelect}
                    filterDateRange={filterDateRange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BugaAnalysisView
                    filterDateRange={filterDateRange}
                    setFilterDateRange={setFilterDateRange}
                    adapterSelect={adapterSelect}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Loading show={loading} />
              {!loading && (error || !adapterSelect) && (
                <NoData error={error} />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default Buga;
