/* eslint-disable react-hooks/exhaustive-deps */
import { Loading } from "@alb/live-lib";
import { Box, Card, CardContent, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { defaultOptions } from "components/charts/defaultOptions";
import { ReactECharts as BarChart, IReactECharts } from "components/charts/ReactECharts";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getNameClient } from "store/slices/authSlice";
import { getFilterDateRange, getGeojsonType, getSelectedAnalysisDetail, getSelectedParishArea } from "store/slices/mapSlice";
import { getVisitsByDateChart, setVisitsByDateChart } from "store/slices/peopleMobilitySlice";
import styles from "styles/modules/map/list.module.scss";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";
import { getIdClientVisits, arrayIsEmpty } from "utils/utils";

export const CardDailyVisits = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const theme = useTheme();

	const nameClient = useSelector(getNameClient);
	const idParish = useSelector(getSelectedParishArea).id;
	const dateRange = useSelector(getFilterDateRange);
	const geojsonTypeMap = useSelector(getGeojsonType);
	const analysisDetail = useSelector(getSelectedAnalysisDetail);
	const data = useSelector(getVisitsByDateChart);

	const [chartOptions, setChartOptions] = useState<IReactECharts["option"]>({});
	const unitChart = t("map.filter.types.visits").toLowerCase();

	const {
		data: dataDailyVisits,
		loading,
		error,
		refetch,
	} = useGet(
		ServiceApiUrl.visitsByDate,
		{
			date: formatDate(dateRange.endDate, t("calendar.dateFormatGeoAnalytics")),
			city_id: idParish ? idParish : getIdClientVisits(nameClient),
		},
		{
			manual: true,
		}
	);

	const optionsDoughnutChart = (values: any) => {
		let periods: string[] = [];
		const dataSeries = values.map((value: any, index: number) => {
			if (index === 0) {
				periods = Object.keys(value).slice(1);
			}
			return {
				name: t("map.detail." + value.type) + " (" + unitChart + ")",
				type: "bar",
				emphasis: {
					focus: "series",
				},
				data: [value.morning, value.afternoon, value.night],
			};
		});
		const dataXAxis = periods.map((value: string) => {
			return t("map.detail." + value);
		});
		return {
			...defaultOptions,
			tooltip: {
				confine: true,
				extraCssText: 'white-space:inherit;',
				trigger: "axis",
				backgroundColor: theme.palette.background.paper,
				textStyle: {
					color: theme.palette.mode === "light" ? "" : theme.palette.common.white,
				},
				valueFormatter: function (value: any) {
					return value ? formatNumber(value) + " " + unitChart : "--";
				},
			},
			grid: {
				left: "13%",
				top: "15%",
				bottom: "19%",
			},
			legend: {
				...defaultOptions.legend,
				...{
					textStyle: {
						color: theme.palette.text.primary,
						fontFamily: theme.typography.fontFamily,
						fontSize: "12px",
					},
				},
			},
			xAxis: [
				{
					type: "category",
					axisTick: { show: false },
					data: dataXAxis,
					axisLine: {
						lineStyle: {
							color: theme.palette.mode === "light" ? "" : theme.palette.common.white
						}
					}
				},
			],
			yAxis: [
				{
					type: "value",
					axisLabel: {
						formatter: (val: any) => formatNumber(val),
					},
					axisLine: {
						lineStyle: {
							color: theme.palette.mode === "light" ? "" : theme.palette.common.white
						}
					}
				},
			],
			series: dataSeries,
		} as IReactECharts["option"];
	};

	useEffect(() => {
		if (geojsonTypeMap !== "analysis" && !analysisDetail) {
			refetch();
		} else if (geojsonTypeMap === "analysis" && analysisDetail) {
			setChartOptions(optionsDoughnutChart(data));
		}
	}, [dateRange, analysisDetail]);

	useEffect(() => {
		if (!loading && dataDailyVisits) {
			dispatch(setVisitsByDateChart(dataDailyVisits));
			setChartOptions(optionsDoughnutChart(dataDailyVisits));
		}
	}, [dataDailyVisits]);

	useEffect(() => {
		if (error) {
			dispatch(setVisitsByDateChart(null));
		}
	}, [error]);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Card>
					<CardContent className={styles["list-detail__card"]}>
						<Box>
							<Typography variant="body1">
								{t("map.detail.dailyVisits")}
							</Typography>
							<Typography fontSize="14px" mb={"1.5px"} mt="auto">
								{"(" +
									formatDate(dateRange.endDate, "d MMM") +
									" - " +
									t("average") +
									")"}
							</Typography>
						</Box>
						<Loading show={loading} />
						{!arrayIsEmpty(data) && !loading && (
							<BarChart option={chartOptions} style={{ minHeight: "300px" }} />
						)}
						{arrayIsEmpty(data) && !loading && <NoData error={error} />}
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
