import { TableHeadSort } from "@alb/live-lib";
import { Table, TableBody, TableContainer } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IResultTable } from "types/interfaces";

import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import { BugaTypeKey } from "types/enum";

import { BugaTableRow } from "./BugaTableRow";

interface IBugaTable extends IResultTable {
  keyURL: BugaTypeKey;
}

export const BugaTable = ({
  keyURL,
  data,
  page,
  orderBy,
  order,
  setPage,
  setOrder,
  setOrderBy,
}: IBugaTable) => {
  const { t } = useTranslation();

  function headerTable(key: BugaTypeKey) {
    switch (key) {
      case BugaTypeKey.bikes:
        return [
          {
            id: "status",
            label: t("common.statusLabel"),
          },
          {
            id: "name",
            label: t("common.name"),
          },
          {
            id: "docked",
            label: t("streams.docked"),
          },
          {
            id: "geolocation",
            label: t("streams.geolocation"),
          },
          {
            id: "date",
            label: t("common.updatedAt"),
          },
        ];
      case BugaTypeKey.station:
        return [
          {
            id: "status",
            label: t("common.statusLabel"),
          },
          {
            id: "name",
            label: t("common.name"),
          },
          {
            id: "free_bikes",
            label: t("streams.free_bikes"),
          },
          {
            id: "date",
            label: t("common.updatedAt"),
          },
        ];
    }
  }
  const handleRequestSort = (
    data: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHeadSort
            headCells={headerTable(keyURL)}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>
            {data?.data.map((elem: any, index: number) => (
              <BugaTableRow
                key={index}
                row={elem}
                sortOrder={headerTable(keyURL).map((el) => {
                  return el.id;
                })}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        count={data?.totalCount}
        page={page}
        totalPages={data?.totalPages}
        onPageChange={handleChangePage}
      />
    </>
  );
};
