/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutContext, Loading, MiniSelect } from "@alb/live-lib";
import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import { Box, Typography, useTheme } from "@mui/material";
import { subDays } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TAdapterConfigurator } from "types/types";

import FilterSection from "components/map/waze/FilterSection";
import WazeDashboard from "components/map/waze/WazeDashboard";
import WazeList from "components/map/waze/WazeList";
import { WazeMapCards } from "components/map/waze/WazeMapCards";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { DataModuleMap, IFilterDateRangeMap, setActiveModule, setMarkers } from "store/slices/mapSlice";
import { getSelectedModule } from "utils/modules/modules";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

export enum WazeAdapters {
	jams = "jams",
	alerts = "alerts",
}

export const getAdapterNameKey = (adapter: string) => {
  switch (true) {
    case adapter.toLocaleLowerCase().includes("jams"):
      return WazeAdapters.jams;
    case adapter.toLocaleLowerCase().includes("alerts"):
      return WazeAdapters.alerts;
    default:
      return adapter;
  }
};

const Waze = () => {

  const dateRange: IFilterDateRangeMap = {
		startDate: subDays(new Date(), 6),
		endDate: new Date(),
	};
  const { t } = useTranslation();
  const { addHeader } = useContext(LayoutContext);
  const theme = useTheme()
  const dispatch = useDispatch()
  const user = useSelector(getUser);
  const moduleID = getSelectedModule(user, MODULES_KEYS.WAZE);
  const [ adaptersOptions, setAdaptersOptions ] = useState<TOption[]>([])
  const [ selectedAdapter, setSelectedAdapter ] = useState<string>()
  const [ selectedDisplay, setSelectedDisplay ] = useState<'dashboard' | 'list'>('dashboard')
  const [ selectedRange, setSelectedRange ] = useState<IFilterDateRangeMap>(dateRange)
  const [ selectedType, setSelectedType ] = useState<null | string>(null)
  const [ filterSearch, setFilterSearch ] = useState<null | string>(null)
  const [ selectedLevel, setSelectedLevel ] = useState<null | string>(null)


  const {data: adaptersData, loading: loadingAdapters} = useGet(ServiceApiUrl.adapterConfigurators, { module_id: moduleID })

  

  const header = {
    title: t("sidebar.waze"),
  };

  useEffect(() => {
    addHeader(header);
    dispatch(setActiveModule(DataModuleMap.waze));

    return () => {
      // reset redux states
      dispatch(setMarkers([]));
      dispatch(setActiveModule(DataModuleMap.none));
    };
  }, []);


  useEffect(() => {
    if(adaptersData) {
      let options: TOption[];
      options = adaptersData.data.map((item: {id: string, name: string}) => {
        return {
          label: t(`waze.${getAdapterNameKey(item.name)}`),
          value: item.id
        }
      })
      setAdaptersOptions(options)
      setSelectedAdapter(getAdapterNameKey(adaptersData.data[0].name))
    }
  }, [adaptersData])

  const handleChangeOption = (selectedOption: TOption) => {
    const wazeAdapter = getAdapterNameKey(adaptersData.data.find((i: TAdapterConfigurator) => {
      return i.id === selectedOption.value
    }).name)
    setSelectedAdapter(wazeAdapter)
  }

  

  return <Box width={'100%'} paddingY={'25px'}>
    <Loading show={loadingAdapters} />
    {!!adaptersOptions.length && <Box display={'flex'} alignItems={'center'}>
      <Typography mr={1} variant="h6">
        {t("dashboard.dataFrom")}
      </Typography>
      <MiniSelect
        onChangeOption={handleChangeOption}
        showLabel
        showBorder
        sx={{
          ...(theme.palette.mode === "light" && {
            backgroundColor: "#ECF8F8",
          }),
        }}
        borderColor="rgba(81,191,188,0.5)"
        options={adaptersOptions}
        defaultValue={adaptersOptions[0]}
      />
    </Box>}

    {!!selectedAdapter && <>
        <WazeMapCards
          selectedAdapterKey={selectedAdapter}
          filterSelectedRange={selectedRange}
        />

        <FilterSection 
          selectedDisplay={selectedDisplay} 
          setSelectedDisplay={setSelectedDisplay}
          selectedRange= {selectedRange}
          setSelectedRange={setSelectedRange}
          selectedAdapter={selectedAdapter}
          setSelectedType={setSelectedType}
          setFilterSearch={setFilterSearch}
          setSelectedLevel={setSelectedLevel}
        />

        <Box width={'100%'} marginTop={'20px'}>
          {selectedDisplay === 'dashboard' ? 
            <WazeDashboard filterRange={selectedRange} selectedWazeAdapter={selectedAdapter} /> 
            : 
            <WazeList selectedAdapter={selectedAdapter} selectedFilterRange={selectedRange} selectedType={selectedType} selectedLevel={selectedLevel} filterSearch={filterSearch} />
          }
        </Box>
      </>
    }
    
  </Box>
}

export default Waze;
