import { CardInfo, Loading } from "@alb/live-lib";
import { Grid, SvgIcon, SvgIconTypeMap, Typography, useTheme } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useTranslation } from "react-i18next";

import { moduleCardsIcons } from "components/modules/cards/moduleCardsIcons";
import { IModuleCardItem } from "components/modules/ModuleCards";
import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import i18n from "i18n/config";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IFilterDateRangeMap } from "store/slices/mapSlice";

interface IWazeMapCards {
  selectedAdapterKey: string;
  filterSelectedRange: IFilterDateRangeMap;
}
export const WazeMapCards = ({
  selectedAdapterKey,
  filterSelectedRange,
}: IWazeMapCards) => {
  const { t } = useTranslation();
  const theme = useTheme();


  const {
    data: cards,
    loading,
    error,
  } = useGet<IModuleCardItem[]>(
    ServiceApiUrl?.wazeCards(selectedAdapterKey),
    getParams()
  );

  function getParams() {
    var params = {
      ...(filterSelectedRange && {
        from: filterSelectedRange.startDate,
        to: filterSelectedRange.endDate,
      }),
    };
    return params;
  }

  const finalCards = cards?.map((card: IModuleCardItem) => {
    return {
      ...card,
      value: i18n.exists(`waze.types.${card.value}`)
        ? t(`waze.types.${card.value}`)
        : card.value,
    };
  });

  const getIcon = (name: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & { muiName: string; }) => {
		const color = theme.palette.mode === 'light' ? theme.palette.neutral5.main : theme.palette.neutral5.dark;
		return <SvgIcon
			sx={{ width: '44px', height: '44px', color: color }}
			component={name}
			inheritViewBox
		/>
	}

  function showTranslationName(name: string) {
		return i18n.exists(`waze.state.${name}`)
			? t(`waze.state.${name}`)
			: name;
	}

  const formatValue = (value: string | number | undefined) => {
    if(typeof value === 'number') {
      return <Typography fontWeight={'bold'} fontSize={'30px'} lineHeight={'30px'}>{value}</Typography>
    }
    return value
  }

  return (
    <>

      {/*<ModuleCards
        module="waze"
        items={finalCards}
        loading={loading}
        error={error}
        dataTestId={"WazeCardsContainer"}
        sx={{mt: '20px'}}
      />*/}

      <Grid container spacing={2} marginTop={'5px'}>
        {!loading && !error && finalCards && finalCards.map((card, index) => {
          return <Grid key={index} item lg={12 / finalCards.length} sm={12}>
              <CardInfo icon={getIcon(moduleCardsIcons[card?.name])} title={showTranslationName(card.name)}  type={t(`common.${card.type}`)} value={formatValue(card.value)} />
            </Grid>
        })}
        {loading && <Grid item sm={12}><Loading show={loading} /></Grid>}
        {!loading && error && <Grid item sm={12}><NoData error={error} /></Grid>}
      </Grid>

      {/*<Grid item xs={12} mt={2}>
        <Card>
          <CardContent>
            <Typography variant="h3" fontSize={"22px"}>
              {getTitleChartCard(selectedAdapterKey)}
            </Typography>
            <WazeTypesChart
              selectedRange={filterSelectedRange}
              selectedAdapterKey={selectedAdapterKey}
            />
          </CardContent>
        </Card>
  </Grid>*/}
    </>
  );
};
