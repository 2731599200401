import { TAdapterConfigurator, TModule } from 'types/types'

export const formDefaultValues = (data?: TModule) => {
  const defaultValues = {
    old_module: false,
    name: data?.name || '' ,
    adapter_configurators: (data?.adapter_configurators.map((p: TAdapterConfigurator) => {
      return { label: p.name, value: p.id }
    }) || []),
    extra_params: JSON.stringify(data?.extra_params) || '',
    endpoint_type: data?.endpoint_type || '',
  }
  return defaultValues
}
