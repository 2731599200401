import { arrayIsEmpty, colorsMarkers, MapType, useFeedback } from "@alb/live-lib";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IAnalysisForm, IGeojsonFeatures, IGeojsonFeaturesCollection } from "types/interfaces";

import useCreate from "hooks/fetchData/useCreate";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getFilterDateRange, getGeojsonFilesUpload, getSelectedMapType, setGeojsonFilesUpload, setGeojsonType, setSelectedAnalysis } from "store/slices/mapSlice";
import { getOverviewChart, getPresenceChart, getVisitsByDateChart, getVisitsByDateRangeChart } from "store/slices/peopleMobilitySlice";
import { formatDate, getLastWeekRangeDates } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

import { AnalysisForm } from "./AnalysisForm";

interface IAnalysisAdd {
  open: boolean;
  onClose: () => void;
  getGeojsonUpdate?: (
    features: IGeojsonFeatures[],
    analysis: boolean
  ) => IGeojsonFeaturesCollection;
}

const AnalysisAdd = ({
  open,
  onClose,
  getGeojsonUpdate,
}: IAnalysisAdd) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const dispatch = useDispatch();

  const multiFilesUpload = useSelector(getGeojsonFilesUpload);
  const selectedMapType = useSelector(getSelectedMapType);
  const selectedRangeDate = useSelector(getFilterDateRange);
  const infoOverviewChart = useSelector(getOverviewChart);
  const infoPresenceChart = useSelector(getPresenceChart);
  const infoVisitsDateChart = useSelector(getVisitsByDateChart);
  const infoVisitsDateRangeChart = useSelector(getVisitsByDateRangeChart);

  const addFormId = "add-analysis-form";

  const { startDate: startDateDefault, endDate: endDateDefault } =
    getLastWeekRangeDates(t("calendar.dateFormatGeoAnalytics"));

  const { headers } = useGetModuleHeaders(MODULES_KEYS.PEOPLEMOBILITY);

  //create analysis
  const {
    loading,
    refetch: addAnalysis,
  } = useCreate(ServiceApiUrl.analysis, undefined, headers);

  // success
  const handlerSuccess = () => {
    onClose && onClose(); //close modal
    addFeedback({
      message: t("analysis.analysisAdded"),
      severity: "success",
    });
    //clean uploaded files
    if (!arrayIsEmpty(multiFilesUpload)) {
      dispatch(setGeojsonFilesUpload(null));
      dispatch(setGeojsonType("analysis")); // é analysis e nao parishes porque quando se adiciona 1 analise a mesma aparece logo no mapa
    }
  };

  function getDatesToName(range: any) {
    const iDate = formatDate(range[0], t("calendar.dateFormatSimple"));
    const fDate = formatDate(range[1], t("calendar.dateFormatSimple"));
    if (iDate === fDate) {
      return " (" + iDate + ")";
    } else {
      return " (" + iDate + " - " + fDate + ")";
    }
  }

  const formSubmitHandler = async (payLoad: IAnalysisForm) => {
    // obter apenas os files a true
    const selectedFiles =
      payLoad?.files &&
      Object.keys(payLoad?.files).filter((v: string) => payLoad?.files?.[v]);
    //obtem os objetos de cada um
    let chosenLoadedFiles: IGeojsonFeatures | IGeojsonFeatures[] =
      multiFilesUpload?.filter((f: IGeojsonFeatures) =>
        selectedFiles?.includes(f.id)
      ) || [];

    let result: IGeojsonFeaturesCollection | undefined = undefined;
    if (!arrayIsEmpty(chosenLoadedFiles) && getGeojsonUpdate) {
      result = getGeojsonUpdate(chosenLoadedFiles, true);
      const dateFormat = "yyyy-MM-dd";

      //vai verificar se existem files com filters
      let fileWFilters = chosenLoadedFiles.find((f: IGeojsonFeatures) =>
        f.hasOwnProperty("filters")
      );
      const rangeDateCustomFile =
        fileWFilters && fileWFilters?.filters?.dateRange;
      //se existir file custom, coloca o range guardado nos filters, se não, coloca a data default
      const iDate = formatDate(
        selectedMapType === MapType.heat
          ? rangeDateCustomFile
            ? rangeDateCustomFile[0]
            : startDateDefault
          : selectedRangeDate.startDate,
        dateFormat
      );
      const fDate = formatDate(
        selectedMapType === MapType.heat
          ? rangeDateCustomFile
            ? rangeDateCustomFile[1]
            : endDateDefault
          : selectedRangeDate.endDate,
        dateFormat
      );
      //adiciona os filters ao objeto
      Object.assign(result, { filters: { dateRange: [iDate, fDate] } });

      //Substituí as cor guardada pela primeira cor no colorsArray
      if (
        result?.markers &&
        !arrayIsEmpty(result?.markers) &&
        selectedMapType === MapType.heat
      ) {
        const allMarkers = result.markers.map((feature: GeoJSON.Feature) => {
          return {
            ...feature,
            properties: {
              ...feature.properties,
              color: colorsMarkers[0],
            },
          };
        });
        Object.assign(result, { markers: allMarkers });
      }
    }
    const finalPayLoad = {
      map_type: selectedMapType,
      ...(result && {
        name: payLoad.name + getDatesToName(result.filters?.dateRange),
        geojson: result,
      }),
      charts: {
        overview: infoOverviewChart,
        presence: infoPresenceChart,
        visits_by_date: infoVisitsDateChart,
        visits_by_date_range: infoVisitsDateRangeChart,
      }
    };
    try {
      await addAnalysis({ data: finalPayLoad }).then((response) => {
        if (response.status === 201) {
          //created
          if (response?.data) dispatch(setSelectedAnalysis(response?.data?.id)); //dispatch da nova análise criada
          handlerSuccess();
        }
      });
    } catch (error) {}
  };

  const [formIsValid, setFormIsValid] = useState(false);
  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
          {t("analysis.addAnalysis")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <AnalysisForm
          formId={addFormId}
          onFormSubmit={formSubmitHandler}
          setFormIsValidStatus={getFormIsValidStatus}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!formIsValid}
          type={"submit"}
          form={addFormId}
        >
          {t("common.add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AnalysisAdd;
