import { Loading, TableHeadCell, TableHeadOrder, TableHeadSort } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import AddIcon from "@mui/icons-material/Add";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DeleteIcon from "@mui/icons-material/DeleteRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Collapse, Grid, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TAccount, TAdapterConfigurator, TModule } from "types/types";

import { ChipStatus } from "components/Utils/ChipStatus";
import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { TableCellExpanded, TableExpanded, TableRowExpanded } from "styles/css/components";
import { formatDate } from "utils/date";
import { formatOrderBy } from "utils/orderByAPI";
import { PERMISSIONS } from "utils/permissions/permissions";
import { statusColor, arrayIsEmpty } from "utils/utils";

import AccountsAdd from "./AccountsAdd";
import AccountsDelete from "./AccountsDelete";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";

interface IModulesListTab {
	filters: { clientID: string };
}

function Row(props: {
	row: TAccount;
	open: boolean;
	onClick: Function;
	actionsClick: {
		editAction: Function;
		deleteAction: Function;
	};
	showExpandCell:boolean;
}) {
	const { row, open, onClick, actionsClick, showExpandCell } = props;
	const { deleteAction } = actionsClick;
	const { t } = useTranslation();
	const user = useSelector(getUser);

	// PERMISSIONS
	const { hasPermission } = usePermission();
	const canDelete = hasPermission(PERMISSIONS.ADMINISTRATION.CLIENTS.DELETE);

	const rowExpandCell = !arrayIsEmpty(row.adapter_configurators);


	//Só os superAdmins podem eliminar contas admin
	const hasPermitionToDelete = row.admin
    ? user.is_superadmin && canDelete
    : canDelete;

	return (
		<>
			<TableRow
				className={clsx({ "no-pointer": !rowExpandCell, expanded: open })}
				onClick={() => {
					if (!rowExpandCell) return void (0);
					onClick(open ? "" : row.id)
				}}
			>

				{showExpandCell &&
					<TableCell sx={{ width: 50 }} size="small">
						{rowExpandCell && (
							<IconButton >
								{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
							</IconButton>
						)}
					</TableCell>
				}

				<TableCell>
					<Stack direction="row" alignItems="flex-end" gap={1}>
						{row.name}
						{row.admin && (
							<AdminPanelSettingsIcon
								fontSize="small"
								color={"primary"}
								titleAccess={t("accounts.adminLabel")}
							/>
						)}
					</Stack>
				</TableCell>
				<TableCell>
					<ChipStatus
						label={row.is_active ? t("common.active") : t("common.inactive")}
						color={
							row.is_active ? statusColor("active") : statusColor("non-active")
						}
					/>
				</TableCell>
				<TableCell>
					{formatDate(row.created_at, t("calendar.dateTimeFormatLocal"))}
				</TableCell>
				<TableCell>
					{formatDate(row.updated_at, t("calendar.dateTimeFormatLocal"))}
				</TableCell>
				<TableCell align="right">
					{hasPermitionToDelete && (
						<IconButton
							title={t("common.delete")}
							aria-label="delete"
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								deleteAction(true, e, row);
							}}>
							<DeleteIcon />
						</IconButton>
					)}
				</TableCell>
			</TableRow>
			{showExpandCell && (
				<TableRowExpanded>
					<TableCellExpanded colSpan={7}>
						<Collapse in={open} timeout="auto" unmountOnExit>
							<GatewaysList
								accountID={row.id}
							/>
						</Collapse>
					</TableCellExpanded>
				</TableRowExpanded>
			)}
		</>
	);
}

function GatewaysList(props: {
	accountID: string;
}) {
	const { t } = useTranslation();
	const { accountID } = props;
	const [page, setPage] = useState(1);

	const itemsPerPage = 6

	const {
		data: adapters,
		loading,
	} = useGet(ServiceApiUrl.adapterConfigurators, {
		account_id: accountID,
		page: page,
		items: itemsPerPage,
	});

	const handleChangePage = (
		newPage: number
	  ) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	  };

	return (
		<>
			<Typography variant="h5" className="bold">
				{t("clients.listDetails.adaptersList")}
			</Typography>

			{loading && <Loading show={loading}/>}

			{arrayIsEmpty(adapters?.data) && !loading && (
				<Typography mt={4} mb={2} variant="body1">
					{t("adapters.noAdapters")}
				</Typography>
			)}

			{!arrayIsEmpty(adapters?.data) && (
				<>
				<TableContainer>
					<Table sx={{ minWidth: 600, marginTop: 1 }}>
						<TableHead>
							<TableRow>
								<TableCell>{t("common.name")}</TableCell>
								<TableCell>{t("common.statusLabel")}</TableCell>
								<TableCell>{t("common.createdAt")}</TableCell>
								<TableCell>{t("common.updatedAt")}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{adapters?.data.map((adapter: TAdapterConfigurator) => (
								<TableRow key={adapter.id}>
									<TableCell>{adapter.name}</TableCell>
									<TableCell>
										<ChipStatus
											label={
												adapter.is_active
													? t("common.active")
													: t("common.inactive")
											}
											color={
												adapter.is_active
													? statusColor("active")
													: statusColor("non-active")
											}
										/>
									</TableCell>
									<TableCell>
										{formatDate(
											adapter.created_at,
											t("calendar.dateTimeFormatLocal")
										)}
									</TableCell>
									<TableCell>
										{formatDate(
											adapter.updated_at,
											t("calendar.dateTimeFormatLocal")
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>

				<CustomTablePagination
				count={adapters?.totalCount}
				page={page}
				totalPages={adapters?.totalPages}
				onPageChange={handleChangePage}
				itemsPage={itemsPerPage}
				/>
			</>
			)}
		</>
	);
}

function AccountsListTab({ filters }: IModulesListTab) {
	const { t } = useTranslation();

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");

	const {
		data: accounts,
		loading,
		refetch,
	} = useGet(ServiceApiUrl.accounts, {
		client: filters.clientID,
		page: page,
		items: ROWS_PER_PAGE,
		order_by: formatOrderBy(order, orderBy),
	});

	useEffect(() => {
		setPage(1);
	}, [filters.clientID]);

	//para fazer nova chamada dos Módulos
	const updateAccounts = () => {
		refetch();
	};

	const [openRow, setOpenRow] = useState("");
	// PERMISSIONS
	const { hasPermission } = usePermission();
	const crudPermissions = hasPermission(PERMISSIONS.ADMINISTRATION.CREATE);
	const canList = hasPermission(PERMISSIONS.ADMINISTRATION.READ);

	const [selectedModule, setSelectedModule] = useState<TAccount>();
	const [openDialogEdit, setOpenDialogEdit] = useState(false);
	const [openDialogDelete, setOpenDialogDelete] = useState(false);

	//atualiza o estado do modal de remover
	const handlerDialogDeleteModule = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		account: TAccount
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedModule(account);
		}
		setOpenDialogDelete(!openDialogDelete);
	};

	//atualiza o estado do modal de editar
	const handlerDialogEditModule = (
		open = false,
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		account: TAccount
	) => {
		if (open) {
			e.stopPropagation(); //evitar que abra o event do parent (a row)
			setSelectedModule(account);
		}
		setOpenDialogEdit(!openDialogEdit);
	};

	//Trocar página da listagem
	const handleChangePage = (
		newPage: number
	) => {
		setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
	};

	const [openDialogAddMod, setOpenDialogAddMod] = useState(false);
	const onAddClick = () => {
		setOpenDialogAddMod(true);
	};

	const accountsHasAdapterConfigurators = () => {
		return accounts?.data.some((e: TModule) => e.adapter_configurators && e.adapter_configurators.length > 0)
	}
	const showExpandableColumn = useMemo(() => {
		return accountsHasAdapterConfigurators();
	}, [accounts?.data])

	const expandableColumn = {
		id: "expand_cell",
		label: "",
		notSortable: true,
	}

	const tableHeads: TableHeadCell[] = [
		{
			id: "name",
			label: t("common.name"),
		},
		{
			id: "is_active",
			label: t("common.statusLabel"),
		},
		{
			id: "created_at",
			label: t("common.createdAt"),
		},
		{
			id: "updated_at",
			label: t("common.updatedAt"),
		},
		{
			id: "action_cell",
			label: "",
			notSortable: true,
		},
	];
	if (showExpandableColumn) tableHeads.unshift(expandableColumn);

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: string
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	return (
		<div>
			<Grid
				container
				mt={1}
				alignItems="center"
				direction="row"
				sx={{ justifyContent: "end" }}
			>
				<Grid item xs={true}>
					<Typography variant="h5" className="bold">
						{t("clients.listDetails.accountsList")}
					</Typography>
				</Grid>
				{crudPermissions && (
					<Grid item>
						<Button
							color="primary"
							onClick={onAddClick}
							variant="contained"
							size="medium"
							startIcon={<AddIcon />}
						>
							{t("common.add")}
						</Button>
					</Grid>
				)}
			</Grid>
			{/* <LiveLoading show={loading} /> */}

			{arrayIsEmpty(accounts?.data) && !loading && canList && (
				<Typography mt={4} mb={2} variant="body1">
					{t("accounts.noAccounts")}
				</Typography>
			)}

			{accounts && !arrayIsEmpty(accounts?.data) && !loading && (
				<>
					<TableContainer>
						<TableExpanded>
							<TableHeadSort
								headCells={tableHeads}
								onRequestSort={handleRequestSort}
								order={order}
								orderBy={orderBy}
							/>
							<TableBody>
								{accounts?.data?.map((account: TAccount, index: number) => (
									<Row
										key={account.id}
										row={account}
										open={account.id === openRow}
										onClick={(id: string) => setOpenRow(id)}
										actionsClick={{
											editAction: handlerDialogEditModule,
											deleteAction: handlerDialogDeleteModule,
										}}
										showExpandCell={showExpandableColumn}
									/>
								))}
							</TableBody>
						</TableExpanded>
					</TableContainer>

					<CustomTablePagination
						count={accounts?.totalCount}
						page={page}
						totalPages={accounts?.totalPages}
						onPageChange={handleChangePage}
					/>

				</>
			)}

			{crudPermissions && (
				<AccountsAdd
					clientID={filters.clientID}
					open={openDialogAddMod}
					onClose={() => setOpenDialogAddMod(false)}
					updateList={updateAccounts}
					administrationPage
				/>
			)}

			{crudPermissions && selectedModule && openDialogDelete && (
				<AccountsDelete
					account={selectedModule}
					clientID={filters.clientID}
					open={openDialogDelete}
					handlerClose={() => setOpenDialogDelete(false)}
					updateList={updateAccounts}
				/>
			)}
		</div>
	);
}

export default AccountsListTab;
