import { Loading } from "@alb/live-lib";
import { Box, useTheme } from "@mui/material";
import { AxiosError } from "axios";
import * as echarts from "echarts";
import ReactEcharts from "echarts-for-react";
import { useSelector } from "react-redux";

import NoData from "components/Utils/NoData";
import { getNameClient } from "store/slices/authSlice";
import { arrayIsEmpty } from "utils/utils";
import { formatNumber } from "utils/number";

import { rangeOpenOccurrences } from "./utils/colorsOccurrences";
import { defaultOptionsOccurrences, textStyle } from "./utils/optionsOccurrences";

export interface IChartClientCityMap {
  data: { name: string; value: number }[];
  loading: boolean;
  error: AxiosError<any, any> | null;
}

interface IValueChart {
  name: string;
  value: number;
}

const ChartClientCityMap = ({
  data,
  loading,
  error,
}: IChartClientCityMap) => {
  const nameClient = useSelector(getNameClient);
  const theme = useTheme();
  const colors = rangeOpenOccurrences();

  const option = {
    ...defaultOptionsOccurrences(theme),
    geo: {
      select: "disabled",
      map: nameClient?.toLowerCase(),
      roam: false,
      emphasis: {
        itemStyle: {
          color: null,
        },
        label: {
          show: false,
        },
      },
    },
    visualMap: {
      left: "right",
      top: "20px",
      type: "piecewise",
      min: 0,
      max: data ? Math.max(...data.map((v: IValueChart) => v.value), 0) : 0,
      seriesIndex: 0,
      itemWidth: 22,
      itemHeight: 22,
      itemGap: 2,
      hoverLink: false,
      inRange: {
        color: [
          // range de cores (primary)
          // "#51bfbc",
          // "#4eb5b4",
          // "#4babab",
          // "#47a1a3",
          // "#44979b",
          // "#418d92",
          // "#3e848a",
          // "#3a7a82",
          // "#377079",
          // "#346671",
          // "#315c69",
          // "#2d5260",
          // "#2a4858",

          // red colors
          ...colors,
        ],
      },
      textStyle: textStyle(theme),
      calculable: true,
      formatter: function (value: string, value2: string) {
        return formatNumber(value) + " - " + formatNumber(value2);
      },
    },
    series: [
      {
        nodeClick: false,
        name: "occurrences",
        type: "map",
        geoIndex: 0,
        data: data,
      },
    ],
  };

  const geoCity = require(`assets/${nameClient?.toLowerCase()}.json`);
  echarts.registerMap(nameClient?.toLowerCase(), geoCity);

  const checkData = (data: object) => {
    let haveValues = 0;
    if (data) {
      for (const value of Object.values(data)) {
        haveValues += value.value;
      }
    }
    return haveValues === 0;
  };

  return (
    <Box sx={{ height: "100%", display: "grid", alignItems: "center" }}>
      <Loading show={loading} />
      {!arrayIsEmpty(data) && !checkData(data) && !loading && (
        <ReactEcharts style={{height: "100%"}} option={option} />
      )}
      {checkData(data) && !loading && <NoData error={error} />}
    </Box>
  );
};

export default ChartClientCityMap;
