import { TableCell, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IElectricMobility } from 'types/interfaces'
import { formatDate } from 'utils/date'
import { formatNumber } from 'utils/number'

const TableInformationRow = (props: {
  row: IElectricMobility
}) => {
  const { row } = props
  const { t } = useTranslation()

  return (
    <>
      <TableRow>
        <TableCell>{row.name}</TableCell>
				<TableCell align={'right'}>{formatNumber(row.duration)}</TableCell>
				<TableCell align={'right'}>{formatNumber(row.consumption_kWh)}</TableCell>
				<TableCell align={'right'}>{formatNumber(row.cost)}</TableCell>
				<TableCell align={'right'}>{formatNumber(row.cost_kWh)}</TableCell>
				<TableCell align={'right'}>{formatNumber(row.CO2_reduction)}</TableCell>
				<TableCell>
					{/* {row.start_transaction} */}
          {formatDate(row.start_transaction, t('calendar.dateTimeFormatLocal'))}
        </TableCell>
				<TableCell>
					{/* {row.end_transaction} */}
          {formatDate(row.end_transaction, t('calendar.dateTimeFormatLocal'))}
        </TableCell>
      </TableRow>
    </>
  )
}

export default TableInformationRow
