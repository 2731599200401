import React from 'react'
import { IQArtFilters } from './QArtContainer';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { QArtCardContentStyled, QArtCardHeaderStyled } from './QArtCardStyle';
import QArtCard from './QArtCard';
import { IPaletteColor, IPollutantLimits, getColor, getLimit } from './QArtColorsLimits';
import useGet from 'hooks/fetchData/useGet';
import { ServiceApiUrl } from 'services/ServiceApiUrl';
import { IQARTDevice } from './qartDeviceInterface';
import { Loading, arrayIsEmpty } from '@alb/live-lib';

import { differenceInDays, startOfDay, endOfDay } from 'date-fns';
import { TDateRange } from './QArtFilters';

import QArtGlobalCard from './QArtGlobalCard';
import QArtChart from './QArtChart';
import QArtGlobalChart from './QArtGlobalChart';

import { GlobalInfoTooltip } from './QArtStyled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import QArtTableLimits from './QArtTableLimits';
import NoData from 'components/Utils/NoData';
import { objectIsEmpty } from 'utils/utils';

// ALL POLLUTANTS
// 'o3,no2,particules_pm10,particules_pm25,co,no,particules_pm01,particules_pm07,particules_pm05,particules_mass';
const VIEW_POLLUTANTS = 'o3,no2,particules_pm10,particules_pm25';

export interface IQArtCardItem {
	name: string,
	type: string,
	value: number,
	unit: string,
	color: IPaletteColor,
}
export interface IQArtGraphItem extends IQArtCardItem {
	collect_date: Date
}
export interface IQArtCards {
	device: IQARTDevice,
	filters: IQArtFilters
}

interface IQArtValues {
	cards: IQArtCardItem[],
	global: { cards: IQArtCardItem, graphs: IQArtGraphItem[] },
	graphs: [IQArtGraphItem[]]
}

export interface IGraphsInfo {
	data: {
		name: string; label: string; value: number; itemStyle: { color: string; } | { color: string; };
		emphasis: { itemStyle: { color: string; }; } | { itemStyle: { color: string; }; };
	}[];
	limits: IPollutantLimits[];
	yMaxValue: number
}

function getDaysByDateRange({ startDate, endDate }: TDateRange) {
	const days = differenceInDays(endOfDay(endDate), startOfDay(startDate));
	return days;
}

function getIntervalByDateRange({ startDate, endDate }: TDateRange) {
	const days = getDaysByDateRange({ startDate, endDate });
	return days >= 1 ? 'day' : 'hour';
}
function getLegendNameByInterval({ startDate, endDate }: TDateRange) {
	const days = getDaysByDateRange({ startDate, endDate });
	return days >= 1 ? 'common.dailyAvg' : 'common.hourlyAvg';
}

function QArtCardChart({ device, filters }: IQArtCards) {
	const { t, i18n } = useTranslation();

	function showTranslationName(name: string) {
		return i18n.exists(`qart.pollutantStreams.${name}`)
			? t(`qart.pollutantStreams.${name}`)
			: name;
	}

	const interval = getIntervalByDateRange(filters?.dateRange);
	// get  the data for this device
	const params = {
		external_id: device.external_id,
		from: filters ? filters.dateRange.startDate : undefined,
		to: filters ? filters.dateRange.endDate : undefined,
		streams: VIEW_POLLUTANTS,
		range: interval,
	}

	const { data, loading, error } = useGet<IQArtValues>(ServiceApiUrl.qartValues, params);

	let cards: IQArtCardItem[] = [];
	if (data?.cards) {
		const oCards = data.cards;
		oCards.forEach((card: IQArtCardItem) => {
			const color = getColor(card.name, card.value)
			cards.push({ name: card.name, value: card.value, type: card.type, unit: card.unit, color: color })
		});
	}

	let globalCards: IQArtCardItem | undefined = undefined;
	let globalGraphs: IQArtGraphItem[] = [];
	if (data?.global && !objectIsEmpty(data.global)) {
		const gCards = data.global.cards;
		const color = getColor(gCards.name, gCards.value)
		globalCards = { ...gCards, color: color }

		globalGraphs = data.global.graphs;
	}

	const getGlobalCardTitle = (item: IQArtCardItem) => {
		const classif = getLimit(item.name, item.value);

		return <>
			<Typography component={'span'}>{t('qart.airQualityIndex')}</Typography>
			<Typography component={'span'} variant='h5' color={item.color.main}>{t(`qart.pollutantClassification.${classif?.classification}`)}</Typography>
		</>;
	}

	const getGlobalCardAction = (pollutant: string, value?: number) => {
		return (<>
			<GlobalInfoTooltip title={<QArtTableLimits pollutant={pollutant} value={value} />}>
				<InfoOutlinedIcon sx={{ cursor: 'help', marginLeft: 1 }} fontSize="small" color="primary" />
			</GlobalInfoTooltip>
		</>)
	}

	return (
		<>
			<Loading show={loading} />
			{!loading && arrayIsEmpty(cards) && <NoData message={t('common.noData')} />}
			{!loading && error && <NoData error={error} />}

			{!error && !loading && !arrayIsEmpty(cards) && globalCards &&
				<Grid container spacing={{ xs: 2, md: 3 }}>
					<Grid item xs={12} sm={6} md={4}>
						<Stack direction={"column"}>
							<Card>
								<QArtCardHeaderStyled title={getGlobalCardTitle(globalCards)} action={getGlobalCardAction(globalCards.name, globalCards.value)} />
								<QArtCardContentStyled>
									<QArtGlobalCard item={globalCards} />
									{!arrayIsEmpty(globalGraphs) && <QArtGlobalChart data={globalGraphs} item={globalCards} legendName={t(getLegendNameByInterval(filters?.dateRange))} interval={interval} />}
								</QArtCardContentStyled>
							</Card>
						</Stack>
					</Grid>
					{cards.map((item: IQArtCardItem, index: number) => {
						return (
							<Grid item xs={12} sm={6} md={4} key={index}>
								<Stack direction={"column"}>
									<Card>
										<QArtCardHeaderStyled title={showTranslationName(item.name)} />
										<QArtCardContentStyled>
											<QArtCard item={item} />
											<QArtChart data={data?.graphs} item={item} legendName={t(getLegendNameByInterval(filters?.dateRange))} interval={interval} />
										</QArtCardContentStyled>
									</Card>
								</Stack>
							</Grid>)
					})
					}
				</Grid>
			}
		</>
	)
}

export default QArtCardChart
