import { TableHeadCell, TableHeadSort } from "@alb/live-lib";
import { Grid, TableBody, TableContainer, Typography } from "@mui/material";
import { t } from "i18next";
import { TableExpanded } from "styles/css/components";
import { TGroup, TUser } from "types/types";
import UsersTabRow from "./UsersTabRow";
import { useEffect, useState } from "react";


const UsersTab = (props: { users: TUser[], group: TGroup, refetchGroups: () => void }) => {

    const { users, group, refetchGroups } = props;
    
    const tableHeads: TableHeadCell[] = [
        {
          id: "name",
          label: t("common.name"),
          notSortable: true
        },
        {
          id: "email",
          label: t("user.email"),
          notSortable: true
        },
        {
          id: "removeUser",
          label: "",
          notSortable: true
        },
      ];


      const [list, setList] = useState<any>([]);
      useEffect(() => {
        if (users) {
            setList([])
            const newList = users.map((u: TUser) => <UsersTabRow key={u.id} user={u} group={group} refetchGroups={refetchGroups} />);
            setList((prevList: any) => [...prevList, ...newList]);
        }
        // eslint-disable-next-line
      }, [users]);

    return(<>
        <Grid
        container
        mt={1}
        alignItems="center"
        direction="row"
        sx={{ justifyContent: "end" }}
      >
            <Grid item xs={true}>
                <Typography variant="h3">{t("common.users")}</Typography>

                <TableContainer>
                    <TableExpanded>
                        <TableHeadSort
                            headCells={tableHeads}
                            onRequestSort={() => {}}
                            order={"asc"}
                            orderBy={"ascs"}
                        />
                        <TableBody>
                           {list}
                        </TableBody>
                    </TableExpanded>
                </TableContainer>
            </Grid>
      </Grid>




    </>)
}

export default UsersTab;