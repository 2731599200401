import { ChipCustom } from "@alb/live-lib";
import { TableCell, TableRow } from "@mui/material";
import { LatLngExpression } from "leaflet";
import { useTranslation } from "react-i18next";

import { formatDate } from "utils/date";

import { colorsStatusBuga } from "../utils/colorsStatus";

interface IBugaTableRow {
  row: {
    [key: string]: string | boolean | Date | LatLngExpression[];
  };
  sortOrder: string[];
}
export const BugaTableRow = ({ row, sortOrder }: IBugaTableRow) => {
  const { t } = useTranslation();

  function showDefaultValue(
    param: string | number | boolean | JSX.Element | null
  ) {
    return param ? param : "--";
  }
  function showColumnValue(row: any) {
    switch (Object.keys(row)[0]) {
      case "status":
        const value = String(row.status).toLowerCase();
        const status = (
          <ChipCustom
            label={t("buga.list.status." + value)}
            colorChip={colorsStatusBuga(value).chip}
            colorLabel={colorsStatusBuga(value).text}
          />
        );
        return <>{showDefaultValue(status)}</>;
      case "name":
        return <>{showDefaultValue(row.name)}</>;
      case "docked":
        const docked = row.docked ? t("buga.list.docked." + row.docked) : null;
        return <>{showDefaultValue(docked)}</>;
      case "geolocation":
        const coordinates = row.geolocation
          ? String(row.geolocation[0] + ", " + row.geolocation[1])
          : null;
        return <>{showDefaultValue(coordinates)}</>;
      case "date":
        const dateTime = formatDate(
          row.date,
          t("calendar.dateTimeFormatLocal")
        );
        return <>{showDefaultValue(dateTime)}</>;
      case "free_bikes":
        return <>{showDefaultValue(row.free_bikes)}</>;
      default:
        return <>{showDefaultValue(null)}</>;
    }
  }
  return (
    <TableRow sx={{ cursor: "pointer", height: 44 }}>
      {sortOrder.map((elem: string, index: number) => (
        <TableCell key={index}>
          {showColumnValue({ [elem]: row[elem] })}
        </TableCell>
      ))}
    </TableRow>
  );
};
