import { IUserCustomTranslations } from "types/interfaces";
import { emailRegex } from "utils/regex";
import { array, mixed, object, string, boolean } from "yup";

export const InputsLengths = {
  max: {
    email: 254,
    firstName: 150,
    lastName: 150,
    userName: 30,
    password: 20,
  },
  min: {
    userName: 3,
    password: 5,
  },
};

export const getUserSchema = (customTranslations: IUserCustomTranslations) =>
object({
  is_superadmin: boolean(),
  is_admin: boolean(),
  email: string().max(InputsLengths.max.email).email().matches(emailRegex, customTranslations.matchesEmail).required(),
  first_name: string().max(InputsLengths.max.firstName),
  last_name: string().max(InputsLengths.max.lastName),
  account: object()
  .shape({
      label: string(),
      value: string()
    })
    .when(['is_admin', 'is_superadmin'], {
    is:  (is_admin: boolean, is_superadmin: boolean) => !is_admin && !is_superadmin,
    then: () => object().nullable().required(),
    otherwise: () => object().nullable().notRequired(),
  }),
  username: string()
    .min(InputsLengths.min.userName)
    .max(InputsLengths.max.userName)
    .required(),
}).shape({
  scope: array().transform((o, obj )=> {
    return obj
  }).min(1)
});

  export const setModuleSchema = (required: string) =>
  object({
    adapter_configurators:
      mixed()
      // .test("required", required, (value) => Array.isArray(value) && value.length > 0),
  });
