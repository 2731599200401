import { IApiResponse, MiniSelect } from "@alb/live-lib";
import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import { Theme } from "@mui/material";
import { debounce } from "@mui/material/utils";
import { SxProps } from "@mui/system";
import { AxiosRequestConfig } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";

import useGet from "hooks/fetchData/useGet";

import { ROWS_PER_PAGE } from "./Pagination/pagination";

interface IFilterSelect {
  onSelected: (value: TOption | null) => void;
  apiUrl: string;
  selectedValue?: string;
  customOptions?: (data: IApiResponse<any>) => TOption[];
  othersConfig?: AxiosRequestConfig<any>;
  showLabel?: boolean;
  showBorder?: boolean;
  borderColor?: string;
  sx?: SxProps<Theme>;
  immediateRequest?: boolean;
}

const FindSelectedOption = (props: {
  options: ISelectOption[];
  selectedOption?: string;
}) => {
  const { options, selectedOption } = props;

  const option = React.useMemo(() => {
    return (
      options.find(
        (option: ISelectOption) => option.value === selectedOption
      ) || ""
    );
  }, [options, selectedOption]);

  return option;
};

export const FilterSelectForArray = <T extends Object>(
  props: IFilterSelect
): JSX.Element => {
  const {
    onSelected,
    apiUrl,
    selectedValue,
    customOptions,
    othersConfig,
    showLabel,
    showBorder,
    borderColor,
    sx,
    immediateRequest,
  } = props;

  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [position, setPosition] = useState(0);
  const [listboxNode, setListboxNode] = useState<any>("");
  const [options, setOptions] = useState<TOption[]>([]);
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [searchOnServer, setSearchOnServer] = useState<boolean>(true);
  const [manual, setManual] = useState<boolean | undefined>(!immediateRequest);

  const { data, loading } = useGet(
    apiUrl,
    {
      page: page,
      items: ROWS_PER_PAGE,
      contains: inputValue,
    },
    // undefined,
    { manual: Boolean(manual) },
    othersConfig ? othersConfig : undefined
  );

  useEffect(() => {
    if (!data) return undefined;

    setTotalPages(data.totalPages);
    setShowSearchBar(inputValue !== "" || data.totalCount > 5);
    setSearchOnServer(inputValue !== "" || data.totalPages > page);

    let opts = customOptions
      ? customOptions(data)
      : data.map((a: T) => {
          return { label: Object.keys(a).pop(), value: Object.keys(a).pop() };
        });
          
    let newOptions = opts;
    if (page > 1 && options) {
      newOptions = [...options, ...opts];
    }
    setOptions(newOptions);

    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    setPosition(position);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (inputValue === "") {
      return undefined;
    }
    setOptions([]);
    setPage(1);
  }, [inputValue]);

  const loadMoreResults = () => {
    const nextPage = page + 1;
    if (!totalPages || nextPage > totalPages) return undefined;
    setPage(nextPage);
  };

  // change scroll position
  useEffect(() => {
    if (listboxNode !== "" && position >= 0) {
      listboxNode.scrollTop = position;
    }
  }, [position, listboxNode]);

  const handleOnScroll = (event: React.SyntheticEvent<Element, Event>) => {
    if (loading) return;
    const _listboxNode = event.currentTarget;

    const position = _listboxNode.scrollTop + _listboxNode.clientHeight;
    if (_listboxNode.scrollHeight - position < 1) {
      setListboxNode(_listboxNode);

      loadMoreResults();
    }
  };
  const debounceData = debounce((cb) => {
    cb();
  }, 10);
  const handleOnInputChange = (
    event: React.SyntheticEvent<Element, Event> | null,
    value: string
  ) => {
    debounceData(() => {
      setInputValue(value);
    });
  };

  const handleOnOpen = (event: React.SyntheticEvent<Element, Event>) => {
    setInputValue("");
    setManual(false); //estado enviado no manual do pedido que inicialmente é true e muda após abrir o select pel 1º vez
  };

  const getSelectedOption = () => {
    return FindSelectedOption({ options, selectedOption: selectedValue });
  };

  return (
    <MiniSelect
      sx={sx}
      showLoading={loading}
      showLabel={showLabel}
      showBorder={showBorder}
      options={options}
      showSearchBar={showSearchBar}
      noDataText={t("common.noData")}
      value={getSelectedOption()}
      searchOnServer={searchOnServer}
      onChangeOption={onSelected}
      onScroll={handleOnScroll}
      onSearch={handleOnInputChange}
      onOpen={handleOnOpen}
      borderColor={borderColor}
    />
  );
};

export default FilterSelectForArray;
