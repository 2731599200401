import React, { ReactNode } from 'react'
import { IPollutantLimits, getPollutantClassification, getPollutantLimits } from './QArtColorsLimits'
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

export interface IQArtTableLimits {
	pollutant?: string
	value?: number
}
function QArtTableLimits({ pollutant, value }: IQArtTableLimits) {
	const { t, i18n } = useTranslation();

	function translationIfExist(key: string) {
		return i18n.exists(key)
			? t(key)
			: key;
	}

	const pollutants = getPollutantLimits();

	const pollutantClassification = getPollutantClassification();

	const tableHeads: ReactNode[] = [];
	tableHeads.push(<th key={`th0`} className='bg_gray'>{t('qart.pollutant')}</th>);
	pollutantClassification.forEach(({ color, classification },index) => {
		tableHeads.push(<th key={`th${classification}${index}`} style={{ backgroundColor: color.main }}>{translationIfExist(`qart.pollutantClassification.${classification}`)}</th>)
	})

	const getSelectedClass= (name: string, limit: IPollutantLimits, value?: number) => {
		if (!value) return '';

		const classe = pollutant === name && limit.min <= value && limit.max >= value ? "selected" : ''
		return classe
	}

	const tableRows: ReactNode[] = [];
	pollutants.forEach(({ name, limits }, index) => {
		const td: ReactNode[] = [];
		td.push(<td key={`td${name}${index}`} className='bg_gray'>{translationIfExist(`qart.pollutantStreamsAbbr.${name}`)}</td>);
		limits.forEach((limit, index) => {
			td.push(<td key={`td${limit.classification}${index}`} className={getSelectedClass(name, limit, value)}>{limit.min}-{limit.max}</td>)
		})
		tableRows.push(<tr key={`tr${name}${index}`}>{td}</tr>)
	})

	return (
		<>
			<Typography variant='h6'>
				{t('qart.airClassification')}
			</Typography>
			<table className='tablePollutantLimits'>
				<thead>
					<tr>
						{tableHeads}
					</tr>
				</thead>
				<tbody>
					{tableRows}
				</tbody>
			</table>
			<Typography variant='caption' rowGap={2}>
				{t('qart.noteIntervalMetric')}
			</Typography>
		</>
	)
}

export default QArtTableLimits
