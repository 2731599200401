import { object, string, array, number, Schema } from "yup";
import { t } from "i18next";

export const InputsLengths = {
  max: {
    name: 32,
  },
  min: {
    name: 1,
  },
};

export const getClientFormSchema = () =>
  object().shape({
    client: object().shape(
      {
        name: string()
          .required()
          .min(InputsLengths.min.name)
          .max(InputsLengths.max.name),
        center_point: array()
          .json()
          .transform((value: any, originalValue: any, context: any) => {
						// o valor é do tipo array
						if (context.isType(value)) return value;

						// para o caso de estar vazio
						if (value?.length === 0) return []

						// tem um valor e não é um array
						return value;
					})
          .when("center_point", {
            is: (value: any) => {
              return value?.length;
            },
            then: (schema: any) =>
              schema.of(number().required()).min(
                2,
                t("validations.enterValidFormat", {
                  formatType: "[lat,lng]",
                })
              ),
						 otherwise: (schema: Schema) => schema,
          }),
      },
      [["center_point", "center_point"]]
    ),
    account: object().shape({
      name: string()
        .required()
        .min(InputsLengths.min.name)
        .max(InputsLengths.max.name),

      adapter_configurators: array().required().min(1),
    }),
  });

export const getEditClientFormSchema = () =>
  object().shape({
    client: object().shape(
      {
        name: string()
          .required()
          .min(InputsLengths.min.name)
          .max(InputsLengths.max.name),
					center_point: array()
          .json()
          .transform((value: any, originalValue: any, context: any) => {
						// o valor é do tipo array
						if (context.isType(value)) return value;

						// para o caso de estar vazio
						if (value?.length === 0) return []

						// tem um valor e não é um array
						return value;
					})
          .when("center_point", {
            is: (value: any) => {
              return value?.length;
            },
            then: (schema: any) =>
              schema.of(number().required()).min(
                2,
                t("validations.enterValidFormat", {
                  formatType: "[lat,lng]",
                })
              ),
						 otherwise: (schema: Schema) => schema,
          }),
      },
      [["center_point", "center_point"]]
    ),
  });

export const getModuleFormSchema = () =>
  object({
    name: string()
      .min(InputsLengths.min.name)
      .max(InputsLengths.max.name)
      .required(),
  });
