import { IRange, TableHeadOrder } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */

import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { formatOrderBy } from "utils/orderByAPI";
import { arrayIsEmpty } from "utils/utils";

import { IFilterFormOccurrences } from "./FilterForm";
import { OccurrencesTable } from "./OccurrencesTable";

interface IOccurrencesList {
  selectedDateRange: IRange | undefined;
  filterSearch: string;
  filtersPopup: IFilterFormOccurrences | null;
  refetch: boolean;
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>;
}

const OccurrencesList = ({
  selectedDateRange,
  filterSearch,
  filtersPopup,
  refetch,
  setRefetch,
}: IOccurrencesList) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("-created_at");

  const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

  const {
    data: occurrences,
    loading,
    error,
    refetch: refetchOcurrences,
  } = useGet(ServiceApiUrl.occurrences, paramsRequest(), undefined, headers);

  function paramsRequest() {
    var params = {
      page: page,
      items: ROWS_PER_PAGE,
      order_by: formatOrderBy(order, orderBy),
      ...(selectedDateRange && {
        record_date_interval:
          formatDate(
            selectedDateRange?.startDate,
            t("calendar.dateTimeFormat")
          ) +
          "," +
          formatDate(selectedDateRange?.endDate, t("calendar.dateTimeFormat")),
      }),
      ...(filterSearch !== "" && { contains: filterSearch }),
      ...filtersPopup,
    };
    return params;
  }

  useEffect(() => {
    setPage(1);
  }, [filterSearch]);

  useEffect(() => {
    if(!loading && refetch) {
      refetchOcurrences();
    }
  }, [refetch])

  return (
    <Grid container data-testid={"OccurrencesListContainer"}>
      <Grid item xs={12}>
        {(error || arrayIsEmpty(occurrences?.data)) && !loading && (
          <NoData error={error} />
        )}
        {loading && <Loading />}
        {!error &&
          !loading &&
          occurrences &&
          !arrayIsEmpty(occurrences.data) && (
            <OccurrencesTable
              data={occurrences}
              orderBy={orderBy}
              order={order}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              page={page}
              setPage={setPage}
              setRefetch={setRefetch}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default OccurrencesList;
