import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';


export const InfoTooltip = styled(({ className, ...props }: TooltipProps ) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		minWidth: 200,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
		cursor: 'help'
	}
}));

export const GlobalInfoTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#f5f5f9',
		color: 'rgba(0, 0, 0, 0.87)',
		minWidth: 500,
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #dadde9',
		cursor: 'help'
	}
}));
