import { IDynamicInputTemplate } from "@alb/live-lib";
import { Grid, Typography } from "@mui/material";

export interface IColXTemplate extends IDynamicInputTemplate {
  xsCol: "auto" | number | boolean;
}
export const ColXTemplate = ({ inputLabel, children, xsCol }: IColXTemplate) => {
  return (
    <Grid item xs={xsCol}>
      {inputLabel && (
        <Typography gutterBottom variant="h6">
          {inputLabel}
        </Typography>
      )}
      {children}
    </Grid>
  );
};