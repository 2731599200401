/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUpdate from "hooks/fetchData/useUpdate";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import useGet from "hooks/fetchData/useGet";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import { RequiredField, useFeedback } from "@alb/live-lib";
import { TClient, TUser } from "types/types";
import { ISelectOption } from "types/interfaces";
import { useDispatch } from "react-redux";
import { setUser } from "store/slices/authSlice";
import { setRoles } from "store/slices/authSlice";
import { RequiredFields } from "components/Utils/RequiredField";

interface IChangeClient {
  user: TUser;
  open: boolean;
  onClose: () => void;
}

export default function ChangeClient({ user, open, onClose }: IChangeClient) {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const auth = useSelector(getUser);
  const dispatch = useDispatch();

  const [clients, setClients] = useState<ISelectOption[]>();
  const [selectedClient, setSelectedClient] = useState<TClient>();

  useEffect(() => {
    if (auth) {
      setSelectedClient(auth.client);
    }
  }, [auth, onClose]);

  //pedido de todos os clientes
  const { data: dataClients } = useGet(ServiceApiUrl.clients);

  //pedido de edição de dados do utilizador (enviar cliente novo)
  const {
    loading,
    refetch: updateUser,
  } = useUpdate(ServiceApiUrl.user, user.id);

  useEffect(() => {
    if (dataClients) {
      const clients_ = dataClients?.data?.map((p: TClient) => ({
        label: p.name,
        value: p.id,
      }));
      setClients(clients_);
    }
  }, [dataClients]);

  //sucesso
  const handlerSuccess = () => {
    onClose();
    addFeedback({
      message: t("feedback.success.changesSaved"),
      severity: "success",
    });
  };

  const changeClient = async () => {
    if (auth && selectedClient) {
      dispatch({ type: "changeClient" });
      try {
        await updateUser({ data: { client: selectedClient?.id } }).then(
          async (response) => {
            dispatch(setUser(response.data));
            dispatch(setRoles(response.data));
          }
        );
        await handlerSuccess();
      } catch (error) {}
    }
  };

  const handleClientSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const client = dataClients?.data.find((c: any) => c.id === e.target.value);
    if (client) setSelectedClient(client);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle id="alert-dialog-title">
        <Typography
          component={"span"}
          color="text.black"
          gutterBottom={true}
          variant="h5"
          noWrap
        >
          {t("user.changeClientTitle")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        {user && selectedClient && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RequiredField title={t("user.visibleClientLabel")} />

              <TextField
                placeholder={t("user.clientPlaceholder")}
                variant="standard"
                fullWidth
                select
                value={selectedClient?.id ?? user.client.id}
                onChange={handleClientSelectChange}
              >
                {clients?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        )}

        <RequiredFields allRequired />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          form={"change-pwd-form"}
          onClick={changeClient}
        >
          {t("save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
