import { useFeedback } from "@alb/live-lib";
import { LoadingButton } from "@mui/lab";
//material
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TUser } from "types/types";

import useCreate from "hooks/fetchData/useCreate";
import { ServiceApiUrl } from "services/ServiceApiUrl";

interface IUserInviteProps {
	open: boolean,
	handlerClose: any,
	updateGetUsers: any,
	user: TUser
}

export default function UserInvite({ open, handlerClose, updateGetUsers, user }: IUserInviteProps) {

	//traduções
	const { t } = useTranslation();
	//alert
	const { addFeedback } = useFeedback();

	//########## SERVIÇOS,
	const { loading, refetch: inviteUser } = useCreate(`${ServiceApiUrl.userInvite(user.id)}`);

	//sucesso
	const handlerSuccess = () => {
		handlerClose()
		addFeedback({ message: t("user.userInvited"), severity: 'success' })
		updateGetUsers();
	};

	//apaga utilizador
	const handleInviteUser = async () => {
		try {
			await inviteUser()
			await handlerSuccess();
		}
		catch {
		}
	}


	return (


		<Dialog maxWidth="md" fullWidth
			open={open}
		>

			<DialogTitle id="alert-dialog-title">
				<Typography component={'span'} color="text.black" gutterBottom={true} variant="h5" sx={{ fontWeight: 'bold' }} noWrap>
					{t('user.inviteUser')}
				</Typography>
			</DialogTitle>


			<DialogContent>
				<Typography variant="body1">
					{t('confirmations.userInviteConfirmation', { user: `${user.first_name} ${user.last_name} (${user.username})` })}
				</Typography>
			</DialogContent>

			<DialogActions>

				<Button
					variant="outlined"
					color='primary'
					onClick={handlerClose}>
					{t('common.cancel')}
				</Button>

				<LoadingButton
					loading={loading}
					variant="contained"
					color='primary'
					onClick={handleInviteUser}>
					{t('common.invite')}
				</LoadingButton>

			</DialogActions>

		</Dialog>

	);
}
