import { IGroupForm } from "types/interfaces";
import { object, string, array } from "yup";

export const getGroupFormSchema = (data: IGroupForm | undefined) => 
    object().shape(
        {
          name: string().required(),
          user: array().required().min(1)
        }
      );
