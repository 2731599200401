/* eslint-disable react-hooks/exhaustive-deps */
import { InputAutocomplete, InputSelect } from "@alb/live-lib";
import { FormControl, Grid, SelectChangeEvent, styled, useTheme } from "@mui/material";
import { SyntheticEvent, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "types/interfaces";
import "../double-select/doubleSelect.scss";

export const DoubleSelect = styled(Grid)(({ theme }) => ({
  display: "flex",
  borderRadius: '12px',
  backgroundColor: theme.palette.background.paper,
  alignItems: "baseline",
  border: "1px solid",
  borderColor: theme.palette.greyColor.light,
  "&:hover": {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
  },
  "&.focused": {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
  },
}));

let adapterAlreadyChanged = false;
interface ILiveDoubleSelect {
  secondItemDefaultValue?: ISelectOption | undefined;
  firstItemDefaultValue?: string;
  firstItem: ISelectOption[];
  secondItem: ISelectOption[];
  handleChangeSecondItem(
    event: SyntheticEvent<Element, Event>,
    value: ISelectOption
  ): void;
  handleChangeFirstItem(e: SelectChangeEvent<string[]>): void;
}

const LiveDoubleSelect = ({
  firstItemDefaultValue,
  secondItemDefaultValue,
  firstItem,
  secondItem,
  handleChangeSecondItem,
  handleChangeFirstItem,
}: ILiveDoubleSelect) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const DEVICE_NAME_KEY = "device";

  useEffect(() => {
    adapterAlreadyChanged = false;
  }, []);

  //sempre que os adapters alterarem, dá "reset" ao form
  useEffect(() => {
    if (adapterAlreadyChanged) {
      resetField("gatewaySelect", { defaultValue: firstItem[0].value });
      resetField(DEVICE_NAME_KEY, { defaultValue: null });
    }
  }, [firstItem]);

  const defaultValues = {
    gatewaySelect: firstItemDefaultValue ?? firstItem[0].value,
    [DEVICE_NAME_KEY]: secondItemDefaultValue ?? null,
  };
  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const { control, resetField } = methods;

  function onChangeHandlerValue(
    e: SyntheticEvent<Element, Event>,
    value: ISelectOption
  ) {
      handleChangeSecondItem(e, value);
  }
  function onChangeGateway(e: SelectChangeEvent<string[]>) {
    adapterAlreadyChanged = true;
    resetField(DEVICE_NAME_KEY, { defaultValue: null }); //ao trocar de adaptador, limpa os devices selecionados
    handleChangeFirstItem(e);
  }
  return (
    <FormControl className="fullWidth">
      <DoubleSelect>
        <Grid item xs={3}>
          <InputSelect
            options={firstItem}
            name={"gatewaySelect"}
            control={control}
            handleOnChange={onChangeGateway}
            sx={{
              "& .MuiSelect-select": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: '10px',
                color: theme.palette.common.white,
                margin: "3px",
                padding: "6px 11px",
                "&:focus": {
                  borderRadius: '10px',
                },
              },
              "& .MuiSelect-icon": {
                color: theme.palette.common.white,
              },
            }}
          />
        </Grid>
        <Grid item xs={true}>
          <InputAutocomplete
            name="device"
            placeholder={t("comparativeAnalysis.selectDevice")}
            options={secondItem}
            control={control}
            handleOnChange={onChangeHandlerValue}
          />
        </Grid>
      </DoubleSelect>
    </FormControl>
  );
};

export default LiveDoubleSelect;
