import { IRange, LayoutContext, Loading } from "@alb/live-lib";
import { Box, Grid } from "@mui/material";
import { AxiosError } from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import CardsTotals from "components/electricMobility/CardsTotals";
import { FiltersSection } from "components/electricMobility/FiltersSection";
import List from "components/electricMobility/List";
import NoData from "components/Utils/NoData";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";

export interface IDataStatus {
  isLoading: boolean;
  hasData: boolean;
}

const ElectricMobility = () => {
  const { t } = useTranslation();
  // ------ permissions
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.MODULES.ELECTRICMOBILITY.READ);

  // ------ header page
  const header = {
    title: t("electricMobility.title"),
  };
  const { addHeader } = useContext(LayoutContext);

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line
  }, []);

  const [filterName, setFilterName] = useState("");
  const [filterDateRange, setFilterDateRange] = useState<IRange | undefined>();
  const [cardsDataStatus, setCardsDataStatus] = useState<IDataStatus>({
    isLoading: false,
    hasData: false,
  });
  const [listDataStatus, setListDataStatus] = useState<IDataStatus>({
    isLoading: false,
    hasData: false,
  });
  const [error, setError] = useState<AxiosError<any, any> | null>(null);

  const args = {
    filterName: filterName,
    filterDateRange: filterDateRange,
    setErrorRequest: setError,
    setCardsDataStatus: setCardsDataStatus,
    setListDataStatus: setListDataStatus,
  };
  return (
    <>
      {canREAD && (
        <>
          <Grid item mt={4}>
            <FiltersSection
              setFilterName={setFilterName}
              setFilterDateRange={setFilterDateRange}
            />
          </Grid>
          <CardsTotals {...args} />
          <Box data-testid={"ElectricMobilityListContainer"}>
            <List {...args} />
            <Loading
              show={cardsDataStatus.isLoading || listDataStatus.isLoading}
            />
            {!cardsDataStatus.hasData &&
              !listDataStatus.hasData &&
              !listDataStatus.isLoading &&
              !cardsDataStatus.isLoading && <NoData error={error} />}
          </Box>
        </>
      )}
    </>
  );
};

export default ElectricMobility;
