import { PERMISSIONS } from "../permissions";
import { adminPermissions } from "./adminPermissions";

export const superAdminPermissions = [
  ...adminPermissions,
  PERMISSIONS.ADMINISTRATION.CREATE,
  PERMISSIONS.ADMINISTRATION.READ,
  PERMISSIONS.ADMINISTRATION.UPDATE,
  PERMISSIONS.ADMINISTRATION.DELETE,
  ...Object.values(PERMISSIONS.ADMINISTRATION.CLIENTS),
  ...Object.values(PERMISSIONS.MANAGEMENT.CLIENTS),
]