import axios from "axios";
import { configure } from "axios-hooks";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { AUTH_TOKEN } from "./keys";
import { getCookie, removeCookie } from "./cookie";

const defaultOptions = {
  baseURL: ServiceApiUrl.baseApiUrl,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Content-Type",
  },
};

// Create instance
export const api = axios.create(defaultOptions);
const cache = false; //new LRUCache({ max: 10 });
configure({
  axios: api,
  cache,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const status: number = error.response.status; // API Request Status Code
      if (status === 401) {
        const errorCode: number = +error.response.data[0].error_code; // BE Error Code - see BE Docs
        //Se for 10006, deve ser redirecionado para a unauthorized
        if (errorCode === 10006) {
          window.location.replace("/unauthorized");
        }
        //Se for 10013, deve ser redireccionado para o login "Auth Token Expired"
        //Se for 10014, deve ser redireccionado para o login "Missing Token"
        if (errorCode === 10013 || errorCode === 10014) {
          // clear data of store
          localStorage.clear();
          // clear auth cookie
          removeCookie(AUTH_TOKEN);
          // navigate for login page
          window.location.replace("/login");
        }
      }
    }
    return Promise.reject(error);
  }
);
api.interceptors.request.use(
  (config) => {
    const token: string | null = getCookie<string | null>(AUTH_TOKEN);
    if (token) {
      config.headers.setAuthorization("Token " + token);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
