import { useFeedback } from "@alb/live-lib";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IOccurrenceForm } from "types/interfaces";

import useCreate from "hooks/fetchData/useCreate";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { selectCoords, setDrawingMap, setDrawnCoords } from "store/slices/mapSlice";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { objectIsEmpty } from "utils/utils";

import OccurrenceForm from "./form/OccurrenceForm";
import { formatDataAddForm } from "./utils/formatDataToSend";

interface IOccurrenceAdd {
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export const OccurrenceAdd = ({ open, onClose, onSuccess }: IOccurrenceAdd) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();

  const addFormId = "add-occurrences-form";

  const dispatch = useDispatch();
  const drawCoords = useSelector(selectCoords);

  const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

  const [formIsValid, setFormIsValid] = useState(false);
  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  //pedido para criar a ocorrência
  const { loading, refetch: createOccurrence } = useCreate(
    ServiceApiUrl.occurrences,
    undefined,
    headers
  );

  const handlerClose = () => {
    onClose(); //fecha modal
    dispatch(setDrawingMap(false));
    dispatch(setDrawnCoords(null));
  };
  const handlerSuccess = () => {
    handlerClose();
    addFeedback({
      message: t("occurrences.occurrenceAdded"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  //ao submeter o formulário
  const onSubmit: SubmitHandler<IOccurrenceForm> = async (payLoad: IOccurrenceForm) => {
    const result = formatDataAddForm(payLoad);

    try {
      await createOccurrence({ data: result });
      await handlerSuccess();
    } catch (error) {}
  };

  useEffect(() => {
    if (!objectIsEmpty(drawCoords)) {
      dispatch(setDrawnCoords(null));
    }
    dispatch(setDrawingMap(true)); //ativar modo desenho
  }, []);

  return (
    <>
      <Dialog
        maxWidth="xl"
        fullWidth
        sx={{
          ".MuiPaper-root": {
            padding: 0,
            display: "flex",
            flexDirection: "row",
          },
        }}
        open={open}
      >
        <Grid container sx={{ overflow: "auto" }}>
          <Grid item xs={true} sx={{ padding: "0 10px 0 20px" }}>
            <DialogTitle>
              <Typography
                component={"span"}
                gutterBottom={true}
                variant="h5"
                noWrap
              >
                {t("occurrences.addOccurrence")}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={handlerClose}
                size="small"
                sx={{
                  position: "absolute",
                  right: 10,
                  top: 15,
                  zIndex: 500,
                }}
                title={t("close")}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>

            <DialogContent
              style={{
                paddingTop: "0",
                paddingRight: "10px",
              }}
            >
              <OccurrenceForm
                formId={addFormId}
                editMode="add"
                onFormSubmit={onSubmit}
                setFormIsValidStatus={getFormIsValidStatus}
              />
            </DialogContent>
            <DialogActions
              style={{
                paddingRight: "10px",
              }}
            >
              <Button variant="outlined" color="primary" onClick={onClose}>
                {t("cancel")}
              </Button>

              <LoadingButton
                loading={loading}
                variant="contained"
                color="primary"
                type={"submit"}
                disableElevation
                disabled={!formIsValid}
                form={addFormId}
              >
                {t("common.add")}
              </LoadingButton>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};
