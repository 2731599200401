import { Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const CertificationLevelRanks = () => {
  const { t } = useTranslation();

  const theme = useTheme();

  return (
    <>
      <Grid
        p={2}
        sx={{ backgroundColor: theme.palette.divider }}
        mt={2}
        mb={2}
        container
        justifyContent={"space-between"}
        alignItems="row"
      >
        {ranksInfo.map((rank: any, index: number) => (
          <Grid key={index} item>
            <Typography variant="h6" color={rank.color}>
              {t(`indicators.iso.categoriesISO.rank.types.${rank.type}`)}
            </Typography>
            <Typography sx={{fontSize:'15px'}}>
              <b>{rank.indicators} </b>{" "}
              {rank.indicators
                ? t("indicators.iso.categoriesISO.rank.indicators")
                : ""}
            </Typography>
            <Typography sx={{fontSize:'15px'}}>
              <b>{rank.main} </b>{" "}
              {rank.main ? t("indicators.iso.categoriesISO.rank.core") : ""}
            </Typography>
            <Typography sx={{fontSize:'15px'}}>
              <b> {rank.suport}</b>{" "}
              {rank.suport
                ? t("indicators.iso.categoriesISO.goals.support")
                : ""}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default CertificationLevelRanks;

const ranksInfo = [
  {
    type: "aspirant",
    color: "#262526",
    indicators: "30 - 44",
  },
  {
    type: "bronze",
    color: "#8C6249",
    indicators: "45 - 59",
    main: 45,
    suport: "0 - 14",
  },
  {
    type: "silver",
    color: "#8D9192",
    indicators: "60 - 74",
    main: 45,
    suport: "15 - 29",
  },
  {
    type: "gold",
    color: "#8C7954",
    indicators: "75 - 89",
    main: 45,
    suport: "30 - 44",
  },
  {
    type: "platinum",
    color: "#6C868C",
    indicators: "90 - 104",
    main: 45,
    suport: "45 - 59",
  },
];
