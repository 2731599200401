/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { ConfirmDialog, useFeedback } from "@alb/live-lib";
import { TModule } from "types/types";
import useDelete from "hooks/fetchData/useDelete";
export interface IModulesDelete {
  module: TModule;
  clientID: string;
  open: boolean;
  handlerClose: () => void;
  updateList: () => void;
}

const ModulesDisassociate = ({
  module,
  clientID,
  open,
  handlerClose,
  updateList,
}: IModulesDelete) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();

  const {
    loading,
    refetch: disassociateModule,
  } = useDelete(ServiceApiUrl.clientModule(clientID));

  //sucesso
  const handlerSuccess = () => {
    handlerClose();
    addFeedback({
      message: t("modules.moduleDisassociated"),
      severity: "success",
    });
    updateList();
  };

  // eliminar módulo
  const handleDisassociateModule = async () => {
    const payLoad = {"modules": [module.id]}
    try {
      await disassociateModule({ data: payLoad });
      await handlerSuccess();
    } catch {}
  };

  return (
    <>
    <ConfirmDialog
              open={open}
              title={`${t("modules.disassociateModule")} ${module.name}`}
              message={t("modules.moduleDisassociateConfirmation")}
              actionConfirmText={t("common.delete")}
              actionCancelText={t("common.cancel")}
              loading={loading}
              onCancel={handlerClose}
              onConfirm={handleDisassociateModule}
    />
    </>
  );
};

export default ModulesDisassociate;
