import { Box } from "@mui/material";
import IconsList from "./iconList";

const IconCell = (props: {row: any}) => {

    const { row } = props;

    const getUrlByName = (name: string) => {
        let url: string = "";
        IconsList.forEach(i => {
            if(i.name === name)
                url = i.url;
        })
        return url;
    }

    return(
        <Box sx={{ height: "30px", marginRight: "10px" }}>
            {row.icon ? <img height={"30px"} alt={row.icon} src={getUrlByName(row.icon)}/> : '--'}
        </Box>
    
    )
}

export default IconCell;