import { LayoutContext } from '@alb/live-lib'
import { Grid } from '@mui/material'

import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import PersonalData from 'components/profile-settings/personal-data/PersonalData'
import Password from 'components/profile-settings/password/Password'
import ProfessionalData from 'components/profile-settings/professional-data/ProfessionalData'

const ProfileSettings = () => {
  const { t, i18n } = useTranslation()

  // --------------- Header -------------------
  const { addHeader } = useContext(LayoutContext)
  const header = {
    title: t('user.personalDetails'),
  }
  useEffect(() => {
    addHeader(header)
    // eslint-disable-next-line
  }, [i18n.language])

  return (
    <Grid container>
      <Grid container item spacing={2} mt={5}>
        <Grid item xs={7}>
          <PersonalData />
        </Grid>
        <Grid item xs={5}>
          <Password />
        </Grid>
      </Grid>
      <Grid container item spacing={2} mt={2}>
        <Grid item xs={7}>
          <ProfessionalData />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProfileSettings
