import { arrayIsEmpty, TableHeadSort } from "@alb/live-lib";
import { Card, CardContent, Stack, Table, TableBody, TableContainer, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";

import DeleteButton from "./buttons/DeleteButton";
import OpenDataTableRow from "./OpenDataTableRow";

const OpenDataCSVTable = (props: any) => {
  const [page, setPage] = useState(1);

  let header = props.header.map((h: any) => {
    return {
      id: h,
      label: h,
    };
  });

  const [rows, setRows] = useState([]);

  const updateRows = () => {
    if (!arrayIsEmpty(props.content)) {
      let rows: any = [];

      props.content.forEach((c: any, index: number) => {
        if (
          index < page * ROWS_PER_PAGE &&
          index > (page - 1) * ROWS_PER_PAGE &&
          index !== props.content.length - 1
        )
          rows.push(<OpenDataTableRow key={index} content={c} />);
      });

      setRows(rows);
    }
  };

  useEffect(() => {
    updateRows();
    // eslint-disable-next-line
  }, [props.content, page]);

  useEffect(() => {
    setPage(1);
  }, [props.document]);

  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1);
  };

  return (
    <>
      {!arrayIsEmpty(props.content) && (
        <Card sx={{ mt: 4, mb: 2 }}>
          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h3" fontSize={"22px"}>
                {props.uploadedFile} {""}
              </Typography>
              <DeleteButton deleteArray={props.clearArrays} />
            </Stack>
            <TableContainer
              sx={{ width: "100%", maxHeight: "80vh", position: "relative" }}
            >
              <Table stickyHeader>
                <TableHeadSort
                  headCells={header}
                  onRequestSort={() => {}}
                  order="asc"
                  orderBy="id"
                />
                <TableBody>{rows}</TableBody>
              </Table>
            </TableContainer>
            <CustomTablePagination
              count={rows.length - 1}
              page={page}
              totalPages={Math.round((rows.length - 1) / ROWS_PER_PAGE)}
              onPageChange={handleChangePage}
            />
          </CardContent>
        </Card>
      )}

      {/*!arrayIsEmpty(props.content)  &&  <Pagination 
        count={Math.round((rows.length-1) / ROWS_PER_PAGE)-1} 
        page={page-1}
        onChange={handleChangePage}
        showFirstButton={true}
        showLastButton={true}
        size="large"
        sx={{float: "right", marginTop: "5px"}}
/>*/}
    </>
  );
};

export default OpenDataCSVTable;
