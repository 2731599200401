export const darkenColor = (hexColor: string, percent: number) => {
  hexColor = hexColor.replace('#', '');

  let r: string | number = parseInt(hexColor.substring(0,2),16);
  let g: string | number = parseInt(hexColor.substring(2,4),16);
  let b: string | number = parseInt(hexColor.substring(4,6),16);

  r = Math.round(r * (1 - percent / 100));
  g = Math.round(g * (1 - percent / 100));
  b = Math.round(b * (1 - percent / 100));

  r = (r < 255 ? r : 255).toString(16);
  g = (g < 255 ? g : 255).toString(16);
  b = (b < 255 ? b : 255).toString(16);

  r = ('0' + r).slice(-2);
  g = ('0' + g).slice(-2);
  b = ('0' + b).slice(-2);

  return `#${r}${g}${b}`;

}
