import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
  
import { useTranslation } from "react-i18next";
import { useState } from "react";

import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";

import ChangePassword from "../change-password/ChangePassword";

  
const Password = () => {
  const { t } = useTranslation();
  const user = useSelector(getUser);

  const [openDialog, setOpenDialog] = useState(false);

  const closeDialog = () => {
    setOpenDialog(false);
  };
  
  return (
    <>
      {user && (
        <>
          <Card>
            <CardHeader
              title={t("user.password")}
              action={
                <Button
                  color="primary"
                  onClick={() => setOpenDialog(true)}
                  variant="outlined"
                  startIcon={<EditIcon />}
                  fullWidth
                >
                  {t("edit")}
                </Button>
              }
            />
            <CardContent>
              <Typography variant="body1" mb={2}>
                {t("user.passwordInfoText")}
              </Typography>
            </CardContent>
          </Card>
          <ChangePassword open={openDialog} onClose={closeDialog} />
        </>
      )}
    </>
  );
};

export default Password;