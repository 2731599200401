import { useFeedback } from "@alb/live-lib";
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { arrayIsEmpty } from 'utils/utils'
import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IUserAddForm, IUserPermission } from "types/interfaces";
import { TUser } from "types/types";
import UsersForm from "./UsersForm";
import useUpdate from "hooks/fetchData/useUpdate";

export interface IUsersEdit {
	user: TUser;
	clientID?: string;
	open: boolean;
	invite?: boolean;
	onClose: () => void;
	updateList: () => void;
	administration?: boolean;
	allModules?: any;
}

const CRUD = "CRUD";

const UsersEdit = ({
	user,
	clientID,
	open,
	invite,
	onClose,
	updateList,
	administration,
	allModules,
}: IUsersEdit) => {
	const { t } = useTranslation();
	const { addFeedback } = useFeedback();
	const editFormId = 'edit-users-form'
	const [formIsValid, setFormIsValid] = useState(false);

	//   const { hasPermission } = useHasPermissions();


	//   const canAdminister = hasPermission([PERMISSIONS.MANAGEMENT.USERS.CUD]);


	//update user
	const {
		loading,
		refetch: editUser,
	} = useUpdate(ServiceApiUrl.user, user.id);

	// let permitionModule: { [permitionId: string]: boolean } = {};
	// permissions?.forEach((permission) => {
	//   CRUD.split("").forEach((c) => {
	//     const name = `${permission.module_id}_${c}`;
	//     const obj = { [name]: false };
	//     permitionModule = { ...permitionModule, ...obj };
	//   });
	// });

	//update user request
	const formSubmitHandler: SubmitHandler<IUserAddForm> = async (
		payLoad: IUserAddForm
	) => {
		const _payLoad = preparePayload(payLoad);
		try {
			await editUser({ data: _payLoad });
			await handlerSuccess();
		} catch (error) { }
	};


	function preparePayload(payLoad: IUserAddForm) {
		let _payLoad: IUserAddForm = {
			// account: payLoad.account,
			email: user.email,
			first_name: payLoad.first_name,
			last_name: payLoad.last_name,
			is_admin: payLoad.is_admin,
			language: payLoad.language,
			permission: [],
			username: payLoad.username,

			status: payLoad.status,
		};
		//campos reservados a utilizadores superAdmin, e se for pela administração
		if (administration) {
			_payLoad.client = clientID;
			_payLoad.is_superadmin = payLoad.is_superadmin;
		}
		const perm: IUserPermission[] = [];
		if (!arrayIsEmpty(user.permission)) {
			user.permission.forEach((m) => {
				let value: string[] = [];
				CRUD.split("").forEach((c) => {
					const name = `${m.module_id}_${c}`;
					const t = (payLoad as unknown as any)[name];
					value.push(t ? "1" : "0");
				});

				if (value.join("") !== "0000") {
					perm.push({
						module_id: m.module_id,
						value: value.join(""),
					});
				}
			});
		}
		payLoad.is_admin || payLoad.is_superadmin ? _payLoad.permission = [] : _payLoad.permission = perm;
		_payLoad.account =
			typeof payLoad.account === "string"
				? payLoad.account
				: payLoad.account?.value || "";
		_payLoad.language = (typeof payLoad.language === 'string') ? payLoad.language : payLoad.language?.value || '';
		return _payLoad;
	}

	const handlerSuccess = () => {
		onClose();
		addFeedback({
			message: t("user.userEdited"),
			severity: "success",
		});
		updateList && updateList();
	};

	const getFormIsValidStatus = (status: boolean) => {
		setFormIsValid(status);
	};

	return (
		<Dialog maxWidth="md" fullWidth open={open}>
			<DialogTitle>
				<Typography
					component={"span"}
					color="text.black"
					gutterBottom={true}
					variant="h5"
					noWrap
				>
					{t("user.editUser")}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<UsersForm
					data={user}
					formId={editFormId}
					onFormSubmit={formSubmitHandler}
					clientID={clientID}
					administration={administration}
					permissions={user.permission || []}
					onChangeStatusForm={getFormIsValidStatus}
				/>
			</DialogContent>

			<DialogActions>
				<Button variant="outlined" color="primary" onClick={onClose}>
					{t("common.cancel")}
				</Button>

				<LoadingButton
					loading={loading}
					variant="contained"
					color="primary"
					disabled={!formIsValid}
					type={"submit"}
					form={editFormId}
				>
					{t("save")}
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default UsersEdit;
