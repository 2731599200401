/* eslint-disable react-hooks/exhaustive-deps */
import { useFeedback } from "@alb/live-lib";
import { debounce } from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { TAdapterConfigurator, TUser } from "types/types";

import useMarkers from "hooks/useMarkers";
import { selectDevicesFilterParams, setDevicesFilterParams } from "store/slices/adapterConfiguratorsSlice";
import { getUser } from "store/slices/authSlice";
import { setTextSearchBar } from "store/slices/dashboardSlice";
import { selectEventsFilterParams, setEventsFilterParams } from "store/slices/eventsSlice";
import { getSelectedMarkerType, setFitBounds } from "store/slices/mapSlice";
import { selectOccurrencesFilterParams, setOccurrencesFilterParams } from "store/slices/occurrencesSlice";
import { clientPublicPortal, IClientPublicPortal } from "utils/clientsPublicPortal";
import { formatDate } from "utils/date";
import { arrayIsEmpty, objectIsEmpty } from "utils/utils";

import useFetchMarkers from "../../../../../hooks/useFetchMarkers";
import { IFilters } from "../FilterTabForm";
import { MarkersTypeOptions } from "../utils/GetOptions";
import { useEffect } from "react";
import { getWazeFilterParams, setWazeFilterParams } from "store/slices/wazeSlice";

const useMapFilters = (
  adapters: TAdapterConfigurator[] = [],
  publicAPI: boolean = false
) => {
  const dispatch = useDispatch();
  const { addFeedback } = useFeedback();
  let mapDeviceParams = useSelector(selectDevicesFilterParams);
  let mapOccurrencesParams = useSelector(selectOccurrencesFilterParams);
  let mapEventsParams = useSelector(selectEventsFilterParams);
  let mapWazeParams = useSelector(getWazeFilterParams)
  const location = useLocation();
  const userRedux = useSelector(getUser);
  const user = !publicAPI
    ? userRedux
    : (clientPublicPortal(location.pathname) as TUser | IClientPublicPortal);
  const { getEventsData, getOccurrencesData, getWazeData } =
    useMarkers();
  const { t } = useTranslation();
  const selectedType = useSelector(getSelectedMarkerType);

  const {fetchDevicesMarkers, fetchAllMarkers} = useFetchMarkers()


  const success = () => {
    addFeedback({
      message: t("feedback.success.appliedFilters"),
      severity: "success",
      duration: 1500,
    });
  };

  //sempre que forem detetadas alterações nos params, faz novo pedido
  useEffect(() => {
    if((selectedType === MarkersTypeOptions.devices) || selectedType === MarkersTypeOptions.all) {
    if (!objectIsEmpty(mapDeviceParams)) {
      fetchDevicesMarkers(true);
    }
  }
  }, [JSON.stringify(mapDeviceParams)]);

  useEffect(() => {
    if((selectedType === MarkersTypeOptions.occurrences) || selectedType === MarkersTypeOptions.all) {
		const getOccurData = async()=> {
			try {
        await getOccurrencesData(user, publicAPI);
        success();
      } catch {}
		}
    if (!objectIsEmpty(mapOccurrencesParams)) {
      getOccurData()
    }
  }
  }, [JSON.stringify(mapOccurrencesParams)]);

  useEffect(() => {
    if((selectedType === MarkersTypeOptions.events) || selectedType === MarkersTypeOptions.all) {
		const getEvtsData = async()=> {
			try {
        await getEventsData(user, publicAPI);
        success();
      } catch {}
		}
    if (!objectIsEmpty(mapEventsParams)) {
      getEvtsData();
    }
  }
  }, [JSON.stringify(mapEventsParams)]);

  useEffect(() => {
    if((selectedType === MarkersTypeOptions.waze) || selectedType === MarkersTypeOptions.all) {
      const getWaze = async () => {
      try {
        await getWazeData(user)
        success()
      } catch{}
    }

    if(!objectIsEmpty(mapWazeParams))
        getWaze()
    }
  }, [JSON.stringify(mapWazeParams)])


  const debounceData = debounce((cb) => {
    cb();
  }, 450);
  //envia os valores novos (filtrados) para os params de cada tipo, dependendo do tipo selecionado
  const dispatchParams = (
    values?: IFilters,
    searchValue?: string,
    clear?: boolean
  ) => {
    //se o tipo for trocado, faz os pedidos novamente (com os params limpos)
    if (clear) {
      fetchAllMarkers();
      return;
    }

    const searchParams =
      mapDeviceParams?.contains ||
      mapEventsParams?.contains ||
      mapOccurrencesParams?.contains;


    //se o search tiver sido alterado e for vazio, não faz fitBounds(true)
    const searchWasClean =
      searchParams !== searchValue && searchValue?.length === 0;

     !searchWasClean && dispatch(setFitBounds(true)); //avisa mapa para fazer fitbounds


    debounceData(() => {
      switch (selectedType) {
        case MarkersTypeOptions.devices:
          dispatchDevices(values, searchValue); //DEVICES
          break;
        case MarkersTypeOptions.events:
          dispatchEvents(values, searchValue); //EVENTS
          break;
        case MarkersTypeOptions.occurrences:
          dispatchOccurrences(values, searchValue); //OCCURRENCES
          break;
        case MarkersTypeOptions.waze:
          dispatchWaze(values, searchValue) // WAZE
          break;
        default:
          dispatchEvents(values, searchValue);
          dispatchOccurrences(values, searchValue);
          dispatchDevices(values, searchValue);
          dispatchWaze(values, searchValue)
      }
      // if (values) success();
      //guarda o valor em redux
      dispatch(setTextSearchBar(searchValue));
    });
  };

  //guarda os params dos eventos
  const dispatchEvents = (values?: IFilters | any, searchValue?: string) => {
    dispatch(
      setEventsFilterParams({
        ...mapEventsParams,
        ...(values && {
          status: values?.eventStatus?.value,
          category: values?.eventsCategory?.value,
          "start-date":
            values?.start_date_events !== null
              ? formatDate(
                  values?.start_date_events,
                  t("calendar.dateTimeEndpoints")
                )
              : undefined,
          "end-date":
            values?.end_date_events !== null
              ? formatDate(
                  values?.end_date_events,
                  t("calendar.dateTimeEndpoints")
                )
              : undefined,
        }),
        ...(searchValue !== undefined && {
          contains: searchValue !== "" ? searchValue : undefined,
        }),
      })
    );
  };

  const dispatchWaze = (values?: IFilters, seachValue?: string ) => {
    dispatch(setWazeFilterParams({
      ...mapWazeParams,
      ...(values && {selectedAdapter: values?.wazeAdapters})
    }))
  }

  //guarda os params das occorrencias
  const dispatchOccurrences = (
    values?: IFilters | any,
    searchValue?: string
  ) => {
    dispatch(
      setOccurrencesFilterParams({
        ...mapOccurrencesParams,
        ...(values && {
          status: values?.occurrencesStatus?.value,
          category: values?.occurrencesCategory?.value,
          status_date:
            values?.last_date_update_occurrences !== null
              ? formatDate(
                  values?.last_date_update_occurrences,
                  t("calendar.dateTimeEndpoints")
                )
              : undefined,
        }),
        ...(searchValue !== undefined && {
          contains: searchValue !== "" ? searchValue : undefined,
        }),
      })
    );
  };
  //guarda os params dos devices
  const dispatchDevices = (values?: IFilters | any, searchValue?: string) => {
    if (values !== undefined) {
      // se algum estiver a false
      if (
        Object.keys(values?.adapters).some((a: any) => !values?.adapters[a])
      ) {
        //coloca todos os que estão ativos numa variavel
        const activeAdapters = Object.keys(values?.adapters).filter(
          (v: string) => values.adapters[v]
        );
        if (!arrayIsEmpty(activeAdapters)) {
          const selectedAdapters = adapters?.filter((a: any) =>
            activeAdapters.includes(a.name)
          );
          var adaptersIDs = selectedAdapters
            .map((item: any) => {
              return item.id;
            })
            .join(",");
          dispatch(
            setDevicesFilterParams({
              ...mapDeviceParams,
              adapters_id: adaptersIDs || "",
              is_active: values?.deviceStatus?.value,
            })
          );
        }
      } else {
        dispatch(
          setDevicesFilterParams({
            ...mapDeviceParams,
            adapters_id: undefined,
            is_active: values?.deviceStatus?.value as any,
          })
        );
      }
    } else if (searchValue !== undefined) {
      dispatch(
        setDevicesFilterParams({
          ...mapDeviceParams,
          contains: searchValue !== "" ? searchValue : undefined,
        })
      );
    }
  };

  return dispatchParams;

};

export default useMapFilters;
