import { LayoutContext, Loading } from "@alb/live-lib";
import { Box } from "@mui/material";
import { t } from "i18next";
import { toLower } from "lodash";
import { useContext, useEffect, useState } from "react";

import ParkingCharts from "components/mobility/ParkingCharts";
import { IModuleCardItem, ModuleCards } from "components/modules/ModuleCards";
import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

export interface IParkingChartData {
    name: string;
    value: number;
  }

const Parking = () => {

  const { addHeader } = useContext(LayoutContext);

  const { headers } = useGetModuleHeaders(MODULES_KEYS.PARKING);

  const [cardsData, setCardsData] = useState<IModuleCardItem[]>([]);
  const [percentagesData, setPercentagesData] = useState<IParkingChartData[]>();

  const header = {
    title: t("parking.title"),
  };

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line
  }, []);

  const {
    data: parkingData,
    loading: loadingParkingData,
    error: errorParking,
  } = useGet(ServiceApiUrl.parking, undefined, undefined, headers);

  const prepareCardsData = (entry: {[name:string]:any}) => {
    let auxArr: IModuleCardItem[] = [];
    const entries = Object.entries(entry);
    const total = entry.total;
    entries.forEach((element) => {
      if (element[0] !== "total") {
        auxArr.push({
          name: t(`parking.${toLower(element[0])}`),
          value: `${element[1]}/${total}`,
          type: "total",
        });
      }
    });
    setCardsData(auxArr);
  };

  const preparePieChartData = (entry: {[name:string]:any}) => {
    let data: IParkingChartData[] = [];
    let entries = Object.entries(entry);

    entries.forEach((e) => {
      data.push({
        name: t(`parking.${toLower(e[0])}`),
        value: Math.round(e[1]),
      });
    });

    setPercentagesData(data);
  };

  useEffect(() => {
    if (parkingData) {
      parkingData?.forEach((e: {[name:string]:any}) => {
        let entry = Object.entries(e)[0];
        switch (entry[0]) {
          case "cards":
            prepareCardsData(entry[1]);
            break;
          case "percentage":
            preparePieChartData(entry[1]);
            break;
        }
      });
    }
  }, [parkingData]);

  return (
    <>
      <Loading show={loadingParkingData} />
        <Box sx={{ marginTop: 4 }}>
          <ModuleCards
            module="mobility"
            items={cardsData}
            loading={loadingParkingData}
            error={errorParking}
            dataTestId="parkingCardsContainer"
          />
        </Box>

      <Box sx={{ mt: 4 }}>
        <ParkingCharts
          loading={loadingParkingData}
          pieChartData={percentagesData}
          error={errorParking}
        />
      </Box>
    </>
  );
};

export default Parking;
