import { Trans, useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import { getCityClient } from "utils/utils";
interface IAboutCreditAssigment {
  client: string;
}

const AboutCreditAssigment = ({ client }: IAboutCreditAssigment) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Grid container mt={10} mb={10}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {t("about.creditAssignment")}
          </Typography>
        </Grid>
        <Grid item xs={12} mt={1}>
          <Typography variant="body1">
            <Trans
              i18nKey="about.credits.parishesChart"
              values={{ client: getCityClient(client) }}
              components={{
                a: <a style={{color: theme.palette.text.secondary}} href="https://www.dgterritorio.gov.pt/dados-abertos" > </a>,
              }}
            />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default AboutCreditAssigment;
