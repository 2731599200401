import { arrayIsEmpty, Calendar, HeatmapOptions, Loading } from "@alb/live-lib";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import NoData from "components/Utils/NoData";
import { getFilterDateRange, getMapBounds, IFilterDateRangeMap, setFilterDateRange } from "store/slices/mapSlice";
import * as variables from "styles/css/variables";
import styles from "styles/modules/map/external-controls.module.scss";
import { legendHeatmapColors, maxValueLegend, minValueLegend, rangeColorsHeatMap } from "utils/heatMap";
import { formatNumber } from "utils/number";

import { ColorRangeLegend } from "./ColorGradientLegend";

interface IMapLegend {
  options?: number[][];
  loading: boolean;
  error: AxiosError<any, any> | null;
  disabledCalendar: boolean;
  heatmap: boolean;
  heatmapData: HeatmapOptions<number> | null;
}

export const MapLegend = ({
  options,
  loading,
  error,
  disabledCalendar,
  heatmap,
  heatmapData,
}: IMapLegend) => {
  const { t, i18n } = useTranslation();
  const rangeDefault = useSelector(getFilterDateRange);
  const dispatch = useDispatch();

  const mapBounds = useSelector(getMapBounds);

  const colorsRange = [
    variables.firstColor,
    variables.secondColor,
    variables.thirdColor,
    variables.fourthColor,
    variables.fifthColor,
  ];
  const optionsLegend = options?.map((range: number[], index: number) => {
    return {
      color: colorsRange[index],
      text:
        formatNumber(range[0].toFixed(0)) +
        " - " +
        formatNumber(range[1].toFixed(0)),
    };
  });

  const selectedRangeDates = (dates: IFilterDateRangeMap) => {
    dispatch(setFilterDateRange(dates));
  };

  //converte os values em percentagem para enviar no gradiente como é colocado no heatmap
  const heatmapColors = rangeColorsHeatMap(legendHeatmapColors);

  const maxValue = maxValueLegend(heatmapData, mapBounds);

  const minValue = minValueLegend(heatmapData, mapBounds);

  return (
    <>
      <Card>
        <CardContent
          className={[styles["filter-card"], styles["legend-map"]].join(" ")}
        >
          <Grid container item>
            <Calendar
              startDate={rangeDefault.startDate}
              endDate={rangeDefault.endDate}
              // TODO temporario para a demo de AveiroDemo
              // maxDate={subDays(new Date(), 1)}
              disabled={disabledCalendar}
              onSelectedRange={selectedRangeDates}
              predefinedRanges={{
                labelLast30Days: t("calendar.last30Days"),
                labelLast7Days: t("calendar.last7Days"),
                position: "left",
              }}
              locale={i18n.language}
              labelBtnConfirm={t("apply")}
              labelBtnCancel={t("cancel")}
            />
            <Divider sx={{ marginTop: 1, width: "100%" }} />
          </Grid>

          <Grid container item>
            <Typography variant="subtitle1">
              {heatmap && t("map.legend.heatmapTitle")}
              {!heatmap && t("map.legend.visitsTitle")}
            </Typography>
          </Grid>

          <Grid container item>
            <Typography variant="subtitle2" sx={{ paddingBottom: 2 }}>
              {heatmap && t("map.legend.heatmapSubtitle")}
              {!heatmap && t("map.legend.visitsSubTitle")}
            </Typography>
          </Grid>

          <Loading show={loading} />

          {!optionsLegend &&
            (!heatmapData || arrayIsEmpty(heatmapData.points)) &&
            !loading && <NoData error={error} />}

          {!loading &&
            !heatmap &&
            optionsLegend?.map((legend, index) => (
              <Grid container item key={index}>
                <Grid item sx={{ height: 30 }} justifyContent="center">
                  <SquareRoundedIcon
                    sx={{ color: legend.color, height: 30, width: 30 }}
                  />
                </Grid>
                <Grid item sx={{ margin: "auto 0" }}>
                  <Typography variant="body1">{legend.text}</Typography>
                </Grid>
              </Grid>
            ))}

          {heatmap &&
            heatmapData &&
            !arrayIsEmpty(heatmapData.points) &&
            !loading && (
              <ColorRangeLegend
                colors={heatmapColors}
                maxValue={maxValue}
                minValue={minValue}
                titleTooltip={t("map.legend.visits")}
              />
            )}
        </CardContent>
      </Card>
    </>
  );
};
