import { BackToButton, EntityInfo, EntityInfoItem, LayoutContext } from '@alb/live-lib'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Tab } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ISelectOption } from 'types/interfaces'
import { TAccount } from 'types/types'

import FilterSelect from 'components/Utils/FilterSelect'
import Loading from 'components/Utils/Loading'
import NoData from 'components/Utils/NoData'
import { TabPanelContainer } from 'components/Utils/Tab/TabPanelContainer'
import useGet from 'hooks/fetchData/useGet'
import { ServiceApiUrl } from 'services/ServiceApiUrl'
import { ContainedTabs } from 'styles/css/components'
import { formatDate } from 'utils/date'
import { formatRoutePath } from 'utils/routePath'

import AdaptersListTab from './adaptersTab/AdaptersListTab'

const AccountsView = () => {
  let params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { addHeader } = useContext(LayoutContext)
  const { data: account, loading, error } = useGet(
    `${ServiceApiUrl.accounts}/${params.accountsId}`
  )

  const handleSelectedAccount = (option: ISelectOption | null) => {
    if (!option) return undefined

    const path = formatRoutePath(location, params, {
      accountsId: option.value,
    })
    navigate(path)
  }

  const header = {
    title: account?.name ? account?.name : t("accounts.detail"),
    backTo: (
      <BackToButton
        title={t('goBack')}
        onClick={() => navigate('/management/accounts')}
      />
    ),
    select: account && (
      <FilterSelect<TAccount>
        apiUrl={ServiceApiUrl.accounts}
        onSelected={handleSelectedAccount}
        selectedValue={params?.accountsId}
      />
    ),
  }

  useEffect(() => {
    addHeader(header)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account?.name])

  let accountView = <NoData error={error} />
  let adapters = <NoData error={error} />

  if (loading) {
    accountView = <Loading />
    adapters = <Loading />
  }

  if (account) {
    accountView = (
      <EntityInfo title={t('common.details')}>
        <EntityInfoItem label={t('accounts.name')} value={account.name} />
        <EntityInfoItem
          label={t('accounts.status')}
          value={account.is_active ? t('common.active') : t('common.inactive')}
        />
        <EntityInfoItem
          label={t('common.createdAt')}
          value={formatDate(
            account.created_at,
            t('calendar.dateTimeFullFormat')
          )}
        />
        <EntityInfoItem
          label={t('common.updatedAt')}
          value={formatDate(
            account.updated_at,
            t('calendar.dateTimeFullFormat')
          )}
        />
      </EntityInfo>
    )
    adapters = <AdaptersListTab filters={{ id: account.id }} />
  }

  const [value, setValue] = React.useState('1')
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <>
      {accountView}
      {account && 
      <Box mt={2}>
        <TabContext value={value}>
          <ContainedTabs indicatorColor="secondary" onChange={handleChange} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
            <Tab label={t('accounts.adapters')} value="1" />
          </ContainedTabs>

          <TabPanel sx={{ padding: 0, paddingTop: 2 }} value="1">
            <TabPanelContainer>{adapters}</TabPanelContainer>
          </TabPanel>
        </TabContext>
      </Box>
      }
    </>
  )
}

export default AccountsView
