import { DynamicForm, FilterSectionPopup, IDynamicInputTemplate, IFormDefinition, IRange, ToggleViewButton, TToggle, TypeDynamicInput } from "@alb/live-lib";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, debounce, Grid, useTheme } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ColXTemplate } from "utils/templates";

import { FilterForm, IFilterFormOccurrences } from "./FilterForm";

interface IDefaultValuesOccurrenceFilters {
  startDateRange: IRange | undefined;
  searchInput?: string;
}

interface IOccurrenceFilters {
  display: string;
  setDisplay: React.Dispatch<React.SetStateAction<string>>;
  filterDateRange: IRange | undefined;
  setFilterDateRange: React.Dispatch<React.SetStateAction<IRange | undefined>>;
  filterSearch: string;
  setFilterSearch: React.Dispatch<React.SetStateAction<string>>;
  dataFilterForm: IFilterFormOccurrences | null;
  setDataFilterForm: React.Dispatch<
    React.SetStateAction<IFilterFormOccurrences | null>
  >;
}

export const OccurrenceFilters = ({
  display,
  setDisplay,
  filterDateRange,
  setFilterDateRange,
  filterSearch,
  setFilterSearch,
  dataFilterForm,
  setDataFilterForm,
}: IOccurrenceFilters) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const [open, setOpen] = useState<boolean>(false);

  const filterFormID = "filter-occurrences-form";

  const template = useCallback(
    ({ inputLabel, children }: IDynamicInputTemplate) => {
      return (
        <ColXTemplate
          xsCol={true}
          inputLabel={inputLabel}
          children={children}
        />
      );
    },
    []
  );
  const calculateFiltersCount = useCallback(() => {
    let count = dataFilterForm
      ? Object.values(dataFilterForm).filter((value) => !!value).length
      : 0;
    return count;
  }, [dataFilterForm]);

  const debounceSearch = debounce((cb) => {
    cb();
  }, 350);

  const toggleConfig: TToggle[] = [
    {
      value: "cards",
      text: t("sidebar.dashboard"),
    },
    {
      value: "list",
      text: t("map.list.title"),
    },
  ];
  const handleDisplay = (
    event: React.MouseEvent<HTMLElement>,
    newDisplay: string | null
  ) => {
    if (newDisplay !== null) {
      setDisplay(newDisplay);
    }
  };

  const handleOnSelectedRange = (dateRange: IRange) => {
    setFilterDateRange(dateRange);
  };

  const handleOnSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    debounceSearch(() => {
      setFilterSearch(e.target.value);
    });
  };

  const predefinedRanges = {
    labelLast30Days: t("calendar.last30Days"),
    labelLast7Days: t("calendar.last7Days"),
    position: "left",
  };

  const dynamicForm = {
    formId: "form-search",
    inputsDefinition: {
      startDateRange: {
        inputType: TypeDynamicInput.daterange,
        placeholder: t("calendar.placeholderDate"),
        defaultValue: undefined,
        defaultValueField: undefined,
        onSelectedRange: handleOnSelectedRange,
        noOptionsText: t("common.noOptions"),
        template: template,
        locale: i18n.language,
        predefinedRanges: predefinedRanges,
        labelBtnCancel: t("cancel"),
        labelBtnConfirm: t("apply"),
        clearButton: true,
      },
      ...(display === "list" && {
        searchInput: {
          inputType: TypeDynamicInput.text,
          placeholder: t("common.searchBy"),
          endAdornment: <SearchIcon />,
          defaultValue: "",
          handleOnChange: handleOnSearch,
          template: template,
          InputProps: {
            sx: { backgoundColor: "red" },
          },
        },
      }),
    },
  };

  const defaultValues: IDefaultValuesOccurrenceFilters = {
    startDateRange: dynamicForm.inputsDefinition.startDateRange.defaultValue,
    ...(display === "list" && {
      searchInput: "",
    }),
  };

  const methodsForm = useForm<IDefaultValuesOccurrenceFilters>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const formDefinition: IFormDefinition = { ...dynamicForm, methodsForm };

  const setInitialValue = () => {
    methodsForm.setValue("startDateRange", filterDateRange, {
      shouldDirty: true,
    });
  };

  const onToggle = (open: boolean) => {
    setOpen(open);
  };

  const cancelFilterForm = () => {
    setOpen((prev) => !prev);
  };

  const applyFilterForm = () => {
    cancelFilterForm();
  };

  const onSubmit: SubmitHandler<IFilterFormOccurrences> = async (
    payLoad: IFilterFormOccurrences
  ) => {
    setDataFilterForm(payLoad);
  };

  useEffect(() => {
    setInitialValue();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Grid container marginTop={4}>
        <Grid item xs={display === "list" ? 5 : 8}>
          <ToggleViewButton
            size={"medium"}
            display={display}
            toggle={toggleConfig}
            onChange={handleDisplay}
          />
        </Grid>
        <Grid
          item
          xs={display === "list" ? 7 : 4}
          sx={{
            ">form >div >div >div.MuiTextField-root": {
              background: theme.palette.mode === "light" ? "white" : "none",
              borderRadius: "12px",
            },
          }}
          display={"flex"}
          justifyContent={"flex-end"}
        >
          <DynamicForm formDefinition={formDefinition} />
          {display === "list" && (
            <div style={{ marginLeft: "16px" }}>
              <FilterSectionPopup
                filterTitle={t("common.filter")}
                isOpen={open}
                onToggle={onToggle}
                children={
                  <Box>
                    <FilterForm
                      formID={filterFormID}
                      data={dataFilterForm}
                      numActiveFilters={calculateFiltersCount()}
                      onFormSubmit={onSubmit}
                    />
                    <Grid container item mt={4} justifyContent={"flex-end"}>
                      <Button
                        sx={{ marginRight: "10px" }}
                        variant="outlined"
                        onClick={cancelFilterForm}
                      >
                        {t("cancel")}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        type={"submit"}
                        form={filterFormID}
                        onClick={applyFilterForm}
                      >
                        {t("apply")}
                      </Button>
                    </Grid>
                  </Box>
                }
                activeFiltersCount={calculateFiltersCount()}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};
