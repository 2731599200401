
import { formatNumber } from "utils/number";

import { IReactECharts } from "./ReactECharts";
import { TooltipComponentOption } from "echarts";

export const colors = [
  "#51bfbc",
  "#9986bc",
  //variants of #51bfbc
  "#c08a2d",
  "#ecc255",
  "#be8fd8",
  "#875ba0",
  "#c17982",
  "#efa2ab",
  "#9dd8d6",
  "#2e9492",
  //variants of #9986bc
  "#55742f",
  "#7d9d55",
  "#c1b6d7",
  "#6a598c",
  "#256657",
  "#468e7e",
  "#c49d62",
  "#9a6d3c",
];

export const defaultOptions: IReactECharts["option"] = {
  color: colors,
  tooltip: {
    confine: true,
    extraCssText: "white-space:inherit;",
    trigger: "axis",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    valueFormatter: function (value: any) {
      return formatNumber(value);
    },
  } as TooltipComponentOption,
  legend: {
    bottom: 0,
    icon: "circle",
    itemGap: 42,
    itemHeight: 12,
    textStyle: {
      fontSize: "12px",
      color: "#333333",
      fontFamily: "Altice",
    },
  },
  grid: {
    left: "8%",
    right: "8%",
    bottom: "30%",
  },
  xAxis: {
    offset: 10,
    boundaryGap: false,
    axisTick: { show: false },
    type: "category",
    data: [],
  },
  series: [],
  yAxis: [
    {
      axisLabel: {
        formatter: (val: any) => formatNumber(val) as string,
      },
    },
  ],
};
