export function setLocalStorage<T> (key: string, value: T | null) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getLocalStorage<T> (key: string): T | null {
  const json = localStorage.getItem(key);

  if (!json) {
    return null;
  }

  try {
    const data:T = JSON.parse(json);
    return data ?? null;
  } catch {
    return json as unknown as T
  }
}

export function removeItem(key:string) {
  localStorage.removeItem(key);
}
