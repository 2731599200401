import { LayoutContext } from "@alb/live-lib";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ChartConsumptionBT from "components/energy-ptd/charts/ChartConsumptionBT";
import ChartParishesAveiro from "components/energy-ptd/charts/ChartParishesAveiro";
import useGet from "hooks/fetchData/useGet";
import usePermission from "hooks/usePermission";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { PERMISSIONS } from "utils/permissions/permissions";

const EnergyPTD = () => {
  const [parishes, setParishes] = useState<string[]>([]);
  const [valuesTotalConsumption, setValuesTotalConsumption] = useState<string[]>([]);
  const { t } = useTranslation();
  const [codeError, setCodeError] = useState<string | number | undefined>(
    undefined
  ); // esta variavel serve para esconder o grafico quando o user nao tem autorização para ver (o BE para o 2ºchart nao envia esse erro, nos modulos o BE nao filtra se o user tem permissao ou nao para os adpaters dos modulos)
  // ------ permissions
  const { hasPermission } = usePermission();
  const canREAD = hasPermission(PERMISSIONS.MODULES.ENERGYPTD.READ);

  // ------ header page
  const header = {
    title: t("energyPTD.title"),
  };
  const { addHeader } = useContext(LayoutContext);

  useEffect(() => {
    addHeader(header);
    // eslint-disable-next-line
  }, []);

  function handleChange(
    value: any
  ) {
    setParishes(value)
  }

  function handleValuesTotalConsumption(
    value: any
  ) {
    setValuesTotalConsumption(value)
  }

  const {
    data: consumptionBT,
    loading,
    error,
  } = useGet(ServiceApiUrl.parishInfo);

  function showCodeErrorRequest(code: string | number | undefined) {
    setCodeError(code);
  }

  return (
    <>
      {canREAD && (
        <>
          <Grid
            mb={3}
            container
            // columnSpacing={5}
            rowSpacing={3}
            mt={4}
            justifyContent="space-between"
          >
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <ChartConsumptionBT 
                    errorRequest={showCodeErrorRequest}
                    consumptionBT={consumptionBT} 
                    handleChangeSecondItem={handleChange} 
                    valuesTotalConsumption={handleValuesTotalConsumption} />
                  {codeError !== 30001 && (
                    <>
                      <Typography variant="h3" fontSize={"22px"} mt={6}>
                        {t("energyPTD.titleParishesChart")}
                      </Typography>
                      <ChartParishesAveiro
                        parish={parishes}
                        valuesConsumption={valuesTotalConsumption}
                        consumptionBT={consumptionBT}
                        error={error}
                        loading={loading}
                      />
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default EnergyPTD;
