import { IMapPoint } from "@alb/live-lib";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import useGet from "hooks/fetchData/useGet";
import { t } from "i18next";
import { LatLngExpression } from "leaflet";
import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { IFeedNotification } from "types/interfaces";
import { formatDate } from "utils/date";

import getUrlByType from "./iconList";

import "styles/modules/notificationFeed/notificationFeedRow.scss"
import { IDeviceNotification } from "./NotificationFeed";

const NotificationRow = (props: { n: IDeviceNotification, onPointClick: (point: IMapPoint) => void, onReady: () => void }) => {

    const { n, onPointClick, onReady } = props;
    const [ notificationState, setNotificationState ] = useState<IFeedNotification>();
    const [ device, setDevice ] = useState<any>()
    const [ clicked, setClicked ] = useState(false);
    const map = useMap();
    const theme = useTheme();

    const type = n.last_read_value.park_type;


    const { data: deviceArr, loading } = useGet(
        ServiceApiUrl.devices,
        {contains: n.external_id}
        )

    useEffect(() => {
        if(!loading) {
            setDevice(deviceArr.data[0])
        }
        // eslint-disable-next-line
    }, [deviceArr])

    useEffect(() => {

        if(device) {
            let notificationText: string = t("notifications.devices.smartparking", { 
                deviceId: device.title, 
                type: t("typesParking.park_type." + n.last_read_value.park_type.toLowerCase()),
                state: t("typesParking.park_occupied." + n.last_read_value.park_occupied)
            })

              const notification: IFeedNotification = {
                text: notificationText,
                date: n.last_read_at
              }

              setNotificationState(notification)
        }
    // eslint-disable-next-line
    }, [device])

    useEffect(() => {
        if(!loading)
            onReady();
    // eslint-disable-next-line
    }, [loading])

    const handleRowClick = () => {
        const coordinates: LatLngExpression = {
            lat: device?.geolocation.iconPosition[0],
            lng: device.geolocation.iconPosition[1],
        }
        map.setView(coordinates, 100)
        setClicked(true)
        onPointClick(device)
    }
    

    n.seen = true;

    return(<>
        {<Box onClick={handleRowClick} className="notificationFeedRow" sx={{ 
            ":hover": {
                backgroundColor: theme.palette.mode === "light" ? "#f1eff6" : "#757575" 
            },
            display: loading ? "none" : ""   
        }}>
            <Box className="iconWrapper">
                <Box className={n.unread && !clicked ? "unreadAnimation rowIcon" : "rowIcon"} sx={{ 
                    backgroundImage: `url(${getUrlByType(type, n.last_read_value.park_occupied)})`,
                }}>
                </Box>
            </Box>
            <Box className="rowContentWrapper">
                <Box className="rowMainContent">
                    {
                        !loading && notificationState &&<>
                            <Box className="textWrapper">
                                <Typography mb={1} variant="h3" fontSize={"13px"}>{notificationState.text}</Typography>
                                <Typography variant="body1"
                                    color="text.secondary"
                                    className="text">
                                        {formatDate(notificationState.date, t("calendar.dateTimeFullFormat"))}
                                </Typography>
                            </Box>
                        
                        </>
                    }
                </Box>
                <Divider />
            </Box>
        </Box>}
        </>
        
    )
}

export default NotificationRow;
