/* eslint-disable react-hooks/exhaustive-deps */
import { IMap, IRange, Map, TileType } from "@alb/live-lib";
import { Box } from "@mui/material";
import { LatLngExpression } from "leaflet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { legendHeatmapColors } from "utils/heatMap";
import { arcgisKey } from "utils/keys";
import { arrayIsEmpty } from "utils/utils";

import { HeatMapLegend } from "./utils/HeatMapLegend";

interface IBugaHeatMapBikes {
  selectedDateRange: IRange | undefined;
}

export const BugaHeatMapBikes = ({ selectedDateRange }: IBugaHeatMapBikes) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);

  const [mapCenter, setMapCenter] = useState<LatLngExpression | undefined>();
  const [boundsHeatMap, setBoundsHeatMap] = useState<number[][]>([]);

  const initialZoom = 15;
  const mapTile = user.extra_params?.map_tile_layer || TileType.default;
  const translations = {
    buttons: {
      layers: t("map.buttons.layers"),
      recenter: t("map.buttons.recenter"),
    },
  };

  const {
    data: coordinates,
    loading,
    error,
    refetch
  } = useGet(ServiceApiUrl.bugaBikesHeatMap, paramsRequest(), {manual: true})

  function paramsRequest() {
    const params = {
      from: selectedDateRange?.startDate,
      to: selectedDateRange?.endDate
    }
    return params
  }

  function showLeftBottomContentMap() {
    return (
      <HeatMapLegend
        title={t("buga.heatMap.title")}
        loading={loading}
        error={error}
        rangeColors={legendHeatmapColors}
        coordinates={coordinates}
        mapBounds={boundsHeatMap}
      />
    );
  }

  function handleBoundsChange(bounds: number[][]) {
    setBoundsHeatMap(bounds);
  }

  let args: IMap = {
    mapVariant: "component",
    language: user?.language.toLowerCase() || "pt",
    mapTranslations: translations,
    mapCenterPoint: mapCenter,
    initialMapCenter: mapCenter
      ? (Object.values(mapCenter) as LatLngExpression)
      : undefined,
    onBounds: handleBoundsChange,
    mapZoom: initialZoom,
    initialZoom: initialZoom,
    showZoom: true,
    mapTileType: mapTile,
    mapModule: false,
    points: [],
    heatmap: {
      coords: coordinates,
      gradientColor: legendHeatmapColors,
    },
    arcgisKey: arcgisKey,
    // legend of heatMap
    leftBottomContentMap: showLeftBottomContentMap(),
    dynamicAttribution: true,
  };

  useEffect(() => {
    if (user && !arrayIsEmpty(user.client.center_point)) {
      setMapCenter({
        lat: user.client.center_point[0],
        lng: user.client.center_point[1],
      });
    }
  }, [user]);

  useEffect(() => {
    refetch();
  }, [selectedDateRange])
  return (
    <>
      {mapCenter && args && (
        <Box
          className="map-component"
          sx={{
            height: "600px",
          }}
        >
          <Map {...args} />
        </Box>
      )}
    </>
  );
};
