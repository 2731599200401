/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IApiResponse, Loading } from "@alb/live-lib";
import { useEffect, useState } from "react";
import { ISelectOption } from "types/interfaces";
import { useForm } from "react-hook-form";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import WeatherChart from "./weatherChart";
import WeatherCarousel from "./weatherCarousel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FilterSelect from "components/Utils/FilterSelect";
import { TDevice } from "types/types";
import { dateTimeFormat, formatDate } from "utils/date";

const Temperature = ({ idAdapter }: any) => {
  const { t } = useTranslation();

  const [deviceSelected, setDeviceSelected] = useState<any | null>(null);
  const [selectedStream, setSelectedStream] = useState<any>(null);
  const [rangeDate, setRangeDate] = useState<{
    startDate: string;
    endDate: string;
  }>();
  const [loadingStreams, setLoadingStreams] = useState<boolean>(false);

  const defaultValues = {
    device: deviceSelected ? deviceSelected : null,
  };
  //   let defaultValues = {
  //         device:  {label
  //         :
  //         "Biblioteca Municipal de Aveiro",
  //         value
  //         :
  //         "20af26a3-0021-4893-91fd-fc3f7c21aa3e"}
  //       }

  const methods = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const { reset } = methods;

  const onChangeHandlerValue = (option: ISelectOption | null) => {
    if (!option) return undefined;
    setRangeDate(undefined);
    setSelectedStream(undefined);
    setDeviceSelected(option);
  };
  function onChangeStream(value: any) {
    setSelectedStream(value);
  }

  useEffect(() => {
    if (deviceSelected) {
      var endDate = new Date(deviceSelected?.dateLastRead);
      endDate.setHours(endDate.getHours() - 24);

      setRangeDate({
        startDate: dateTimeFormat(endDate),
        endDate: dateTimeFormat(deviceSelected?.dateLastRead),
      });
      reset(defaultValues);
    }
  }, [deviceSelected]);

  const customOptions = (data: IApiResponse<TDevice>) => {
    return data.data?.map((p: any) => ({
      label: p.title,
      value: p.id,
      external_id: p.external_id,
      dateLastRead: p.date[0],
    }));
  };

  return (
      <Box data-testid={"weatherChart"}>
      <Typography variant="h3" fontSize={"22px"}>
        {t("executive.weather.title")}
      </Typography>
      <div>
        {rangeDate && (
          <Typography variant="body1" mb={1}>
            {t("executive.weather.subTitle") + " "} (
            {formatDate(rangeDate?.startDate, t("calendar.dateFullFormat"))} -{" "}
            {formatDate(rangeDate?.endDate, t("calendar.dateFullFormat"))})
          </Typography>
        )}
        {idAdapter && (
          <>
            <LocationOnIcon color="action" sx={{ fontSize: 18, mr: 0.5 }} />
            <FilterSelect<TDevice>
              immediateRequest
              setFirstValue //caso o selectedValue inicial dependa das opções geradas para o select
              showLabel
              apiUrl={ServiceApiUrl.devices}
              onSelected={onChangeHandlerValue}
              selectedValue={deviceSelected?.value}
              customOptions={customOptions}
              sx={{ "& .MuiOutlinedInput-input": { padding: 0 } }}
              otherParams={{ adapters_id: idAdapter }}
            />
          </>
        )}
      </div>
      {<Loading show={!deviceSelected || loadingStreams} />}
      {deviceSelected && rangeDate && (
        <>
          {selectedStream && !loadingStreams && (
            <WeatherChart
              deviceSelected={deviceSelected}
              adapterId={idAdapter}
              selectedStream={selectedStream}
              dateRange={rangeDate}
            />
          )}
          <WeatherCarousel
            deviceType="desktop"
            deviceSelected={deviceSelected?.value}
            handleOnChange={onChangeStream}
            setLoadingStreams={setLoadingStreams}
          />
        </>
      )}
    </Box>
  );
};

export default Temperature;
