import { arrayIsEmpty, IRange, Loading, TableHeadOrder } from "@alb/live-lib";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

import NoData from "components/Utils/NoData";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { BugaTypeKey } from "types/enum";
import { formatOrderBy } from "utils/orderByAPI";

import { IBugaFiltersFieldsForm } from "./BugaFiltersForm";
import { BugaTable } from "./table/BugaTable";

interface IBugaList {
  selectedDateRange: IRange | undefined;
  filterSearch: string;
  filtersPopup: IBugaFiltersFieldsForm | null;
  keyURL: BugaTypeKey;
}

export const BugaList = ({
  selectedDateRange,
  filterSearch,
  filtersPopup,
  keyURL,
}: IBugaList) => {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("asc");
  const [orderBy, setOrderBy] = useState<string>("date");

  const { data, loading, error } = useGet(
    ServiceApiUrl.bugaList(keyURL),
    paramsRequest()
  );

  function paramsRequest() {
    const params = {
      page: page,
      items: ROWS_PER_PAGE,
      order_by: formatOrderBy(order, orderBy),
      ...(filterSearch !== "" && { contains: filterSearch }),
      from: selectedDateRange?.startDate,
      to: selectedDateRange?.endDate,
      ...filtersPopup,
    };
    return params;
  }

  useEffect(() => {
    setPage(1);
  }, [filterSearch]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Loading show={loading} />
        {(error || arrayIsEmpty(data?.data)) && !loading && (
          <NoData error={error} />
        )}
        {!error && !loading && !arrayIsEmpty(data?.data) && (
          <BugaTable
            data={data}
            orderBy={orderBy}
            order={order}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            page={page}
            setPage={setPage}
            keyURL={keyURL}
          />
        )}
      </Grid>
    </Grid>
  );
};
