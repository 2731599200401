/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  Grid,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import Collapse from "@mui/material/Collapse";

import {
  getGeojsonFilesUpload,
  getGeojsonType,
  getSelectedMapType,
} from "store/slices/mapSlice";

import styles from "styles/modules/map/list.module.scss";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ExpandButtonStyle } from "styles/css/components";
import { Loading, MapType, arrayIsEmpty } from "@alb/live-lib";
import SaveIcon from '@mui/icons-material/Save';
import AnalysisAdd from "../controls/analysisTab/analysisAdd/AnalysisAdd";
import { IGeojsonFeatures, IGeojsonFeaturesCollection } from "types/interfaces";
interface IListUploadFiles {
  onClearFile?: (id: string) => void;
  onChangeVisibility?: (id: string) => void;
  getGeojsonUpdate?:(features: IGeojsonFeatures[], analysis: boolean) => IGeojsonFeaturesCollection ;
}

export const ListUploadFiles = ({
  onClearFile,
  onChangeVisibility,
  getGeojsonUpdate
}: IListUploadFiles) => {
  const { t } = useTranslation();
  const geojsonUpload = useSelector(getGeojsonFilesUpload);
  const viewMapType = useSelector(getSelectedMapType);
  const geojsonType = useSelector(getGeojsonType);

  const [collapsed, setCollapsed] = useState<boolean>(true);
  function onHideDetail() {
    setCollapsed(!collapsed);
  }

  function onClearUpload(id: string) {
    onClearFile && onClearFile(id);
  }

  const changeVisibility = (id: string) => {
    onChangeVisibility && onChangeVisibility(id);
  };
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const handleAnalysisAddDialog = () => {
    setOpenDialog(true);
  };

  const getColorMarker = (file: IGeojsonFeatures) => {
    if (file.markers && file.markers[0].properties) {
      return {
        color: `#${file.markers[0]?.properties.color}`,
      };
    } else if (file.features[0].properties) {
      return {
        color: `#${file.features[0].properties.color}`,
      };
    }
  };

  const filesStillLoading = Boolean(geojsonType.includes("upload")) && arrayIsEmpty(geojsonUpload)

  return (
    <>
      <Grid container item className={styles["list-detail__title"]}>
        <Grid item xs={11}>
          <Typography variant="h3">
            {t("map.listUpload.title", { count: 3 })}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <ExpandButtonStyle onClick={onHideDetail}>
            {collapsed ? (
              <ExpandLess fontSize="inherit" />
            ) : (
              <ExpandMore fontSize="inherit" />
            )}
          </ExpandButtonStyle>
        </Grid>
      </Grid>

      <Collapse in={collapsed} unmountOnExit sx={{ paddingLeft: "15px" }}>
        <List>
          {filesStillLoading &&
            <Grid sx={{pr: "20px" }}>
             <Loading show={filesStillLoading}/>
            </Grid>
          }
          {geojsonUpload && geojsonUpload?.length > 0 && (
            <>
              {geojsonUpload.map((file: IGeojsonFeatures) => (
                <Grid key={file.id} item xs={12}>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        size="small"
                        edge="end"
                        aria-label="remove"
                        onClick={() => {
                          onClearUpload(file.id);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {viewMapType === MapType.heat && (
                      <>
                        <Grid item xs={1}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              changeVisibility(file.id);
                            }}
                          >
                            {file.visible ? (
                              <VisibilityIcon fontSize="inherit" />
                            ) : (
                              <VisibilityOffIcon fontSize="inherit" />
                            )}
                          </IconButton>
                        </Grid>
                        {/* <Grid item xs={1}> */}
                        <ListItemIcon sx={{ minWidth: "40px" }}>
                          <CircleIcon
                            fontSize="small"
                            sx={getColorMarker(file)}
                          />
                        </ListItemIcon>
                        {/* </Grid> */}
                      </>
                    )}
                    <ListItemText
											className='with-overlay'
                      title={file.name}
                      primaryTypographyProps={{
                        style: { fontWeight: "normal", marginRight: "50px" },
                      }}
                      primary={file.name}
                    />
                  </ListItem>
                </Grid>
              ))}
              <Grid sx={{ mt: 2, pr: "20px" }}>
                <Button
                  fullWidth
                  variant="contained"
                  disableElevation
                  startIcon={<SaveIcon />}
                  onClick={handleAnalysisAddDialog}
                >
                  {t("analysis.addAnalysis")}
                </Button>
              </Grid>
            </>
          )}
        </List>
      </Collapse>

      {openDialog && (
        <AnalysisAdd
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
          }}
          getGeojsonUpdate={getGeojsonUpdate}
        />
      )}
    </>
  );
};
