import DeleteIcon from '@mui/icons-material/DeleteRounded'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, TableCell, TableRow, useTheme } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TEvent } from 'types/types'

import usePermission from 'hooks/usePermission'
import { formatDate } from 'utils/date'
import { PERMISSIONS } from 'utils/permissions/permissions'

import { ChipStatus } from 'components/Utils/ChipStatus'

const EventsTableRow = (props: {
  row: TEvent
  onDelete: (row: TEvent) => void
  onEdit: (row: TEvent) => void
  onRowClick: (row: TEvent) => void
}) => {
  const { row, onDelete, onEdit, onRowClick } = props
  const { t } = useTranslation()

  const { hasPermission } = usePermission()
  const canUpdate = hasPermission(PERMISSIONS.MODULES.EVENTS.UPDATE)
  const canDelete = hasPermission(PERMISSIONS.MODULES.EVENTS.DELETE)

  const theme = useTheme()
  return (
    <>
      <TableRow
        sx={{ cursor: 'pointer', height: 44 }}
        onClick={(e): void => {
          e.stopPropagation()
          onRowClick(row)
        }}
      >
        <TableCell data-testid={"eventNameCell"}>{row.name}</TableCell>
        <TableCell>{row.type.name}</TableCell>
        <TableCell>
          <ChipStatus
            label={row.status.name}
            sx={{
              ...(row.status.color && {
                backgroundColor: row.status.color,
                color: theme.palette.getContrastText(row.status.color),
              }),
            }}
          />
        </TableCell>

        <TableCell>
          {formatDate(row.start_date, t('calendar.dateTimeFormatLocal'))}
        </TableCell>

        <TableCell>
          {formatDate(row.end_date, t('calendar.dateTimeFormatLocal'))}
        </TableCell>
        <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
          {canUpdate && (
            <IconButton
              title={t('common.edit')}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation()
                onEdit(row)
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          {canDelete && (
            <IconButton
              title={t('common.delete')}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation()
                onDelete(row)
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

export default EventsTableRow
