import { IRange } from "@alb/live-lib";

import WazeMapListComponent from "./WazeMapListComponent";

interface IWazeListProps {
  selectedAdapter: string,
  selectedFilterRange: IRange,
  selectedType: string | null,
  filterSearch: string | null,
  selectedLevel: string | null,
}

const WazeList = (props: IWazeListProps) => {

  const { selectedAdapter, selectedFilterRange, selectedType, filterSearch, selectedLevel } = props;

  return <><WazeMapListComponent  type={selectedAdapter} filterSelectedRange={selectedFilterRange} selectedType={selectedType} selectedLevel={selectedLevel} filterSearch={filterSearch} /></>
}

export default WazeList;