import { useFeedback } from "@alb/live-lib";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { IClientForm, IDialogProps } from "types/interfaces";

import useUpdate from "hooks/fetchData/useUpdate";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser, setClient } from "store/slices/authSlice";

import ClientsForm from "./ClientsForm";

const ClientsEdit = ({
  open,
  data,
  onClose,
  onSuccess,
}: IDialogProps<IClientForm>) => {
  const { t } = useTranslation();
  const { addFeedback } = useFeedback();
  const editFormId = "edit-clients-form";
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  //pedido para editar clients
  const { loading, refetch: updateClients } = useUpdate(
    ServiceApiUrl.clients,
    data?.id
  );

  // colocar o botão guardar a disabled
  const [formIsValid, setFormIsValid] = useState(false);
  const getFormIsValidStatus = (status: boolean) => {
    setFormIsValid(status);
  };

  // sucesso
  const handlerSuccess = () => {
    onClose && onClose(); //fecha modal
    addFeedback({
      message: t("feedback.success.changesSaved"),
      severity: "success",
    });
    onSuccess && onSuccess();
  };

  const formSubmitHandler = async (payLoad: IClientForm) => {
    let payLoadAPI = payLoad.client;

    // const centerPoint = payLoadAPI.center_point;
    // if(typeof centerPoint === 'string') {
    //   const newCenterPoint = centerPoint ? centerPoint.split(",").map(Number) : [];
    //   payLoadAPI.center_point = newCenterPoint;
    // }
    var centerPoint1 = payLoadAPI.center_point;
    centerPoint1 = centerPoint1 && centerPoint1.length > 0 ? centerPoint1 : [];
    payLoadAPI.center_point = centerPoint1;
    try {
      await updateClients({ data: payLoadAPI });
      if (data?.id === user?.client?.id) await dispatch(setClient(payLoadAPI));
      await handlerSuccess();
    } catch (error) {}
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open}>
      <DialogTitle>
        <Typography component={"span"} gutterBottom={true} variant="h5" noWrap>
          {t("clients.editClient")}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <ClientsForm
          formId={editFormId}
          data={data}
          onFormSubmit={formSubmitHandler}
          editForm={true}
          setFormIsValidStatus={getFormIsValidStatus}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("common.cancel")}
        </Button>

        <LoadingButton
          loading={loading}
          variant="contained"
          color="primary"
          disabled={!formIsValid}
          type={"submit"}
          form={editFormId}
        >
          {t("common.save")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ClientsEdit;
