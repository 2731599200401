import { MODULES_KEYS } from "../../modules/modulesKeys";
import { getRolesByModules } from "./clientRolesModules";

// interface IClientsModulesArray {
//   name: string;
//   roles: any;
//   modules: { name: string; key: string }[];
// }

//Array do lado do frontend que vai armazenar o nome dos clientes, os roles e os respetivo módulos, e
//keys (criadas por nós), para permitir atribuir permissões dinamicamente

function getModuleKey(name: string) {
  switch (name) {
    case "CMAveiro_EnergyPTD":
      return MODULES_KEYS.ENERGYPTD;
    case "Dashboard_Executivo":
      return MODULES_KEYS.EXECUTIVE;
    case "CMFunchal_Events":
      return MODULES_KEYS.EVENTS;
    case "Events":
      return MODULES_KEYS.EVENTS;
    case "CMFunchal_Occurrences":
      return MODULES_KEYS.OCCURRENCES;
    case "DashboardExecutivo":
      return MODULES_KEYS.EXECUTIVE;
    case "Occurrences":
      return MODULES_KEYS.OCCURRENCES;
    case "EnergyPTD":
      return MODULES_KEYS.ENERGYPTD;
    case "CMBeja_ElectricMobility":
      return MODULES_KEYS.ELECTRICMOBILITY;
    case "CMBeja_HydrogenSupply":
      return MODULES_KEYS.HYDROGENSUPPLY;
    case "CMBeja_Occurrences":
      return MODULES_KEYS.OCCURRENCES;
    case "UrbanWaste":
      return MODULES_KEYS.URBANWASTE;
    case "CMBeja_CERManagement":
      return MODULES_KEYS.CERMANAGEMENT;
    case "CMBeja_PeopleMobility":
      return MODULES_KEYS.PEOPLEMOBILITY;
    case "CMBeja_OpenData":
      return MODULES_KEYS.OPENDATA;
    case "AveiroDemo_PeopleMobility":
      return MODULES_KEYS.PEOPLEMOBILITY;
    case "CMChaves_PeopleMobility":
      return MODULES_KEYS.PEOPLEMOBILITY;
    case "CMFunchal_Parking":
      return MODULES_KEYS.PARKING;
    case "Waze":
      return MODULES_KEYS.WAZE;
    case "Buga":
      return MODULES_KEYS.BUGA;
    case "AirQuality":
      return MODULES_KEYS.AIRQUALITY;
  }
}

function clientsModulesArray(dataCliente: any) {
  const arrayModules = dataCliente?.modules.map((p: any) => {
    let moduleKey = { name: p.name, key: getModuleKey(p.name) };
    return moduleKey;
  });
  let result = [
    {
      name: dataCliente?.name,
      roles: getRolesByModules(dataCliente),
      modules: arrayModules,
    },
  ];
  return result;
}

export { clientsModulesArray, getModuleKey };
