/* eslint-disable react-hooks/exhaustive-deps */
import { IDrawCoordinates, IMapPoint, IMediaFile, InputDateTime, InputSelect, InputText, Loading, RequiredField, useUpdateEffect } from "@alb/live-lib";
import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import { yupResolver } from "@hookform/resolvers/yup";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import { FormGroup, Grid, styled, Typography, useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IOccurrenceForm } from "types/interfaces";

import { RequiredFields } from "components/Utils/RequiredField";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { selectCoords, selectDrawingMap, setDrawnCoords } from "store/slices/mapSlice";
import { addItemListDeletedMedia } from "store/slices/occurrencesSlice";
import { getSelectedModule } from "utils/modules/modules";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { objectIsEmpty } from "utils/utils";

import { useCategoriesOptions } from "../hooks/useCategoriesOptions";
import useGroupOptions from "../hooks/useGroupOptions";
import { useStatusOptions } from "../hooks/useStatusOptions";
import useUrgencyOptions from "../hooks/useUrgencyOptions";
import useUsersOptions from "../hooks/useUsersOptions";
import { formDefaultValues } from "./formDefaultValues";
import { OccurrenceMapAndMedia } from "./OccurrenceMapAndMedia";
import { getOccurrenceFormSchema, InputsLengths } from "./validations";

interface IOccurrenceFormComponent {
  formId: string;
  data?: IOccurrenceForm;
  editMode: "add" | "edit" | "detail";
  onFormSubmit: (payLoad: IOccurrenceForm) => void;
  setFormIsValidStatus?: (status: boolean) => void;
}

const DoubleSelect = styled(Grid)(({ theme }) => ({
  display: "flex",
  borderRadius: "12px",
  alignItems: "baseline",
  border: "1px solid",
  borderColor: theme.palette.greyColor.light,
  "&:hover": {
    borderColor: theme.palette.text.primary,
    borderWidth: 1,
  },
  "&.focused": {
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
  },
  ">.MuiGrid-item:first-of-type >.MuiFormControl-root >.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled":
    {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  ">.MuiGrid-item:last-child >.MuiFormControl-root >.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled":
    {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
}));

const OccurrenceForm = ({
  formId,
  data,
  editMode,
  onFormSubmit,
  setFormIsValidStatus,
}: IOccurrenceFormComponent) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const editForm: boolean = editMode === "detail" ? false : true;
  const mapTranslations = {
    buttons: {
      layers: t("map.buttons.layers"),
      selectAreas: t("map.buttons.selectAreas"),
      edit: t("map.buttons.edit"),
      remove: t("map.buttons.remove"),
      marker: t("map.buttons.marker"),
      recenter: t("map.buttons.recenter"),
      delete: t("map.buttons.remove"),
      cancel: t("map.buttons.cancel"),
      close: t("close"),
    },
    popups: {
      lastRead: t("map.marker.lastReading"),
    },
    date: {
      dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
    },
    leafletDraw: {
      removeShapeConfirmation: t("leafletDraw.removeShapeConfirmation"),
      removeShape: t("leafletDraw.removeShape"),
      dragToEdit: t("leafletDraw.dragToEdit"),
      clickCancelToUndo: t("leafletDraw.clickCancelToUndo"),
      clickToRemove: t("leafletDraw.clickToRemove"),
      howToDrawCircle: t("leafletDraw.howToDrawCircle"),
      howToDrawCircleMarker: t("leafletDraw.howToDrawCircleMarker"),
      howToDrawMarker: t("leafletDraw.howToDrawMarker"),
      howToDrawPolygon: t("leafletDraw.howToDrawPolygon"),
      howToDrawRectangle: t("leafletDraw.howToDrawRectangle"),
    },
  };
  const deleteMediaTranslations = {
    title: t("occurrences.deleteMedia"),
    message: t("occurrences.deleteMediaConfirmation"),
    actionConfirmText: t("common.delete"),
    actionCancelText: t("common.cancel"),
  };
  const defaultValues = formDefaultValues(data);
  const assigneeTypeOptions: TOption[] = [
    {
      label: t("user.user"),
      value: "user",
    },
    {
      label: t("occurrences.team"),
      value: "usergroup",
    },
  ];

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const drawingMap = useSelector(selectDrawingMap);
  const drawCoordinates = useSelector(selectCoords);

  const methodsForm = useForm<IOccurrenceForm>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(getOccurrenceFormSchema(data)),
  });
  const {
    control,
    handleSubmit,
    resetField,
    reset,
    trigger,
    watch,
    setValue,
    formState: { errors: filterErrors, dirtyFields },
  } = methodsForm;

  const [selectedDates, setSelectedDates] = useState({
    start_date: undefined,
    end_date: undefined,
  });

  const moduleID = getSelectedModule(user, MODULES_KEYS.OCCURRENCES);

  const { statusOptions, loading: loadingStatusOptions } = useStatusOptions();
  const { categoriesOptions, loading: loadingCategoriesOptions } =
    useCategoriesOptions();
  const { usersOptions, loading: loadingUserOptions } =
    useUsersOptions(moduleID);
  const { groupOptions, loading: loadingGroupOptions } = useGroupOptions();
  const { urgencyOptions, loading: loadingUrgencyOptions } =
    useUrgencyOptions();

  const [assigneeType, setAssigneeType] = useState<string>(
    defaultValues.assignee.assignee_type
  );
  const [assigneeOptions, setAssigneeOptions] = useState<TOption[]>([]);

  const [resetMedia, setResetMedia] = useState<boolean>(false);

  // pedido para atualizar URL dos videos do BE com expiração
  const {
    data: newURLMedia,
    loading: loadingURLMedia,
    error: errorURLMedia,
    refetch: refetchURLMedia,
  } = useGet(`${ServiceApiUrl.occurrences}/${data?.id}/media`, undefined, {
    manual: true,
  });

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IOccurrenceForm> = (
    payLoad: IOccurrenceForm
  ) => {
    onFormSubmit(payLoad);
  };

  // cancel form
  const handleFormReset: SubmitHandler<IOccurrenceForm> = () => {
    setResetMedia(true);
    reset();
  };

  const handleDateOnChange = (date: Date | null, positionDate: string) => {
    setSelectedDates({
      ...selectedDates,
      [positionDate]: date,
    });
  };

  const watchStartDate = watch("record_date");
  const watchEndDate = watch("end_date");
  useEffect(() => {
    // o touchedfield não está a funcionar com o input date time :(...
    // if (!touchedFields.start_date) return;
    if (dirtyFields.end_date) {
      trigger("record_date"); // re validate field
      trigger("end_date"); // re validate field
    }
    // trigger('end_date'); // re validate field
    setTimeout(() => {
      if (!filterErrors.record_date && !filterErrors.end_date) {
        handleDateOnChange(watchStartDate, "record_date");
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchStartDate]);
  useEffect(() => {
    if (dirtyFields.record_date) {
      trigger("record_date"); // re validate field
      trigger("end_date");
    }
    // trigger('start_date'); // re validate field
    setTimeout(() => {
      if (!filterErrors.end_date && watchEndDate) {
        handleDateOnChange(watchEndDate, "end_date");
      }
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchEndDate]);

  //Sempre que os methodsForm.valid alterar, envia os dados
  useEffect(() => {
    if (setFormIsValidStatus)
      setFormIsValidStatus(methodsForm.formState.isValid);
  }, [methodsForm.formState.isValid]);

  const handleAssigneTypeChange = (e: any) => {
    resetField("assignee.assignee_id", { defaultValue: "" });
    setAssigneeType(e.target.value);
  };

  function showMarkers(occurrence?: IOccurrenceForm) {
    const points: IMapPoint[] = occurrence
      ? [
          {
            id: occurrence.id || "",
            title: occurrence.subject || "",
            type: occurrence.type || "",
            subType: occurrence.category?.name,
            geolocation: occurrence.geolocation,
            status: occurrence.status?.name,
            date: [String(occurrence.created_at)],
            selected: false,
            icon: occurrence.category?.icon || undefined,
          },
        ]
      : [];
    return points;
  }

  const onDrawCoordinatesChange = (coordinates: IDrawCoordinates | null) => {
    dispatch(setDrawnCoords(coordinates));
  };

  const onDeleteMedia = (media: IMediaFile) => {
    dispatch(addItemListDeletedMedia(media));
  };

  const onUpdateURLMedia = (idMedia: string | undefined) => {
    if (idMedia) {
      refetchURLMedia({ params: { id_media: idMedia } });
    }
  };

  useEffect(() => {
    if (!loadingUserOptions && !loadingGroupOptions) {
      if (assigneeType === "user") {
        setAssigneeOptions(usersOptions);
      }
      if (assigneeType === "usergroup") {
        setAssigneeOptions(groupOptions);
      }
    }
  }, [assigneeType, usersOptions, loadingUserOptions, loadingGroupOptions]);

  useUpdateEffect(() => {
    if (editForm && drawingMap) {
      // coloca o valor das coordendas do mapa no input
      const location = !objectIsEmpty(drawCoordinates)
        ? JSON.stringify(
            drawCoordinates?.iconPosition || drawCoordinates?.coordinates
          )
        : "";
      setValue("geolocation", location, { shouldValidate: true });
    }
  }, [drawCoordinates]);

  return (
    <>
      <FormProvider {...methodsForm}>
        <form
          id={formId}
          onSubmit={handleSubmit(formSubmitHandler)}
          onReset={handleSubmit(handleFormReset)}
        >
          <FormGroup>
            <Grid container spacing={2}>
              <Grid container item xs={6} spacing={2}>
                {/* first column */}
                <Grid container item xs={6} spacing={2} alignContent="start">
                  {editMode !== "add" && (
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="h6">
                        {t("occurrences.reporter")}
                      </Typography>
                      <InputText
                        placeholder={t("occurrences.reporterPlaceholder")}
                        name="reporter"
                        type="text"
                        control={control}
                        disabled
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">
                      {t("occurrences.category")}
                    </Typography>
                    {!loadingCategoriesOptions && (
                      <InputSelect
                        noOptionsText={t("common.noOptions")}
                        placeholder={t("occurrences.categoryPlaceholder")}
                        name="category"
                        control={control}
                        options={categoriesOptions || []}
                        disabled={!editForm}
                      />
                    )}
                    <Loading show={loadingCategoriesOptions} />
                  </Grid>
                  {editMode !== "add" && (
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="h6">
                        {t("occurrences.status")}
                      </Typography>
                      {!loadingStatusOptions && (
                        <InputSelect
                          noOptionsText={t("common.noOptions")}
                          placeholder={t("occurrences.statusPlaceholder")}
                          name="status"
                          control={control}
                          options={statusOptions || []}
                          disabled={!editForm}
                        />
                      )}
                      <Loading show={loadingStatusOptions} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <RequiredField title={t("occurrences.startDate")} />
                    <InputDateTime
                      name="record_date"
                      control={control}
                      maxDate={
                        selectedDates?.end_date
                          ? new Date(selectedDates.end_date)
                          : undefined
                      }
                      handleOnChange={(date: Date | null) => {
                        handleDateOnChange(date, "record_date");
                      }}
                      locale={i18n.language}
                      labelTextTime={t("calendar.time")}
                      showTimeInput
                      labelBtnCancel={t("cancel")}
                      labelBtnConfirm={t("apply")}
                      placeholder={t("calendar.placeholderDateTime")}
                      disabled={!editForm}
                    />
                  </Grid>
                  {editMode !== "add" && (
                    <Grid item xs={12}>
                      <Typography gutterBottom variant="h6">
                        {t("occurrences.resolutionTime")}
                      </Typography>
                      <InputText
                        name="resolution_days"
                        type="text"
                        control={control}
                        disabled
                      />
                    </Grid>
                  )}
                </Grid>
                {/* second column */}
                <Grid container item xs={6} spacing={2} alignContent="start">
                  {editMode !== "add" && (
                    <Grid item xs={12} height="10.4rem"></Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">
                      {t("occurrences.urgency")}
                    </Typography>
                    {!loadingUrgencyOptions && (
                      <InputSelect
                        noOptionsText={t("common.noOptions")}
                        placeholder={t("occurrences.urgencyPlaceholder")}
                        name="urgency"
                        control={control}
                        options={urgencyOptions}
                        disabled={!editForm}
                      />
                    )}
                    <Loading show={loadingUrgencyOptions} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">
                      {t("occurrences.expectedResolutionDate")}
                    </Typography>
                    <InputDateTime
                      name="end_date"
                      control={control}
                      minDate={
                        selectedDates?.start_date
                          ? new Date(selectedDates.start_date)
                          : undefined
                      }
                      handleOnChange={(date: Date | null) => {
                        handleDateOnChange(date, "end_date");
                      }}
                      locale={i18n.language}
                      labelTextTime={t("calendar.time")}
                      showTimeInput
                      labelBtnCancel={t("cancel")}
                      labelBtnConfirm={t("apply")}
                      placeholder={t("calendar.placeholderDateTime")}
                      clearButton
                      disabled={!editForm}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">
                      {t("occurrences.assignUser")}
                    </Typography>
                    {!loadingUserOptions && !loadingGroupOptions && (
                      <DoubleSelect className="fullWidth">
                        <Grid item xs={4}>
                          <InputSelect
                            noOptionsText={t("common.noOptions")}
                            options={assigneeTypeOptions}
                            name={"assignee.assignee_type"}
                            control={control}
                            sx={{
                              "& .MuiSelect-select": {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: "10px",
                                color: theme.palette.common.white,
                                margin: "3px",
                                padding: "6px 11px",
                                "&:focus": {
                                  borderRadius: "10px",
                                },
                              },
                              "& .MuiSelect-icon": {
                                color: theme.palette.common.white,
                              },
                            }}
                            handleOnChange={handleAssigneTypeChange}
                            disabled={!editForm}
                          />
                        </Grid>
                        <Grid item xs={true} sx={{ overflow: "hidden" }}>
                          <InputSelect
                            noOptionsText={t("common.noOptions")}
                            name="assignee.assignee_id"
                            placeholder={t("occurrences.assignPlaceholder")}
                            options={assigneeOptions}
                            control={control}
                            disabled={!editForm}
                          />
                        </Grid>
                      </DoubleSelect>
                    )}
                    <Loading show={loadingUserOptions || loadingGroupOptions} />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="h6">
                    {t("occurrences.occurrenceURL")}
                  </Typography>
                  <InputText
                    placeholder={t("occurrences.occurrenceURLPlaceholder")}
                    name="url"
                    type="text"
                    control={control}
                    disabled={!editForm}
                  />
                </Grid>
                <Grid container item xs={12}>
                  <RequiredField title={t("occurrences.description")} />
                  {/* {data?.audio ? (
                    <> TODO AUDIO </>
                  ) : ( */}
                  <InputText
                    placeholder={t("occurrences.descriptionPlaceholder")}
                    name="description"
                    type="text"
                    multiline
                    rows={5}
                    control={control}
                    disabled={!editForm}
                  />
                  {/* )} */}
                </Grid>
              </Grid>
              <Grid container item xs={6} spacing={2}>
                {editMode !== "add" && (
                  <Grid item xs={12}>
                    <Typography gutterBottom variant="h6">
                      {t("occurrences.address")}
                    </Typography>
                    <InputText
                      name="address"
                      type="text"
                      control={control}
                      disabled
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <RequiredField
                    title={t("occurrences.coordinates")}
                    sx={{
                      verticalAlign: "middle",
                      display: "inline-flex",
                      gap: 0.5,
                    }}
                  >
                    {editForm && (
                      <Tooltip
                        placement="right"
                        title={t("occurrences.selectLocationOnMap")}
                      >
                        <HelpOutlineIcon
                          sx={{ fontSize: 20 }}
                          color="primary"
                        />
                      </Tooltip>
                    )}
                  </RequiredField>
                  <InputText
                    placeholder={t("occurrences.selectLocationOnMap")}
                    name="geolocation"
                    type="text"
                    control={control}
                    endAdornment={<LocationSearchingIcon />}
                    {...(!editForm && { disabled: true })}
                    InputProps={{ ...(editForm && { readOnly: true }) }}
                  />
                </Grid>
                <Grid item xs={12} display="flex" height={"473px"}>
                  <OccurrenceMapAndMedia
                    user={user}
                    markers={showMarkers(data)}
                    mapTranslations={mapTranslations}
                    drawCoordinates={data?.geolocation}
                    onDrawCoordinatesChange={onDrawCoordinatesChange}
                    limitMedia={
                      InputsLengths.max.photos + InputsLengths.max.videos
                    }
                    deleteConfirmTranslations={deleteMediaTranslations}
                    onDeleteMedia={onDeleteMedia}
                    updateURLMedia={{
                      loading: loadingURLMedia,
                      error: errorURLMedia,
                      data: newURLMedia,
                    }}
                    onUpdateURLMedia={onUpdateURLMedia}
                    control={control}
                    formReset={resetMedia}
                    onFormReset={setResetMedia}
                    editMode={editForm}
                  />
                </Grid>
              </Grid>
            </Grid>
          </FormGroup>
        </form>
      </FormProvider>
      <RequiredFields />
    </>
  );
};

export default OccurrenceForm;
