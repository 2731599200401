import { arrayIsEmpty, IApiResponse, IMap, IMapPoint, Map, TileType } from '@alb/live-lib'
import { Box } from '@mui/material';
import { LatLngExpression } from 'leaflet';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import MarkerInfoComponent from 'components/map-entity-info/MarkerInfoComponent';
import useGet from 'hooks/fetchData/useGet';
import { ServiceApiUrl } from 'services/ServiceApiUrl';
import { getUser } from 'store/slices/authSlice';
import { getMapBounds, getMarkerDetails, setBounds, TypeMarkerInfo } from 'store/slices/mapSlice';
import { arcgisKey } from "utils/keys";

import { IQArtFilters } from './QArtContainer';
import { IQARTDevice } from './qartDeviceInterface';
import QArtMapMarkerIcon from './QArtMapMarkerIcon';
import QArtMapMarkerPopupInfo from './QArtMapMarkerPopupInfo';
import QArtMapStreamsContainer from './QArtMapStreamsContainer';

const VIEW_POLLUTANTS = 'o3,no2,particules_pm10,particules_pm25';

interface IQArtMapAvgValues {
	name: string,
	value: number,
	unit: string,
	type: string,
}
export interface IQArtMapMarkers extends IMapPoint {
	agg_values?: IQArtMapAvgValues[],
	global: Omit<IQArtMapAvgValues, 'type'>
}

export interface IQArtMap {
	device: IQARTDevice,
	filters: IQArtFilters
}
function QArtMap({ device, filters }: IQArtMap) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector(getUser);

	const mapBounds = useSelector(getMapBounds);

	const mapCenter: LatLngExpression = device.geolocation;

	const [pollutant, setPollutant] = useState<string>('');

	const [openDetails, setOpenDetails] = useState<boolean>(false);
	const [markerTypeDetails, setMarkerTypeDetails] = useState<string>("");

	const initialZoom = 13;

	const params = {
		from: filters ? filters.dateRange.startDate : undefined,
		to: filters ? filters.dateRange.endDate : undefined,
		streams: VIEW_POLLUTANTS,
		points: JSON.stringify(mapBounds),
	}

	const { data, refetch } = useGet<IApiResponse<IQArtMapMarkers>>(ServiceApiUrl.qartMap, params, { manual: true });

	useEffect(() => {
		if (!arrayIsEmpty(mapBounds)) {
			refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mapBounds])

	const handleBoundsChange = (bounds: number[][]) => {
		dispatch(setBounds(bounds));
	};

	const handlePointClick = (point: IMapPoint) => {
		dispatch(getMarkerDetails(point.id, point.type as TypeMarkerInfo, point.subType, user));

		setMarkerTypeDetails(point.type);
		setOpenDetails(true);
	};

	const handleMapFilterChange = (pollutant: string) => {
		setPollutant(pollutant);
	}

	let markers: IQArtMapMarkers[] = [];
	if (!arrayIsEmpty(data?.data)) {
		data?.data.forEach(marker => {
			const _m = { ...marker, selected: device.external_id === marker.external_id };
			markers.push({
				..._m,
				markerDivIcon: QArtMapMarkerIcon(_m, pollutant, t),
				markerPopup: <QArtMapMarkerPopupInfo marker={_m} pollutant={pollutant} />
			});
		});
	}

	const translations = {
		buttons: {
			layers: t("map.buttons.layers"),
			recenter: t("map.buttons.recenter"),
		},
		popups: {
			lastRead: t("map.marker.lastReading"),
		},
	};
	const argsMap: IMap = {
		idMap: 'qartMap',
		arcgisKey: arcgisKey,
		mapVariant: "component",
		language: user?.language.toLowerCase() || "pt",
		mapTranslations: translations,
		mapCenterPoint: mapCenter,
		initialMapCenter: mapCenter
			? (Object.values(mapCenter) as LatLngExpression)
			: undefined,
		mapZoom: initialZoom,
		initialZoom: initialZoom,
		mapTileType: TileType.default,
		points: markers,
		mapModule: false,
		showZoom: true,
		noClusters: true,
		onPointClick: handlePointClick,
		onBounds: handleBoundsChange,
		leftBottomContentMap: <QArtMapStreamsContainer onChange={handleMapFilterChange} />,
		dynamicAttribution: true,
	};

	return (
		<Box className="map-component" sx={{ height: "600px", }}		>
			{mapCenter && <Map {...argsMap} />}

			{openDetails && markerTypeDetails && (
				<MarkerInfoComponent
					type={markerTypeDetails}
					dialogProps={{
						open: openDetails,
						onClose: () => setOpenDetails(false),
					}}
					onSuccess={() => console.log('markerinfocomponent')}
				/>
			)}
		</Box>
	)
}

export default QArtMap
