import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@store";

import useLocalStorage from "hooks/useLocalStorage";
import usePermission from "hooks/usePermission";

import { IAuth } from "types/interfaces";
import { setCookie } from "utils/cookie";
import { AUTH, AUTH_TOKEN, I18NEXT, THEME } from "utils/keys";

// eslint-disable-next-line react-hooks/rules-of-hooks
const { getLocalStorage, setLocalStorage } = useLocalStorage();
// eslint-disable-next-line react-hooks/rules-of-hooks
const { getUserPermissions } = usePermission();

const initialState: IAuth = {
  roles: getLocalStorage(AUTH)?.roles,
  user: getLocalStorage(AUTH)?.user,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth(state, action) {
      const permissions = getUserPermissions(action.payload.user);
      const response: IAuth = {
        user: action.payload.user,
        roles: permissions,
      };
      const appTheme = response.user.extra_params?.dark_mode ? "dark" : "light";
      setCookie<string>(AUTH_TOKEN, action.payload.auth_token);
      setCookie<string>(I18NEXT, response.user.language.toLowerCase(), {
        maxAge: Number.MAX_SAFE_INTEGER,
      });
      setCookie<string>(THEME, appTheme);

      setLocalStorage(AUTH, response); // store auth info on the localStorage
      state.roles = response.roles;
      state.user = response.user;
    },
    setUser(state, action) {
      const valuesLS = getLocalStorage(AUTH);
      const updatedUser = {
        ...valuesLS.user,
        ...action.payload,
      };
      const newValues: any = {
        ...valuesLS,
        user: updatedUser,
      };
      setLocalStorage(AUTH, newValues);
      state.user = updatedUser;
    },
    setRoles(state, action) {
      const valuesLS = getLocalStorage(AUTH);
      const permissions = getUserPermissions(action.payload);
      const newValues: any = {
        ...valuesLS,
        roles: permissions,
      };
      setLocalStorage(AUTH, newValues);
      state.roles = permissions;
    },
    setClient(state, action) {
      const valuesLS = getLocalStorage(AUTH);
      // change values
      const updatedClient = {
        ...valuesLS.user.client,
        ...action.payload,
      };
      const newValues: IAuth = {
        ...valuesLS,
        user: {
          ...valuesLS.user,
          client: updatedClient,
        },
      };
      // save new values
      setLocalStorage(AUTH, newValues);
      state.user.client = updatedClient;
    },
  },
});

export const { setAuth, setUser, setRoles, setClient } = authSlice.actions;

export const getUser = (state: RootState) => state.auth.user;
export const getRoles = (state: RootState) => state.auth.roles;
export const getNameClient = (state: RootState) =>
  state.auth?.user?.client.name;

export default authSlice.reducer;
