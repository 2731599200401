import { Box, useTheme } from "@mui/material";

const ImageOption = ({ isLoading, name, url, onClick, imageLoaded }: { isLoading: boolean, name: string, url: string, onClick: (item: {name: string, url: string}) => void, imageLoaded: () => void }) => {

    const theme = useTheme()

    const hadleClick = () => {
        onClick({
            name: name,
            url: url
        });
    }

    return(
    <>
        <Box 
        onClick={hadleClick}
        sx={{
            width: "100%",
            cursor: "pointer",
            display: isLoading ?  "none" : "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "100%",
            transition: "background-color 0.5s",
            "img": {
                width: "100%",
            },
            "&:hover": {
                backgroundColor: theme.palette.primary.light
            }
        }}>
            <img alt={name} src={url} onLoad={imageLoaded} />
        </Box>
    </>
    )
}

export default ImageOption;