import { t } from "i18next";
import { IOccurrenceForm } from "types/interfaces";
import { array, date, object, string } from "yup";

import { formatDate } from "utils/date";

export const InputsLengths = {
  max: {
    description: 500,
    notes: 500,
    photos: 3,
    videos: 1,
    sizePhotos: 5, // size in MB
    sizeVideos: 15, // size in MB
  },
  min: {
    name: 3,
    scope: 3,
    description: 3,
  },
};

const URL =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!$&'()*+,;=]|:|@)|\/|\?)*)?$/i;

export const getOccurrenceFormSchema = (data: IOccurrenceForm | undefined) =>
  //se o data existir  significa que foi enviado do formulário de editar
  object().shape(
    {
      category: string(),
      //Se for para retirar a prop "noAllowedWrite", estas validações das datas tem de ser otimizadas no max e min
      record_date: date()
        .typeError(
          t("validations.invalidDate", { dateFormat: "HH:mm, YYYY-MM-DD" })
        )
        .nullable()
        .required(),
      end_date: date()
        .nullable()
        .typeError(
          t("validations.invalidDate", { dateFormat: "HH:mm, YYYY-MM-DD" })
        )
        .when("record_date", ([record_date], schema) => {
          if (record_date) {
            const dateFromFormatted = formatDate(
              record_date,
              "HH:mm, yyyy-MM-dd"
            );
            return schema.min(
              record_date,
              t("validations.invalidMinDate", { minDate: dateFromFormatted })
            );
          } else {
            return schema;
          }
        }),
      geolocation: string().required(),
      ...(data && { status: string() }),
      description: string().required(),
      url: string()
        .nullable()
        .notRequired()
        .when("url", {
          is: (value: any) => value?.length,
          then: (rule) => rule.matches(URL, t("occurrences.URLRule")),
        }),
      urgency: string().notRequired(),
      assignuser: string().notRequired(),
      media: array()
        .json()
        .test(
          "max-media-count",
          t("validations.invalidMaxMedia", {
            maxPhotos: InputsLengths.max.photos,
            maxVideos: InputsLengths.max.videos,
          }),
          (value) => {
            if (value) {
              const photosCount = value.filter(
                (item) => item.media_type === "image"
              ).length;
              const videosCount = value.filter(
                (item) => item.media_type === "video"
              ).length;
              const maxPhotos = InputsLengths.max.photos;
              const maxVideos = InputsLengths.max.videos;

              return photosCount <= maxPhotos && videosCount <= maxVideos;
            }
          }
        )
        .test("repeated-content", t("validations.repeatedContent"), (value) => {
          const set = new Set();
          if (value) {
            for (const file of value) {
              if (set.has(file.url)) {
                return false;
              } else {
                set.add(file.url);
              }
            }
          }
          return true;
        })
        .test(
          "max-media-size",
          t("validations.maxSizeMedia", {
            sizePhotos: InputsLengths.max.sizePhotos + "MB",
            sizeVideos: InputsLengths.max.sizeVideos + "MB",
          }),
          (value) => {
            const set = new Set();
            if (value) {
              for (const file of value) {
                if (file.url.startsWith("data:")) {
                  const twoEquals = file.url.endsWith("==") ? 2 : 1;
                  const base64Length = getLengthBase64(file.url);
                  const totalBytes = base64Length * (3 / 4) - twoEquals;
                  const maxSize = getSizeMedia(file.media_type);
                  set.add(totalBytes <= maxSize);
                }
              }
            }
            if (set.has(false)) return false;
            return true;
          }
        ),
    },
    [
      // Add Cyclic deps here because when require itself
      ["url", "url"],
    ]
  );

function getSizeMedia(typeMedia: "image" | "video") {
  switch (typeMedia) {
    case "image":
      const maxSize = InputsLengths.max.sizePhotos;
      return maxSize * 1024 * 1024; // convert MB to byte
    case "video":
      const maxSizeV = InputsLengths.max.sizePhotos;
      return maxSizeV * 1024 * 1024;
  }
}

function getLengthBase64(stringBase64: string) {
  const base64 = stringBase64.split(";base64,")[1];
  return base64.length;
}
