import { getCookie } from "utils/cookie";
import { THEME } from "utils/keys";

// colors of urgency
export function getColorUrgency(label: string) {
  const theme = getCookie(THEME);
  const darkMode = theme === "dark";
  switch (label.toLowerCase()) {
    case "baixa":
      return {
        chip: "#3EE04733",
        label: darkMode ? "white" : "#06750C",
      };
    case "média":
      return {
        chip: "#FFC04633",
        label: darkMode ? "white" : "#B77800",
      };
    case "alta":
      return {
        chip: "#FF594E33",
        label: darkMode ? "white" : "#CF190D",
      };
    default:
      return {
        chip: "none",
        label: "none",
      };
  }
}