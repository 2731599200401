import { createSlice } from "@reduxjs/toolkit";

import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@store";
import { TAdapterConfigurator, TMarker } from "types/types";
import { DeviceRealTime } from "utils/data-real-time";

export interface AdapterConfiguratorsState {
  adapterConfigurators: TAdapterConfigurator[];
  devices: TMarker[];
  listDevices: TMarker[];
  selectedAdapters: string[];
  devicesFilterParams: any;
  deviceRealTime: DeviceRealTime;
}

const initialState: AdapterConfiguratorsState = {
  adapterConfigurators: [],
  devices: [],
  listDevices: [],
  selectedAdapters: [],
  devicesFilterParams: {},
  deviceRealTime: {
    external_id: '',
    last_read_at: '',
    last_read_value: {
      park_type: '',
      park_occupied: '',
    }
  },
};

export const adapterConfiguratorsSlice = createSlice({
  name: "adapterConfigurators",
  initialState,
  reducers: {
    getAdapters: (state, action: PayloadAction<[]>) => {
      // add adapter to globalState
      state.adapterConfigurators = action.payload;

      // ------ Devices ------ //
      let devices: any = [];
      let device: any = {};

      // create devices array
      state.adapterConfigurators.map((a: any) => {
        return a.devices.map((d: any) => {
          // create single device object with adapter reference keys
          device = {
            ...d,
            domain_devo: a.domain_devo,
            partner_devo: a.partner_devo,
            domain: a.domain,
            partner: a.partner,
            streams: a.streams,
            adapterId: a.id,
            adapterName: a.name,
            typeMarker: "device",
          };
          // create devices array
          return devices.push(device);
        });
      });
      // add devices to global state
      state.devices = devices;
    },
    devicesByAdapter: (state) => {
      const filteredDevices: TMarker[] = [];

      //  Validar o que esta função requer para funcionar após login
      //  let selectedAdapter = current(state.selectedAdapters)
      let selectedAdapter = state.selectedAdapters;

      // check if payload exists and is not empty
      if (selectedAdapter && selectedAdapter.length > 0) {
        // payload contains adapater configurator names
        selectedAdapter.forEach((filter) => {
          // filter devices by adapater name
          // const devices: TMarker[] = allDevices.filter(
          //   (device: TMarker) => device.adapterName === filter
          // )
          // // send each filtered device to new array
          // filteredDevices.push(...devices)
        });
      }
      // set state equal to filtered devices if any or retrieved devices from localStorage
      state.devices = filteredDevices;
    },
    getListDevices: (state, { payload }) => {
      const allDevices = state.devices;
      let devices: TMarker[] = [];
      if (state.devices) {
        devices =
          state.listDevices.length > state.devices.length
            ? state.devices
            : allDevices;
      }
      if (devices.length > 0) {
        state.listDevices = devices.filter((d: TMarker) =>
          payload.contains(d.geolocation.iconPosition)
        );
      }
    },
    devicesByName: (state, { payload }) => {
      const allDevices = state.devices;

      if (payload.length <= 2) {
        state.devices = allDevices;
        return;
      } else {
        state.devices = allDevices.filter((d: TMarker) =>
          d.title.toLocaleLowerCase().includes(payload)
        );
      }
    },
    devicesByListName: (state, { payload }) => {
      const allDevices = state.devices;
      if (payload.length <= 2) {
        state.devices = allDevices;
        return;
      } else {
        const devices = state.devices.filter((d: TMarker) =>
          d.title.toLocaleLowerCase().includes(payload)
        );
        state.devices = devices;
      }
    },
    setAdaptersFilter: (state, action) => {
      state.selectedAdapters = action.payload;
    },
    setDevices(state, action) {
      state.devices = action.payload;
    },
    setDevicesFilterParams(state, action) {
      state.devicesFilterParams = action.payload;
    },
    setOneDeviceMap(state, action) {
      state.devices = state.devices.map((dev) => {
        if (dev.external_id === action.payload.external_id) {
          dev = action.payload
        }
        return dev
      })
    },
    setDeviceRealTime(state, action) {
      state.deviceRealTime = action.payload
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getAdapters,
  devicesByAdapter,
  devicesByName,
  devicesByListName,
  getListDevices,
  setAdaptersFilter,
  setDevices,
  setDevicesFilterParams,
  setOneDeviceMap,
  setDeviceRealTime,
} = adapterConfiguratorsSlice.actions;

// Selectors/Getters
export const selectAdapters = (state: RootState) =>
  state.adapterConfigurators.adapterConfigurators;
export const selectDevices = (state: RootState) =>
  state.adapterConfigurators.devices;
export const selectDeviceRealTime = (state: RootState) =>
  state.adapterConfigurators.deviceRealTime;
export const selectListDevices = (state: RootState) =>
  state.adapterConfigurators.listDevices;
export const selectDevicesFilterParams = (state: RootState) =>
  state.adapterConfigurators.devicesFilterParams;
export default adapterConfiguratorsSlice.reducer;
