import { Box, Button, Grid, Typography } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import SearchOffIcon from "@mui/icons-material/SearchOff";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goHomeHandler = () => {
    navigate("/", { replace: true });
  };

  return (
    <>
      <CssBaseline />
      <Grid container sx={{ textAlign: "center" }}>
        <Grid item xs={true} mt={12}>
          <SearchOffIcon color="error" sx={{ fontSize: 80 }} />
          
          <Typography mt={2} variant="h3">
            {t("errors.somethingWentWrong")}
          </Typography>

          <Typography mb={2} mt={6} component={"span"} variant="h5">
            {t("errors.notFound")}
          </Typography>
          
          <Box mt={10}>
            <Button onClick={goHomeHandler} size="large" variant="contained">
              {t("errors.goToHome")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
export default NotFound;
