import React, { useState } from 'react'
import QArtDeviceSelect from './QArtDeviceSelect'
import { Grid } from '@mui/material'
import QArtMap from './QArtMap'
import QArtFilters, { TDateRange } from './QArtFilters'
import QArtCardChart from './QArtCardChart'
import { IQARTDevice } from './qartDeviceInterface'
import { startOfDay } from "date-fns";

export interface IQArtFilters {
	dateRange: TDateRange,
}
function QArtContainer() {

	const defaultRangeDate = {
		startDate: startOfDay(new Date()),
		endDate: new Date()
	}

	const [device, setDevice] = useState<IQARTDevice | undefined>(undefined);
	const [filters, setFilters] = useState<IQArtFilters>({ dateRange: defaultRangeDate });
	function onChangeHandler(device: IQARTDevice | undefined) {
		setDevice(device);
	}
	function onChangeHandlerFilters(value: TDateRange) {
		setFilters({ dateRange: value });
	}

	return (
		<Grid container >
			<Grid item xs={12} marginTop={4}>
				<Grid container>
					<Grid item xs={12} sm={8}>
						<QArtDeviceSelect onChange={onChangeHandler} />
					</Grid>
					<Grid item xs={12} sm={4}>
						<QArtFilters onChange={onChangeHandlerFilters} dateRange={defaultRangeDate} />
					</Grid>
				</Grid>
			</Grid>
			{device &&
				<>
					<Grid item xs={12} marginTop={4}>
						<QArtCardChart device={device} filters={filters} />
					</Grid>

					<Grid item xs={12} sm={12} marginTop={4}>
						<QArtMap device={device} filters={filters} />
					</Grid>
				</>}
		</Grid >
	)
}

export default QArtContainer
