import { arrayIsEmpty, IMapPoint, Loading } from "@alb/live-lib";
import { useTheme } from "@emotion/react";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { Box, Button, Card, CardContent, Divider, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { useSelector } from "react-redux";

import NoData from "components/Utils/NoData";
import { selectFiltersTabOpen, selectOpenList } from "store/slices/mapSlice";

import NotificationRow from "./NotificationRow";

import "styles/modules/notificationFeed/notificationFeed.scss";

export interface IDeviceNotification {
    external_id: string;
    last_read_at: string;
    last_read_value: {
        park_occupied: boolean;
        park_type: string;
    }
    unread: boolean | undefined;
    seen: boolean | undefined;
}

const NotificationFeed = (props: {
    feed: IDeviceNotification[];
    onPointClick: (point: IMapPoint) => void;
    readNotifications: () => void;
    errorRequest: Event | null;
}) => {
    const { feed, onPointClick, readNotifications, errorRequest } = props;

    const theme: any = useTheme()
    const map = useMap();
    
    const [ open, setOpen ] = useState<boolean>(false)
    const [ rows, setRows ] = useState<any>([])
    const [ showAll, setShowAll ] = useState(false)
    const [ rowsLoading, setRowsLoading ] = useState(true)
    const isDrawerOpen = useSelector(selectOpenList);
    const isFilterTabOpen = useSelector(selectFiltersTabOpen);

    const hasUnreads = () => {
        let unreads = 0;

        feed.forEach((notification: IDeviceNotification) => {
            if(notification.unread)
                unreads++;
        })

        return unreads > 0
    }
    

    const handleClick = () => {
        setOpen(!open)
        setShowAll(false)
    }

    let count = 0;
    let readyComponents = 0;

    const handleReadyComponent = () => {
        readyComponents++;
        if(readyComponents === count) {
            setRowsLoading(false)
        }
    }


    useEffect(() => {
        if(feed && feed.length > 0) {
            let auxRows: any = [];
            // eslint-disable-next-line
            count = 0;
            if(feed.length > 5 && !showAll) {
                for(let i = feed.length-1; i !== feed.length-6; i--) { 
                    auxRows.push(<NotificationRow n={feed[i]} key={i} onPointClick={ onPointClick } onReady={ handleReadyComponent } />)
                    count++;
                }
                
            } else {
                if(feed.length > 20) {
                    for(let i = feed.length-1; i > feed.length-21; i--) {

                        if((feed.length-1)-i >= 5 && feed[i].seen)
                            feed[i].unread = false

                        auxRows.push(<NotificationRow n={feed[i]} key={i} onPointClick={ onPointClick } onReady={handleReadyComponent}/>)
                        count++;
                    }
                } else {
                    for(let i = feed.length-1; i >= 0; i--) {
                        
                        if((feed.length-1)-i >= 5 && feed[i].seen)
                            feed[i].unread = false

                        auxRows.push(<NotificationRow n={feed[i]} key={i} onPointClick={ onPointClick }  onReady={handleReadyComponent}/>)
                        count++;
                    }
                }
            }
            setRows(auxRows)
        }

        // eslint-disable-next-line
    }, [feed.length, showAll, open])

    useEffect(() => {
        if(open) {
            return(() => {
                readNotifications();
            })
        } else {
            setRowsLoading(true)
        }
    // eslint-disable-next-line
    }, [open])


    // Quando o rato está em cima desativa a função de zoom e volta a ativar quando sai
    const handleMouseOver = () => { 
        map.scrollWheelZoom.disable()
    }

    const handleMouseOut = () => {
        map.scrollWheelZoom.enable()
    }

    return(<>
        <Box onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className="notificationFeed" sx={{
            top: isDrawerOpen ? 10 :  9,
            right: isDrawerOpen ? 60 : isFilterTabOpen ? 10 : 190,
          }}>
            <Tooltip title={open ? "" : t("notifications.title")}>
                <IconButton  className="contrast iconbutton" onClick={handleClick}>
                    {hasUnreads() && !open && <Box className="badge" sx={{ backgroundColor: theme.palette.primary.main }} />}
                    <ListAltIcon/>
                </IconButton>
            </Tooltip>
            

            { open && 
                <Grid className="feedGrid">
                    <Card className="card" elevation={0}  >
                        <CardContent sx={{ paddingBottom: (showAll || feed.length <= 5  ? "" : "5px !important") }}>
                            <Typography mb={1} variant="h3" fontSize={"16px"}>
                                {t("notifications.title")}
                            </Typography>
                            <Divider />
                                <Box sx={{ display: rowsLoading ? "none" : "" }} className="dataWrapper">
                                    { rows }
                                </Box>
                                <Loading show={rowsLoading && !arrayIsEmpty(rows)} />
                                { arrayIsEmpty(rows) && <NoData error={errorRequest}/> }
                            <Divider />
                            { feed.length > 5 && !rowsLoading && !showAll && <Button className="seeAllButton" onClick={() => setShowAll(true)}>
                                {t("common.seeAll")}
                            </Button>}
                        </CardContent>
                    </Card> 
                </Grid>
                    }
        </Box>
        
    </>)
}

export default NotificationFeed;