import { AnyAction, combineReducers } from '@reduxjs/toolkit'

import { removeCookie } from 'utils/cookie'
import { AUTH_TOKEN } from 'utils/keys'

import adapterConfiguratorsReducer from './slices/adapterConfiguratorsSlice'
import appManagementReducer from './slices/appManagementSlice'
import authReducer from './slices/authSlice'
import comparativeAnalysisReducer from './slices/comparativeAnalysisSlice'
import dashboardReducer from './slices/dashboardSlice'
import eventsReducer from './slices/eventsSlice'
import mapReducer from './slices/mapSlice'
import notificationReducer from './slices/notificationsSlice'
import occurrencesReducer from './slices/occurrencesSlice'
import peopleMobilitySlice from './slices/peopleMobilitySlice'
import wazeReducer from './slices/wazeSlice'

const allReducers = {
  auth: authReducer,
  adapterConfigurators: adapterConfiguratorsReducer,
  map: mapReducer,
  dashboard: dashboardReducer,
  events: eventsReducer,
  occurrences: occurrencesReducer,
  waze: wazeReducer,
  comparativeAnalysisCharts: comparativeAnalysisReducer,
  peopleMobility: peopleMobilitySlice,
  app: appManagementReducer,
	notifications: notificationReducer
}

const appReducer = combineReducers(allReducers)

const rootReducer = (
  state: ReturnType<typeof appReducer | any>,
  action: AnyAction
) => {
  if (action.type === 'logout') {
    localStorage.clear()
		removeCookie(AUTH_TOKEN);
  }
  if (action.type === 'changeClient') {
    const newValuesState = {}
    Object.keys(state).forEach((element) => {
      Object.assign(newValuesState, {
        [element]: element === 'auth' ? state[element] : undefined,
      })
    })
    state = newValuesState
  }
  return appReducer(state, action)
}

export default rootReducer
