/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutContext } from "@alb/live-lib";
import AccountsAdd from "components/management/accounts/AccountsAdd";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AccountsList from "../../../components/management/accounts/AccountsList";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";
import { useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";

const Accounts = () => {
  const { t } = useTranslation();
  const { hasPermission } = usePermission();
  const canCreate = hasPermission(PERMISSIONS.MANAGEMENT.ACCOUNTS.CREATE);
  const user = useSelector(getUser);

  const header = {
    title: t("pages.accounts.headerText"),
    action: canCreate && (
      <Button
        variant="contained"
        disableElevation
        startIcon={<AddIcon />}
        onClick={() => addAction(true)}
      >
        {t("accounts.addAccount")}
      </Button>
    ),
  };
  const { addHeader, addAction, action } = useContext(LayoutContext);
  const [added, setAdded] = useState<boolean>(false);

  useEffect(() => {
    addHeader(header);
  }, []);

  let accountAdd;
  if (action && canCreate) {
    accountAdd = (
      <AccountsAdd
        open={action}
        onClose={() => addAction(false)}
        onSuccess={() => {
          addAction(false);
          setAdded(true);
        }}
        clientID={user.client.id}
      />
    );
  }

  return (
    <>
      <AccountsList refetch={added} />
      {canCreate && accountAdd}
    </>
  );
};

export default Accounts;
