import { mixed, object, string, lazy, ObjectSchema, AnyObject } from "yup";

export const InputsLengths = {
  max: {
    name: 255,
    endpoint_type: 20,
  },
  min: {
    name: 3,
  },
};

export const getModuleSchema = (): ObjectSchema<AnyObject> => {
  return object({
    name: lazy((value: any) => {
      switch (typeof value) {
        case "object":
          return object().required();
        case "string":
          return string()
            .min(InputsLengths.min.name)
            .max(InputsLengths.max.name)
            .required();
        default:
          return string(); // Add a default case to handle any other types if needed.
      }
    }),
    adapter_configurators: mixed(),
    extra_params: object()
      .json()
      .transform((value: any, originalValue: any, context: any) => {
        // verifica se o valor é um objeto válido
        if (context.isType(value)) return value;

        // para o caso de estar vazio
        if (value?.length === 0) return {};

        // tem um valor e não é um objeto
        return value;
      }),
    endpoint_type: string().max(InputsLengths.max.endpoint_type),
  });
};

export const setModuleSchema = (required: string) =>
  object({
    adapter_configurators: mixed(),
    // .test("required", required, (value) => Array.isArray(value) && value.length > 0),
  });
