import { getIcons } from "components/map/list/listIcons";
import { AppLogo } from "@alb/live-lib";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "store/slices/authSlice";
import { INotificationAPI, INotificationContentDevice, INotificationContentModule } from "types/interfaces";
import { useTranslation } from "react-i18next";
import { formatDate } from "utils/date";
import { fetchReadNotification } from "store/slices/notificationsSlice";
import { INotification } from "@alb/live-lib/templates/sidebar/components/SidebarNotificationItem";

export const useNotificationsUtils = () => {
	const { t } = useTranslation();
	const userStorage = useSelector(getUser);
	const dispatch = useDispatch();

	function getNotificationInfo(notification: INotificationAPI) {
		let title: string= '';
		let icon: React.ReactElement = getIcons('default');
		let link: string= '';

		let content: INotificationContentDevice | INotificationContentModule | null = null
		switch (notification.type) {
			case "alarm":
				content = notification.content as INotificationContentDevice;
				title = t("notifications.devices.device", { device: content.device_name, adapter: content.adapter_name, status: content.status === "failure" ? t("notifications.stoppedWork") : t("notifications.worksAgain") });
				icon = getIcon(notification.sub_type, content.domain, content.status !== 'failure');
				link = getDeviceLink(notification);
				break;
			case "module":
				content = notification.content as INotificationContentModule;
				title = t(`notifications.module.${notification.sub_type}`, { name: content.subject});
				icon = getIcon(notification.sub_type);
				link = getModuleLink(notification);
				break;
		}


		return {title, icon, link}
	}


	function getIcon(type: string,		subType?: string,		status?: boolean): React.ReactElement {
		const icon = getIcons(type, subType, status);

		const iconElem = <AppLogo
			sx={{ width: 46, height: "auto" }}
			variant="circular"
			src={icon}
		/>

		return iconElem;
	}

	function getDeviceLink(notification: INotificationAPI): string {
		const simCli = notification.client?.findIndex((cli) => cli.id === userStorage.client.id) !== -1;
		if (!simCli) return '';

		return `/dashboard/${(notification.content as INotificationContentDevice).device_id}`;
	}
	function getModuleLink(notification: INotificationAPI): string {
		const simCli = notification.client?.findIndex((cli) => cli.id === userStorage.client.id) !== -1;
		if (!simCli) return '';

		return `/${notification.sub_type}/${(notification.content as INotificationContentModule).id}`;
	}

	function readNotification(id: string, read: boolean, reload?: boolean) {
		dispatch(fetchReadNotification({ id: id, read: read, reload: reload }));
	}
	const onActionClickHandler = (notification: INotification) => {
		readNotification(notification.id, !notification.read, true);
	}
	const onNotificationClickHandler = (notification: INotificationAPI) => {
		if (!notification.read) {
			readNotification(notification.id, true, true);
		}
	}

	function convertNotification(notification: INotificationAPI) {

		const {title, icon, link} = getNotificationInfo(notification);

		const newNotification = {
			id: notification.id,
			title: title,
			subTitle: formatDate(notification.created_at, t("calendar.dateFullFormatOf")),
			read: notification.read,
			icon: icon,
			linkTo: link,
			onItemClick: () => onNotificationClickHandler(notification),
			onActionClick: onActionClickHandler,
		}

		return newNotification
	}

	function addNotificationItem(array: INotification[], item: INotification) {
		const len = 5;
		array.unshift(item);
		if (array.length > len) {
			array.pop();
		}
	}

	return {getNotificationInfo, convertNotification, addNotificationItem}
}
