import _ from "lodash";

import { wazeTypes } from "../waze/wazeTypes";

export const getIcons = (
  type: string,
  subType?: string,
  status?: boolean,
  park_type?: string | { value: string; unit: string } | any,
  park_occupied?: boolean | { value: string; unit: string } | any,
  icon?: string
) => {
  if (type === "default") {
    return require(`assets/images/map/markers/customMarker.svg`);
  }

  if (type === "event") {
    const onOff = "enabled";
    const simplifiedName =
      icon && _.deburr(icon?.toLowerCase().replaceAll(" ", ""));
    switch (simplifiedName) {
      case "parqueinfantil":
        return require(`assets/images/map/markers/${onOff}/occurrences/parqueinfantil.svg`);
      case "livrospedidos":
        return require(`assets/images/map/markers/${onOff}/occurrences/livrospedidos.svg`);
      case "materiaisnaviapublica":
        return require(`assets/images/map/markers/${onOff}/occurrences/materiaisviapublica.svg`);
      case "instalacaodesportiva":
        return require(`assets/images/map/markers/${onOff}/occurrences/pavimentos.svg`);
      default:
        return require("assets/images/map/markers/enabled/event.svg").default;
    }
  }

  if (type === "occurrence" || type === 'occurrences') {
    const onOff = "enabled"; //NOTA: Para já não vai ser considerado o estado das ocorrências e será para mostrar sempre como enabled

    const simplifiedName =
      icon && _.deburr(icon?.toLowerCase().replaceAll(" ", ""));
    switch (simplifiedName) {
      case "evc":
        return require(`assets/images/map/markers/${onOff}/occurrences/evc.svg`);
      case "cer":
        return require(`assets/images/map/markers/${onOff}/occurrences/cer.svg`);
      case "h2":
        return require(`assets/images/map/markers/${onOff}/occurrences/h2.svg`);
      case "parqueinfantil":
        return require(`assets/images/map/markers/${onOff}/occurrences/parqueinfantil.svg`);
      case "rededeagua":
        return require(`assets/images/map/markers/${onOff}/occurrences/redeagua.svg`);
      case "predionaoconforme":
        return require(`assets/images/map/markers/${onOff}/occurrences/predionaoconforme.svg`);
      case "contadoresdeagua":
        return require(`assets/images/map/markers/${onOff}/occurrences/contadoresagua.svg`);
      case "rededeesgotos":
        return require(`assets/images/map/markers/${onOff}/occurrences/redeesgotos.svg`);
      case "limpezaurbana":
        return require(`assets/images/map/markers/${onOff}/occurrences/limpezaurbana.svg`);
      case "ambiente":
        return require(`assets/images/map/markers/${onOff}/occurrences/ambiente.svg`);
      case "recolhadelixo":
        return require(`assets/images/map/markers/${onOff}/occurrences/recolhalixo.svg`);
      case "transito":
        return require(`assets/images/map/markers/${onOff}/occurrences/transito.svg`);
      case "livrospedidos":
        return require(`assets/images/map/markers/${onOff}/occurrences/livrospedidos.svg`);
      case "materiaisnaviapublica":
        return require(`assets/images/map/markers/${onOff}/occurrences/materiaisviapublica.svg`);
      case "outros":
        return require(`assets/images/map/markers/${onOff}/occurrences/outros.svg`);
      case "muros":
        return require(`assets/images/map/markers/${onOff}/occurrences/muros.svg`);
      case "jardinseespacosverdesurbanos":
        return require(`assets/images/map/markers/${onOff}/occurrences/espacosverdes.svg`);
      case "fiscalizacao":
        return require(`assets/images/map/markers/${onOff}/occurrences/fiscalizacao.svg`);
      case "instalacaodesportiva":
        return require(`assets/images/map/markers/${onOff}/occurrences/instalacaodesportiva.svg`);
      case "pavimentos":
        return require(`assets/images/map/markers/${onOff}/occurrences/pavimentos.svg`);
      default:
        return require(`assets/images/map/markers/${onOff}/occurrence.svg`);
    }
  }

  if (type === "device") {
    const onOff = status ? "enabled" : "disabled";

    if (park_type && park_occupied) {
      const parkType: string = park_type?.value || park_type;
      const parkOccupied: boolean = park_occupied?.value || park_occupied;
      const park_occupied_on_off =
        parkOccupied === true ? "disabled" : "enabled";

      switch (parkType?.toLowerCase()) {
        case "p":
          return require(`assets/images/map/markers/${park_occupied_on_off}/smartparking/meter.svg`);
        case "e":
          return require(`assets/images/map/markers/${park_occupied_on_off}/smartparking/eletric.svg`);
        case "c":
          return require(`assets/images/map/markers/${park_occupied_on_off}/smartparking/load.svg`);
        default:
      }
    } else {
      switch (subType?.toLowerCase()) {
        case "ptdenergy":
          return require(`assets/images/map/markers/${onOff}/edp/ptdenergy.svg`);
        case "ipenergy":
          return require(`assets/images/map/markers/${onOff}/edp/ipenergy.svg`);
        case "consumptionenergy":
          return require(`assets/images/map/markers/${onOff}/edp/consumptionenergy.svg`);
        case "air":
          return require(`assets/images/map/markers/${onOff}/monitar/air.svg`);
        case "meteo":
          return require(`assets/images/map/markers/${onOff}/monitar/meteo.svg`);
        case "noise":
          return require(`assets/images/map/markers/${onOff}/monitar/noise.svg`);
        case "mobility":
          return require(`assets/images/map/markers/${onOff}/smartparking/mobility.svg`);
        case "traffic":
          return require(`assets/images/map/markers/${onOff}/citycatalyst/traffic.svg`);
        case "charge":
          return require(`assets/images/map/markers/${onOff}/tulaLabs/charge.svg`);
        case "inversor_fotovoltaico":
          return require(`assets/images/map/markers/${onOff}/cer/inversor_fotovoltaico.svg`);
        case "storage":
          return require(`assets/images/map/markers/${onOff}/cer/storage.svg`);
        case "mobilitybikes":
          return require(`assets/images/map/markers/${onOff}/soltrafego/mobilitybikes.svg`);
        case "mobilitystations":
          return require(`assets/images/map/markers/${onOff}/soltrafego/mobilitystations.svg`);

        case "environment":
          return require(`assets/images/map/markers/${onOff}/qartenvironment/environment.svg`);
        default:
      }
    }

    return require(`assets/images/map/markers/${onOff}/default.svg`);
  }

  if(wazeTypes.includes(type)) {

    if(subType) {
      const lowerCaseType = subType.toLowerCase()
      switch(lowerCaseType) {
        case 'hazard_on_shoulder': return require('assets/images/map/markers/enabled/waze/hazard_on_shoulder.svg').default
        case "hazard_on_road_object": return require('assets/images/map/markers/enabled/waze/hazard_on_road_object.svg').default
        case "hazard_on_road_traffic_light_fault": return require('assets/images/map/markers/enabled/waze/hazard_on_road_traffic_light_fault.svg').default
        case "hazard_weather": return require('assets/images/map/markers/enabled/waze/hazard_weather.svg').default
        case "accident_major": return require('assets/images/map/markers/enabled/waze/accident_major.svg').default
        case "hazard_weather_hail": return require('assets/images/map/markers/enabled/waze/hazard_weather_hail.svg').default
        case "jam_moderate_traffic": return require('assets/images/map/markers/enabled/waze/jam_moderate_traffic.svg').default
        case "accident_minor": return require('assets/images/map/markers/enabled/waze/accident_minor.svg').default
        case "jam_stand_still_traffic": return require('assets/images/map/markers/enabled/waze/jam_stand_still_traffic.svg').default
        case "hazard_weather_fog": return require('assets/images/map/markers/enabled/waze/hazard_weather_fog.svg').default
        case "jam_heavy_traffic": return require('assets/images/map/markers/enabled/waze/jam_heavy_traffic.svg').default
        case "road_closed_construction": return require('assets/images/map/markers/enabled/waze/road_closed_construction.svg').default
        case "hazard_on_road_pot_hole": return require('assets/images/map/markers/enabled/waze/hazard_on_road_pot_hole.svg').default
        case "road_closed_event": return require('assets/images/map/markers/enabled/waze/road_closed_event.svg').default
        case "hazard_on_road_construction": return require('assets/images/map/markers/enabled/waze/hazard_on_road_construction.svg').default
        case "hazard_on_road": return require('assets/images/map/markers/enabled/waze/hazard_on_road.svg').default
        case "hazard_on_road_car_stopped": return require('assets/images/map/markers/enabled/waze/hazard_on_road_car_stopped.svg').default
        case "hazard_on_shoulder_car_stopped": return require('assets/images/map/markers/enabled/waze/hazard_on_shoulder_car_stopped.svg').default
      }
    } else {
      const lowerCaseType = type.toLowerCase()
      switch(lowerCaseType) {
        case 'accident': return require('assets/images/map/markers/enabled/waze/accident.svg').default
        case 'hazard': return require('assets/images/map/markers/enabled/waze/hazard.svg').default
        case 'road_closed': return require('assets/images/map/markers/enabled/waze/roadclosed.svg').default
        case 'jams':
        case 'jam': return require('assets/images/map/markers/enabled/waze/jams.svg').default
      }
    }

    
  }
  return require(`assets/images/map/markers/enabled/default.svg`).default;
};
