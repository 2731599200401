/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutContext } from "@alb/live-lib";
import { Grid, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getRoutePath } from "utils/routePath";
import usePermission from "hooks/usePermission";
import { PERMISSIONS } from "utils/permissions/permissions";
import { ModuleGridItem } from "components/Utils/ModuleGridItem";

const Modules = () => {
	const { addHeader } = useContext(LayoutContext);
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const params = useParams();

	const { hasPermission } = usePermission()
	const canReadEvents = hasPermission(PERMISSIONS.MODULES.EVENTS.READ)
	const canReadOccurrences = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.READ)

	const header = {
		title: t("sidebar.modules"),
	};

	useEffect(() => {
		addHeader(header);
	}, []);

	function navigateTo(page: string) {
		const path = getRoutePath(location, params);
		navigate(`${path}/${page}`);
	}

	return (
		<>
			<Grid container mt={5} mb={1} spacing={2}>
				{canReadEvents || canReadOccurrences ?
					<>
						{canReadEvents && <ModuleGridItem title={t("events.events")} onClick={() => navigateTo("events")} />}
						{canReadOccurrences && <ModuleGridItem title={t("occurrences.occurrences")} onClick={() => navigateTo("occurrences")} />}
					</>
					:
					<> <Typography fontWeight={400} fontSize={16}>
						{t('modules.noModulesManage')}
					</Typography></>}

			</Grid>
		</>
	);
};

export default Modules;
