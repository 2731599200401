/* eslint-disable react-hooks/exhaustive-deps */
import { arrayIsEmpty, IActionControl, IMap, IMapPoint, IRange, Map as MainMap, TileType } from "@alb/live-lib";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, useTheme } from "@mui/material";
import { LatLngExpression } from "leaflet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TWazeJamMarker } from "types/types";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { getTextSearchBar } from "store/slices/dashboardSlice";
import { arcgisKey } from "utils/keys";

import wazeJamIcon from "./wazeJamIcon";

interface IWazeMapProps {
    filterRange: IRange,
    selectedWazeAdapter: string
}

const WazeMap = (props: IWazeMapProps) => {

  const { filterRange, selectedWazeAdapter } = props

  const { t } = useTranslation()
  const idMap = 'WazeMap'
  const user = useSelector(getUser);
  const textSearch = useSelector(getTextSearchBar);
  let mapCenter: LatLngExpression | undefined;
  const mapTile = user.extra_params?.map_tile_layer || TileType.default;
  const theme = useTheme()
  const isDarkMode = theme.palette.mode === 'dark'
  if (user && !arrayIsEmpty(user.client.center_point))
    mapCenter = {
      lat: user.client.center_point[0],
      lng: user.client.center_point[1],
    };
  const [centerMap, setcenterMap] = useState<LatLngExpression | undefined>(
    mapCenter
  );
  const initialZoom = 15;
  const darkMode = useSelector(getUser)?.extra_params?.dark_mode;
  const [ bounds, setBounds ] = useState< number[][]>([])
  const [fitBounds, setFitBounds] = useState(true)
  const [ markers, setMarkers ] = useState<IMapPoint[]>([])
  const [ currentZoom, setCurrentZoom ] = useState<number>(initialZoom)

  const getParams = () => {
		var params: {
			points: string;
		} = {
			points: JSON.stringify(bounds),
			...(filterRange && {
				from: filterRange.startDate,
				to: filterRange.endDate,
			}),
			//...(filterType && { type: filterType }),
		};
		return params;
	};

  const {
		refetch: refetchWazeMarkers,
    error
	} = useGet<IMapPoint[]>(
		ServiceApiUrl.wazeMarkersByAdapter(selectedWazeAdapter),
		getParams(),
		{ manual: true }
	);


  useEffect(() => {
    if(bounds.length) {
      
      refetchWazeMarkers().then((res) => {
        if(selectedWazeAdapter === 'alerts') {
          setMarkers(res.data)
          return;
        }

        const markers = res.data.map((item) => {
          const marker = item as TWazeJamMarker
          return {
            ...marker,
            markerDivIcon: wazeJamIcon(marker,isDarkMode)
          }
        })
        setMarkers(markers)
      })
    }
  }, [bounds, filterRange, selectedWazeAdapter])


  useEffect(() => {
    setMarkers([])
  }, [selectedWazeAdapter, filterRange])

  const center: LatLngExpression | any = centerMap;
  const openWazeMap = () => {
    center &&
      window.open(
        `https://www.waze.com/${user.language}/live-map/directions?latlng=${center.lat}%2C${center?.lng}`,
        "_blank"
      );
  };
  const actionsCtrl: IActionControl[] = [
    {
      action: openWazeMap,
      icon: <OpenInNewIcon />,
      title: t("waze.openMap"),
    },
  ];

  const handleCenter = (coord: LatLngExpression) => {
    setcenterMap(coord);
  };

  const handleBoundsChange = (bounds: number[][]) => {
    setBounds(bounds);
  };

  const handleChangeFitBounds = (fitBoundsValue: boolean) => {
    fitBoundsValue !== fitBounds && setFitBounds(fitBoundsValue);
  };

  const handleZoomChange = (zoom: number) => {
    setCurrentZoom(zoom)
  }


  let args: IMap = {
    arcgisKey: arcgisKey,
    idMap: idMap,
    language: user?.language.toLowerCase() || "pt",
    mapTranslations: {
      buttons: {
        layers: t("map.buttons.layers"),
        recenter: t("map.buttons.recenter"),
      },
      date: {
        dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
      },
      popups: {
        lastRead: t("map.marker.lastReading"),
          jamsLevel: {
          1: t("waze.jamsLevel." + 1),
          2: t("waze.jamsLevel." + 2),
          3: t("waze.jamsLevel." + 3),
          4: t("waze.jamsLevel." + 4),
          5: t("waze.jamsLevel." + 5)
        },
        traffic: t("waze.traffic")
      },
      markers: {
        active: t("common.active"),
        inactive: t("common.inactive"),
      },
      common: {
        noData: t("common.noData"),
      },
    },
    mapVariant: "component",
    searchBarText: textSearch,
    actionsControls: actionsCtrl,
    mapCenterPoint: centerMap,
    initialMapCenter: mapCenter
      ? (Object.values(mapCenter) as LatLngExpression)
      : undefined,
    mapZoom: initialZoom,
    initialZoom: initialZoom,
    mapTileType: mapTile,
    points: markers,
    mapModule: true,
    showZoom: true, 
    onCenter: handleCenter,
    appDarkThemeMode: darkMode,
    onBounds: handleBoundsChange,
    onZoom: handleZoomChange,
    //onPointClick: handlePointClick,
    //sidebarIsOpen: sidebarIsOpen,
    //onChangeTileType: handleChangeTileType,
    mapFitBounds: fitBounds,
    onChangeFitBounds: handleChangeFitBounds,
    noClusters: currentZoom > 13,
    dynamicAttribution: true,
    
  };
  return <>
    {mapCenter && args && !error && <MainMap {...args} />}
    {error && <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}><NoData error={error} /></Box>}
    </>
}

export default WazeMap;