import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Collapse, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { getNameClient } from "store/slices/authSlice";
import { getFilterDateRange, getSelectedParishArea, setSelectedParishArea } from "store/slices/mapSlice";
import { ExpandButtonStyle } from "styles/css/components";
import styles from "styles/modules/map/list.module.scss";
import { formatDate } from "utils/date";

import { CardDailyVisits } from "./CardDailyVisits";
import { CardPeriod } from "./CardPeriod";
import { CardPeriodLastYear } from "./CardPeriodLastYear";
import { CardPresence } from "./CardPresence";
import { CardsInfo } from "./CardsInfo";

interface IMapDetailConatiner {
  hideInfoBtn?: boolean;
}

export const MapDetailContainer = ({ hideInfoBtn }: IMapDetailConatiner) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();

  const nameClient = useSelector(getNameClient);
  const nameParish = useSelector(getSelectedParishArea).name;
  const city = nameClient === "AveiroDemo" ? "Aveiro" : nameClient.slice(2);
  const dateRange = useSelector(getFilterDateRange);
  const dateFormat = "d MMM yyyy";

  function onCloseDetail() {
    dispatch(setSelectedParishArea({ name: "", id: "" }));
  }

  const [collapsed, setCollapsed] = useState<boolean>(true);
  function onHideDetail() {
    setCollapsed(!collapsed);
  }

  useEffect(() => {
    if (!hideInfoBtn && !collapsed) {
      setCollapsed(!collapsed);
    }
  }, [collapsed, hideInfoBtn]);

  return (
    <>
      <Grid container item className={styles["list-detail__title"]}>
        <Grid item xs={nameParish || (hideInfoBtn && !nameParish) ? 11 : 12}>
          <Typography variant="h3">
            {nameParish || t("map.detail.title", { client: city })}
          </Typography>
        </Grid>
        {nameParish && (
          <Grid item xs={1}>
            <IconButton
              size="small"
              onClick={onCloseDetail}
              sx={{
                padding: 0,
                "&.MuiIconButton-root:hover": { background: "none" },
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        )}
        {hideInfoBtn && !nameParish && (
          <Grid item xs={1}>
            <ExpandButtonStyle onClick={onHideDetail}>
              {collapsed ? (
                <ExpandLess fontSize="inherit" />
              ) : (
                <ExpandMore fontSize="inherit" />
              )}
            </ExpandButtonStyle>
          </Grid>
        )}
      </Grid>
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
        }}
      >
        <Collapse in={collapsed} unmountOnExit>
          {/* because of scroll and collapse, this grid need to be sticky */}
          <Grid 
           sx={{backgroundColor: theme.palette.mode === 'light' ? theme.palette.background.paper : theme.palette.background.default}}
           container item className={styles["list-detail__subTitle"]}>
            <Typography sx={{ fontSize: "18px" }}>
              {t("map.detail.subTitle", {
                iDate: formatDate(dateRange.startDate, dateFormat),
                fDate: formatDate(dateRange.endDate, dateFormat),
              })}
            </Typography>
          </Grid>

          {/* content */}
          <Grid container item className={styles["list-detail"]}>
            <Grid container item pb={3}>
              <CardsInfo />
            </Grid>
            <Grid container item pb={3}>
              <CardPresence />
            </Grid>
            <Grid container item pb={3}>
              <CardDailyVisits />
            </Grid>
            <Grid container item pb={3}>
              <CardPeriod />
            </Grid>
            <Grid container item pb={3}>
              <CardPeriodLastYear />
            </Grid>
          </Grid>
        </Collapse>
      </Box>
    </>
  );
};
