import { LayoutContext } from '@alb/live-lib';
import QArtContainer from 'components/air-quality/QArtContainer';
import usePermission from 'hooks/usePermission';
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { PERMISSIONS } from 'utils/permissions/permissions';

function AirQuality() {
	const { t } = useTranslation();
	const { hasPermission } = usePermission();
	const canREAD = hasPermission(PERMISSIONS.MODULES.AIRQUALITY.READ);
	const { addHeader } = useContext(LayoutContext);
	const header = {
		title: t("sidebar.qart"),
	};

	useEffect(() => {
		addHeader(header);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		canREAD && (
			<QArtContainer />
		)
	);
}

export default AirQuality
