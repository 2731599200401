import { Grid } from '@mui/material'

interface ILiveMain {
  children: React.ReactNode
  backgroundImage?: boolean
}

const LiveMain = ({ children }: ILiveMain) => {
  return (
    <Grid container alignItems="stretch">
      <Grid item xs={true}>
        {children}
      </Grid>
    </Grid>
  )
}

export default LiveMain
