export const I18NEXT = 'i18nextLng'
export const AUTH_TOKEN= 'auth_token'
export const AUTH = 'auth'
export const THEME = 'theme'
export const ADAPTER_CONFIGURATORS = 'adapterConfigurators'
export const COMPARATIVE_ANALYSIS_CHARTS = 'comparativeAnalysisCharts'
export const DEVICES = 'devices'
export const MARKERS = 'markers'
export const EVENTS = 'events'
export const NOTIFICATIONS = 'notifications'

export const arcgisKey = process.env.REACT_APP_ARCGIS_TOKEN || '';
