import { InputText, RequiredField } from "@alb/live-lib";
import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { RequiredFields } from "components/Utils/RequiredField";
import React, { useEffect } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { formDefaultValues } from "./formDefaultValues";
import { getUrgencyFormSchema } from "./validations";

export interface IOccurrenceUrgencyForm {
  id: string;
  name: string;
}

export interface IUrgenciesForm {
  formId: string;
  data?: IOccurrenceUrgencyForm;
  editForm?: boolean;
  onFormSubmit: (payLoad: IOccurrenceUrgencyForm) => void;
  setFormIsValidStatus?: (status: boolean) => void;
}

const UrgenciesForm = ({
  formId,
  data,
  editForm,
  onFormSubmit,
  setFormIsValidStatus,
}: IUrgenciesForm) => {
  const { t } = useTranslation();

  const defaultValues = formDefaultValues(data);

  const methodsForm = useForm<IOccurrenceUrgencyForm>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
    resolver: yupResolver(getUrgencyFormSchema()),
  });

  const { control, handleSubmit } = methodsForm;

  //ao submeter o formulário
  const formSubmitHandler: SubmitHandler<IOccurrenceUrgencyForm> = (
    payLoad: IOccurrenceUrgencyForm
  ) => {
    onFormSubmit(payLoad);
  };

  useEffect(() => {
    if (setFormIsValidStatus)
      setFormIsValidStatus(methodsForm.formState.isValid);
  }, [setFormIsValidStatus, methodsForm.formState.isValid]);

  return (
    <>
      <FormProvider {...methodsForm}>
        <form id={formId} onSubmit={handleSubmit(formSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <RequiredField title={t("common.name")} />
              <InputText
                control={control}
                name="name"
                placeholder={t("occurrences.urgencyNamePlaceholder")}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
      <RequiredFields allRequired />
    </>
  );
};
export default UrgenciesForm;
