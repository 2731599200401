import { Loading } from "@alb/live-lib";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IDialogProps } from "types/interfaces";
import { TOccurrence } from "types/types";

import { getIcons } from "components/map/list/listIcons";
import OccurrenceEdit from "components/occurrences/OccurrenceEdit";
import NoData from "components/Utils/NoData";
import usePermission from "hooks/usePermission";
import i18n from "i18n/config";
import { getUser } from "store/slices/authSlice";
import { getMarkerDetails, selectDetailsError, selectDetailsLoading, selectMarkerDetails, TypeMarkerInfo } from "store/slices/mapSlice";
import { formatDate } from "utils/date";
import { PERMISSIONS } from "utils/permissions/permissions";
import { formattedLocation } from "utils/utils";

import MapEntiyInfoItem from "./MapEntiyInfoItem";
import MarkerDialogTitle from "./MarkerDialogTitle";

const OccurrenceInfo = ({ open, onClose, onSuccess }: IDialogProps) => {
  const { t } = useTranslation();

  const data = useSelector(selectMarkerDetails);
  const loading = useSelector(selectDetailsLoading);
  const error = useSelector(selectDetailsError);
  const user = useSelector(getUser);

  const { hasPermission } = usePermission();
  const canEdit = hasPermission(PERMISSIONS.MODULES.OCCURRENCES.UPDATE);

  const dispatch = useDispatch();

  const [openEdit, setOpenEdit] = useState(false);

  const onShowEdit = () => {
    setOpenEdit(true);
  };

  const handleClose = () => {
    setOpenEdit(false);
  };

  const handleSucess = (occurrence: TOccurrence) => {
    handleClose();
    onSuccess && onSuccess();
    //update
    dispatch(
      getMarkerDetails(occurrence.id, "occurrence" as TypeMarkerInfo, null, user)
    );
  };

  const icon = getIcons(
    "occurrences",
    data?.subType,
    data?.is_active,
    data?.last_read_value?.park_type,
    data?.last_read_value?.park_occupied,
    data?.icon
  );

  return (
    <>
      <Dialog maxWidth="sm" fullWidth open={open}>
        {data && !loading && !error.isError ? (
          <>
            <MarkerDialogTitle
              icon={icon}
              altIcon={
                data?.subType
                  ? i18n.exists(`domains.${data?.subType}`)
                    ? t(`domains.${data?.subType}`)
                    : data?.subType
                  : data?.type
              }
              title={
                data?.name || data?.category?.name || t("occurrences.detail")
              }
              status={data.status ? data.status.name : ""}
              onClose={onClose}
            />

            <DialogContent
              sx={{
                ".MuiPaper-root &.MuiDialogContent-root": {
                  padding: "0 0",
                },
              }}
            >
              <Grid container direction="row">
                <MapEntiyInfoItem
                  label={t("occurrences.type")}
                  value={data.type}
                />
                <MapEntiyInfoItem
                  icon={"LocationOnRounded"}
                  label={t("occurrences.location")}
                  value={formattedLocation(data.geolocation.iconPosition)}
                />
                <MapEntiyInfoItem
                  label={t("occurrences.address")}
                  value={data.address}
                />
                <MapEntiyInfoItem
                  label={t("occurrences.category")}
                  value={data.category?.name}
                />
                <MapEntiyInfoItem
                icon={"CalendarToday"}
                  label={t("occurrences.startDate")}
                  value={
                    data.start_date
                      ? formatDate(
                          data.start_date,
                          t("calendar.dateTimeFullFormat")
                        )
                      : "n/a"
                  }
                />
                <MapEntiyInfoItem
                 icon={"CalendarToday"}
                  label={t("occurrences.endDate")}
                  value={
                    data.end_date
                      ? formatDate(
                          data.end_date,
                          t("calendar.dateTimeFullFormat")
                        )
                      : "n/a"
                  }
                />
                <MapEntiyInfoItem
                  label={t("occurrences.occurrenceReporter")}
                  value={data.reporter}
                />
                <MapEntiyInfoItem
                  label={t("occurrences.description")}
                  value={data.description}
                />
                {/* <MapEntiyInfoItem label={t("occurrences.subject")} value={data.subject} /> */}

                <MapEntiyInfoItem
                  label={t("common.createdAt")}
                  value={
                    data.created_at
                      ? formatDate(
                          data.created_at,
                          t("calendar.dateTimeFullFormat")
                        )
                      : "n/a"
                  }
                />
                <MapEntiyInfoItem
                  label={t("common.updatedAt")}
                  value={
                    data.updated_at
                      ? formatDate(
                          data.updated_at,
                          t("calendar.dateTimeFullFormat")
                        )
                      : "n/a"
                  }
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={onClose}>
                {t("close")}
              </Button>
              {canEdit && (
                <Button variant="contained" color="primary" onClick={onShowEdit}>
                  {t("common.edit")}
                </Button>
              )}
            </DialogActions>
          </>
        ) : (
          <>
            <Loading show={loading} />
            {error.isError && !loading && 
              <Box marginTop={'10px'} marginBottom={'20px'}>
                <Box width={'100%'} display={'flex'} justifyContent={'end'}>
                  <IconButton onClick={onClose}><CloseIcon /></IconButton>
                </Box>
                <NoData error={error.description} />
              </Box>
            }
          </>
        )}
      </Dialog>

      {openEdit && canEdit && (
        <OccurrenceEdit
          idOccurrence={data.id as string}
          open={openEdit}
          onClose={handleClose}
          onSuccess={handleSucess}
        />
      )}
    </>
  );
};

export default OccurrenceInfo;
