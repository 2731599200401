import { BackToButton, LayoutContext, TableHeadOrder } from '@alb/live-lib';
import AddIcon from "@mui/icons-material/Add";
import { Button, Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import Loading from 'components/Utils/Loading';
import NoData from 'components/Utils/NoData';
import useGet from 'hooks/fetchData/useGet';
import { ServiceApiUrl } from 'services/ServiceApiUrl';
import { arrayIsEmpty } from 'utils/utils';
import { getListParams } from 'utils/listParams';

import UrgenciesAdd from './UrgenciesAdd';
import UrgenciesTable from './UrgenciesTable';
import { FiltersSection } from './FiltersSection';
import { useGetModuleHeaders } from 'hooks/useGetModuleHeaders';
import { MODULES_KEYS } from 'utils/modules/modulesKeys';

const UrgenciesList = () => {
	const { t } = useTranslation();
	const { addHeader, addAction, action } = useContext(LayoutContext);
	const navigate = useNavigate();

	const [page, setPage] = useState(1);
	const [order, setOrder] = useState<TableHeadOrder>("asc");
	const [orderBy, setOrderBy] = useState<string>("name");
	const [filterSearch, setFilterSearch] = useState<string>("");

	const header = {
		title: t("occurrences.urgencies"),
		action: (
			<Button
				variant="contained"
				disableElevation
				startIcon={<AddIcon />}
				onClick={() => addAction(true)}
			>
				{t("occurrences.addUrgency")}
			</Button>
		),
		backTo: <BackToButton title={t("goBack")} onClick={() => navigate(-1)} />,
	};

	useEffect(() => {
		addHeader(header);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const { headers: headersEndpoint } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

	const {
		data: urgencies,
		loading,
		error,
		refetch,
	} = useGet(
		ServiceApiUrl.occurrencesUrgency,
		getListParams(page, order, orderBy, filterSearch),
		undefined,
		headersEndpoint
	);

	let urgenciesList = <NoData error={error} />;

	if (loading) {
		urgenciesList = <Loading />;
	}

	if (urgencies && !arrayIsEmpty(urgencies.data)) {
		urgenciesList = (
			<UrgenciesTable
				data={urgencies}
				orderBy={orderBy}
				order={order}
				setOrder={setOrder}
				setOrderBy={setOrderBy}
				page={page}
				setPage={setPage}
				refetch={refetch}
			/>
		);
	}

	let urgencyAdd;
	if (action) {
		urgencyAdd = (
			<UrgenciesAdd
				open={action}
				onClose={() => addAction(false)}
				onSuccess={() => {
					addAction(false);
					refetch();
				}}
			/>
		);
	}

	return (
		<>
			<Grid item mt={4}>
				<FiltersSection
					setPage={setPage}
					setFilterSearch={setFilterSearch}
				/>
				{urgenciesList}
			</Grid>
			{urgencyAdd}
		</>
	)
}

export default UrgenciesList
