import { useEffect, useState } from "react";
import { IApiResponse, IEventTypes, ISelectOption } from "types/interfaces";

import useGet from "hooks/fetchData/useGet";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { MODULES_KEYS } from "utils/modules/modulesKeys";

export const useTypeOptions = () => {
  const { headers } = useGetModuleHeaders(MODULES_KEYS.EVENTS);

  const [typeOptions, setTypeOptions] = useState<ISelectOption[]>();

  const {
    data: allTypes,
    loading,
  } = useGet<IApiResponse<IEventTypes>>(ServiceApiUrl.eventsTypes, null, undefined, headers);

  useEffect(() => {
    if (allTypes) {
      const typeOptionsSelect = allTypes?.data?.map((p) => ({
        label: p.name,
        value: p.id,
      }));
      setTypeOptions(typeOptionsSelect);
    }
  }, [allTypes]);

  return { typeOptions, loading };
};
