import { Box, CardContent, CardHeader, Typography, styled } from "@mui/material";

export const QArtCardHeaderStyled = styled(CardHeader)(({ theme }) => {
  return {
    "& .MuiCardHeader-title": {
      fontWeight: 700,
    },
  };
});
export const QArtCardContentStyled = styled(CardContent)(({ theme }) => {
  return {
    paddingTop: 0
  };
});

export const QArtBoxContainerStyled = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: '100%'
  };
});

export const QArtBoxStyled = styled(Box)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    boxSizing: 'border-box',
    paddingLeft: '10px'
  };
});

export const QArtTypographyStyled = styled(Typography)(({ theme }) => {
  return {
    fontSize: '30px',
    lineHeight: '30px',
    fontWeight: 700
  };
});


