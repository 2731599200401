import { TimelineHorizontal } from "@alb/live-lib";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  TOccurrence,
  TOccurrenceComments,
  TOccurrenceTimelineSteps,
  TOccurrenceUpdates,
} from "types/types";
import { getCookie } from "utils/cookie";

import { formatDate } from "utils/date";
import { THEME } from "utils/keys";

interface IOccurrenceHistory {
  occurrence: TOccurrence;
}

export const OccurrenceHistory = ({ occurrence }: IOccurrenceHistory) => {
  const { t } = useTranslation();
  const theme = getCookie(THEME);
  const darkMode = theme === "dark" ?? false;

  const comments = occurrence.comments || [];
  const updates = occurrence.updates || [];
  const historyOccurrence = [...comments, ...updates].sort(
    (
      a: TOccurrenceComments | TOccurrenceUpdates,
      b: TOccurrenceComments | TOccurrenceUpdates
    ) => {
      let dateA = new Date(a.created_at).getTime();
      let dateB = new Date(b.created_at).getTime();
      return dateB - dateA;
    }
  );

  const getTranslationStatusName = (name: string) => {
    return t("occurrences.history.statusTimeline." + name.toLowerCase());
  };

  const htmlLabelTimeline = (label: string, date: string | Date | null) => {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <Typography variant="h6" sx={{ fontSize: "16px" }}>
          {getTranslationStatusName(label)}
        </Typography>
        {date && (
          <Typography variant="subtitle2">
            {formatDate(date, t("calendar.dateTimeFullFormat"))}
          </Typography>
        )}
      </Box>
    );
  };

  const stepsTimeline = occurrence.timeline?.steps.map(
    (elem: TOccurrenceTimelineSteps) => {
      return {
        label: htmlLabelTimeline(elem.status, elem.date),
        active: elem.active,
        completed: elem.completed,
      };
    }
  );

  return (
    <>
      <Grid container marginTop={6}>
        <Grid item xs={12}>
          <Typography variant="h3" gutterBottom>
            <Trans
              i18nKey="occurrences.history.title"
              values={{
                status: occurrence.status?.name
                  ? getTranslationStatusName(occurrence.status.name)
                  : "",
              }}
            />
          </Typography>
        </Grid>
        <Grid container item xs={12} spacing={1}>
          <Grid item>
            <Typography variant="h6" gutterBottom fontSize={"18px"}>
              {t("adapters.updatedAt")}:
            </Typography>
          </Grid>
          <Grid item>
            <Typography gutterBottom fontSize={"18px"}>
              {formatDate(
                occurrence.updated_at || "",
                t("calendar.dateTimeFormatLocal")
              )}
            </Typography>
          </Grid>
        </Grid>
        {stepsTimeline && occurrence.status && (
          <Grid item xs={12} marginY={4}>
            <TimelineHorizontal steps={stepsTimeline} />
          </Grid>
        )}
        <Grid item xs={12}>
          <List>
            {historyOccurrence.map((row: any, index: number) => (
              <ListItem
                key={index}
                sx={{
                  backgroundColor: darkMode ? "1F1F1F" : "#FAFAFA", //TODO quando se alterar o palette do UX na lib mudar para white e black do theme.palette
                  padding: 2,
                }}
              >
                <ListItemText
                  primary={
                    <React.Fragment>
                      {row.status__name && (
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography component="span">
                                <Trans
                                  i18nKey="occurrences.history.textChangeStatus"
                                  values={{
                                    user: row.user_editor,
                                    status: getTranslationStatusName(
                                      row.status__name
                                    ),
                                  }}
                                />
                              </Typography>
                            </React.Fragment>
                          }
                        ></ListItemText>
                      )}
                      {row.comment && (
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Typography>{row.comment}</Typography>
                            </React.Fragment>
                          }
                        ></ListItemText>
                      )}
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography variant="subtitle2" component="span">
                        {formatDate(
                          row.created_at,
                          t("calendar.dateTimeFullFormat")
                        )}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </>
  );
};
