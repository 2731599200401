export const formDefaultValues = (filesChecks?: any, data?: any) => {
  const name = data?.name.split(" (")[0]
  const defaultValues = {
    name: name || "",
    ...(filesChecks
      && {
          ...filesChecks,
        })
  };
  return defaultValues;
};
