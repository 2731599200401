/* eslint-disable react-hooks/exhaustive-deps */
import { IMap, IMapPoint, Loading, Map } from "@alb/live-lib";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { TMarker } from "types/types";
import { v4 as uuid } from "uuid";

import NoData from "components/Utils/NoData";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { getUser } from "store/slices/authSlice";
import { getFitBounds } from "store/slices/mapSlice";
import { arcgisKey } from "utils/keys";
import { objectIsEmpty } from "utils/utils";

interface IBugaMapStation {
  idStation: string;
}

export const BugaMapStation = ({ idStation }: IBugaMapStation) => {
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const fitBounds = useSelector(getFitBounds);

  const [argsMap, setArgsMap] = useState<IMap | null>(null);
  const [station, setStation] = useState<TMarker | null>(null);

  const initialZoom = 15;
  const mapTile = user.extra_params?.map_tile_layer;
  const darkMode = user.extra_params?.dark_mode;
  const translations = {
    buttons: {
      layers: t("map.buttons.layers"),
      recenter: t("map.buttons.recenter"),
    },
    popups: {
      lastRead: t("map.marker.lastReading"),
    },
    date: {
      dateTimeFullFormat: t("calendar.dateTimeFullFormat"),
    },
  };
  const defaultArgs = {
    mapZoom: initialZoom,
    mapTileType: mapTile,
    appDarkThemeMode: darkMode,
    mapVariant: "component" as "component",
    mapModule: false,
    showZoom: true,
    language: user?.language.toLowerCase() || "pt",
    mapTranslations: translations,
    mapFitBounds: fitBounds,
    arcgisKey: arcgisKey,
    dynamicAttribution: true
  };

  const { data, loading, error } = useGet(
    ServiceApiUrl.devices,
    paramsRequest()
  );

  function paramsRequest() {
    const params = {
      contains: idStation,
    };
    return params;
  }

  useEffect(() => {
    if (!loading && data?.data) {
      setStation(data.data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (error || loading) setStation(null);
  }, [error, loading]);

  useEffect(() => {
    if (station && !objectIsEmpty(station)) {
      const markers: IMapPoint[] = [
        {
          id: station.id,
          title: station.title,
          type: station.type,
          subType: station.subType,
          geolocation: station.geolocation,
          date: station.date,
          is_active: station.is_active,
          selected: false,
        },
      ];
      setArgsMap({
        idMap: uuid(),
        ...defaultArgs,
        mapCenterPoint: station.geolocation.iconPosition,
        initialMapCenter: station.geolocation.iconPosition,
        points: markers,
      });
    }
  }, [station]);

  return (
    <>
      <Loading show={loading} />
      {!loading && (!station || error) && <NoData error={error} />}
      {!objectIsEmpty(station) && argsMap && (
        <Box
          className="map-component"
          mb={3}
          sx={{
            height: "250px",
            ".leaflet-container": {
              borderRadius: "12px",
            },
          }}
        >
          <Map {...argsMap} />
        </Box>
      )}
    </>
  );
};
