import { ConfirmDialog, useFeedback } from "@alb/live-lib";
import useDelete from "hooks/fetchData/useDelete";
import { t } from "i18next";
import { ServiceApiUrl } from "services/ServiceApiUrl";

const GroupDeleteDialog = (props: {
    selectedRow: any,
    showDeleteDialog: boolean,
    onClose: () => void,
    refetchGroups: () => void
}) => {

    const { selectedRow, showDeleteDialog, onClose, refetchGroups } = props;

    const { addFeedback } = useFeedback();

    const { loading, refetch: deleteGroup } = useDelete(
        ServiceApiUrl.groups,
        selectedRow?.id,
        undefined
      );

    const handleConfirmDelete = async () => {

        try {
            await deleteGroup();

            addFeedback({
                message: t("group.groupDeleted"),
                severity: "success",
              });

              refetchGroups();
          } catch {}

        onClose();

    }

    return(<ConfirmDialog
        open={showDeleteDialog}
        type="error"
        title={t("group.deleteGroup")}
        message={t("group.deleteGroupConfirmation")}
        actionConfirmText={t("common.delete")}
        actionCancelText={t("common.cancel")}
        loading={loading}
        onConfirm={handleConfirmDelete}
        onCancel={onClose}
    />)
}

export default GroupDeleteDialog;
