import React from 'react'

import { WazeAdapters } from 'pages/Waze';
import { IFilterDateRangeMap } from 'store/slices/mapSlice';

import WazeAlertsList from './WazeAlertsList';
import WazeJamsList from './WazeJamsList';

interface IWazeMapList {
	type: string;
	filterSelectedRange: IFilterDateRangeMap;
	selectedType: string | null,
	filterSearch: string | null,
	selectedLevel: string | null,
}

function WazeMapListComponent({ type, filterSelectedRange, selectedType, filterSearch, selectedLevel }: IWazeMapList) {
	const lookupComponent = {
		[WazeAdapters.alerts]: WazeAlertsList,
		[WazeAdapters.jams]: WazeJamsList
	}

	const Component = lookupComponent[type as keyof typeof lookupComponent];

	return (
		<Component filterSelectedRange={filterSelectedRange} selectedType={selectedType} filterSearch={filterSearch} selectedLevel={selectedLevel} />
	)
}

export default WazeMapListComponent
