import CloseIcon from "@mui/icons-material/Close";
import { DialogTitle, Grid, IconButton } from "@mui/material";

export interface ILiveDialogTitle {
  onClose?: () => void;
  children?: React.ReactNode;
}

const LiveDialogTitle = ({ onClose, children }: ILiveDialogTitle) => {
  return (
    <DialogTitle>
      <Grid container columnSpacing={1} justifyContent="space-between">
      <Grid xs={true} item>{children}</Grid> 
        {onClose && (
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={onClose}
              size="small"
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </DialogTitle>
  );
};

export default LiveDialogTitle;
