import { ConfirmDialog, TableHeadCell, TableHeadSort, useFeedback } from "@alb/live-lib";
import { Table, TableBody, TableContainer } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router";
import { IResultTable } from "types/interfaces";
import { TOccurrence } from "types/types";

import { CustomTablePagination } from "components/Utils/Pagination/CustomTablePagination";
import useDelete from "hooks/fetchData/useDelete";
import { useGetModuleHeaders } from "hooks/useGetModuleHeaders";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { MODULES_KEYS } from "utils/modules/modulesKeys";
import { formatRoutePath } from "utils/routePath";
import { arrayIsEmpty } from "utils/utils";

import OccurrenceEdit from "./OccurrenceEdit";
import OccurrencesTableRow from "./OccurrencesTableRow";

export const OccurrencesTable = (props: IResultTable) => {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { addFeedback } = useFeedback();
  const [selectedOcurrence, setSelectedOcurrence] = useState<any>();
  const [openEdit, setOpenEdit] = useState(false);
  const {
    data: occurrences,
    orderBy,
    order,
    setOrder,
    setOrderBy,
    page,
    setPage,
    refetch,
    setRefetch
  } = props;

  const [selectedOccurrence, setselectedOccurrence] = useState<any>();
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);

  const { headers } = useGetModuleHeaders(MODULES_KEYS.OCCURRENCES);

	const tableHeads: TableHeadCell[] = [
		/*{
			id: 'name',
			label: t('occurrences.name'),
		},
		{
			id: 'reference',
			label: t('occurrences.reference'),
		},*/
		{
			id: 'status',
			label: t('occurrences.status'),
		},
		{
			id: 'category',
			label: t('occurrences.category'),
		},
		// {
		// 	id: 'type',
		// 	label: t('occurrences.type'),
		// },
		{
			id: 'address',
			label: t('occurrences.address'),
		},
    // {
    //   id: 'iconPosition',
    //   label: t('occurrences.coordinates'),
    //   notSortable: true,
    // },
		{
			id: 'record_date',
			label: t('occurrences.startDate'),
		},
		{
			id: "assignee_id",
			label: t("occurrences.assignedTo"),
		  },
		  {
			id: "urgency",
			label: t("occurrences.urgency"),
		  },
		  {
			id: "resolution_days",
			label: t("occurrences.resolutionTime"),
		  },
	]

  const handleRequestSort = (
    occurrences: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  //Trocar página da listagem
  const handleChangePage = (newPage: number) => {
    setPage(newPage + 1); //porque o index da paginação do mui inicia no 0
  };

  const handleRowClick = (row: TOccurrence) => {
    const path = formatRoutePath(location, params, {
      occurrenceId: row.id,
    });
    navigate(path);
  };

  const handleDeleteClick = (row: TOccurrence) => {
    setselectedOccurrence(row);
    setOpenDeleteConfirm(true);
  };

  const handleEdit = (row: TOccurrence) => {
    setSelectedOcurrence(row.id);
    setOpenEdit(true);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleSuccessEdit = (o: TOccurrence) => {
    refetch();
    setRefetch(true);
  };

  let row;
  if (!arrayIsEmpty(occurrences.data)) {
    row = occurrences.data.map((occurences: TOccurrence) => (
      <OccurrencesTableRow
        key={occurences.id}
        row={occurences}
        onRowClick={handleRowClick}
        onDeleteClick={handleDeleteClick}
        onEdit={handleEdit}
      />
    ));
  }

  const handleCloseDelete = () => {
    setOpenDeleteConfirm(false);
  };

  const { loading, refetch: deleteOccurence } = useDelete(
    ServiceApiUrl.occurrences,
    selectedOccurrence?.id,
    headers
  );

  const handlerDeleteOccurrence = () => {
    handleCloseDelete();
    addFeedback({
      message: t("occurrences.occurrenceDeleted"),
      severity: "success",
    });

    // update list
    refetch && refetch();
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteOccurence();
      await handlerDeleteOccurrence();
      setRefetch(true);
    } catch {}
  };

  return (
    <>
      <TableContainer sx={{ mt: 4 }}>
        <Table>
          <TableHeadSort
            headCells={tableHeads}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <TableBody>{row}</TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        count={occurrences?.totalCount}
        page={page}
        totalPages={occurrences?.totalPages}
        onPageChange={handleChangePage}
      />

      {selectedOccurrence && (
        <>
          {openDeleteConfirm && (
            <ConfirmDialog
              open={openDeleteConfirm}
              type="error"
              title={t("occurrences.deleteOccurrence")}
              message={t("occurrences.occurrenceDeleteConfirmation")}
              actionConfirmText={t("common.delete")}
              actionCancelText={t("common.cancel")}
              loading={loading}
              onCancel={handleCloseDelete}
              onConfirm={handleConfirmDelete}
            />
          )}
        </>
      )}

      {openEdit && (
        <OccurrenceEdit
          open={openEdit}
          idOccurrence={selectedOcurrence}
          onClose={handleCloseEdit}
          onSuccess={handleSuccessEdit}
        />
      )}
    </>
  );
};
