/* eslint-disable react-hooks/exhaustive-deps */
import { arrayIsEmpty, InputSelect, IRange, Loading } from "@alb/live-lib";
import { TOption } from "@alb/live-lib/components/input-fields/input.interface";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Button, FormGroup, Grid, Typography } from "@mui/material";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useCategoriesOptions } from "./hooks/useCategoriesOptions";
import { useStatusOptions } from "./hooks/useStatusOptions";
import useUrgencyOptions from "./hooks/useUrgencyOptions";

export interface IFiltersSection {
  name?: string;
  status?: string | null;
  urgency?: string | null;
  startDateRange?: IRange | undefined;
}
export interface IFilterFormOccurrences {
  status?: string | null;
  urgency?: string | null;
  category?: string | null;
  startDateRange?: IRange | undefined;
}
interface IFilterForm {
  data: IFilterFormOccurrences | null;
  formID: string;
  numActiveFilters: number;
  onFormSubmit: (payLoad: IFilterFormOccurrences) => void;
}

export const FilterForm = ({
  data,
  formID,
  numActiveFilters,
  onFormSubmit,
}: IFilterForm) => {
  const { t } = useTranslation();
  const { statusOptions, loading: loadingStatus } = useStatusOptions();
  const { urgencyOptions, loading: loadingUrgency } = useUrgencyOptions();
  const { categoriesOptions, loading: loadingCategories } =
    useCategoriesOptions();

  const defaultValues: IFilterFormOccurrences = {
    status: data?.status || "",
    category: data?.category || "",
    urgency: data?.urgency || "",
  };

  const methodsForm = useForm<IFilterFormOccurrences>({
    defaultValues: defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
    // resolver: yupResolver(getFiltersSchema()),
  });
  const { control, handleSubmit } = methodsForm;

  const formSubmitHandler: SubmitHandler<IFilterFormOccurrences> = (
    payLoad: any
  ) => {
    if (payLoad && Object.keys(payLoad).length > 0) {
      for (const key in payLoad) {
        if (payLoad[key] === "") {
          delete payLoad[key];
        }
      }
    }
    onFormSubmit(payLoad);
  };

  return (
    <FormProvider {...methodsForm}>
      <form id={formID} onSubmit={handleSubmit(formSubmitHandler)}>
        <FormGroup>
          <Grid container item xs={12} justifyContent="flex-end">
            {numActiveFilters > 0 && (
              <Button
                variant="outlined"
                startIcon={<RefreshIcon />}
                type={"submit"}
                onClick={() => {
                  methodsForm.reset({
                    status: "",
                    category: "",
                    urgency: "",
                  });
                }}
              >
                {t("clear")}
              </Button>
            )}
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                {t("occurrences.status")}
              </Typography>
              {statusOptions && !arrayIsEmpty(statusOptions) && (
                <InputSelect
                  placeholder={t("occurrences.statusPlaceholder")}
                  name="status"
                  control={control}
                  options={statusOptions as TOption[]}
                  noOptionsText={t("common.noOptions")}
                />
              )}
              {loadingStatus && <Loading show={loadingStatus} />}
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom color="text.black" variant="h6">
                {t("occurrences.category")}
              </Typography>
              {categoriesOptions && !arrayIsEmpty(categoriesOptions) && (
                <InputSelect
                  placeholder={t("occurrences.categoryNamePlaceholder")}
                  name="category"
                  control={control}
                  options={categoriesOptions as TOption[]}
                  noOptionsText={t("common.noOptions")}
                />
              )}
              {loadingCategories && <Loading show={loadingCategories} />}
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom color="text.black" variant="h6">
                {t("occurrences.urgency")}
              </Typography>
              {urgencyOptions && !arrayIsEmpty(urgencyOptions) && (
                <InputSelect
                  placeholder={t("occurrences.urgencyPlaceholder")}
                  name="urgency"
                  control={control}
                  options={urgencyOptions}
                  noOptionsText={t("common.noOptions")}
                />
              )}
              {loadingUrgency && <Loading show={loadingUrgency} />}
            </Grid>
          </Grid>
        </FormGroup>
      </form>
    </FormProvider>
  );
};
