/* eslint-disable react-hooks/exhaustive-deps */
import useAxios from "axios-hooks";
import { AxiosRequestConfig } from "axios";
import useErrorMessage from "hooks/useErrorMessage";
import { useEffect } from "react";

const useUpdate = (
  url: string,
  id?: string,
  othersConfig?: AxiosRequestConfig<any>
) => {

  const { showError } = useErrorMessage();
  const verifiedURL = id ? url +"/" + id : url
  const requestConfig: AxiosRequestConfig = { 
    ...othersConfig,
    url: verifiedURL,
    method: 'PUT'
  };

  const [{ data, loading, error }, refetch, cancelRequest] = useAxios(
    requestConfig,
    { manual: true }
  );

  useEffect(() => {
    if (error) {
      showError(error);
    }
  }, [error]);

  return {
    data,
    loading,
    error,
    refetch,
    cancelRequest
  };
}

export default useUpdate;