import { Loading } from "@alb/live-lib";
import { Box, Card, CardContent, Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { defaultOptions } from "components/charts/defaultOptions";
import { IReactECharts, ReactECharts as SmoothedLineChart } from "components/charts/ReactECharts";
import NoData from "components/Utils/NoData";
import { getSelectedParishArea } from "store/slices/mapSlice";
import styles from "styles/modules/map/list.module.scss";
import { formatDate } from "utils/date";
import { formatNumber } from "utils/number";
import { arrayIsEmpty } from "utils/utils";

export const CardPeriodLastYear = () => {
	const { t } = useTranslation();
	const theme = useTheme();
	const idParish = useSelector(getSelectedParishArea).id;
	const [data, setData] = useState<any | null>(null);
	const [chartOptions, setChartOptions] = useState<IReactECharts["option"]>({});

	const loading = false;
	const error = null;
	const dateRange = t("map.detail.datesPeriodLastYear", {
		iDate: formatDate("2023-01-05", "d MMM yyyy"),
		fDate: formatDate("2023-01-11", "d MMM yyyy"),
	});
	const dataPeriod = [
		{
			date: "2023-01-05",
			value: 23104,
		},
		{
			date: "2023-01-06",
			value: 20576,
		},
		{
			date: "2023-01-07",
			value: 25953,
		},
		{
			date: "2024-01-08",
			value: 25854,
		},
		{
			date: "2024-01-09",
			value: 25595,
		},
		{
			date: "2024-01-10",
			value: 24595,
		},
		{
			date: "2024-01-11",
			value: 29595,
		},
	];
	const dataPeriodAEE = [
		{
			date: "2023-01-05",
			value: 541,
		},
		{
			date: "2023-01-06",
			value: 429,
		},
		{
			date: "2023-01-07",
			value: 691,
		},
		{
			date: "2023-01-08",
			value: 727,
		},
		{
			date: "2023-01-09",
			value: 682,
		},
		{
			date: "2023-01-10",
			value: 682,
		},
		{
			date: "2023-01-11",
			value: 682,
		},
	];

	const optionsDoughnutChart = (values: any) => {
		let dataXAxis: string[] = [];
		const dataSeries = values.map((val: any) => {
			dataXAxis.push(val.date);
			return val.value;
		});
		return {
			...defaultOptions,
			tooltip: {
				confine: true,
				extraCssText: "white-space:inherit;",
				trigger: "axis",
				backgroundColor: theme.palette.background.paper,
				textStyle: {
					color: theme.palette.mode === "light" ? "" : theme.palette.common.white,
				},
				valueFormatter: function (value: any) {
					return formatNumber(value) || "--";
				},
			},
			grid: {
				left: "13%",
				top: "15%",
				bottom: "19%",
			},
			xAxis: [
				{
					type: "category",
					axisTick: { show: false },
					data: dataXAxis,
					axisLine: {
						lineStyle: {
							color: theme.palette.mode === "light" ? "" : theme.palette.common.white,
						},
					},
				},
			],
			yAxis: [
				{
					type: "value",
					axisLabel: {
						formatter: (val: any) => formatNumber(val),
					},
					axisLine: {
						lineStyle: {
							color: theme.palette.mode === "light" ? "" : theme.palette.common.white,
						},
					},
				},
			],
			series: [
				{
					name: t("map.legend.visitsTitle"),
					type: "line",
					emphasis: {
						focus: "series",
					},
					data: dataSeries,
				},
			],
			legend: {
				...defaultOptions.legend,
				...{
					textStyle: {
						color: theme.palette.text.primary,
						fontFamily: theme.typography.fontFamily,
						fontSize: "12px",
					},
				},
			},
		} as IReactECharts["option"];
	};

	useEffect(() => {
		if (!loading) {
			setData(dataPeriod);
			setChartOptions(optionsDoughnutChart(dataPeriod));
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (error) {
			setData(null);
		}
	}, [error]);

	useEffect(() => {
		if (idParish) {
			setData(dataPeriodAEE);
			setChartOptions(optionsDoughnutChart(dataPeriodAEE));
		} else {
			setData(dataPeriod);
			setChartOptions(optionsDoughnutChart(dataPeriod));
		}
		// eslint-disable-next-line
	}, [idParish]);

	return (
		<Grid container>
			<Grid item xs={12}>
				<Card>
					<CardContent className={styles["list-detail__card"]}>
						<Box>
							<Typography variant="body1">
								{t("map.detail.comparisonPeriodLastYear")}
							</Typography>
							<Typography fontSize="14px" mb={"1.5px"} mt="auto">
								{"(" + dateRange + " - " + t("average") + ")"}
							</Typography>
						</Box>
						<Loading show={loading} />
						{!arrayIsEmpty(data) && !loading && (
							<>
								<SmoothedLineChart
									option={chartOptions}
									style={{ minHeight: "300px" }}
								/>
							</>
						)}
						{arrayIsEmpty(data) && !loading && <NoData error={error} />}
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
