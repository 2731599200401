import { BugaTypeKey } from "types/enum"

const _baseApiUrl = process.env.REACT_APP_API_BASE_URL || ''
const _baseApiUrlSSE = process.env.REACT_APP_API_BASE_SSE_URL || ''

const _apiWSSUrl = process.env.REACT_APP_API_WSS_URL || ''
const _baseApiUrlWSS = process.env.REACT_APP_API_BASE_WSS_URL || ''

export const ServiceApiUrl = {
  baseApiUrl: _baseApiUrl,
  baseApiUrl_SSE: _baseApiUrlSSE,

	wsNotifications: `${_apiWSSUrl}${_baseApiUrlWSS}/notifications`,

  login: '/login',
  logout: '/logout',

  adapterConfiguratorsAllDevices: '/adapter-configurators/devices',
  adapterConfiguratorDevices: (idAdapter: string) => {
    return `/adapter-configurators/${idAdapter}/devices`
  },
  adapterConfigurators: '/adapter-configurators',

  devices: '/devices',
  streams: '/streams',

  user: '/users',
  recoverPassword: '/users/recover-password',
  resetPassword: '/users/reset-password',
  validateTokenURL: '/users/validate-token',
  setPwdURL: '/users/set-password',
  clients: '/clients',

  // userInviteURL: "/users/invite",
  userInvite: (id: string) => {
    return `/users/${id}/invite`
  },

  //CMAveiro
  energyConsumption: '/aveiro/energy/consumption',
  energyIP: '/aveiro/energy/ip',
  energyPTD: 'aveiro/energy/ptd',
  parishInfo: '/aveiro/parish',
  parishConsumption: '/aveiro/agg-values/city',
  isoCategorys: '/aveiro/iso/categories',

  //CMFunchal
  events: '/modules/funchal/events',
  eventsTotalStatus: 'modules/funchal/events/total?attr=status',
	eventsStatus: 'modules/funchal/events/status',
	eventsTypes: 'modules/funchal/events/types',
  eventsCategories: '/modules/funchal/events/categories',
  eventsType: '/modules/funchal/events/types',
  statusType: '/modules/funchal/events/status',
  parking: '/smart_parking',
  avgParkingTime: '/smart_parking/avg_time',

	occurrences: '/modules/occurrences',
	occurrencesStatus: '/modules/occurrences/status',
	occurrencesTypes: '/modules/occurrences/types',
  occurrencesCategories: 'modules/occurrences/categories',
	occurrencesUrgencies: 'modules/occurrences/urgencies',
  occurrencesTotalStatus: 'modules/occurrences/total?attr=status',
  occurrencesAllParishes: '/modules/occurrences/total?attr=freguesia',
  occurrencesAllCategories: '/modules/occurrences/total?attr=category',
  occurrencesWeekday: '/modules/occurrences/total?attr=weekday',
  occurrencesAverageResolutionTime: '/modules/occurrences/total?attr=closed',
  occurrencesUrgency: '/modules/occurrences/urgencies',

  indicatorCategories: '/aveiro/iso/categories',
  indicatorRank: '/aveiro/iso/rank',
  modules: '/modules',
  accounts: '/accounts',
  groups: '/groups',

	aveiroVeolia: '/aveiro/veolia',

	// QART
	qartDevices: '/modules/qart/devices',
	//'/modules/qart/values?external_id={ID}&from={DATA}&to={DATA}&streams={streams}',
	qartValues: '/modules/qart/values',
	//qart/map?from={DATA}&to={DATA}&streams={streams}&points=[[38.791071468037416,-9.25254821777344],[38.791071468037416,-9.48875427246094],[38.64132730808247,-9.48875427246094],[38.64132730808247,-9.25254821777344]]
	qartMap: '/modules/qart/map',

  clientModule: (id: string) => {
    return `/clients/${id}/modules`
  },

  // ---- Public API
  publicEvents: (nameClient: string) => {
    return `/${nameClient}/events`
  },
  publicEventsFilters: (nameClient: string, filter: string) => {
    return `/${nameClient}/events/${filter}`
  },
  publicOccurrences: (nameClient: string) => {
    return `/occurrences`
  },
  publicOccurrencesFilters: (nameClient: string, filter: string) => {
    return `/occurrences/${filter}`
  },
  adapterDownload: (id:string)=> {
    return `/adapter-configurators/${id}/download-data`;
  },

  // ---- SSE, data in real time
  devicesSmartParking: 'funchal/smartparking',

  // ---- CMChaves
  // GeoAnalytics - tourism
  mapView: '/geo-analytics/tourism/map-view',
  overViewTourism: '/geo-analytics/tourism/overview',
  presenceTourism: '/geo-analytics/tourism/presence',
  visitsByDate: '/geo-analytics/tourism/visits-by-date',
  visitsByDateRange: '/geo-analytics/tourism/visits-by-date-range',
  heatmap: '/geo-analytics/tourism/heatmap',

	// ---- CMBeja
	chargingCards : '/charging/cards',
	chargingTransactions : '/charging/transactions',
  storage: '/storage',
  storageCards: '/storage/cards',
  inversor: '/inversor',
  inversorCards: '/inversor/cards',
  hydrogenCards: (device:string)=> {
    return `/hydrogen/${device}/cards/`;
  },
  hydrogenList: (device:string)=> {
    return `/hydrogen/${device}/table/`;
  },
  analysis: '/geo-analytics/analysis',

	// notifications
	notifications: '/notifications',
	notificationsRead: '/notifications/read',
	notificationsReadAll: '/notifications/readall',

  // --- waze module
  wazeMap: '/modules/waze/map',
  jams: '/modules/waze/jams',
  alerts: '/modules/waze/alerts',
  wazeMarkersByAdapter: (adapter: string) => {
    return `/modules/waze/${adapter}`
  },
  wazeList: (adapter: string) => {
    return `/modules/waze/${adapter}/list`
  },
  wazeCards: (adapter: string) => {
    return `/modules/waze/${adapter}/stats/cards`
  },
  wazeAlertsTypes:'/modules/waze/alerts/types', //tipos alerts
  wazeAlertsCategoriesChart: (adapter: string) => {
    return `/modules/waze/${adapter}/stats/chart`
  },
  wazeTimeSeriesChart: (adapter: string) => {
    return `/modules/waze/${adapter}/stats/timeseries`
  },

  // soltrafego
  bugaCrads: (type: BugaTypeKey) => {
    // TODO temporario a parte v2
    if(type === BugaTypeKey.station) {
      return `/modules/soltrafego/${type}/v2/stats/cards`;
    }
    return `/modules/soltrafego/${type}/stats/cards`;
  },
  bugaBikesHeatMap: "/modules/soltrafego/bikes/heatmap",
  bugaBikesStatus: "/modules/soltrafego/bikes/status",
  bugaStationCharts: (externalID: string) => {
    // TODO temporario a parte v2
    return `/modules/soltrafego/station/v2/stats/charts/${externalID}`;
  },
  bugaList: (type: BugaTypeKey) => {
    // TODO temporario a parte v2
    if(type === BugaTypeKey.station) {
      return `/modules/soltrafego/${type}/v2`;
    }
    return `/modules/soltrafego/${type}`;
  },
};
