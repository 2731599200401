import { IRange, TableHeadOrder } from "@alb/live-lib";
import { Grid } from "@mui/material";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IApiResponse, IHydrogenSupply } from "types/interfaces";

import Loading from "components/Utils/Loading";
import NoData from "components/Utils/NoData";
import { ROWS_PER_PAGE } from "components/Utils/Pagination/pagination";
import useGet from "hooks/fetchData/useGet";
import { ServiceApiUrl } from "services/ServiceApiUrl";
import { formatDate } from "utils/date";
import { formatOrderBy } from "utils/orderByAPI";
import { arrayIsEmpty } from "utils/utils";

import TableInformation from "./TableInformation";

interface IList {
  deviceName: string;
  filterDateRange?: IRange | undefined;
}

const List = ({ deviceName, filterDateRange }: IList) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<TableHeadOrder>("desc");
  const [orderBy, setOrderBy] = useState<string>("date");

  const getParams = () => {
    var params: {
      page: number;
      items: number;
      order_by: string;
    } = {
      page: page,
      items: ROWS_PER_PAGE,
      order_by: formatOrderBy(order, orderBy),
      ...(filterDateRange && {
        start_date: formatDate(
          filterDateRange?.startDate,
          t("calendar.dateFormatGeoAnalytics")
        ),
        end_date: formatDate(
          filterDateRange?.endDate,
          t("calendar.dateFormatGeoAnalytics")
        ),
      }),
    };
    return params;
  };

  const {
    data: supply,
    loading,
    error,
    refetch
  } = useGet<IApiResponse<IHydrogenSupply>>(
    ServiceApiUrl.hydrogenList(deviceName),
    getParams()
  );

  function reset() {
    setPage(1);
    setOrder("desc");
    setOrderBy("date");
  }

  useEffect(() => {
    reset();
  }, [deviceName]);

  let list = <NoData error={error} />;

  if (loading) {
    list = <Loading />;
  }

  if (!loading && supply && !arrayIsEmpty(supply?.data)) {
    list = (
      <TableInformation
        data={supply}
        orderBy={orderBy}
        order={order}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        page={page}
        setPage={setPage}
        setRefetch={refetch}
      />
    );
  }

  return (
    <Grid item mt={4} pt={6} data-testid="hydrogenSupplyList">
      {list}
    </Grid>
  );
};

export default List;
