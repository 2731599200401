/* eslint-disable react-hooks/exhaustive-deps */
import {
  Typography,
  Drawer,
  Box,
  IconButton,
  Grid,
  Badge,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { FilterTabForm } from "./FilterTabForm";
import useGetFiltersCount from "./hooks/useGetFiltersCount";
import { useSelector } from "react-redux";
import { selectMarkers } from "store/slices/mapSlice";
import { IMapPoint } from "@alb/live-lib";

interface IFilterTab {
  open: boolean;
  publicAPI?: boolean;
  onClose: () => void;
}
export const FilterTab = ({ open, publicAPI, onClose }: IFilterTab) => {
  const formId = "map-filterForm";
  const { t } = useTranslation();
  const theme = useTheme();

  const filtersCount = useGetFiltersCount();
  const markers: IMapPoint[] = useSelector(selectMarkers);

  return (
    <Drawer variant="persistent" anchor="right" open={open}>
      <Box
        sx={{
          width: 450,
          padding: 3,
          paddingRight: 3,
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            flexBasis: 0,
            flexGrow: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography  sx={{ fontSize: "24px", fontWeight: 700 }}>
              {t("common.filters")}
            </Typography>
            {filtersCount > 0 && (
              <Badge
                badgeContent={filtersCount}
                sx={{
                  paddingLeft: '5px',
                  '.MuiBadge-badge': {
                    backgroundColor: theme.palette.primary.dark,
                    color: theme.palette.secondary.contrastText,
                  },
                }}
              ></Badge>
            )}
          </Box>
          <IconButton size="small" onClick={onClose}>
            <CloseOutlinedIcon fontSize="inherit" />
          </IconButton>
        </Grid>
        {markers.length > 0 ? (
          <Box sx={{ display: "flex" }}>
            <Typography
              color="text.secondary"
              sx={{ fontSize: "18px", fontWeight: 700, lineHeight: "0.8" }}
            >
              {markers.length}
            </Typography>
            <Typography
              color="text.secondary"
              sx={{ fontSize: "18px", lineHeight: "0.8", paddingLeft: 1 }}
            >
              {t("map.list.subTitle", { count: markers.length })}
            </Typography>
          </Box>
        ) : (
          <Typography
            color="text.secondary"
            sx={{ fontSize: "16px", fontWeight: 400, lineHeight: "0.8" }}
          >
            {t("map.list.noResults")}
          </Typography>
        )}

        <Box
          sx={{
            overflowY: "auto",
            flexGrow: 20,
            flexBasis: 0,
            paddingRight: 1,
          }}
        >
          <FilterTabForm formId={formId} publicAPI={publicAPI || false} />
        </Box>
      </Box>
    </Drawer>
  );
};
